export const data = "1991-01-01,28,51;" +
    "1991-01-01,35,52;" +
    "1991-01-02,30,53;" +
    "1991-01-02,36,54;" +
    "1991-01-03,37,54;" +
    "1991-01-03,42,52;" +
    "1991-01-04,30,58;" +
    "1991-01-04,36,58;" +
    "1991-01-05,29,45;" +
    "1991-01-05,30,44;" +
    "1991-01-06,34,54;" +
    "1991-01-06,37,54;" +
    "1991-01-07,43,56;" +
    "1991-01-07,44,55;" +
    "1991-01-08,42,50;" +
    "1991-01-08,42,51;" +
    "1991-01-09,43,51;" +
    "1991-01-09,44,51;" +
    "1991-01-10,38,59;" +
    "1991-01-10,40,58;" +
    "1991-01-11,40,54;" +
    "1991-01-11,42,53;" +
    "1991-01-12,46,62;" +
    "1991-01-12,47,59;" +
    "1991-01-13,49,65;" +
    "1991-01-13,50,61;" +
    "1991-01-14,47,54;" +
    "1991-01-14,48,52;" +
    "1991-01-15,47,62;" +
    "1991-01-15,45,58;" +
    "1991-01-16,42,64;" +
    "1991-01-16,51,63;" +
    "1991-01-17,43,64;" +
    "1991-01-17,48,65;" +
    "1991-01-18,37,70;" +
    "1991-01-18,43,68;" +
    "1991-01-19,39,68;" +
    "1991-01-19,41,67;" +
    "1991-01-20,36,63;" +
    "1991-01-20,45,61;" +
    "1991-01-21,39,62;" +
    "1991-01-21,42,61;" +
    "1991-01-22,30,64;" +
    "1991-01-22,38,65;" +
    "1991-01-23,30,64;" +
    "1991-01-23,35,66;" +
    "1991-01-24,32,58;" +
    "1991-01-24,36,58;" +
    "1991-01-25,30,58;" +
    "1991-01-25,31,59;" +
    "1991-01-26,32,59;" +
    "1991-01-26,37,57;" +
    "1991-01-27,33,59;" +
    "1991-01-27,38,57;" +
    "1991-01-28,39,59;" +
    "1991-01-28,38,58;" +
    "1991-01-29,31,65;" +
    "1991-01-29,38,65;" +
    "1991-01-30,29,57;" +
    "1991-01-30,35,58;" +
    "1991-01-31,36,60;" +
    "1991-01-31,39,62;" +
    "1991-02-01,33,65;" +
    "1991-02-01,40,65;" +
    "1991-02-02,50,65;" +
    "1991-02-02,49,62;" +
    "1991-02-03,45,65;" +
    "1991-02-03,47,67;" +
    "1991-02-04,46,60;" +
    "1991-02-04,45,57;" +
    "1991-02-05,52,58;" +
    "1991-02-05,46,56;" +
    "1991-02-06,38,61;" +
    "1991-02-06,42,61;" +
    "1991-02-07,40,59;" +
    "1991-02-07,48,61;" +
    "1991-02-08,40,68;" +
    "1991-02-08,45,65;" +
    "1991-02-09,37,64;" +
    "1991-02-09,42,65;" +
    "1991-02-10,38,65;" +
    "1991-02-10,41,65;" +
    "1991-02-11,44,69;" +
    "1991-02-11,46,69;" +
    "1991-02-12,39,68;" +
    "1991-02-12,47,67;" +
    "1991-02-13,43,71;" +
    "1991-02-13,47,72;" +
    "1991-02-14,43,71;" +
    "1991-02-14,52,73;" +
    "1991-02-15,47,69;" +
    "1991-02-15,50,65;" +
    "1991-02-16,50,62;" +
    "1991-02-16,50,60;" +
    "1991-02-17,43,65;" +
    "1991-02-17,45,60;" +
    "1991-02-18,38,71;" +
    "1991-02-18,41,68;" +
    "1991-02-19,42,75;" +
    "1991-02-19,48,75;" +
    "1991-02-20,41,69;" +
    "1991-02-20,47,68;" +
    "1991-02-21,41,71;" +
    "1991-02-21,48,68;" +
    "1991-02-22,49,63;" +
    "1991-02-22,44,60;" +
    "1991-02-23,41,74;" +
    "1991-02-23,42,72;" +
    "1991-02-24,40,71;" +
    "1991-02-24,49,70;" +
    "1991-02-25,39,72;" +
    "1991-02-25,45,72;" +
    "1991-02-26,43,67;" +
    "1991-02-26,46,64;" +
    "1991-02-27,46,55;" +
    "1991-02-27,46,54;" +
    "1991-02-28,47,61;" +
    "1991-02-28,49,59;" +
    "1991-03-01,47,61;" +
    "1991-03-01,42,54;" +
    "1991-03-02,48,63;" +
    "1991-03-02,47,59;" +
    "1991-03-03,51,64;" +
    "1991-03-03,53,60;" +
    "1991-03-04,55,62;" +
    "1991-03-04,46,59;" +
    "1991-03-05,45,57;" +
    "1991-03-05,41,53;" +
    "1991-03-06,34,59;" +
    "1991-03-06,36,55;" +
    "1991-03-07,37,60;" +
    "1991-03-07,40,59;" +
    "1991-03-08,35,64;" +
    "1991-03-08,37,62;" +
    "1991-03-09,39,61;" +
    "1991-03-09,44,56;" +
    "1991-03-10,39,60;" +
    "1991-03-10,38,54;" +
    "1991-03-11,36,57;" +
    "1991-03-11,38,90;" +
    "1991-03-12,41,59;" +
    "1991-03-12,41,53;" +
    "1991-03-13,44,57;" +
    "1991-03-13,39,51;" +
    "1991-03-14,36,57;" +
    "1991-03-14,36,48;" +
    "1991-03-15,37,57;" +
    "1991-03-15,34,54;" +
    "1991-03-16,43,58;" +
    "1991-03-16,41,54;" +
    "1991-03-17,46,56;" +
    "1991-03-17,44,52;" +
    "1991-03-18,40,54;" +
    "1991-03-18,40,53;" +
    "1991-03-19,40,58;" +
    "1991-03-19,41,52;" +
    "1991-03-20,43,54;" +
    "1991-03-20,41,50;" +
    "1991-03-21,43,57;" +
    "1991-03-21,41,54;" +
    "1991-03-22,41,59;" +
    "1991-03-22,43,52;" +
    "1991-03-23,43,59;" +
    "1991-03-23,43,53;" +
    "1991-03-24,41,54;" +
    "1991-03-24,43,51;" +
    "1991-03-25,44,56;" +
    "1991-03-25,40,50;" +
    "1991-03-26,38,49;" +
    "1991-03-26,36,47;" +
    "1991-03-27,35,63;" +
    "1991-03-27,35,55;" +
    "1991-03-28,37,67;" +
    "1991-03-28,37,59;" +
    "1991-03-29,39,69;" +
    "1991-03-29,39,68;" +
    "1991-03-30,42,72;" +
    "1991-03-30,49,71;" +
    "1991-03-31,49,66;" +
    "1991-03-31,47,59;" +
    "1991-04-01,49,57;" +
    "1991-04-01,45,56;" +
    "1991-04-02,44,67;" +
    "1991-04-02,46,61;" +
    "1991-04-03,41,76;" +
    "1991-04-03,45,72;" +
    "1991-04-04,43,77;" +
    "1991-04-04,46,72;" +
    "1991-04-05,46,72;" +
    "1991-04-05,48,67;" +
    "1991-04-06,54,67;" +
    "1991-04-06,45,61;" +
    "1991-04-07,37,65;" +
    "1991-04-07,43,60;" +
    "1991-04-08,37,72;" +
    "1991-04-08,43,69;" +
    "1991-04-09,42,72;" +
    "1991-04-09,10,90;" +
    "1991-04-10,39,64;" +
    "1991-04-10,41,58;" +
    "1991-04-11,45,62;" +
    "1991-04-11,45,59;" +
    "1991-04-12,39,74;" +
    "1991-04-12,46,71;" +
    "1991-04-13,41,74;" +
    "1991-04-13,43,68;" +
    "1991-04-14,47,66;" +
    "1991-04-14,0,70;" +
    "1991-04-15,46,60;" +
    "1991-04-15,44,54;" +
    "1991-04-16,44,64;" +
    "1991-04-16,43,58;" +
    "1991-04-17,40,65;" +
    "1991-04-17,41,58;" +
    "1991-04-18,48,65;" +
    "1991-04-18,47,55;" +
    "1991-04-19,40,71;" +
    "1991-04-19,41,66;" +
    "1991-04-20,48,68;" +
    "1991-04-20,48,61;" +
    "1991-04-21,50,68;" +
    "1991-04-21,49,64;" +
    "1991-04-22,45,65;" +
    "1991-04-22,46,56;" +
    "1991-04-23,48,58;" +
    "1991-04-23,47,52;" +
    "1991-04-24,48,61;" +
    "1991-04-24,46,56;" +
    "1991-04-25,44,64;" +
    "1991-04-25,0,57;" +
    "1991-04-26,48,70;" +
    "1991-04-26,47,65;" +
    "1991-04-27,43,75;" +
    "1991-04-27,45,70;" +
    "1991-04-28,42,80;" +
    "1991-04-28,43,75;" +
    "1991-04-29,46,82;" +
    "1991-04-29,50,77;" +
    "1991-04-30,45,81;" +
    "1991-04-30,40,80;" +
    "1991-05-01,43,61;" +
    "1991-05-01,40,48;" +
    "1991-05-02,45,61;" +
    "1991-05-02,46,54;" +
    "1991-05-03,46,65;" +
    "1991-05-03,45,60;" +
    "1991-05-04,45,78;" +
    "1991-05-04,47,74;" +
    "1991-05-05,46,81;" +
    "1991-05-05,50,76;" +
    "1991-05-06,50,78;" +
    "1991-05-06,50,72;" +
    "1991-05-07,49,81;" +
    "1991-05-07,50,76;" +
    "1991-05-08,54,71;" +
    "1991-05-08,46,60;" +
    "1991-05-09,43,68;" +
    "1991-05-09,43,63;" +
    "1991-05-10,46,73;" +
    "1991-05-10,47,69;" +
    "1991-05-11,43,68;" +
    "1991-05-11,46,62;" +
    "1991-05-12,50,65;" +
    "1991-05-12,48,59;" +
    "1991-05-13,49,63;" +
    "1991-05-13,49,57;" +
    "1991-05-14,50,74;" +
    "1991-05-14,48,69;" +
    "1991-05-15,45,82;" +
    "1991-05-15,47,78;" +
    "1991-05-16,48,68;" +
    "1991-05-16,47,62;" +
    "1991-05-17,48,64;" +
    "1991-05-17,45,59;" +
    "1991-05-18,44,58;" +
    "1991-05-18,42,55;" +
    "1991-05-19,42,73;" +
    "1991-05-19,44,62;" +
    "1991-05-20,44,75;" +
    "1991-05-20,44,50;" +
    "1991-05-21,47,82;" +
    "1991-05-21,47,77;" +
    "1991-05-22,50,81;" +
    "1991-05-22,49,74;" +
    "1991-05-23,48,87;" +
    "1991-05-23,49,83;" +
    "1991-05-24,54,91;" +
    "1991-05-24,58,86;" +
    "1991-05-25,53,85;" +
    "1991-05-25,47,73;" +
    "1991-05-26,45,75;" +
    "1991-05-26,45,69;" +
    "1991-05-27,46,81;" +
    "1991-05-27,47,76;" +
    "1991-05-28,46,71;" +
    "1991-05-28,47,65;" +
    "1991-05-29,46,65;" +
    "1991-05-29,47,59;" +
    "1991-05-30,52,70;" +
    "1991-05-30,50,67;" +
    "1991-05-31,47,84;" +
    "1991-05-31,52,80;" +
    "1991-06-01,53,92;" +
    "1991-06-01,56,87;" +
    "1991-06-02,53,80;" +
    "1991-06-02,52,74;" +
    "1991-06-03,50,79;" +
    "1991-06-03,49,75;" +
    "1991-06-04,46,85;" +
    "1991-06-04,45,82;" +
    "1991-06-05,61,86;" +
    "1991-06-05,49,81;" +
    "1991-06-06,48,75;" +
    "1991-06-06,47,70;" +
    "1991-06-07,47,90;" +
    "1991-06-07,46,87;" +
    "1991-06-08,58,92;" +
    "1991-06-08,50,86;" +
    "1991-06-09,53,91;" +
    "1991-06-09,53,87;" +
    "1991-06-10,52,95;" +
    "1991-06-10,51,90;" +
    "1991-06-11,57,89;" +
    "1991-06-11,56,84;" +
    "1991-06-12,53,84;" +
    "1991-06-12,51,73;" +
    "1991-06-13,49,80;" +
    "1991-06-13,48,76;" +
    "1991-06-14,49,86;" +
    "1991-06-14,48,82;" +
    "1991-06-15,52,80;" +
    "1991-06-15,50,75;" +
    "1991-06-16,49,79;" +
    "1991-06-16,49,73;" +
    "1991-06-17,48,86;" +
    "1991-06-17,49,81;" +
    "1991-06-18,49,76;" +
    "1991-06-18,48,66;" +
    "1991-06-19,52,68;" +
    "1991-06-19,48,64;" +
    "1991-06-20,46,75;" +
    "1991-06-20,46,70;" +
    "1991-06-21,48,69;" +
    "1991-06-21,46,65;" +
    "1991-06-22,51,71;" +
    "1991-06-22,49,66;" +
    "1991-06-23,51,67;" +
    "1991-06-23,49,62;" +
    "1991-06-24,52,62;" +
    "1991-06-24,50,56;" +
    "1991-06-25,53,71;" +
    "1991-06-25,51,65;" +
    "1991-06-26,52,66;" +
    "1991-06-26,50,61;" +
    "1991-06-27,54,68;" +
    "1991-06-27,53,67;" +
    "1991-06-28,58,68;" +
    "1991-06-28,55,63;" +
    "1991-06-29,55,76;" +
    "1991-06-29,54,73;" +
    "1991-06-30,52,91;" +
    "1991-06-30,52,88;" +
    "1991-07-01,60,100;" +
    "1991-07-01,64,100;" +
    "1991-07-02,64,107;" +
    "1991-07-02,71,104;" +
    "1991-07-03,66,107;" +
    "1991-07-03,68,102;" +
    "1991-07-04,64,105;" +
    "1991-07-04,62,99;" +
    "1991-07-05,59,100;" +
    "1991-07-05,58,92;" +
    "1991-07-06,56,92;" +
    "1991-07-06,55,89;" +
    "1991-07-07,56,83;" +
    "1991-07-07,54,79;" +
    "1991-07-08,55,82;" +
    "1991-07-08,52,77;" +
    "1991-07-09,54,76;" +
    "1991-07-09,52,70;" +
    "1991-07-10,52,85;" +
    "1991-07-10,53,79;" +
    "1991-07-11,51,93;" +
    "1991-07-11,50,89;" +
    "1991-07-12,54,87;" +
    "1991-07-12,53,81;" +
    "1991-07-13,55,83;" +
    "1991-07-13,54,81;" +
    "1991-07-14,54,76;" +
    "1991-07-14,52,72;" +
    "1991-07-15,51,80;" +
    "1991-07-15,51,74;" +
    "1991-07-16,58,78;" +
    "1991-07-16,56,73;" +
    "1991-07-17,57,84;" +
    "1991-07-17,54,80;" +
    "1991-07-18,54,85;" +
    "1991-07-18,53,80;" +
    "1991-07-19,56,78;" +
    "1991-07-19,55,74;" +
    "1991-07-20,56,79;" +
    "1991-07-20,54,75;" +
    "1991-07-21,55,88;" +
    "1991-07-21,54,84;" +
    "1991-07-22,57,92;" +
    "1991-07-22,56,88;" +
    "1991-07-23,56,87;" +
    "1991-07-23,55,84;" +
    "1991-07-24,57,78;" +
    "1991-07-24,54,70;" +
    "1991-07-25,53,89;" +
    "1991-07-25,53,84;" +
    "1991-07-26,54,94;" +
    "1991-07-26,55,89;" +
    "1991-07-27,57,90;" +
    "1991-07-27,56,85;" +
    "1991-07-28,57,92;" +
    "1991-07-28,56,89;" +
    "1991-07-29,58,101;" +
    "1991-07-29,57,100;" +
    "1991-07-30,63,98;" +
    "1991-07-30,62,94;" +
    "1991-07-31,57,90;" +
    "1991-07-31,55,81;" +
    "1991-08-01,56,87;" +
    "1991-08-01,54,83;" +
    "1991-08-02,53,78;" +
    "1991-08-02,54,69;" +
    "1991-08-03,55,72;" +
    "1991-08-03,54,68;" +
    "1991-08-04,56,75;" +
    "1991-08-04,54,70;" +
    "1991-08-05,56,78;" +
    "1991-08-05,56,74;" +
    "1991-08-06,57,76;" +
    "1991-08-06,56,73;" +
    "1991-08-07,60,87;" +
    "1991-08-07,58,84;" +
    "1991-08-08,57,94;" +
    "1991-08-08,57,90;" +
    "1991-08-09,58,88;" +
    "1991-08-09,55,83;" +
    "1991-08-10,53,95;" +
    "1991-08-10,54,95;" +
    "1991-08-11,59,92;" +
    "1991-08-11,59,87;" +
    "1991-08-12,58,90;" +
    "1991-08-12,57,85;" +
    "1991-08-13,64,93;" +
    "1991-08-13,63,94;" +
    "1991-08-14,63,92;" +
    "1991-08-14,57,81;" +
    "1991-08-15,57,83;" +
    "1991-08-15,55,72;" +
    "1991-08-16,56,81;" +
    "1991-08-16,55,78;" +
    "1991-08-17,57,76;" +
    "1991-08-17,55,71;" +
    "1991-08-18,56,79;" +
    "1991-08-18,55,75;" +
    "1991-08-19,57,80;" +
    "1991-08-19,55,77;" +
    "1991-08-20,56,83;" +
    "1991-08-20,54,79;" +
    "1991-08-21,56,85;" +
    "1991-08-21,54,81;" +
    "1991-08-22,53,80;" +
    "1991-08-22,52,77;" +
    "1991-08-23,55,93;" +
    "1991-08-23,54,93;" +
    "1991-08-24,54,94;" +
    "1991-08-24,55,91;" +
    "1991-08-25,54,80;" +
    "1991-08-25,54,74;" +
    "1991-08-26,52,72;" +
    "1991-08-26,53,68;" +
    "1991-08-27,56,73;" +
    "1991-08-27,55,70;" +
    "1991-08-28,56,85;" +
    "1991-08-28,10,82;" +
    "1991-08-29,57,90;" +
    "1991-08-29,58,87;" +
    "1991-08-30,57,84;" +
    "1991-08-30,57,77;" +
    "1991-08-31,58,83;" +
    "1991-08-31,57,78;" +
    "1991-09-01,55,92;" +
    "1991-09-01,57,90;" +
    "1991-09-02,57,98;" +
    "1991-09-02,64,100;" +
    "1991-09-03,57,98;" +
    "1991-09-03,65,93;" +
    "1991-09-04,60,101;" +
    "1991-09-04,61,98;" +
    "1991-09-05,61,94;" +
    "1991-09-05,60,90;" +
    "1991-09-06,59,83;" +
    "1991-09-06,57,78;" +
    "1991-09-07,60,80;" +
    "1991-09-07,56,77;" +
    "1991-09-08,55,88;" +
    "1991-09-08,53,85;" +
    "1991-09-09,53,72;" +
    "1991-09-09,52,68;" +
    "1991-09-10,49,80;" +
    "1991-09-10,49,78;" +
    "1991-09-11,53,86;" +
    "1991-09-11,53,85;" +
    "1991-09-12,53,90;" +
    "1991-09-12,52,87;" +
    "1991-09-13,53,83;" +
    "1991-09-13,52,80;" +
    "1991-09-14,53,84;" +
    "1991-09-14,52,81;" +
    "1991-09-15,53,92;" +
    "1991-09-15,52,90;" +
    "1991-09-16,54,97;" +
    "1991-09-16,57,98;" +
    "1991-09-17,56,99;" +
    "1991-09-17,59,93;" +
    "1991-09-18,55,97;" +
    "1991-09-18,54,94;" +
    "1991-09-19,55,85;" +
    "1991-09-19,54,81;" +
    "1991-09-20,57,87;" +
    "1991-09-20,56,84;" +
    "1991-09-21,52,92;" +
    "1991-09-22,54,94;" +
    "1991-09-23,54,99;" +
    "1991-09-23,57,97;" +
    "1991-09-24,56,100;" +
    "1991-09-24,70,101;" +
    "1991-09-25,63,92;" +
    "1991-09-25,56,86;" +
    "1991-09-26,56,81;" +
    "1991-09-26,54,73;" +
    "1991-09-27,55,79;" +
    "1991-09-27,54,76;" +
    "1991-09-28,51,91;" +
    "1991-09-28,52,90;" +
    "1991-09-29,55,94;" +
    "1991-09-29,59,97;" +
    "1991-09-30,55,89;" +
    "1991-09-30,56,85;" +
    "1991-10-01,55,89;" +
    "1991-10-01,55,87;" +
    "1991-10-02,56,98;" +
    "1991-10-02,59,98;" +
    "1991-10-03,58,99;" +
    "1991-10-03,66,99;" +
    "1991-10-04,58,93;" +
    "1991-10-04,57,91;" +
    "1991-10-05,56,84;" +
    "1991-10-05,55,81;" +
    "1991-10-06,53,87;" +
    "1991-10-06,62,85;" +
    "1991-10-07,52,92;" +
    "1991-10-07,57,90;" +
    "1991-10-08,52,90;" +
    "1991-10-08,56,90;" +
    "1991-10-09,53,92;" +
    "1991-10-09,58,92;" +
    "1991-10-10,57,96;" +
    "1991-10-10,66,99;" +
    "1991-10-11,62,91;" +
    "1991-10-11,66,88;" +
    "1991-10-12,58,94;" +
    "1991-10-12,63,93;" +
    "1991-10-13,56,95;" +
    "1991-10-13,62,95;" +
    "1991-10-14,56,97;" +
    "1991-10-14,64,97;" +
    "1991-10-15,55,95;" +
    "1991-10-15,64,94;" +
    "1991-10-16,53,92;" +
    "1991-10-16,54,91;" +
    "1991-10-17,53,82;" +
    "1991-10-17,53,80;" +
    "1991-10-18,51,90;" +
    "1991-10-18,53,90;" +
    "1991-10-19,53,91;" +
    "1991-10-19,58,90;" +
    "1991-10-20,58,88;" +
    "1991-10-20,67,89;" +
    "1991-10-21,53,87;" +
    "1991-10-21,58,86;" +
    "1991-10-22,58,68;" +
    "1991-10-22,51,67;" +
    "1991-10-23,43,69;" +
    "1991-10-23,46,67;" +
    "1991-10-24,40,65;" +
    "1991-10-24,43,62;" +
    "1991-10-25,54,68;" +
    "1991-10-25,54,65;" +
    "1991-10-26,55,60;" +
    "1991-10-26,47,58;" +
    "1991-10-27,41,64;" +
    "1991-10-27,43,61;" +
    "1991-10-28,42,65;" +
    "1991-10-28,47,64;" +
    "1991-10-29,48,62;" +
    "1991-10-29,49,61;" +
    "1991-10-30,44,62;" +
    "1991-10-30,50,61;" +
    "1991-10-31,41,64;" +
    "1991-10-31,48,65;" +
    "1991-11-01,38,69;" +
    "1991-11-01,43,70;" +
    "1991-11-02,40,72;" +
    "1991-11-02,45,72;" +
    "1991-11-03,41,72;" +
    "1991-11-03,47,74;" +
    "1991-11-04,42,78;" +
    "1991-11-04,50,80;" +
    "1991-11-05,44,79;" +
    "1991-11-05,50,82;" +
    "1991-11-06,47,78;" +
    "1991-11-06,66,83;" +
    "1991-11-07,50,74;" +
    "1991-11-07,61,73;" +
    "1991-11-08,56,66;" +
    "1991-11-08,54,65;" +
    "1991-11-09,55,71;" +
    "1991-11-09,52,70;" +
    "1991-11-10,46,75;" +
    "1991-11-10,7,73;" +
    "1991-11-11,44,73;" +
    "1991-11-11,50,67;" +
    "1991-11-12,44,71;" +
    "1991-11-12,50,72;" +
    "1991-11-13,47,70;" +
    "1991-11-13,49,67;" +
    "1991-11-14,44,63;" +
    "1991-11-14,43,50;" +
    "1991-11-15,50,63;" +
    "1991-11-16,33,68;" +
    "1991-11-17,53,61;" +
    "1991-11-18,46,62;" +
    "1991-11-18,44,61;" +
    "1991-11-19,38,64;" +
    "1991-11-19,45,54;" +
    "1991-11-20,42,62;" +
    "1991-11-20,45,62;" +
    "1991-11-21,48,63;" +
    "1991-11-21,48,51;" +
    "1991-11-22,47,60;" +
    "1991-11-22,47,59;" +
    "1991-11-23,41,59;" +
    "1991-11-23,46,60;" +
    "1991-11-24,38,65;" +
    "1991-11-24,47,66;" +
    "1991-11-25,39,70;" +
    "1991-11-25,44,71;" +
    "1991-11-26,38,69;" +
    "1991-11-26,45,69;" +
    "1991-11-27,46,62;" +
    "1991-11-27,49,59;" +
    "1991-11-28,46,63;" +
    "1991-11-28,45,62;" +
    "1991-11-29,33,57;" +
    "1991-11-29,38,54;" +
    "1991-11-30,33,60;" +
    "1991-11-30,45,58;" +
    "1991-12-01,48,58;" +
    "1991-12-02,34,60;" +
    "1991-12-02,45,60;" +
    "1991-12-03,35,58;" +
    "1991-12-03,40,59;" +
    "1991-12-04,32,61;" +
    "1991-12-04,38,62;" +
    "1991-12-05,32,60;" +
    "1991-12-05,40,61;" +
    "1991-12-06,35,60;" +
    "1991-12-06,36,59;" +
    "1991-12-07,48,56;" +
    "1991-12-07,48,55;" +
    "1991-12-08,45,59;" +
    "1991-12-08,46,59;" +
    "1991-12-09,37,64;" +
    "1991-12-09,42,68;" +
    "1991-12-10,31,63;" +
    "1991-12-10,38,63;" +
    "1991-12-11,43,61;" +
    "1991-12-11,43,64;" +
    "1991-12-12,31,57;" +
    "1991-12-12,33,58;" +
    "1991-12-13,34,56;" +
    "1991-12-13,32,59;" +
    "1991-12-14,37,43;" +
    "1991-12-14,35,43;" +
    "1991-12-15,35,45;" +
    "1991-12-15,34,46;" +
    "1991-12-16,30,40;" +
    "1991-12-16,29,40;" +
    "1991-12-17,33,49;" +
    "1991-12-17,34,55;" +
    "1991-12-18,44,59;" +
    "1991-12-18,44,57;" +
    "1991-12-19,33,54;" +
    "1991-12-19,35,52;" +
    "1991-12-20,39,53;" +
    "1991-12-20,40,54;" +
    "1991-12-21,34,53;" +
    "1991-12-21,36,52;" +
    "1991-12-22,33,52;" +
    "1991-12-22,36,53;" +
    "1991-12-23,34,62;" +
    "1991-12-23,41,63;" +
    "1991-12-24,34,62;" +
    "1991-12-24,41,65;" +
    "1991-12-25,35,56;" +
    "1991-12-25,42,58;" +
    "1991-12-26,40,58;" +
    "1991-12-26,43,58;" +
    "1991-12-27,44,64;" +
    "1991-12-27,48,62;" +
    "1991-12-28,44,64;" +
    "1991-12-28,47,54;" +
    "1991-12-29,44,53;" +
    "1991-12-29,45,51;" +
    "1991-12-30,44,52;" +
    "1991-12-30,45,52;" +
    "1991-12-31,39,54;" +
    "1991-12-31,40,53;"