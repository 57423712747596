export const data = "2015-01-01,32,52;" +
    "2015-01-01,30,50;" +
    "2015-01-02,30,53;" +
    "2015-01-02,27,51;" +
    "2015-01-03,30,56;" +
    "2015-01-03,28,54;" +
    "2015-01-04,33,56;" +
    "2015-01-04,32,53;" +
    "2015-01-05,37,58;" +
    "2015-01-05,35,55;" +
    "2015-01-06,38,63;" +
    "2015-01-06,36,60;" +
    "2015-01-07,38,70;" +
    "2015-01-07,36,67;" +
    "2015-01-08,41,64;" +
    "2015-01-08,39,62;" +
    "2015-01-09,47,67;" +
    "2015-01-09,45,67;" +
    "2015-01-10,46,67;" +
    "2015-01-10,45,66;" +
    "2015-01-11,40,66;" +
    "2015-01-11,38,65;" +
    "2015-01-12,40,65;" +
    "2015-01-12,38,65;" +
    "2015-01-13,41,62;" +
    "2015-01-13,41,60;" +
    "2015-01-14,36,57;" +
    "2015-01-14,34,54;" +
    "2015-01-15,39,60;" +
    "2015-01-15,36,57;" +
    "2015-01-16,42,65;" +
    "2015-01-16,40,62;" +
    "2015-01-17,49,56;" +
    "2015-01-17,48,54;" +
    "2015-01-18,48,69;" +
    "2015-01-18,45,68;" +
    "2015-01-19,43,59;" +
    "2015-01-19,49,60;" +
    "2015-01-20,41,62;" +
    "2015-01-20,39,60;" +
    "2015-01-21,37,61;" +
    "2015-01-21,36,58;" +
    "2015-01-22,39,53;" +
    "2015-01-22,39,51;" +
    "2015-01-23,36,68;" +
    "2015-01-23,36,64;" +
    "2015-01-24,40,68;" +
    "2015-01-24,40,63;" +
    "2015-01-25,42,66;" +
    "2015-01-25,39,63;" +
    "2015-01-26,38,66;" +
    "2015-01-26,36,64;" +
    "2015-01-27,45,69;" +
    "2015-01-27,49,68;" +
    "2015-01-28,41,67;" +
    "2015-01-28,40,66;" +
    "2015-01-29,41,68;" +
    "2015-01-29,40,66;" +
    "2015-01-30,39,71;" +
    "2015-01-30,39,71;" +
    "2015-01-31,41,74;" +
    "2015-01-31,43,71;" +
    "2015-02-01,37,68;" +
    "2015-02-01,37,67;" +
    "2015-02-02,42,67;" +
    "2015-02-02,40,64;" +
    "2015-02-03,47,69;" +
    "2015-02-03,45,67;" +
    "2015-02-04,43,69;" +
    "2015-02-04,41,67;" +
    "2015-02-05,47,65;" +
    "2015-02-05,45,64;" +
    "2015-02-06,56,68;" +
    "2015-02-06,52,66;" +
    "2015-02-07,53,69;" +
    "2015-02-07,57,69;" +
    "2015-02-08,50,67;" +
    "2015-02-08,49,63;" +
    "2015-02-09,49,67;" +
    "2015-02-09,53,68;" +
    "2015-02-10,40,64;" +
    "2015-02-10,40,63;" +
    "2015-02-11,40,66;" +
    "2015-02-11,39,63;" +
    "2015-02-12,45,70;" +
    "2015-02-12,43,68;" +
    "2015-02-13,45,72;" +
    "2015-02-13,42,71;" +
    "2015-02-14,44,74;" +
    "2015-02-14,43,72;" +
    "2015-02-15,42,77;" +
    "2015-02-15,41,73;" +
    "2015-02-16,43,71;" +
    "2015-02-16,41,69;" +
    "2015-02-17,54,59;" +
    "2015-02-17,51,64;" +
    "2015-02-18,50,68;" +
    "2015-02-18,49,66;" +
    "2015-02-19,49,66;" +
    "2015-02-19,44,65;" +
    "2015-02-20,46,71;" +
    "2015-02-20,48,69;" +
    "2015-02-21,41,68;" +
    "2015-02-21,40,66;" +
    "2015-02-22,45,65;" +
    "2015-02-22,44,63;" +
    "2015-02-23,38,62;" +
    "2015-02-23,44,60;" +
    "2015-02-24,33,69;" +
    "2015-02-24,32,68;" +
    "2015-02-25,38,71;" +
    "2015-02-25,37,70;" +
    "2015-02-26,42,73;" +
    "2015-02-26,41,73;" +
    "2015-02-27,47,63;" +
    "2015-02-27,48,63;" +
    "2015-02-28,42,62;" +
    "2015-02-28,42,60;" +
    "2015-03-01,37,64;" +
    "2015-03-01,38,62;" +
    "2015-03-02,39,62;" +
    "2015-03-02,38,60;" +
    "2015-03-03,37,66;" +
    "2015-03-03,37,65;" +
    "2015-03-04,37,68;" +
    "2015-03-04,36,67;" +
    "2015-03-05,37,69;" +
    "2015-03-05,36,68;" +
    "2015-03-06,36,74;" +
    "2015-03-06,37,72;" +
    "2015-03-07,39,75;" +
    "2015-03-07,39,74;" +
    "2015-03-08,40,77;" +
    "2015-03-08,41,76;" +
    "2015-03-09,49,78;" +
    "2015-03-09,47,77;" +
    "2015-03-10,44,75;" +
    "2015-03-10,44,75;" +
    "2015-03-11,48,70;" +
    "2015-03-11,54,68;" +
    "2015-03-12,44,75;" +
    "2015-03-12,44,73;" +
    "2015-03-13,47,77;" +
    "2015-03-13,47,76;" +
    "2015-03-14,54,85;" +
    "2015-03-14,52,83;" +
    "2015-03-15,49,81;" +
    "2015-03-15,53,80;" +
    "2015-03-16,49,73;" +
    "2015-03-16,48,70;" +
    "2015-03-17,49,73;" +
    "2015-03-17,52,72;" +
    "2015-03-18,43,76;" +
    "2015-03-18,43,75;" +
    "2015-03-19,44,82;" +
    "2015-03-19,43,82;" +
    "2015-03-20,44,76;" +
    "2015-03-20,44,77;" +
    "2015-03-21,53,76;" +
    "2015-03-21,49,74;" +
    "2015-03-22,55,70;" +
    "2015-03-22,53,69;" +
    "2015-03-23,51,67;" +
    "2015-03-23,49,66;" +
    "2015-03-24,50,70;" +
    "2015-03-24,47,70;" +
    "2015-03-25,46,78;" +
    "2015-03-25,45,76;" +
    "2015-03-26,49,83;" +
    "2015-03-26,47,82;" +
    "2015-03-27,51,79;" +
    "2015-03-27,50,80;" +
    "2015-03-28,47,83;" +
    "2015-03-28,46,82;" +
    "2015-03-29,44,86;" +
    "2015-03-29,44,85;" +
    "2015-03-30,45,80;" +
    "2015-03-30,46,80;" +
    "2015-03-31,52,71;" +
    "2015-03-31,52,70;" +
    "2015-04-01,39,68;" +
    "2015-04-01,39,68;" +
    "2015-04-02,42,73;" +
    "2015-04-02,41,71;" +
    "2015-04-03,38,77;" +
    "2015-04-03,39,76;" +
    "2015-04-04,41,69;" +
    "2015-04-04,41,68;" +
    "2015-04-05,44,63;" +
    "2015-04-05,43,60;" +
    "2015-04-06,39,65;" +
    "2015-04-06,37,64;" +
    "2015-04-07,50,62;" +
    "2015-04-07,47,60;" +
    "2015-04-08,43,64;" +
    "2015-04-08,45,64;" +
    "2015-04-09,39,70;" +
    "2015-04-09,38,70;" +
    "2015-04-10,43,74;" +
    "2015-04-10,42,74;" +
    "2015-04-11,43,75;" +
    "2015-04-11,42,73;" +
    "2015-04-12,47,82;" +
    "2015-04-12,46,81;" +
    "2015-04-13,47,69;" +
    "2015-04-13,46,78;" +
    "2015-04-14,45,67;" +
    "2015-04-14,42,65;" +
    "2015-04-15,41,75;" +
    "2015-04-15,41,73;" +
    "2015-04-16,50,81;" +
    "2015-04-16,53,79;" +
    "2015-04-17,47,84;" +
    "2015-04-17,46,83;" +
    "2015-04-18,47,85;" +
    "2015-04-18,46,84;" +
    "2015-04-19,51,80;" +
    "2015-04-19,48,79;" +
    "2015-04-20,53,73;" +
    "2015-04-20,51,73;" +
    "2015-04-21,53,69;" +
    "2015-04-21,51,67;" +
    "2015-04-22,52,75;" +
    "2015-04-22,47,74;" +
    "2015-04-23,53,75;" +
    "2015-04-23,49,74;" +
    "2015-04-24,54,67;" +
    "2015-04-24,51,66;" +
    "2015-04-25,52,67;" +
    "2015-04-25,51,64;" +
    "2015-04-26,48,76;" +
    "2015-04-26,47,75;" +
    "2015-04-27,49,87;" +
    "2015-04-27,48,85;" +
    "2015-04-28,53,77;" +
    "2015-04-28,50,84;" +
    "2015-04-29,51,80;" +
    "2015-04-29,48,80;" +
    "2015-04-30,51,90;" +
    "2015-04-30,49,88;" +
    "2015-05-01,54,90;" +
    "2015-05-01,49,89;" +
    "2015-05-02,54,83;" +
    "2015-05-02,51,83;" +
    "2015-05-03,51,74;" +
    "2015-05-03,49,78;" +
    "2015-05-04,51,69;" +
    "2015-05-04,51,68;" +
    "2015-05-05,50,72;" +
    "2015-05-05,46,71;" +
    "2015-05-06,50,75;" +
    "2015-05-06,48,74;" +
    "2015-05-07,50,63;" +
    "2015-05-07,47,68;" +
    "2015-05-08,45,75;" +
    "2015-05-08,44,75;" +
    "2015-05-09,53,74;" +
    "2015-05-09,50,73;" +
    "2015-05-10,52,74;" +
    "2015-05-10,50,73;" +
    "2015-05-11,52,68;" +
    "2015-05-11,48,66;" +
    "2015-05-12,51,67;" +
    "2015-05-12,48,66;" +
    "2015-05-13,51,68;" +
    "2015-05-13,48,66;" +
    "2015-05-14,53,69;" +
    "2015-05-14,50,67;" +
    "2015-05-15,51,67;" +
    "2015-05-15,48,67;" +
    "2015-05-16,55,70;" +
    "2015-05-16,51,69;" +
    "2015-05-17,55,68;" +
    "2015-05-17,52,66;" +
    "2015-05-18,55,67;" +
    "2015-05-18,52,63;" +
    "2015-05-19,55,69;" +
    "2015-05-19,52,69;" +
    "2015-05-20,55,68;" +
    "2015-05-20,52,68;" +
    "2015-05-21,54,73;" +
    "2015-05-21,52,70;" +
    "2015-05-22,56,71;" +
    "2015-05-22,54,69;" +
    "2015-05-23,54,71;" +
    "2015-05-23,53,69;" +
    "2015-05-24,51,74;" +
    "2015-05-24,48,73;" +
    "2015-05-25,55,72;" +
    "2015-05-25,53,71;" +
    "2015-05-26,56,72;" +
    "2015-05-26,53,71;" +
    "2015-05-27,55,75;" +
    "2015-05-27,54,75;" +
    "2015-05-28,54,78;" +
    "2015-05-28,52,78;" +
    "2015-05-29,54,80;" +
    "2015-05-29,52,81;" +
    "2015-05-30,51,87;" +
    "2015-05-30,50,86;" +
    "2015-05-31,54,76;" +
    "2015-05-31,52,81;" +
    "2015-06-01,55,77;" +
    "2015-06-01,52,76;" +
    "2015-06-02,56,77;" +
    "2015-06-02,57,77;" +
    "2015-06-03,54,75;" +
    "2015-06-03,50,72;" +
    "2015-06-04,55,81;" +
    "2015-06-04,52,79;" +
    "2015-06-05,55,88;" +
    "2015-06-05,53,86;" +
    "2015-06-06,58,86;" +
    "2015-06-06,55,84;" +
    "2015-06-07,58,96;" +
    "2015-06-07,56,95;" +
    "2015-06-08,64,106;" +
    "2015-06-08,62,103;" +
    "2015-06-09,67,89;" +
    "2015-06-09,65,101;" +
    "2015-06-10,64,74;" +
    "2015-06-10,63,72;" +
    "2015-06-11,61,92;" +
    "2015-06-11,59,91;" +
    "2015-06-12,63,101;" +
    "2015-06-12,60,99;" +
    "2015-06-13,59,94;" +
    "2015-06-13,59,94;" +
    "2015-06-14,56,82;" +
    "2015-06-14,54,82;" +
    "2015-06-15,54,76;" +
    "2015-06-15,52,76;" +
    "2015-06-16,53,90;" +
    "2015-06-16,50,89;" +
    "2015-06-17,54,94;" +
    "2015-06-17,52,93;" +
    "2015-06-18,54,88;" +
    "2015-06-18,53,87;" +
    "2015-06-19,53,92;" +
    "2015-06-19,51,90;" +
    "2015-06-20,54,94;" +
    "2015-06-20,53,93;" +
    "2015-06-21,56,83;" +
    "2015-06-21,53,89;" +
    "2015-06-22,53,83;" +
    "2015-06-22,51,83;" +
    "2015-06-23,54,88;" +
    "2015-06-23,52,87;" +
    "2015-06-24,56,93;" +
    "2015-06-24,52,92;" +
    "2015-06-25,60,101;" +
    "2015-06-25,59,100;" +
    "2015-06-26,63,99;" +
    "2015-06-26,63,99;" +
    "2015-06-27,60,91;" +
    "2015-06-27,57,89;" +
    "2015-06-28,61,84;" +
    "2015-06-28,61,83;" +
    "2015-06-29,60,94;" +
    "2015-06-29,57,93;" +
    "2015-06-30,64,108;" +
    "2015-06-30,62,106;" +
    "2015-07-01,71,105;" +
    "2015-07-01,68,103;" +
    "2015-07-02,67,91;" +
    "2015-07-02,69,92;" +
    "2015-07-03,63,94;" +
    "2015-07-03,61,93;" +
    "2015-07-04,60,93;" +
    "2015-07-04,59,93;" +
    "2015-07-05,62,84;" +
    "2015-07-05,59,84;" +
    "2015-07-06,62,84;" +
    "2015-07-06,60,83;" +
    "2015-07-07,62,82;" +
    "2015-07-07,62,80;" +
    "2015-07-08,63,78;" +
    "2015-07-08,59,76;" +
    "2015-07-09,62,76;" +
    "2015-07-09,58,74;" +
    "2015-07-10,62,79;" +
    "2015-07-10,59,77;" +
    "2015-07-11,62,81;" +
    "2015-07-11,58,79;" +
    "2015-07-12,63,82;" +
    "2015-07-12,59,79;" +
    "2015-07-13,63,86;" +
    "2015-07-13,59,84;" +
    "2015-07-14,63,80;" +
    "2015-07-14,59,79;" +
    "2015-07-15,62,92;" +
    "2015-07-15,58,91;" +
    "2015-07-16,61,94;" +
    "2015-07-16,57,93;" +
    "2015-07-17,61,94;" +
    "2015-07-17,58,93;" +
    "2015-07-18,61,88;" +
    "2015-07-18,57,87;" +
    "2015-07-19,65,95;" +
    "2015-07-19,62,92;" +
    "2015-07-20,66,95;" +
    "2015-07-20,66,95;" +
    "2015-07-21,63,87;" +
    "2015-07-21,60,86;" +
    "2015-07-22,60,79;" +
    "2015-07-22,58,79;" +
    "2015-07-23,59,80;" +
    "2015-07-23,56,79;" +
    "2015-07-24,57,89;" +
    "2015-07-24,54,88;" +
    "2015-07-25,59,85;" +
    "2015-07-25,54,84;" +
    "2015-07-26,61,87;" +
    "2015-07-26,58,86;" +
    "2015-07-27,59,96;" +
    "2015-07-27,55,95;" +
    "2015-07-28,60,104;" +
    "2015-07-28,57,101;" +
    "2015-07-29,66,107;" +
    "2015-07-29,62,105;" +
    "2015-07-30,64,97;" +
    "2015-07-30,61,97;" +
    "2015-07-31,64,87;" +
    "2015-07-31,60,90;" +
    "2015-08-01,64,88;" +
    "2015-08-01,59,87;" +
    "2015-08-02,63,85;" +
    "2015-08-02,60,85;" +
    "2015-08-03,64,82;" +
    "2015-08-03,61,81;" +
    "2015-08-04,63,79;" +
    "2015-08-04,60,76;" +
    "2015-08-05,59,92;" +
    "2015-08-05,55,91;" +
    "2015-08-06,60,88;" +
    "2015-08-06,58,88;" +
    "2015-08-07,63,88;" +
    "2015-08-07,59,86;" +
    "2015-08-08,62,82;" +
    "2015-08-08,59,80;" +
    "2015-08-09,58,89;" +
    "2015-08-09,54,88;" +
    "2015-08-10,62,83;" +
    "2015-08-10,58,81;" +
    "2015-08-11,61,86;" +
    "2015-08-11,58,84;" +
    "2015-08-12,59,88;" +
    "2015-08-12,55,87;" +
    "2015-08-13,64,86;" +
    "2015-08-13,62,83;" +
    "2015-08-14,64,86;" +
    "2015-08-14,62,86;" +
    "2015-08-15,59,101;" +
    "2015-08-15,55,100;" +
    "2015-08-16,65,106;" +
    "2015-08-16,63,104;" +
    "2015-08-17,65,104;" +
    "2015-08-17,63,102;" +
    "2015-08-18,61,93;" +
    "2015-08-18,58,93;" +
    "2015-08-19,61,86;" +
    "2015-08-19,59,85;" +
    "2015-08-20,63,83;" +
    "2015-08-20,62,83;" +
    "2015-08-21,63,85;" +
    "2015-08-21,59,83;" +
    "2015-08-22,61,87;" +
    "2015-08-22,58,86;" +
    "2015-08-23,61,85;" +
    "2015-08-23,58,84;" +
    "2015-08-24,60,90;" +
    "2015-08-24,57,90;" +
    "2015-08-25,60,93;" +
    "2015-08-25,56,91;" +
    "2015-08-26,61,98;" +
    "2015-08-26,56,96;" +
    "2015-08-27,64,102;" +
    "2015-08-27,62,99;" +
    "2015-08-28,65,102;" +
    "2015-08-28,63,100;" +
    "2015-08-29,65,83;" +
    "2015-08-29,63,85;" +
    "2015-08-30,64,85;" +
    "2015-08-30,60,83;" +
    "2015-08-31,61,94;" +
    "2015-08-31,55,91;" +
    "2015-09-01,59,88;" +
    "2015-09-01,54,87;" +
    "2015-09-02,61,81;" +
    "2015-09-02,58,78;" +
    "2015-09-03,57,79;" +
    "2015-09-03,58,77;" +
    "2015-09-04,52,79;" +
    "2015-09-04,50,75;" +
    "2015-09-05,50,86;" +
    "2015-09-05,47,82;" +
    "2015-09-06,50,92;" +
    "2015-09-06,49,90;" +
    "2015-09-07,53,98;" +
    "2015-09-07,51,96;" +
    "2015-09-08,58,104;" +
    "2015-09-08,55,98;" +
    "2015-09-09,63,107;" +
    "2015-09-09,59,103;" +
    "2015-09-10,67,108;" +
    "2015-09-10,64,106;" +
    "2015-09-11,68,105;" +
    "2015-09-11,65,102;" +
    "2015-09-12,62,85;" +
    "2015-09-12,59,95;" +
    "2015-09-13,64,94;" +
    "2015-09-13,64,93;" +
    "2015-09-14,63,79;" +
    "2015-09-14,59,83;" +
    "2015-09-15,60,77;" +
    "2015-09-15,57,75;" +
    "2015-09-16,59,79;" +
    "2015-09-16,56,76;" +
    "2015-09-17,59,82;" +
    "2015-09-17,57,79;" +
    "2015-09-18,54,89;" +
    "2015-09-18,51,87;" +
    "2015-09-19,54,96;" +
    "2015-09-19,51,91;" +
    "2015-09-20,56,102;" +
    "2015-09-20,54,98;" +
    "2015-09-21,65,103;" +
    "2015-09-21,63,101;" +
    "2015-09-22,58,81;" +
    "2015-09-22,58,92;" +
    "2015-09-23,54,92;" +
    "2015-09-23,49,91;" +
    "2015-09-24,60,98;" +
    "2015-09-24,57,94;" +
    "2015-09-25,60,96;" +
    "2015-09-25,59,94;" +
    "2015-09-26,61,94;" +
    "2015-09-26,57,92;" +
    "2015-09-27,58,92;" +
    "2015-09-27,57,90;" +
    "2015-09-28,59,88;" +
    "2015-09-28,57,87;" +
    "2015-09-29,59,80;" +
    "2015-09-29,56,76;" +
    "2015-09-30,59,68;" +
    "2015-09-30,55,69;" +
    "2015-10-01,62,81;" +
    "2015-10-01,59,78;" +
    "2015-10-02,56,88;" +
    "2015-10-02,52,86;" +
    "2015-10-03,53,87;" +
    "2015-10-03,51,85;" +
    "2015-10-04,51,84;" +
    "2015-10-04,48,81;" +
    "2015-10-05,55,83;" +
    "2015-10-05,52,81;" +
    "2015-10-06,57,84;" +
    "2015-10-06,54,82;" +
    "2015-10-07,59,90;" +
    "2015-10-07,54,87;" +
    "2015-10-08,61,94;" +
    "2015-10-08,57,91;" +
    "2015-10-09,60,92;" +
    "2015-10-09,56,90;" +
    "2015-10-10,55,85;" +
    "2015-10-10,54,83;" +
    "2015-10-11,56,91;" +
    "2015-10-11,53,89;" +
    "2015-10-12,58,95;" +
    "2015-10-12,54,90;" +
    "2015-10-13,62,101;" +
    "2015-10-13,58,97;" +
    "2015-10-14,65,94;" +
    "2015-10-14,62,92;" +
    "2015-10-15,65,89;" +
    "2015-10-15,63,86;" +
    "2015-10-16,63,88;" +
    "2015-10-16,62,86;" +
    "2015-10-17,63,75;" +
    "2015-10-17,59,73;" +
    "2015-10-18,58,75;" +
    "2015-10-18,56,72;" +
    "2015-10-19,57,77;" +
    "2015-10-19,59,75;" +
    "2015-10-20,54,86;" +
    "2015-10-20,50,81;" +
    "2015-10-21,52,86;" +
    "2015-10-21,51,82;" +
    "2015-10-22,52,85;" +
    "2015-10-22,50,81;" +
    "2015-10-23,53,86;" +
    "2015-10-23,51,84;" +
    "2015-10-24,51,86;" +
    "2015-10-24,50,82;" +
    "2015-10-25,58,81;" +
    "2015-10-25,56,78;" +
    "2015-10-26,49,84;" +
    "2015-10-26,46,81;" +
    "2015-10-27,59,73;" +
    "2015-10-27,56,72;" +
    "2015-10-28,54,77;" +
    "2015-10-28,54,74;" +
    "2015-10-29,49,80;" +
    "2015-10-29,47,79;" +
    "2015-10-30,47,86;" +
    "2015-10-30,47,85;" +
    "2015-10-31,50,88;" +
    "2015-10-31,50,87;" +
    "2015-11-01,56,77;" +
    "2015-11-01,54,77;" +
    "2015-11-02,51,64;" +
    "2015-11-02,51,66;" +
    "2015-11-03,44,65;" +
    "2015-11-03,43,64;" +
    "2015-11-04,41,64;" +
    "2015-11-04,40,63;" +
    "2015-11-05,38,68;" +
    "2015-11-05,37,66;" +
    "2015-11-06,39,69;" +
    "2015-11-06,38,68;" +
    "2015-11-07,39,67;" +
    "2015-11-07,38,65;" +
    "2015-11-08,41,61;" +
    "2015-11-08,40,60;" +
    "2015-11-09,44,56;" +
    "2015-11-09,42,56;" +
    "2015-11-10,41,59;" +
    "2015-11-10,44,57;" +
    "2015-11-11,37,62;" +
    "2015-11-11,35,61;" +
    "2015-11-12,37,63;" +
    "2015-11-12,36,62;" +
    "2015-11-13,39,65;" +
    "2015-11-13,37,63;" +
    "2015-11-14,36,70;" +
    "2015-11-14,36,70;" +
    "2015-11-15,45,61;" +
    "2015-11-15,45,60;" +
    "2015-11-16,38,61;" +
    "2015-11-16,36,59;" +
    "2015-11-17,36,66;" +
    "2015-11-17,36,65;" +
    "2015-11-18,38,70;" +
    "2015-11-18,39,71;" +
    "2015-11-19,40,70;" +
    "2015-11-19,40,68;" +
    "2015-11-20,44,73;" +
    "2015-11-20,44,71;" +
    "2015-11-21,44,68;" +
    "2015-11-21,44,66;" +
    "2015-11-22,42,68;" +
    "2015-11-22,41,66;" +
    "2015-11-23,43,60;" +
    "2015-11-23,42,61;" +
    "2015-11-24,44,59;" +
    "2015-11-24,42,58;" +
    "2015-11-25,36,52;" +
    "2015-11-25,37,52;" +
    "2015-11-26,32,52;" +
    "2015-11-26,32,51;" +
    "2015-11-27,32,55;" +
    "2015-11-27,34,55;" +
    "2015-11-28,28,54;" +
    "2015-11-28,31,53;" +
    "2015-11-29,26,56;" +
    "2015-11-29,28,53;" +
    "2015-11-30,30,49;" +
    "2015-11-30,29,47;" +
    "2015-12-01,34,58;" +
    "2015-12-01,36,55;" +
    "2015-12-02,39,56;" +
    "2015-12-02,37,54;" +
    "2015-12-03,37,67;" +
    "2015-12-03,37,66;" +
    "2015-12-04,37,61;" +
    "2015-12-04,38,62;" +
    "2015-12-05,35,54;" +
    "2015-12-05,35,53;" +
    "2015-12-06,39,68;" +
    "2015-12-06,39,68;" +
    "2015-12-07,48,65;" +
    "2015-12-07,46,64;" +
    "2015-12-08,45,63;" +
    "2015-12-08,44,62;" +
    "2015-12-09,50,69;" +
    "2015-12-09,49,68;" +
    "2015-12-10,48,62;" +
    "2015-12-10,49,61;" +
    "2015-12-11,43,58;" +
    "2015-12-11,42,57;" +
    "2015-12-12,35,59;" +
    "2015-12-12,36,59;" +
    "2015-12-13,40,57;" +
    "2015-12-13,44,56;" +
    "2015-12-14,31,55;" +
    "2015-12-14,32,55;" +
    "2015-12-15,33,54;" +
    "2015-12-15,34,53;" +
    "2015-12-16,33,54;" +
    "2015-12-16,33,53;" +
    "2015-12-17,34,60;" +
    "2015-12-17,32,60;" +
    "2015-12-18,33,62;" +
    "2015-12-18,34,62;" +
    "2015-12-19,37,58;" +
    "2015-12-19,46,57;" +
    "2015-12-20,33,54;" +
    "2015-12-20,33,53;" +
    "2015-12-21,52,58;" +
    "2015-12-21,51,58;" +
    "2015-12-22,50,61;" +
    "2015-12-22,53,59;" +
    "2015-12-23,40,57;" +
    "2015-12-23,41,56;" +
    "2015-12-24,40,52;" +
    "2015-12-24,40,50;" +
    "2015-12-25,31,51;" +
    "2015-12-25,33,51;" +
    "2015-12-26,30,50;" +
    "2015-12-26,32,50;" +
    "2015-12-27,26,48;" +
    "2015-12-27,25,48;" +
    "2015-12-28,33,49;" +
    "2015-12-28,36,47;" +
    "2015-12-29,30,54;" +
    "2015-12-29,28,53;" +
    "2015-12-30,28,52;" +
    "2015-12-30,28,51;" +
    "2015-12-31,30,50;" +
    "2015-12-31,35,48;"