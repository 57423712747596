export const data = "1989-01-01,34,52;" +
    "1989-01-02,32,49;" +
    "1989-01-03,33,48;" +
    "1989-01-04,30,55;" +
    "1989-01-05,40,50;" +
    "1989-01-06,30,50;" +
    "1989-01-07,37,44;" +
    "1989-01-08,29,50;" +
    "1989-01-09,40,48;" +
    "1989-01-10,45,54;" +
    "1989-01-11,44,54;" +
    "1989-01-12,38,55;" +
    "1989-01-13,28,52;" +
    "1989-01-14,31,53;" +
    "1989-01-15,28,56;" +
    "1989-01-16,32,57;" +
    "1989-01-17,30,57;" +
    "1989-01-18,40,64;" +
    "1989-01-19,42,61;" +
    "1989-01-20,36,61;" +
    "1989-01-21,35,66;" +
    "1989-01-22,38,59;" +
    "1989-01-23,41,49;" +
    "1989-01-24,39,58;" +
    "1989-01-25,42,56;" +
    "1989-01-26,34,58;" +
    "1989-01-27,33,59;" +
    "1989-01-28,39,60;" +
    "1989-01-29,40,64;" +
    "1989-01-30,38,64;" +
    "1989-01-31,37,67;" +
    "1989-02-01,44,52;" +
    "1989-02-02,34,50;" +
    "1989-02-03,41,44;" +
    "1989-02-04,35,46;" +
    "1989-02-05,28,42;" +
    "1989-02-06,24,41;" +
    "1989-02-07,25,42;" +
    "1989-02-08,30,39;" +
    "1989-02-09,36,45;" +
    "1989-02-10,37,53;" +
    "1989-02-11,31,56;" +
    "1989-02-12,37,59;" +
    "1989-02-13,35,57;" +
    "1989-02-14,30,56;" +
    "1989-02-15,32,58;" +
    "1989-02-16,42,64;" +
    "1989-02-17,38,64;" +
    "1989-02-18,45,59;" +
    "1989-02-19,49,64;" +
    "1989-02-20,43,64;" +
    "1989-02-21,45,66;" +
    "1989-02-22,48,71;" +
    "1989-02-23,39,66;" +
    "1989-02-24,43,56;" +
    "1989-02-25,45,62;" +
    "1989-02-26,39,70;" +
    "1989-02-27,39,74;" +
    "1989-02-28,42,59;" +
    "1989-03-01,44,55;" +
    "1989-03-02,44,53;" +
    "1989-03-03,32,57;" +
    "1989-03-04,35,50;" +
    "1989-03-05,43,55;" +
    "1989-03-06,51,64;" +
    "1989-03-07,52,65;" +
    "1989-03-08,53,64;" +
    "1989-03-09,52,70;" +
    "1989-03-10,53,68;" +
    "1989-03-11,54,70;" +
    "1989-03-12,42,69;" +
    "1989-03-13,46,60;" +
    "1989-03-14,38,65;" +
    "1989-03-15,38,69;" +
    "1989-03-16,45,60;" +
    "1989-03-17,39,66;" +
    "1989-03-18,50,58;" +
    "1989-03-19,55,68;" +
    "1989-03-20,43,70;" +
    "1989-03-21,46,72;" +
    "1989-03-22,50,71;" +
    "1989-03-23,46,67;" +
    "1989-03-24,55,66;" +
    "1989-03-25,50,60;" +
    "1989-03-26,37,60;" +
    "1989-03-27,46,68;" +
    "1989-03-28,54,64;" +
    "1989-03-29,43,69;" +
    "1989-03-30,39,74;" +
    "1989-03-31,50,70;" +
    "1989-04-01,45,61;" +
    "1989-04-02,51,67;" +
    "1989-04-03,49,71;" +
    "1989-04-04,44,77;" +
    "1989-04-05,51,84;" +
    "1989-04-06,50,88;" +
    "1989-04-07,52,89;" +
    "1989-04-08,53,90;" +
    "1989-04-09,52,93;" +
    "1989-04-10,50,87;" +
    "1989-04-11,44,85;" +
    "1989-04-12,50,78;" +
    "1989-04-13,50,74;" +
    "1989-04-14,52,70;" +
    "1989-04-15,51,76;" +
    "1989-04-16,49,78;" +
    "1989-04-17,53,69;" +
    "1989-04-18,52,82;" +
    "1989-04-19,51,79;" +
    "1989-04-20,53,76;" +
    "1989-04-21,49,62;" +
    "1989-04-22,45,68;" +
    "1989-04-23,48,60;" +
    "1989-04-24,42,58;" +
    "1989-04-25,44,67;" +
    "1989-04-26,43,67;" +
    "1989-04-27,41,72;" +
    "1989-04-28,42,77;" +
    "1989-04-29,47,76;" +
    "1989-04-30,55,70;" +
    "1989-05-01,44,78;" +
    "1989-05-02,49,80;" +
    "1989-05-03,50,87;" +
    "1989-05-04,57,88;" +
    "1989-05-05,54,90;" +
    "1989-05-06,60,89;" +
    "1989-05-07,58,84;" +
    "1989-05-08,54,78;" +
    "1989-05-09,54,62;" +
    "1989-05-10,49,63;" +
    "1989-05-11,44,70;" +
    "1989-05-12,44,72;" +
    "1989-05-13,46,73;" +
    "1989-05-14,46,76;" +
    "1989-05-15,50,78;" +
    "1989-05-16,50,86;" +
    "1989-05-17,51,79;" +
    "1989-05-18,49,73;" +
    "1989-05-19,43,80;" +
    "1989-05-20,47,72;" +
    "1989-05-21,50,65;" +
    "1989-05-22,48,68;" +
    "1989-05-23,51,69;" +
    "1989-05-24,45,72;" +
    "1989-05-25,48,75;" +
    "1989-05-26,46,81;" +
    "1989-05-27,51,73;" +
    "1989-05-28,46,71;" +
    "1989-05-29,50,70;" +
    "1989-05-30,45,80;" +
    "1989-05-31,49,88;" +
    "1989-06-01,55,97;" +
    "1989-06-02,59,91;" +
    "1989-06-03,53,73;" +
    "1989-06-04,55,68;" +
    "1989-06-05,52,77;" +
    "1989-06-06,50,79;" +
    "1989-06-07,54,71;" +
    "1989-06-08,52,72;" +
    "1989-06-09,50,75;" +
    "1989-06-10,52,70;" +
    "1989-06-11,53,67;" +
    "1989-06-12,52,76;" +
    "1989-06-13,47,86;" +
    "1989-06-14,55,88;" +
    "1989-06-15,59,82;" +
    "1989-06-16,54,85;" +
    "1989-06-17,52,92;" +
    "1989-06-18,52,86;" +
    "1989-06-19,49,83;" +
    "1989-06-20,50,89;" +
    "1989-06-21,58,94;" +
    "1989-06-22,62,102;" +
    "1989-06-23,61,95;" +
    "1989-06-24,59,79;" +
    "1989-06-25,55,77;" +
    "1989-06-26,55,76;" +
    "1989-06-27,54,74;" +
    "1989-06-28,55,71;" +
    "1989-06-29,51,79;" +
    "1989-06-30,62,79;" +
    "1989-07-01,49,80;" +
    "1989-07-02,48,81;" +
    "1989-07-03,50,84;" +
    "1989-07-04,51,87;" +
    "1989-07-05,52,100;" +
    "1989-07-06,55,106;" +
    "1989-07-07,64,104;" +
    "1989-07-08,64,98;" +
    "1989-07-09,51,91;" +
    "1989-07-10,55,90;" +
    "1989-07-11,54,84;" +
    "1989-07-12,54,81;" +
    "1989-07-13,52,90;" +
    "1989-07-14,52,82;" +
    "1989-07-15,54,78;" +
    "1989-07-16,53,86;" +
    "1989-07-17,58,94;" +
    "1989-07-18,62,96;" +
    "1989-07-19,58,95;" +
    "1989-07-20,56,91;" +
    "1989-07-21,53,94;" +
    "1989-07-22,56,97;" +
    "1989-07-23,59,93;" +
    "1989-07-24,57,92;" +
    "1989-07-25,54,88;" +
    "1989-07-26,54,82;" +
    "1989-07-27,51,94;" +
    "1989-07-28,51,87;" +
    "1989-07-29,52,84;" +
    "1989-07-30,52,79;" +
    "1989-07-31,55,78;" +
    "1989-08-01,53,83;" +
    "1989-08-02,52,86;" +
    "1989-08-03,54,92;" +
    "1989-08-04,56,91;" +
    "1989-08-05,55,93;" +
    "1989-08-06,56,91;" +
    "1989-08-07,63,94;" +
    "1989-08-08,63,88;" +
    "1989-08-09,56,90;" +
    "1989-08-10,55,85;" +
    "1989-08-11,54,88;" +
    "1989-08-12,52,87;" +
    "1989-08-13,51,99;" +
    "1989-08-14,56,100;" +
    "1989-08-15,56,97;" +
    "1989-08-16,56,87;" +
    "1989-08-17,54,87;" +
    "1989-08-18,57,83;" +
    "1989-08-19,55,84;" +
    "1989-08-20,56,82;" +
    "1989-08-21,56,80;" +
    "1989-08-22,57,80;" +
    "1989-08-23,61,81;" +
    "1989-08-24,53,88;" +
    "1989-08-25,53,91;" +
    "1989-08-26,54,84;" +
    "1989-08-27,54,88;" +
    "1989-08-28,54,92;" +
    "1989-08-29,53,81;" +
    "1989-08-30,53,82;" +
    "1989-08-31,52,91;" +
    "1989-09-01,56,90;" +
    "1989-09-02,49,92;" +
    "1989-09-03,53,96;" +
    "1989-09-04,55,94;" +
    "1989-09-05,53,93;" +
    "1989-09-06,54,82;" +
    "1989-09-07,50,87;" +
    "1989-09-08,54,82;" +
    "1989-09-09,53,75;" +
    "1989-09-10,55,70;" +
    "1989-09-11,57,73;" +
    "1989-09-12,54,92;" +
    "1989-09-13,55,95;" +
    "1989-09-14,57,97;" +
    "1989-09-15,59,92;" +
    "1989-09-16,55,81;" +
    "1989-09-17,54,68;" +
    "1989-09-18,54,67;" +
    "1989-09-19,47,76;" +
    "1989-09-20,52,77;" +
    "1989-09-21,52,82;" +
    "1989-09-22,55,90;" +
    "1989-09-23,58,85;" +
    "1989-09-24,54,76;" +
    "1989-09-25,53,78;" +
    "1989-09-26,52,77;" +
    "1989-09-27,50,78;" +
    "1989-09-28,52,73;" +
    "1989-09-29,59,74;" +
    "1989-09-30,52,77;" +
    "1989-10-01,50,81;" +
    "1989-10-02,53,63;" +
    "1989-10-03,46,74;" +
    "1989-10-04,45,80;" +
    "1989-10-05,46,81;" +
    "1989-10-06,49,85;" +
    "1989-10-07,47,84;" +
    "1989-10-08,50,86;" +
    "1989-10-09,51,88;" +
    "1989-10-10,52,89;" +
    "1989-10-11,52,88;" +
    "1989-10-12,51,79;" +
    "1989-10-13,50,77;" +
    "1989-10-14,48,69;" +
    "1989-10-15,50,70;" +
    "1989-10-16,46,79;" +
    "1989-10-17,48,80;" +
    "1989-10-18,51,85;" +
    "1989-10-19,52,90;" +
    "1989-10-20,54,71;" +
    "1989-10-21,58,69;" +
    "1989-10-22,52,72;" +
    "1989-10-23,54,65;" +
    "1989-10-24,49,65;" +
    "1989-10-25,47,63;" +
    "1989-10-26,42,67;" +
    "1989-10-27,46,68;" +
    "1989-10-28,41,63;" +
    "1989-10-29,47,64;" +
    "1989-10-30,42,67;" +
    "1989-10-31,37,70;" +
    "1989-11-01,39,69;" +
    "1989-11-02,39,72;" +
    "1989-11-03,40,74;" +
    "1989-11-04,42,78;" +
    "1989-11-05,46,67;" +
    "1989-11-06,39,70;" +
    "1989-11-07,38,69;" +
    "1989-11-08,38,69;" +
    "1989-11-09,39,69;" +
    "1989-11-10,41,72;" +
    "1989-11-11,42,71;" +
    "1989-11-12,42,73;" +
    "1989-11-13,48,59;" +
    "1989-11-14,44,68;" +
    "1989-11-15,46,64;" +
    "1989-11-16,40,76;" +
    "1989-11-17,44,74;" +
    "1989-11-18,49,70;" +
    "1989-11-19,41,75;" +
    "1989-11-20,40,75;" +
    "1989-11-21,40,70;" +
    "1989-11-22,46,60;" +
    "1989-11-23,45,59;" +
    "1989-11-24,51,60;" +
    "1989-11-25,48,61;" +
    "1989-11-26,43,58;" +
    "1989-11-27,36,54;" +
    "1989-11-28,33,53;" +
    "1989-11-29,34,58;" +
    "1989-11-30,33,59;" +
    "1989-12-01,36,65;" +
    "1989-12-02,36,58;" +
    "1989-12-03,37,63;" +
    "1989-12-04,36,68;" +
    "1989-12-05,41,69;" +
    "1989-12-06,43,71;" +
    "1989-12-07,38,62;" +
    "1989-12-08,34,56;" +
    "1989-12-09,37,63;" +
    "1989-12-10,42,59;" +
    "1989-12-11,39,52;" +
    "1989-12-12,30,54;" +
    "1989-12-13,31,57;" +
    "1989-12-14,29,60;" +
    "1989-12-15,32,58;" +
    "1989-12-16,30,58;" +
    "1989-12-17,30,60;" +
    "1989-12-18,41,54;" +
    "1989-12-19,31,55;" +
    "1989-12-20,30,51;" +
    "1989-12-21,32,57;" +
    "1989-12-22,33,42;" +
    "1989-12-23,28,57;" +
    "1989-12-24,32,50;" +
    "1989-12-25,28,53;" +
    "1989-12-26,29,56;" +
    "1989-12-27,29,53;" +
    "1989-12-28,37,53;" +
    "1989-12-29,39,63;" +
    "1989-12-30,40,62;" +
    "1989-12-31,33,59;"