export const data = "2011-01-01,42,50;" +
    "2011-01-01,40,49;" +
    "2011-01-02,44,51;" +
    "2011-01-02,45,51;" +
    "2011-01-03,37,50;" +
    "2011-01-03,42,51;" +
    "2011-01-04,32,53;" +
    "2011-01-04,32,52;" +
    "2011-01-05,31,54;" +
    "2011-01-05,31,52;" +
    "2011-01-06,31,43;" +
    "2011-01-06,30,44;" +
    "2011-01-07,31,44;" +
    "2011-01-07,35,46;" +
    "2011-01-08,31,45;" +
    "2011-01-08,31,44;" +
    "2011-01-09,30,47;" +
    "2011-01-09,35,45;" +
    "2011-01-10,27,46;" +
    "2011-01-10,27,46;" +
    "2011-01-11,39,45;" +
    "2011-01-11,37,44;" +
    "2011-01-12,40,56;" +
    "2011-01-12,41,55;" +
    "2011-01-13,37,57;" +
    "2011-01-13,38,57;" +
    "2011-01-14,45,63;" +
    "2011-01-14,48,65;" +
    "2011-01-15,42,67;" +
    "2011-01-15,43,66;" +
    "2011-01-16,41,60;" +
    "2011-01-16,41,59;" +
    "2011-01-17,42,64;" +
    "2011-01-17,42,66;" +
    "2011-01-18,39,65;" +
    "2011-01-18,42,65;" +
    "2011-01-19,38,63;" +
    "2011-01-19,40,61;" +
    "2011-01-20,39,60;" +
    "2011-01-20,41,59;" +
    "2011-01-21,35,65;" +
    "2011-01-21,37,64;" +
    "2011-01-22,39,69;" +
    "2011-01-22,40,67;" +
    "2011-01-23,36,68;" +
    "2011-01-23,41,68;" +
    "2011-01-24,34,68;" +
    "2011-01-24,35,68;" +
    "2011-01-25,38,66;" +
    "2011-01-25,38,65;" +
    "2011-01-26,39,61;" +
    "2011-01-26,42,59;" +
    "2011-01-27,36,66;" +
    "2011-01-27,36,66;" +
    "2011-01-28,34,61;" +
    "2011-01-28,34,63;" +
    "2011-01-29,47,59;" +
    "2011-01-29,44,61;" +
    "2011-01-30,41,55;" +
    "2011-01-30,47,57;" +
    "2011-01-31,35,62;" +
    "2011-01-31,34,63;" +
    "2011-02-01,41,63;" +
    "2011-02-01,39,62;" +
    "2011-02-02,34,60;" +
    "2011-02-02,41,59;" +
    "2011-02-03,31,60;" +
    "2011-02-03,32,61;" +
    "2011-02-04,35,70;" +
    "2011-02-04,35,69;" +
    "2011-02-05,41,76;" +
    "2011-02-05,42,77;" +
    "2011-02-06,49,73;" +
    "2011-02-06,57,73;" +
    "2011-02-07,41,73;" +
    "2011-02-07,42,75;" +
    "2011-02-08,45,60;" +
    "2011-02-08,46,60;" +
    "2011-02-09,35,59;" +
    "2011-02-09,39,59;" +
    "2011-02-10,31,62;" +
    "2011-02-10,33,62;" +
    "2011-02-11,32,63;" +
    "2011-02-11,33,62;" +
    "2011-02-12,33,72;" +
    "2011-02-12,33,74;" +
    "2011-02-13,32,68;" +
    "2011-02-13,34,69;" +
    "2011-02-14,47,63;" +
    "2011-02-14,43,63;" +
    "2011-02-15,50,61;" +
    "2011-02-15,49,62;" +
    "2011-02-16,40,53;" +
    "2011-02-16,42,56;" +
    "2011-02-17,37,50;" +
    "2011-02-17,38,49;" +
    "2011-02-18,38,45;" +
    "2011-02-18,41,44;" +
    "2011-02-19,37,47;" +
    "2011-02-19,36,46;" +
    "2011-02-20,32,54;" +
    "2011-02-20,32,54;" +
    "2011-02-21,35,53;" +
    "2011-02-21,35,56;" +
    "2011-02-22,33,58;" +
    "2011-02-22,33,60;" +
    "2011-02-23,34,56;" +
    "2011-02-23,32,58;" +
    "2011-02-24,38,51;" +
    "2011-02-24,39,51;" +
    "2011-02-25,46,51;" +
    "2011-02-25,42,52;" +
    "2011-02-26,29,47;" +
    "2011-02-26,29,47;" +
    "2011-02-27,26,57;" +
    "2011-02-27,27,58;" +
    "2011-02-28,31,57;" +
    "2011-02-28,30,60;" +
    "2011-03-01,31,62;" +
    "2011-03-01,32,64;" +
    "2011-03-02,49,61;" +
    "2011-03-02,48,62;" +
    "2011-03-03,43,66;" +
    "2011-03-03,48,68;" +
    "2011-03-04,38,64;" +
    "2011-03-04,38,64;" +
    "2011-03-05,40,68;" +
    "2011-03-05,42,68;" +
    "2011-03-06,52,59;" +
    "2011-03-06,52,61;" +
    "2011-03-07,45,58;" +
    "2011-03-07,45,59;" +
    "2011-03-08,44,62;" +
    "2011-03-08,48,64;" +
    "2011-03-09,44,65;" +
    "2011-03-09,43,66;" +
    "2011-03-10,42,61;" +
    "2011-03-10,42,62;" +
    "2011-03-11,41,60;" +
    "2011-03-11,41,62;" +
    "2011-03-12,43,63;" +
    "2011-03-12,42,65;" +
    "2011-03-13,46,66;" +
    "2011-03-13,46,67;" +
    "2011-03-14,52,67;" +
    "2011-03-14,53,70;" +
    "2011-03-15,52,68;" +
    "2011-03-15,53,70;" +
    "2011-03-16,42,59;" +
    "2011-03-16,50,61;" +
    "2011-03-17,35,62;" +
    "2011-03-17,35,63;" +
    "2011-03-18,44,56;" +
    "2011-03-18,43,58;" +
    "2011-03-19,39,55;" +
    "2011-03-19,38,53;" +
    "2011-03-20,44,52;" +
    "2011-03-20,46,52;" +
    "2011-03-21,42,56;" +
    "2011-03-21,41,57;" +
    "2011-03-22,38,58;" +
    "2011-03-22,39,58;" +
    "2011-03-23,47,56;" +
    "2011-03-23,46,56;" +
    "2011-03-24,44,51;" +
    "2011-03-24,44,52;" +
    "2011-03-25,44,56;" +
    "2011-03-25,43,57;" +
    "2011-03-26,43,56;" +
    "2011-03-26,46,56;" +
    "2011-03-27,37,60;" +
    "2011-03-27,37,62;" +
    "2011-03-28,41,63;" +
    "2011-03-28,42,64;" +
    "2011-03-29,41,69;" +
    "2011-03-29,41,71;" +
    "2011-03-30,45,80;" +
    "2011-03-30,46,80;" +
    "2011-03-31,52,80;" +
    "2011-03-31,54,79;" +
    "2011-04-01,47,80;" +
    "2011-04-01,48,81;" +
    "2011-04-02,48,65;" +
    "2011-04-02,52,76;" +
    "2011-04-03,41,68;" +
    "2011-04-03,41,67;" +
    "2011-04-04,40,74;" +
    "2011-04-04,42,75;" +
    "2011-04-05,45,69;" +
    "2011-04-05,46,72;" +
    "2011-04-06,41,64;" +
    "2011-04-06,41,66;" +
    "2011-04-07,40,54;" +
    "2011-04-07,41,56;" +
    "2011-04-08,35,57;" +
    "2011-04-08,36,56;" +
    "2011-04-09,33,63;" +
    "2011-04-09,34,65;" +
    "2011-04-10,44,63;" +
    "2011-04-10,42,65;" +
    "2011-04-11,43,64;" +
    "2011-04-11,45,67;" +
    "2011-04-12,37,62;" +
    "2011-04-12,37,64;" +
    "2011-04-13,43,59;" +
    "2011-04-13,44,61;" +
    "2011-04-14,38,63;" +
    "2011-04-14,38,66;" +
    "2011-04-15,44,73;" +
    "2011-04-15,44,75;" +
    "2011-04-16,51,74;" +
    "2011-04-16,50,75;" +
    "2011-04-17,52,70;" +
    "2011-04-17,53,72;" +
    "2011-04-18,53,65;" +
    "2011-04-18,52,67;" +
    "2011-04-19,54,71;" +
    "2011-04-19,54,73;" +
    "2011-04-20,52,70;" +
    "2011-04-20,51,71;" +
    "2011-04-21,46,64;" +
    "2011-04-21,48,67;" +
    "2011-04-22,42,61;" +
    "2011-04-22,42,63;" +
    "2011-04-23,46,69;" +
    "2011-04-23,46,71;" +
    "2011-04-24,51,63;" +
    "2011-04-24,53,65;" +
    "2011-04-25,47,67;" +
    "2011-04-25,50,67;" +
    "2011-04-26,41,68;" +
    "2011-04-26,42,70;" +
    "2011-04-27,43,70;" +
    "2011-04-27,45,73;" +
    "2011-04-28,47,65;" +
    "2011-04-28,46,65;" +
    "2011-04-29,37,68;" +
    "2011-04-29,38,69;" +
    "2011-04-30,45,74;" +
    "2011-04-30,52,76;" +
    "2011-05-01,37,79;" +
    "2011-05-01,41,79;" +
    "2011-05-02,43,80;" +
    "2011-05-02,44,83;" +
    "2011-05-03,45,78;" +
    "2011-05-03,46,78;" +
    "2011-05-04,49,87;" +
    "2011-05-04,50,86;" +
    "2011-05-05,49,90;" +
    "2011-05-05,51,93;" +
    "2011-05-06,48,69;" +
    "2011-05-06,52,85;" +
    "2011-05-07,47,65;" +
    "2011-05-07,47,67;" +
    "2011-05-08,48,62;" +
    "2011-05-08,49,62;" +
    "2011-05-09,45,67;" +
    "2011-05-09,46,69;" +
    "2011-05-10,41,76;" +
    "2011-05-10,42,78;" +
    "2011-05-11,49,63;" +
    "2011-05-11,48,66;" +
    "2011-05-12,48,75;" +
    "2011-05-12,49,77;" +
    "2011-05-13,45,69;" +
    "2011-05-13,43,71;" +
    "2011-05-14,47,60;" +
    "2011-05-14,48,63;" +
    "2011-05-15,44,58;" +
    "2011-05-15,43,60;" +
    "2011-05-16,43,60;" +
    "2011-05-16,43,61;" +
    "2011-05-17,50,60;" +
    "2011-05-17,48,61;" +
    "2011-05-18,49,68;" +
    "2011-05-18,48,68;" +
    "2011-05-19,42,77;" +
    "2011-05-19,42,78;" +
    "2011-05-20,47,75;" +
    "2011-05-20,46,76;" +
    "2011-05-21,50,70;" +
    "2011-05-21,49,72;" +
    "2011-05-22,48,68;" +
    "2011-05-22,48,67;" +
    "2011-05-23,49,69;" +
    "2011-05-23,48,69;" +
    "2011-05-24,48,71;" +
    "2011-05-24,46,73;" +
    "2011-05-25,47,62;" +
    "2011-05-25,46,66;" +
    "2011-05-26,47,67;" +
    "2011-05-26,46,69;" +
    "2011-05-27,50,68;" +
    "2011-05-27,49,69;" +
    "2011-05-28,48,67;" +
    "2011-05-28,49,68;" +
    "2011-05-29,45,70;" +
    "2011-05-29,45,71;" +
    "2011-05-30,49,66;" +
    "2011-05-30,48,69;" +
    "2011-05-31,49,62;" +
    "2011-05-31,51,64;" +
    "2011-06-01,43,64;" +
    "2011-06-01,44,64;" +
    "2011-06-02,46,68;" +
    "2011-06-02,46,67;" +
    "2011-06-03,43,67;" +
    "2011-06-03,43,67;" +
    "2011-06-04,53,65;" +
    "2011-06-04,54,66;" +
    "2011-06-05,53,69;" +
    "2011-06-05,52,69;" +
    "2011-06-06,54,69;" +
    "2011-06-06,53,69;" +
    "2011-06-07,52,76;" +
    "2011-06-07,54,77;" +
    "2011-06-08,52,75;" +
    "2011-06-08,52,77;" +
    "2011-06-09,50,83;" +
    "2011-06-09,49,84;" +
    "2011-06-10,52,74;" +
    "2011-06-10,52,78;" +
    "2011-06-11,53,65;" +
    "2011-06-11,52,67;" +
    "2011-06-12,52,79;" +
    "2011-06-12,51,81;" +
    "2011-06-13,52,80;" +
    "2011-06-13,51,82;" +
    "2011-06-14,53,94;" +
    "2011-06-14,53,94;" +
    "2011-06-15,58,94;" +
    "2011-06-15,59,96;" +
    "2011-06-16,54,82;" +
    "2011-06-16,55,90;" +
    "2011-06-17,52,80;" +
    "2011-06-17,51,83;" +
    "2011-06-18,51,83;" +
    "2011-06-18,50,84;" +
    "2011-06-19,54,91;" +
    "2011-06-19,54,92;" +
    "2011-06-20,59,98;" +
    "2011-06-20,58,99;" +
    "2011-06-21,61,101;" +
    "2011-06-21,62,102;" +
    "2011-06-22,56,94;" +
    "2011-06-22,63,96;" +
    "2011-06-23,54,82;" +
    "2011-06-23,53,85;" +
    "2011-06-24,51,84;" +
    "2011-06-24,49,84;" +
    "2011-06-25,52,78;" +
    "2011-06-25,50,80;" +
    "2011-06-26,53,86;" +
    "2011-06-26,51,87;" +
    "2011-06-27,53,78;" +
    "2011-06-27,52,83;" +
    "2011-06-28,55,70;" +
    "2011-06-28,54,74;" +
    "2011-06-29,53,69;" +
    "2011-06-29,52,70;" +
    "2011-06-30,50,84;" +
    "2011-06-30,52,86;" +
    "2011-07-01,55,94;" +
    "2011-07-01,55,95;" +
    "2011-07-02,59,98;" +
    "2011-07-02,59,99;" +
    "2011-07-03,61,101;" +
    "2011-07-03,61,101;" +
    "2011-07-04,61,97;" +
    "2011-07-04,61,98;" +
    "2011-07-05,60,99;" +
    "2011-07-05,60,100;" +
    "2011-07-06,66,99;" +
    "2011-07-06,65,98;" +
    "2011-07-07,61,96;" +
    "2011-07-07,61,96;" +
    "2011-07-08,57,95;" +
    "2011-07-08,58,96;" +
    "2011-07-09,54,81;" +
    "2011-07-09,54,92;" +
    "2011-07-10,52,82;" +
    "2011-07-10,51,83;" +
    "2011-07-11,55,70;" +
    "2011-07-11,54,77;" +
    "2011-07-12,55,71;" +
    "2011-07-12,54,74;" +
    "2011-07-13,56,71;" +
    "2011-07-13,57,73;" +
    "2011-07-14,55,69;" +
    "2011-07-14,54,70;" +
    "2011-07-15,56,71;" +
    "2011-07-15,54,73;" +
    "2011-07-16,56,75;" +
    "2011-07-16,55,76;" +
    "2011-07-17,54,75;" +
    "2011-07-17,55,76;" +
    "2011-07-18,51,81;" +
    "2011-07-18,52,82;" +
    "2011-07-19,58,86;" +
    "2011-07-19,52,88;" +
    "2011-07-20,57,96;" +
    "2011-07-20,56,95;" +
    "2011-07-21,55,93;" +
    "2011-07-21,56,94;" +
    "2011-07-22,58,87;" +
    "2011-07-22,57,89;" +
    "2011-07-23,57,85;" +
    "2011-07-23,55,89;" +
    "2011-07-24,56,83;" +
    "2011-07-24,55,86;" +
    "2011-07-25,55,82;" +
    "2011-07-25,54,84;" +
    "2011-07-26,55,87;" +
    "2011-07-26,54,88;" +
    "2011-07-27,54,94;" +
    "2011-07-27,55,94;" +
    "2011-07-28,57,91;" +
    "2011-07-28,56,92;" +
    "2011-07-29,57,87;" +
    "2011-07-29,57,89;" +
    "2011-07-30,58,82;" +
    "2011-07-30,57,84;" +
    "2011-07-31,56,82;" +
    "2011-07-31,56,83;" +
    "2011-08-01,56,84;" +
    "2011-08-01,54,84;" +
    "2011-08-02,54,90;" +
    "2011-08-02,53,90;" +
    "2011-08-03,54,86;" +
    "2011-08-03,54,86;" +
    "2011-08-04,56,76;" +
    "2011-08-04,56,78;" +
    "2011-08-05,57,88;" +
    "2011-08-05,57,89;" +
    "2011-08-06,57,83;" +
    "2011-08-06,57,86;" +
    "2011-08-07,55,78;" +
    "2011-08-07,56,80;" +
    "2011-08-08,56,86;" +
    "2011-08-08,55,88;" +
    "2011-08-09,54,91;" +
    "2011-08-09,51,92;" +
    "2011-08-10,54,89;" +
    "2011-08-10,54,90;" +
    "2011-08-11,54,82;" +
    "2011-08-11,53,84;" +
    "2011-08-12,54,86;" +
    "2011-08-12,53,87;" +
    "2011-08-13,55,91;" +
    "2011-08-13,56,92;" +
    "2011-08-14,55,90;" +
    "2011-08-14,53,90;" +
    "2011-08-15,55,88;" +
    "2011-08-15,54,89;" +
    "2011-08-16,52,90;" +
    "2011-08-16,54,91;" +
    "2011-08-17,56,89;" +
    "2011-08-17,54,90;" +
    "2011-08-18,55,88;" +
    "2011-08-18,54,90;" +
    "2011-08-19,54,78;" +
    "2011-08-19,54,80;" +
    "2011-08-20,55,79;" +
    "2011-08-20,54,80;" +
    "2011-08-21,57,79;" +
    "2011-08-21,58,81;" +
    "2011-08-22,59,87;" +
    "2011-08-22,57,88;" +
    "2011-08-23,55,97;" +
    "2011-08-23,54,98;" +
    "2011-08-24,59,93;" +
    "2011-08-24,63,94;" +
    "2011-08-25,58,89;" +
    "2011-08-25,57,90;" +
    "2011-08-26,58,92;" +
    "2011-08-26,58,94;" +
    "2011-08-27,57,91;" +
    "2011-08-27,56,92;" +
    "2011-08-28,54,94;" +
    "2011-08-28,54,94;" +
    "2011-08-29,57,85;" +
    "2011-08-29,55,87;" +
    "2011-08-30,54,82;" +
    "2011-08-30,51,84;" +
    "2011-08-31,54,84;" +
    "2011-08-31,53,85;" +
    "2011-09-01,54,93;" +
    "2011-09-01,52,94;" +
    "2011-09-02,57,98;" +
    "2011-09-02,55,98;" +
    "2011-09-03,57,90;" +
    "2011-09-03,55,92;" +
    "2011-09-04,55,85;" +
    "2011-09-04,56,86;" +
    "2011-09-05,54,87;" +
    "2011-09-05,53,88;" +
    "2011-09-06,54,100;" +
    "2011-09-06,52,100;" +
    "2011-09-07,56,97;" +
    "2011-09-07,56,97;" +
    "2011-09-08,57,91;" +
    "2011-09-08,58,92;" +
    "2011-09-09,56,96;" +
    "2011-09-09,54,98;" +
    "2011-09-10,62,92;" +
    "2011-09-10,63,94;" +
    "2011-09-11,61,81;" +
    "2011-09-11,59,82;" +
    "2011-09-12,58,86;" +
    "2011-09-12,57,86;" +
    "2011-09-13,57,89;" +
    "2011-09-13,56,90;" +
    "2011-09-14,57,83;" +
    "2011-09-14,57,84;" +
    "2011-09-15,55,78;" +
    "2011-09-15,54,79;" +
    "2011-09-16,51,78;" +
    "2011-09-16,51,78;" +
    "2011-09-17,50,86;" +
    "2011-09-17,47,86;" +
    "2011-09-18,52,93;" +
    "2011-09-18,52,91;" +
    "2011-09-19,58,97;" +
    "2011-09-19,57,95;" +
    "2011-09-20,59,101;" +
    "2011-09-20,59,100;" +
    "2011-09-21,55,100;" +
    "2011-09-21,57,100;" +
    "2011-09-22,57,98;" +
    "2011-09-22,55,98;" +
    "2011-09-23,63,101;" +
    "2011-09-23,61,101;" +
    "2011-09-24,59,76;" +
    "2011-09-24,61,90;" +
    "2011-09-25,55,70;" +
    "2011-09-25,58,70;" +
    "2011-09-26,51,82;" +
    "2011-09-26,50,82;" +
    "2011-09-27,52,89;" +
    "2011-09-27,52,88;" +
    "2011-09-28,58,96;" +
    "2011-09-28,56,96;" +
    "2011-09-29,60,90;" +
    "2011-09-29,59,91;" +
    "2011-09-30,57,78;" +
    "2011-09-30,58,78;" +
    "2011-10-01,59,77;" +
    "2011-10-01,57,78;" +
    "2011-10-02,53,76;" +
    "2011-10-02,53,77;" +
    "2011-10-03,48,69;" +
    "2011-10-03,49,68;" +
    "2011-10-04,56,69;" +
    "2011-10-04,55,67;" +
    "2011-10-05,50,64;" +
    "2011-10-05,51,64;" +
    "2011-10-06,47,62;" +
    "2011-10-06,47,65;" +
    "2011-10-07,44,72;" +
    "2011-10-07,42,73;" +
    "2011-10-08,46,77;" +
    "2011-10-08,46,78;" +
    "2011-10-09,52,79;" +
    "2011-10-09,50,79;" +
    "2011-10-10,56,70;" +
    "2011-10-10,55,71;" +
    "2011-10-11,58,77;" +
    "2011-10-11,61,78;" +
    "2011-10-12,53,83;" +
    "2011-10-12,52,80;" +
    "2011-10-13,52,88;" +
    "2011-10-13,52,85;" +
    "2011-10-14,55,87;" +
    "2011-10-14,53,85;" +
    "2011-10-15,54,82;" +
    "2011-10-15,55,83;" +
    "2011-10-16,58,77;" +
    "2011-10-16,55,83;" +
    "2011-10-17,52,87;" +
    "2011-10-17,52,85;" +
    "2011-10-18,54,87;" +
    "2011-10-18,53,86;" +
    "2011-10-19,55,71;" +
    "2011-10-19,55,72;" +
    "2011-10-20,53,76;" +
    "2011-10-20,53,77;" +
    "2011-10-21,50,80;" +
    "2011-10-21,48,79;" +
    "2011-10-22,48,86;" +
    "2011-10-22,49,83;" +
    "2011-10-23,49,85;" +
    "2011-10-23,48,83;" +
    "2011-10-24,51,78;" +
    "2011-10-24,51,77;" +
    "2011-10-25,49,73;" +
    "2011-10-25,52,73;" +
    "2011-10-26,42,74;" +
    "2011-10-26,43,73;" +
    "2011-10-27,40,74;" +
    "2011-10-27,39,72;" +
    "2011-10-28,42,77;" +
    "2011-10-28,42,74;" +
    "2011-10-29,42,81;" +
    "2011-10-29,41,81;" +
    "2011-10-30,46,81;" +
    "2011-10-30,45,78;" +
    "2011-10-31,49,81;" +
    "2011-10-31,47,79;" +
    "2011-11-01,51,73;" +
    "2011-11-01,52,73;" +
    "2011-11-02,48,75;" +
    "2011-11-02,53,73;" +
    "2011-11-03,40,65;" +
    "2011-11-03,41,67;" +
    "2011-11-04,39,59;" +
    "2011-11-04,39,58;" +
    "2011-11-05,36,56;" +
    "2011-11-05,36,55;" +
    "2011-11-06,42,59;" +
    "2011-11-06,42,60;" +
    "2011-11-07,37,62;" +
    "2011-11-07,39,63;" +
    "2011-11-08,34,61;" +
    "2011-11-08,35,60;" +
    "2011-11-09,36,62;" +
    "2011-11-09,36,61;" +
    "2011-11-10,35,66;" +
    "2011-11-10,36,64;" +
    "2011-11-11,41,59;" +
    "2011-11-11,42,58;" +
    "2011-11-12,45,62;" +
    "2011-11-12,47,62;" +
    "2011-11-13,43,68;" +
    "2011-11-13,43,68;" +
    "2011-11-14,44,65;" +
    "2011-11-14,46,64;" +
    "2011-11-15,39,69;" +
    "2011-11-15,39,69;" +
    "2011-11-16,38,71;" +
    "2011-11-16,39,72;" +
    "2011-11-17,43,62;" +
    "2011-11-17,43,62;" +
    "2011-11-18,46,58;" +
    "2011-11-18,46,57;" +
    "2011-11-19,38,57;" +
    "2011-11-19,38,57;" +
    "2011-11-20,45,57;" +
    "2011-11-20,43,57;" +
    "2011-11-21,42,60;" +
    "2011-11-21,39,60;" +
    "2011-11-22,37,62;" +
    "2011-11-22,36,63;" +
    "2011-11-23,39,61;" +
    "2011-11-23,38,61;" +
    "2011-11-24,45,58;" +
    "2011-11-24,47,57;" +
    "2011-11-25,42,62;" +
    "2011-11-25,41,63;" +
    "2011-11-26,43,58;" +
    "2011-11-26,43,59;" +
    "2011-11-27,46,60;" +
    "2011-11-27,44,60;" +
    "2011-11-28,41,61;" +
    "2011-11-28,42,61;" +
    "2011-11-29,36,66;" +
    "2011-11-29,36,65;" +
    "2011-11-30,35,66;" +
    "2011-11-30,35,65;" +
    "2011-12-01,54,65;" +
    "2011-12-01,50,64;" +
    "2011-12-02,48,72;" +
    "2011-12-02,52,70;" +
    "2011-12-03,43,62;" +
    "2011-12-03,48,64;" +
    "2011-12-04,33,66;" +
    "2011-12-04,33,65;" +
    "2011-12-05,34,57;" +
    "2011-12-05,37,56;" +
    "2011-12-06,28,60;" +
    "2011-12-06,29,59;" +
    "2011-12-07,31,59;" +
    "2011-12-07,30,57;" +
    "2011-12-08,30,59;" +
    "2011-12-08,30,57;" +
    "2011-12-09,29,60;" +
    "2011-12-09,30,58;" +
    "2011-12-10,31,60;" +
    "2011-12-10,31,59;" +
    "2011-12-11,37,54;" +
    "2011-12-11,36,53;" +
    "2011-12-12,33,51;" +
    "2011-12-12,42,50;" +
    "2011-12-13,30,59;" +
    "2011-12-13,30,59;" +
    "2011-12-14,31,59;" +
    "2011-12-14,32,58;" +
    "2011-12-15,35,51;" +
    "2011-12-15,41,49;" +
    "2011-12-16,34,63;" +
    "2011-12-16,35,60;" +
    "2011-12-17,31,62;" +
    "2011-12-17,31,62;" +
    "2011-12-18,30,56;" +
    "2011-12-18,31,56;" +
    "2011-12-19,40,64;" +
    "2011-12-19,40,62;" +
    "2011-12-20,31,60;" +
    "2011-12-20,32,61;" +
    "2011-12-21,31,61;" +
    "2011-12-21,30,59;" +
    "2011-12-22,33,58;" +
    "2011-12-22,44,57;" +
    "2011-12-23,25,62;" +
    "2011-12-23,27,60;" +
    "2011-12-24,26,55;" +
    "2011-12-24,27,53;" +
    "2011-12-25,25,60;" +
    "2011-12-25,27,60;" +
    "2011-12-26,26,59;" +
    "2011-12-26,28,60;" +
    "2011-12-27,36,62;" +
    "2011-12-27,35,62;" +
    "2011-12-28,36,67;" +
    "2011-12-28,36,69;" +
    "2011-12-29,42,65;" +
    "2011-12-29,41,64;" +
    "2011-12-30,48,61;" +
    "2011-12-30,48,60;" +
    "2011-12-31,37,59;" +
    "2011-12-31,40,56;"