export const data = "2012-01-01,39,61;" +
    "2012-01-01,39,56;" +
    "2012-01-02,36,59;" +
    "2012-01-02,37,58;" +
    "2012-01-03,38,64;" +
    "2012-01-03,38,62;" +
    "2012-01-04,37,69;" +
    "2012-01-04,37,67;" +
    "2012-01-05,35,70;" +
    "2012-01-05,36,70;" +
    "2012-01-06,38,61;" +
    "2012-01-06,40,62;" +
    "2012-01-07,32,68;" +
    "2012-01-07,34,66;" +
    "2012-01-08,38,66;" +
    "2012-01-08,42,64;" +
    "2012-01-09,33,63;" +
    "2012-01-09,33,62;" +
    "2012-01-10,32,64;" +
    "2012-01-10,32,62;" +
    "2012-01-11,33,69;" +
    "2012-01-11,33,66;" +
    "2012-01-12,33,64;" +
    "2012-01-12,32,62;" +
    "2012-01-13,32,65;" +
    "2012-01-13,32,64;" +
    "2012-01-14,31,66;" +
    "2012-01-14,30,64;" +
    "2012-01-15,29,56;" +
    "2012-01-15,32,55;" +
    "2012-01-16,23,52;" +
    "2012-01-16,25,52;" +
    "2012-01-17,24,53;" +
    "2012-01-17,25,54;" +
    "2012-01-18,25,56;" +
    "2012-01-18,26,57;" +
    "2012-01-19,29,51;" +
    "2012-01-19,31,48;" +
    "2012-01-20,47,61;" +
    "2012-01-20,45,60;" +
    "2012-01-21,38,58;" +
    "2012-01-21,46,60;" +
    "2012-01-22,38,56;" +
    "2012-01-22,37,53;" +
    "2012-01-23,47,56;" +
    "2012-01-23,48,55;" +
    "2012-01-24,46,61;" +
    "2012-01-24,44,61;" +
    "2012-01-25,42,63;" +
    "2012-01-25,40,62;" +
    "2012-01-26,48,65;" +
    "2012-01-26,47,66;" +
    "2012-01-27,46,65;" +
    "2012-01-27,46,64;" +
    "2012-01-28,36,63;" +
    "2012-01-28,37,61;" +
    "2012-01-29,36,65;" +
    "2012-01-29,35,64;" +
    "2012-01-30,42,61;" +
    "2012-01-30,42,62;" +
    "2012-01-31,44,63;" +
    "2012-01-31,45,64;" +
    "2012-02-01,40,61;" +
    "2012-02-01,41,61;" +
    "2012-02-02,36,66;" +
    "2012-02-02,36,65;" +
    "2012-02-03,33,59;" +
    "2012-02-03,35,58;" +
    "2012-02-04,36,64;" +
    "2012-02-04,35,62;" +
    "2012-02-05,34,65;" +
    "2012-02-05,35,64;" +
    "2012-02-06,39,67;" +
    "2012-02-06,39,69;" +
    "2012-02-07,51,60;" +
    "2012-02-07,51,61;" +
    "2012-02-08,41,65;" +
    "2012-02-08,41,64;" +
    "2012-02-09,41,68;" +
    "2012-02-09,39,68;" +
    "2012-02-10,39,67;" +
    "2012-02-10,40,67;" +
    "2012-02-11,49,58;" +
    "2012-02-11,48,58;" +
    "2012-02-12,47,61;" +
    "2012-02-12,44,63;" +
    "2012-02-13,36,53;" +
    "2012-02-13,44,51;" +
    "2012-02-14,34,60;" +
    "2012-02-14,33,60;" +
    "2012-02-15,38,57;" +
    "2012-02-15,35,57;" +
    "2012-02-16,38,66;" +
    "2012-02-16,43,65;" +
    "2012-02-17,34,68;" +
    "2012-02-17,36,68;" +
    "2012-02-18,41,61;" +
    "2012-02-18,49,62;" +
    "2012-02-19,34,57;" +
    "2012-02-19,35,58;" +
    "2012-02-20,42,58;" +
    "2012-02-20,41,57;" +
    "2012-02-21,39,69;" +
    "2012-02-21,38,70;" +
    "2012-02-22,47,79;" +
    "2012-02-22,47,78;" +
    "2012-02-23,43,77;" +
    "2012-02-23,44,74;" +
    "2012-02-24,50,77;" +
    "2012-02-24,51,77;" +
    "2012-02-25,41,63;" +
    "2012-02-25,41,64;" +
    "2012-02-26,35,60;" +
    "2012-02-26,38,61;" +
    "2012-02-27,36,55;" +
    "2012-02-27,36,56;" +
    "2012-02-28,37,55;" +
    "2012-02-28,36,56;" +
    "2012-02-29,44,56;" +
    "2012-02-29,46,54;" +
    "2012-03-01,38,56;" +
    "2012-03-01,42,56;" +
    "2012-03-02,35,62;" +
    "2012-03-02,37,62;" +
    "2012-03-03,33,68;" +
    "2012-03-03,34,67;" +
    "2012-03-04,39,72;" +
    "2012-03-04,38,72;" +
    "2012-03-05,39,75;" +
    "2012-03-05,40,75;" +
    "2012-03-06,43,58;" +
    "2012-03-06,42,59;" +
    "2012-03-07,38,63;" +
    "2012-03-07,39,62;" +
    "2012-03-08,35,70;" +
    "2012-03-08,35,70;" +
    "2012-03-09,37,76;" +
    "2012-03-09,37,76;" +
    "2012-03-10,38,64;" +
    "2012-03-10,38,66;" +
    "2012-03-11,39,59;" +
    "2012-03-11,45,59;" +
    "2012-03-12,35,63;" +
    "2012-03-12,35,64;" +
    "2012-03-13,52,58;" +
    "2012-03-13,52,58;" +
    "2012-03-14,54,60;" +
    "2012-03-14,53,60;" +
    "2012-03-15,55,64;" +
    "2012-03-15,54,65;" +
    "2012-03-16,47,62;" +
    "2012-03-16,54,61;" +
    "2012-03-17,39,50;" +
    "2012-03-17,39,56;" +
    "2012-03-18,42,54;" +
    "2012-03-18,40,54;" +
    "2012-03-19,33,58;" +
    "2012-03-19,32,57;" +
    "2012-03-20,48,68;" +
    "2012-03-20,46,69;" +
    "2012-03-21,43,73;" +
    "2012-03-21,43,75;" +
    "2012-03-22,41,59;" +
    "2012-03-22,48,64;" +
    "2012-03-23,37,65;" +
    "2012-03-23,36,66;" +
    "2012-03-24,44,52;" +
    "2012-03-24,44,58;" +
    "2012-03-25,40,56;" +
    "2012-03-25,39,56;" +
    "2012-03-26,42,60;" +
    "2012-03-26,41,59;" +
    "2012-03-27,49,60;" +
    "2012-03-27,47,60;" +
    "2012-03-28,48,66;" +
    "2012-03-28,52,67;" +
    "2012-03-29,44,67;" +
    "2012-03-29,43,68;" +
    "2012-03-30,50,68;" +
    "2012-03-30,48,71;" +
    "2012-03-31,46,60;" +
    "2012-03-31,47,63;" +
    "2012-04-01,43,61;" +
    "2012-04-01,42,63;" +
    "2012-04-02,38,67;" +
    "2012-04-02,38,68;" +
    "2012-04-03,46,66;" +
    "2012-04-03,45,68;" +
    "2012-04-04,41,60;" +
    "2012-04-04,41,62;" +
    "2012-04-05,37,58;" +
    "2012-04-05,37,60;" +
    "2012-04-06,35,63;" +
    "2012-04-06,35,64;" +
    "2012-04-07,35,69;" +
    "2012-04-07,36,69;" +
    "2012-04-08,38,74;" +
    "2012-04-08,39,75;" +
    "2012-04-09,39,71;" +
    "2012-04-09,40,73;" +
    "2012-04-10,52,57;" +
    "2012-04-10,51,68;" +
    "2012-04-11,48,60;" +
    "2012-04-11,46,59;" +
    "2012-04-12,46,63;" +
    "2012-04-12,47,63;" +
    "2012-04-13,44,57;" +
    "2012-04-13,44,58;" +
    "2012-04-14,43,65;" +
    "2012-04-14,43,67;" +
    "2012-04-15,43,71;" +
    "2012-04-15,43,73;" +
    "2012-04-16,45,70;" +
    "2012-04-16,43,72;" +
    "2012-04-17,48,71;" +
    "2012-04-17,48,73;" +
    "2012-04-18,48,71;" +
    "2012-04-18,47,73;" +
    "2012-04-19,54,77;" +
    "2012-04-19,53,80;" +
    "2012-04-20,55,88;" +
    "2012-04-20,54,87;" +
    "2012-04-21,54,91;" +
    "2012-04-21,55,91;" +
    "2012-04-22,55,87;" +
    "2012-04-22,58,90;" +
    "2012-04-23,56,72;" +
    "2012-04-23,55,77;" +
    "2012-04-24,54,73;" +
    "2012-04-24,53,74;" +
    "2012-04-25,57,74;" +
    "2012-04-25,57,74;" +
    "2012-04-26,47,63;" +
    "2012-04-26,51,67;" +
    "2012-04-27,41,70;" +
    "2012-04-27,41,71;" +
    "2012-04-28,46,77;" +
    "2012-04-28,45,78;" +
    "2012-04-29,48,84;" +
    "2012-04-29,48,85;" +
    "2012-04-30,52,83;" +
    "2012-04-30,51,83;" +
    "2012-05-01,48,68;" +
    "2012-05-01,47,67;" +
    "2012-05-02,44,67;" +
    "2012-05-02,44,68;" +
    "2012-05-03,50,65;" +
    "2012-05-03,47,67;" +
    "2012-05-04,48,65;" +
    "2012-05-04,49,64;" +
    "2012-05-05,42,79;" +
    "2012-05-05,42,78;" +
    "2012-05-06,46,84;" +
    "2012-05-06,48,84;" +
    "2012-05-07,47,90;" +
    "2012-05-07,49,89;" +
    "2012-05-08,49,93;" +
    "2012-05-08,50,93;" +
    "2012-05-09,51,86;" +
    "2012-05-09,51,89;" +
    "2012-05-10,46,85;" +
    "2012-05-10,46,85;" +
    "2012-05-11,48,90;" +
    "2012-05-11,49,91;" +
    "2012-05-12,51,90;" +
    "2012-05-12,52,90;" +
    "2012-05-13,51,75;" +
    "2012-05-13,51,85;" +
    "2012-05-14,52,72;" +
    "2012-05-14,50,73;" +
    "2012-05-15,51,76;" +
    "2012-05-15,50,78;" +
    "2012-05-16,51,79;" +
    "2012-05-16,50,82;" +
    "2012-05-17,51,70;" +
    "2012-05-17,49,77;" +
    "2012-05-18,48,81;" +
    "2012-05-18,45,81;" +
    "2012-05-19,48,85;" +
    "2012-05-19,47,87;" +
    "2012-05-20,52,91;" +
    "2012-05-20,51,91;" +
    "2012-05-21,53,73;" +
    "2012-05-21,51,79;" +
    "2012-05-22,54,77;" +
    "2012-05-22,54,78;" +
    "2012-05-23,52,75;" +
    "2012-05-23,51,76;" +
    "2012-05-24,49,73;" +
    "2012-05-24,48,73;" +
    "2012-05-25,49,65;" +
    "2012-05-25,47,64;" +
    "2012-05-26,49,66;" +
    "2012-05-26,48,66;" +
    "2012-05-27,50,70;" +
    "2012-05-27,51,72;" +
    "2012-05-28,51,68;" +
    "2012-05-28,50,69;" +
    "2012-05-29,48,77;" +
    "2012-05-29,47,78;" +
    "2012-05-30,49,87;" +
    "2012-05-30,47,87;" +
    "2012-05-31,52,96;" +
    "2012-05-31,51,97;" +
    "2012-06-01,56,94;" +
    "2012-06-01,58,95;" +
    "2012-06-02,53,83;" +
    "2012-06-02,53,85;" +
    "2012-06-03,51,84;" +
    "2012-06-03,50,84;" +
    "2012-06-04,51,63;" +
    "2012-06-04,53,73;" +
    "2012-06-05,50,69;" +
    "2012-06-05,48,69;" +
    "2012-06-06,44,75;" +
    "2012-06-06,45,76;" +
    "2012-06-07,49,78;" +
    "2012-06-07,47,79;" +
    "2012-06-08,51,75;" +
    "2012-06-08,51,76;" +
    "2012-06-09,46,83;" +
    "2012-06-09,45,81;" +
    "2012-06-10,63,91;" +
    "2012-06-10,60,89;" +
    "2012-06-11,57,96;" +
    "2012-06-11,55,96;" +
    "2012-06-12,56,91;" +
    "2012-06-12,55,91;" +
    "2012-06-13,53,85;" +
    "2012-06-13,51,87;" +
    "2012-06-14,53,85;" +
    "2012-06-14,52,86;" +
    "2012-06-15,51,92;" +
    "2012-06-15,49,92;" +
    "2012-06-16,61,106;" +
    "2012-06-16,60,105;" +
    "2012-06-17,60,96;" +
    "2012-06-17,66,102;" +
    "2012-06-18,55,87;" +
    "2012-06-18,54,88;" +
    "2012-06-19,53,89;" +
    "2012-06-19,51,90;" +
    "2012-06-20,56,97;" +
    "2012-06-20,56,97;" +
    "2012-06-21,52,69;" +
    "2012-06-21,52,90;" +
    "2012-06-22,50,73;" +
    "2012-06-22,48,76;" +
    "2012-06-23,54,70;" +
    "2012-06-23,52,72;" +
    "2012-06-24,50,72;" +
    "2012-06-24,49,74;" +
    "2012-06-25,52,74;" +
    "2012-06-25,50,73;" +
    "2012-06-26,52,81;" +
    "2012-06-26,51,82;" +
    "2012-06-27,52,88;" +
    "2012-06-27,50,88;" +
    "2012-06-28,54,82;" +
    "2012-06-28,52,82;" +
    "2012-06-29,58,76;" +
    "2012-06-29,56,77;" +
    "2012-06-30,59,79;" +
    "2012-06-30,58,81;" +
    "2012-07-01,58,74;" +
    "2012-07-01,57,77;" +
    "2012-07-02,55,80;" +
    "2012-07-02,58,82;" +
    "2012-07-03,54,87;" +
    "2012-07-03,51,89;" +
    "2012-07-04,55,90;" +
    "2012-07-04,54,90;" +
    "2012-07-05,54,75;" +
    "2012-07-05,53,81;" +
    "2012-07-06,53,88;" +
    "2012-07-06,51,89;" +
    "2012-07-07,56,96;" +
    "2012-07-07,54,97;" +
    "2012-07-08,54,85;" +
    "2012-07-08,54,90;" +
    "2012-07-09,55,88;" +
    "2012-07-09,54,90;" +
    "2012-07-10,53,101;" +
    "2012-07-10,50,102;" +
    "2012-07-11,61,104;" +
    "2012-07-11,58,104;" +
    "2012-07-12,60,98;" +
    "2012-07-12,63,101;" +
    "2012-07-13,55,74;" +
    "2012-07-13,54,87;" +
    "2012-07-14,56,82;" +
    "2012-07-14,54,87;" +
    "2012-07-15,55,89;" +
    "2012-07-15,54,90;" +
    "2012-07-16,57,75;" +
    "2012-07-16,54,79;" +
    "2012-07-17,56,75;" +
    "2012-07-17,56,76;" +
    "2012-07-18,55,79;" +
    "2012-07-18,53,80;" +
    "2012-07-19,54,79;" +
    "2012-07-19,54,80;" +
    "2012-07-20,57,94;" +
    "2012-07-20,55,95;" +
    "2012-07-21,59,100;" +
    "2012-07-21,55,101;" +
    "2012-07-22,61,96;" +
    "2012-07-22,60,97;" +
    "2012-07-23,60,91;" +
    "2012-07-23,58,92;" +
    "2012-07-24,55,88;" +
    "2012-07-24,54,89;" +
    "2012-07-25,57,83;" +
    "2012-07-25,55,84;" +
    "2012-07-26,56,78;" +
    "2012-07-26,54,80;" +
    "2012-07-27,56,84;" +
    "2012-07-27,54,85;" +
    "2012-07-28,55,83;" +
    "2012-07-28,54,85;" +
    "2012-07-29,55,87;" +
    "2012-07-29,53,88;" +
    "2012-07-30,54,98;" +
    "2012-07-30,53,99;" +
    "2012-07-31,59,95;" +
    "2012-07-31,58,96;" +
    "2012-08-01,55,94;" +
    "2012-08-01,55,96;" +
    "2012-08-02,56,98;" +
    "2012-08-02,55,98;" +
    "2012-08-03,55,88;" +
    "2012-08-03,54,92;" +
    "2012-08-04,55,77;" +
    "2012-08-04,55,83;" +
    "2012-08-05,57,81;" +
    "2012-08-05,58,82;" +
    "2012-08-06,55,92;" +
    "2012-08-06,54,94;" +
    "2012-08-07,58,89;" +
    "2012-08-07,56,90;" +
    "2012-08-08,56,98;" +
    "2012-08-08,53,98;" +
    "2012-08-09,59,103;" +
    "2012-08-09,56,104;" +
    "2012-08-10,63,100;" +
    "2012-08-10,60,101;" +
    "2012-08-11,58,105;" +
    "2012-08-11,56,105;" +
    "2012-08-12,60,99;" +
    "2012-08-12,59,100;" +
    "2012-08-13,65,103;" +
    "2012-08-13,64,103;" +
    "2012-08-14,65,95;" +
    "2012-08-14,67,96;" +
    "2012-08-15,58,89;" +
    "2012-08-15,57,92;" +
    "2012-08-16,57,93;" +
    "2012-08-16,55,95;" +
    "2012-08-17,55,90;" +
    "2012-08-17,54,91;" +
    "2012-08-18,59,92;" +
    "2012-08-18,61,93;" +
    "2012-08-19,55,85;" +
    "2012-08-19,54,87;" +
    "2012-08-20,54,90;" +
    "2012-08-20,52,92;" +
    "2012-08-21,55,86;" +
    "2012-08-21,52,88;" +
    "2012-08-22,55,83;" +
    "2012-08-22,54,85;" +
    "2012-08-23,57,88;" +
    "2012-08-23,55,90;" +
    "2012-08-24,55,88;" +
    "2012-08-24,54,89;" +
    "2012-08-25,55,81;" +
    "2012-08-25,54,82;" +
    "2012-08-26,56,72;" +
    "2012-08-26,55,74;" +
    "2012-08-27,53,90;" +
    "2012-08-27,50,91;" +
    "2012-08-28,58,91;" +
    "2012-08-28,56,92;" +
    "2012-08-29,52,97;" +
    "2012-08-29,52,98;" +
    "2012-08-30,56,90;" +
    "2012-08-30,57,91;" +
    "2012-08-31,56,67;" +
    "2012-08-31,55,78;" +
    "2012-09-01,57,79;" +
    "2012-09-01,55,80;" +
    "2012-09-02,51,92;" +
    "2012-09-02,50,93;" +
    "2012-09-03,54,96;" +
    "2012-09-03,53,97;" +
    "2012-09-04,58,91;" +
    "2012-09-04,56,93;" +
    "2012-09-05,55,85;" +
    "2012-09-05,55,85;" +
    "2012-09-06,56,87;" +
    "2012-09-06,55,89;" +
    "2012-09-07,54,86;" +
    "2012-09-07,52,88;" +
    "2012-09-08,55,80;" +
    "2012-09-08,54,82;" +
    "2012-09-09,54,81;" +
    "2012-09-09,52,82;" +
    "2012-09-10,49,90;" +
    "2012-09-10,46,91;" +
    "2012-09-11,52,88;" +
    "2012-09-11,51,90;" +
    "2012-09-12,55,89;" +
    "2012-09-12,53,90;" +
    "2012-09-13,60,96;" +
    "2012-09-13,57,97;" +
    "2012-09-14,56,88;" +
    "2012-09-14,55,89;" +
    "2012-09-15,53,90;" +
    "2012-09-15,52,91;" +
    "2012-09-16,53,89;" +
    "2012-09-16,53,90;" +
    "2012-09-17,53,78;" +
    "2012-09-17,52,80;" +
    "2012-09-18,53,78;" +
    "2012-09-18,52,79;" +
    "2012-09-19,52,75;" +
    "2012-09-19,52,76;" +
    "2012-09-20,50,81;" +
    "2012-09-20,50,82;" +
    "2012-09-21,52,88;" +
    "2012-09-21,50,89;" +
    "2012-09-22,53,94;" +
    "2012-09-22,52,94;" +
    "2012-09-23,54,93;" +
    "2012-09-23,53,91;" +
    "2012-09-24,53,85;" +
    "2012-09-24,51,84;" +
    "2012-09-25,52,89;" +
    "2012-09-25,51,89;" +
    "2012-09-26,55,87;" +
    "2012-09-26,53,88;" +
    "2012-09-27,55,95;" +
    "2012-09-27,53,94;" +
    "2012-09-28,57,78;" +
    "2012-09-28,56,86;" +
    "2012-09-29,54,93;" +
    "2012-09-29,54,92;" +
    "2012-09-30,56,100;" +
    "2012-09-30,55,99;" +
    "2012-10-01,58,100;" +
    "2012-10-01,58,99;" +
    "2012-10-02,59,103;" +
    "2012-10-02,58,101;" +
    "2012-10-03,60,95;" +
    "2012-10-03,59,96;" +
    "2012-10-04,57,78;" +
    "2012-10-04,56,81;" +
    "2012-10-05,57,74;" +
    "2012-10-05,55,74;" +
    "2012-10-06,52,73;" +
    "2012-10-06,51,73;" +
    "2012-10-07,49,76;" +
    "2012-10-07,48,76;" +
    "2012-10-08,51,73;" +
    "2012-10-08,51,73;" +
    "2012-10-09,48,75;" +
    "2012-10-09,47,75;" +
    "2012-10-10,50,76;" +
    "2012-10-10,49,76;" +
    "2012-10-11,56,62;" +
    "2012-10-11,54,65;" +
    "2012-10-12,52,66;" +
    "2012-10-12,53,64;" +
    "2012-10-13,46,76;" +
    "2012-10-13,45,76;" +
    "2012-10-14,51,80;" +
    "2012-10-14,50,81;" +
    "2012-10-15,53,84;" +
    "2012-10-15,51,84;" +
    "2012-10-16,59,84;" +
    "2012-10-16,58,84;" +
    "2012-10-17,55,87;" +
    "2012-10-17,53,84;" +
    "2012-10-18,53,92;" +
    "2012-10-18,52,91;" +
    "2012-10-19,58,79;" +
    "2012-10-19,59,81;" +
    "2012-10-20,51,71;" +
    "2012-10-20,54,72;" +
    "2012-10-21,49,66;" +
    "2012-10-21,45,68;" +
    "2012-10-22,52,63;" +
    "2012-10-22,50,64;" +
    "2012-10-23,45,64;" +
    "2012-10-23,49,64;" +
    "2012-10-24,45,67;" +
    "2012-10-24,44,66;" +
    "2012-10-25,50,66;" +
    "2012-10-25,52,65;" +
    "2012-10-26,45,76;" +
    "2012-10-26,45,76;" +
    "2012-10-27,48,82;" +
    "2012-10-27,47,80;" +
    "2012-10-28,50,83;" +
    "2012-10-28,50,83;" +
    "2012-10-29,50,81;" +
    "2012-10-29,49,80;" +
    "2012-10-30,48,76;" +
    "2012-10-30,47,77;" +
    "2012-10-31,57,66;" +
    "2012-10-31,56,67;" +
    "2012-11-01,53,69;" +
    "2012-11-01,56,69;" +
    "2012-11-02,48,70;" +
    "2012-11-02,46,71;" +
    "2012-11-03,47,75;" +
    "2012-11-03,46,73;" +
    "2012-11-04,49,79;" +
    "2012-11-04,48,77;" +
    "2012-11-05,49,79;" +
    "2012-11-05,49,77;" +
    "2012-11-06,47,83;" +
    "2012-11-06,48,80;" +
    "2012-11-07,48,73;" +
    "2012-11-07,48,74;" +
    "2012-11-08,46,62;" +
    "2012-11-08,49,61;" +
    "2012-11-09,42,57;" +
    "2012-11-09,40,57;" +
    "2012-11-10,39,59;" +
    "2012-11-10,40,59;" +
    "2012-11-11,34,58;" +
    "2012-11-11,34,56;" +
    "2012-11-12,35,61;" +
    "2012-11-12,35,59;" +
    "2012-11-13,38,68;" +
    "2012-11-13,38,66;" +
    "2012-11-14,43,67;" +
    "2012-11-14,45,65;" +
    "2012-11-15,41,69;" +
    "2012-11-15,39,68;" +
    "2012-11-16,45,67;" +
    "2012-11-16,56,66;" +
    "2012-11-17,54,64;" +
    "2012-11-17,54,62;" +
    "2012-11-18,46,62;" +
    "2012-11-18,45,62;" +
    "2012-11-19,48,68;" +
    "2012-11-19,46,68;" +
    "2012-11-20,47,69;" +
    "2012-11-20,46,67;" +
    "2012-11-21,45,66;" +
    "2012-11-21,56,66;" +
    "2012-11-22,42,66;" +
    "2012-11-22,42,64;" +
    "2012-11-23,38,66;" +
    "2012-11-23,38,64;" +
    "2012-11-24,40,69;" +
    "2012-11-24,39,67;" +
    "2012-11-25,39,67;" +
    "2012-11-25,38,65;" +
    "2012-11-26,38,66;" +
    "2012-11-26,38,65;" +
    "2012-11-27,42,63;" +
    "2012-11-27,48,61;" +
    "2012-11-28,47,64;" +
    "2012-11-28,47,64;" +
    "2012-11-29,52,64;" +
    "2012-11-29,52,63;" +
    "2012-11-30,56,62;" +
    "2012-11-30,56,63;" +
    "2012-12-01,56,66;" +
    "2012-12-01,53,64;" +
    "2012-12-02,46,63;" +
    "2012-12-02,51,61;" +
    "2012-12-03,39,62;" +
    "2012-12-03,38,61;" +
    "2012-12-04,48,63;" +
    "2012-12-04,47,61;" +
    "2012-12-05,56,64;" +
    "2012-12-05,55,62;" +
    "2012-12-06,46,57;" +
    "2012-12-06,52,56;" +
    "2012-12-07,40,65;" +
    "2012-12-07,38,64;" +
    "2012-12-08,37,60;" +
    "2012-12-08,35,61;" +
    "2012-12-09,45,64;" +
    "2012-12-09,43,63;" +
    "2012-12-10,42,67;" +
    "2012-12-10,43,65;" +
    "2012-12-11,39,66;" +
    "2012-12-11,38,67;" +
    "2012-12-12,38,55;" +
    "2012-12-12,46,55;" +
    "2012-12-13,33,52;" +
    "2012-12-13,32,52;" +
    "2012-12-14,31,51;" +
    "2012-12-14,30,51;" +
    "2012-12-15,31,49;" +
    "2012-12-15,30,48;" +
    "2012-12-16,43,57;" +
    "2012-12-16,42,54;" +
    "2012-12-17,43,57;" +
    "2012-12-17,51,56;" +
    "2012-12-18,33,52;" +
    "2012-12-18,35,52;" +
    "2012-12-19,29,51;" +
    "2012-12-19,28,50;" +
    "2012-12-20,29,58;" +
    "2012-12-20,28,59;" +
    "2012-12-21,38,59;" +
    "2012-12-21,39,61;" +
    "2012-12-22,48,56;" +
    "2012-12-22,46,57;" +
    "2012-12-23,48,56;" +
    "2012-12-23,48,55;" +
    "2012-12-24,37,56;" +
    "2012-12-24,38,55;" +
    "2012-12-25,37,52;" +
    "2012-12-25,36,46;" +
    "2012-12-26,42,54;" +
    "2012-12-26,42,54;" +
    "2012-12-27,35,54;" +
    "2012-12-27,39,54;" +
    "2012-12-28,32,46;" +
    "2012-12-28,31,46;" +
    "2012-12-29,35,52;" +
    "2012-12-29,39,52;" +
    "2012-12-30,32,54;" +
    "2012-12-30,31,54;" +
    "2012-12-31,31,52;" +
    "2012-12-31,30,53;"