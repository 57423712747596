export const data = "1985-01-01,35,51;" +
    "1985-01-02,30,53;" +
    "1985-01-03,30,56;" +
    "1985-01-04,28,54;" +
    "1985-01-05,30,56;" +
    "1985-01-06,44,60;" +
    "1985-01-07,45,58;" +
    "1985-01-08,43,57;" +
    "1985-01-09,43,55;" +
    "1985-01-10,36,60;" +
    "1985-01-11,32,56;" +
    "1985-01-12,33,52;" +
    "1985-01-13,26,46;" +
    "1985-01-14,29,49;" +
    "1985-01-15,34,53;" +
    "1985-01-16,35,50;" +
    "1985-01-17,30,66;" +
    "1985-01-18,31,58;" +
    "1985-01-19,31,60;" +
    "1985-01-20,32,46;" +
    "1985-01-21,38,43;" +
    "1985-01-22,39,46;" +
    "1985-01-23,35,42;" +
    "1985-01-24,37,44;" +
    "1985-01-25,39,49;" +
    "1985-01-26,41,50;" +
    "1985-01-27,37,60;" +
    "1985-01-28,44,48;" +
    "1985-01-29,33,53;" +
    "1985-01-30,29,58;" +
    "1985-01-31,29,59;" +
    "1985-02-01,31,57;" +
    "1985-02-02,32,57;" +
    "1985-02-03,30,56;" +
    "1985-02-04,37,53;" +
    "1985-02-05,27,57;" +
    "1985-02-06,29,55;" +
    "1985-02-07,44,56;" +
    "1985-02-08,45,56;" +
    "1985-02-09,32,57;" +
    "1985-02-10,29,54;" +
    "1985-02-11,32,61;" +
    "1985-02-12,38,68;" +
    "1985-02-13,45,67;" +
    "1985-02-14,37,65;" +
    "1985-02-15,37,75;" +
    "1985-02-16,38,74;" +
    "1985-02-17,42,67;" +
    "1985-02-18,38,72;" +
    "1985-02-19,39,64;" +
    "1985-02-20,37,63;" +
    "1985-02-21,46,72;" +
    "1985-02-22,54,74;" +
    "1985-02-23,50,71;" +
    "1985-02-24,40,77;" +
    "1985-02-25,40,70;" +
    "1985-02-26,45,70;" +
    "1985-02-27,44,74;" +
    "1985-02-28,39,75;" +
    "1985-03-01,39,57;" +
    "1985-03-02,37,57;" +
    "1985-03-03,37,63;" +
    "1985-03-04,40,57;" +
    "1985-03-05,36,51;" +
    "1985-03-06,35,54;" +
    "1985-03-07,39,59;" +
    "1985-03-08,39,58;" +
    "1985-03-09,34,63;" +
    "1985-03-10,49,60;" +
    "1985-03-11,45,64;" +
    "1985-03-12,45,58;" +
    "1985-03-13,37,68;" +
    "1985-03-14,40,73;" +
    "1985-03-15,44,65;" +
    "1985-03-16,47,63;" +
    "1985-03-17,44,61;" +
    "1985-03-18,45,58;" +
    "1985-03-19,48,67;" +
    "1985-03-20,41,69;" +
    "1985-03-21,38,64;" +
    "1985-03-22,35,68;" +
    "1985-03-23,42,68;" +
    "1985-03-24,40,59;" +
    "1985-03-25,35,59;" +
    "1985-03-26,37,52;" +
    "1985-03-27,40,54;" +
    "1985-03-28,39,58;" +
    "1985-03-29,32,65;" +
    "1985-03-30,37,73;" +
    "1985-03-31,41,75;" +
    "1985-04-01,46,78;" +
    "1985-04-02,47,82;" +
    "1985-04-03,47,85;" +
    "1985-04-04,50,81;" +
    "1985-04-05,51,82;" +
    "1985-04-06,51,70;" +
    "1985-04-07,50,75;" +
    "1985-04-08,44,85;" +
    "1985-04-09,48,74;" +
    "1985-04-10,47,71;" +
    "1985-04-11,47,73;" +
    "1985-04-12,44,85;" +
    "1985-04-13,49,90;" +
    "1985-04-14,53,94;" +
    "1985-04-15,52,77;" +
    "1985-04-16,49,66;" +
    "1985-04-17,49,63;" +
    "1985-04-18,48,65;" +
    "1985-04-19,42,65;" +
    "1985-04-20,45,58;" +
    "1985-04-21,48,62;" +
    "1985-04-22,48,72;" +
    "1985-04-23,43,77;" +
    "1985-04-24,41,77;" +
    "1985-04-25,42,70;" +
    "1985-04-26,45,78;" +
    "1985-04-27,49,86;" +
    "1985-04-28,48,74;" +
    "1985-04-29,48,78;" +
    "1985-04-30,45,85;" +
    "1985-05-01,46,82;" +
    "1985-05-02,51,71;" +
    "1985-05-03,50,71;" +
    "1985-05-04,43,80;" +
    "1985-05-05,43,82;" +
    "1985-05-06,47,68;" +
    "1985-05-07,46,69;" +
    "1985-05-08,41,69;" +
    "1985-05-09,42,68;" +
    "1985-05-10,50,64;" +
    "1985-05-11,42,70;" +
    "1985-05-12,39,80;" +
    "1985-05-13,45,87;" +
    "1985-05-14,48,86;" +
    "1985-05-15,50,86;" +
    "1985-05-16,51,82;" +
    "1985-05-17,47,72;" +
    "1985-05-18,49,73;" +
    "1985-05-19,51,77;" +
    "1985-05-20,47,81;" +
    "1985-05-21,46,87;" +
    "1985-05-22,50,86;" +
    "1985-05-23,55,79;" +
    "1985-05-24,52,74;" +
    "1985-05-25,52,69;" +
    "1985-05-26,53,69;" +
    "1985-05-27,53,68;" +
    "1985-05-28,50,69;" +
    "1985-05-29,44,75;" +
    "1985-05-30,45,73;" +
    "1985-05-31,52,66;" +
    "1985-06-01,49,69;" +
    "1985-06-02,50,74;" +
    "1985-06-03,48,77;" +
    "1985-06-04,58,82;" +
    "1985-06-05,54,86;" +
    "1985-06-06,55,90;" +
    "1985-06-07,56,84;" +
    "1985-06-08,55,92;" +
    "1985-06-09,57,97;" +
    "1985-06-10,57,101;" +
    "1985-06-11,60,105;" +
    "1985-06-12,59,94;" +
    "1985-06-13,57,96;" +
    "1985-06-14,57,96;" +
    "1985-06-15,58,100;" +
    "1985-06-16,65,100;" +
    "1985-06-17,60,91;" +
    "1985-06-18,56,91;" +
    "1985-06-19,54,75;" +
    "1985-06-20,55,85;" +
    "1985-06-21,57,82;" +
    "1985-06-22,56,78;" +
    "1985-06-23,56,86;" +
    "1985-06-24,55,81;" +
    "1985-06-25,49,92;" +
    "1985-06-26,53,98;" +
    "1985-06-27,58,101;" +
    "1985-06-28,58,96;" +
    "1985-06-29,53,83;" +
    "1985-06-30,49,92;" +
    "1985-07-01,56,103;" +
    "1985-07-02,62,99;" +
    "1985-07-03,58,99;" +
    "1985-07-04,59,97;" +
    "1985-07-05,60,99;" +
    "1985-07-06,59,93;" +
    "1985-07-07,59,92;" +
    "1985-07-08,60,102;" +
    "1985-07-09,63,102;" +
    "1985-07-10,68,90;" +
    "1985-07-11,57,84;" +
    "1985-07-12,55,87;" +
    "1985-07-13,53,101;" +
    "1985-07-14,57,93;" +
    "1985-07-15,52,100;" +
    "1985-07-16,56,95;" +
    "1985-07-17,52,93;" +
    "1985-07-18,54,90;" +
    "1985-07-19,55,90;" +
    "1985-07-20,58,75;" +
    "1985-07-21,56,88;" +
    "1985-07-22,56,89;" +
    "1985-07-23,55,97;" +
    "1985-07-24,60,104;" +
    "1985-07-25,60,93;" +
    "1985-07-26,60,92;" +
    "1985-07-27,56,92;" +
    "1985-07-28,56,84;" +
    "1985-07-29,56,75;" +
    "1985-07-30,57,75;" +
    "1985-07-31,56,77;" +
    "1985-08-01,56,78;" +
    "1985-08-02,52,95;" +
    "1985-08-03,56,97;" +
    "1985-08-04,57,92;" +
    "1985-08-05,55,85;" +
    "1985-08-06,52,90;" +
    "1985-08-07,53,85;" +
    "1985-08-08,57,91;" +
    "1985-08-09,55,97;" +
    "1985-08-10,54,89;" +
    "1985-08-11,53,88;" +
    "1985-08-12,55,80;" +
    "1985-08-13,55,79;" +
    "1985-08-14,52,79;" +
    "1985-08-15,55,75;" +
    "1985-08-16,55,77;" +
    "1985-08-17,57,75;" +
    "1985-08-18,49,80;" +
    "1985-08-19,53,78;" +
    "1985-08-20,54,85;" +
    "1985-08-21,49,82;" +
    "1985-08-22,50,90;" +
    "1985-08-23,50,96;" +
    "1985-08-24,56,95;" +
    "1985-08-25,55,90;" +
    "1985-08-26,55,93;" +
    "1985-08-27,55,89;" +
    "1985-08-28,49,91;" +
    "1985-08-29,52,81;" +
    "1985-08-30,60,93;" +
    "1985-08-31,55,95;" +
    "1985-09-01,67,86;" +
    "1985-09-02,60,75;" +
    "1985-09-03,50,85;" +
    "1985-09-04,54,80;" +
    "1985-09-05,54,79;" +
    "1985-09-06,56,78;" +
    "1985-09-07,57,76;" +
    "1985-09-08,56,68;" +
    "1985-09-09,51,70;" +
    "1985-09-10,47,72;" +
    "1985-09-11,48,73;" +
    "1985-09-12,45,82;" +
    "1985-09-13,49,85;" +
    "1985-09-14,58,75;" +
    "1985-09-15,47,83;" +
    "1985-09-16,51,80;" +
    "1985-09-17,51,75;" +
    "1985-09-18,49,80;" +
    "1985-09-19,48,84;" +
    "1985-09-20,50,87;" +
    "1985-09-21,51,92;" +
    "1985-09-22,53,94;" +
    "1985-09-23,55,94;" +
    "1985-09-24,55,96;" +
    "1985-09-25,55,93;" +
    "1985-09-26,59,84;" +
    "1985-09-27,60,72;" +
    "1985-09-28,55,70;" +
    "1985-09-29,53,67;" +
    "1985-09-30,44,72;" +
    "1985-10-01,47,85;" +
    "1985-10-02,51,89;" +
    "1985-10-03,59,92;" +
    "1985-10-04,56,97;" +
    "1985-10-05,55,94;" +
    "1985-10-06,60,82;" +
    "1985-10-07,55,73;" +
    "1985-10-08,49,67;" +
    "1985-10-09,46,70;" +
    "1985-10-10,42,78;" +
    "1985-10-11,57,77;" +
    "1985-10-12,45,76;" +
    "1985-10-13,41,77;" +
    "1985-10-14,44,83;" +
    "1985-10-15,42,85;" +
    "1985-10-16,43,83;" +
    "1985-10-17,50,80;" +
    "1985-10-18,48,72;" +
    "1985-10-19,47,66;" +
    "1985-10-20,48,69;" +
    "1985-10-21,49,61;" +
    "1985-10-22,41,68;" +
    "1985-10-23,55,73;" +
    "1985-10-24,49,79;" +
    "1985-10-25,46,78;" +
    "1985-10-26,46,81;" +
    "1985-10-27,45,82;" +
    "1985-10-28,48,71;" +
    "1985-10-29,44,64;" +
    "1985-10-30,47,68;" +
    "1985-10-31,41,72;" +
    "1985-11-01,43,80;" +
    "1985-11-02,50,78;" +
    "1985-11-03,44,76;" +
    "1985-11-04,53,77;" +
    "1985-11-05,47,75;" +
    "1985-11-06,47,72;" +
    "1985-11-07,47,79;" +
    "1985-11-08,41,63;" +
    "1985-11-09,38,60;" +
    "1985-11-10,44,51;" +
    "1985-11-11,36,50;" +
    "1985-11-12,32,52;" +
    "1985-11-13,27,52;" +
    "1985-11-14,28,56;" +
    "1985-11-15,31,56;" +
    "1985-11-16,42,50;" +
    "1985-11-17,43,60;" +
    "1985-11-18,30,58;" +
    "1985-11-19,27,55;" +
    "1985-11-20,40,51;" +
    "1985-11-21,39,54;" +
    "1985-11-22,32,55;" +
    "1985-11-23,44,47;" +
    "1985-11-24,44,56;" +
    "1985-11-25,46,53;" +
    "1985-11-26,35,63;" +
    "1985-11-27,44,54;" +
    "1985-11-28,46,61;" +
    "1985-11-29,44,55;" +
    "1985-11-30,38,55;" +
    "1985-12-01,38,55;" +
    "1985-12-02,50,68;" +
    "1985-12-03,49,69;" +
    "1985-12-04,44,60;" +
    "1985-12-05,47,62;" +
    "1985-12-06,40,59;" +
    "1985-12-07,42,60;" +
    "1985-12-08,36,56;" +
    "1985-12-09,34,56;" +
    "1985-12-10,33,50;" +
    "1985-12-11,35,50;" +
    "1985-12-12,26,52;" +
    "1985-12-13,25,49;" +
    "1985-12-14,31,51;" +
    "1985-12-15,32,52;" +
    "1985-12-16,28,63;" +
    "1985-12-17,29,59;" +
    "1985-12-18,30,57;" +
    "1985-12-19,29,56;" +
    "1985-12-20,29,60;" +
    "1985-12-21,29,51;" +
    "1985-12-22,28,55;" +
    "1985-12-23,29,55;" +
    "1985-12-24,30,55;" +
    "1985-12-25,28,52;" +
    "1985-12-26,25,58;" +
    "1985-12-27,29,48;" +
    "1985-12-28,25,43;" +
    "1985-12-29,35,45;" +
    "1985-12-30,42,59;" +
    "1985-12-31,45,59;"