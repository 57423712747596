export const data = "2000-01-01,40,53;" +
    "2000-01-01,45,52;" +
    "2000-01-02,27,56;" +
    "2000-01-02,30,56;" +
    "2000-01-03,30,56;" +
    "2000-01-03,33,56;" +
    "2000-01-04,32,58;" +
    "2000-01-04,35,58;" +
    "2000-01-05,41,61;" +
    "2000-01-05,43,60;" +
    "2000-01-06,36,58;" +
    "2000-01-06,41,59;" +
    "2000-01-07,32,59;" +
    "2000-01-07,35,61;" +
    "2000-01-08,35,60;" +
    "2000-01-08,39,60;" +
    "2000-01-09,43,59;" +
    "2000-01-09,40,59;" +
    "2000-01-10,46,57;" +
    "2000-01-10,45,56;" +
    "2000-01-11,45,59;" +
    "2000-01-11,45,59;" +
    "2000-01-12,40,54;" +
    "2000-01-12,42,54;" +
    "2000-01-13,37,64;" +
    "2000-01-13,38,64;" +
    "2000-01-14,40,62;" +
    "2000-01-14,38,64;" +
    "2000-01-15,49,68;" +
    "2000-01-15,50,66;" +
    "2000-01-16,44,63;" +
    "2000-01-16,49,64;" +
    "2000-01-17,44,50;" +
    "2000-01-17,45,51;" +
    "2000-01-18,49,65;" +
    "2000-01-18,47,67;" +
    "2000-01-19,51,63;" +
    "2000-01-19,52,63;" +
    "2000-01-20,44,58;" +
    "2000-01-20,51,58;" +
    "2000-01-21,41,62;" +
    "2000-01-21,41,64;" +
    "2000-01-22,45,60;" +
    "2000-01-22,45,61;" +
    "2000-01-23,51,58;" +
    "2000-01-23,50,57;" +
    "2000-01-24,52,58;" +
    "2000-01-24,53,57;" +
    "2000-01-25,46,60;" +
    "2000-01-25,51,61;" +
    "2000-01-26,38,56;" +
    "2000-01-26,40,57;" +
    "2000-01-27,38,60;" +
    "2000-01-27,41,60;" +
    "2000-01-28,34,55;" +
    "2000-01-28,36,54;" +
    "2000-01-29,39,60;" +
    "2000-01-29,40,60;" +
    "2000-01-30,45,58;" +
    "2000-01-30,47,60;" +
    "2000-01-31,46,55;" +
    "2000-01-31,47,54;" +
    "2000-02-01,45,62;" +
    "2000-02-01,45,61;" +
    "2000-02-02,47,58;" +
    "2000-02-02,48,57;" +
    "2000-02-03,44,70;" +
    "2000-02-03,47,70;" +
    "2000-02-04,43,60;" +
    "2000-02-04,43,62;" +
    "2000-02-05,45,62;" +
    "2000-02-05,46,62;" +
    "2000-02-06,40,61;" +
    "2000-02-06,39,61;" +
    "2000-02-07,40,64;" +
    "2000-02-07,42,64;" +
    "2000-02-08,45,68;" +
    "2000-02-08,45,72;" +
    "2000-02-09,48,60;" +
    "2000-02-09,49,59;" +
    "2000-02-10,42,56;" +
    "2000-02-10,49,59;" +
    "2000-02-11,41,56;" +
    "2000-02-11,41,56;" +
    "2000-02-12,42,57;" +
    "2000-02-12,43,57;" +
    "2000-02-13,49,59;" +
    "2000-02-13,48,59;" +
    "2000-02-14,46,60;" +
    "2000-02-14,50,61;" +
    "2000-02-15,39,60;" +
    "2000-02-15,40,61;" +
    "2000-02-16,43,55;" +
    "2000-02-16,47,55;" +
    "2000-02-17,36,58;" +
    "2000-02-17,38,60;" +
    "2000-02-18,40,60;" +
    "2000-02-18,40,60;" +
    "2000-02-19,40,63;" +
    "2000-02-19,42,63;" +
    "2000-02-20,50,69;" +
    "2000-02-20,52,68;" +
    "2000-02-21,45,63;" +
    "2000-02-21,48,64;" +
    "2000-02-22,44,55;" +
    "2000-02-22,46,59;" +
    "2000-02-23,37,53;" +
    "2000-02-23,40,54;" +
    "2000-02-24,33,51;" +
    "2000-02-24,35,52;" +
    "2000-02-25,44,57;" +
    "2000-02-25,44,57;" +
    "2000-02-26,50,63;" +
    "2000-02-26,49,64;" +
    "2000-02-27,48,57;" +
    "2000-02-27,49,57;" +
    "2000-02-28,44,56;" +
    "2000-02-28,45,57;" +
    "2000-02-29,39,57;" +
    "2000-02-29,45,59;" +
    "2000-03-01,37,61;" +
    "2000-03-01,37,63;" +
    "2000-03-02,47,55;" +
    "2000-03-02,47,56;" +
    "2000-03-03,39,64;" +
    "2000-03-03,40,66;" +
    "2000-03-04,42,62;" +
    "2000-03-04,42,65;" +
    "2000-03-05,42,52;" +
    "2000-03-05,45,55;" +
    "2000-03-06,43,54;" +
    "2000-03-06,43,57;" +
    "2000-03-07,42,53;" +
    "2000-03-07,43,53;" +
    "2000-03-08,39,54;" +
    "2000-03-08,38,54;" +
    "2000-03-09,41,58;" +
    "2000-03-09,42,58;" +
    "2000-03-10,35,64;" +
    "2000-03-10,36,66;" +
    "2000-03-11,44,63;" +
    "2000-03-11,51,64;" +
    "2000-03-12,41,67;" +
    "2000-03-12,42,70;" +
    "2000-03-13,39,69;" +
    "2000-03-13,42,71;" +
    "2000-03-14,48,73;" +
    "2000-03-14,48,73;" +
    "2000-03-15,42,75;" +
    "2000-03-15,44,78;" +
    "2000-03-16,44,67;" +
    "2000-03-16,49,70;" +
    "2000-03-17,37,72;" +
    "2000-03-17,39,74;" +
    "2000-03-18,42,76;" +
    "2000-03-18,44,79;" +
    "2000-03-19,45,62;" +
    "2000-03-19,51,69;" +
    "2000-03-20,43,64;" +
    "2000-03-20,43,65;" +
    "2000-03-21,44,72;" +
    "2000-03-21,53,73;" +
    "2000-03-22,39,74;" +
    "2000-03-22,44,77;" +
    "2000-03-23,44,63;" +
    "2000-03-23,46,65;" +
    "2000-03-24,39,65;" +
    "2000-03-24,41,68;" +
    "2000-03-25,43,63;" +
    "2000-03-25,44,67;" +
    "2000-03-26,40,66;" +
    "2000-03-26,43,69;" +
    "2000-03-27,47,55;" +
    "2000-03-27,47,54;" +
    "2000-03-28,43,62;" +
    "2000-03-28,46,64;" +
    "2000-03-29,37,67;" +
    "2000-03-29,39,70;" +
    "2000-03-30,40,74;" +
    "2000-03-30,43,75;" +
    "2000-03-31,61,79;" +
    "2000-03-31,59,79;" +
    "2000-04-01,52,82;" +
    "2000-04-01,60,83;" +
    "2000-04-02,47,85;" +
    "2000-04-02,50,88;" +
    "2000-04-03,48,82;" +
    "2000-04-03,51,85;" +
    "2000-04-04,49,68;" +
    "2000-04-04,51,73;" +
    "2000-04-05,47,67;" +
    "2000-04-05,50,70;" +
    "2000-04-06,43,69;" +
    "2000-04-06,47,72;" +
    "2000-04-07,38,79;" +
    "2000-04-07,41,81;" +
    "2000-04-08,48,62;" +
    "2000-04-08,48,76;" +
    "2000-04-09,48,66;" +
    "2000-04-09,50,69;" +
    "2000-04-10,40,74;" +
    "2000-04-10,44,77;" +
    "2000-04-11,44,81;" +
    "2000-04-11,46,83;" +
    "2000-04-12,55,83;" +
    "2000-04-12,56,83;" +
    "2000-04-13,53,66;" +
    "2000-04-13,56,67;" +
    "2000-04-14,49,65;" +
    "2000-04-14,50,67;" +
    "2000-04-15,51,61;" +
    "2000-04-15,50,64;" +
    "2000-04-16,52,65;" +
    "2000-04-16,52,65;" +
    "2000-04-17,43,59;" +
    "2000-04-17,50,62;" +
    "2000-04-18,37,65;" +
    "2000-04-18,39,67;" +
    "2000-04-19,40,68;" +
    "2000-04-19,42,71;" +
    "2000-04-20,45,72;" +
    "2000-04-20,47,74;" +
    "2000-04-21,47,66;" +
    "2000-04-21,47,70;" +
    "2000-04-22,48,64;" +
    "2000-04-22,51,66;" +
    "2000-04-23,43,65;" +
    "2000-04-23,47,68;" +
    "2000-04-24,39,73;" +
    "2000-04-24,42,76;" +
    "2000-04-25,43,71;" +
    "2000-04-25,46,74;" +
    "2000-04-26,44,82;" +
    "2000-04-26,47,85;" +
    "2000-04-27,46,67;" +
    "2000-04-27,50,78;" +
    "2000-04-28,44,63;" +
    "2000-04-28,46,66;" +
    "2000-04-29,38,72;" +
    "2000-04-29,40,75;" +
    "2000-04-30,40,81;" +
    "2000-04-30,45,84;" +
    "2000-05-01,45,75;" +
    "2000-05-01,47,78;" +
    "2000-05-02,51,75;" +
    "2000-05-02,57,77;" +
    "2000-05-03,48,75;" +
    "2000-05-03,49,78;" +
    "2000-05-04,52,72;" +
    "2000-05-04,52,73;" +
    "2000-05-05,49,67;" +
    "2000-05-05,51,69;" +
    "2000-05-06,48,61;" +
    "2000-05-06,48,64;" +
    "2000-05-07,50,61;" +
    "2000-05-07,49,61;" +
    "2000-05-08,52,71;" +
    "2000-05-08,56,71;" +
    "2000-05-09,52,68;" +
    "2000-05-09,53,71;" +
    "2000-05-10,46,63;" +
    "2000-05-10,49,64;" +
    "2000-05-11,40,67;" +
    "2000-05-11,42,69;" +
    "2000-05-12,40,72;" +
    "2000-05-12,42,74;" +
    "2000-05-13,48,69;" +
    "2000-05-13,49,71;" +
    "2000-05-14,43,65;" +
    "2000-05-14,44,66;" +
    "2000-05-15,49,66;" +
    "2000-05-15,51,66;" +
    "2000-05-16,49,63;" +
    "2000-05-16,48,64;" +
    "2000-05-17,53,72;" +
    "2000-05-17,53,75;" +
    "2000-05-18,47,83;" +
    "2000-05-18,48,85;" +
    "2000-05-19,52,90;" +
    "2000-05-19,53,92;" +
    "2000-05-20,53,93;" +
    "2000-05-20,56,96;" +
    "2000-05-21,57,98;" +
    "2000-05-21,61,100;" +
    "2000-05-22,59,96;" +
    "2000-05-22,62,99;" +
    "2000-05-23,61,93;" +
    "2000-05-23,65,96;" +
    "2000-05-24,54,80;" +
    "2000-05-24,60,83;" +
    "2000-05-25,51,71;" +
    "2000-05-25,53,74;" +
    "2000-05-26,52,77;" +
    "2000-05-26,53,79;" +
    "2000-05-27,55,81;" +
    "2000-05-27,54,83;" +
    "2000-05-28,54,83;" +
    "2000-05-28,55,85;" +
    "2000-05-29,50,76;" +
    "2000-05-29,51,78;" +
    "2000-05-30,46,75;" +
    "2000-05-30,48,76;" +
    "2000-05-31,47,83;" +
    "2000-05-31,47,83;" +
    "2000-06-01,47,88;" +
    "2000-06-01,51,90;" +
    "2000-06-02,52,79;" +
    "2000-06-02,53,83;" +
    "2000-06-03,50,81;" +
    "2000-06-03,50,83;" +
    "2000-06-04,50,79;" +
    "2000-06-04,50,81;" +
    "2000-06-05,53,72;" +
    "2000-06-05,52,74;" +
    "2000-06-06,50,76;" +
    "2000-06-06,50,78;" +
    "2000-06-07,55,72;" +
    "2000-06-07,56,73;" +
    "2000-06-08,53,66;" +
    "2000-06-08,52,68;" +
    "2000-06-09,52,69;" +
    "2000-06-09,53,70;" +
    "2000-06-10,51,72;" +
    "2000-06-10,51,73;" +
    "2000-06-11,47,80;" +
    "2000-06-11,48,81;" +
    "2000-06-12,,;" +
    "2000-06-12,56,88;" +
    "2000-06-13,56,99;" +
    "2000-06-13,58,99;" +
    "2000-06-14,69,107;" +
    "2000-06-14,76,106;" +
    "2000-06-15,65,103;" +
    "2000-06-15,67,105;" +
    "2000-06-16,62,95;" +
    "2000-06-16,63,99;" +
    "2000-06-17,58,81;" +
    "2000-06-17,58,88;" +
    "2000-06-18,56,76;" +
    "2000-06-18,60,77;" +
    "2000-06-19,56,89;" +
    "2000-06-19,56,90;" +
    "2000-06-20,59,96;" +
    "2000-06-20,60,97;" +
    "2000-06-21,60,96;" +
    "2000-06-21,61,97;" +
    "2000-06-22,54,81;" +
    "2000-06-22,59,91;" +
    "2000-06-23,53,79;" +
    "2000-06-23,53,82;" +
    "2000-06-24,54,83;" +
    "2000-06-24,54,84;" +
    "2000-06-25,56,91;" +
    "2000-06-25,56,93;" +
    "2000-06-26,55,95;" +
    "2000-06-26,57,96;" +
    "2000-06-27,60,94;" +
    "2000-06-27,61,95;" +
    "2000-06-28,59,93;" +
    "2000-06-28,60,94;" +
    "2000-06-29,56,82;" +
    "2000-06-29,57,89;" +
    "2000-06-30,53,76;" +
    "2000-06-30,56,78;" +
    "2000-07-01,53,77;" +
    "2000-07-01,54,79;" +
    "2000-07-02,52,70;" +
    "2000-07-02,53,72;" +
    "2000-07-03,52,73;" +
    "2000-07-03,54,74;" +
    "2000-07-04,51,80;" +
    "2000-07-04,50,81;" +
    "2000-07-05,54,69;" +
    "2000-07-05,55,71;" +
    "2000-07-06,54,72;" +
    "2000-07-06,54,73;" +
    "2000-07-07,52,72;" +
    "2000-07-07,54,73;" +
    "2000-07-08,55,81;" +
    "2000-07-08,56,82;" +
    "2000-07-09,54,87;" +
    "2000-07-09,54,88;" +
    "2000-07-10,53,85;" +
    "2000-07-10,55,86;" +
    "2000-07-11,55,85;" +
    "2000-07-11,55,86;" +
    "2000-07-12,57,79;" +
    "2000-07-12,57,82;" +
    "2000-07-13,55,77;" +
    "2000-07-13,55,79;" +
    "2000-07-14,52,90;" +
    "2000-07-14,53,90;" +
    "2000-07-15,53,83;" +
    "2000-07-15,55,85;" +
    "2000-07-16,56,70;" +
    "2000-07-16,56,72;" +
    "2000-07-17,55,70;" +
    "2000-07-17,58,71;" +
    "2000-07-18,56,88;" +
    "2000-07-18,55,89;" +
    "2000-07-19,54,88;" +
    "2000-07-19,54,90;" +
    "2000-07-20,54,89;" +
    "2000-07-20,53,89;" +
    "2000-07-21,54,78;" +
    "2000-07-21,54,80;" +
    "2000-07-22,56,86;" +
    "2000-07-22,56,87;" +
    "2000-07-23,51,96;" +
    "2000-07-23,54,97;" +
    "2000-07-24,53,97;" +
    "2000-07-24,55,98;" +
    "2000-07-25,54,88;" +
    "2000-07-25,54,91;" +
    "2000-07-26,56,79;" +
    "2000-07-26,55,82;" +
    "2000-07-27,55,85;" +
    "2000-07-27,55,87;" +
    "2000-07-28,55,92;" +
    "2000-07-28,54,93;" +
    "2000-07-29,56,97;" +
    "2000-07-29,56,99;" +
    "2000-07-30,62,98;" +
    "2000-07-30,63,98;" +
    "2000-07-31,62,103;" +
    "2000-07-31,63,103;" +
    "2000-08-01,66,104;" +
    "2000-08-01,67,104;" +
    "2000-08-02,65,99;" +
    "2000-08-02,67,100;" +
    "2000-08-03,62,94;" +
    "2000-08-03,64,94;" +
    "2000-08-04,58,91;" +
    "2000-08-04,58,92;" +
    "2000-08-05,57,92;" +
    "2000-08-05,58,94;" +
    "2000-08-06,56,83;" +
    "2000-08-06,56,88;" +
    "2000-08-07,56,71;" +
    "2000-08-07,57,76;" +
    "2000-08-08,57,81;" +
    "2000-08-08,57,83;" +
    "2000-08-09,56,80;" +
    "2000-08-09,56,82;" +
    "2000-08-10,55,79;" +
    "2000-08-10,56,81;" +
    "2000-08-11,52,96;" +
    "2000-08-11,53,96;" +
    "2000-08-12,56,96;" +
    "2000-08-12,58,97;" +
    "2000-08-13,51,93;" +
    "2000-08-13,54,94;" +
    "2000-08-14,56,94;" +
    "2000-08-14,56,95;" +
    "2000-08-15,53,95;" +
    "2000-08-15,54,97;" +
    "2000-08-16,53,98;" +
    "2000-08-16,56,100;" +
    "2000-08-17,56,91;" +
    "2000-08-17,58,92;" +
    "2000-08-18,54,83;" +
    "2000-08-18,54,84;" +
    "2000-08-19,55,80;" +
    "2000-08-19,54,79;" +
    "2000-08-20,50,85;" +
    "2000-08-20,50,87;" +
    "2000-08-21,51,90;" +
    "2000-08-21,54,91;" +
    "2000-08-22,54,84;" +
    "2000-08-22,55,85;" +
    "2000-08-23,56,75;" +
    "2000-08-23,56,78;" +
    "2000-08-24,55,93;" +
    "2000-08-24,57,94;" +
    "2000-08-25,56,94;" +
    "2000-08-25,58,95;" +
    "2000-08-26,55,87;" +
    "2000-08-26,55,89;" +
    "2000-08-27,55,90;" +
    "2000-08-27,56,92;" +
    "2000-08-28,57,84;" +
    "2000-08-28,57,85;" +
    "2000-08-29,56,64;" +
    "2000-08-29,56,76;" +
    "2000-08-30,59,71;" +
    "2000-08-30,59,72;" +
    "2000-08-31,57,73;" +
    "2000-08-31,58,75;" +
    "2000-09-01,57,66;" +
    "2000-09-01,57,68;" +
    "2000-09-02,56,73;" +
    "2000-09-02,56,74;" +
    "2000-09-03,50,72;" +
    "2000-09-03,52,73;" +
    "2000-09-04,52,73;" +
    "2000-09-04,53,74;" +
    "2000-09-05,46,81;" +
    "2000-09-05,47,80;" +
    "2000-09-06,48,86;" +
    "2000-09-06,50,85;" +
    "2000-09-07,50,93;" +
    "2000-09-07,52,92;" +
    "2000-09-08,52,91;" +
    "2000-09-08,54,91;" +
    "2000-09-09,49,85;" +
    "2000-09-09,52,87;" +
    "2000-09-10,47,85;" +
    "2000-09-10,49,86;" +
    "2000-09-11,50,94;" +
    "2000-09-11,52,95;" +
    "2000-09-12,55,91;" +
    "2000-09-12,55,88;" +
    "2000-09-13,64,97;" +
    "2000-09-13,66,97;" +
    "2000-09-14,59,79;" +
    "2000-09-14,66,86;" +
    "2000-09-15,57,78;" +
    "2000-09-15,58,79;" +
    "2000-09-16,58,86;" +
    "2000-09-16,58,88;" +
    "2000-09-17,54,96;" +
    "2000-09-17,56,93;" +
    "2000-09-18,59,101;" +
    "2000-09-18,59,100;" +
    "2000-09-19,59,103;" +
    "2000-09-19,61,103;" +
    "2000-09-20,62,99;" +
    "2000-09-20,64,100;" +
    "2000-09-21,60,77;" +
    "2000-09-21,60,82;" +
    "2000-09-22,56,67;" +
    "2000-09-22,59,71;" +
    "2000-09-23,51,80;" +
    "2000-09-23,51,82;" +
    "2000-09-24,49,84;" +
    "2000-09-24,50,85;" +
    "2000-09-25,51,90;" +
    "2000-09-25,53,89;" +
    "2000-09-26,54,86;" +
    "2000-09-26,56,88;" +
    "2000-09-27,56,74;" +
    "2000-09-27,57,75;" +
    "2000-09-28,51,66;" +
    "2000-09-28,58,68;" +
    "2000-09-29,48,89;" +
    "2000-09-29,49,89;" +
    "2000-09-30,54,95;" +
    "2000-09-30,56,94;" +
    "2000-10-01,57,91;" +
    "2000-10-02,54,79;" +
    "2000-10-03,49,80;" +
    "2000-10-04,53,76;" +
    "2000-10-05,52,82;" +
    "2000-10-06,54,76;" +
    "2000-10-07,55,71;" +
    "2000-10-08,53,82;" +
    "2000-10-09,54,67;" +
    "2000-10-10,54,62;" +
    "2000-10-11,47,61;" +
    "2000-10-12,46,65;" +
    "2000-10-13,44,73;" +
    "2000-10-14,46,75;" +
    "2000-10-15,50,77;" +
    "2000-10-16,46,79;" +
    "2000-10-17,48,82;" +
    "2000-10-18,49,80;" +
    "2000-10-19,53,81;" +
    "2000-10-20,49,70;" +
    "2000-10-21,46,72;" +
    "2000-10-22,57,73;" +
    "2000-10-23,43,75;" +
    "2000-10-24,44,80;" +
    "2000-10-25,55,65;" +
    "2000-10-26,53,61;" +
    "2000-10-27,51,65;" +
    "2000-10-28,46,64;" +
    "2000-10-29,48,62;" +
    "2000-10-30,47,58;" +
    "2000-10-31,41,68;" +
    "2000-11-01,39,67;" +
    "2000-11-01,41,67;" +
    "2000-11-02,44,71;" +
    "2000-11-02,44,70;" +
    "2000-11-03,46,71;" +
    "2000-11-03,48,70;" +
    "2000-11-04,42,74;" +
    "2000-11-04,43,74;" +
    "2000-11-05,49,67;" +
    "2000-11-05,52,68;" +
    "2000-11-06,42,69;" +
    "2000-11-06,44,69;" +
    "2000-11-07,44,69;" +
    "2000-11-07,54,69;" +
    "2000-11-08,40,63;" +
    "2000-11-08,42,63;" +
    "2000-11-09,41,58;" +
    "2000-11-09,43,59;" +
    "2000-11-10,37,55;" +
    "2000-11-10,40,58;" +
    "2000-11-11,32,56;" +
    "2000-11-11,34,56;" +
    "2000-11-12,28,59;" +
    "2000-11-12,30,61;" +
    "2000-11-13,30,54;" +
    "2000-11-13,31,56;" +
    "2000-11-14,32,54;" +
    "2000-11-14,33,54;" +
    "2000-11-15,31,55;" +
    "2000-11-15,33,56;" +
    "2000-11-16,34,56;" +
    "2000-11-16,42,55;" +
    "2000-11-17,31,61;" +
    "2000-11-17,33,61;" +
    "2000-11-18,28,62;" +
    "2000-11-18,31,61;" +
    "2000-11-19,32,62;" +
    "2000-11-19,35,61;" +
    "2000-11-20,31,60;" +
    "2000-11-20,33,60;" +
    "2000-11-21,37,53;" +
    "2000-11-21,39,54;" +
    "2000-11-22,39,54;" +
    "2000-11-22,44,56;" +
    "2000-11-23,40,55;" +
    "2000-11-23,40,56;" +
    "2000-11-24,39,59;" +
    "2000-11-24,44,58;" +
    "2000-11-25,33,59;" +
    "2000-11-25,35,58;" +
    "2000-11-26,37,60;" +
    "2000-11-26,37,60;" +
    "2000-11-27,36,54;" +
    "2000-11-27,38,53;" +
    "2000-11-28,42,49;" +
    "2000-11-28,43,49;" +
    "2000-11-29,39,60;" +
    "2000-11-29,46,62;" +
    "2000-11-30,35,58;" +
    "2000-11-30,36,58;" +
    "2000-12-01,34,58;" +
    "2000-12-01,37,57;" +
    "2000-12-02,31,60;" +
    "2000-12-02,34,60;" +
    "2000-12-03,33,55;" +
    "2000-12-03,35,53;" +
    "2000-12-04,37,62;" +
    "2000-12-04,39,62;" +
    "2000-12-05,37,62;" +
    "2000-12-05,38,61;" +
    "2000-12-06,36,55;" +
    "2000-12-06,36,58;" +
    "2000-12-07,45,66;" +
    "2000-12-07,45,68;" +
    "2000-12-08,43,65;" +
    "2000-12-08,48,66;" +
    "2000-12-09,41,56;" +
    "2000-12-09,43,56;" +
    "2000-12-10,49,54;" +
    "2000-12-10,51,54;" +
    "2000-12-11,43,53;" +
    "2000-12-11,44,53;" +
    "2000-12-12,39,57;" +
    "2000-12-12,44,58;" +
    "2000-12-13,41,57;" +
    "2000-12-13,42,58;" +
    "2000-12-14,50,56;" +
    "2000-12-14,50,55;" +
    "2000-12-15,43,61;" +
    "2000-12-15,52,63;" +
    "2000-12-16,40,63;" +
    "2000-12-16,43,62;" +
    "2000-12-17,37,62;" +
    "2000-12-17,38,61;" +
    "2000-12-18,38,53;" +
    "2000-12-18,41,55;" +
    "2000-12-19,34,62;" +
    "2000-12-19,36,61;" +
    "2000-12-20,35,62;" +
    "2000-12-20,38,61;" +
    "2000-12-21,32,59;" +
    "2000-12-21,34,60;" +
    "2000-12-22,39,59;" +
    "2000-12-22,42,60;" +
    "2000-12-23,32,55;" +
    "2000-12-23,35,56;" +
    "2000-12-24,38,60;" +
    "2000-12-24,42,59;" +
    "2000-12-25,35,59;" +
    "2000-12-25,40,57;" +
    "2000-12-26,32,59;" +
    "2000-12-26,34,59;" +
    "2000-12-27,32,61;" +
    "2000-12-27,34,61;" +
    "2000-12-28,31,66;" +
    "2000-12-28,34,65;" +
    "2000-12-29,30,60;" +
    "2000-12-29,32,58;" +
    "2000-12-30,28,62;" +
    "2000-12-30,31,61;" +
    "2000-12-31,26,62;" +
    "2000-12-31,31,62;"