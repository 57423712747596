export const data = "2016-01-01,28,47;" +
    "2016-01-01,31,46;" +
    "2016-01-02,37,48;" +
    "2016-01-02,38,46;" +
    "2016-01-03,38,51;" +
    "2016-01-03,38,50;" +
    "2016-01-04,44,61;" +
    "2016-01-04,44,61;" +
    "2016-01-05,44,57;" +
    "2016-01-05,48,55;" +
    "2016-01-06,43,55;" +
    "2016-01-06,44,54;" +
    "2016-01-07,36,52;" +
    "2016-01-07,38,51;" +
    "2016-01-08,35,52;" +
    "2016-01-08,34,51;" +
    "2016-01-09,46,55;" +
    "2016-01-09,45,54;" +
    "2016-01-10,47,53;" +
    "2016-01-10,46,52;" +
    "2016-01-11,44,57;" +
    "2016-01-11,44,56;" +
    "2016-01-12,43,59;" +
    "2016-01-12,42,58;" +
    "2016-01-13,42,62;" +
    "2016-01-13,49,60;" +
    "2016-01-14,37,55;" +
    "2016-01-14,37,54;" +
    "2016-01-15,44,58;" +
    "2016-01-15,44,57;" +
    "2016-01-16,49,63;" +
    "2016-01-16,47,61;" +
    "2016-01-17,49,61;" +
    "2016-01-17,48,60;" +
    "2016-01-18,50,64;" +
    "2016-01-18,49,64;" +
    "2016-01-19,49,62;" +
    "2016-01-19,50,61;" +
    "2016-01-20,43,62;" +
    "2016-01-20,41,62;" +
    "2016-01-21,46,63;" +
    "2016-01-21,44,61;" +
    "2016-01-22,52,62;" +
    "2016-01-22,52,60;" +
    "2016-01-23,47,58;" +
    "2016-01-23,49,57;" +
    "2016-01-24,42,57;" +
    "2016-01-24,43,57;" +
    "2016-01-25,45,59;" +
    "2016-01-25,44,58;" +
    "2016-01-26,38,60;" +
    "2016-01-26,37,60;" +
    "2016-01-27,39,61;" +
    "2016-01-27,43,61;" +
    "2016-01-28,37,62;" +
    "2016-01-28,37,61;" +
    "2016-01-29,54,65;" +
    "2016-01-29,52,65;" +
    "2016-01-30,49,60;" +
    "2016-01-30,47,60;" +
    "2016-01-31,43,55;" +
    "2016-01-31,42,55;" +
    "2016-02-01,36,56;" +
    "2016-02-01,36,56;" +
    "2016-02-02,39,56;" +
    "2016-02-02,37,54;" +
    "2016-02-03,36,54;" +
    "2016-02-03,34,54;" +
    "2016-02-04,41,56;" +
    "2016-02-04,42,56;" +
    "2016-02-05,34,59;" +
    "2016-02-05,35,60;" +
    "2016-02-06,37,64;" +
    "2016-02-06,37,64;" +
    "2016-02-07,40,69;" +
    "2016-02-07,44,68;" +
    "2016-02-08,44,73;" +
    "2016-02-08,44,72;" +
    "2016-02-09,41,69;" +
    "2016-02-09,40,67;" +
    "2016-02-10,40,72;" +
    "2016-02-10,41,72;" +
    "2016-02-11,42,70;" +
    "2016-02-11,43,70;" +
    "2016-02-12,43,72;" +
    "2016-02-12,44,72;" +
    "2016-02-13,42,72;" +
    "2016-02-13,43,74;" +
    "2016-02-14,42,75;" +
    "2016-02-14,43,76;" +
    "2016-02-15,50,75;" +
    "2016-02-15,52,75;" +
    "2016-02-16,45,72;" +
    "2016-02-16,46,70;" +
    "2016-02-17,49,69;" +
    "2016-02-17,54,70;" +
    "2016-02-18,44,61;" +
    "2016-02-18,46,62;" +
    "2016-02-19,39,62;" +
    "2016-02-19,39,62;" +
    "2016-02-20,42,59;" +
    "2016-02-20,41,60;" +
    "2016-02-21,37,67;" +
    "2016-02-21,37,67;" +
    "2016-02-22,39,67;" +
    "2016-02-22,38,67;" +
    "2016-02-23,39,72;" +
    "2016-02-23,41,71;" +
    "2016-02-24,37,69;" +
    "2016-02-24,39,68;" +
    "2016-02-25,43,71;" +
    "2016-02-25,43,71;" +
    "2016-02-26,45,71;" +
    "2016-02-26,47,72;" +
    "2016-02-27,47,70;" +
    "2016-02-27,50,69;" +
    "2016-02-28,42,67;" +
    "2016-02-28,43,68;" +
    "2016-02-29,41,72;" +
    "2016-02-29,43,72;" +
    "2016-03-01,45,73;" +
    "2016-03-01,45,74;" +
    "2016-03-02,44,73;" +
    "2016-03-02,44,75;" +
    "2016-03-03,53,68;" +
    "2016-03-03,52,68;" +
    "2016-03-04,57,66;" +
    "2016-03-04,57,66;" +
    "2016-03-05,53,66;" +
    "2016-03-05,56,65;" +
    "2016-03-06,49,60;" +
    "2016-03-06,46,60;" +
    "2016-03-07,42,54;" +
    "2016-03-07,44,54;" +
    "2016-03-08,35,59;" +
    "2016-03-08,36,58;" +
    "2016-03-09,47,68;" +
    "2016-03-09,46,67;" +
    "2016-03-10,48,66;" +
    "2016-03-10,48,66;" +
    "2016-03-11,49,58;" +
    "2016-03-11,48,58;" +
    "2016-03-12,46,61;" +
    "2016-03-12,44,59;" +
    "2016-03-13,54,59;" +
    "2016-03-13,52,59;" +
    "2016-03-14,43,61;" +
    "2016-03-14,49,61;" +
    "2016-03-15,40,68;" +
    "2016-03-15,38,68;" +
    "2016-03-16,39,71;" +
    "2016-03-16,40,70;" +
    "2016-03-17,43,74;" +
    "2016-03-17,44,74;" +
    "2016-03-18,44,72;" +
    "2016-03-18,45,74;" +
    "2016-03-19,47,72;" +
    "2016-03-19,47,74;" +
    "2016-03-20,46,71;" +
    "2016-03-20,46,72;" +
    "2016-03-21,44,64;" +
    "2016-03-21,45,64;" +
    "2016-03-22,40,63;" +
    "2016-03-22,41,63;" +
    "2016-03-23,39,68;" +
    "2016-03-23,39,68;" +
    "2016-03-24,41,71;" +
    "2016-03-24,41,72;" +
    "2016-03-25,48,72;" +
    "2016-03-25,48,74;" +
    "2016-03-26,42,73;" +
    "2016-03-26,42,73;" +
    "2016-03-27,50,65;" +
    "2016-03-27,51,66;" +
    "2016-03-28,44,60;" +
    "2016-03-28,44,60;" +
    "2016-03-29,36,65;" +
    "2016-03-29,37,63;" +
    "2016-03-30,38,66;" +
    "2016-03-30,38,67;" +
    "2016-03-31,48,71;" +
    "2016-03-31,47,71;" +
    "2016-04-01,47,69;" +
    "2016-04-01,46,69;" +
    "2016-04-02,49,77;" +
    "2016-04-02,48,78;" +
    "2016-04-03,50,72;" +
    "2016-04-03,49,73;" +
    "2016-04-04,50,74;" +
    "2016-04-04,48,74;" +
    "2016-04-05,45,81;" +
    "2016-04-05,45,79;" +
    "2016-04-06,48,85;" +
    "2016-04-06,49,84;" +
    "2016-04-07,53,78;" +
    "2016-04-07,53,80;" +
    "2016-04-08,55,69;" +
    "2016-04-08,54,68;" +
    "2016-04-09,56,64;" +
    "2016-04-09,54,63;" +
    "2016-04-10,54,59;" +
    "2016-04-10,52,58;" +
    "2016-04-11,53,66;" +
    "2016-04-11,52,65;" +
    "2016-04-12,50,62;" +
    "2016-04-12,50,63;" +
    "2016-04-13,50,68;" +
    "2016-04-13,49,68;" +
    "2016-04-14,47,65;" +
    "2016-04-14,47,64;" +
    "2016-04-15,39,72;" +
    "2016-04-15,40,72;" +
    "2016-04-16,44,80;" +
    "2016-04-16,45,79;" +
    "2016-04-17,45,82;" +
    "2016-04-17,47,82;" +
    "2016-04-18,48,88;" +
    "2016-04-18,49,88;" +
    "2016-04-19,49,84;" +
    "2016-04-19,50,84;" +
    "2016-04-20,45,79;" +
    "2016-04-20,46,80;" +
    "2016-04-21,55,74;" +
    "2016-04-21,54,75;" +
    "2016-04-22,50,61;" +
    "2016-04-22,51,66;" +
    "2016-04-23,47,69;" +
    "2016-04-23,46,69;" +
    "2016-04-24,50,68;" +
    "2016-04-24,51,67;" +
    "2016-04-25,43,68;" +
    "2016-04-25,43,68;" +
    "2016-04-26,41,69;" +
    "2016-04-26,41,69;" +
    "2016-04-27,50,63;" +
    "2016-04-27,48,62;" +
    "2016-04-28,43,74;" +
    "2016-04-28,45,75;" +
    "2016-04-29,48,72;" +
    "2016-04-29,47,71;" +
    "2016-04-30,48,79;" +
    "2016-04-30,47,79;" +
    "2016-05-01,47,83;" +
    "2016-05-01,49,84;" +
    "2016-05-02,48,81;" +
    "2016-05-02,49,82;" +
    "2016-05-03,53,79;" +
    "2016-05-03,51,80;" +
    "2016-05-04,55,72;" +
    "2016-05-04,53,74;" +
    "2016-05-05,54,68;" +
    "2016-05-05,55,67;" +
    "2016-05-06,53,61;" +
    "2016-05-06,53,63;" +
    "2016-05-07,53,63;" +
    "2016-05-07,51,63;" +
    "2016-05-08,53,72;" +
    "2016-05-08,52,72;" +
    "2016-05-09,53,73;" +
    "2016-05-09,52,74;" +
    "2016-05-10,52,82;" +
    "2016-05-10,51,82;" +
    "2016-05-11,52,81;" +
    "2016-05-11,51,82;" +
    "2016-05-12,53,79;" +
    "2016-05-12,51,80;" +
    "2016-05-13,54,70;" +
    "2016-05-13,53,76;" +
    "2016-05-14,51,73;" +
    "2016-05-14,50,74;" +
    "2016-05-15,55,76;" +
    "2016-05-15,55,76;" +
    "2016-05-16,54,82;" +
    "2016-05-16,53,82;" +
    "2016-05-17,51,90;" +
    "2016-05-17,51,90;" +
    "2016-05-18,54,93;" +
    "2016-05-18,56,93;" +
    "2016-05-19,52,72;" +
    "2016-05-19,53,86;" +
    "2016-05-20,48,62;" +
    "2016-05-20,48,61;" +
    "2016-05-21,50,67;" +
    "2016-05-21,49,66;" +
    "2016-05-22,49,70;" +
    "2016-05-22,48,68;" +
    "2016-05-23,50,70;" +
    "2016-05-23,50,71;" +
    "2016-05-24,55,69;" +
    "2016-05-24,54,69;" +
    "2016-05-25,55,71;" +
    "2016-05-25,54,72;" +
    "2016-05-26,53,79;" +
    "2016-05-26,52,80;" +
    "2016-05-27,51,87;" +
    "2016-05-27,48,87;" +
    "2016-05-28,56,89;" +
    "2016-05-28,56,90;" +
    "2016-05-29,54,86;" +
    "2016-05-29,54,86;" +
    "2016-05-30,54,94;" +
    "2016-05-30,53,94;" +
    "2016-05-31,56,98;" +
    "2016-05-31,56,99;" +
    "2016-06-01,56,94;" +
    "2016-06-01,55,95;" +
    "2016-06-02,54,96;" +
    "2016-06-02,54,96;" +
    "2016-06-03,57,101;" +
    "2016-06-03,57,101;" +
    "2016-06-04,64,97;" +
    "2016-06-04,64,96;" +
    "2016-06-05,56,93;" +
    "2016-06-05,55,94;" +
    "2016-06-06,55,80;" +
    "2016-06-06,54,92;" +
    "2016-06-07,57,84;" +
    "2016-06-07,56,85;" +
    "2016-06-08,57,75;" +
    "2016-06-08,56,77;" +
    "2016-06-09,57,73;" +
    "2016-06-09,56,74;" +
    "2016-06-10,53,75;" +
    "2016-06-10,52,76;" +
    "2016-06-11,48,89;" +
    "2016-06-11,48,88;" +
    "2016-06-12,55,86;" +
    "2016-06-12,54,87;" +
    "2016-06-13,56,75;" +
    "2016-06-13,54,80;" +
    "2016-06-14,54,71;" +
    "2016-06-14,52,69;" +
    "2016-06-15,53,68;" +
    "2016-06-15,51,67;" +
    "2016-06-16,49,78;" +
    "2016-06-16,48,78;" +
    "2016-06-17,55,77;" +
    "2016-06-17,52,78;" +
    "2016-06-18,54,83;" +
    "2016-06-18,53,82;" +
    "2016-06-19,54,92;" +
    "2016-06-19,54,92;" +
    "2016-06-20,55,92;" +
    "2016-06-20,54,93;" +
    "2016-06-21,57,98;" +
    "2016-06-21,56,98;" +
    "2016-06-22,56,85;" +
    "2016-06-22,54,92;" +
    "2016-06-23,54,87;" +
    "2016-06-23,51,87;" +
    "2016-06-24,54,89;" +
    "2016-06-24,53,89;" +
    "2016-06-25,55,97;" +
    "2016-06-25,56,97;" +
    "2016-06-26,58,100;" +
    "2016-06-26,57,100;" +
    "2016-06-27,61,102;" +
    "2016-06-27,61,101;" +
    "2016-06-28,64,98;" +
    "2016-06-28,63,98;" +
    "2016-06-29,55,83;" +
    "2016-06-29,56,91;" +
    "2016-06-30,54,98;" +
    "2016-06-30,53,99;" +
    "2016-07-01,59,91;" +
    "2016-07-01,57,91;" +
    "2016-07-02,57,89;" +
    "2016-07-02,56,89;" +
    "2016-07-03,56,89;" +
    "2016-07-03,55,90;" +
    "2016-07-04,56,84;" +
    "2016-07-04,54,87;" +
    "2016-07-05,55,81;" +
    "2016-07-05,55,82;" +
    "2016-07-06,54,78;" +
    "2016-07-06,53,79;" +
    "2016-07-07,54,76;" +
    "2016-07-07,53,77;" +
    "2016-07-08,56,85;" +
    "2016-07-08,55,85;" +
    "2016-07-09,57,81;" +
    "2016-07-09,58,80;" +
    "2016-07-10,56,82;" +
    "2016-07-10,54,83;" +
    "2016-07-11,55,90;" +
    "2016-07-11,54,90;" +
    "2016-07-12,55,85;" +
    "2016-07-12,52,86;" +
    "2016-07-13,54,98;" +
    "2016-07-13,54,98;" +
    "2016-07-14,59,99;" +
    "2016-07-14,59,99;" +
    "2016-07-15,56,94;" +
    "2016-07-15,55,95;" +
    "2016-07-16,57,88;" +
    "2016-07-16,56,89;" +
    "2016-07-17,55,81;" +
    "2016-07-17,53,85;" +
    "2016-07-18,57,75;" +
    "2016-07-18,55,76;" +
    "2016-07-19,55,80;" +
    "2016-07-19,54,82;" +
    "2016-07-20,54,88;" +
    "2016-07-20,52,88;" +
    "2016-07-21,53,82;" +
    "2016-07-21,52,83;" +
    "2016-07-22,52,93;" +
    "2016-07-22,50,93;" +
    "2016-07-23,56,101;" +
    "2016-07-23,56,100;" +
    "2016-07-24,59,101;" +
    "2016-07-24,58,101;" +
    "2016-07-25,56,97;" +
    "2016-07-25,54,97;" +
    "2016-07-26,61,105;" +
    "2016-07-26,59,105;" +
    "2016-07-27,65,102;" +
    "2016-07-27,64,101;" +
    "2016-07-28,63,102;" +
    "2016-07-28,63,102;" +
    "2016-07-29,64,102;" +
    "2016-07-29,62,102;" +
    "2016-07-30,61,97;" +
    "2016-07-30,59,97;" +
    "2016-07-31,57,87;" +
    "2016-07-31,55,89;" +
    "2016-08-01,57,87;" +
    "2016-08-01,56,89;" +
    "2016-08-02,56,92;" +
    "2016-08-02,54,93;" +
    "2016-08-03,57,93;" +
    "2016-08-03,56,94;" +
    "2016-08-04,55,76;" +
    "2016-08-04,53,83;" +
    "2016-08-05,56,82;" +
    "2016-08-05,56,83;" +
    "2016-08-06,55,82;" +
    "2016-08-06,54,83;" +
    "2016-08-07,54,86;" +
    "2016-08-07,52,86;" +
    "2016-08-08,55,88;" +
    "2016-08-08,53,88;" +
    "2016-08-09,55,90;" +
    "2016-08-09,53,90;" +
    "2016-08-10,57,89;" +
    "2016-08-10,55,89;" +
    "2016-08-11,56,90;" +
    "2016-08-11,54,91;" +
    "2016-08-12,58,93;" +
    "2016-08-12,56,94;" +
    "2016-08-13,60,98;" +
    "2016-08-13,58,98;" +
    "2016-08-14,60,96;" +
    "2016-08-14,61,96;" +
    "2016-08-15,56,89;" +
    "2016-08-15,55,90;" +
    "2016-08-16,57,93;" +
    "2016-08-16,55,94;" +
    "2016-08-17,57,96;" +
    "2016-08-17,56,97;" +
    "2016-08-18,58,92;" +
    "2016-08-18,57,92;" +
    "2016-08-19,58,87;" +
    "2016-08-19,56,88;" +
    "2016-08-20,57,85;" +
    "2016-08-20,56,86;" +
    "2016-08-21,57,85;" +
    "2016-08-21,55,86;" +
    "2016-08-22,56,86;" +
    "2016-08-22,55,85;" +
    "2016-08-23,57,86;" +
    "2016-08-23,55,87;" +
    "2016-08-24,56,86;" +
    "2016-08-24,55,87;" +
    "2016-08-25,56,83;" +
    "2016-08-25,54,84;" +
    "2016-08-26,57,80;" +
    "2016-08-26,56,81;" +
    "2016-08-27,57,78;" +
    "2016-08-27,58,79;" +
    "2016-08-28,57,85;" +
    "2016-08-28,55,86;" +
    "2016-08-29,56,82;" +
    "2016-08-29,55,82;" +
    "2016-08-30,56,87;" +
    "2016-08-30,56,87;" +
    "2016-08-31,57,82;" +
    "2016-08-31,55,80;" +
    "2016-09-01,56,80;" +
    "2016-09-01,54,81;" +
    "2016-09-02,53,85;" +
    "2016-09-02,52,85;" +
    "2016-09-03,55,77;" +
    "2016-09-03,53,76;" +
    "2016-09-04,55,78;" +
    "2016-09-04,54,79;" +
    "2016-09-05,49,86;" +
    "2016-09-05,48,85;" +
    "2016-09-06,51,95;" +
    "2016-09-06,50,93;" +
    "2016-09-07,54,97;" +
    "2016-09-07,54,97;" +
    "2016-09-08,58,84;" +
    "2016-09-08,58,90;" +
    "2016-09-09,55,91;" +
    "2016-09-09,53,90;" +
    "2016-09-10,55,88;" +
    "2016-09-10,54,88;" +
    "2016-09-11,55,78;" +
    "2016-09-11,54,79;" +
    "2016-09-12,54,74;" +
    "2016-09-12,54,74;" +
    "2016-09-13,48,73;" +
    "2016-09-13,47,74;" +
    "2016-09-14,52,82;" +
    "2016-09-14,49,82;" +
    "2016-09-15,52,86;" +
    "2016-09-15,50,85;" +
    "2016-09-16,52,89;" +
    "2016-09-16,50,90;" +
    "2016-09-17,53,98;" +
    "2016-09-17,52,97;" +
    "2016-09-18,55,100;" +
    "2016-09-18,55,98;" +
    "2016-09-19,61,101;" +
    "2016-09-19,59,100;" +
    "2016-09-20,61,89;" +
    "2016-09-20,60,89;" +
    "2016-09-21,56,74;" +
    "2016-09-21,56,82;" +
    "2016-09-22,53,72;" +
    "2016-09-22,52,73;" +
    "2016-09-23,49,81;" +
    "2016-09-23,46,81;" +
    "2016-09-24,52,90;" +
    "2016-09-24,51,88;" +
    "2016-09-25,55,94;" +
    "2016-09-25,54,93;" +
    "2016-09-26,58,102;" +
    "2016-09-26,57,101;" +
    "2016-09-27,56,99;" +
    "2016-09-27,57,99;" +
    "2016-09-28,57,92;" +
    "2016-09-28,57,92;" +
    "2016-09-29,53,82;" +
    "2016-09-29,53,83;" +
    "2016-09-30,52,69;" +
    "2016-09-30,51,73;" +
    "2016-10-01,48,74;" +
    "2016-10-01,48,75;" +
    "2016-10-02,52,69;" +
    "2016-10-02,51,69;" +
    "2016-10-03,47,65;" +
    "2016-10-03,48,64;" +
    "2016-10-04,51,73;" +
    "2016-10-04,49,73;" +
    "2016-10-05,46,76;" +
    "2016-10-05,46,75;" +
    "2016-10-06,46,80;" +
    "2016-10-06,45,79;" +
    "2016-10-07,49,84;" +
    "2016-10-07,47,82;" +
    "2016-10-08,47,89;" +
    "2016-10-08,47,86;" +
    "2016-10-09,50,92;" +
    "2016-10-09,49,90;" +
    "2016-10-10,51,81;" +
    "2016-10-10,52,81;" +
    "2016-10-11,55,70;" +
    "2016-10-11,54,70;" +
    "2016-10-12,51,75;" +
    "2016-10-12,50,74;" +
    "2016-10-13,48,75;" +
    "2016-10-13,47,75;" +
    "2016-10-14,60,68;" +
    "2016-10-14,58,68;" +
    "2016-10-15,56,74;" +
    "2016-10-15,56,74;" +
    "2016-10-16,,;" +
    "2016-10-16,58,65;" +
    "2016-10-17,50,70;" +
    "2016-10-17,53,70;" +
    "2016-10-18,48,73;" +
    "2016-10-18,48,72;" +
    "2016-10-19,47,78;" +
    "2016-10-19,46,76;" +
    "2016-10-20,47,81;" +
    "2016-10-20,46,81;" +
    "2016-10-21,49,83;" +
    "2016-10-21,48,83;" +
    "2016-10-22,50,77;" +
    "2016-10-22,49,77;" +
    "2016-10-23,47,76;" +
    "2016-10-23,46,76;" +
    "2016-10-24,59,68;" +
    "2016-10-24,56,67;" +
    "2016-10-25,58,75;" +
    "2016-10-25,61,73;" +
    "2016-10-26,50,77;" +
    "2016-10-26,49,76;" +
    "2016-10-27,56,64;" +
    "2016-10-27,55,69;" +
    "2016-10-28,59,69;" +
    "2016-10-28,58,68;" +
    "2016-10-29,54,72;" +
    "2016-10-29,56,73;" +
    "2016-10-30,53,65;" +
    "2016-10-30,50,67;" +
    "2016-10-31,48,66;" +
    "2016-10-31,46,65;" +
    "2016-11-01,48,66;" +
    "2016-11-01,52,66;" +
    "2016-11-02,44,68;" +
    "2016-11-02,43,67;" +
    "2016-11-03,45,70;" +
    "2016-11-03,46,68;" +
    "2016-11-04,46,73;" +
    "2016-11-04,46,72;" +
    "2016-11-05,43,72;" +
    "2016-11-05,43,72;" +
    "2016-11-06,55,71;" +
    "2016-11-06,53,71;" +
    "2016-11-07,52,71;" +
    "2016-11-07,52,70;" +
    "2016-11-08,51,74;" +
    "2016-11-08,50,72;" +
    "2016-11-09,50,74;" +
    "2016-11-09,48,73;" +
    "2016-11-10,49,74;" +
    "2016-11-10,49,73;" +
    "2016-11-11,49,76;" +
    "2016-11-11,49,76;" +
    "2016-11-12,52,76;" +
    "2016-11-12,54,76;" +
    "2016-11-13,47,74;" +
    "2016-11-13,47,73;" +
    "2016-11-14,46,73;" +
    "2016-11-14,46,72;" +
    "2016-11-15,53,68;" +
    "2016-11-15,51,67;" +
    "2016-11-16,40,61;" +
    "2016-11-16,43,61;" +
    "2016-11-17,35,60;" +
    "2016-11-17,36,60;" +
    "2016-11-18,38,69;" +
    "2016-11-18,36,67;" +
    "2016-11-19,42,67;" +
    "2016-11-19,42,64;" +
    "2016-11-20,52,62;" +
    "2016-11-20,52,60;" +
    "2016-11-21,44,62;" +
    "2016-11-21,46,62;" +
    "2016-11-22,40,62;" +
    "2016-11-22,39,62;" +
    "2016-11-23,37,61;" +
    "2016-11-23,45,61;" +
    "2016-11-24,33,60;" +
    "2016-11-24,33,58;" +
    "2016-11-25,34,58;" +
    "2016-11-25,35,57;" +
    "2016-11-26,41,56;" +
    "2016-11-26,41,55;" +
    "2016-11-27,47,58;" +
    "2016-11-27,46,57;" +
    "2016-11-28,38,58;" +
    "2016-11-28,46,58;" +
    "2016-11-29,35,59;" +
    "2016-11-29,35,58;" +
    "2016-11-30,33,57;" +
    "2016-11-30,33,57;" +
    "2016-12-01,35,60;" +
    "2016-12-01,35,59;" +
    "2016-12-02,42,60;" +
    "2016-12-02,42,58;" +
    "2016-12-03,37,64;" +
    "2016-12-03,37,63;" +
    "2016-12-04,33,62;" +
    "2016-12-04,35,63;" +
    "2016-12-05,43,53;" +
    "2016-12-05,45,52;" +
    "2016-12-06,34,55;" +
    "2016-12-06,35,55;" +
    "2016-12-07,32,49;" +
    "2016-12-07,33,47;" +
    "2016-12-08,42,62;" +
    "2016-12-08,41,59;" +
    "2016-12-09,53,64;" +
    "2016-12-09,53,65;" +
    "2016-12-10,51,62;" +
    "2016-12-10,55,61;" +
    "2016-12-11,43,53;" +
    "2016-12-11,43,56;" +
    "2016-12-12,44,54;" +
    "2016-12-12,44,53;" +
    "2016-12-13,45,58;" +
    "2016-12-13,44,58;" +
    "2016-12-14,50,63;" +
    "2016-12-14,48,64;" +
    "2016-12-15,49,63;" +
    "2016-12-15,54,62;" +
    "2016-12-16,34,53;" +
    "2016-12-16,43,57;" +
    "2016-12-17,29,51;" +
    "2016-12-17,28,51;" +
    "2016-12-18,26,50;" +
    "2016-12-18,27,49;" +
    "2016-12-19,26,51;" +
    "2016-12-19,27,50;" +
    "2016-12-20,31,57;" +
    "2016-12-20,30,55;" +
    "2016-12-21,38,62;" +
    "2016-12-21,39,59;" +
    "2016-12-22,35,63;" +
    "2016-12-22,37,63;" +
    "2016-12-23,39,54;" +
    "2016-12-23,40,53;" +
    "2016-12-24,33,52;" +
    "2016-12-24,35,53;" +
    "2016-12-25,28,52;" +
    "2016-12-25,29,52;" +
    "2016-12-26,30,50;" +
    "2016-12-26,32,49;" +
    "2016-12-27,31,56;" +
    "2016-12-27,30,55;" +
    "2016-12-28,30,56;" +
    "2016-12-28,30,55;" +
    "2016-12-29,34,55;" +
    "2016-12-29,36,54;" +
    "2016-12-30,34,62;" +
    "2016-12-30,36,62;" +
    "2016-12-31,34,52;" +
    "2016-12-31,37,52;"