export const data = "2007-01-01,31,64;" +
    "2007-01-01,31,63;" +
    "2007-01-02,38,62;" +
    "2007-01-02,43,62;" +
    "2007-01-03,33,62;" +
    "2007-01-03,36,63;" +
    "2007-01-04,41,56;" +
    "2007-01-04,46,56;" +
    "2007-01-05,35,55;" +
    "2007-01-05,38,54;" +
    "2007-01-06,28,55;" +
    "2007-01-06,29,57;" +
    "2007-01-07,33,60;" +
    "2007-01-07,35,60;" +
    "2007-01-08,39,64;" +
    "2007-01-08,39,64;" +
    "2007-01-09,33,64;" +
    "2007-01-09,34,62;" +
    "2007-01-10,31,57;" +
    "2007-01-10,33,59;" +
    "2007-01-11,27,50;" +
    "2007-01-11,29,51;" +
    "2007-01-12,26,44;" +
    "2007-01-12,28,44;" +
    "2007-01-13,21,49;" +
    "2007-01-13,22,48;" +
    "2007-01-14,20,46;" +
    "2007-01-14,22,46;" +
    "2007-01-15,22,52;" +
    "2007-01-15,26,51;" +
    "2007-01-16,21,53;" +
    "2007-01-16,23,53;" +
    "2007-01-17,31,57;" +
    "2007-01-17,32,57;" +
    "2007-01-18,28,61;" +
    "2007-01-18,29,58;" +
    "2007-01-19,37,61;" +
    "2007-01-19,34,63;" +
    "2007-01-20,28,64;" +
    "2007-01-20,30,62;" +
    "2007-01-21,36,62;" +
    "2007-01-21,40,60;" +
    "2007-01-22,30,60;" +
    "2007-01-22,33,59;" +
    "2007-01-23,28,58;" +
    "2007-01-23,29,58;" +
    "2007-01-24,30,62;" +
    "2007-01-24,31,60;" +
    "2007-01-25,31,61;" +
    "2007-01-25,31,63;" +
    "2007-01-26,36,59;" +
    "2007-01-26,38,58;" +
    "2007-01-27,46,54;" +
    "2007-01-27,46,54;" +
    "2007-01-28,41,55;" +
    "2007-01-28,46,55;" +
    "2007-01-29,39,64;" +
    "2007-01-29,41,64;" +
    "2007-01-30,33,64;" +
    "2007-01-30,35,64;" +
    "2007-01-31,44,57;" +
    "2007-01-31,44,58;" +
    "2007-02-01,40,51;" +
    "2007-02-01,41,50;" +
    "2007-02-02,37,54;" +
    "2007-02-02,44,54;" +
    "2007-02-03,31,58;" +
    "2007-02-03,32,57;" +
    "2007-02-04,34,65;" +
    "2007-02-04,36,65;" +
    "2007-02-05,38,67;" +
    "2007-02-05,40,67;" +
    "2007-02-06,42,65;" +
    "2007-02-06,42,66;" +
    "2007-02-07,49,62;" +
    "2007-02-07,49,62;" +
    "2007-02-08,47,58;" +
    "2007-02-08,47,58;" +
    "2007-02-09,53,59;" +
    "2007-02-09,53,58;" +
    "2007-02-10,53,66;" +
    "2007-02-10,53,65;" +
    "2007-02-11,41,60;" +
    "2007-02-11,51,62;" +
    "2007-02-12,39,60;" +
    "2007-02-12,38,62;" +
    "2007-02-13,38,56;" +
    "2007-02-13,43,57;" +
    "2007-02-14,36,63;" +
    "2007-02-14,37,64;" +
    "2007-02-15,39,67;" +
    "2007-02-15,40,68;" +
    "2007-02-16,41,75;" +
    "2007-02-16,41,76;" +
    "2007-02-17,50,73;" +
    "2007-02-17,52,73;" +
    "2007-02-18,39,59;" +
    "2007-02-18,45,65;" +
    "2007-02-19,36,64;" +
    "2007-02-19,36,65;" +
    "2007-02-20,39,58;" +
    "2007-02-20,41,58;" +
    "2007-02-21,46,59;" +
    "2007-02-21,46,59;" +
    "2007-02-22,42,54;" +
    "2007-02-22,43,56;" +
    "2007-02-23,36,55;" +
    "2007-02-23,37,55;" +
    "2007-02-24,33,56;" +
    "2007-02-24,35,56;" +
    "2007-02-25,46,55;" +
    "2007-02-25,46,53;" +
    "2007-02-26,43,54;" +
    "2007-02-26,45,53;" +
    "2007-02-27,34,52;" +
    "2007-02-27,35,53;" +
    "2007-02-28,36,53;" +
    "2007-02-28,36,54;" +
    "2007-03-01,32,55;" +
    "2007-03-01,33,55;" +
    "2007-03-02,32,63;" +
    "2007-03-02,33,64;" +
    "2007-03-03,37,62;" +
    "2007-03-03,41,63;" +
    "2007-03-04,42,68;" +
    "2007-03-04,43,69;" +
    "2007-03-05,47,71;" +
    "2007-03-05,50,72;" +
    "2007-03-06,41,73;" +
    "2007-03-06,43,73;" +
    "2007-03-07,49,66;" +
    "2007-03-07,48,68;" +
    "2007-03-08,43,65;" +
    "2007-03-08,43,66;" +
    "2007-03-09,40,63;" +
    "2007-03-09,42,64;" +
    "2007-03-10,39,72;" +
    "2007-03-10,41,73;" +
    "2007-03-11,43,77;" +
    "2007-03-11,45,78;" +
    "2007-03-12,45,79;" +
    "2007-03-12,47,79;" +
    "2007-03-13,49,82;" +
    "2007-03-13,50,84;" +
    "2007-03-14,45,72;" +
    "2007-03-14,45,77;" +
    "2007-03-15,41,79;" +
    "2007-03-15,43,79;" +
    "2007-03-16,45,82;" +
    "2007-03-16,46,82;" +
    "2007-03-17,45,71;" +
    "2007-03-17,48,79;" +
    "2007-03-18,49,71;" +
    "2007-03-18,49,72;" +
    "2007-03-19,46,68;" +
    "2007-03-19,44,69;" +
    "2007-03-20,48,61;" +
    "2007-03-20,53,61;" +
    "2007-03-21,43,68;" +
    "2007-03-21,45,68;" +
    "2007-03-22,39,73;" +
    "2007-03-22,40,74;" +
    "2007-03-23,39,79;" +
    "2007-03-23,42,81;" +
    "2007-03-24,45,70;" +
    "2007-03-24,46,75;" +
    "2007-03-25,51,66;" +
    "2007-03-25,51,67;" +
    "2007-03-26,44,68;" +
    "2007-03-26,47,69;" +
    "2007-03-27,39,59;" +
    "2007-03-27,41,59;" +
    "2007-03-28,33,67;" +
    "2007-03-28,36,68;" +
    "2007-03-29,40,73;" +
    "2007-03-29,43,72;" +
    "2007-03-30,37,79;" +
    "2007-03-30,40,80;" +
    "2007-03-31,41,76;" +
    "2007-03-31,43,79;" +
    "2007-04-01,45,69;" +
    "2007-04-01,44,71;" +
    "2007-04-02,44,73;" +
    "2007-04-02,43,76;" +
    "2007-04-03,40,78;" +
    "2007-04-03,42,80;" +
    "2007-04-04,48,75;" +
    "2007-04-04,48,76;" +
    "2007-04-05,50,78;" +
    "2007-04-05,52,79;" +
    "2007-04-06,46,70;" +
    "2007-04-06,47,73;" +
    "2007-04-07,49,67;" +
    "2007-04-07,52,68;" +
    "2007-04-08,49,69;" +
    "2007-04-08,49,71;" +
    "2007-04-09,48,66;" +
    "2007-04-09,51,68;" +
    "2007-04-10,39,72;" +
    "2007-04-10,41,74;" +
    "2007-04-11,48,63;" +
    "2007-04-11,49,67;" +
    "2007-04-12,41,65;" +
    "2007-04-12,43,67;" +
    "2007-04-13,38,73;" +
    "2007-04-13,40,75;" +
    "2007-04-14,49,56;" +
    "2007-04-14,50,64;" +
    "2007-04-15,42,71;" +
    "2007-04-15,43,71;" +
    "2007-04-16,46,77;" +
    "2007-04-16,49,79;" +
    "2007-04-17,45,61;" +
    "2007-04-17,49,76;" +
    "2007-04-18,40,62;" +
    "2007-04-18,41,65;" +
    "2007-04-19,42,59;" +
    "2007-04-19,43,60;" +
    "2007-04-20,45,63;" +
    "2007-04-20,45,64;" +
    "2007-04-21,40,62;" +
    "2007-04-21,42,63;" +
    "2007-04-22,46,62;" +
    "2007-04-22,49,64;" +
    "2007-04-23,39,73;" +
    "2007-04-23,41,75;" +
    "2007-04-24,43,75;" +
    "2007-04-24,42,78;" +
    "2007-04-25,48,65;" +
    "2007-04-25,47,70;" +
    "2007-04-26,42,78;" +
    "2007-04-26,43,80;" +
    "2007-04-27,47,89;" +
    "2007-04-27,48,90;" +
    "2007-04-28,52,91;" +
    "2007-04-28,54,92;" +
    "2007-04-29,50,82;" +
    "2007-04-29,52,85;" +
    "2007-04-30,46,82;" +
    "2007-04-30,48,83;" +
    "2007-05-01,47,75;" +
    "2007-05-01,47,76;" +
    "2007-05-02,49,66;" +
    "2007-05-02,53,67;" +
    "2007-05-03,43,61;" +
    "2007-05-03,46,61;" +
    "2007-05-04,47,62;" +
    "2007-05-04,49,62;" +
    "2007-05-05,40,74;" +
    "2007-05-05,42,73;" +
    "2007-05-06,50,87;" +
    "2007-05-06,51,86;" +
    "2007-05-07,53,90;" +
    "2007-05-07,56,89;" +
    "2007-05-08,54,92;" +
    "2007-05-08,55,93;" +
    "2007-05-09,50,86;" +
    "2007-05-09,54,86;" +
    "2007-05-10,50,76;" +
    "2007-05-10,51,80;" +
    "2007-05-11,47,70;" +
    "2007-05-11,47,76;" +
    "2007-05-12,48,65;" +
    "2007-05-12,48,66;" +
    "2007-05-13,41,79;" +
    "2007-05-13,41,81;" +
    "2007-05-14,48,84;" +
    "2007-05-14,47,86;" +
    "2007-05-15,49,62;" +
    "2007-05-15,49,79;" +
    "2007-05-16,50,73;" +
    "2007-05-16,50,75;" +
    "2007-05-17,48,72;" +
    "2007-05-17,48,72;" +
    "2007-05-18,49,73;" +
    "2007-05-18,47,74;" +
    "2007-05-19,50,75;" +
    "2007-05-19,52,76;" +
    "2007-05-20,49,74;" +
    "2007-05-20,48,74;" +
    "2007-05-21,53,85;" +
    "2007-05-21,52,85;" +
    "2007-05-22,48,85;" +
    "2007-05-22,50,85;" +
    "2007-05-23,56,87;" +
    "2007-05-23,56,87;" +
    "2007-05-24,50,89;" +
    "2007-05-24,52,90;" +
    "2007-05-25,50,83;" +
    "2007-05-25,51,84;" +
    "2007-05-26,52,80;" +
    "2007-05-26,52,82;" +
    "2007-05-27,51,73;" +
    "2007-05-27,52,75;" +
    "2007-05-28,51,85;" +
    "2007-05-28,50,86;" +
    "2007-05-29,51,77;" +
    "2007-05-29,52,79;" +
    "2007-05-30,52,70;" +
    "2007-05-30,52,71;" +
    "2007-05-31,52,70;" +
    "2007-05-31,52,72;" +
    "2007-06-01,51,75;" +
    "2007-06-01,52,76;" +
    "2007-06-02,51,75;" +
    "2007-06-02,51,77;" +
    "2007-06-03,50,80;" +
    "2007-06-03,51,81;" +
    "2007-06-04,56,79;" +
    "2007-06-04,56,80;" +
    "2007-06-05,53,68;" +
    "2007-06-05,56,73;" +
    "2007-06-06,50,71;" +
    "2007-06-06,50,71;" +
    "2007-06-07,46,79;" +
    "2007-06-07,46,79;" +
    "2007-06-08,47,81;" +
    "2007-06-08,46,82;" +
    "2007-06-09,50,86;" +
    "2007-06-09,50,86;" +
    "2007-06-10,53,80;" +
    "2007-06-10,55,80;" +
    "2007-06-11,50,84;" +
    "2007-06-11,49,85;" +
    "2007-06-12,48,93;" +
    "2007-06-12,48,94;" +
    "2007-06-13,57,98;" +
    "2007-06-13,57,100;" +
    "2007-06-14,60,103;" +
    "2007-06-14,63,103;" +
    "2007-06-15,57,94;" +
    "2007-06-15,62,98;" +
    "2007-06-16,54,72;" +
    "2007-06-16,55,85;" +
    "2007-06-17,52,92;" +
    "2007-06-17,52,93;" +
    "2007-06-18,55,97;" +
    "2007-06-18,56,98;" +
    "2007-06-19,55,82;" +
    "2007-06-19,55,88;" +
    "2007-06-20,52,79;" +
    "2007-06-20,52,81;" +
    "2007-06-21,52,87;" +
    "2007-06-21,52,88;" +
    "2007-06-22,53,88;" +
    "2007-06-22,52,88;" +
    "2007-06-23,52,84;" +
    "2007-06-23,51,84;" +
    "2007-06-24,50,76;" +
    "2007-06-24,50,78;" +
    "2007-06-25,50,92;" +
    "2007-06-25,49,92;" +
    "2007-06-26,54,86;" +
    "2007-06-26,54,87;" +
    "2007-06-27,53,82;" +
    "2007-06-27,52,83;" +
    "2007-06-28,54,77;" +
    "2007-06-28,55,79;" +
    "2007-06-29,56,79;" +
    "2007-06-29,59,80;" +
    "2007-06-30,52,76;" +
    "2007-06-30,53,78;" +
    "2007-07-01,52,81;" +
    "2007-07-01,51,82;" +
    "2007-07-02,54,88;" +
    "2007-07-02,53,90;" +
    "2007-07-03,58,89;" +
    "2007-07-03,57,92;" +
    "2007-07-04,58,104;" +
    "2007-07-04,58,104;" +
    "2007-07-05,65,107;" +
    "2007-07-05,64,108;" +
    "2007-07-06,57,96;" +
    "2007-07-06,64,100;" +
    "2007-07-07,55,77;" +
    "2007-07-07,56,84;" +
    "2007-07-08,54,92;" +
    "2007-07-08,54,94;" +
    "2007-07-09,57,85;" +
    "2007-07-09,57,88;" +
    "2007-07-10,55,80;" +
    "2007-07-10,56,82;" +
    "2007-07-11,57,76;" +
    "2007-07-11,59,77;" +
    "2007-07-12,54,79;" +
    "2007-07-12,55,79;" +
    "2007-07-13,56,86;" +
    "2007-07-13,55,88;" +
    "2007-07-14,55,85;" +
    "2007-07-14,56,87;" +
    "2007-07-15,57,86;" +
    "2007-07-15,56,87;" +
    "2007-07-16,56,77;" +
    "2007-07-16,56,81;" +
    "2007-07-17,60,79;" +
    "2007-07-17,59,81;" +
    "2007-07-18,60,79;" +
    "2007-07-18,64,77;" +
    "2007-07-19,57,82;" +
    "2007-07-19,56,83;" +
    "2007-07-20,56,81;" +
    "2007-07-20,57,82;" +
    "2007-07-21,60,84;" +
    "2007-07-21,60,85;" +
    "2007-07-22,61,87;" +
    "2007-07-22,60,88;" +
    "2007-07-23,61,91;" +
    "2007-07-23,60,92;" +
    "2007-07-24,57,86;" +
    "2007-07-24,61,87;" +
    "2007-07-25,57,77;" +
    "2007-07-25,58,79;" +
    "2007-07-26,56,85;" +
    "2007-07-26,57,87;" +
    "2007-07-27,57,93;" +
    "2007-07-27,56,95;" +
    "2007-07-28,55,91;" +
    "2007-07-28,56,93;" +
    "2007-07-29,54,87;" +
    "2007-07-29,54,88;" +
    "2007-07-30,55,94;" +
    "2007-07-30,55,96;" +
    "2007-07-31,58,90;" +
    "2007-07-31,58,93;" +
    "2007-08-01,56,91;" +
    "2007-08-01,55,93;" +
    "2007-08-02,57,89;" +
    "2007-08-02,57,91;" +
    "2007-08-03,57,100;" +
    "2007-08-03,56,101;" +
    "2007-08-04,58,97;" +
    "2007-08-04,58,99;" +
    "2007-08-05,56,72;" +
    "2007-08-05,55,92;" +
    "2007-08-06,58,70;" +
    "2007-08-06,57,70;" +
    "2007-08-07,57,73;" +
    "2007-08-07,56,74;" +
    "2007-08-08,56,79;" +
    "2007-08-08,56,82;" +
    "2007-08-09,56,89;" +
    "2007-08-09,55,90;" +
    "2007-08-10,55,90;" +
    "2007-08-10,54,91;" +
    "2007-08-11,56,75;" +
    "2007-08-11,54,82;" +
    "2007-08-12,53,82;" +
    "2007-08-12,52,84;" +
    "2007-08-13,51,88;" +
    "2007-08-13,52,89;" +
    "2007-08-14,53,90;" +
    "2007-08-14,52,90;" +
    "2007-08-15,55,85;" +
    "2007-08-15,55,86;" +
    "2007-08-16,57,83;" +
    "2007-08-16,56,82;" +
    "2007-08-17,53,86;" +
    "2007-08-17,52,88;" +
    "2007-08-18,53,82;" +
    "2007-08-18,53,82;" +
    "2007-08-19,55,84;" +
    "2007-08-19,56,85;" +
    "2007-08-20,60,90;" +
    "2007-08-20,60,91;" +
    "2007-08-21,60,96;" +
    "2007-08-21,59,96;" +
    "2007-08-22,59,96;" +
    "2007-08-22,60,97;" +
    "2007-08-23,57,92;" +
    "2007-08-23,57,93;" +
    "2007-08-24,58,90;" +
    "2007-08-24,58,92;" +
    "2007-08-25,58,81;" +
    "2007-08-25,58,83;" +
    "2007-08-26,57,83;" +
    "2007-08-26,57,84;" +
    "2007-08-27,55,92;" +
    "2007-08-27,56,93;" +
    "2007-08-28,63,100;" +
    "2007-08-28,63,101;" +
    "2007-08-29,68,105;" +
    "2007-08-29,67,105;" +
    "2007-08-30,70,104;" +
    "2007-08-30,68,102;" +
    "2007-08-31,69,95;" +
    "2007-08-31,71,96;" +
    "2007-09-01,62,100;" +
    "2007-09-01,63,100;" +
    "2007-09-02,58,100;" +
    "2007-09-02,60,100;" +
    "2007-09-03,60,92;" +
    "2007-09-03,60,93;" +
    "2007-09-04,59,80;" +
    "2007-09-04,62,79;" +
    "2007-09-05,55,91;" +
    "2007-09-05,54,91;" +
    "2007-09-06,60,93;" +
    "2007-09-06,59,93;" +
    "2007-09-07,58,80;" +
    "2007-09-07,58,82;" +
    "2007-09-08,57,86;" +
    "2007-09-08,56,86;" +
    "2007-09-09,57,78;" +
    "2007-09-09,56,79;" +
    "2007-09-10,57,81;" +
    "2007-09-10,56,82;" +
    "2007-09-11,57,78;" +
    "2007-09-11,56,80;" +
    "2007-09-12,59,70;" +
    "2007-09-12,58,72;" +
    "2007-09-13,56,77;" +
    "2007-09-13,56,78;" +
    "2007-09-14,58,79;" +
    "2007-09-14,58,79;" +
    "2007-09-15,56,74;" +
    "2007-09-15,57,75;" +
    "2007-09-16,57,77;" +
    "2007-09-16,59,78;" +
    "2007-09-17,55,78;" +
    "2007-09-17,54,79;" +
    "2007-09-18,55,78;" +
    "2007-09-18,53,79;" +
    "2007-09-19,54,66;" +
    "2007-09-19,54,65;" +
    "2007-09-20,47,71;" +
    "2007-09-20,46,70;" +
    "2007-09-21,56,80;" +
    "2007-09-21,55,80;" +
    "2007-09-22,55,67;" +
    "2007-09-22,54,70;" +
    "2007-09-23,54,71;" +
    "2007-09-23,53,70;" +
    "2007-09-24,47,80;" +
    "2007-09-24,48,79;" +
    "2007-09-25,46,85;" +
    "2007-09-25,48,85;" +
    "2007-09-26,50,89;" +
    "2007-09-26,50,89;" +
    "2007-09-27,53,88;" +
    "2007-09-27,53,88;" +
    "2007-09-28,50,65;" +
    "2007-09-28,53,74;" +
    "2007-09-29,44,74;" +
    "2007-09-29,44,73;" +
    "2007-09-30,42,81;" +
    "2007-09-30,42,81;" +
    "2007-10-01,53,75;" +
    "2007-10-01,56,75;" +
    "2007-10-02,47,85;" +
    "2007-10-02,47,84;" +
    "2007-10-03,50,78;" +
    "2007-10-03,49,78;" +
    "2007-10-04,50,68;" +
    "2007-10-04,51,68;" +
    "2007-10-05,45,65;" +
    "2007-10-05,47,64;" +
    "2007-10-06,39,73;" +
    "2007-10-06,40,74;" +
    "2007-10-07,41,79;" +
    "2007-10-07,42,79;" +
    "2007-10-08,47,82;" +
    "2007-10-08,46,82;" +
    "2007-10-09,43,73;" +
    "2007-10-09,44,77;" +
    "2007-10-10,49,69;" +
    "2007-10-10,53,70;" +
    "2007-10-11,45,72;" +
    "2007-10-11,45,73;" +
    "2007-10-12,52,61;" +
    "2007-10-12,51,65;" +
    "2007-10-13,50,73;" +
    "2007-10-13,49,74;" +
    "2007-10-14,47,76;" +
    "2007-10-14,47,76;" +
    "2007-10-15,50,70;" +
    "2007-10-15,48,69;" +
    "2007-10-16,52,65;" +
    "2007-10-16,52,65;" +
    "2007-10-17,45,68;" +
    "2007-10-17,46,68;" +
    "2007-10-18,47,74;" +
    "2007-10-18,47,74;" +
    "2007-10-19,57,72;" +
    "2007-10-19,55,72;" +
    "2007-10-20,50,72;" +
    "2007-10-20,51,67;" +
    "2007-10-21,45,73;" +
    "2007-10-21,45,72;" +
    "2007-10-22,46,78;" +
    "2007-10-22,50,77;" +
    "2007-10-23,46,83;" +
    "2007-10-23,47,81;" +
    "2007-10-24,47,86;" +
    "2007-10-24,48,84;" +
    "2007-10-25,47,79;" +
    "2007-10-25,49,80;" +
    "2007-10-26,44,71;" +
    "2007-10-26,44,72;" +
    "2007-10-27,46,76;" +
    "2007-10-27,47,77;" +
    "2007-10-28,48,80;" +
    "2007-10-28,49,80;" +
    "2007-10-29,52,70;" +
    "2007-10-29,52,74;" +
    "2007-10-30,50,63;" +
    "2007-10-30,53,64;" +
    "2007-10-31,51,70;" +
    "2007-10-31,49,72;" +
    "2007-11-01,50,76;" +
    "2007-11-01,49,76;" +
    "2007-11-02,44,76;" +
    "2007-11-02,44,75;" +
    "2007-11-03,42,80;" +
    "2007-11-03,45,78;" +
    "2007-11-04,41,80;" +
    "2007-11-04,42,78;" +
    "2007-11-05,41,81;" +
    "2007-11-05,43,79;" +
    "2007-11-06,47,75;" +
    "2007-11-06,47,75;" +
    "2007-11-07,43,80;" +
    "2007-11-07,43,80;" +
    "2007-11-08,51,60;" +
    "2007-11-08,49,60;" +
    "2007-11-09,45,67;" +
    "2007-11-09,47,67;" +
    "2007-11-10,44,65;" +
    "2007-11-10,44,65;" +
    "2007-11-11,44,67;" +
    "2007-11-11,53,66;" +
    "2007-11-12,40,72;" +
    "2007-11-12,40,72;" +
    "2007-11-13,51,75;" +
    "2007-11-13,51,75;" +
    "2007-11-14,52,75;" +
    "2007-11-14,54,76;" +
    "2007-11-15,47,76;" +
    "2007-11-15,47,77;" +
    "2007-11-16,51,65;" +
    "2007-11-16,52,66;" +
    "2007-11-17,50,69;" +
    "2007-11-17,47,70;" +
    "2007-11-18,46,69;" +
    "2007-11-18,43,70;" +
    "2007-11-19,45,68;" +
    "2007-11-19,47,67;" +
    "2007-11-20,40,63;" +
    "2007-11-20,42,62;" +
    "2007-11-21,35,65;" +
    "2007-11-21,40,63;" +
    "2007-11-22,32,65;" +
    "2007-11-22,35,64;" +
    "2007-11-23,33,69;" +
    "2007-11-23,34,67;" +
    "2007-11-24,34,66;" +
    "2007-11-24,38,65;" +
    "2007-11-25,41,60;" +
    "2007-11-25,42,60;" +
    "2007-11-26,35,67;" +
    "2007-11-26,36,66;" +
    "2007-11-27,38,62;" +
    "2007-11-27,40,61;" +
    "2007-11-28,37,64;" +
    "2007-11-28,41,62;" +
    "2007-11-29,34,62;" +
    "2007-11-29,35,60;" +
    "2007-11-30,33,57;" +
    "2007-11-30,34,57;" +
    "2007-12-01,34,54;" +
    "2007-12-01,37,54;" +
    "2007-12-02,33,61;" +
    "2007-12-02,35,61;" +
    "2007-12-03,40,67;" +
    "2007-12-03,42,69;" +
    "2007-12-04,53,57;" +
    "2007-12-04,52,57;" +
    "2007-12-05,45,69;" +
    "2007-12-05,45,69;" +
    "2007-12-06,45,54;" +
    "2007-12-06,46,57;" +
    "2007-12-07,41,57;" +
    "2007-12-07,47,57;" +
    "2007-12-08,32,53;" +
    "2007-12-08,33,54;" +
    "2007-12-09,36,58;" +
    "2007-12-09,38,57;" +
    "2007-12-10,32,56;" +
    "2007-12-10,33,58;" +
    "2007-12-11,33,57;" +
    "2007-12-11,41,56;" +
    "2007-12-12,31,55;" +
    "2007-12-12,31,55;" +
    "2007-12-13,29,58;" +
    "2007-12-13,29,56;" +
    "2007-12-14,30,59;" +
    "2007-12-14,30,59;" +
    "2007-12-15,35,58;" +
    "2007-12-15,36,58;" +
    "2007-12-16,36,54;" +
    "2007-12-16,36,54;" +
    "2007-12-17,48,57;" +
    "2007-12-17,46,57;" +
    "2007-12-18,41,57;" +
    "2007-12-18,46,56;" +
    "2007-12-19,39,57;" +
    "2007-12-19,39,57;" +
    "2007-12-20,34,55;" +
    "2007-12-20,46,55;" +
    "2007-12-21,32,52;" +
    "2007-12-21,33,52;" +
    "2007-12-22,31,54;" +
    "2007-12-22,32,55;" +
    "2007-12-23,34,60;" +
    "2007-12-23,34,60;" +
    "2007-12-24,39,59;" +
    "2007-12-24,42,58;" +
    "2007-12-25,34,56;" +
    "2007-12-25,35,56;" +
    "2007-12-26,37,53;" +
    "2007-12-26,37,53;" +
    "2007-12-27,32,48;" +
    "2007-12-27,32,49;" +
    "2007-12-28,39,44;" +
    "2007-12-28,38,44;" +
    "2007-12-29,41,51;" +
    "2007-12-29,39,50;" +
    "2007-12-30,40,51;" +
    "2007-12-30,44,50;" +
    "2007-12-31,40,52;" +
    "2007-12-31,38,51;"