export const data = "2010-01-01,44,62;" +
    "2010-01-01,45,63;" +
    "2010-01-02,43,55;" +
    "2010-01-02,44,54;" +
    "2010-01-03,38,54;" +
    "2010-01-03,39,54;" +
    "2010-01-04,37,50;" +
    "2010-01-04,43,50;" +
    "2010-01-05,34,56;" +
    "2010-01-05,35,57;" +
    "2010-01-06,35,53;" +
    "2010-01-06,36,53;" +
    "2010-01-07,35,55;" +
    "2010-01-07,36,56;" +
    "2010-01-08,42,47;" +
    "2010-01-08,41,46;" +
    "2010-01-09,36,50;" +
    "2010-01-09,41,49;" +
    "2010-01-10,36,52;" +
    "2010-01-10,35,51;" +
    "2010-01-11,41,50;" +
    "2010-01-11,41,49;" +
    "2010-01-12,42,62;" +
    "2010-01-12,42,63;" +
    "2010-01-13,45,61;" +
    "2010-01-13,51,62;" +
    "2010-01-14,41,59;" +
    "2010-01-14,39,59;" +
    "2010-01-15,38,54;" +
    "2010-01-15,38,54;" +
    "2010-01-16,41,56;" +
    "2010-01-16,40,57;" +
    "2010-01-17,48,56;" +
    "2010-01-17,48,56;" +
    "2010-01-18,49,57;" +
    "2010-01-18,47,55;" +
    "2010-01-19,46,56;" +
    "2010-01-19,45,56;" +
    "2010-01-20,46,53;" +
    "2010-01-20,46,53;" +
    "2010-01-21,41,49;" +
    "2010-01-21,40,48;" +
    "2010-01-22,35,52;" +
    "2010-01-22,40,51;" +
    "2010-01-23,35,56;" +
    "2010-01-23,35,58;" +
    "2010-01-24,36,53;" +
    "2010-01-24,36,52;" +
    "2010-01-25,47,57;" +
    "2010-01-25,46,54;" +
    "2010-01-26,45,52;" +
    "2010-01-26,46,55;" +
    "2010-01-27,40,56;" +
    "2010-01-27,39,58;" +
    "2010-01-28,40,55;" +
    "2010-01-28,39,56;" +
    "2010-01-29,42,60;" +
    "2010-01-29,42,60;" +
    "2010-01-30,41,59;" +
    "2010-01-30,40,61;" +
    "2010-01-31,39,57;" +
    "2010-01-31,39,57;" +
    "2010-02-01,39,60;" +
    "2010-02-01,39,61;" +
    "2010-02-02,43,57;" +
    "2010-02-02,43,58;" +
    "2010-02-03,40,62;" +
    "2010-02-03,41,62;" +
    "2010-02-04,45,59;" +
    "2010-02-04,45,59;" +
    "2010-02-05,47,62;" +
    "2010-02-05,47,62;" +
    "2010-02-06,45,56;" +
    "2010-02-06,47,56;" +
    "2010-02-07,42,57;" +
    "2010-02-07,46,59;" +
    "2010-02-08,39,54;" +
    "2010-02-08,40,54;" +
    "2010-02-09,40,54;" +
    "2010-02-09,43,54;" +
    "2010-02-10,35,57;" +
    "2010-02-10,36,57;" +
    "2010-02-11,42,62;" +
    "2010-02-11,41,63;" +
    "2010-02-12,49,63;" +
    "2010-02-12,50,65;" +
    "2010-02-13,41,61;" +
    "2010-02-13,41,61;" +
    "2010-02-14,39,67;" +
    "2010-02-14,38,66;" +
    "2010-02-15,40,67;" +
    "2010-02-15,41,67;" +
    "2010-02-16,45,69;" +
    "2010-02-16,44,69;" +
    "2010-02-17,42,66;" +
    "2010-02-17,43,66;" +
    "2010-02-18,42,66;" +
    "2010-02-18,43,68;" +
    "2010-02-19,43,57;" +
    "2010-02-19,49,57;" +
    "2010-02-20,45,55;" +
    "2010-02-20,45,54;" +
    "2010-02-21,47,53;" +
    "2010-02-21,47,52;" +
    "2010-02-22,39,58;" +
    "2010-02-22,43,60;" +
    "2010-02-23,40,54;" +
    "2010-02-23,42,52;" +
    "2010-02-24,48,61;" +
    "2010-02-24,48,63;" +
    "2010-02-25,47,61;" +
    "2010-02-25,47,62;" +
    "2010-02-26,47,64;" +
    "2010-02-26,49,65;" +
    "2010-02-27,42,58;" +
    "2010-02-27,47,58;" +
    "2010-02-28,38,67;" +
    "2010-02-28,38,68;" +
    "2010-03-01,43,61;" +
    "2010-03-01,44,62;" +
    "2010-03-02,46,59;" +
    "2010-03-02,50,60;" +
    "2010-03-03,41,56;" +
    "2010-03-03,45,57;" +
    "2010-03-04,36,56;" +
    "2010-03-04,38,58;" +
    "2010-03-05,35,56;" +
    "2010-03-05,36,56;" +
    "2010-03-06,44,64;" +
    "2010-03-06,45,63;" +
    "2010-03-07,39,60;" +
    "2010-03-07,40,62;" +
    "2010-03-08,39,55;" +
    "2010-03-08,42,56;" +
    "2010-03-09,33,55;" +
    "2010-03-09,34,57;" +
    "2010-03-10,38,56;" +
    "2010-03-10,38,57;" +
    "2010-03-11,33,63;" +
    "2010-03-11,34,64;" +
    "2010-03-12,38,56;" +
    "2010-03-12,39,57;" +
    "2010-03-13,33,57;" +
    "2010-03-13,35,58;" +
    "2010-03-14,34,63;" +
    "2010-03-14,34,64;" +
    "2010-03-15,37,67;" +
    "2010-03-15,38,68;" +
    "2010-03-16,44,70;" +
    "2010-03-16,43,71;" +
    "2010-03-17,48,74;" +
    "2010-03-17,49,75;" +
    "2010-03-18,43,75;" +
    "2010-03-18,44,77;" +
    "2010-03-19,42,77;" +
    "2010-03-19,48,77;" +
    "2010-03-20,38,72;" +
    "2010-03-20,41,75;" +
    "2010-03-21,40,68;" +
    "2010-03-21,42,71;" +
    "2010-03-22,45,67;" +
    "2010-03-22,50,68;" +
    "2010-03-23,41,72;" +
    "2010-03-23,45,74;" +
    "2010-03-24,39,72;" +
    "2010-03-24,40,73;" +
    "2010-03-25,45,61;" +
    "2010-03-25,46,62;" +
    "2010-03-26,36,66;" +
    "2010-03-26,38,66;" +
    "2010-03-27,36,70;" +
    "2010-03-27,39,71;" +
    "2010-03-28,42,76;" +
    "2010-03-28,44,77;" +
    "2010-03-29,45,66;" +
    "2010-03-29,45,68;" +
    "2010-03-30,46,58;" +
    "2010-03-30,48,60;" +
    "2010-03-31,42,58;" +
    "2010-03-31,41,58;" +
    "2010-04-01,37,59;" +
    "2010-04-01,39,60;" +
    "2010-04-02,40,56;" +
    "2010-04-02,42,57;" +
    "2010-04-03,39,57;" +
    "2010-04-03,39,58;" +
    "2010-04-04,39,55;" +
    "2010-04-04,40,54;" +
    "2010-04-05,39,58;" +
    "2010-04-05,40,60;" +
    "2010-04-06,35,63;" +
    "2010-04-06,36,64;" +
    "2010-04-07,38,71;" +
    "2010-04-07,39,71;" +
    "2010-04-08,43,66;" +
    "2010-04-08,44,68;" +
    "2010-04-09,40,69;" +
    "2010-04-09,43,69;" +
    "2010-04-10,45,61;" +
    "2010-04-10,46,68;" +
    "2010-04-11,46,56;" +
    "2010-04-11,45,67;" +
    "2010-04-12,46,56;" +
    "2010-04-12,47,56;" +
    "2010-04-13,45,61;" +
    "2010-04-13,45,63;" +
    "2010-04-14,42,62;" +
    "2010-04-14,42,64;" +
    "2010-04-15,40,67;" +
    "2010-04-15,40,68;" +
    "2010-04-16,46,69;" +
    "2010-04-16,46,72;" +
    "2010-04-17,47,72;" +
    "2010-04-17,51,75;" +
    "2010-04-18,40,78;" +
    "2010-04-18,42,79;" +
    "2010-04-19,47,71;" +
    "2010-04-19,48,76;" +
    "2010-04-20,45,59;" +
    "2010-04-20,46,62;" +
    "2010-04-21,43,52;" +
    "2010-04-21,45,54;" +
    "2010-04-22,46,65;" +
    "2010-04-22,45,65;" +
    "2010-04-23,43,74;" +
    "2010-04-23,44,76;" +
    "2010-04-24,44,74;" +
    "2010-04-24,43,76;" +
    "2010-04-25,44,83;" +
    "2010-04-25,45,83;" +
    "2010-04-26,45,77;" +
    "2010-04-26,46,81;" +
    "2010-04-27,50,63;" +
    "2010-04-27,52,67;" +
    "2010-04-28,43,56;" +
    "2010-04-28,47,56;" +
    "2010-04-29,40,63;" +
    "2010-04-29,40,64;" +
    "2010-04-30,37,68;" +
    "2010-04-30,39,70;" +
    "2010-05-01,41,72;" +
    "2010-05-01,41,75;" +
    "2010-05-02,42,82;" +
    "2010-05-02,44,82;" +
    "2010-05-03,47,83;" +
    "2010-05-03,48,84;" +
    "2010-05-04,49,78;" +
    "2010-05-04,47,80;" +
    "2010-05-05,46,69;" +
    "2010-05-05,46,72;" +
    "2010-05-06,43,73;" +
    "2010-05-06,45,73;" +
    "2010-05-07,41,73;" +
    "2010-05-07,43,75;" +
    "2010-05-08,47,67;" +
    "2010-05-08,48,69;" +
    "2010-05-09,52,62;" +
    "2010-05-09,50,63;" +
    "2010-05-10,43,58;" +
    "2010-05-10,47,60;" +
    "2010-05-11,36,68;" +
    "2010-05-11,37,69;" +
    "2010-05-12,41,76;" +
    "2010-05-12,43,78;" +
    "2010-05-13,47,78;" +
    "2010-05-13,46,80;" +
    "2010-05-14,49,72;" +
    "2010-05-14,48,74;" +
    "2010-05-15,50,80;" +
    "2010-05-15,49,82;" +
    "2010-05-16,49,70;" +
    "2010-05-16,49,74;" +
    "2010-05-17,51,64;" +
    "2010-05-17,50,64;" +
    "2010-05-18,53,71;" +
    "2010-05-18,54,72;" +
    "2010-05-19,48,67;" +
    "2010-05-19,48,68;" +
    "2010-05-20,46,73;" +
    "2010-05-20,46,75;" +
    "2010-05-21,44,62;" +
    "2010-05-21,47,68;" +
    "2010-05-22,40,62;" +
    "2010-05-22,42,63;" +
    "2010-05-23,38,68;" +
    "2010-05-23,39,69;" +
    "2010-05-24,48,65;" +
    "2010-05-24,49,68;" +
    "2010-05-25,43,65;" +
    "2010-05-25,44,66;" +
    "2010-05-26,49,67;" +
    "2010-05-26,51,67;" +
    "2010-05-27,42,65;" +
    "2010-05-27,43,64;" +
    "2010-05-28,41,69;" +
    "2010-05-28,44,68;" +
    "2010-05-29,43,81;" +
    "2010-05-29,44,83;" +
    "2010-05-30,46,86;" +
    "2010-05-30,47,87;" +
    "2010-05-31,52,77;" +
    "2010-05-31,53,82;" +
    "2010-06-01,54,70;" +
    "2010-06-01,53,71;" +
    "2010-06-02,55,80;" +
    "2010-06-02,54,81;" +
    "2010-06-03,59,80;" +
    "2010-06-03,59,81;" +
    "2010-06-04,60,83;" +
    "2010-06-04,59,85;" +
    "2010-06-05,61,87;" +
    "2010-06-05,60,88;" +
    "2010-06-06,58,84;" +
    "2010-06-06,59,85;" +
    "2010-06-07,54,77;" +
    "2010-06-07,54,79;" +
    "2010-06-08,53,72;" +
    "2010-06-08,52,74;" +
    "2010-06-09,54,69;" +
    "2010-06-09,53,68;" +
    "2010-06-10,55,73;" +
    "2010-06-10,54,74;" +
    "2010-06-11,47,84;" +
    "2010-06-11,48,84;" +
    "2010-06-12,61,90;" +
    "2010-06-12,61,90;" +
    "2010-06-13,56,95;" +
    "2010-06-13,57,95;" +
    "2010-06-14,53,85;" +
    "2010-06-14,57,90;" +
    "2010-06-15,51,73;" +
    "2010-06-15,50,78;" +
    "2010-06-16,48,82;" +
    "2010-06-16,47,85;" +
    "2010-06-17,50,86;" +
    "2010-06-17,50,86;" +
    "2010-06-18,51,69;" +
    "2010-06-18,49,79;" +
    "2010-06-19,53,68;" +
    "2010-06-19,52,68;" +
    "2010-06-20,50,79;" +
    "2010-06-20,49,80;" +
    "2010-06-21,51,87;" +
    "2010-06-21,50,88;" +
    "2010-06-22,55,88;" +
    "2010-06-22,54,90;" +
    "2010-06-23,55,85;" +
    "2010-06-23,55,87;" +
    "2010-06-24,54,71;" +
    "2010-06-24,54,78;" +
    "2010-06-25,54,77;" +
    "2010-06-25,54,79;" +
    "2010-06-26,54,87;" +
    "2010-06-26,53,90;" +
    "2010-06-27,58,102;" +
    "2010-06-27,58,102;" +
    "2010-06-28,63,96;" +
    "2010-06-28,63,98;" +
    "2010-06-29,57,90;" +
    "2010-06-29,57,90;" +
    "2010-06-30,53,78;" +
    "2010-06-30,53,84;" +
    "2010-07-01,50,78;" +
    "2010-07-01,49,81;" +
    "2010-07-02,53,81;" +
    "2010-07-02,52,83;" +
    "2010-07-03,55,92;" +
    "2010-07-03,54,92;" +
    "2010-07-04,55,94;" +
    "2010-07-04,56,95;" +
    "2010-07-05,55,89;" +
    "2010-07-05,55,92;" +
    "2010-07-06,55,79;" +
    "2010-07-06,55,84;" +
    "2010-07-07,54,81;" +
    "2010-07-07,54,83;" +
    "2010-07-08,54,80;" +
    "2010-07-08,54,82;" +
    "2010-07-09,54,84;" +
    "2010-07-09,53,87;" +
    "2010-07-10,54,92;" +
    "2010-07-10,54,93;" +
    "2010-07-11,57,88;" +
    "2010-07-11,56,90;" +
    "2010-07-12,57,82;" +
    "2010-07-12,56,86;" +
    "2010-07-13,58,84;" +
    "2010-07-13,57,86;" +
    "2010-07-14,56,93;" +
    "2010-07-14,55,93;" +
    "2010-07-15,64,99;" +
    "2010-07-15,63,100;" +
    "2010-07-16,63,95;" +
    "2010-07-16,63,96;" +
    "2010-07-17,59,94;" +
    "2010-07-17,58,95;" +
    "2010-07-18,58,95;" +
    "2010-07-18,58,96;" +
    "2010-07-19,55,86;" +
    "2010-07-19,57,89;" +
    "2010-07-20,55,79;" +
    "2010-07-20,54,89;" +
    "2010-07-21,54,74;" +
    "2010-07-21,53,77;" +
    "2010-07-22,54,89;" +
    "2010-07-22,52,91;" +
    "2010-07-23,55,90;" +
    "2010-07-23,54,92;" +
    "2010-07-24,55,88;" +
    "2010-07-24,54,89;" +
    "2010-07-25,53,82;" +
    "2010-07-25,53,89;" +
    "2010-07-26,54,76;" +
    "2010-07-26,53,78;" +
    "2010-07-27,53,77;" +
    "2010-07-27,54,78;" +
    "2010-07-28,52,78;" +
    "2010-07-28,52,80;" +
    "2010-07-29,53,86;" +
    "2010-07-29,52,88;" +
    "2010-07-30,53,86;" +
    "2010-07-30,53,86;" +
    "2010-07-31,54,85;" +
    "2010-07-31,54,86;" +
    "2010-08-01,52,87;" +
    "2010-08-01,51,89;" +
    "2010-08-02,53,86;" +
    "2010-08-02,53,88;" +
    "2010-08-03,54,91;" +
    "2010-08-03,54,92;" +
    "2010-08-04,54,83;" +
    "2010-08-04,55,87;" +
    "2010-08-05,54,77;" +
    "2010-08-05,53,80;" +
    "2010-08-06,54,85;" +
    "2010-08-06,52,87;" +
    "2010-08-07,54,83;" +
    "2010-08-07,54,85;" +
    "2010-08-08,54,77;" +
    "2010-08-08,52,78;" +
    "2010-08-09,54,83;" +
    "2010-08-09,55,85;" +
    "2010-08-10,54,83;" +
    "2010-08-10,54,84;" +
    "2010-08-11,55,69;" +
    "2010-08-11,54,74;" +
    "2010-08-12,54,87;" +
    "2010-08-12,54,88;" +
    "2010-08-13,55,83;" +
    "2010-08-13,54,84;" +
    "2010-08-14,53,79;" +
    "2010-08-14,54,80;" +
    "2010-08-15,54,80;" +
    "2010-08-15,54,82;" +
    "2010-08-16,54,88;" +
    "2010-08-16,53,90;" +
    "2010-08-17,54,79;" +
    "2010-08-17,54,85;" +
    "2010-08-18,54,81;" +
    "2010-08-18,57,82;" +
    "2010-08-19,53,93;" +
    "2010-08-19,53,94;" +
    "2010-08-20,54,86;" +
    "2010-08-20,54,88;" +
    "2010-08-21,53,70;" +
    "2010-08-21,54,81;" +
    "2010-08-22,52,86;" +
    "2010-08-22,52,88;" +
    "2010-08-23,54,97;" +
    "2010-08-23,54,96;" +
    "2010-08-24,59,105;" +
    "2010-08-24,59,102;" +
    "2010-08-25,64,105;" +
    "2010-08-25,65,105;" +
    "2010-08-26,58,87;" +
    "2010-08-26,57,94;" +
    "2010-08-27,52,79;" +
    "2010-08-27,54,80;" +
    "2010-08-28,53,69;" +
    "2010-08-28,53,70;" +
    "2010-08-29,52,77;" +
    "2010-08-29,51,78;" +
    "2010-08-30,51,74;" +
    "2010-08-30,48,74;" +
    "2010-08-31,56,88;" +
    "2010-08-31,56,88;" +
    "2010-09-01,57,95;" +
    "2010-09-01,56,94;" +
    "2010-09-02,59,101;" +
    "2010-09-02,59,99;" +
    "2010-09-03,60,96;" +
    "2010-09-03,60,97;" +
    "2010-09-04,54,91;" +
    "2010-09-04,54,91;" +
    "2010-09-05,54,90;" +
    "2010-09-05,53,90;" +
    "2010-09-06,56,95;" +
    "2010-09-06,58,96;" +
    "2010-09-07,56,76;" +
    "2010-09-07,55,88;" +
    "2010-09-08,56,69;" +
    "2010-09-08,58,68;" +
    "2010-09-09,55,78;" +
    "2010-09-09,53,78;" +
    "2010-09-10,51,85;" +
    "2010-09-10,50,86;" +
    "2010-09-11,53,91;" +
    "2010-09-11,53,90;" +
    "2010-09-12,54,82;" +
    "2010-09-12,54,84;" +
    "2010-09-13,51,77;" +
    "2010-09-13,52,78;" +
    "2010-09-14,51,83;" +
    "2010-09-14,49,83;" +
    "2010-09-15,51,79;" +
    "2010-09-15,51,80;" +
    "2010-09-16,52,82;" +
    "2010-09-16,50,82;" +
    "2010-09-17,62,75;" +
    "2010-09-17,61,76;" +
    "2010-09-18,58,75;" +
    "2010-09-18,60,75;" +
    "2010-09-19,55,76;" +
    "2010-09-19,56,76;" +
    "2010-09-20,57,81;" +
    "2010-09-20,59,82;" +
    "2010-09-21,51,71;" +
    "2010-09-21,52,74;" +
    "2010-09-22,53,70;" +
    "2010-09-22,54,71;" +
    "2010-09-23,47,85;" +
    "2010-09-23,47,85;" +
    "2010-09-24,51,89;" +
    "2010-09-24,49,87;" +
    "2010-09-25,52,97;" +
    "2010-09-25,52,95;" +
    "2010-09-26,54,99;" +
    "2010-09-26,55,99;" +
    "2010-09-27,56,100;" +
    "2010-09-27,55,98;" +
    "2010-09-28,61,105;" +
    "2010-09-28,60,105;" +
    "2010-09-29,63,102;" +
    "2010-09-29,63,102;" +
    "2010-09-30,59,93;" +
    "2010-09-30,58,94;" +
    "2010-10-01,58,79;" +
    "2010-10-01,57,81;" +
    "2010-10-02,57,88;" +
    "2010-10-02,56,88;" +
    "2010-10-03,56,75;" +
    "2010-10-03,56,77;" +
    "2010-10-04,54,65;" +
    "2010-10-04,55,64;" +
    "2010-10-05,49,74;" +
    "2010-10-05,48,74;" +
    "2010-10-06,49,77;" +
    "2010-10-06,49,76;" +
    "2010-10-07,50,73;" +
    "2010-10-07,51,75;" +
    "2010-10-08,48,79;" +
    "2010-10-08,48,79;" +
    "2010-10-09,48,83;" +
    "2010-10-09,48,83;" +
    "2010-10-10,54,88;" +
    "2010-10-10,53,88;" +
    "2010-10-11,55,91;" +
    "2010-10-11,55,89;" +
    "2010-10-12,60,90;" +
    "2010-10-12,66,88;" +
    "2010-10-13,56,93;" +
    "2010-10-13,55,91;" +
    "2010-10-14,56,95;" +
    "2010-10-14,57,93;" +
    "2010-10-15,56,91;" +
    "2010-10-15,56,90;" +
    "2010-10-16,53,77;" +
    "2010-10-16,52,79;" +
    "2010-10-17,53,57;" +
    "2010-10-17,52,64;" +
    "2010-10-18,52,72;" +
    "2010-10-18,51,73;" +
    "2010-10-19,47,79;" +
    "2010-10-19,48,79;" +
    "2010-10-20,51,77;" +
    "2010-10-20,50,78;" +
    "2010-10-21,50,67;" +
    "2010-10-21,51,68;" +
    "2010-10-22,49,62;" +
    "2010-10-22,50,64;" +
    "2010-10-23,48,65;" +
    "2010-10-23,49,63;" +
    "2010-10-24,55,67;" +
    "2010-10-24,56,67;" +
    "2010-10-25,49,66;" +
    "2010-10-25,49,66;" +
    "2010-10-26,41,68;" +
    "2010-10-26,42,66;" +
    "2010-10-27,41,65;" +
    "2010-10-27,42,63;" +
    "2010-10-28,44,78;" +
    "2010-10-28,44,74;" +
    "2010-10-29,49,67;" +
    "2010-10-29,49,67;" +
    "2010-10-30,51,63;" +
    "2010-10-30,50,63;" +
    "2010-10-31,45,72;" +
    "2010-10-31,45,72;" +
    "2010-11-01,45,75;" +
    "2010-11-01,45,73;" +
    "2010-11-02,46,79;" +
    "2010-11-02,46,77;" +
    "2010-11-03,49,79;" +
    "2010-11-03,48,77;" +
    "2010-11-04,51,81;" +
    "2010-11-04,52,79;" +
    "2010-11-05,51,76;" +
    "2010-11-05,52,76;" +
    "2010-11-06,49,71;" +
    "2010-11-06,49,70;" +
    "2010-11-07,48,59;" +
    "2010-11-07,53,59;" +
    "2010-11-08,41,62;" +
    "2010-11-08,43,62;" +
    "2010-11-09,38,62;" +
    "2010-11-09,38,63;" +
    "2010-11-10,43,63;" +
    "2010-11-10,48,64;" +
    "2010-11-11,39,66;" +
    "2010-11-11,42,65;" +
    "2010-11-12,35,68;" +
    "2010-11-12,36,69;" +
    "2010-11-13,47,72;" +
    "2010-11-13,46,69;" +
    "2010-11-14,54,79;" +
    "2010-11-14,53,77;" +
    "2010-11-15,57,79;" +
    "2010-11-15,58,78;" +
    "2010-11-16,48,77;" +
    "2010-11-16,51,76;" +
    "2010-11-17,40,75;" +
    "2010-11-17,41,73;" +
    "2010-11-18,37,64;" +
    "2010-11-18,37,64;" +
    "2010-11-19,45,56;" +
    "2010-11-19,49,56;" +
    "2010-11-20,42,52;" +
    "2010-11-20,41,52;" +
    "2010-11-21,41,54;" +
    "2010-11-21,40,54;" +
    "2010-11-22,43,56;" +
    "2010-11-22,43,58;" +
    "2010-11-23,34,53;" +
    "2010-11-23,45,52;" +
    "2010-11-24,30,51;" +
    "2010-11-24,29,50;" +
    "2010-11-25,28,51;" +
    "2010-11-25,29,50;" +
    "2010-11-26,30,54;" +
    "2010-11-26,28,52;" +
    "2010-11-27,40,53;" +
    "2010-11-27,40,52;" +
    "2010-11-28,30,54;" +
    "2010-11-28,32,54;" +
    "2010-11-29,29,53;" +
    "2010-11-29,29,52;" +
    "2010-11-30,30,52;" +
    "2010-11-30,30,50;" +
    "2010-12-01,31,57;" +
    "2010-12-01,31,55;" +
    "2010-12-02,35,58;" +
    "2010-12-02,35,57;" +
    "2010-12-03,45,54;" +
    "2010-12-03,44,52;" +
    "2010-12-04,47,62;" +
    "2010-12-04,46,62;" +
    "2010-12-05,47,68;" +
    "2010-12-05,48,66;" +
    "2010-12-06,45,64;" +
    "2010-12-06,50,65;" +
    "2010-12-07,39,64;" +
    "2010-12-07,39,64;" +
    "2010-12-08,46,57;" +
    "2010-12-08,46,56;" +
    "2010-12-09,52,64;" +
    "2010-12-09,52,64;" +
    "2010-12-10,54,65;" +
    "2010-12-10,52,65;" +
    "2010-12-11,52,68;" +
    "2010-12-11,56,68;" +
    "2010-12-12,52,59;" +
    "2010-12-12,52,58;" +
    "2010-12-13,50,55;" +
    "2010-12-13,49,55;" +
    "2010-12-14,50,59;" +
    "2010-12-14,51,58;" +
    "2010-12-15,37,55;" +
    "2010-12-15,43,56;" +
    "2010-12-16,32,54;" +
    "2010-12-16,31,52;" +
    "2010-12-17,44,56;" +
    "2010-12-17,43,48;" +
    "2010-12-18,54,61;" +
    "2010-12-18,47,60;" +
    "2010-12-19,49,59;" +
    "2010-12-19,48,58;" +
    "2010-12-20,40,54;" +
    "2010-12-20,46,54;" +
    "2010-12-21,37,59;" +
    "2010-12-21,38,58;" +
    "2010-12-22,47,58;" +
    "2010-12-22,49,60;" +
    "2010-12-23,37,58;" +
    "2010-12-23,39,59;" +
    "2010-12-24,35,59;" +
    "2010-12-24,36,59;" +
    "2010-12-25,42,58;" +
    "2010-12-25,41,56;" +
    "2010-12-26,37,58;" +
    "2010-12-26,38,59;" +
    "2010-12-27,40,57;" +
    "2010-12-27,39,58;" +
    "2010-12-28,44,53;" +
    "2010-12-28,42,54;" +
    "2010-12-29,37,54;" +
    "2010-12-29,43,53;" +
    "2010-12-30,28,52;" +
    "2010-12-30,28,53;" +
    "2010-12-31,29,48;" +
    "2010-12-31,30,47;"