export const data = "2014-01-01,31,68;" +
    "2014-01-01,31,65;" +
    "2014-01-02,37,67;" +
    "2014-01-02,36,63;" +
    "2014-01-03,36,68;" +
    "2014-01-03,36,66;" +
    "2014-01-04,36,67;" +
    "2014-01-04,35,64;" +
    "2014-01-05,34,66;" +
    "2014-01-05,35,62;" +
    "2014-01-06,35,63;" +
    "2014-01-06,35,60;" +
    "2014-01-07,41,66;" +
    "2014-01-07,39,65;" +
    "2014-01-08,47,59;" +
    "2014-01-08,46,58;" +
    "2014-01-09,44,63;" +
    "2014-01-09,43,63;" +
    "2014-01-10,39,66;" +
    "2014-01-10,37,64;" +
    "2014-01-11,40,60;" +
    "2014-01-11,40,57;" +
    "2014-01-12,37,62;" +
    "2014-01-12,38,60;" +
    "2014-01-13,42,65;" +
    "2014-01-13,42,62;" +
    "2014-01-14,42,69;" +
    "2014-01-14,42,67;" +
    "2014-01-15,40,70;" +
    "2014-01-15,41,67;" +
    "2014-01-16,36,71;" +
    "2014-01-16,36,67;" +
    "2014-01-17,36,69;" +
    "2014-01-17,35,66;" +
    "2014-01-18,32,73;" +
    "2014-01-18,32,70;" +
    "2014-01-19,33,68;" +
    "2014-01-19,33,64;" +
    "2014-01-20,34,72;" +
    "2014-01-20,34,69;" +
    "2014-01-21,35,70;" +
    "2014-01-21,34,67;" +
    "2014-01-22,33,75;" +
    "2014-01-22,33,73;" +
    "2014-01-23,37,65;" +
    "2014-01-23,37,62;" +
    "2014-01-24,38,69;" +
    "2014-01-24,38,69;" +
    "2014-01-25,39,74;" +
    "2014-01-25,39,72;" +
    "2014-01-26,39,73;" +
    "2014-01-26,39,72;" +
    "2014-01-27,37,67;" +
    "2014-01-27,39,67;" +
    "2014-01-28,50,71;" +
    "2014-01-28,48,70;" +
    "2014-01-29,55,67;" +
    "2014-01-29,56,65;" +
    "2014-01-30,51,62;" +
    "2014-01-30,50,60;" +
    "2014-01-31,37,63;" +
    "2014-01-31,40,63;" +
    "2014-02-01,32,61;" +
    "2014-02-01,31,61;" +
    "2014-02-02,36,49;" +
    "2014-02-02,36,50;" +
    "2014-02-03,37,57;" +
    "2014-02-03,36,54;" +
    "2014-02-04,33,57;" +
    "2014-02-04,32,56;" +
    "2014-02-05,33,57;" +
    "2014-02-05,32,57;" +
    "2014-02-06,46,56;" +
    "2014-02-06,44,54;" +
    "2014-02-07,49,57;" +
    "2014-02-07,47,54;" +
    "2014-02-08,55,62;" +
    "2014-02-08,51,63;" +
    "2014-02-09,56,62;" +
    "2014-02-09,54,61;" +
    "2014-02-10,51,64;" +
    "2014-02-10,52,63;" +
    "2014-02-11,47,64;" +
    "2014-02-11,44,66;" +
    "2014-02-12,42,67;" +
    "2014-02-12,40,67;" +
    "2014-02-13,48,75;" +
    "2014-02-13,47,75;" +
    "2014-02-14,49,64;" +
    "2014-02-14,52,65;" +
    "2014-02-15,46,62;" +
    "2014-02-15,45,60;" +
    "2014-02-16,42,67;" +
    "2014-02-16,45,66;" +
    "2014-02-17,37,64;" +
    "2014-02-17,36,64;" +
    "2014-02-18,39,66;" +
    "2014-02-18,38,67;" +
    "2014-02-19,48,67;" +
    "2014-02-19,46,66;" +
    "2014-02-20,38,68;" +
    "2014-02-20,38,66;" +
    "2014-02-21,38,73;" +
    "2014-02-21,38,73;" +
    "2014-02-22,39,73;" +
    "2014-02-22,39,73;" +
    "2014-02-23,38,74;" +
    "2014-02-23,39,72;" +
    "2014-02-24,39,74;" +
    "2014-02-24,39,72;" +
    "2014-02-25,42,71;" +
    "2014-02-25,42,71;" +
    "2014-02-26,53,62;" +
    "2014-02-26,50,61;" +
    "2014-02-27,53,66;" +
    "2014-02-27,50,67;" +
    "2014-02-28,51,63;" +
    "2014-02-28,51,61;" +
    "2014-03-01,51,67;" +
    "2014-03-01,50,65;" +
    "2014-03-02,46,63;" +
    "2014-03-02,47,64;" +
    "2014-03-03,43,61;" +
    "2014-03-03,42,59;" +
    "2014-03-04,53,66;" +
    "2014-03-04,52,64;" +
    "2014-03-05,52,70;" +
    "2014-03-05,51,70;" +
    "2014-03-06,50,62;" +
    "2014-03-06,54,66;" +
    "2014-03-07,42,70;" +
    "2014-03-07,42,69;" +
    "2014-03-08,41,71;" +
    "2014-03-08,42,70;" +
    "2014-03-09,55,72;" +
    "2014-03-09,54,72;" +
    "2014-03-10,51,68;" +
    "2014-03-10,53,69;" +
    "2014-03-11,51,69;" +
    "2014-03-11,49,68;" +
    "2014-03-12,50,74;" +
    "2014-03-12,50,74;" +
    "2014-03-13,38,78;" +
    "2014-03-13,42,79;" +
    "2014-03-14,43,75;" +
    "2014-03-14,42,75;" +
    "2014-03-15,43,81;" +
    "2014-03-15,44,81;" +
    "2014-03-16,45,83;" +
    "2014-03-16,46,84;" +
    "2014-03-17,48,69;" +
    "2014-03-17,48,76;" +
    "2014-03-18,44,71;" +
    "2014-03-18,45,69;" +
    "2014-03-19,38,76;" +
    "2014-03-19,40,76;" +
    "2014-03-20,48,78;" +
    "2014-03-20,47,77;" +
    "2014-03-21,43,72;" +
    "2014-03-21,42,73;" +
    "2014-03-22,45,73;" +
    "2014-03-22,44,75;" +
    "2014-03-23,46,76;" +
    "2014-03-23,44,77;" +
    "2014-03-24,43,81;" +
    "2014-03-24,42,82;" +
    "2014-03-25,43,65;" +
    "2014-03-25,42,75;" +
    "2014-03-26,51,61;" +
    "2014-03-26,49,61;" +
    "2014-03-27,49,65;" +
    "2014-03-27,45,65;" +
    "2014-03-28,47,71;" +
    "2014-03-28,46,71;" +
    "2014-03-29,49,63;" +
    "2014-03-29,52,63;" +
    "2014-03-30,43,62;" +
    "2014-03-30,42,62;" +
    "2014-03-31,39,58;" +
    "2014-03-31,42,58;" +
    "2014-04-01,39,57;" +
    "2014-04-01,39,59;" +
    "2014-04-02,39,60;" +
    "2014-04-02,39,61;" +
    "2014-04-03,40,66;" +
    "2014-04-03,39,67;" +
    "2014-04-04,47,62;" +
    "2014-04-04,46,61;" +
    "2014-04-05,43,67;" +
    "2014-04-05,43,68;" +
    "2014-04-06,44,79;" +
    "2014-04-06,43,79;" +
    "2014-04-07,49,80;" +
    "2014-04-07,48,80;" +
    "2014-04-08,51,86;" +
    "2014-04-08,52,85;" +
    "2014-04-09,51,81;" +
    "2014-04-09,52,82;" +
    "2014-04-10,45,84;" +
    "2014-04-10,44,86;" +
    "2014-04-11,52,75;" +
    "2014-04-11,52,79;" +
    "2014-04-12,53,72;" +
    "2014-04-12,51,73;" +
    "2014-04-13,53,80;" +
    "2014-04-13,51,79;" +
    "2014-04-14,48,81;" +
    "2014-04-14,46,81;" +
    "2014-04-15,50,79;" +
    "2014-04-15,49,80;" +
    "2014-04-16,49,83;" +
    "2014-04-16,47,83;" +
    "2014-04-17,50,79;" +
    "2014-04-17,50,80;" +
    "2014-04-18,49,76;" +
    "2014-04-18,47,77;" +
    "2014-04-19,48,71;" +
    "2014-04-19,46,72;" +
    "2014-04-20,50,84;" +
    "2014-04-20,48,84;" +
    "2014-04-21,47,78;" +
    "2014-04-21,48,77;" +
    "2014-04-22,48,66;" +
    "2014-04-22,48,66;" +
    "2014-04-23,42,70;" +
    "2014-04-23,42,70;" +
    "2014-04-24,49,71;" +
    "2014-04-24,49,71;" +
    "2014-04-25,49,58;" +
    "2014-04-25,50,61;" +
    "2014-04-26,43,63;" +
    "2014-04-26,42,62;" +
    "2014-04-27,50,68;" +
    "2014-04-27,49,68;" +
    "2014-04-28,46,77;" +
    "2014-04-28,45,77;" +
    "2014-04-29,46,87;" +
    "2014-04-29,46,85;" +
    "2014-04-30,52,92;" +
    "2014-04-30,54,91;" +
    "2014-05-01,57,95;" +
    "2014-05-01,56,95;" +
    "2014-05-02,51,88;" +
    "2014-05-02,52,88;" +
    "2014-05-03,53,71;" +
    "2014-05-03,52,80;" +
    "2014-05-04,54,71;" +
    "2014-05-04,52,72;" +
    "2014-05-05,53,69;" +
    "2014-05-05,52,68;" +
    "2014-05-06,51,72;" +
    "2014-05-06,49,72;" +
    "2014-05-07,48,72;" +
    "2014-05-07,46,72;" +
    "2014-05-08,52,70;" +
    "2014-05-08,51,69;" +
    "2014-05-09,53,70;" +
    "2014-05-09,55,70;" +
    "2014-05-10,49,72;" +
    "2014-05-10,47,72;" +
    "2014-05-11,47,82;" +
    "2014-05-11,47,82;" +
    "2014-05-12,48,89;" +
    "2014-05-12,47,86;" +
    "2014-05-13,53,95;" +
    "2014-05-13,53,94;" +
    "2014-05-14,58,98;" +
    "2014-05-14,56,98;" +
    "2014-05-15,56,97;" +
    "2014-05-15,56,97;" +
    "2014-05-16,56,88;" +
    "2014-05-16,54,89;" +
    "2014-05-17,55,74;" +
    "2014-05-17,52,80;" +
    "2014-05-18,55,72;" +
    "2014-05-18,52,73;" +
    "2014-05-19,54,70;" +
    "2014-05-19,52,69;" +
    "2014-05-20,53,73;" +
    "2014-05-20,52,72;" +
    "2014-05-21,54,81;" +
    "2014-05-21,53,83;" +
    "2014-05-22,54,82;" +
    "2014-05-22,53,84;" +
    "2014-05-23,54,85;" +
    "2014-05-23,52,86;" +
    "2014-05-24,57,86;" +
    "2014-05-24,55,86;" +
    "2014-05-25,54,93;" +
    "2014-05-25,53,93;" +
    "2014-05-26,54,90;" +
    "2014-05-26,54,91;" +
    "2014-05-27,53,82;" +
    "2014-05-27,52,84;" +
    "2014-05-28,51,83;" +
    "2014-05-28,49,83;" +
    "2014-05-29,51,86;" +
    "2014-05-29,50,86;" +
    "2014-05-30,50,74;" +
    "2014-05-30,49,80;" +
    "2014-05-31,52,84;" +
    "2014-05-31,51,84;" +
    "2014-06-01,51,90;" +
    "2014-06-01,52,91;" +
    "2014-06-02,53,75;" +
    "2014-06-02,51,85;" +
    "2014-06-03,53,83;" +
    "2014-06-03,52,85;" +
    "2014-06-04,53,93;" +
    "2014-06-04,52,94;" +
    "2014-06-05,56,89;" +
    "2014-06-05,56,91;" +
    "2014-06-06,55,86;" +
    "2014-06-06,54,88;" +
    "2014-06-07,55,93;" +
    "2014-06-07,53,95;" +
    "2014-06-08,61,105;" +
    "2014-06-08,59,104;" +
    "2014-06-09,65,106;" +
    "2014-06-09,64,105;" +
    "2014-06-10,59,87;" +
    "2014-06-10,60,95;" +
    "2014-06-11,57,81;" +
    "2014-06-11,55,82;" +
    "2014-06-12,56,75;" +
    "2014-06-12,54,77;" +
    "2014-06-13,52,86;" +
    "2014-06-13,50,87;" +
    "2014-06-14,54,90;" +
    "2014-06-14,52,90;" +
    "2014-06-15,54,79;" +
    "2014-06-15,53,82;" +
    "2014-06-16,53,71;" +
    "2014-06-16,53,70;" +
    "2014-06-17,52,81;" +
    "2014-06-17,48,82;" +
    "2014-06-18,51,91;" +
    "2014-06-18,51,92;" +
    "2014-06-19,54,89;" +
    "2014-06-19,54,91;" +
    "2014-06-20,55,92;" +
    "2014-06-20,55,92;" +
    "2014-06-21,55,80;" +
    "2014-06-21,54,83;" +
    "2014-06-22,53,82;" +
    "2014-06-22,50,84;" +
    "2014-06-23,53,88;" +
    "2014-06-23,52,89;" +
    "2014-06-24,57,83;" +
    "2014-06-24,55,84;" +
    "2014-06-25,57,73;" +
    "2014-06-25,54,74;" +
    "2014-06-26,60,78;" +
    "2014-06-26,58,79;" +
    "2014-06-27,58,81;" +
    "2014-06-27,57,83;" +
    "2014-06-28,58,85;" +
    "2014-06-28,56,86;" +
    "2014-06-29,56,98;" +
    "2014-06-29,55,98;" +
    "2014-06-30,63,105;" +
    "2014-06-30,62,105;" +
    "2014-07-01,58,88;" +
    "2014-07-01,58,90;" +
    "2014-07-02,58,86;" +
    "2014-07-02,56,88;" +
    "2014-07-03,57,88;" +
    "2014-07-03,55,90;" +
    "2014-07-04,57,92;" +
    "2014-07-04,55,94;" +
    "2014-07-05,54,94;" +
    "2014-07-05,53,97;" +
    "2014-07-06,54,98;" +
    "2014-07-06,55,100;" +
    "2014-07-07,60,88;" +
    "2014-07-07,61,95;" +
    "2014-07-08,59,91;" +
    "2014-07-08,60,93;" +
    "2014-07-09,58,87;" +
    "2014-07-09,56,89;" +
    "2014-07-10,58,85;" +
    "2014-07-10,56,86;" +
    "2014-07-11,59,81;" +
    "2014-07-11,58,81;" +
    "2014-07-12,58,83;" +
    "2014-07-12,58,85;" +
    "2014-07-13,57,94;" +
    "2014-07-13,56,95;" +
    "2014-07-14,64,101;" +
    "2014-07-14,63,100;" +
    "2014-07-15,65,89;" +
    "2014-07-15,63,91;" +
    "2014-07-16,62,85;" +
    "2014-07-16,61,85;" +
    "2014-07-17,61,80;" +
    "2014-07-17,59,81;" +
    "2014-07-18,61,80;" +
    "2014-07-18,59,82;" +
    "2014-07-19,61,88;" +
    "2014-07-19,59,90;" +
    "2014-07-20,62,83;" +
    "2014-07-20,61,85;" +
    "2014-07-21,61,81;" +
    "2014-07-21,62,82;" +
    "2014-07-22,61,82;" +
    "2014-07-22,59,83;" +
    "2014-07-23,62,83;" +
    "2014-07-23,62,84;" +
    "2014-07-24,59,96;" +
    "2014-07-24,58,91;" +
    "2014-07-25,64,102;" +
    "2014-07-25,61,102;" +
    "2014-07-26,64,101;" +
    "2014-07-26,63,102;" +
    "2014-07-27,68,99;" +
    "2014-07-27,68,100;" +
    "2014-07-28,61,95;" +
    "2014-07-28,60,96;" +
    "2014-07-29,65,97;" +
    "2014-07-29,64,98;" +
    "2014-07-30,64,99;" +
    "2014-07-30,63,100;" +
    "2014-07-31,60,98;" +
    "2014-07-31,60,100;" +
    "2014-08-01,65,102;" +
    "2014-08-01,64,102;" +
    "2014-08-02,59,89;" +
    "2014-08-02,59,97;" +
    "2014-08-03,59,85;" +
    "2014-08-03,58,87;" +
    "2014-08-04,60,81;" +
    "2014-08-04,58,83;" +
    "2014-08-05,61,89;" +
    "2014-08-05,60,88;" +
    "2014-08-06,65,90;" +
    "2014-08-06,68,91;" +
    "2014-08-07,60,87;" +
    "2014-08-07,59,90;" +
    "2014-08-08,59,89;" +
    "2014-08-08,57,90;" +
    "2014-08-09,57,81;" +
    "2014-08-09,56,85;" +
    "2014-08-10,57,86;" +
    "2014-08-10,56,88;" +
    "2014-08-11,57,90;" +
    "2014-08-11,57,91;" +
    "2014-08-12,57,85;" +
    "2014-08-12,56,87;" +
    "2014-08-13,58,86;" +
    "2014-08-13,56,86;" +
    "2014-08-14,60,88;" +
    "2014-08-14,58,89;" +
    "2014-08-15,58,92;" +
    "2014-08-15,58,93;" +
    "2014-08-16,58,92;" +
    "2014-08-16,57,94;" +
    "2014-08-17,56,90;" +
    "2014-08-17,55,92;" +
    "2014-08-18,59,83;" +
    "2014-08-18,57,85;" +
    "2014-08-19,61,81;" +
    "2014-08-19,59,82;" +
    "2014-08-20,60,80;" +
    "2014-08-20,60,81;" +
    "2014-08-21,59,90;" +
    "2014-08-21,58,91;" +
    "2014-08-22,59,86;" +
    "2014-08-22,58,86;" +
    "2014-08-23,57,87;" +
    "2014-08-23,56,87;" +
    "2014-08-24,58,84;" +
    "2014-08-24,57,85;" +
    "2014-08-25,58,77;" +
    "2014-08-25,59,79;" +
    "2014-08-26,56,87;" +
    "2014-08-26,54,89;" +
    "2014-08-27,60,95;" +
    "2014-08-27,57,96;" +
    "2014-08-28,59,94;" +
    "2014-08-28,58,95;" +
    "2014-08-29,60,88;" +
    "2014-08-29,58,90;" +
    "2014-08-30,60,94;" +
    "2014-08-30,59,94;" +
    "2014-08-31,59,93;" +
    "2014-08-31,57,93;" +
    "2014-09-01,57,99;" +
    "2014-09-01,57,100;" +
    "2014-09-02,60,84;" +
    "2014-09-02,60,91;" +
    "2014-09-03,59,88;" +
    "2014-09-03,58,90;" +
    "2014-09-04,59,89;" +
    "2014-09-04,58,90;" +
    "2014-09-05,59,86;" +
    "2014-09-05,58,87;" +
    "2014-09-06,57,87;" +
    "2014-09-06,55,88;" +
    "2014-09-07,55,85;" +
    "2014-09-07,54,86;" +
    "2014-09-08,56,76;" +
    "2014-09-08,56,77;" +
    "2014-09-09,55,88;" +
    "2014-09-09,53,88;" +
    "2014-09-10,53,97;" +
    "2014-09-10,53,96;" +
    "2014-09-11,59,101;" +
    "2014-09-11,57,101;" +
    "2014-09-12,59,101;" +
    "2014-09-12,59,102;" +
    "2014-09-13,63,98;" +
    "2014-09-13,61,100;" +
    "2014-09-14,61,93;" +
    "2014-09-14,59,94;" +
    "2014-09-15,61,85;" +
    "2014-09-15,59,88;" +
    "2014-09-16,57,90;" +
    "2014-09-16,55,91;" +
    "2014-09-17,59,91;" +
    "2014-09-17,58,90;" +
    "2014-09-18,63,82;" +
    "2014-09-18,67,82;" +
    "2014-09-19,59,86;" +
    "2014-09-19,58,85;" +
    "2014-09-20,61,85;" +
    "2014-09-20,61,86;" +
    "2014-09-21,62,76;" +
    "2014-09-21,60,76;" +
    "2014-09-22,62,86;" +
    "2014-09-22,62,87;" +
    "2014-09-23,60,87;" +
    "2014-09-23,58,87;" +
    "2014-09-24,64,83;" +
    "2014-09-24,64,83;" +
    "2014-09-25,58,75;" +
    "2014-09-25,57,75;" +
    "2014-09-26,57,77;" +
    "2014-09-26,56,76;" +
    "2014-09-27,59,78;" +
    "2014-09-27,58,78;" +
    "2014-09-28,56,77;" +
    "2014-09-28,54,76;" +
    "2014-09-29,56,80;" +
    "2014-09-29,54,81;" +
    "2014-09-30,54,81;" +
    "2014-09-30,52,82;" +
    "2014-10-01,55,87;" +
    "2014-10-01,55,84;" +
    "2014-10-02,51,90;" +
    "2014-10-02,51,87;" +
    "2014-10-03,54,95;" +
    "2014-10-03,54,93;" +
    "2014-10-04,53,96;" +
    "2014-10-04,54,95;" +
    "2014-10-05,55,99;" +
    "2014-10-05,54,97;" +
    "2014-10-06,57,99;" +
    "2014-10-06,55,100;" +
    "2014-10-07,56,94;" +
    "2014-10-07,55,93;" +
    "2014-10-08,55,96;" +
    "2014-10-08,54,95;" +
    "2014-10-09,54,85;" +
    "2014-10-09,53,86;" +
    "2014-10-10,55,81;" +
    "2014-10-10,54,82;" +
    "2014-10-11,53,88;" +
    "2014-10-11,52,88;" +
    "2014-10-12,57,91;" +
    "2014-10-12,56,89;" +
    "2014-10-13,55,92;" +
    "2014-10-13,55,92;" +
    "2014-10-14,55,74;" +
    "2014-10-14,52,81;" +
    "2014-10-15,56,74;" +
    "2014-10-15,59,74;" +
    "2014-10-16,52,75;" +
    "2014-10-16,52,75;" +
    "2014-10-17,50,77;" +
    "2014-10-17,49,77;" +
    "2014-10-18,52,81;" +
    "2014-10-18,51,81;" +
    "2014-10-19,53,82;" +
    "2014-10-19,52,82;" +
    "2014-10-20,54,71;" +
    "2014-10-20,58,70;" +
    "2014-10-21,47,73;" +
    "2014-10-21,46,73;" +
    "2014-10-22,49,77;" +
    "2014-10-22,46,77;" +
    "2014-10-23,55,77;" +
    "2014-10-23,52,77;" +
    "2014-10-24,51,80;" +
    "2014-10-24,50,81;" +
    "2014-10-25,56,73;" +
    "2014-10-25,58,71;" +
    "2014-10-26,49,71;" +
    "2014-10-26,50,70;" +
    "2014-10-27,43,73;" +
    "2014-10-27,42,72;" +
    "2014-10-28,45,78;" +
    "2014-10-28,44,78;" +
    "2014-10-29,49,81;" +
    "2014-10-29,47,78;" +
    "2014-10-30,55,84;" +
    "2014-10-30,53,82;" +
    "2014-10-31,55,66;" +
    "2014-10-31,54,67;" +
    "2014-11-01,46,64;" +
    "2014-11-01,49,64;" +
    "2014-11-02,42,69;" +
    "2014-11-02,42,68;" +
    "2014-11-03,41,70;" +
    "2014-11-03,40,70;" +
    "2014-11-04,42,72;" +
    "2014-11-04,41,70;" +
    "2014-11-05,47,75;" +
    "2014-11-05,46,73;" +
    "2014-11-06,47,81;" +
    "2014-11-06,47,80;" +
    "2014-11-07,47,80;" +
    "2014-11-07,45,78;" +
    "2014-11-08,48,80;" +
    "2014-11-08,46,76;" +
    "2014-11-09,46,82;" +
    "2014-11-09,46,82;" +
    "2014-11-10,48,80;" +
    "2014-11-10,46,78;" +
    "2014-11-11,55,69;" +
    "2014-11-11,53,67;" +
    "2014-11-12,58,68;" +
    "2014-11-12,54,68;" +
    "2014-11-13,55,67;" +
    "2014-11-13,53,65;" +
    "2014-11-14,50,69;" +
    "2014-11-14,48,67;" +
    "2014-11-15,47,68;" +
    "2014-11-15,44,67;" +
    "2014-11-16,44,62;" +
    "2014-11-16,43,59;" +
    "2014-11-17,42,67;" +
    "2014-11-17,40,63;" +
    "2014-11-18,44,63;" +
    "2014-11-18,41,58;" +
    "2014-11-19,51,69;" +
    "2014-11-19,48,67;" +
    "2014-11-20,53,60;" +
    "2014-11-20,51,59;" +
    "2014-11-21,51,64;" +
    "2014-11-21,47,62;" +
    "2014-11-22,49,69;" +
    "2014-11-22,55,66;" +
    "2014-11-23,43,65;" +
    "2014-11-23,42,62;" +
    "2014-11-24,38,69;" +
    "2014-11-24,38,66;" +
    "2014-11-25,41,65;" +
    "2014-11-25,40,61;" +
    "2014-11-26,42,68;" +
    "2014-11-26,40,65;" +
    "2014-11-27,42,73;" +
    "2014-11-27,42,72;" +
    "2014-11-28,36,69;" +
    "2014-11-28,35,68;" +
    "2014-11-29,54,68;" +
    "2014-11-29,48,64;" +
    "2014-11-30,52,60;" +
    "2014-11-30,51,58;" +
    "2014-12-01,50,69;" +
    "2014-12-01,47,68;" +
    "2014-12-02,55,61;" +
    "2014-12-02,52,57;" +
    "2014-12-03,55,67;" +
    "2014-12-03,53,64;" +
    "2014-12-04,53,69;" +
    "2014-12-04,51,68;" +
    "2014-12-05,51,70;" +
    "2014-12-05,49,66;" +
    "2014-12-06,57,71;" +
    "2014-12-06,56,68;" +
    "2014-12-07,51,64;" +
    "2014-12-07,50,62;" +
    "2014-12-08,50,72;" +
    "2014-12-08,46,71;" +
    "2014-12-09,50,66;" +
    "2014-12-09,48,64;" +
    "2014-12-10,53,67;" +
    "2014-12-10,50,64;" +
    "2014-12-11,51,68;" +
    "2014-12-11,49,66;" +
    "2014-12-12,46,57;" +
    "2014-12-12,46,53;" +
    "2014-12-13,44,55;" +
    "2014-12-13,41,52;" +
    "2014-12-14,45,56;" +
    "2014-12-14,41,53;" +
    "2014-12-15,48,59;" +
    "2014-12-15,45,55;" +
    "2014-12-16,47,63;" +
    "2014-12-16,44,61;" +
    "2014-12-17,46,60;" +
    "2014-12-17,43,58;" +
    "2014-12-18,51,60;" +
    "2014-12-18,48,58;" +
    "2014-12-19,49,62;" +
    "2014-12-19,47,59;" +
    "2014-12-20,55,62;" +
    "2014-12-20,52,59;" +
    "2014-12-21,57,67;" +
    "2014-12-21,54,64;" +
    "2014-12-22,52,67;" +
    "2014-12-22,55,67;" +
    "2014-12-23,49,70;" +
    "2014-12-23,49,67;" +
    "2014-12-24,45,60;" +
    "2014-12-24,43,56;" +
    "2014-12-25,35,58;" +
    "2014-12-25,34,56;" +
    "2014-12-26,39,57;" +
    "2014-12-26,42,55;" +
    "2014-12-27,33,54;" +
    "2014-12-27,32,52;" +
    "2014-12-28,37,59;" +
    "2014-12-28,35,59;" +
    "2014-12-29,41,57;" +
    "2014-12-29,37,55;" +
    "2014-12-30,37,54;" +
    "2014-12-30,37,52;" +
    "2014-12-31,38,57;" +
    "2014-12-31,35,54;"