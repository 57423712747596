export const data = "1984-01-01,37,62;" +
    "1984-01-02,35,58;" +
    "1984-01-03,34,54;" +
    "1984-01-04,39,55;" +
    "1984-01-05,38,50;" +
    "1984-01-06,35,50;" +
    "1984-01-07,38,50;" +
    "1984-01-08,41,50;" +
    "1984-01-09,40,45;" +
    "1984-01-10,40,48;" +
    "1984-01-11,39,60;" +
    "1984-01-12,35,50;" +
    "1984-01-13,27,53;" +
    "1984-01-14,27,54;" +
    "1984-01-15,38,50;" +
    "1984-01-16,39,52;" +
    "1984-01-17,28,54;" +
    "1984-01-18,26,52;" +
    "1984-01-19,33,59;" +
    "1984-01-20,29,55;" +
    "1984-01-21,40,54;" +
    "1984-01-22,33,52;" +
    "1984-01-23,36,67;" +
    "1984-01-24,39,68;" +
    "1984-01-25,39,71;" +
    "1984-01-26,40,65;" +
    "1984-01-27,44,63;" +
    "1984-01-28,38,67;" +
    "1984-01-29,34,63;" +
    "1984-01-30,35,67;" +
    "1984-01-31,41,60;" +
    "1984-02-01,36,59;" +
    "1984-02-02,32,63;" +
    "1984-02-03,38,67;" +
    "1984-02-04,33,65;" +
    "1984-02-05,35,68;" +
    "1984-02-06,34,64;" +
    "1984-02-07,35,62;" +
    "1984-02-08,38,69;" +
    "1984-02-09,45,57;" +
    "1984-02-10,35,57;" +
    "1984-02-11,33,51;" +
    "1984-02-12,42,54;" +
    "1984-02-13,48,59;" +
    "1984-02-14,32,61;" +
    "1984-02-15,45,57;" +
    "1984-02-16,38,55;" +
    "1984-02-17,31,61;" +
    "1984-02-18,31,64;" +
    "1984-02-19,37,65;" +
    "1984-02-20,39,66;" +
    "1984-02-21,43,59;" +
    "1984-02-22,33,59;" +
    "1984-02-23,30,61;" +
    "1984-02-24,43,59;" +
    "1984-02-25,36,63;" +
    "1984-02-26,32,62;" +
    "1984-02-27,33,64;" +
    "1984-02-28,34,69;" +
    "1984-02-29,42,62;" +
    "1984-03-01,43,71;" +
    "1984-03-02,47,68;" +
    "1984-03-03,37,72;" +
    "1984-03-04,43,72;" +
    "1984-03-05,44,73;" +
    "1984-03-06,36,71;" +
    "1984-03-07,41,66;" +
    "1984-03-08,39,71;" +
    "1984-03-09,41,70;" +
    "1984-03-10,45,70;" +
    "1984-03-11,45,68;" +
    "1984-03-12,49,74;" +
    "1984-03-13,51,64;" +
    "1984-03-14,48,61;" +
    "1984-03-15,46,62;" +
    "1984-03-16,37,65;" +
    "1984-03-17,37,64;" +
    "1984-03-18,37,70;" +
    "1984-03-19,41,75;" +
    "1984-03-20,45,79;" +
    "1984-03-21,41,68;" +
    "1984-03-22,38,74;" +
    "1984-03-23,41,80;" +
    "1984-03-24,45,70;" +
    "1984-03-25,40,70;" +
    "1984-03-26,50,64;" +
    "1984-03-27,36,74;" +
    "1984-03-28,44,78;" +
    "1984-03-29,38,68;" +
    "1984-03-30,38,70;" +
    "1984-03-31,41,57;" +
    "1984-04-01,37,65;" +
    "1984-04-02,36,67;" +
    "1984-04-03,38,73;" +
    "1984-04-04,43,65;" +
    "1984-04-05,44,64;" +
    "1984-04-06,41,68;" +
    "1984-04-07,39,68;" +
    "1984-04-08,48,62;" +
    "1984-04-09,34,64;" +
    "1984-04-10,46,65;" +
    "1984-04-11,36,68;" +
    "1984-04-12,40,72;" +
    "1984-04-13,39,82;" +
    "1984-04-14,43,87;" +
    "1984-04-15,46,80;" +
    "1984-04-16,46,66;" +
    "1984-04-17,42,63;" +
    "1984-04-18,44,62;" +
    "1984-04-19,43,61;" +
    "1984-04-20,36,70;" +
    "1984-04-21,38,80;" +
    "1984-04-22,48,82;" +
    "1984-04-23,45,72;" +
    "1984-04-24,42,73;" +
    "1984-04-25,42,65;" +
    "1984-04-26,36,64;" +
    "1984-04-27,33,71;" +
    "1984-04-28,37,75;" +
    "1984-04-29,41,64;" +
    "1984-04-30,46,67;" +
    "1984-05-01,48,69;" +
    "1984-05-02,51,71;" +
    "1984-05-03,48,72;" +
    "1984-05-04,47,67;" +
    "1984-05-05,39,71;" +
    "1984-05-06,38,80;" +
    "1984-05-07,41,88;" +
    "1984-05-08,47,92;" +
    "1984-05-09,48,78;" +
    "1984-05-10,45,75;" +
    "1984-05-11,51,82;" +
    "1984-05-12,54,88;" +
    "1984-05-13,51,81;" +
    "1984-05-14,48,67;" +
    "1984-05-15,43,70;" +
    "1984-05-16,39,79;" +
    "1984-05-17,47,74;" +
    "1984-05-18,45,83;" +
    "1984-05-19,47,85;" +
    "1984-05-20,48,78;" +
    "1984-05-21,44,81;" +
    "1984-05-22,48,92;" +
    "1984-05-23,57,85;" +
    "1984-05-24,46,76;" +
    "1984-05-25,47,92;" +
    "1984-05-26,53,93;" +
    "1984-05-27,52,100;" +
    "1984-05-28,57,103;" +
    "1984-05-29,59,98;" +
    "1984-05-30,55,93;" +
    "1984-05-31,47,90;" +
    "1984-06-01,49,93;" +
    "1984-06-02,47,84;" +
    "1984-06-03,46,83;" +
    "1984-06-04,56,68;" +
    "1984-06-05,49,71;" +
    "1984-06-06,50,67;" +
    "1984-06-07,48,76;" +
    "1984-06-08,46,81;" +
    "1984-06-09,47,82;" +
    "1984-06-10,43,87;" +
    "1984-06-11,49,81;" +
    "1984-06-12,49,73;" +
    "1984-06-13,37,80;" +
    "1984-06-14,48,82;" +
    "1984-06-15,50,85;" +
    "1984-06-16,58,95;" +
    "1984-06-17,55,98;" +
    "1984-06-18,50,89;" +
    "1984-06-19,49,75;" +
    "1984-06-20,48,75;" +
    "1984-06-21,44,88;" +
    "1984-06-22,50,92;" +
    "1984-06-23,52,95;" +
    "1984-06-24,54,95;" +
    "1984-06-25,57,94;" +
    "1984-06-26,50,91;" +
    "1984-06-27,54,98;" +
    "1984-06-28,53,86;" +
    "1984-06-29,50,83;" +
    "1984-06-30,48,96;" +
    "1984-07-01,54,105;" +
    "1984-07-02,63,107;" +
    "1984-07-03,62,100;" +
    "1984-07-04,60,100;" +
    "1984-07-05,58,94;" +
    "1984-07-06,57,97;" +
    "1984-07-07,54,94;" +
    "1984-07-08,53,95;" +
    "1984-07-09,52,93;" +
    "1984-07-10,52,99;" +
    "1984-07-11,54,99;" +
    "1984-07-12,56,103;" +
    "1984-07-13,61,107;" +
    "1984-07-14,66,101;" +
    "1984-07-15,63,98;" +
    "1984-07-16,65,98;" +
    "1984-07-17,64,98;" +
    "1984-07-18,60,93;" +
    "1984-07-19,56,91;" +
    "1984-07-20,55,87;" +
    "1984-07-21,52,83;" +
    "1984-07-22,52,75;" +
    "1984-07-23,52,82;" +
    "1984-07-24,52,79;" +
    "1984-07-25,50,85;" +
    "1984-07-26,48,95;" +
    "1984-07-27,50,93;" +
    "1984-07-28,52,81;" +
    "1984-07-29,51,95;" +
    "1984-07-30,55,98;" +
    "1984-07-31,54,91;" +
    "1984-08-01,53,84;" +
    "1984-08-02,51,78;" +
    "1984-08-03,49,90;" +
    "1984-08-04,50,95;" +
    "1984-08-05,50,83;" +
    "1984-08-06,50,96;" +
    "1984-08-07,54,105;" +
    "1984-08-08,59,105;" +
    "1984-08-09,56,107;" +
    "1984-08-10,59,95;" +
    "1984-08-11,55,88;" +
    "1984-08-12,52,90;" +
    "1984-08-13,63,91;" +
    "1984-08-14,54,93;" +
    "1984-08-15,53,85;" +
    "1984-08-16,52,90;" +
    "1984-08-17,54,99;" +
    "1984-08-18,57,97;" +
    "1984-08-19,52,90;" +
    "1984-08-20,51,90;" +
    "1984-08-21,52,89;" +
    "1984-08-22,52,90;" +
    "1984-08-23,49,89;" +
    "1984-08-24,49,80;" +
    "1984-08-25,53,77;" +
    "1984-08-26,51,85;" +
    "1984-08-27,52,90;" +
    "1984-08-28,55,97;" +
    "1984-08-29,56,95;" +
    "1984-08-30,57,82;" +
    "1984-08-31,48,90;" +
    "1984-09-01,52,97;" +
    "1984-09-02,52,100;" +
    "1984-09-03,53,98;" +
    "1984-09-04,55,102;" +
    "1984-09-05,60,94;" +
    "1984-09-06,53,91;" +
    "1984-09-07,55,101;" +
    "1984-09-08,63,105;" +
    "1984-09-09,61,106;" +
    "1984-09-10,62,93;" +
    "1984-09-11,53,85;" +
    "1984-09-12,50,93;" +
    "1984-09-13,51,96;" +
    "1984-09-14,56,95;" +
    "1984-09-15,53,90;" +
    "1984-09-16,50,98;" +
    "1984-09-17,62,98;" +
    "1984-09-18,65,97;" +
    "1984-09-19,65,96;" +
    "1984-09-20,56,75;" +
    "1984-09-21,51,87;" +
    "1984-09-22,49,90;" +
    "1984-09-23,47,81;" +
    "1984-09-24,45,82;" +
    "1984-09-25,55,88;" +
    "1984-09-26,50,92;" +
    "1984-09-27,52,95;" +
    "1984-09-28,53,94;" +
    "1984-09-29,53,89;" +
    "1984-09-30,55,81;" +
    "1984-10-01,48,80;" +
    "1984-10-02,47,82;" +
    "1984-10-03,54,73;" +
    "1984-10-04,52,75;" +
    "1984-10-05,55,79;" +
    "1984-10-06,50,86;" +
    "1984-10-07,52,89;" +
    "1984-10-08,52,83;" +
    "1984-10-09,54,79;" +
    "1984-10-10,44,77;" +
    "1984-10-11,52,70;" +
    "1984-10-12,44,75;" +
    "1984-10-13,55,76;" +
    "1984-10-14,46,77;" +
    "1984-10-15,44,70;" +
    "1984-10-16,42,60;" +
    "1984-10-17,38,65;" +
    "1984-10-18,36,67;" +
    "1984-10-19,47,65;" +
    "1984-10-20,45,69;" +
    "1984-10-21,40,69;" +
    "1984-10-22,39,75;" +
    "1984-10-23,41,76;" +
    "1984-10-24,43,80;" +
    "1984-10-25,51,83;" +
    "1984-10-26,46,66;" +
    "1984-10-27,35,66;" +
    "1984-10-28,39,68;" +
    "1984-10-29,52,68;" +
    "1984-10-30,46,61;" +
    "1984-10-31,41,68;" +
    "1984-11-01,32,75;" +
    "1984-11-02,39,69;" +
    "1984-11-03,45,70;" +
    "1984-11-04,41,71;" +
    "1984-11-05,42,65;" +
    "1984-11-06,51,68;" +
    "1984-11-07,41,65;" +
    "1984-11-08,49,60;" +
    "1984-11-09,37,65;" +
    "1984-11-10,48,60;" +
    "1984-11-11,52,64;" +
    "1984-11-12,53,60;" +
    "1984-11-13,49,65;" +
    "1984-11-14,41,57;" +
    "1984-11-15,43,54;" +
    "1984-11-16,48,60;" +
    "1984-11-17,40,65;" +
    "1984-11-18,49,63;" +
    "1984-11-19,38,64;" +
    "1984-11-20,43,52;" +
    "1984-11-21,41,57;" +
    "1984-11-22,39,63;" +
    "1984-11-23,35,56;" +
    "1984-11-24,46,57;" +
    "1984-11-25,36,57;" +
    "1984-11-26,32,52;" +
    "1984-11-27,44,63;" +
    "1984-11-28,48,60;" +
    "1984-11-29,35,58;" +
    "1984-11-30,37,61;" +
    "1984-12-01,45,55;" +
    "1984-12-02,37,50;" +
    "1984-12-03,44,50;" +
    "1984-12-04,34,51;" +
    "1984-12-05,44,53;" +
    "1984-12-06,45,63;" +
    "1984-12-07,38,63;" +
    "1984-12-08,40,64;" +
    "1984-12-09,42,59;" +
    "1984-12-10,47,61;" +
    "1984-12-11,44,60;" +
    "1984-12-12,47,59;" +
    "1984-12-13,38,56;" +
    "1984-12-14,42,58;" +
    "1984-12-15,36,55;" +
    "1984-12-16,34,53;" +
    "1984-12-17,27,47;" +
    "1984-12-18,38,53;" +
    "1984-12-19,41,50;" +
    "1984-12-20,32,57;" +
    "1984-12-21,32,52;" +
    "1984-12-22,29,50;" +
    "1984-12-23,29,54;" +
    "1984-12-24,33,42;" +
    "1984-12-25,34,43;" +
    "1984-12-26,36,42;" +
    "1984-12-27,31,53;" +
    "1984-12-28,30,56;" +
    "1984-12-29,32,44;" +
    "1984-12-30,38,53;" +
    "1984-12-31,31,50;"