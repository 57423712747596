export const data = "1987-01-01,38,60;" +
    "1987-01-02,40,56;" +
    "1987-01-03,44,56;" +
    "1987-01-04,34,52;" +
    "1987-01-05,34,56;" +
    "1987-01-06,36,53;" +
    "1987-01-07,30,56;" +
    "1987-01-08,26,52;" +
    "1987-01-09,35,54;" +
    "1987-01-10,28,47;" +
    "1987-01-11,29,43;" +
    "1987-01-12,29,47;" +
    "1987-01-13,28,58;" +
    "1987-01-14,36,62;" +
    "1987-01-15,31,50;" +
    "1987-01-16,25,53;" +
    "1987-01-17,33,56;" +
    "1987-01-18,26,63;" +
    "1987-01-19,28,65;" +
    "1987-01-20,33,63;" +
    "1987-01-21,25,56;" +
    "1987-01-22,32,57;" +
    "1987-01-23,41,60;" +
    "1987-01-24,42,58;" +
    "1987-01-25,40,70;" +
    "1987-01-26,34,65;" +
    "1987-01-27,38,55;" +
    "1987-01-28,40,58;" +
    "1987-01-29,35,60;" +
    "1987-01-30,42,56;" +
    "1987-01-31,33,64;" +
    "1987-02-01,28,71;" +
    "1987-02-02,48,56;" +
    "1987-02-03,45,58;" +
    "1987-02-04,44,61;" +
    "1987-02-05,33,64;" +
    "1987-02-06,32,70;" +
    "1987-02-07,35,71;" +
    "1987-02-08,40,69;" +
    "1987-02-09,49,65;" +
    "1987-02-10,48,65;" +
    "1987-02-11,46,69;" +
    "1987-02-12,46,58;" +
    "1987-02-13,51,62;" +
    "1987-02-14,37,58;" +
    "1987-02-15,43,60;" +
    "1987-02-16,32,64;" +
    "1987-02-17,32,66;" +
    "1987-02-18,34,63;" +
    "1987-02-19,37,67;" +
    "1987-02-20,42,65;" +
    "1987-02-21,34,57;" +
    "1987-02-22,31,65;" +
    "1987-02-23,36,57;" +
    "1987-02-24,29,58;" +
    "1987-02-25,29,56;" +
    "1987-02-26,26,64;" +
    "1987-02-27,30,65;" +
    "1987-02-28,32,67;" +
    "1987-03-01,37,63;" +
    "1987-03-02,42,66;" +
    "1987-03-03,58,68;" +
    "1987-03-04,49,61;" +
    "1987-03-05,52,63;" +
    "1987-03-06,49,64;" +
    "1987-03-07,39,63;" +
    "1987-03-08,41,65;" +
    "1987-03-09,44,67;" +
    "1987-03-10,43,64;" +
    "1987-03-11,51,69;" +
    "1987-03-12,47,65;" +
    "1987-03-13,47,65;" +
    "1987-03-14,45,56;" +
    "1987-03-15,39,61;" +
    "1987-03-16,37,67;" +
    "1987-03-17,40,68;" +
    "1987-03-18,44,56;" +
    "1987-03-19,36,57;" +
    "1987-03-20,33,60;" +
    "1987-03-21,41,54;" +
    "1987-03-22,34,61;" +
    "1987-03-23,44,61;" +
    "1987-03-24,36,67;" +
    "1987-03-25,40,69;" +
    "1987-03-26,39,73;" +
    "1987-03-27,45,73;" +
    "1987-03-28,44,70;" +
    "1987-03-29,48,72;" +
    "1987-03-30,43,74;" +
    "1987-03-31,45,76;" +
    "1987-04-01,44,78;" +
    "1987-04-02,46,67;" +
    "1987-04-03,48,67;" +
    "1987-04-04,43,69;" +
    "1987-04-05,46,68;" +
    "1987-04-06,43,70;" +
    "1987-04-07,43,76;" +
    "1987-04-08,46,80;" +
    "1987-04-09,46,83;" +
    "1987-04-10,49,78;" +
    "1987-04-11,50,70;" +
    "1987-04-12,40,76;" +
    "1987-04-13,43,81;" +
    "1987-04-14,46,84;" +
    "1987-04-15,49,87;" +
    "1987-04-16,52,90;" +
    "1987-04-17,47,77;" +
    "1987-04-18,46,66;" +
    "1987-04-19,43,71;" +
    "1987-04-20,47,80;" +
    "1987-04-21,47,87;" +
    "1987-04-22,50,86;" +
    "1987-04-23,47,73;" +
    "1987-04-24,49,81;" +
    "1987-04-25,47,87;" +
    "1987-04-26,53,85;" +
    "1987-04-27,50,92;" +
    "1987-04-28,52,78;" +
    "1987-04-29,49,76;" +
    "1987-04-30,52,67;" +
    "1987-05-01,50,67;" +
    "1987-05-02,46,73;" +
    "1987-05-03,46,83;" +
    "1987-05-04,46,92;" +
    "1987-05-05,57,97;" +
    "1987-05-06,59,101;" +
    "1987-05-07,60,101;" +
    "1987-05-08,63,89;" +
    "1987-05-09,60,88;" +
    "1987-05-10,56,94;" +
    "1987-05-11,54,93;" +
    "1987-05-12,56,94;" +
    "1987-05-13,59,92;" +
    "1987-05-14,58,95;" +
    "1987-05-15,60,84;" +
    "1987-05-16,54,71;" +
    "1987-05-17,51,74;" +
    "1987-05-18,53,68;" +
    "1987-05-19,50,72;" +
    "1987-05-20,51,72;" +
    "1987-05-21,51,67;" +
    "1987-05-22,49,78;" +
    "1987-05-23,49,71;" +
    "1987-05-24,52,63;" +
    "1987-05-25,51,65;" +
    "1987-05-26,51,64;" +
    "1987-05-27,51,67;" +
    "1987-05-28,44,77;" +
    "1987-05-29,52,68;" +
    "1987-05-30,50,73;" +
    "1987-05-31,56,74;" +
    "1987-07-01,55,72;" +
    "1987-07-02,53,78;" +
    "1987-07-03,55,74;" +
    "1987-07-04,56,77;" +
    "1987-07-05,56,77;" +
    "1987-07-06,53,92;" +
    "1987-07-07,57,95;" +
    "1987-07-08,55,86;" +
    "1987-07-09,58,89;" +
    "1987-07-10,57,82;" +
    "1987-07-11,56,84;" +
    "1987-07-12,56,84;" +
    "1987-07-13,56,92;" +
    "1987-07-14,59,94;" +
    "1987-07-15,57,90;" +
    "1987-07-16,57,81;" +
    "1987-07-17,53,71;" +
    "1987-07-18,53,73;" +
    "1987-07-19,49,82;" +
    "1987-07-20,54,74;" +
    "1987-07-21,56,74;" +
    "1987-07-22,50,81;" +
    "1987-07-23,53,79;" +
    "1987-07-24,55,80;" +
    "1987-07-25,53,78;" +
    "1987-07-26,50,80;" +
    "1987-07-27,51,81;" +
    "1987-07-28,51,82;" +
    "1987-07-29,54,77;" +
    "1987-07-30,51,84;" +
    "1987-07-31,51,96;" +
    "1987-08-01,59,102;" +
    "1987-08-02,61,103;" +
    "1987-08-03,60,99;" +
    "1987-08-04,60,96;" +
    "1987-08-05,56,100;" +
    "1987-08-06,56,91;" +
    "1987-08-07,55,84;" +
    "1987-08-08,55,89;" +
    "1987-08-09,57,84;" +
    "1987-08-10,58,78;" +
    "1987-08-11,53,82;" +
    "1987-08-12,53,82;" +
    "1987-08-13,55,70;" +
    "1987-08-14,57,77;" +
    "1987-08-15,49,84;" +
    "1987-08-16,53,91;" +
    "1987-08-17,56,91;" +
    "1987-08-18,56,82;" +
    "1987-08-19,58,77;" +
    "1987-08-20,55,77;" +
    "1987-08-21,53,79;" +
    "1987-08-22,53,77;" +
    "1987-08-23,52,81;" +
    "1987-08-24,55,90;" +
    "1987-08-25,56,92;" +
    "1987-08-26,56,84;" +
    "1987-08-27,57,90;" +
    "1987-08-28,55,100;" +
    "1987-08-29,60,102;" +
    "1987-08-30,61,97;" +
    "1987-08-31,58,90;" +
    "1987-09-01,60,97;" +
    "1987-09-02,63,100;" +
    "1987-09-03,62,92;" +
    "1987-09-04,56,81;" +
    "1987-09-05,52,78;" +
    "1987-09-06,56,75;" +
    "1987-09-07,57,82;" +
    "1987-09-08,52,92;" +
    "1987-09-09,58,87;" +
    "1987-09-10,57,76;" +
    "1987-09-11,55,75;" +
    "1987-09-12,56,73;" +
    "1987-09-13,58,72;" +
    "1987-09-14,57,74;" +
    "1987-09-15,51,86;" +
    "1987-09-16,52,89;" +
    "1987-09-17,52,90;" +
    "1987-09-18,52,87;" +
    "1987-09-19,79,91;" +
    "1987-09-20,51,96;" +
    "1987-09-21,54,100;" +
    "1987-09-22,53,86;" +
    "1987-09-23,55,80;" +
    "1987-09-24,52,80;" +
    "1987-09-25,54,78;" +
    "1987-09-26,50,87;" +
    "1987-09-27,50,93;" +
    "1987-09-28,52,91;" +
    "1987-09-29,56,97;" +
    "1987-09-30,60,97;" +
    "1987-10-01,56,86;" +
    "1987-10-02,53,94;" +
    "1987-10-03,56,99;" +
    "1987-10-04,57,96;" +
    "1987-10-05,58,98;" +
    "1987-10-06,58,98;" +
    "1987-10-07,57,85;" +
    "1987-10-08,56,73;" +
    "1987-10-09,54,69;" +
    "1987-10-10,53,79;" +
    "1987-10-11,49,82;" +
    "1987-10-12,56,72;" +
    "1987-10-13,55,72;" +
    "1987-10-14,51,81;" +
    "1987-10-15,49,81;" +
    "1987-10-16,48,79;" +
    "1987-10-17,46,83;" +
    "1987-10-18,47,88;" +
    "1987-10-19,49,77;" +
    "1987-10-20,52,68;" +
    "1987-10-21,52,77;" +
    "1987-10-22,58,69;" +
    "1987-10-23,54,71;" +
    "1987-10-24,55,75;" +
    "1987-10-25,50,79;" +
    "1987-10-26,51,77;" +
    "1987-10-27,60,75;" +
    "1987-10-28,59,67;" +
    "1987-10-29,57,68;" +
    "1987-10-30,48,68;" +
    "1987-10-31,51,69;" +
    "1987-11-01,53,64;" +
    "1987-11-02,49,61;" +
    "1987-11-03,43,68;" +
    "1987-11-04,43,72;" +
    "1987-11-05,48,64;" +
    "1987-11-06,53,65;" +
    "1987-11-07,43,69;" +
    "1987-11-08,42,73;" +
    "1987-11-09,52,68;" +
    "1987-11-10,46,66;" +
    "1987-11-11,42,68;" +
    "1987-11-12,43,70;" +
    "1987-11-13,56,63;" +
    "1987-11-14,44,63;" +
    "1987-11-15,35,64;" +
    "1987-11-16,50,62;" +
    "1987-11-17,51,59;" +
    "1987-11-18,51,60;" +
    "1987-11-19,46,64;" +
    "1987-11-20,45,59;" +
    "1987-11-21,45,55;" +
    "1987-11-22,36,62;" +
    "1987-11-23,37,63;" +
    "1987-11-24,38,62;" +
    "1987-11-25,38,58;" +
    "1987-11-26,44,58;" +
    "1987-11-27,31,59;" +
    "1987-11-28,40,61;" +
    "1987-11-29,41,58;" +
    "1987-11-30,43,55;" +
    "1987-12-01,52,64;" +
    "1987-12-02,56,67;" +
    "1987-12-03,48,67;" +
    "1987-12-04,48,58;" +
    "1987-12-05,45,60;" +
    "1987-12-06,53,60;" +
    "1987-12-07,43,59;" +
    "1987-12-08,46,55;" +
    "1987-12-09,50,65;" +
    "1987-12-10,53,64;" +
    "1987-12-11,44,57;" +
    "1987-12-12,44,52;" +
    "1987-12-13,39,49;" +
    "1987-12-14,36,50;" +
    "1987-12-15,34,48;" +
    "1987-12-16,44,53;" +
    "1987-12-17,41,59;" +
    "1987-12-18,33,56;" +
    "1987-12-19,39,59;" +
    "1987-12-20,30,54;" +
    "1987-12-21,35,49;" +
    "1987-12-22,43,54;" +
    "1987-12-23,35,50;" +
    "1987-12-24,34,47;" +
    "1987-12-25,33,50;" +
    "1987-12-26,27,49;" +
    "1987-12-27,32,56;" +
    "1987-12-28,41,51;" +
    "1987-12-29,38,47;" +
    "1987-12-30,33,50;" +
    "1987-12-31,32,46;"