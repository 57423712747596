export const data = "2005-01-01,39,53;" +
    "2005-01-01,42,55;" +
    "2005-01-02,37,47;" +
    "2005-01-02,38,46;" +
    "2005-01-03,38,50;" +
    "2005-01-03,41,49;" +
    "2005-01-04,34,52;" +
    "2005-01-04,38,53;" +
    "2005-01-05,33,51;" +
    "2005-01-05,34,50;" +
    "2005-01-06,43,52;" +
    "2005-01-06,43,53;" +
    "2005-01-07,44,57;" +
    "2005-01-07,44,58;" +
    "2005-01-08,45,53;" +
    "2005-01-08,45,53;" +
    "2005-01-09,37,52;" +
    "2005-01-09,41,52;" +
    "2005-01-10,39,58;" +
    "2005-01-10,41,59;" +
    "2005-01-11,35,51;" +
    "2005-01-11,43,54;" +
    "2005-01-12,31,54;" +
    "2005-01-12,31,56;" +
    "2005-01-13,31,42;" +
    "2005-01-13,32,45;" +
    "2005-01-14,34,45;" +
    "2005-01-14,34,45;" +
    "2005-01-15,37,45;" +
    "2005-01-15,37,45;" +
    "2005-01-16,39,59;" +
    "2005-01-16,38,57;" +
    "2005-01-17,37,54;" +
    "2005-01-17,39,56;" +
    "2005-01-18,41,47;" +
    "2005-01-18,41,48;" +
    "2005-01-19,37,47;" +
    "2005-01-19,40,48;" +
    "2005-01-20,38,54;" +
    "2005-01-20,39,53;" +
    "2005-01-21,33,57;" +
    "2005-01-21,34,56;" +
    "2005-01-22,38,47;" +
    "2005-01-22,38,47;" +
    "2005-01-23,37,49;" +
    "2005-01-23,40,48;" +
    "2005-01-24,34,50;" +
    "2005-01-24,36,50;" +
    "2005-01-25,40,53;" +
    "2005-01-25,41,54;" +
    "2005-01-26,44,59;" +
    "2005-01-26,49,58;" +
    "2005-01-27,36,57;" +
    "2005-01-27,40,57;" +
    "2005-01-28,40,54;" +
    "2005-01-28,47,55;" +
    "2005-01-29,36,57;" +
    "2005-01-29,38,58;" +
    "2005-01-30,30,60;" +
    "2005-01-30,33,60;" +
    "2005-01-31,32,61;" +
    "2005-01-31,35,62;" +
    "2005-02-01,36,68;" +
    "2005-02-01,41,67;" +
    "2005-02-02,37,66;" +
    "2005-02-02,44,67;" +
    "2005-02-03,36,64;" +
    "2005-02-03,37,65;" +
    "2005-02-04,33,63;" +
    "2005-02-04,37,62;" +
    "2005-02-05,42,60;" +
    "2005-02-05,43,63;" +
    "2005-02-06,38,58;" +
    "2005-02-06,40,61;" +
    "2005-02-07,40,56;" +
    "2005-02-07,48,56;" +
    "2005-02-08,37,54;" +
    "2005-02-08,41,54;" +
    "2005-02-09,39,60;" +
    "2005-02-09,40,59;" +
    "2005-02-10,35,64;" +
    "2005-02-10,39,63;" +
    "2005-02-11,40,59;" +
    "2005-02-11,43,58;" +
    "2005-02-12,43,65;" +
    "2005-02-12,45,68;" +
    "2005-02-13,43,57;" +
    "2005-02-13,45,58;" +
    "2005-02-14,50,58;" +
    "2005-02-14,50,57;" +
    "2005-02-15,51,54;" +
    "2005-02-15,50,54;" +
    "2005-02-16,50,61;" +
    "2005-02-16,50,61;" +
    "2005-02-17,52,58;" +
    "2005-02-17,51,57;" +
    "2005-02-18,46,64;" +
    "2005-02-18,49,64;" +
    "2005-02-19,45,57;" +
    "2005-02-19,49,58;" +
    "2005-02-20,45,61;" +
    "2005-02-20,47,61;" +
    "2005-02-21,47,64;" +
    "2005-02-21,51,66;" +
    "2005-02-22,40,64;" +
    "2005-02-22,41,64;" +
    "2005-02-23,40,59;" +
    "2005-02-23,42,61;" +
    "2005-02-24,50,54;" +
    "2005-02-24,49,54;" +
    "2005-02-25,46,57;" +
    "2005-02-25,50,59;" +
    "2005-02-26,45,61;" +
    "2005-02-26,47,63;" +
    "2005-02-27,47,62;" +
    "2005-02-27,48,62;" +
    "2005-02-28,42,63;" +
    "2005-02-28,44,65;" +
    "2005-03-01,40,63;" +
    "2005-03-01,42,65;" +
    "2005-03-02,40,59;" +
    "2005-03-02,46,59;" +
    "2005-03-03,37,63;" +
    "2005-03-03,39,64;" +
    "2005-03-04,44,57;" +
    "2005-03-04,51,58;" +
    "2005-03-05,40,69;" +
    "2005-03-05,43,72;" +
    "2005-03-06,40,71;" +
    "2005-03-06,42,72;" +
    "2005-03-07,42,71;" +
    "2005-03-07,44,73;" +
    "2005-03-08,43,74;" +
    "2005-03-08,46,74;" +
    "2005-03-09,44,77;" +
    "2005-03-09,48,76;" +
    "2005-03-10,47,78;" +
    "2005-03-10,50,78;" +
    "2005-03-11,48,79;" +
    "2005-03-11,53,79;" +
    "2005-03-12,45,66;" +
    "2005-03-12,49,72;" +
    "2005-03-13,50,68;" +
    "2005-03-13,53,70;" +
    "2005-03-14,43,70;" +
    "2005-03-14,46,70;" +
    "2005-03-15,36,74;" +
    "2005-03-15,41,76;" +
    "2005-03-16,36,71;" +
    "2005-03-16,39,73;" +
    "2005-03-17,45,65;" +
    "2005-03-17,47,67;" +
    "2005-03-18,49,59;" +
    "2005-03-18,51,60;" +
    "2005-03-19,53,62;" +
    "2005-03-19,53,63;" +
    "2005-03-20,48,60;" +
    "2005-03-20,49,60;" +
    "2005-03-21,43,62;" +
    "2005-03-21,45,62;" +
    "2005-03-22,48,58;" +
    "2005-03-22,48,59;" +
    "2005-03-23,48,57;" +
    "2005-03-23,47,56;" +
    "2005-03-24,41,60;" +
    "2005-03-24,43,62;" +
    "2005-03-25,36,62;" +
    "2005-03-25,39,63;" +
    "2005-03-26,37,66;" +
    "2005-03-26,40,66;" +
    "2005-03-27,42,68;" +
    "2005-03-27,45,68;" +
    "2005-03-28,46,61;" +
    "2005-03-28,48,61;" +
    "2005-03-29,41,61;" +
    "2005-03-29,47,63;" +
    "2005-03-30,36,67;" +
    "2005-03-30,39,68;" +
    "2005-03-31,37,69;" +
    "2005-03-31,40,70;" +
    "2005-04-01,41,75;" +
    "2005-04-01,43,77;" +
    "2005-04-02,42,65;" +
    "2005-04-02,46,67;" +
    "2005-04-03,47,63;" +
    "2005-04-03,49,63;" +
    "2005-04-04,41,63;" +
    "2005-04-04,42,62;" +
    "2005-04-05,38,67;" +
    "2005-04-05,40,67;" +
    "2005-04-06,41,76;" +
    "2005-04-06,44,78;" +
    "2005-04-07,44,60;" +
    "2005-04-07,50,62;" +
    "2005-04-08,43,59;" +
    "2005-04-08,44,59;" +
    "2005-04-09,44,66;" +
    "2005-04-09,47,67;" +
    "2005-04-10,38,71;" +
    "2005-04-10,42,72;" +
    "2005-04-11,46,65;" +
    "2005-04-11,49,67;" +
    "2005-04-12,44,62;" +
    "2005-04-12,44,64;" +
    "2005-04-13,37,61;" +
    "2005-04-13,41,62;" +
    "2005-04-14,36,67;" +
    "2005-04-14,38,68;" +
    "2005-04-15,37,74;" +
    "2005-04-15,40,75;" +
    "2005-04-16,46,74;" +
    "2005-04-16,46,76;" +
    "2005-04-17,47,65;" +
    "2005-04-17,51,67;" +
    "2005-04-18,38,67;" +
    "2005-04-18,42,69;" +
    "2005-04-19,43,70;" +
    "2005-04-19,44,72;" +
    "2005-04-20,41,68;" +
    "2005-04-20,42,70;" +
    "2005-04-21,43,74;" +
    "2005-04-21,46,76;" +
    "2005-04-22,43,77;" +
    "2005-04-22,47,77;" +
    "2005-04-23,47,63;" +
    "2005-04-23,51,63;" +
    "2005-04-24,44,63;" +
    "2005-04-24,48,64;" +
    "2005-04-25,48,70;" +
    "2005-04-25,48,73;" +
    "2005-04-26,42,73;" +
    "2005-04-26,44,75;" +
    "2005-04-27,47,68;" +
    "2005-04-27,47,70;" +
    "2005-04-28,45,64;" +
    "2005-04-28,51,67;" +
    "2005-04-29,44,69;" +
    "2005-04-29,45,72;" +
    "2005-04-30,48,69;" +
    "2005-04-30,49,71;" +
    "2005-05-01,50,71;" +
    "2005-05-01,50,73;" +
    "2005-05-02,47,71;" +
    "2005-05-02,49,74;" +
    "2005-05-03,49,71;" +
    "2005-05-03,49,74;" +
    "2005-05-04,54,69;" +
    "2005-05-04,55,70;" +
    "2005-05-05,53,67;" +
    "2005-05-05,53,68;" +
    "2005-05-06,53,68;" +
    "2005-05-06,55,69;" +
    "2005-05-07,49,69;" +
    "2005-05-07,50,71;" +
    "2005-05-08,54,63;" +
    "2005-05-08,53,64;" +
    "2005-05-09,48,62;" +
    "2005-05-09,51,63;" +
    "2005-05-10,42,67;" +
    "2005-05-10,46,70;" +
    "2005-05-11,43,72;" +
    "2005-05-11,45,74;" +
    "2005-05-12,47,79;" +
    "2005-05-12,47,80;" +
    "2005-05-13,51,79;" +
    "2005-05-13,51,82;" +
    "2005-05-14,52,77;" +
    "2005-05-14,53,80;" +
    "2005-05-15,59,76;" +
    "2005-05-15,59,78;" +
    "2005-05-16,52,68;" +
    "2005-05-16,57,69;" +
    "2005-05-17,51,65;" +
    "2005-05-17,51,67;" +
    "2005-05-18,55,71;" +
    "2005-05-18,55,71;" +
    "2005-05-19,52,68;" +
    "2005-05-19,59,68;" +
    "2005-05-20,48,73;" +
    "2005-05-20,50,74;" +
    "2005-05-21,45,82;" +
    "2005-05-21,48,83;" +
    "2005-05-22,49,82;" +
    "2005-05-22,52,84;" +
    "2005-05-23,48,84;" +
    "2005-05-23,48,85;" +
    "2005-05-24,49,89;" +
    "2005-05-24,52,91;" +
    "2005-05-25,52,90;" +
    "2005-05-25,56,92;" +
    "2005-05-26,54,82;" +
    "2005-05-26,54,85;" +
    "2005-05-27,53,74;" +
    "2005-05-27,54,80;" +
    "2005-05-28,53,68;" +
    "2005-05-28,53,69;" +
    "2005-05-29,54,70;" +
    "2005-05-29,53,72;" +
    "2005-05-30,48,81;" +
    "2005-05-30,50,83;" +
    "2005-05-31,55,84;" +
    "2005-05-31,55,86;" +
    "2005-06-01,51,84;" +
    "2005-06-01,55,87;" +
    "2005-06-02,49,79;" +
    "2005-06-02,52,82;" +
    "2005-06-03,50,80;" +
    "2005-06-03,52,83;" +
    "2005-06-04,51,78;" +
    "2005-06-04,51,81;" +
    "2005-06-05,51,70;" +
    "2005-06-05,51,70;" +
    "2005-06-06,48,68;" +
    "2005-06-06,49,69;" +
    "2005-06-07,45,69;" +
    "2005-06-07,47,72;" +
    "2005-06-08,55,67;" +
    "2005-06-08,55,70;" +
    "2005-06-09,56,73;" +
    "2005-06-09,59,75;" +
    "2005-06-10,56,78;" +
    "2005-06-10,56,80;" +
    "2005-06-11,53,78;" +
    "2005-06-11,53,80;" +
    "2005-06-12,47,87;" +
    "2005-06-12,50,89;" +
    "2005-06-13,53,93;" +
    "2005-06-13,56,94;" +
    "2005-06-14,54,83;" +
    "2005-06-14,57,88;" +
    "2005-06-15,49,85;" +
    "2005-06-15,49,85;" +
    "2005-06-16,53,68;" +
    "2005-06-16,54,70;" +
    "2005-06-17,53,70;" +
    "2005-06-17,53,69;" +
    "2005-06-18,52,72;" +
    "2005-06-18,51,73;" +
    "2005-06-19,53,74;" +
    "2005-06-19,54,76;" +
    "2005-06-20,52,77;" +
    "2005-06-20,53,78;" +
    "2005-06-21,54,74;" +
    "2005-06-21,54,76;" +
    "2005-06-22,51,81;" +
    "2005-06-22,52,83;" +
    "2005-06-23,52,77;" +
    "2005-06-23,51,79;" +
    "2005-06-24,55,77;" +
    "2005-06-24,58,79;" +
    "2005-06-25,55,74;" +
    "2005-06-25,56,76;" +
    "2005-06-26,55,78;" +
    "2005-06-26,57,80;" +
    "2005-06-27,54,71;" +
    "2005-06-27,55,73;" +
    "2005-06-28,58,78;" +
    "2005-06-28,57,82;" +
    "2005-06-29,51,93;" +
    "2005-06-29,55,95;" +
    "2005-06-30,60,96;" +
    "2005-06-30,62,97;" +
    "2005-07-01,61,94;" +
    "2005-07-01,62,97;" +
    "2005-07-02,56,87;" +
    "2005-07-02,57,91;" +
    "2005-07-03,55,87;" +
    "2005-07-03,55,90;" +
    "2005-07-04,56,89;" +
    "2005-07-04,57,91;" +
    "2005-07-05,55,78;" +
    "2005-07-05,54,85;" +
    "2005-07-06,54,86;" +
    "2005-07-06,55,86;" +
    "2005-07-07,56,81;" +
    "2005-07-07,55,82;" +
    "2005-07-08,54,77;" +
    "2005-07-08,55,78;" +
    "2005-07-09,59,74;" +
    "2005-07-09,59,76;" +
    "2005-07-10,58,81;" +
    "2005-07-10,58,83;" +
    "2005-07-11,59,91;" +
    "2005-07-11,59,93;" +
    "2005-07-12,60,99;" +
    "2005-07-12,61,100;" +
    "2005-07-13,64,99;" +
    "2005-07-13,64,101;" +
    "2005-07-14,64,100;" +
    "2005-07-14,65,102;" +
    "2005-07-15,64,102;" +
    "2005-07-15,65,103;" +
    "2005-07-16,63,102;" +
    "2005-07-16,64,104;" +
    "2005-07-17,66,99;" +
    "2005-07-17,67,101;" +
    "2005-07-18,60,91;" +
    "2005-07-18,63,95;" +
    "2005-07-19,59,92;" +
    "2005-07-19,59,95;" +
    "2005-07-20,58,95;" +
    "2005-07-20,59,97;" +
    "2005-07-21,59,78;" +
    "2005-07-21,62,92;" +
    "2005-07-22,58,85;" +
    "2005-07-22,59,87;" +
    "2005-07-23,54,104;" +
    "2005-07-23,56,104;" +
    "2005-07-24,61,100;" +
    "2005-07-24,62,101;" +
    "2005-07-25,58,95;" +
    "2005-07-25,61,99;" +
    "2005-07-26,58,97;" +
    "2005-07-26,59,100;" +
    "2005-07-27,56,93;" +
    "2005-07-27,57,96;" +
    "2005-07-28,58,89;" +
    "2005-07-28,59,92;" +
    "2005-07-29,58,90;" +
    "2005-07-29,58,92;" +
    "2005-07-30,59,94;" +
    "2005-07-30,60,96;" +
    "2005-07-31,58,95;" +
    "2005-07-31,60,96;" +
    "2005-08-01,59,99;" +
    "2005-08-01,59,100;" +
    "2005-08-02,57,92;" +
    "2005-08-02,59,96;" +
    "2005-08-03,60,92;" +
    "2005-08-03,60,94;" +
    "2005-08-04,58,96;" +
    "2005-08-04,59,98;" +
    "2005-08-05,62,101;" +
    "2005-08-05,62,101;" +
    "2005-08-06,63,100;" +
    "2005-08-06,62,101;" +
    "2005-08-07,64,97;" +
    "2005-08-07,64,98;" +
    "2005-08-08,60,90;" +
    "2005-08-08,60,92;" +
    "2005-08-09,59,94;" +
    "2005-08-09,59,95;" +
    "2005-08-10,57,93;" +
    "2005-08-10,58,95;" +
    "2005-08-11,57,97;" +
    "2005-08-11,57,98;" +
    "2005-08-12,56,97;" +
    "2005-08-12,60,99;" +
    "2005-08-13,56,75;" +
    "2005-08-13,56,87;" +
    "2005-08-14,57,79;" +
    "2005-08-14,56,81;" +
    "2005-08-15,56,80;" +
    "2005-08-15,56,81;" +
    "2005-08-16,58,91;" +
    "2005-08-16,59,92;" +
    "2005-08-17,57,87;" +
    "2005-08-17,57,89;" +
    "2005-08-18,57,76;" +
    "2005-08-18,57,78;" +
    "2005-08-19,55,78;" +
    "2005-08-19,56,80;" +
    "2005-08-20,55,84;" +
    "2005-08-20,55,85;" +
    "2005-08-21,55,83;" +
    "2005-08-21,55,86;" +
    "2005-08-22,52,93;" +
    "2005-08-22,52,93;" +
    "2005-08-23,56,96;" +
    "2005-08-23,57,97;" +
    "2005-08-24,56,86;" +
    "2005-08-24,55,88;" +
    "2005-08-25,54,89;" +
    "2005-08-25,54,90;" +
    "2005-08-26,55,95;" +
    "2005-08-26,56,96;" +
    "2005-08-27,60,97;" +
    "2005-08-27,60,99;" +
    "2005-08-28,58,96;" +
    "2005-08-28,60,98;" +
    "2005-08-29,56,85;" +
    "2005-08-29,58,91;" +
    "2005-08-30,55,93;" +
    "2005-08-30,56,92;" +
    "2005-08-31,58,98;" +
    "2005-08-31,57,98;" +
    "2005-09-01,53,90;" +
    "2005-09-01,57,93;" +
    "2005-09-02,56,85;" +
    "2005-09-02,56,86;" +
    "2005-09-03,55,82;" +
    "2005-09-03,54,84;" +
    "2005-09-04,51,78;" +
    "2005-09-04,53,80;" +
    "2005-09-05,51,88;" +
    "2005-09-05,51,88;" +
    "2005-09-06,53,83;" +
    "2005-09-06,54,84;" +
    "2005-09-07,52,78;" +
    "2005-09-07,52,82;" +
    "2005-09-08,56,76;" +
    "2005-09-08,55,79;" +
    "2005-09-09,55,71;" +
    "2005-09-09,60,75;" +
    "2005-09-10,52,76;" +
    "2005-09-10,50,79;" +
    "2005-09-11,50,73;" +
    "2005-09-11,48,76;" +
    "2005-09-12,47,73;" +
    "2005-09-12,47,77;" +
    "2005-09-13,51,71;" +
    "2005-09-13,50,76;" +
    "2005-09-14,53,70;" +
    "2005-09-14,52,74;" +
    "2005-09-15,53,79;" +
    "2005-09-15,54,82;" +
    "2005-09-16,53,74;" +
    "2005-09-16,52,78;" +
    "2005-09-17,52,73;" +
    "2005-09-17,54,77;" +
    "2005-09-18,46,83;" +
    "2005-09-18,48,85;" +
    "2005-09-19,49,90;" +
    "2005-09-19,50,88;" +
    "2005-09-20,54,82;" +
    "2005-09-20,55,87;" +
    "2005-09-21,55,86;" +
    "2005-09-21,55,88;" +
    "2005-09-22,51,89;" +
    "2005-09-22,52,92;" +
    "2005-09-23,50,71;" +
    "2005-09-23,51,81;" +
    "2005-09-24,50,78;" +
    "2005-09-24,50,81;" +
    "2005-09-25,58,81;" +
    "2005-09-25,46,82;" +
    "2005-09-26,57,83;" +
    "2005-09-26,54,88;" +
    "2005-09-27,54,83;" +
    "2005-09-27,53,85;" +
    "2005-09-28,51,94;" +
    "2005-09-28,53,91;" +
    "2005-09-29,53,94;" +
    "2005-09-29,54,92;" +
    "2005-09-30,54,94;" +
    "2005-09-30,54,92;" +
    "2005-10-01,58,78;" +
    "2005-10-01,57,86;" +
    "2005-10-02,51,73;" +
    "2005-10-02,51,77;" +
    "2005-10-03,50,70;" +
    "2005-10-03,50,73;" +
    "2005-10-04,48,74;" +
    "2005-10-04,45,74;" +
    "2005-10-05,45,81;" +
    "2005-10-05,46,80;" +
    "2005-10-06,49,88;" +
    "2005-10-06,48,90;" +
    "2005-10-07,51,82;" +
    "2005-10-07,51,86;" +
    "2005-10-08,50,70;" +
    "2005-10-08,52,72;" +
    "2005-10-09,49,80;" +
    "2005-10-09,44,82;" +
    "2005-10-10,46,84;" +
    "2005-10-10,46,86;" +
    "2005-10-11,48,76;" +
    "2005-10-11,49,79;" +
    "2005-10-12,46,84;" +
    "2005-10-12,46,86;" +
    "2005-10-13,48,87;" +
    "2005-10-13,49,87;" +
    "2005-10-14,49,88;" +
    "2005-10-14,51,89;" +
    "2005-10-15,49,68;" +
    "2005-10-15,50,70;" +
    "2005-10-16,49,83;" +
    "2005-10-16,50,80;" +
    "2005-10-17,49,87;" +
    "2005-10-17,49,87;" +
    "2005-10-18,56,74;" +
    "2005-10-18,53,78;" +
    "2005-10-19,52,72;" +
    "2005-10-19,59,74;" +
    "2005-10-20,49,74;" +
    "2005-10-20,50,76;" +
    "2005-10-21,48,79;" +
    "2005-10-21,48,78;" +
    "2005-10-22,46,80;" +
    "2005-10-22,48,81;" +
    "2005-10-23,46,76;" +
    "2005-10-23,46,78;" +
    "2005-10-24,50,67;" +
    "2005-10-24,41,71;" +
    "2005-10-25,52,68;" +
    "2005-10-25,53,70;" +
    "2005-10-26,50,64;" +
    "2005-10-26,50,65;" +
    "2005-10-27,51,66;" +
    "2005-10-27,51,68;" +
    "2005-10-28,53,72;" +
    "2005-10-28,53,74;" +
    "2005-10-29,45,67;" +
    "2005-10-29,52,70;" +
    "2005-10-30,41,70;" +
    "2005-10-30,41,70;" +
    "2005-10-31,41,75;" +
    "2005-10-31,42,73;" +
    "2005-11-01,44,77;" +
    "2005-11-01,45,80;" +
    "2005-11-02,49,63;" +
    "2005-11-02,54,64;" +
    "2005-11-03,43,65;" +
    "2005-11-03,43,66;" +
    "2005-11-04,45,66;" +
    "2005-11-04,54,68;" +
    "2005-11-05,38,67;" +
    "2005-11-05,39,69;" +
    "2005-11-06,46,68;" +
    "2005-11-06,47,71;" +
    "2005-11-07,53,68;" +
    "2005-11-07,53,67;" +
    "2005-11-08,48,62;" +
    "2005-11-08,47,63;" +
    "2005-11-09,50,74;" +
    "2005-11-09,54,76;" +
    "2005-11-10,46,68;" +
    "2005-11-10,47,66;" +
    "2005-11-11,48,61;" +
    "2005-11-11,49,63;" +
    "2005-11-12,44,67;" +
    "2005-11-12,49,65;" +
    "2005-11-13,40,71;" +
    "2005-11-13,41,72;" +
    "2005-11-14,45,77;" +
    "2005-11-14,46,76;" +
    "2005-11-15,47,72;" +
    "2005-11-15,58,71;" +
    "2005-11-16,42,75;" +
    "2005-11-16,43,72;" +
    "2005-11-17,41,76;" +
    "2005-11-17,42,74;" +
    "2005-11-18,40,74;" +
    "2005-11-18,42,72;" +
    "2005-11-19,36,76;" +
    "2005-11-19,40,73;" +
    "2005-11-20,35,71;" +
    "2005-11-20,37,68;" +
    "2005-11-21,36,72;" +
    "2005-11-21,37,70;" +
    "2005-11-22,37,73;" +
    "2005-11-22,38,71;" +
    "2005-11-23,39,75;" +
    "2005-11-23,39,76;" +
    "2005-11-24,37,69;" +
    "2005-11-24,40,70;" +
    "2005-11-25,47,61;" +
    "2005-11-25,52,63;" +
    "2005-11-26,33,59;" +
    "2005-11-26,37,60;" +
    "2005-11-27,27,58;" +
    "2005-11-27,30,57;" +
    "2005-11-28,33,61;" +
    "2005-11-28,35,59;" +
    "2005-11-29,46,60;" +
    "2005-11-29,44,60;" +
    "2005-11-30,45,58;" +
    "2005-11-30,45,58;" +
    "2005-12-01,49,62;" +
    "2005-12-01,50,64;" +
    "2005-12-02,40,56;" +
    "2005-12-02,40,60;" +
    "2005-12-03,35,55;" +
    "2005-12-03,35,54;" +
    "2005-12-04,33,57;" +
    "2005-12-04,40,55;" +
    "2005-12-05,31,56;" +
    "2005-12-05,32,55;" +
    "2005-12-06,28,60;" +
    "2005-12-06,30,60;" +
    "2005-12-07,33,55;" +
    "2005-12-07,34,55;" +
    "2005-12-08,44,57;" +
    "2005-12-08,45,56;" +
    "2005-12-09,38,65;" +
    "2005-12-09,40,64;" +
    "2005-12-10,35,65;" +
    "2005-12-10,38,63;" +
    "2005-12-11,38,61;" +
    "2005-12-11,39,61;" +
    "2005-12-12,41,57;" +
    "2005-12-12,40,58;" +
    "2005-12-13,37,57;" +
    "2005-12-13,40,60;" +
    "2005-12-14,34,58;" +
    "2005-12-14,34,56;" +
    "2005-12-15,33,57;" +
    "2005-12-15,34,56;" +
    "2005-12-16,29,47;" +
    "2005-12-16,30,46;" +
    "2005-12-17,34,47;" +
    "2005-12-17,35,44;" +
    "2005-12-18,47,61;" +
    "2005-12-18,42,61;" +
    "2005-12-19,45,61;" +
    "2005-12-19,53,60;" +
    "2005-12-20,45,67;" +
    "2005-12-20,45,66;" +
    "2005-12-21,52,68;" +
    "2005-12-21,52,67;" +
    "2005-12-22,57,65;" +
    "2005-12-22,59,65;" +
    "2005-12-23,54,61;" +
    "2005-12-23,54,61;" +
    "2005-12-24,52,64;" +
    "2005-12-24,54,62;" +
    "2005-12-25,52,60;" +
    "2005-12-25,51,59;" +
    "2005-12-26,50,59;" +
    "2005-12-26,51,59;" +
    "2005-12-27,50,63;" +
    "2005-12-27,50,62;" +
    "2005-12-28,43,61;" +
    "2005-12-28,52,61;" +
    "2005-12-29,41,54;" +
    "2005-12-29,42,54;" +
    "2005-12-30,49,62;" +
    "2005-12-30,48,62;" +
    "2005-12-31,43,62;" +
    "2005-12-31,52,62;"