export const data = "1996-01-01,49,64;" +
    "1996-01-01,54,67;" +
    "1996-01-02,49,67;" +
    "1996-01-02,50,68;" +
    "1996-01-03,40,63;" +
    "1996-01-03,47,63;" +
    "1996-01-04,45,60;" +
    "1996-01-04,45,58;" +
    "1996-01-05,42,62;" +
    "1996-01-05,43,64;" +
    "1996-01-06,40,60;" +
    "1996-01-06,46,62;" +
    "1996-01-07,42,57;" +
    "1996-01-07,40,58;" +
    "1996-01-08,39,55;" +
    "1996-01-08,40,52;" +
    "1996-01-09,40,61;" +
    "1996-01-09,40,59;" +
    "1996-01-10,46,62;" +
    "1996-01-10,47,64;" +
    "1996-01-11,42,54;" +
    "1996-01-11,44,54;" +
    "1996-01-12,34,57;" +
    "1996-01-12,40,62;" +
    "1996-01-13,39,58;" +
    "1996-01-13,39,58;" +
    "1996-01-14,34,50;" +
    "1996-01-14,38,49;" +
    "1996-01-15,41,56;" +
    "1996-01-15,43,56;" +
    "1996-01-16,52,60;" +
    "1996-01-16,49,59;" +
    "1996-01-17,40,56;" +
    "1996-01-17,42,57;" +
    "1996-01-18,45,57;" +
    "1996-01-18,46,56;" +
    "1996-01-19,47,59;" +
    "1996-01-19,46,58;" +
    "1996-01-20,38,60;" +
    "1996-01-20,43,58;" +
    "1996-01-21,43,54;" +
    "1996-01-21,43,54;" +
    "1996-01-22,38,53;" +
    "1996-01-22,38,53;" +
    "1996-01-23,33,54;" +
    "1996-01-23,36,52;" +
    "1996-01-24,47,54;" +
    "1996-01-24,48,55;" +
    "1996-01-25,40,56;" +
    "1996-01-25,40,55;" +
    "1996-01-26,33,52;" +
    "1996-01-26,36,53;" +
    "1996-01-27,46,57;" +
    "1996-01-27,47,56;" +
    "1996-01-28,38,56;" +
    "1996-01-28,41,55;" +
    "1996-01-29,37,53;" +
    "1996-01-29,41,53;" +
    "1996-01-30,44,49;" +
    "1996-01-30,44,53;" +
    "1996-01-31,46,54;" +
    "1996-01-31,47,54;" +
    "1996-02-01,47,63;" +
    "1996-02-01,49,62;" +
    "1996-02-02,49,58;" +
    "1996-02-02,51,58;" +
    "1996-02-03,49,61;" +
    "1996-02-03,50,60;" +
    "1996-02-04,56,64;" +
    "1996-02-04,58,63;" +
    "1996-02-05,54,71;" +
    "1996-02-05,54,70;" +
    "1996-02-06,47,69;" +
    "1996-02-06,48,68;" +
    "1996-02-07,46,66;" +
    "1996-02-07,47,68;" +
    "1996-02-08,44,59;" +
    "1996-02-08,46,61;" +
    "1996-02-09,49,65;" +
    "1996-02-09,51,65;" +
    "1996-02-10,50,66;" +
    "1996-02-10,52,69;" +
    "1996-02-11,46,72;" +
    "1996-02-11,51,74;" +
    "1996-02-12,48,73;" +
    "1996-02-12,56,78;" +
    "1996-02-13,45,73;" +
    "1996-02-13,53,75;" +
    "1996-02-14,44,74;" +
    "1996-02-14,51,74;" +
    "1996-02-15,46,61;" +
    "1996-02-15,48,57;" +
    "1996-02-16,54,70;" +
    "1996-02-16,55,69;" +
    "1996-02-17,54,70;" +
    "1996-02-17,57,68;" +
    "1996-02-18,47,67;" +
    "1996-02-18,52,65;" +
    "1996-02-19,52,60;" +
    "1996-02-19,51,59;" +
    "1996-02-20,52,63;" +
    "1996-02-20,52,62;" +
    "1996-02-21,46,55;" +
    "1996-02-21,41,55;" +
    "1996-02-22,37,55;" +
    "1996-02-22,38,54;" +
    "1996-02-23,32,57;" +
    "1996-02-23,35,57;" +
    "1996-02-24,39,52;" +
    "1996-02-24,34,49;" +
    "1996-02-25,32,47;" +
    "1996-02-25,33,47;" +
    "1996-02-26,29,54;" +
    "1996-02-26,31,53;" +
    "1996-02-27,40,52;" +
    "1996-02-27,40,50;" +
    "1996-02-28,38,57;" +
    "1996-02-28,41,54;" +
    "1996-02-29,46,58;" +
    "1996-02-29,46,59;" +
    "1996-03-01,43,61;" +
    "1996-03-01,45,62;" +
    "1996-03-02,40,66;" +
    "1996-03-02,46,67;" +
    "1996-03-03,47,61;" +
    "1996-03-03,50,62;" +
    "1996-03-04,46,59;" +
    "1996-03-04,48,57;" +
    "1996-03-05,46,57;" +
    "1996-03-05,45,54;" +
    "1996-03-06,35,59;" +
    "1996-03-06,52,60;" +
    "1996-03-07,48,64;" +
    "1996-03-07,48,65;" +
    "1996-03-08,44,68;" +
    "1996-03-08,49,68;" +
    "1996-03-09,46,74;" +
    "1996-03-09,50,74;" +
    "1996-03-10,50,66;" +
    "1996-03-10,51,64;" +
    "1996-03-11,50,66;" +
    "1996-03-11,53,63;" +
    "1996-03-12,48,58;" +
    "1996-03-13,39,62;" +
    "1996-03-13,56,63;" +
    "1996-03-14,42,71;" +
    "1996-03-14,48,71;" +
    "1996-03-15,42,73;" +
    "1996-03-15,46,72;" +
    "1996-03-16,46,73;" +
    "1996-03-16,49,74;" +
    "1996-03-17,47,78;" +
    "1996-03-17,55,78;" +
    "1996-03-18,48,82;" +
    "1996-03-18,55,82;" +
    "1996-03-19,48,78;" +
    "1996-03-19,49,76;" +
    "1996-03-20,42,78;" +
    "1996-03-20,47,77;" +
    "1996-03-21,43,68;" +
    "1996-03-21,47,66;" +
    "1996-03-22,49,58;" +
    "1996-03-22,44,56;" +
    "1996-03-23,36,63;" +
    "1996-03-23,39,62;" +
    "1996-03-24,36,65;" +
    "1996-03-24,41,62;" +
    "1996-03-25,46,59;" +
    "1996-03-25,45,56;" +
    "1996-03-26,35,69;" +
    "1996-03-26,41,67;" +
    "1996-03-27,46,71;" +
    "1996-03-27,47,68;" +
    "1996-03-28,47,59;" +
    "1996-03-28,43,56;" +
    "1996-03-29,38,64;" +
    "1996-03-29,41,61;" +
    "1996-03-30,40,68;" +
    "1996-03-30,41,70;" +
    "1996-03-31,43,78;" +
    "1996-03-31,45,74;" +
    "1996-04-01,55,65;" +
    "1996-04-01,50,62;" +
    "1996-04-02,47,65;" +
    "1996-04-02,47,63;" +
    "1996-04-03,42,68;" +
    "1996-04-03,44,66;" +
    "1996-04-04,42,76;" +
    "1996-04-04,44,74;" +
    "1996-04-05,44,79;" +
    "1996-04-05,49,79;" +
    "1996-04-06,47,80;" +
    "1996-04-06,52,83;" +
    "1996-04-07,51,84;" +
    "1996-04-07,54,85;" +
    "1996-04-08,51,81;" +
    "1996-04-08,50,63;" +
    "1996-04-09,52,64;" +
    "1996-04-09,48,63;" +
    "1996-04-10,46,65;" +
    "1996-04-10,45,61;" +
    "1996-04-11,47,67;" +
    "1996-04-11,47,63;" +
    "1996-04-12,47,66;" +
    "1996-04-12,47,63;" +
    "1996-04-13,41,71;" +
    "1996-04-13,43,70;" +
    "1996-04-14,43,74;" +
    "1996-04-14,46,75;" +
    "1996-04-15,52,72;" +
    "1996-04-15,54,69;" +
    "1996-04-16,50,65;" +
    "1996-04-16,48,62;" +
    "1996-04-17,44,64;" +
    "1996-04-17,44,61;" +
    "1996-04-18,42,64;" +
    "1996-04-18,43,62;" +
    "1996-04-19,40,63;" +
    "1996-04-19,42,61;" +
    "1996-04-20,40,61;" +
    "1996-04-20,43,59;" +
    "1996-04-21,41,69;" +
    "1996-04-21,44,67;" +
    "1996-04-22,50,75;" +
    "1996-04-22,52,74;" +
    "1996-04-23,48,76;" +
    "1996-04-23,51,73;" +
    "1996-04-24,54,73;" +
    "1996-04-24,53,69;" +
    "1996-04-25,48,83;" +
    "1996-04-25,50,80;" +
    "1996-04-26,53,86;" +
    "1996-04-26,58,84;" +
    "1996-04-27,52,81;" +
    "1996-04-27,57,78;" +
    "1996-04-28,60,81;" +
    "1996-04-28,64,79;" +
    "1996-04-29,51,89;" +
    "1996-04-29,59,86;" +
    "1996-04-30,53,94;" +
    "1996-04-30,59,92;" +
    "1996-05-01,55,92;" +
    "1996-05-01,61,90;" +
    "1996-05-02,53,87;" +
    "1996-05-02,57,85;" +
    "1996-05-03,51,79;" +
    "1996-05-03,48,74;" +
    "1996-05-04,42,80;" +
    "1996-05-04,45,76;" +
    "1996-05-05,45,76;" +
    "1996-05-05,48,72;" +
    "1996-05-06,45,73;" +
    "1996-05-06,48,70;" +
    "1996-05-07,47,74;" +
    "1996-05-07,47,69;" +
    "1996-05-08,46,73;" +
    "1996-05-08,47,70;" +
    "1996-05-09,44,76;" +
    "1996-05-09,46,73;" +
    "1996-05-10,43,84;" +
    "1996-05-10,45,83;" +
    "1996-05-11,52,93;" +
    "1996-05-11,56,92;" +
    "1996-05-12,57,95;" +
    "1996-05-12,61,93;" +
    "1996-05-13,55,86;" +
    "1996-05-13,57,84;" +
    "1996-05-14,58,80;" +
    "1996-05-14,58,76;" +
    "1996-05-15,60,74;" +
    "1996-05-15,60,69;" +
    "1996-05-16,57,69;" +
    "1996-05-16,59,67;" +
    "1996-05-17,60,69;" +
    "1996-05-17,58,67;" +
    "1996-05-18,55,70;" +
    "1996-05-18,53,67;" +
    "1996-05-19,49,73;" +
    "1996-05-19,49,69;" +
    "1996-05-20,48,74;" +
    "1996-05-20,49,72;" +
    "1996-05-21,51,69;" +
    "1996-05-21,50,63;" +
    "1996-05-22,52,71;" +
    "1996-05-22,51,69;" +
    "1996-05-23,46,74;" +
    "1996-05-23,49,71;" +
    "1996-05-24,56,81;" +
    "1996-05-24,60,79;" +
    "1996-05-25,53,86;" +
    "1996-05-25,58,84;" +
    "1996-05-26,54,85;" +
    "1996-05-26,52,67;" +
    "1996-05-27,51,78;" +
    "1996-05-27,51,76;" +
    "1996-05-28,49,75;" +
    "1996-05-28,48,67;" +
    "1996-05-29,48,72;" +
    "1996-05-29,47,68;" +
    "1996-05-30,46,75;" +
    "1996-05-30,47,72;" +
    "1996-05-31,48,82;" +
    "1996-05-31,47,79;" +
    "1996-06-01,52,93;" +
    "1996-06-01,54,91;" +
    "1996-06-02,58,101;" +
    "1996-06-02,63,100;" +
    "1996-06-03,61,100;" +
    "1996-06-03,67,97;" +
    "1996-06-04,60,94;" +
    "1996-06-04,61,90;" +
    "1996-06-05,58,100;" +
    "1996-06-05,62,96;" +
    "1996-06-06,58,100;" +
    "1996-06-06,56,98;" +
    "1996-06-07,59,92;" +
    "1996-06-07,57,82;" +
    "1996-06-08,53,92;" +
    "1996-06-08,54,89;" +
    "1996-06-09,56,88;" +
    "1996-06-09,56,85;" +
    "1996-06-10,52,79;" +
    "1996-06-10,52,78;" +
    "1996-06-11,51,87;" +
    "1996-06-11,51,84;" +
    "1996-06-12,54,82;" +
    "1996-06-12,51,75;" +
    "1996-06-13,51,77;" +
    "1996-06-13,51,73;" +
    "1996-06-14,50,83;" +
    "1996-06-14,51,80;" +
    "1996-06-15,51,78;" +
    "1996-06-15,50,74;" +
    "1996-06-16,50,70;" +
    "1996-06-16,50,63;" +
    "1996-06-17,48,80;" +
    "1996-06-17,48,77;" +
    "1996-06-18,51,87;" +
    "1996-06-18,54,84;" +
    "1996-06-19,54,90;" +
    "1996-06-19,52,88;" +
    "1996-06-20,51,78;" +
    "1996-06-20,51,67;" +
    "1996-06-21,51,74;" +
    "1996-06-21,49,70;" +
    "1996-06-22,50,86;" +
    "1996-06-22,50,83;" +
    "1996-06-23,53,88;" +
    "1996-06-23,53,86;" +
    "1996-06-24,54,76;" +
    "1996-06-24,53,64;" +
    "1996-06-25,53,72;" +
    "1996-06-25,53,69;" +
    "1996-06-26,55,68;" +
    "1996-06-26,54,65;" +
    "1996-06-27,55,75;" +
    "1996-06-27,53,72;" +
    "1996-06-28,50,85;" +
    "1996-06-28,50,83;" +
    "1996-06-29,54,94;" +
    "1996-06-29,58,94;" +
    "1996-06-30,60,102;" +
    "1996-06-30,68,100;" +
    "1996-07-01,64,103;" +
    "1996-07-01,70,102;" +
    "1996-07-02,64,97;" +
    "1996-07-02,58,89;" +
    "1996-07-03,55,90;" +
    "1996-07-03,55,85;" +
    "1996-07-04,54,86;" +
    "1996-07-04,55,83;" +
    "1996-07-05,51,94;" +
    "1996-07-05,53,92;" +
    "1996-07-06,58,97;" +
    "1996-07-06,61,94;" +
    "1996-07-07,59,89;" +
    "1996-07-07,56,84;" +
    "1996-07-08,56,89;" +
    "1996-07-08,55,86;" +
    "1996-07-09,57,94;" +
    "1996-07-09,56,92;" +
    "1996-07-10,59,92;" +
    "1996-07-10,58,67;" +
    "1996-07-11,59,91;" +
    "1996-07-11,57,88;" +
    "1996-07-12,61,89;" +
    "1996-07-12,60,87;" +
    "1996-07-13,59,92;" +
    "1996-07-13,57,88;" +
    "1996-07-14,57,88;" +
    "1996-07-14,55,84;" +
    "1996-07-15,56,82;" +
    "1996-07-15,54,79;" +
    "1996-07-16,57,80;" +
    "1996-07-16,54,76;" +
    "1996-07-17,55,76;" +
    "1996-07-17,54,71;" +
    "1996-07-18,52,86;" +
    "1996-07-18,52,83;" +
    "1996-07-19,51,94;" +
    "1996-07-19,53,92;" +
    "1996-07-20,57,100;" +
    "1996-07-20,63,99;" +
    "1996-07-21,60,101;" +
    "1996-07-21,66,99;" +
    "1996-07-22,62,95;" +
    "1996-07-22,61,89;" +
    "1996-07-23,62,94;" +
    "1996-07-23,57,91;" +
    "1996-07-24,55,95;" +
    "1996-07-24,54,92;" +
    "1996-07-25,59,100;" +
    "1996-07-25,59,97;" +
    "1996-07-26,58,93;" +
    "1996-07-26,56,91;" +
    "1996-07-27,62,92;" +
    "1996-07-27,61,92;" +
    "1996-07-28,67,101;" +
    "1996-07-28,69,100;" +
    "1996-07-29,65,103;" +
    "1996-07-29,67,101;" +
    "1996-07-30,67,101;" +
    "1996-07-30,66,99;" +
    "1996-07-31,65,102;" +
    "1996-07-31,63,100;" +
    "1996-08-01,56,95;" +
    "1996-08-01,54,81;" +
    "1996-08-02,54,96;" +
    "1996-08-02,54,92;" +
    "1996-08-03,54,83;" +
    "1996-08-03,53,78;" +
    "1996-08-04,55,92;" +
    "1996-08-04,54,89;" +
    "1996-08-05,56,83;" +
    "1996-08-05,54,73;" +
    "1996-08-06,53,83;" +
    "1996-08-06,52,80;" +
    "1996-08-07,53,90;" +
    "1996-08-07,52,89;" +
    "1996-08-08,55,99;" +
    "1996-08-08,54,97;" +
    "1996-08-09,61,106;" +
    "1996-08-09,63,106;" +
    "1996-08-10,64,106;" +
    "1996-08-10,68,105;" +
    "1996-08-11,69,102;" +
    "1996-08-11,69,101;" +
    "1996-08-12,67,105;" +
    "1996-08-12,67,104;" +
    "1996-08-13,69,104;" +
    "1996-08-13,71,103;" +
    "1996-08-14,65,99;" +
    "1996-08-14,64,96;" +
    "1996-08-15,59,101;" +
    "1996-08-15,61,99;" +
    "1996-08-16,58,99;" +
    "1996-08-16,59,97;" +
    "1996-08-17,55,85;" +
    "1996-08-17,54,81;" +
    "1996-08-18,53,87;" +
    "1996-08-18,52,85;" +
    "1996-08-19,54,78;" +
    "1996-08-19,52,70;" +
    "1996-08-20,51,84;" +
    "1996-08-20,52,83;" +
    "1996-08-21,54,92;" +
    "1996-08-21,54,91;" +
    "1996-08-22,55,97;" +
    "1996-08-22,54,95;" +
    "1996-08-23,58,99;" +
    "1996-08-23,59,97;" +
    "1996-08-24,56,91;" +
    "1996-08-24,54,83;" +
    "1996-08-25,56,78;" +
    "1996-08-25,54,76;" +
    "1996-08-26,55,75;" +
    "1996-08-26,54,73;" +
    "1996-08-27,57,81;" +
    "1996-08-27,55,79;" +
    "1996-08-28,53,96;" +
    "1996-08-28,53,94;" +
    "1996-08-29,58,101;" +
    "1996-08-29,63,100;" +
    "1996-08-30,59,100;" +
    "1996-08-30,62,97;" +
    "1996-08-31,56,94;" +
    "1996-08-31,57,91;" +
    "1996-09-01,54,90;" +
    "1996-09-01,55,86;" +
    "1996-09-02,53,86;" +
    "1996-09-02,52,83;" +
    "1996-09-03,54,80;" +
    "1996-09-03,54,77;" +
    "1996-09-04,53,78;" +
    "1996-09-04,53,74;" +
    "1996-09-05,49,83;" +
    "1996-09-05,51,83;" +
    "1996-09-06,50,90;" +
    "1996-09-06,54,88;" +
    "1996-09-07,52,91;" +
    "1996-09-07,58,92;" +
    "1996-09-08,54,94;" +
    "1996-09-08,61,96;" +
    "1996-09-09,56,96;" +
    "1996-09-09,62,95;" +
    "1996-09-10,56,94;" +
    "1996-09-10,54,91;" +
    "1996-09-11,55,80;" +
    "1996-09-11,53,69;" +
    "1996-09-12,52,78;" +
    "1996-09-12,51,76;" +
    "1996-09-13,58,72;" +
    "1996-09-13,55,69;" +
    "1996-09-14,53,79;" +
    "1996-09-14,53,76;" +
    "1996-09-15,59,74;" +
    "1996-09-15,54,71;" +
    "1996-09-16,47,79;" +
    "1996-09-16,49,77;" +
    "1996-09-17,49,83;" +
    "1996-09-17,53,81;" +
    "1996-09-18,53,83;" +
    "1996-09-18,57,83;" +
    "1996-09-19,52,89;" +
    "1996-09-19,58,89;" +
    "1996-09-20,53,92;" +
    "1996-09-20,56,90;" +
    "1996-09-21,55,90;" +
    "1996-09-21,57,88;" +
    "1996-09-22,51,85;" +
    "1996-09-22,52,84;" +
    "1996-09-23,52,80;" +
    "1996-09-23,51,77;" +
    "1996-09-24,50,79;" +
    "1996-09-24,50,78;" +
    "1996-09-25,49,86;" +
    "1996-09-25,53,85;" +
    "1996-09-26,51,82;" +
    "1996-09-26,50,81;" +
    "1996-09-27,52,85;" +
    "1996-09-27,52,82;" +
    "1996-09-28,51,89;" +
    "1996-09-28,52,88;" +
    "1996-09-29,53,89;" +
    "1996-09-29,52,86;" +
    "1996-09-30,54,79;" +
    "1996-09-30,52,65;" +
    "1996-10-01,57,73;" +
    "1996-10-01,54,71;" +
    "1996-10-02,51,79;" +
    "1996-10-02,52,78;" +
    "1996-10-03,54,87;" +
    "1996-10-03,52,87;" +
    "1996-10-04,54,88;" +
    "1996-10-04,58,86;" +
    "1996-10-05,53,93;" +
    "1996-10-05,56,92;" +
    "1996-10-06,54,93;" +
    "1996-10-06,60,95;" +
    "1996-10-07,57,96;" +
    "1996-10-07,63,97;" +
    "1996-10-08,58,101;" +
    "1996-10-08,68,101;" +
    "1996-10-09,59,94;" +
    "1996-10-09,55,86;" +
    "1996-10-10,56,75;" +
    "1996-10-10,54,72;" +
    "1996-10-11,52,83;" +
    "1996-10-11,53,82;" +
    "1996-10-12,55,74;" +
    "1996-10-12,56,73;" +
    "1996-10-13,53,76;" +
    "1996-10-13,54,76;" +
    "1996-10-14,48,81;" +
    "1996-10-14,50,79;" +
    "1996-10-15,47,79;" +
    "1996-10-15,48,79;" +
    "1996-10-16,43,72;" +
    "1996-10-16,45,70;" +
    "1996-10-17,42,78;" +
    "1996-10-17,46,77;" +
    "1996-10-18,44,67;" +
    "1996-10-18,47,64;" +
    "1996-10-19,43,64;" +
    "1996-10-19,45,61;" +
    "1996-10-20,37,67;" +
    "1996-10-20,41,65;" +
    "1996-10-21,45,68;" +
    "1996-10-21,49,69;" +
    "1996-10-22,38,72;" +
    "1996-10-22,42,71;" +
    "1996-10-23,47,74;" +
    "1996-10-23,50,73;" +
    "1996-10-24,49,66;" +
    "1996-10-24,50,61;" +
    "1996-10-25,46,59;" +
    "1996-10-25,45,57;" +
    "1996-10-26,44,66;" +
    "1996-10-26,44,64;" +
    "1996-10-27,47,71;" +
    "1996-10-27,50,69;" +
    "1996-10-28,40,70;" +
    "1996-10-28,45,69;" +
    "1996-10-29,49,58;" +
    "1996-10-29,48,56;" +
    "1996-10-30,48,52;" +
    "1996-10-30,46,52;" +
    "1996-10-31,43,60;" +
    "1996-10-31,45,61;" +
    "1996-11-01,39,67;" +
    "1996-11-01,43,69;" +
    "1996-11-02,40,67;" +
    "1996-11-02,44,66;" +
    "1996-11-03,48,60;" +
    "1996-11-03,49,56;" +
    "1996-11-04,50,58;" +
    "1996-11-04,45,57;" +
    "1996-11-05,40,64;" +
    "1996-11-05,41,61;" +
    "1996-11-06,37,65;" +
    "1996-11-06,43,66;" +
    "1996-11-07,38,66;" +
    "1996-11-07,45,67;" +
    "1996-11-08,42,68;" +
    "1996-11-08,49,70;" +
    "1996-11-09,46,69;" +
    "1996-11-09,53,71;" +
    "1996-11-10,44,72;" +
    "1996-11-10,52,73;" +
    "1996-11-11,42,76;" +
    "1996-11-11,49,75;" +
    "1996-11-12,46,75;" +
    "1996-11-12,50,75;" +
    "1996-11-13,48,70;" +
    "1996-11-13,49,69;" +
    "1996-11-14,43,60;" +
    "1996-11-14,45,59;" +
    "1996-11-15,44,62;" +
    "1996-11-15,44,59;" +
    "1996-11-16,42,57;" +
    "1996-11-16,45,55;" +
    "1996-11-17,48,62;" +
    "1996-11-17,28,56;" +
    "1996-11-18,59,66;" +
    "1996-11-18,55,61;" +
    "1996-11-19,50,62;" +
    "1996-11-19,54,59;" +
    "1996-11-20,45,66;" +
    "1996-11-20,50,67;" +
    "1996-11-21,53,62;" +
    "1996-11-21,53,62;" +
    "1996-11-22,53,62;" +
    "1996-11-22,52,59;" +
    "1996-11-23,52,58;" +
    "1996-11-23,50,57;" +
    "1996-11-24,45,61;" +
    "1996-11-24,47,62;" +
    "1996-11-25,46,67;" +
    "1996-11-25,49,65;" +
    "1996-11-26,46,65;" +
    "1996-11-26,53,65;" +
    "1996-11-27,47,65;" +
    "1996-11-27,48,66;" +
    "1996-11-28,41,62;" +
    "1996-11-28,44,60;" +
    "1996-11-29,38,62;" +
    "1996-11-29,40,58;" +
    "1996-11-30,35,62;" +
    "1996-11-30,38,61;" +
    "1996-12-01,50,59;" +
    "1996-12-01,42,57;" +
    "1996-12-02,33,55;" +
    "1996-12-02,35,55;" +
    "1996-12-03,31,57;" +
    "1996-12-03,34,57;" +
    "1996-12-04,38,58;" +
    "1996-12-04,41,60;" +
    "1996-12-05,54,58;" +
    "1996-12-05,50,56;" +
    "1996-12-06,50,57;" +
    "1996-12-06,48,56;" +
    "1996-12-07,43,66;" +
    "1996-12-07,45,64;" +
    "1996-12-08,49,62;" +
    "1996-12-08,54,62;" +
    "1996-12-09,53,62;" +
    "1996-12-09,54,62;" +
    "1996-12-10,52,62;" +
    "1996-12-10,50,59;" +
    "1996-12-11,48,66;" +
    "1996-12-11,53,63;" +
    "1996-12-12,56,66;" +
    "1996-12-12,56,65;" +
    "1996-12-13,51,61;" +
    "1996-12-13,51,56;" +
    "1996-12-14,46,59;" +
    "1996-12-14,49,56;" +
    "1996-12-15,40,59;" +
    "1996-12-15,43,50;" +
    "1996-12-16,37,62;" +
    "1996-12-16,42,54;" +
    "1996-12-17,39,59;" +
    "1996-12-17,51,53;" +
    "1996-12-18,40,53;" +
    "1996-12-18,51,51;" +
    "1996-12-19,32,56;" +
    "1996-12-19,37,57;" +
    "1996-12-20,29,47;" +
    "1996-12-20,31,47;" +
    "1996-12-21,46,52;" +
    "1996-12-21,47,49;" +
    "1996-12-22,41,53;" +
    "1996-12-23,39,60;" +
    "1996-12-23,41,60;" +
    "1996-12-24,36,53;" +
    "1996-12-24,0,54;" +
    "1996-12-25,34,56;" +
    "1996-12-25,38,58;" +
    "1996-12-26,44,61;" +
    "1996-12-26,44,57;" +
    "1996-12-27,47,61;" +
    "1996-12-27,48,61;" +
    "1996-12-28,45,60;" +
    "1996-12-28,50,58;" +
    "1996-12-29,45,63;" +
    "1996-12-29,55,60;" +
    "1996-12-30,55,65;" +
    "1996-12-30,57,63;" +
    "1996-12-31,59,69;" +
    "1996-12-31,61,67;"