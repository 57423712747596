export const data = "2003-01-01,34,54;" +
    "2003-01-01,35,54;" +
    "2003-01-02,39,56;" +
    "2003-01-02,45,56;" +
    "2003-01-03,37,59;" +
    "2003-01-03,40,58;" +
    "2003-01-04,41,64;" +
    "2003-01-04,42,66;" +
    "2003-01-05,40,64;" +
    "2003-01-05,45,65;" +
    "2003-01-06,39,64;" +
    "2003-01-06,44,65;" +
    "2003-01-07,35,63;" +
    "2003-01-07,35,62;" +
    "2003-01-08,35,55;" +
    "2003-01-08,38,55;" +
    "2003-01-09,42,52;" +
    "2003-01-09,43,52;" +
    "2003-01-10,46,62;" +
    "2003-01-10,46,63;" +
    "2003-01-11,42,58;" +
    "2003-01-11,44,58;" +
    "2003-01-12,45,63;" +
    "2003-01-12,46,66;" +
    "2003-01-13,45,66;" +
    "2003-01-13,47,67;" +
    "2003-01-14,47,62;" +
    "2003-01-14,51,64;" +
    "2003-01-15,43,59;" +
    "2003-01-15,49,59;" +
    "2003-01-16,38,58;" +
    "2003-01-16,46,58;" +
    "2003-01-17,35,61;" +
    "2003-01-17,37,61;" +
    "2003-01-18,38,59;" +
    "2003-01-18,38,61;" +
    "2003-01-19,38,48;" +
    "2003-01-19,37,51;" +
    "2003-01-20,39,48;" +
    "2003-01-20,38,47;" +
    "2003-01-21,44,57;" +
    "2003-01-21,44,56;" +
    "2003-01-22,47,62;" +
    "2003-01-22,47,62;" +
    "2003-01-23,47,62;" +
    "2003-01-23,49,62;" +
    "2003-01-24,50,62;" +
    "2003-01-24,49,64;" +
    "2003-01-25,47,61;" +
    "2003-01-25,48,62;" +
    "2003-01-26,48,58;" +
    "2003-01-26,48,58;" +
    "2003-01-27,47,65;" +
    "2003-01-27,49,67;" +
    "2003-01-28,43,63;" +
    "2003-01-28,46,64;" +
    "2003-01-29,38,64;" +
    "2003-01-29,41,64;" +
    "2003-01-30,41,62;" +
    "2003-01-30,43,62;" +
    "2003-01-31,41,64;" +
    "2003-01-31,44,64;" +
    "2003-02-01,38,61;" +
    "2003-02-01,48,63;" +
    "2003-02-02,36,61;" +
    "2003-02-02,40,61;" +
    "2003-02-03,33,59;" +
    "2003-02-03,41,60;" +
    "2003-02-04,30,62;" +
    "2003-02-04,34,62;" +
    "2003-02-05,32,57;" +
    "2003-02-05,40,57;" +
    "2003-02-06,27,55;" +
    "2003-02-06,30,55;" +
    "2003-02-07,30,58;" +
    "2003-02-07,39,57;" +
    "2003-02-08,23,59;" +
    "2003-02-08,28,60;" +
    "2003-02-09,24,59;" +
    "2003-02-09,29,59;" +
    "2003-02-10,28,58;" +
    "2003-02-10,32,58;" +
    "2003-02-11,37,58;" +
    "2003-02-11,40,58;" +
    "2003-02-12,40,55;" +
    "2003-02-12,42,54;" +
    "2003-02-13,48,63;" +
    "2003-02-13,48,64;" +
    "2003-02-14,50,57;" +
    "2003-02-14,50,58;" +
    "2003-02-15,48,60;" +
    "2003-02-15,48,60;" +
    "2003-02-16,40,60;" +
    "2003-02-16,44,62;" +
    "2003-02-17,36,58;" +
    "2003-02-17,39,60;" +
    "2003-02-18,33,61;" +
    "2003-02-18,35,63;" +
    "2003-02-19,41,57;" +
    "2003-02-19,44,58;" +
    "2003-02-20,33,64;" +
    "2003-02-20,36,64;" +
    "2003-02-21,34,66;" +
    "2003-02-21,38,66;" +
    "2003-02-22,35,66;" +
    "2003-02-22,38,66;" +
    "2003-02-23,33,64;" +
    "2003-02-23,36,66;" +
    "2003-02-24,46,57;" +
    "2003-02-24,49,58;" +
    "2003-02-25,45,62;" +
    "2003-02-25,48,64;" +
    "2003-02-26,45,56;" +
    "2003-02-26,44,56;" +
    "2003-02-27,37,57;" +
    "2003-02-27,41,58;" +
    "2003-02-28,35,60;" +
    "2003-02-28,38,62;" +
    "2003-03-01,39,61;" +
    "2003-03-01,46,63;" +
    "2003-03-02,33,64;" +
    "2003-03-02,35,67;" +
    "2003-03-03,37,56;" +
    "2003-03-03,45,57;" +
    "2003-03-04,36,59;" +
    "2003-03-04,39,61;" +
    "2003-03-05,34,65;" +
    "2003-03-05,36,68;" +
    "2003-03-06,36,66;" +
    "2003-03-06,41,69;" +
    "2003-03-07,34,60;" +
    "2003-03-07,39,63;" +
    "2003-03-08,36,66;" +
    "2003-03-08,40,69;" +
    "2003-03-09,34,65;" +
    "2003-03-09,38,67;" +
    "2003-03-10,39,69;" +
    "2003-03-10,42,71;" +
    "2003-03-11,53,69;" +
    "2003-03-11,52,72;" +
    "2003-03-12,49,73;" +
    "2003-03-12,54,76;" +
    "2003-03-13,48,66;" +
    "2003-03-13,50,68;" +
    "2003-03-14,55,70;" +
    "2003-03-14,56,72;" +
    "2003-03-15,45,62;" +
    "2003-03-15,51,64;" +
    "2003-03-16,42,59;" +
    "2003-03-16,44,60;" +
    "2003-03-17,42,62;" +
    "2003-03-17,44,64;" +
    "2003-03-18,35,64;" +
    "2003-03-18,39,67;" +
    "2003-03-19,37,63;" +
    "2003-03-19,41,65;" +
    "2003-03-20,43,63;" +
    "2003-03-20,47,65;" +
    "2003-03-21,40,68;" +
    "2003-03-21,44,70;" +
    "2003-03-22,51,64;" +
    "2003-03-22,51,66;" +
    "2003-03-23,39,62;" +
    "2003-03-23,51,64;" +
    "2003-03-24,38,67;" +
    "2003-03-24,39,69;" +
    "2003-03-25,40,71;" +
    "2003-03-25,42,74;" +
    "2003-03-26,48,64;" +
    "2003-03-26,55,66;" +
    "2003-03-27,37,67;" +
    "2003-03-27,40,69;" +
    "2003-03-28,44,70;" +
    "2003-03-28,52,71;" +
    "2003-03-29,39,75;" +
    "2003-03-29,44,76;" +
    "2003-03-30,42,78;" +
    "2003-03-30,46,80;" +
    "2003-03-31,44,76;" +
    "2003-03-31,48,77;" +
    "2003-04-01,47,59;" +
    "2003-04-01,50,68;" +
    "2003-04-02,42,55;" +
    "2003-04-02,44,57;" +
    "2003-04-03,35,59;" +
    "2003-04-03,38,59;" +
    "2003-04-04,42,58;" +
    "2003-04-04,44,59;" +
    "2003-04-05,32,60;" +
    "2003-04-05,36,61;" +
    "2003-04-06,40,61;" +
    "2003-04-06,45,63;" +
    "2003-04-07,37,68;" +
    "2003-04-07,40,70;" +
    "2003-04-08,41,77;" +
    "2003-04-08,44,78;" +
    "2003-04-09,42,75;" +
    "2003-04-09,45,77;" +
    "2003-04-10,47,69;" +
    "2003-04-10,49,71;" +
    "2003-04-11,41,67;" +
    "2003-04-11,44,70;" +
    "2003-04-12,49,62;" +
    "2003-04-12,50,66;" +
    "2003-04-13,42,61;" +
    "2003-04-13,46,61;" +
    "2003-04-14,37,59;" +
    "2003-04-14,40,60;" +
    "2003-04-15,39,61;" +
    "2003-04-15,43,61;" +
    "2003-04-16,47,60;" +
    "2003-04-16,48,61;" +
    "2003-04-17,47,63;" +
    "2003-04-17,50,64;" +
    "2003-04-18,40,65;" +
    "2003-04-18,45,66;" +
    "2003-04-19,37,71;" +
    "2003-04-19,41,71;" +
    "2003-04-20,42,63;" +
    "2003-04-20,46,69;" +
    "2003-04-21,45,54;" +
    "2003-04-21,46,58;" +
    "2003-04-22,45,61;" +
    "2003-04-22,45,64;" +
    "2003-04-23,47,65;" +
    "2003-04-23,47,67;" +
    "2003-04-24,42,60;" +
    "2003-04-24,48,60;" +
    "2003-04-25,39,55;" +
    "2003-04-25,42,57;" +
    "2003-04-26,40,63;" +
    "2003-04-26,41,66;" +
    "2003-04-27,37,68;" +
    "2003-04-27,40,69;" +
    "2003-04-28,47,64;" +
    "2003-04-28,46,63;" +
    "2003-04-29,44,61;" +
    "2003-04-29,47,61;" +
    "2003-04-30,38,68;" +
    "2003-04-30,43,69;" +
    "2003-05-01,45,71;" +
    "2003-05-01,48,72;" +
    "2003-05-02,52,67;" +
    "2003-05-02,52,67;" +
    "2003-05-03,52,64;" +
    "2003-05-03,52,65;" +
    "2003-05-04,48,62;" +
    "2003-05-04,50,63;" +
    "2003-05-05,41,65;" +
    "2003-05-05,45,67;" +
    "2003-05-06,49,63;" +
    "2003-05-06,48,65;" +
    "2003-05-07,47,64;" +
    "2003-05-07,49,65;" +
    "2003-05-08,44,59;" +
    "2003-05-08,48,60;" +
    "2003-05-09,37,64;" +
    "2003-05-09,41,66;" +
    "2003-05-10,38,70;" +
    "2003-05-10,41,72;" +
    "2003-05-11,39,72;" +
    "2003-05-11,42,75;" +
    "2003-05-12,42,79;" +
    "2003-05-12,44,82;" +
    "2003-05-13,46,79;" +
    "2003-05-13,50,82;" +
    "2003-05-14,51,64;" +
    "2003-05-14,52,76;" +
    "2003-05-15,46,65;" +
    "2003-05-15,52,66;" +
    "2003-05-16,42,75;" +
    "2003-05-16,44,77;" +
    "2003-05-17,43,77;" +
    "2003-05-17,47,79;" +
    "2003-05-18,45,76;" +
    "2003-05-18,48,76;" +
    "2003-05-19,45,84;" +
    "2003-05-19,48,86;" +
    "2003-05-20,49,88;" +
    "2003-05-20,52,90;" +
    "2003-05-21,51,91;" +
    "2003-05-21,55,93;" +
    "2003-05-22,53,87;" +
    "2003-05-22,53,89;" +
    "2003-05-23,52,88;" +
    "2003-05-23,56,90;" +
    "2003-05-24,54,66;" +
    "2003-05-24,54,72;" +
    "2003-05-25,56,72;" +
    "2003-05-25,56,74;" +
    "2003-05-26,53,77;" +
    "2003-05-26,55,80;" +
    "2003-05-27,50,93;" +
    "2003-05-27,53,96;" +
    "2003-05-28,54,97;" +
    "2003-05-28,58,99;" +
    "2003-05-29,57,82;" +
    "2003-05-29,64,95;" +
    "2003-05-30,53,74;" +
    "2003-05-30,54,75;" +
    "2003-05-31,51,80;" +
    "2003-05-31,51,83;" +
    "2003-06-01,49,92;" +
    "2003-06-01,52,93;" +
    "2003-06-02,53,97;" +
    "2003-06-02,58,100;" +
    "2003-06-03,57,88;" +
    "2003-06-03,61,94;" +
    "2003-06-04,56,78;" +
    "2003-06-04,56,81;" +
    "2003-06-05,55,76;" +
    "2003-06-05,55,78;" +
    "2003-06-06,56,73;" +
    "2003-06-06,56,76;" +
    "2003-06-07,54,71;" +
    "2003-06-07,57,74;" +
    "2003-06-08,54,71;" +
    "2003-06-08,54,75;" +
    "2003-06-09,53,74;" +
    "2003-06-09,54,77;" +
    "2003-06-10,54,74;" +
    "2003-06-10,55,75;" +
    "2003-06-11,54,68;" +
    "2003-06-11,54,70;" +
    "2003-06-12,54,64;" +
    "2003-06-12,53,66;" +
    "2003-06-13,54,74;" +
    "2003-06-13,54,76;" +
    "2003-06-14,50,77;" +
    "2003-06-14,51,79;" +
    "2003-06-15,50,86;" +
    "2003-06-15,51,89;" +
    "2003-06-16,53,91;" +
    "2003-06-16,54,94;" +
    "2003-06-17,54,75;" +
    "2003-06-17,54,89;" +
    "2003-06-18,52,76;" +
    "2003-06-18,52,76;" +
    "2003-06-19,54,74;" +
    "2003-06-19,54,76;" +
    "2003-06-20,52,75;" +
    "2003-06-20,52,76;" +
    "2003-06-21,52,75;" +
    "2003-06-21,54,77;" +
    "2003-06-22,50,78;" +
    "2003-06-22,48,80;" +
    "2003-06-23,50,75;" +
    "2003-06-23,50,77;" +
    "2003-06-24,47,88;" +
    "2003-06-24,49,89;" +
    "2003-06-25,53,98;" +
    "2003-06-25,49,97;" +
    "2003-06-26,59,103;" +
    "2003-06-26,62,103;" +
    "2003-06-27,62,103;" +
    "2003-06-27,64,104;" +
    "2003-06-28,61,96;" +
    "2003-06-28,63,98;" +
    "2003-06-29,56,88;" +
    "2003-06-29,58,92;" +
    "2003-06-30,54,81;" +
    "2003-06-30,56,84;" +
    "2003-07-01,53,84;" +
    "2003-07-01,54,86;" +
    "2003-07-02,48,86;" +
    "2003-07-02,51,87;" +
    "2003-07-03,53,89;" +
    "2003-07-03,54,90;" +
    "2003-07-04,50,96;" +
    "2003-07-04,53,97;" +
    "2003-07-05,54,85;" +
    "2003-07-05,56,91;" +
    "2003-07-06,53,74;" +
    "2003-07-06,53,78;" +
    "2003-07-07,52,72;" +
    "2003-07-07,52,74;" +
    "2003-07-08,50,90;" +
    "2003-07-08,51,91;" +
    "2003-07-09,51,96;" +
    "2003-07-09,53,98;" +
    "2003-07-10,54,91;" +
    "2003-07-10,55,93;" +
    "2003-07-11,53,90;" +
    "2003-07-11,54,92;" +
    "2003-07-12,56,93;" +
    "2003-07-12,59,93;" +
    "2003-07-13,59,89;" +
    "2003-07-13,58,90;" +
    "2003-07-14,56,98;" +
    "2003-07-14,57,100;" +
    "2003-07-15,58,88;" +
    "2003-07-15,60,96;" +
    "2003-07-16,55,96;" +
    "2003-07-16,56,98;" +
    "2003-07-17,58,104;" +
    "2003-07-17,60,106;" +
    "2003-07-18,61,101;" +
    "2003-07-18,64,101;" +
    "2003-07-19,65,94;" +
    "2003-07-19,64,98;" +
    "2003-07-20,66,101;" +
    "2003-07-20,68,103;" +
    "2003-07-21,67,97;" +
    "2003-07-21,68,99;" +
    "2003-07-22,64,97;" +
    "2003-07-22,65,99;" +
    "2003-07-23,63,91;" +
    "2003-07-23,66,95;" +
    "2003-07-24,65,95;" +
    "2003-07-24,65,97;" +
    "2003-07-25,60,91;" +
    "2003-07-25,61,93;" +
    "2003-07-26,59,97;" +
    "2003-07-26,59,99;" +
    "2003-07-27,63,96;" +
    "2003-07-27,64,98;" +
    "2003-07-28,63,98;" +
    "2003-07-28,63,100;" +
    "2003-07-29,61,92;" +
    "2003-07-29,62,95;" +
    "2003-07-30,65,87;" +
    "2003-07-30,65,87;" +
    "2003-07-31,60,86;" +
    "2003-07-31,62,87;" +
    "2003-08-01,62,87;" +
    "2003-08-01,63,89;" +
    "2003-08-02,61,85;" +
    "2003-08-02,61,86;" +
    "2003-08-03,60,82;" +
    "2003-08-03,62,83;" +
    "2003-08-04,59,79;" +
    "2003-08-04,59,79;" +
    "2003-08-05,61,80;" +
    "2003-08-05,62,82;" +
    "2003-08-06,59,77;" +
    "2003-08-06,60,78;" +
    "2003-08-07,59,79;" +
    "2003-08-07,58,81;" +
    "2003-08-08,56,83;" +
    "2003-08-08,58,84;" +
    "2003-08-09,57,83;" +
    "2003-08-09,57,84;" +
    "2003-08-10,57,84;" +
    "2003-08-10,58,85;" +
    "2003-08-11,57,85;" +
    "2003-08-11,57,87;" +
    "2003-08-12,53,86;" +
    "2003-08-12,52,88;" +
    "2003-08-13,50,89;" +
    "2003-08-13,51,91;" +
    "2003-08-14,55,81;" +
    "2003-08-14,57,86;" +
    "2003-08-15,57,89;" +
    "2003-08-15,57,91;" +
    "2003-08-16,50,96;" +
    "2003-08-16,54,97;" +
    "2003-08-17,53,95;" +
    "2003-08-17,57,96;" +
    "2003-08-18,58,91;" +
    "2003-08-18,58,92;" +
    "2003-08-19,59,83;" +
    "2003-08-19,60,85;" +
    "2003-08-20,56,90;" +
    "2003-08-20,57,92;" +
    "2003-08-21,61,76;" +
    "2003-08-21,61,83;" +
    "2003-08-22,60,80;" +
    "2003-08-22,60,80;" +
    "2003-08-23,53,87;" +
    "2003-08-23,55,88;" +
    "2003-08-24,55,96;" +
    "2003-08-24,58,95;" +
    "2003-08-25,60,100;" +
    "2003-08-25,62,101;" +
    "2003-08-26,60,94;" +
    "2003-08-26,66,96;" +
    "2003-08-27,57,84;" +
    "2003-08-27,55,86;" +
    "2003-08-28,56,75;" +
    "2003-08-28,56,77;" +
    "2003-08-29,57,86;" +
    "2003-08-29,56,87;" +
    "2003-08-30,55,87;" +
    "2003-08-30,55,88;" +
    "2003-08-31,54,93;" +
    "2003-08-31,55,94;" +
    "2003-09-01,56,99;" +
    "2003-09-01,59,100;" +
    "2003-09-02,58,96;" +
    "2003-09-02,61,98;" +
    "2003-09-03,61,84;" +
    "2003-09-03,64,88;" +
    "2003-09-04,58,81;" +
    "2003-09-04,60,83;" +
    "2003-09-05,56,82;" +
    "2003-09-05,57,82;" +
    "2003-09-06,53,75;" +
    "2003-09-06,56,76;" +
    "2003-09-07,49,77;" +
    "2003-09-07,54,79;" +
    "2003-09-08,58,82;" +
    "2003-09-08,58,82;" +
    "2003-09-09,56,71;" +
    "2003-09-09,57,73;" +
    "2003-09-10,59,87;" +
    "2003-09-10,59,86;" +
    "2003-09-11,56,96;" +
    "2003-09-11,58,93;" +
    "2003-09-12,60,100;" +
    "2003-09-12,62,98;" +
    "2003-09-13,61,97;" +
    "2003-09-13,65,95;" +
    "2003-09-14,57,97;" +
    "2003-09-14,60,98;" +
    "2003-09-15,57,82;" +
    "2003-09-15,58,84;" +
    "2003-09-16,53,78;" +
    "2003-09-16,53,79;" +
    "2003-09-17,50,84;" +
    "2003-09-17,52,82;" +
    "2003-09-18,51,92;" +
    "2003-09-18,52,92;" +
    "2003-09-19,52,96;" +
    "2003-09-19,54,96;" +
    "2003-09-20,53,98;" +
    "2003-09-20,55,99;" +
    "2003-09-21,56,100;" +
    "2003-09-21,58,99;" +
    "2003-09-22,58,102;" +
    "2003-09-22,60,100;" +
    "2003-09-23,58,86;" +
    "2003-09-23,61,96;" +
    "2003-09-24,57,73;" +
    "2003-09-24,57,80;" +
    "2003-09-25,56,73;" +
    "2003-09-25,57,75;" +
    "2003-09-26,56,81;" +
    "2003-09-26,57,82;" +
    "2003-09-27,57,79;" +
    "2003-09-27,57,80;" +
    "2003-09-28,58,71;" +
    "2003-09-28,57,74;" +
    "2003-09-29,55,79;" +
    "2003-09-29,57,80;" +
    "2003-09-30,52,85;" +
    "2003-09-30,52,86;" +
    "2003-10-01,54,73;" +
    "2003-10-01,55,75;" +
    "2003-10-02,55,73;" +
    "2003-10-02,54,73;" +
    "2003-10-03,56,79;" +
    "2003-10-03,55,80;" +
    "2003-10-04,56,76;" +
    "2003-10-04,56,77;" +
    "2003-10-05,53,83;" +
    "2003-10-05,53,85;" +
    "2003-10-06,53,89;" +
    "2003-10-06,54,90;" +
    "2003-10-07,52,83;" +
    "2003-10-07,52,84;" +
    "2003-10-08,52,80;" +
    "2003-10-08,53,79;" +
    "2003-10-09,49,74;" +
    "2003-10-09,55,75;" +
    "2003-10-10,45,77;" +
    "2003-10-10,46,79;" +
    "2003-10-11,42,82;" +
    "2003-10-11,44,83;" +
    "2003-10-12,49,86;" +
    "2003-10-12,50,83;" +
    "2003-10-13,49,83;" +
    "2003-10-13,53,84;" +
    "2003-10-14,46,85;" +
    "2003-10-14,48,86;" +
    "2003-10-15,42,77;" +
    "2003-10-15,45,78;" +
    "2003-10-16,46,84;" +
    "2003-10-16,48,84;" +
    "2003-10-17,49,84;" +
    "2003-10-17,51,82;" +
    "2003-10-18,47,83;" +
    "2003-10-18,50,84;" +
    "2003-10-19,48,84;" +
    "2003-10-19,51,85;" +
    "2003-10-20,50,88;" +
    "2003-10-20,51,87;" +
    "2003-10-21,53,93;" +
    "2003-10-21,53,94;" +
    "2003-10-22,50,74;" +
    "2003-10-22,51,84;" +
    "2003-10-23,49,87;" +
    "2003-10-23,50,87;" +
    "2003-10-24,50,87;" +
    "2003-10-24,56,85;" +
    "2003-10-25,48,90;" +
    "2003-10-25,52,86;" +
    "2003-10-26,50,92;" +
    "2003-10-26,54,89;" +
    "2003-10-27,49,92;" +
    "2003-10-27,52,91;" +
    "2003-10-28,49,92;" +
    "2003-10-28,52,91;" +
    "2003-10-29,52,76;" +
    "2003-10-29,54,75;" +
    "2003-10-30,46,62;" +
    "2003-10-30,47,63;" +
    "2003-10-31,39,56;" +
    "2003-10-31,46,54;" +
    "2003-11-01,34,60;" +
    "2003-11-01,36,61;" +
    "2003-11-02,33,59;" +
    "2003-11-02,35,61;" +
    "2003-11-03,39,59;" +
    "2003-11-03,45,61;" +
    "2003-11-04,33,61;" +
    "2003-11-04,36,62;" +
    "2003-11-05,40,58;" +
    "2003-11-05,43,57;" +
    "2003-11-06,48,63;" +
    "2003-11-06,47,62;" +
    "2003-11-07,48,65;" +
    "2003-11-07,49,67;" +
    "2003-11-08,47,66;" +
    "2003-11-08,47,66;" +
    "2003-11-09,47,64;" +
    "2003-11-09,51,64;" +
    "2003-11-10,44,66;" +
    "2003-11-10,45,64;" +
    "2003-11-11,39,67;" +
    "2003-11-11,41,66;" +
    "2003-11-12,41,71;" +
    "2003-11-12,42,71;" +
    "2003-11-13,41,67;" +
    "2003-11-13,43,67;" +
    "2003-11-14,46,62;" +
    "2003-11-14,43,67;" +
    "2003-11-15,41,58;" +
    "2003-11-15,49,60;" +
    "2003-11-16,36,61;" +
    "2003-11-16,37,62;" +
    "2003-11-17,43,64;" +
    "2003-11-17,50,66;" +
    "2003-11-18,40,65;" +
    "2003-11-18,41,63;" +
    "2003-11-19,41,69;" +
    "2003-11-19,42,70;" +
    "2003-11-20,42,63;" +
    "2003-11-20,46,64;" +
    "2003-11-21,33,58;" +
    "2003-11-21,35,59;" +
    "2003-11-22,31,56;" +
    "2003-11-22,33,57;" +
    "2003-11-23,33,57;" +
    "2003-11-23,33,57;" +
    "2003-11-24,33,57;" +
    "2003-11-24,35,58;" +
    "2003-11-25,32,58;" +
    "2003-11-25,33,58;" +
    "2003-11-26,37,60;" +
    "2003-11-26,42,61;" +
    "2003-11-27,35,58;" +
    "2003-11-27,39,58;" +
    "2003-11-28,44,56;" +
    "2003-11-28,45,56;" +
    "2003-11-29,41,56;" +
    "2003-11-29,44,54;" +
    "2003-11-30,48,56;" +
    "2003-11-30,48,55;" +
    "2003-12-01,45,67;" +
    "2003-12-01,46,67;" +
    "2003-12-02,45,63;" +
    "2003-12-02,46,64;" +
    "2003-12-03,41,59;" +
    "2003-12-03,47,59;" +
    "2003-12-04,40,53;" +
    "2003-12-04,40,54;" +
    "2003-12-05,50,64;" +
    "2003-12-05,50,65;" +
    "2003-12-06,50,64;" +
    "2003-12-06,51,64;" +
    "2003-12-07,40,59;" +
    "2003-12-07,50,60;" +
    "2003-12-08,33,58;" +
    "2003-12-08,35,58;" +
    "2003-12-09,37,54;" +
    "2003-12-09,39,54;" +
    "2003-12-10,49,58;" +
    "2003-12-10,50,58;" +
    "2003-12-11,35,56;" +
    "2003-12-11,44,56;" +
    "2003-12-12,35,55;" +
    "2003-12-12,38,55;" +
    "2003-12-13,52,62;" +
    "2003-12-13,51,64;" +
    "2003-12-14,34,58;" +
    "2003-12-14,46,59;" +
    "2003-12-15,31,53;" +
    "2003-12-15,33,53;" +
    "2003-12-16,31,54;" +
    "2003-12-16,32,53;" +
    "2003-12-17,34,59;" +
    "2003-12-17,39,58;" +
    "2003-12-18,36,58;" +
    "2003-12-18,40,57;" +
    "2003-12-19,40,51;" +
    "2003-12-19,42,51;" +
    "2003-12-20,45,61;" +
    "2003-12-20,47,60;" +
    "2003-12-21,42,54;" +
    "2003-12-21,48,54;" +
    "2003-12-22,40,55;" +
    "2003-12-22,42,55;" +
    "2003-12-23,45,58;" +
    "2003-12-23,46,57;" +
    "2003-12-24,51,59;" +
    "2003-12-24,52,58;" +
    "2003-12-25,40,52;" +
    "2003-12-25,46,53;" +
    "2003-12-26,32,52;" +
    "2003-12-26,34,54;" +
    "2003-12-27,27,52;" +
    "2003-12-27,29,54;" +
    "2003-12-28,27,50;" +
    "2003-12-28,29,50;" +
    "2003-12-29,45,55;" +
    "2003-12-29,44,55;" +
    "2003-12-30,39,50;" +
    "2003-12-30,42,50;" +
    "2003-12-31,39,56;" +
    "2003-12-31,40,57;"