export const data = "2006-01-01,39,63;" +
    "2006-01-01,40,62;" +
    "2006-01-02,48,55;" +
    "2006-01-02,48,58;" +
    "2006-01-03,41,56;" +
    "2006-01-03,42,55;" +
    "2006-01-04,48,59;" +
    "2006-01-04,50,59;" +
    "2006-01-05,42,58;" +
    "2006-01-05,48,56;" +
    "2006-01-06,39,66;" +
    "2006-01-06,40,69;" +
    "2006-01-07,45,61;" +
    "2006-01-07,50,62;" +
    "2006-01-08,40,56;" +
    "2006-01-08,40,57;" +
    "2006-01-09,35,61;" +
    "2006-01-09,35,60;" +
    "2006-01-10,34,60;" +
    "2006-01-10,36,62;" +
    "2006-01-11,41,61;" +
    "2006-01-11,49,61;" +
    "2006-01-12,37,54;" +
    "2006-01-12,39,53;" +
    "2006-01-13,40,63;" +
    "2006-01-13,41,63;" +
    "2006-01-14,45,54;" +
    "2006-01-14,48,58;" +
    "2006-01-15,34,55;" +
    "2006-01-15,36,56;" +
    "2006-01-16,34,56;" +
    "2006-01-16,35,57;" +
    "2006-01-17,40,61;" +
    "2006-01-17,40,62;" +
    "2006-01-18,41,56;" +
    "2006-01-18,47,55;" +
    "2006-01-19,34,54;" +
    "2006-01-19,36,54;" +
    "2006-01-20,31,56;" +
    "2006-01-20,32,57;" +
    "2006-01-21,39,50;" +
    "2006-01-21,41,50;" +
    "2006-01-22,38,60;" +
    "2006-01-22,40,59;" +
    "2006-01-23,43,61;" +
    "2006-01-23,45,60;" +
    "2006-01-24,35,61;" +
    "2006-01-24,36,60;" +
    "2006-01-25,34,60;" +
    "2006-01-25,36,60;" +
    "2006-01-26,40,56;" +
    "2006-01-26,41,58;" +
    "2006-01-27,37,56;" +
    "2006-01-27,38,57;" +
    "2006-01-28,41,59;" +
    "2006-01-28,41,59;" +
    "2006-01-29,44,61;" +
    "2006-01-29,50,64;" +
    "2006-01-30,42,59;" +
    "2006-01-30,42,61;" +
    "2006-01-31,48,59;" +
    "2006-01-31,36,59;" +
    "2006-02-01,50,59;" +
    "2006-02-01,48,60;" +
    "2006-02-02,49,75;" +
    "2006-02-02,55,64;" +
    "2006-02-03,49,65;" +
    "2006-02-03,48,64;" +
    "2006-02-04,40,62;" +
    "2006-02-04,50,61;" +
    "2006-02-05,41,60;" +
    "2006-02-05,40,59;" +
    "2006-02-06,35,62;" +
    "2006-02-06,37,59;" +
    "2006-02-07,38,72;" +
    "2006-02-07,38,72;" +
    "2006-02-08,6,71;" +
    "2006-02-08,39,70;" +
    "2006-02-09,39,69;" +
    "2006-02-09,39,69;" +
    "2006-02-10,38,70;" +
    "2006-02-10,39,70;" +
    "2006-02-11,41,71;" +
    "2006-02-11,44,72;" +
    "2006-02-12,39,69;" +
    "2006-02-12,42,69;" +
    "2006-02-13,42,74;" +
    "2006-02-13,47,71;" +
    "2006-02-14,40,69;" +
    "2006-02-14,42,70;" +
    "2006-02-15,32,55;" +
    "2006-02-15,39,57;" +
    "2006-02-16,27,54;" +
    "2006-02-16,29,55;" +
    "2006-02-17,39,49;" +
    "2006-02-17,38,49;" +
    "2006-02-18,31,51;" +
    "2006-02-18,32,50;" +
    "2006-02-19,35,50;" +
    "2006-02-19,37,51;" +
    "2006-02-20,30,56;" +
    "2006-02-20,31,56;" +
    "2006-02-21,28,59;" +
    "2006-02-21,30,60;" +
    "2006-02-22,30,64;" +
    "2006-02-22,32,64;" +
    "2006-02-23,33,64;" +
    "2006-02-23,35,64;" +
    "2006-02-24,33,67;" +
    "2006-02-24,36,68;" +
    "2006-02-25,35,70;" +
    "2006-02-25,38,70;" +
    "2006-02-26,42,58;" +
    "2006-02-26,43,62;" +
    "2006-02-27,53,67;" +
    "2006-02-27,51,70;" +
    "2006-02-28,39,56;" +
    "2006-02-28,46,65;" +
    "2006-03-01,35,60;" +
    "2006-03-01,36,62;" +
    "2006-03-02,38,58;" +
    "2006-03-02,42,59;" +
    "2006-03-03,37,53;" +
    "2006-03-03,40,53;" +
    "2006-03-04,31,56;" +
    "2006-03-04,34,58;" +
    "2006-03-05,46,61;" +
    "2006-03-05,47,61;" +
    "2006-03-06,45,59;" +
    "2006-03-06,45,60;" +
    "2006-03-07,38,57;" +
    "2006-03-07,45,58;" +
    "2006-03-08,33,59;" +
    "2006-03-08,35,61;" +
    "2006-03-09,41,55;" +
    "2006-03-09,44,56;" +
    "2006-03-10,37,50;" +
    "2006-03-10,39,52;" +
    "2006-03-11,32,51;" +
    "2006-03-11,33,54;" +
    "2006-03-12,38,50;" +
    "2006-03-12,39,51;" +
    "2006-03-13,32,56;" +
    "2006-03-13,33,57;" +
    "2006-03-14,43,56;" +
    "2006-03-14,45,58;" +
    "2006-03-15,40,57;" +
    "2006-03-15,40,59;" +
    "2006-03-16,44,59;" +
    "2006-03-16,44,61;" +
    "2006-03-17,42,57;" +
    "2006-03-17,45,60;" +
    "2006-03-18,36,60;" +
    "2006-03-18,37,63;" +
    "2006-03-19,34,61;" +
    "2006-03-19,36,61;" +
    "2006-03-20,41,52;" +
    "2006-03-20,42,55;" +
    "2006-03-21,41,56;" +
    "2006-03-21,40,58;" +
    "2006-03-22,36,60;" +
    "2006-03-22,38,61;" +
    "2006-03-23,40,70;" +
    "2006-03-23,42,73;" +
    "2006-03-24,48,69;" +
    "2006-03-24,48,70;" +
    "2006-03-25,44,60;" +
    "2006-03-25,48,60;" +
    "2006-03-26,37,60;" +
    "2006-03-26,39,61;" +
    "2006-03-27,37,64;" +
    "2006-03-27,39,65;" +
    "2006-03-28,41,61;" +
    "2006-03-28,47,62;" +
    "2006-03-29,41,57;" +
    "2006-03-29,43,57;" +
    "2006-03-30,46,59;" +
    "2006-03-30,46,60;" +
    "2006-03-31,45,60;" +
    "2006-03-31,44,61;" +
    "2006-04-01,41,61;" +
    "2006-04-01,43,64;" +
    "2006-04-02,45,60;" +
    "2006-04-02,46,62;" +
    "2006-04-03,50,57;" +
    "2006-04-03,51,55;" +
    "2006-04-04,47,55;" +
    "2006-04-04,49,56;" +
    "2006-04-05,43,57;" +
    "2006-04-05,45,59;" +
    "2006-04-06,37,62;" +
    "2006-04-06,38,62;" +
    "2006-04-07,41,62;" +
    "2006-04-07,41,65;" +
    "2006-04-08,40,65;" +
    "2006-04-08,41,66;" +
    "2006-04-09,48,61;" +
    "2006-04-09,48,63;" +
    "2006-04-10,50,60;" +
    "2006-04-10,49,61;" +
    "2006-04-11,50,61;" +
    "2006-04-11,50,60;" +
    "2006-04-12,50,59;" +
    "2006-04-12,52,61;" +
    "2006-04-13,49,69;" +
    "2006-04-13,49,70;" +
    "2006-04-14,50,69;" +
    "2006-04-14,52,70;" +
    "2006-04-15,50,60;" +
    "2006-04-15,50,59;" +
    "2006-04-16,45,57;" +
    "2006-04-16,47,58;" +
    "2006-04-17,37,60;" +
    "2006-04-17,40,60;" +
    "2006-04-18,37,66;" +
    "2006-04-18,38,67;" +
    "2006-04-19,40,75;" +
    "2006-04-19,41,76;" +
    "2006-04-20,43,74;" +
    "2006-04-20,45,77;" +
    "2006-04-21,49,65;" +
    "2006-04-21,50,66;" +
    "2006-04-22,49,63;" +
    "2006-04-22,49,64;" +
    "2006-04-23,50,62;" +
    "2006-04-23,51,65;" +
    "2006-04-24,50,61;" +
    "2006-04-24,51,64;" +
    "2006-04-25,49,62;" +
    "2006-04-25,50,62;" +
    "2006-04-26,47,73;" +
    "2006-04-26,48,75;" +
    "2006-04-27,45,83;" +
    "2006-04-27,47,85;" +
    "2006-04-28,53,77;" +
    "2006-04-28,53,81;" +
    "2006-04-29,53,70;" +
    "2006-04-29,53,74;" +
    "2006-04-30,48,84;" +
    "2006-04-30,50,85;" +
    "2006-05-01,49,87;" +
    "2006-05-01,50,88;" +
    "2006-05-02,46,84;" +
    "2006-05-02,48,85;" +
    "2006-05-03,50,65;" +
    "2006-05-03,50,79;" +
    "2006-05-04,50,70;" +
    "2006-05-04,50,72;" +
    "2006-05-05,50,66;" +
    "2006-05-05,53,68;" +
    "2006-05-06,49,64;" +
    "2006-05-06,50,66;" +
    "2006-05-07,44,80;" +
    "2006-05-07,44,83;" +
    "2006-05-08,50,78;" +
    "2006-05-08,50,81;" +
    "2006-05-09,47,82;" +
    "2006-05-09,47,85;" +
    "2006-05-10,49,88;" +
    "2006-05-10,51,89;" +
    "2006-05-11,50,87;" +
    "2006-05-11,54,90;" +
    "2006-05-12,50,83;" +
    "2006-05-12,52,86;" +
    "2006-05-13,50,79;" +
    "2006-05-13,49,82;" +
    "2006-05-14,47,93;" +
    "2006-05-14,49,94;" +
    "2006-05-15,58,90;" +
    "2006-05-15,64,92;" +
    "2006-05-16,56,84;" +
    "2006-05-16,55,87;" +
    "2006-05-17,54,83;" +
    "2006-05-17,54,86;" +
    "2006-05-18,50,84;" +
    "2006-05-18,51,89;" +
    "2006-05-19,53,71;" +
    "2006-05-19,54,82;" +
    "2006-05-20,53,73;" +
    "2006-05-20,53,74;" +
    "2006-05-21,53,69;" +
    "2006-05-21,55,69;" +
    "2006-05-22,53,71;" +
    "2006-05-22,52,72;" +
    "2006-05-23,55,76;" +
    "2006-05-23,56,78;" +
    "2006-05-24,53,75;" +
    "2006-05-24,52,77;" +
    "2006-05-25,51,70;" +
    "2006-05-25,52,72;" +
    "2006-05-26,50,68;" +
    "2006-05-26,51,69;" +
    "2006-05-27,50,66;" +
    "2006-05-27,49,69;" +
    "2006-05-28,45,74;" +
    "2006-05-28,45,76;" +
    "2006-05-29,44,77;" +
    "2006-05-29,44,79;" +
    "2006-05-30,49,81;" +
    "2006-05-30,46,83;" +
    "2006-05-31,51,83;" +
    "2006-05-31,52,85;" +
    "2006-06-01,55,84;" +
    "2006-06-01,56,85;" +
    "2006-06-02,59,83;" +
    "2006-06-02,58,84;" +
    "2006-06-03,55,89;" +
    "2006-06-03,56,90;" +
    "2006-06-04,55,80;" +
    "2006-06-04,60,85;" +
    "2006-06-05,54,74;" +
    "2006-06-05,55,78;" +
    "2006-06-06,52,83;" +
    "2006-06-06,53,84;" +
    "2006-06-07,53,80;" +
    "2006-06-07,52,79;" +
    "2006-06-08,52,73;" +
    "2006-06-08,53,76;" +
    "2006-06-09,52,79;" +
    "2006-06-09,50,82;" +
    "2006-06-10,52,71;" +
    "2006-06-10,52,74;" +
    "2006-06-11,52,72;" +
    "2006-06-11,53,73;" +
    "2006-06-12,54,67;" +
    "2006-06-12,56,68;" +
    "2006-06-13,53,69;" +
    "2006-06-13,53,70;" +
    "2006-06-14,49,75;" +
    "2006-06-14,49,77;" +
    "2006-06-15,53,84;" +
    "2006-06-15,53,85;" +
    "2006-06-16,55,96;" +
    "2006-06-16,57,98;" +
    "2006-06-17,58,94;" +
    "2006-06-17,60,96;" +
    "2006-06-18,54,84;" +
    "2006-06-18,55,86;" +
    "2006-06-19,52,82;" +
    "2006-06-19,52,85;" +
    "2006-06-20,54,94;" +
    "2006-06-20,55,96;" +
    "2006-06-21,58,100;" +
    "2006-06-21,59,101;" +
    "2006-06-22,59,103;" +
    "2006-06-22,62,105;" +
    "2006-06-23,62,101;" +
    "2006-06-23,62,104;" +
    "2006-06-24,60,94;" +
    "2006-06-24,60,97;" +
    "2006-06-25,60,95;" +
    "2006-06-25,60,98;" +
    "2006-06-26,62,96;" +
    "2006-06-26,62,98;" +
    "2006-06-27,62,90;" +
    "2006-06-27,64,91;" +
    "2006-06-28,59,96;" +
    "2006-06-28,60,92;" +
    "2006-06-29,57,92;" +
    "2006-06-29,58,95;" +
    "2006-06-30,55,78;" +
    "2006-06-30,58,90;" +
    "2006-07-01,55,89;" +
    "2006-07-01,55,92;" +
    "2006-07-02,55,83;" +
    "2006-07-02,54,87;" +
    "2006-07-03,53,82;" +
    "2006-07-03,53,85;" +
    "2006-07-04,53,79;" +
    "2006-07-04,52,82;" +
    "2006-07-05,53,75;" +
    "2006-07-05,52,78;" +
    "2006-07-06,53,80;" +
    "2006-07-06,53,82;" +
    "2006-07-07,49,95;" +
    "2006-07-07,50,96;" +
    "2006-07-08,56,100;" +
    "2006-07-08,58,101;" +
    "2006-07-09,56,99;" +
    "2006-07-09,63,101;" +
    "2006-07-10,55,83;" +
    "2006-07-10,55,95;" +
    "2006-07-11,54,77;" +
    "2006-07-11,53,81;" +
    "2006-07-12,53,79;" +
    "2006-07-12,55,82;" +
    "2006-07-13,58,96;" +
    "2006-07-13,59,98;" +
    "2006-07-14,58,89;" +
    "2006-07-14,59,94;" +
    "2006-07-15,56,96;" +
    "2006-07-15,56,97;" +
    "2006-07-16,62,102;" +
    "2006-07-16,61,104;" +
    "2006-07-17,63,106;" +
    "2006-07-17,65,107;" +
    "2006-07-18,67,101;" +
    "2006-07-18,67,102;" +
    "2006-07-19,71,96;" +
    "2006-07-19,71,98;" +
    "2006-07-20,68,101;" +
    "2006-07-20,68,104;" +
    "2006-07-21,69,109;" +
    "2006-07-21,70,109;" +
    "2006-07-22,75,111;" +
    "2006-07-22,75,111;" +
    "2006-07-23,79,113;" +
    "2006-07-23,79,112;" +
    "2006-07-24,75,109;" +
    "2006-07-24,74,110;" +
    "2006-07-25,73,109;" +
    "2006-07-25,72,110;" +
    "2006-07-26,64,98;" +
    "2006-07-26,67,104;" +
    "2006-07-27,60,93;" +
    "2006-07-27,61,96;" +
    "2006-07-28,59,88;" +
    "2006-07-28,59,90;" +
    "2006-07-29,59,82;" +
    "2006-07-29,59,84;" +
    "2006-07-30,60,87;" +
    "2006-07-30,59,88;" +
    "2006-07-31,57,81;" +
    "2006-07-31,58,83;" +
    "2006-08-01,55,86;" +
    "2006-08-01,53,88;" +
    "2006-08-02,57,88;" +
    "2006-08-02,56,90;" +
    "2006-08-03,55,90;" +
    "2006-08-03,54,92;" +
    "2006-08-04,56,82;" +
    "2006-08-04,56,84;" +
    "2006-08-05,58,80;" +
    "2006-08-05,58,82;" +
    "2006-08-06,56,80;" +
    "2006-08-06,56,81;" +
    "2006-08-07,57,74;" +
    "2006-08-07,58,75;" +
    "2006-08-08,59,89;" +
    "2006-08-08,59,91;" +
    "2006-08-09,60,99;" +
    "2006-08-09,60,99;" +
    "2006-08-10,63,95;" +
    "2006-08-10,63,96;" +
    "2006-08-11,56,85;" +
    "2006-08-11,58,88;" +
    "2006-08-12,54,85;" +
    "2006-08-12,54,87;" +
    "2006-08-13,56,86;" +
    "2006-08-13,56,89;" +
    "2006-08-14,56,78;" +
    "2006-08-14,56,81;" +
    "2006-08-15,56,81;" +
    "2006-08-15,56,83;" +
    "2006-08-16,55,79;" +
    "2006-08-16,54,82;" +
    "2006-08-17,49,89;" +
    "2006-08-17,48,91;" +
    "2006-08-18,56,87;" +
    "2006-08-18,55,88;" +
    "2006-08-19,54,87;" +
    "2006-08-19,54,89;" +
    "2006-08-20,55,79;" +
    "2006-08-20,54,82;" +
    "2006-08-21,54,81;" +
    "2006-08-21,54,84;" +
    "2006-08-22,55,93;" +
    "2006-08-22,54,94;" +
    "2006-08-23,54,90;" +
    "2006-08-23,54,91;" +
    "2006-08-24,51,85;" +
    "2006-08-24,53,87;" +
    "2006-08-25,56,80;" +
    "2006-08-25,55,82;" +
    "2006-08-26,56,86;" +
    "2006-08-26,56,88;" +
    "2006-08-27,56,90;" +
    "2006-08-27,56,92;" +
    "2006-08-28,55,88;" +
    "2006-08-28,55,90;" +
    "2006-08-29,54,79;" +
    "2006-08-29,54,82;" +
    "2006-08-30,54,92;" +
    "2006-08-30,53,94;" +
    "2006-08-31,58,97;" +
    "2006-08-31,57,98;" +
    "2006-09-01,58,92;" +
    "2006-09-01,59,93;" +
    "2006-09-02,54,83;" +
    "2006-09-02,55,86;" +
    "2006-09-03,54,80;" +
    "2006-09-03,54,82;" +
    "2006-09-04,53,85;" +
    "2006-09-04,53,87;" +
    "2006-09-05,53,98;" +
    "2006-09-05,53,98;" +
    "2006-09-06,55,95;" +
    "2006-09-06,56,96;" +
    "2006-09-07,57,91;" +
    "2006-09-07,56,93;" +
    "2006-09-08,56,71;" +
    "2006-09-08,56,82;" +
    "2006-09-09,53,73;" +
    "2006-09-09,52,75;" +
    "2006-09-10,55,86;" +
    "2006-09-10,55,87;" +
    "2006-09-11,56,94;" +
    "2006-09-11,54,95;" +
    "2006-09-12,58,102;" +
    "2006-09-12,59,102;" +
    "2006-09-13,57,96;" +
    "2006-09-13,60,97;" +
    "2006-09-14,55,72;" +
    "2006-09-14,55,85;" +
    "2006-09-15,52,70;" +
    "2006-09-15,52,70;" +
    "2006-09-16,46,79;" +
    "2006-09-16,46,80;" +
    "2006-09-17,48,88;" +
    "2006-09-17,48,87;" +
    "2006-09-18,55,92;" +
    "2006-09-18,54,93;" +
    "2006-09-19,55,80;" +
    "2006-09-19,55,82;" +
    "2006-09-20,52,85;" +
    "2006-09-20,52,86;" +
    "2006-09-21,52,87;" +
    "2006-09-21,54,86;" +
    "2006-09-22,57,82;" +
    "2006-09-22,61,81;" +
    "2006-09-23,52,87;" +
    "2006-09-23,52,85;" +
    "2006-09-24,50,89;" +
    "2006-09-24,51,88;" +
    "2006-09-25,51,91;" +
    "2006-09-25,51,89;" +
    "2006-09-26,53,84;" +
    "2006-09-26,54,86;" +
    "2006-09-27,54,80;" +
    "2006-09-27,54,81;" +
    "2006-09-28,53,79;" +
    "2006-09-28,54,80;" +
    "2006-09-29,53,75;" +
    "2006-09-29,53,77;" +
    "2006-09-30,53,67;" +
    "2006-09-30,54,68;" +
    "2006-10-01,54,64;" +
    "2006-10-01,53,64;" +
    "2006-10-02,54,70;" +
    "2006-10-02,53,70;" +
    "2006-10-03,54,71;" +
    "2006-10-03,54,72;" +
    "2006-10-04,52,69;" +
    "2006-10-04,51,70;" +
    "2006-10-05,50,68;" +
    "2006-10-05,53,67;" +
    "2006-10-06,48,65;" +
    "2006-10-06,48,66;" +
    "2006-10-07,46,77;" +
    "2006-10-07,46,78;" +
    "2006-10-08,47,85;" +
    "2006-10-08,48,83;" +
    "2006-10-09,48,84;" +
    "2006-10-09,49,84;" +
    "2006-10-10,52,74;" +
    "2006-10-10,53,75;" +
    "2006-10-11,47,79;" +
    "2006-10-11,47,78;" +
    "2006-10-12,48,84;" +
    "2006-10-12,47,84;" +
    "2006-10-13,49,72;" +
    "2006-10-13,48,73;" +
    "2006-10-14,54,68;" +
    "2006-10-14,53,69;" +
    "2006-10-15,54,58;" +
    "2006-10-15,54,59;" +
    "2006-10-16,51,67;" +
    "2006-10-16,54,67;" +
    "2006-10-17,45,74;" +
    "2006-10-17,45,74;" +
    "2006-10-18,48,75;" +
    "2006-10-18,50,77;" +
    "2006-10-19,45,82;" +
    "2006-10-19,45,81;" +
    "2006-10-20,48,83;" +
    "2006-10-20,49,81;" +
    "2006-10-21,47,81;" +
    "2006-10-21,49,79;" +
    "2006-10-22,47,87;" +
    "2006-10-22,49,84;" +
    "2006-10-23,47,87;" +
    "2006-10-23,48,86;" +
    "2006-10-24,47,80;" +
    "2006-10-24,48,80;" +
    "2006-10-25,45,74;" +
    "2006-10-25,48,72;" +
    "2006-10-26,49,74;" +
    "2006-10-26,56,73;" +
    "2006-10-27,44,79;" +
    "2006-10-27,42,77;" +
    "2006-10-28,41,78;" +
    "2006-10-28,40,76;" +
    "2006-10-29,41,70;" +
    "2006-10-29,41,71;" +
    "2006-10-30,46,63;" +
    "2006-10-30,45,63;" +
    "2006-10-31,43,63;" +
    "2006-10-31,45,64;" +
    "2006-11-01,41,70;" +
    "2006-11-01,43,68;" +
    "2006-11-02,54,68;" +
    "2006-11-02,54,69;" +
    "2006-11-03,55,69;" +
    "2006-11-03,58,69;" +
    "2006-11-04,53,69;" +
    "2006-11-04,54,69;" +
    "2006-11-05,50,73;" +
    "2006-11-05,50,74;" +
    "2006-11-06,51,77;" +
    "2006-11-06,51,78;" +
    "2006-11-07,51,78;" +
    "2006-11-07,52,78;" +
    "2006-11-08,52,65;" +
    "2006-11-08,56,65;" +
    "2006-11-09,40,66;" +
    "2006-11-09,43,66;" +
    "2006-11-10,35,67;" +
    "2006-11-10,37,68;" +
    "2006-11-11,46,57;" +
    "2006-11-11,48,56;" +
    "2006-11-12,41,61;" +
    "2006-11-12,42,62;" +
    "2006-11-13,48,62;" +
    "2006-11-13,47,62;" +
    "2006-11-14,45,63;" +
    "2006-11-14,51,63;" +
    "2006-11-15,42,67;" +
    "2006-11-15,42,67;" +
    "2006-11-16,45,70;" +
    "2006-11-16,45,69;" +
    "2006-11-17,51,67;" +
    "2006-11-17,53,67;" +
    "2006-11-18,48,66;" +
    "2006-11-18,49,64;" +
    "2006-11-19,44,68;" +
    "2006-11-19,45,69;" +
    "2006-11-20,49,67;" +
    "2006-11-20,48,67;" +
    "2006-11-21,44,65;" +
    "2006-11-21,52,66;" +
    "2006-11-22,41,64;" +
    "2006-11-22,42,64;" +
    "2006-11-23,34,60;" +
    "2006-11-23,39,59;" +
    "2006-11-24,29,60;" +
    "2006-11-24,30,61;" +
    "2006-11-25,32,61;" +
    "2006-11-25,36,62;" +
    "2006-11-26,37,53;" +
    "2006-11-26,40,52;" +
    "2006-11-27,38,57;" +
    "2006-11-27,43,55;" +
    "2006-11-28,32,55;" +
    "2006-11-28,35,54;" +
    "2006-11-29,32,53;" +
    "2006-11-29,33,52;" +
    "2006-11-30,29,55;" +
    "2006-11-30,31,54;" +
    "2006-12-01,32,63;" +
    "2006-12-01,32,62;" +
    "2006-12-02,39,61;" +
    "2006-12-02,41,60;" +
    "2006-12-03,34,60;" +
    "2006-12-03,34,59;" +
    "2006-12-04,33,66;" +
    "2006-12-04,34,65;" +
    "2006-12-05,33,63;" +
    "2006-12-05,34,62;" +
    "2006-12-06,32,65;" +
    "2006-12-06,33,64;" +
    "2006-12-07,31,66;" +
    "2006-12-07,34,63;" +
    "2006-12-08,37,70;" +
    "2006-12-08,40,70;" +
    "2006-12-09,51,60;" +
    "2006-12-09,52,61;" +
    "2006-12-10,41,59;" +
    "2006-12-10,44,59;" +
    "2006-12-11,38,58;" +
    "2006-12-11,38,58;" +
    "2006-12-12,51,61;" +
    "2006-12-12,50,59;" +
    "2006-12-13,54,64;" +
    "2006-12-13,53,63;" +
    "2006-12-14,53,63;" +
    "2006-12-14,55,62;" +
    "2006-12-15,44,57;" +
    "2006-12-15,50,58;" +
    "2006-12-16,30,50;" +
    "2006-12-16,33,50;" +
    "2006-12-17,28,51;" +
    "2006-12-17,30,52;" +
    "2006-12-18,26,56;" +
    "2006-12-18,28,55;" +
    "2006-12-19,25,52;" +
    "2006-12-19,27,52;" +
    "2006-12-20,28,51;" +
    "2006-12-20,29,50;" +
    "2006-12-21,34,52;" +
    "2006-12-21,35,49;" +
    "2006-12-22,38,57;" +
    "2006-12-22,40,57;" +
    "2006-12-23,31,58;" +
    "2006-12-23,33,58;" +
    "2006-12-24,37,56;" +
    "2006-12-24,38,56;" +
    "2006-12-25,39,57;" +
    "2006-12-25,39,60;" +
    "2006-12-26,46,67;" +
    "2006-12-26,47,68;" +
    "2006-12-27,45,57;" +
    "2006-12-27,47,61;" +
    "2006-12-28,39,56;" +
    "2006-12-28,40,55;" +
    "2006-12-29,34,55;" +
    "2006-12-29,35,55;" +
    "2006-12-30,28,55;" +
    "2006-12-30,30,54;" +
    "2006-12-31,32,53;" +
    "2006-12-31,34,55;"