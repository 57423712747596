export const data = "1994-01-01,36,63;" +
    "1994-01-01,38,64;" +
    "1994-01-02,44,53;" +
    "1994-01-02,44,56;" +
    "1994-01-03,43,46;" +
    "1994-01-03,42,45;" +
    "1994-01-04,42,51;" +
    "1994-01-04,42,52;" +
    "1994-01-05,37,56;" +
    "1994-01-05,39,54;" +
    "1994-01-06,31,57;" +
    "1994-01-06,38,56;" +
    "1994-01-07,32,49;" +
    "1994-01-07,33,50;" +
    "1994-01-08,31,52;" +
    "1994-01-08,35,51;" +
    "1994-01-09,31,56;" +
    "1994-01-09,43,55;" +
    "1994-01-10,37,62;" +
    "1994-01-10,40,59;" +
    "1994-01-11,32,56;" +
    "1994-01-11,36,58;" +
    "1994-01-12,42,59;" +
    "1994-01-12,44,61;" +
    "1994-01-13,37,60;" +
    "1994-01-13,45,63;" +
    "1994-01-14,35,56;" +
    "1994-01-14,42,57;" +
    "1994-01-15,35,68;" +
    "1994-01-15,42,67;" +
    "1994-01-16,37,66;" +
    "1994-01-16,45,67;" +
    "1994-01-17,44,68;" +
    "1994-01-17,50,68;" +
    "1994-01-18,36,68;" +
    "1994-01-18,45,67;" +
    "1994-01-19,36,64;" +
    "1994-01-19,44,65;" +
    "1994-01-20,36,64;" +
    "1994-01-20,44,62;" +
    "1994-01-21,42,69;" +
    "1994-01-21,43,69;" +
    "1994-01-22,39,64;" +
    "1994-01-22,45,65;" +
    "1994-01-23,50,60;" +
    "1994-01-23,50,57;" +
    "1994-01-24,45,54;" +
    "1994-01-24,43,53;" +
    "1994-01-25,39,52;" +
    "1994-01-25,43,51;" +
    "1994-01-26,41,55;" +
    "1994-01-26,43,52;" +
    "1994-01-27,40,57;" +
    "1994-01-27,40,56;" +
    "1994-01-28,35,51;" +
    "1994-01-28,39,51;" +
    "1994-01-29,30,63;" +
    "1994-01-29,38,61;" +
    "1994-01-30,31,58;" +
    "1994-01-30,36,60;" +
    "1994-01-31,37,52;" +
    "1994-01-31,41,52;" +
    "1994-02-01,33,53;" +
    "1994-02-01,40,53;" +
    "1994-02-02,35,53;" +
    "1994-02-02,38,54;" +
    "1994-02-03,36,55;" +
    "1994-02-03,40,54;" +
    "1994-02-04,38,63;" +
    "1994-02-04,41,63;" +
    "1994-02-05,34,55;" +
    "1994-02-05,40,52;" +
    "1994-02-06,46,53;" +
    "1994-02-06,47,54;" +
    "1994-02-07,48,60;" +
    "1994-02-07,45,58;" +
    "1994-02-08,43,55;" +
    "1994-02-08,41,55;" +
    "1994-02-09,32,62;" +
    "1994-02-09,36,60;" +
    "1994-02-10,44,55;" +
    "1994-02-10,42,52;" +
    "1994-02-11,33,55;" +
    "1994-02-11,36,54;" +
    "1994-02-12,34,58;" +
    "1994-02-12,36,59;" +
    "1994-02-13,29,63;" +
    "1994-02-13,35,64;" +
    "1994-02-14,30,62;" +
    "1994-02-14,34,62;" +
    "1994-02-15,36,56;" +
    "1994-02-15,40,57;" +
    "1994-02-16,44,68;" +
    "1994-02-16,46,64;" +
    "1994-02-17,45,59;" +
    "1994-02-17,39,55;" +
    "1994-02-18,40,51;" +
    "1994-02-18,39,49;" +
    "1994-02-19,36,45;" +
    "1994-02-19,38,43;" +
    "1994-02-20,40,55;" +
    "1994-02-20,41,53;" +
    "1994-02-21,36,56;" +
    "1994-02-21,41,53;" +
    "1994-02-22,35,56;" +
    "1994-02-22,38,56;" +
    "1994-02-23,33,64;" +
    "1994-02-23,38,63;" +
    "1994-02-24,36,65;" +
    "1994-02-24,40,65;" +
    "1994-02-25,38,61;" +
    "1994-02-25,42,60;" +
    "1994-02-26,48,57;" +
    "1994-02-26,48,56;" +
    "1994-02-27,49,62;" +
    "1994-02-27,48,60;" +
    "1994-02-28,43,69;" +
    "1994-02-28,46,68;" +
    "1994-03-01,43,69;" +
    "1994-03-01,48,69;" +
    "1994-03-02,43,73;" +
    "1994-03-02,49,73;" +
    "1994-03-03,46,72;" +
    "1994-03-03,51,71;" +
    "1994-03-04,47,74;" +
    "1994-03-04,51,71;" +
    "1994-03-05,53,62;" +
    "1994-03-05,49,60;" +
    "1994-03-06,45,68;" +
    "1994-03-06,47,68;" +
    "1994-03-07,42,75;" +
    "1994-03-07,48,75;" +
    "1994-03-08,41,75;" +
    "1994-03-08,48,72;" +
    "1994-03-09,47,66;" +
    "1994-03-09,47,64;" +
    "1994-03-10,46,62;" +
    "1994-03-10,48,61;" +
    "1994-03-11,41,70;" +
    "1994-03-11,44,67;" +
    "1994-03-12,51,73;" +
    "1994-03-12,56,73;" +
    "1994-03-13,41,79;" +
    "1994-03-13,52,77;" +
    "1994-03-14,42,80;" +
    "1994-03-14,49,79;" +
    "1994-03-15,41,69;" +
    "1994-03-15,47,65;" +
    "1994-03-16,51,58;" +
    "1994-03-16,44,58;" +
    "1994-03-17,37,69;" +
    "1994-03-17,40,68;" +
    "1994-03-18,44,66;" +
    "1994-03-18,47,63;" +
    "1994-03-19,47,63;" +
    "1994-03-19,46,61;" +
    "1994-03-20,38,69;" +
    "1994-03-20,42,67;" +
    "1994-03-21,45,62;" +
    "1994-03-21,45,60;" +
    "1994-03-22,40,56;" +
    "1994-03-22,39,51;" +
    "1994-03-23,33,60;" +
    "1994-03-23,34,57;" +
    "1994-03-24,44,59;" +
    "1994-03-24,44,59;" +
    "1994-03-25,42,59;" +
    "1994-03-25,43,59;" +
    "1994-03-26,45,71;" +
    "1994-03-26,47,68;" +
    "1994-03-27,45,74;" +
    "1994-03-27,46,74;" +
    "1994-03-28,46,73;" +
    "1994-03-28,48,71;" +
    "1994-03-29,42,68;" +
    "1994-03-29,45,65;" +
    "1994-03-30,51,67;" +
    "1994-03-30,47,63;" +
    "1994-03-31,40,74;" +
    "1994-03-31,42,72;" +
    "1994-04-01,42,78;" +
    "1994-04-01,45,76;" +
    "1994-04-02,42,78;" +
    "1994-04-02,48,68;" +
    "1994-04-03,42,76;" +
    "1994-04-03,48,65;" +
    "1994-04-04,41,79;" +
    "1994-04-04,45,74;" +
    "1994-04-05,41,75;" +
    "1994-04-05,43,71;" +
    "1994-04-06,48,65;" +
    "1994-04-06,45,61;" +
    "1994-04-07,42,67;" +
    "1994-04-07,44,64;" +
    "1994-04-08,49,59;" +
    "1994-04-08,47,56;" +
    "1994-04-09,44,62;" +
    "1994-04-09,46,59;" +
    "1994-04-10,39,72;" +
    "1994-04-10,42,69;" +
    "1994-04-11,42,78;" +
    "1994-04-11,47,70;" +
    "1994-04-12,46,81;" +
    "1994-04-12,51,78;" +
    "1994-04-13,44,74;" +
    "1994-04-13,47,69;" +
    "1994-04-14,45,82;" +
    "1994-04-14,45,80;" +
    "1994-04-15,49,85;" +
    "1994-04-15,51,82;" +
    "1994-04-16,52,77;" +
    "1994-04-16,51,67;" +
    "1994-04-17,52,78;" +
    "1994-04-17,51,75;" +
    "1994-04-18,49,78;" +
    "1994-04-18,50,74;" +
    "1994-04-19,51,71;" +
    "1994-04-19,49,66;" +
    "1994-04-20,43,72;" +
    "1994-04-20,46,67;" +
    "1994-04-21,49,67;" +
    "1994-04-21,48,64;" +
    "1994-04-22,50,65;" +
    "1994-04-22,49,60;" +
    "1994-04-23,49,59;" +
    "1994-04-23,47,53;" +
    "1994-04-24,45,59;" +
    "1994-04-24,44,56;" +
    "1994-04-25,47,58;" +
    "1994-04-25,46,55;" +
    "1994-04-26,45,65;" +
    "1994-04-26,46,61;" +
    "1994-04-27,43,65;" +
    "1994-04-27,45,63;" +
    "1994-04-28,43,75;" +
    "1994-04-28,47,72;" +
    "1994-04-29,47,69;" +
    "1994-04-29,47,58;" +
    "1994-04-30,49,67;" +
    "1994-04-30,47,62;" +
    "1994-05-01,44,66;" +
    "1994-05-01,44,62;" +
    "1994-05-02,48,72;" +
    "1994-05-02,46,67;" +
    "1994-05-03,52,71;" +
    "1994-05-03,50,66;" +
    "1994-05-04,53,74;" +
    "1994-05-04,53,70;" +
    "1994-05-05,52,69;" +
    "1994-05-05,51,65;" +
    "1994-05-06,50,64;" +
    "1994-05-06,50,58;" +
    "1994-05-07,51,67;" +
    "1994-05-07,51,66;" +
    "1994-05-08,47,71;" +
    "1994-05-08,49,66;" +
    "1994-05-09,53,75;" +
    "1994-05-09,52,71;" +
    "1994-05-10,53,82;" +
    "1994-05-10,51,79;" +
    "1994-05-11,49,72;" +
    "1994-05-11,49,63;" +
    "1994-05-12,49,73;" +
    "1994-05-12,50,70;" +
    "1994-05-13,46,81;" +
    "1994-05-13,48,76;" +
    "1994-05-14,47,74;" +
    "1994-05-14,49,66;" +
    "1994-05-15,54,65;" +
    "1994-05-15,50,63;" +
    "1994-05-16,49,65;" +
    "1994-05-16,48,60;" +
    "1994-05-17,48,60;" +
    "1994-05-17,48,59;" +
    "1994-05-18,48,69;" +
    "1994-05-18,49,66;" +
    "1994-05-19,52,61;" +
    "1994-05-19,49,58;" +
    "1994-05-20,47,72;" +
    "1994-05-20,48,67;" +
    "1994-05-21,49,71;" +
    "1994-05-21,49,66;" +
    "1994-05-22,44,79;" +
    "1994-05-22,45,76;" +
    "1994-05-23,47,85;" +
    "1994-05-23,49,82;" +
    "1994-05-24,49,82;" +
    "1994-05-24,49,77;" +
    "1994-05-25,51,78;" +
    "1994-05-25,50,73;" +
    "1994-05-26,53,66;" +
    "1994-05-26,48,65;" +
    "1994-05-27,48,78;" +
    "1994-05-27,49,75;" +
    "1994-05-28,50,83;" +
    "1994-05-28,50,80;" +
    "1994-05-29,50,90;" +
    "1994-05-29,51,86;" +
    "1994-05-30,55,86;" +
    "1994-05-30,56,87;" +
    "1994-05-31,56,80;" +
    "1994-05-31,50,72;" +
    "1994-06-01,51,83;" +
    "1994-06-01,50,80;" +
    "1994-06-02,51,80;" +
    "1994-06-02,50,75;" +
    "1994-06-03,48,75;" +
    "1994-06-03,48,72;" +
    "1994-06-04,53,78;" +
    "1994-06-04,52,75;" +
    "1994-06-05,53,70;" +
    "1994-06-05,51,67;" +
    "1994-06-06,53,70;" +
    "1994-06-06,50,67;" +
    "1994-06-07,47,79;" +
    "1994-06-07,47,75;" +
    "1994-06-08,48,88;" +
    "1994-06-08,49,85;" +
    "1994-06-09,54,96;" +
    "1994-06-09,57,98;" +
    "1994-06-10,61,102;" +
    "1994-06-10,66,100;" +
    "1994-06-11,61,95;" +
    "1994-06-11,55,85;" +
    "1994-06-12,53,77;" +
    "1994-06-12,52,68;" +
    "1994-06-13,53,74;" +
    "1994-06-13,50,70;" +
    "1994-06-14,49,81;" +
    "1994-06-14,49,78;" +
    "1994-06-15,50,73;" +
    "1994-06-15,48,70;" +
    "1994-06-16,51,73;" +
    "1994-06-16,49,69;" +
    "1994-06-17,47,78;" +
    "1994-06-17,47,74;" +
    "1994-06-18,46,86;" +
    "1994-06-18,48,84;" +
    "1994-06-19,52,88;" +
    "1994-06-19,53,85;" +
    "1994-06-20,51,81;" +
    "1994-06-20,51,75;" +
    "1994-06-21,47,84;" +
    "1994-06-21,48,80;" +
    "1994-06-22,50,83;" +
    "1994-06-22,49,81;" +
    "1994-06-23,49,86;" +
    "1994-06-23,49,84;" +
    "1994-06-24,52,87;" +
    "1994-06-24,52,84;" +
    "1994-06-25,51,82;" +
    "1994-06-25,50,79;" +
    "1994-06-26,50,95;" +
    "1994-06-26,51,96;" +
    "1994-06-27,60,97;" +
    "1994-06-27,60,93;" +
    "1994-06-28,54,95;" +
    "1994-06-28,53,92;" +
    "1994-06-29,53,92;" +
    "1994-06-29,52,88;" +
    "1994-06-30,52,88;" +
    "1994-06-30,50,84;" +
    "1994-07-01,53,90;" +
    "1994-07-01,53,84;" +
    "1994-07-02,52,80;" +
    "1994-07-02,51,78;" +
    "1994-07-03,53,87;" +
    "1994-07-03,52,83;" +
    "1994-07-04,52,83;" +
    "1994-07-04,50,78;" +
    "1994-07-05,51,84;" +
    "1994-07-05,51,81;" +
    "1994-07-06,54,96;" +
    "1994-07-06,54,73;" +
    "1994-07-07,55,91;" +
    "1994-07-08,55,94;" +
    "1994-07-09,55,97;" +
    "1994-07-10,55,91;" +
    "1994-07-11,55,89;" +
    "1994-07-12,53,90;" +
    "1994-07-13,53,90;" +
    "1994-07-14,53,92;" +
    "1994-07-14,58,89;" +
    "1994-07-15,55,89;" +
    "1994-07-15,53,84;" +
    "1994-07-16,54,93;" +
    "1994-07-16,53,92;" +
    "1994-07-17,57,94;" +
    "1994-07-17,56,90;" +
    "1994-07-18,55,89;" +
    "1994-07-18,54,85;" +
    "1994-07-19,56,83;" +
    "1994-07-19,55,78;" +
    "1994-07-20,56,78;" +
    "1994-07-20,54,76;" +
    "1994-07-21,56,80;" +
    "1994-07-21,55,76;" +
    "1994-07-22,57,82;" +
    "1994-07-22,55,79;" +
    "1994-07-23,57,76;" +
    "1994-07-23,56,71;" +
    "1994-07-24,56,81;" +
    "1994-07-24,55,77;" +
    "1994-07-25,53,95;" +
    "1994-07-25,54,93;" +
    "1994-07-26,59,96;" +
    "1994-07-26,54,94;" +
    "1994-07-27,55,87;" +
    "1994-07-27,54,79;" +
    "1994-07-28,55,79;" +
    "1994-07-28,53,76;" +
    "1994-07-29,55,87;" +
    "1994-07-29,54,83;" +
    "1994-07-30,54,82;" +
    "1994-07-30,54,77;" +
    "1994-07-31,55,80;" +
    "1994-07-31,54,76;" +
    "1994-08-01,55,77;" +
    "1994-08-01,53,74;" +
    "1994-08-02,53,86;" +
    "1994-08-02,52,83;" +
    "1994-08-03,54,87;" +
    "1994-08-03,53,85;" +
    "1994-08-04,54,87;" +
    "1994-08-04,55,80;" +
    "1994-08-05,53,96;" +
    "1994-08-05,54,93;" +
    "1994-08-06,55,102;" +
    "1994-08-06,61,101;" +
    "1994-08-07,60,98;" +
    "1994-08-07,56,86;" +
    "1994-08-08,55,86;" +
    "1994-08-08,54,81;" +
    "1994-08-09,50,86;" +
    "1994-08-09,53,81;" +
    "1994-08-10,52,87;" +
    "1994-08-10,52,84;" +
    "1994-08-11,54,93;" +
    "1994-08-11,55,91;" +
    "1994-08-12,54,94;" +
    "1994-08-12,59,92;" +
    "1994-08-13,55,98;" +
    "1994-08-13,60,97;" +
    "1994-08-14,60,99;" +
    "1994-08-14,66,98;" +
    "1994-08-15,60,101;" +
    "1994-08-15,64,100;" +
    "1994-08-16,55,100;" +
    "1994-08-16,57,98;" +
    "1994-08-17,57,95;" +
    "1994-08-17,60,93;" +
    "1994-08-18,56,91;" +
    "1994-08-18,56,88;" +
    "1994-08-19,56,91;" +
    "1994-08-19,56,83;" +
    "1994-08-20,54,89;" +
    "1994-08-20,54,85;" +
    "1994-08-21,53,81;" +
    "1994-08-21,54,76;" +
    "1994-08-22,52,85;" +
    "1994-08-22,54,83;" +
    "1994-08-23,53,83;" +
    "1994-08-23,54,80;" +
    "1994-08-24,54,89;" +
    "1994-08-24,54,87;" +
    "1994-08-25,55,85;" +
    "1994-08-25,54,83;" +
    "1994-08-26,54,88;" +
    "1994-08-26,54,86;" +
    "1994-08-27,56,83;" +
    "1994-08-27,54,79;" +
    "1994-08-28,55,80;" +
    "1994-08-28,54,78;" +
    "1994-08-29,55,90;" +
    "1994-08-29,54,88;" +
    "1994-08-30,53,88;" +
    "1994-08-30,54,86;" +
    "1994-08-31,53,85;" +
    "1994-08-31,54,81;" +
    "1994-09-01,54,80;" +
    "1994-09-01,54,77;" +
    "1994-09-02,53,72;" +
    "1994-09-02,52,70;" +
    "1994-09-03,53,87;" +
    "1994-09-03,54,85;" +
    "1994-09-04,52,93;" +
    "1994-09-04,56,93;" +
    "1994-09-05,57,95;" +
    "1994-09-05,62,93;" +
    "1994-09-06,57,91;" +
    "1994-09-06,57,89;" +
    "1994-09-07,53,86;" +
    "1994-09-07,52,84;" +
    "1994-09-08,53,79;" +
    "1994-09-08,53,76;" +
    "1994-09-09,54,78;" +
    "1994-09-09,54,75;" +
    "1994-09-10,52,76;" +
    "1994-09-10,52,74;" +
    "1994-09-11,57,72;" +
    "1994-09-11,55,72;" +
    "1994-09-12,56,78;" +
    "1994-09-12,54,76;" +
    "1994-09-13,51,77;" +
    "1994-09-13,52,76;" +
    "1994-09-14,50,87;" +
    "1994-09-14,51,87;" +
    "1994-09-15,54,91;" +
    "1994-09-15,59,93;" +
    "1994-09-16,57,96;" +
    "1994-09-16,63,98;" +
    "1994-09-17,57,87;" +
    "1994-09-17,55,80;" +
    "1994-09-18,54,89;" +
    "1994-09-18,54,88;" +
    "1994-09-19,57,91;" +
    "1994-09-19,58,89;" +
    "1994-09-20,55,93;" +
    "1994-09-20,55,92;" +
    "1994-09-21,57,90;" +
    "1994-09-21,56,89;" +
    "1994-09-22,57,85;" +
    "1994-09-22,57,84;" +
    "1994-09-23,59,79;" +
    "1994-09-23,59,82;" +
    "1994-09-24,57,85;" +
    "1994-09-24,60,84;" +
    "1994-09-25,58,89;" +
    "1994-09-25,58,91;" +
    "1994-09-26,55,92;" +
    "1994-09-26,55,93;" +
    "1994-09-27,57,83;" +
    "1994-09-27,56,82;" +
    "1994-09-28,57,78;" +
    "1994-09-28,57,76;" +
    "1994-09-29,57,79;" +
    "1994-09-29,54,78;" +
    "1994-09-30,53,83;" +
    "1994-09-30,52,82;" +
    "1994-10-01,53,85;" +
    "1994-10-01,56,86;" +
    "1994-10-02,53,90;" +
    "1994-10-02,58,88;" +
    "1994-10-03,56,79;" +
    "1994-10-03,55,78;" +
    "1994-10-04,54,67;" +
    "1994-10-04,52,66;" +
    "1994-10-05,51,71;" +
    "1994-10-05,51,70;" +
    "1994-10-06,51,74;" +
    "1994-10-06,51,77;" +
    "1994-10-07,49,79;" +
    "1994-10-07,53,80;" +
    "1994-10-08,49,82;" +
    "1994-10-08,56,82;" +
    "1994-10-09,51,90;" +
    "1994-10-09,59,91;" +
    "1994-10-10,52,80;" +
    "1994-10-10,52,74;" +
    "1994-10-11,47,78;" +
    "1994-10-11,48,77;" +
    "1994-10-12,50,70;" +
    "1994-10-12,49,68;" +
    "1994-10-13,44,73;" +
    "1994-10-13,46,71;" +
    "1994-10-14,50,70;" +
    "1994-10-14,51,69;" +
    "1994-10-15,49,71;" +
    "1994-10-15,48,68;" +
    "1994-10-16,50,71;" +
    "1994-10-16,50,70;" +
    "1994-10-17,42,74;" +
    "1994-10-17,47,75;" +
    "1994-10-18,44,77;" +
    "1994-10-18,49,77;" +
    "1994-10-19,44,74;" +
    "1994-10-19,47,74;" +
    "1994-10-20,42,74;" +
    "1994-10-20,45,77;" +
    "1994-10-21,43,80;" +
    "1994-10-21,48,82;" +
    "1994-10-22,44,78;" +
    "1994-10-22,51,81;" +
    "1994-10-23,46,80;" +
    "1994-10-23,52,82;" +
    "1994-10-24,52,75;" +
    "1994-10-24,51,75;" +
    "1994-10-25,50,64;" +
    "1994-10-25,50,63;" +
    "1994-10-26,46,76;" +
    "1994-10-26,48,52;" +
    "1994-10-27,53,76;" +
    "1994-10-28,55,75;" +
    "1994-10-29,43,72;" +
    "1994-10-30,38,76;" +
    "1994-10-31,41,76;" +
    "1994-11-14,44,56;" +
    "1994-11-15,41,51;" +
    "1994-11-16,38,49;" +
    "1994-11-21,45,57;" +
    "1994-11-22,40,61;" +
    "1994-11-23,38,58;" +
    "1994-11-24,39,61;" +
    "1994-11-25,43,51;" +
    "1994-11-26,38,52;" +
    "1994-11-27,39,50;" +
    "1994-11-28,41,53;" +
    "1994-11-29,39,52;" +
    "1994-11-30,40,60;" +
    "1994-12-01,37,63;" +
    "1994-12-01,41,63;" +
    "1994-12-02,48,56;" +
    "1994-12-02,47,55;" +
    "1994-12-03,47,60;" +
    "1994-12-03,47,57;" +
    "1994-12-04,43,56;" +
    "1994-12-04,43,53;" +
    "1994-12-05,43,48;" +
    "1994-12-05,40,48;" +
    "1994-12-06,38,56;" +
    "1994-12-06,39,54;" +
    "1994-12-07,31,55;" +
    "1994-12-07,35,54;" +
    "1994-12-08,33,51;" +
    "1994-12-08,34,51;" +
    "1994-12-09,28,51;" +
    "1994-12-09,31,52;" +
    "1994-12-10,26,48;" +
    "1994-12-10,29,49;" +
    "1994-12-11,35,56;" +
    "1994-12-11,39,56;" +
    "1994-12-12,44,53;" +
    "1994-12-12,40,52;" +
    "1994-12-13,36,52;" +
    "1994-12-13,38,52;" +
    "1994-12-14,37,52;" +
    "1994-12-14,38,49;" +
    "1994-12-15,44,50;" +
    "1994-12-15,40,52;" +
    "1994-12-16,35,57;" +
    "1994-12-16,38,59;" +
    "1994-12-17,39,45;" +
    "1994-12-17,40,44;" +
    "1994-12-18,41,59;" +
    "1994-12-18,41,58;" +
    "1994-12-19,40,50;" +
    "1994-12-19,39,48;" +
    "1994-12-20,41,46;" +
    "1994-12-20,39,45;" +
    "1994-12-21,40,44;" +
    "1994-12-21,37,45;" +
    "1994-12-22,34,46;" +
    "1994-12-22,33,47;" +
    "1994-12-23,32,47;" +
    "1994-12-23,31,50;" +
    "1994-12-24,36,54;" +
    "1994-12-24,37,51;" +
    "1994-12-25,31,59;" +
    "1994-12-25,35,60;" +
    "1994-12-26,36,57;" +
    "1994-12-26,39,57;" +
    "1994-12-27,36,55;" +
    "1994-12-27,40,54;" +
    "1994-12-28,41,55;" +
    "1994-12-28,38,54;" +
    "1994-12-29,32,54;" +
    "1994-12-29,36,54;" +
    "1994-12-30,29,50;" +
    "1994-12-30,32,50;" +
    "1994-12-31,33,46;" +
    "1994-12-31,37,46;"