export const data = "2019-01-01,32,54;" +
    "2019-01-01,38,53;" +
    "2019-01-02,28,54;" +
    "2019-01-02,28,53;" +
    "2019-01-03,28,52;" +
    "2019-01-03,28,51;" +
    "2019-01-04,31,57;" +
    "2019-01-04,31,56;" +
    "2019-01-05,36,59;" +
    "2019-01-05,37,60;" +
    "2019-01-06,37,56;" +
    "2019-01-06,39,52;" +
    "2019-01-07,48,60;" +
    "2019-01-07,47,59;" +
    "2019-01-08,47,58;" +
    "2019-01-08,46,57;" +
    "2019-01-09,45,62;" +
    "2019-01-09,51,61;" +
    "2019-01-10,39,60;" +
    "2019-01-10,38,59;" +
    "2019-01-11,45,57;" +
    "2019-01-11,44,56;" +
    "2019-01-12,42,59;" +
    "2019-01-12,44,59;" +
    "2019-01-13,37,56;" +
    "2019-01-13,37,55;" +
    "2019-01-14,42,57;" +
    "2019-01-14,45,55;" +
    "2019-01-15,43,57;" +
    "2019-01-15,46,52;" +
    "2019-01-16,51,63;" +
    "2019-01-16,50,60;" +
    "2019-01-17,50,58;" +
    "2019-01-17,51,61;" +
    "2019-01-18,49,63;" +
    "2019-01-18,48,62;" +
    "2019-01-19,48,68;" +
    "2019-01-19,47,68;" +
    "2019-01-20,44,62;" +
    "2019-01-20,48,62;" +
    "2019-01-21,38,58;" +
    "2019-01-21,42,59;" +
    "2019-01-22,38,59;" +
    "2019-01-22,38,57;" +
    "2019-01-23,33,59;" +
    "2019-01-23,33,58;" +
    "2019-01-24,35,63;" +
    "2019-01-24,36,61;" +
    "2019-01-25,36,63;" +
    "2019-01-25,36,63;" +
    "2019-01-26,40,61;" +
    "2019-01-26,40,59;" +
    "2019-01-27,39,66;" +
    "2019-01-27,40,65;" +
    "2019-01-28,44,67;" +
    "2019-01-28,44,66;" +
    "2019-01-29,44,65;" +
    "2019-01-29,44,65;" +
    "2019-01-30,46,67;" +
    "2019-01-30,45,67;" +
    "2019-01-31,51,63;" +
    "2019-01-31,52,63;" +
    "2019-02-01,46,58;" +
    "2019-02-01,47,57;" +
    "2019-02-02,50,59;" +
    "2019-02-02,49,58;" +
    "2019-02-03,45,55;" +
    "2019-02-03,48,54;" +
    "2019-02-04,39,51;" +
    "2019-02-04,41,49;" +
    "2019-02-05,32,49;" +
    "2019-02-05,34,49;" +
    "2019-02-06,30,55;" +
    "2019-02-06,29,55;" +
    "2019-02-07,29,52;" +
    "2019-02-07,29,52;" +
    "2019-02-08,37,51;" +
    "2019-02-08,36,50;" +
    "2019-02-09,38,53;" +
    "2019-02-09,38,53;" +
    "2019-02-10,33,50;" +
    "2019-02-10,35,51;" +
    "2019-02-11,30,54;" +
    "2019-02-11,30,53;" +
    "2019-02-12,33,48;" +
    "2019-02-12,33,48;" +
    "2019-02-13,44,64;" +
    "2019-02-13,43,64;" +
    "2019-02-14,47,65;" +
    "2019-02-14,47,64;" +
    "2019-02-15,41,52;" +
    "2019-02-15,40,50;" +
    "2019-02-16,40,53;" +
    "2019-02-16,40,52;" +
    "2019-02-17,33,53;" +
    "2019-02-17,38,54;" +
    "2019-02-18,29,53;" +
    "2019-02-18,29,53;" +
    "2019-02-19,27,56;" +
    "2019-02-19,27,57;" +
    "2019-02-20,41,57;" +
    "2019-02-20,43,55;" +
    "2019-02-21,35,53;" +
    "2019-02-21,34,54;" +
    "2019-02-22,33,56;" +
    "2019-02-22,35,56;" +
    "2019-02-23,29,57;" +
    "2019-02-23,29,58;" +
    "2019-02-24,40,59;" +
    "2019-02-24,41,60;" +
    "2019-02-25,42,57;" +
    "2019-02-25,42,56;" +
    "2019-02-26,52,58;" +
    "2019-02-26,52,57;" +
    "2019-02-27,48,62;" +
    "2019-02-27,51,64;" +
    "2019-02-28,46,57;" +
    "2019-02-28,45,58;" +
    "2019-03-01,43,56;" +
    "2019-03-01,42,55;" +
    "2019-03-02,49,56;" +
    "2019-03-02,47,54;" +
    "2019-03-03,49,60;" +
    "2019-03-03,47,59;" +
    "2019-03-04,47,57;" +
    "2019-03-04,45,57;" +
    "2019-03-05,48,59;" +
    "2019-03-05,47,56;" +
    "2019-03-06,49,62;" +
    "2019-03-06,52,58;" +
    "2019-03-07,45,52;" +
    "2019-03-07,45,53;" +
    "2019-03-08,40,55;" +
    "2019-03-08,40,56;" +
    "2019-03-09,38,53;" +
    "2019-03-09,37,54;" +
    "2019-03-10,40,57;" +
    "2019-03-10,41,57;" +
    "2019-03-11,35,65;" +
    "2019-03-11,34,65;" +
    "2019-03-12,37,61;" +
    "2019-03-12,37,63;" +
    "2019-03-13,39,62;" +
    "2019-03-13,39,63;" +
    "2019-03-14,35,63;" +
    "2019-03-14,36,63;" +
    "2019-03-15,35,66;" +
    "2019-03-15,35,66;" +
    "2019-03-16,37,70;" +
    "2019-03-16,37,70;" +
    "2019-03-17,40,69;" +
    "2019-03-17,40,69;" +
    "2019-03-18,44,74;" +
    "2019-03-18,43,74;" +
    "2019-03-19,45,69;" +
    "2019-03-19,45,72;" +
    "2019-03-20,43,61;" +
    "2019-03-20,47,64;" +
    "2019-03-21,39,63;" +
    "2019-03-21,38,65;" +
    "2019-03-22,42,58;" +
    "2019-03-22,46,60;" +
    "2019-03-23,42,61;" +
    "2019-03-23,49,63;" +
    "2019-03-24,36,62;" +
    "2019-03-24,36,62;" +
    "2019-03-25,43,66;" +
    "2019-03-25,44,66;" +
    "2019-03-26,47,67;" +
    "2019-03-26,48,66;" +
    "2019-03-27,48,63;" +
    "2019-03-27,51,64;" +
    "2019-03-28,42,63;" +
    "2019-03-28,44,64;" +
    "2019-03-29,41,63;" +
    "2019-03-29,41,65;" +
    "2019-03-30,39,69;" +
    "2019-03-30,39,70;" +
    "2019-03-31,40,74;" +
    "2019-03-31,41,75;" +
    "2019-04-01,47,65;" +
    "2019-04-01,47,69;" +
    "2019-04-02,49,62;" +
    "2019-04-02,49,64;" +
    "2019-04-03,52,63;" +
    "2019-04-03,50,63;" +
    "2019-04-04,49,63;" +
    "2019-04-04,52,64;" +
    "2019-04-05,47,63;" +
    "2019-04-05,47,63;" +
    "2019-04-06,49,69;" +
    "2019-04-06,48,70;" +
    "2019-04-07,53,75;" +
    "2019-04-07,51,76;" +
    "2019-04-08,52,72;" +
    "2019-04-08,52,73;" +
    "2019-04-09,48,67;" +
    "2019-04-09,50,67;" +
    "2019-04-10,42,71;" +
    "2019-04-10,42,72;" +
    "2019-04-11,43,64;" +
    "2019-04-11,44,68;" +
    "2019-04-12,41,72;" +
    "2019-04-12,42,74;" +
    "2019-04-13,43,78;" +
    "2019-04-13,44,78;" +
    "2019-04-14,47,67;" +
    "2019-04-14,46,74;" +
    "2019-04-15,47,62;" +
    "2019-04-15,47,63;" +
    "2019-04-16,46,66;" +
    "2019-04-16,48,66;" +
    "2019-04-17,43,76;" +
    "2019-04-17,42,77;" +
    "2019-04-18,46,80;" +
    "2019-04-18,47,81;" +
    "2019-04-19,51,82;" +
    "2019-04-19,51,83;" +
    "2019-04-20,50,64;" +
    "2019-04-20,52,73;" +
    "2019-04-21,43,73;" +
    "2019-04-21,42,73;" +
    "2019-04-22,43,84;" +
    "2019-04-22,43,84;" +
    "2019-04-23,49,88;" +
    "2019-04-23,51,86;" +
    "2019-04-24,57,91;" +
    "2019-04-24,56,92;" +
    "2019-04-25,53,86;" +
    "2019-04-25,55,87;" +
    "2019-04-26,49,85;" +
    "2019-04-26,48,87;" +
    "2019-04-27,51,75;" +
    "2019-04-27,50,81;" +
    "2019-04-28,50,73;" +
    "2019-04-28,49,75;" +
    "2019-04-29,50,72;" +
    "2019-04-29,49,73;" +
    "2019-04-30,50,68;" +
    "2019-04-30,51,69;" +
    "2019-05-01,45,76;" +
    "2019-05-01,44,77;" +
    "2019-05-02,44,79;" +
    "2019-05-02,45,81;" +
    "2019-05-03,44,81;" +
    "2019-05-03,44,82;" +
    "2019-05-04,45,76;" +
    "2019-05-04,44,77;" +
    "2019-05-05,48,65;" +
    "2019-05-05,47,68;" +
    "2019-05-06,51,66;" +
    "2019-05-06,51,67;" +
    "2019-05-07,51,70;" +
    "2019-05-07,53,72;" +
    "2019-05-08,53,75;" +
    "2019-05-08,51,77;" +
    "2019-05-09,53,68;" +
    "2019-05-09,53,70;" +
    "2019-05-10,54,78;" +
    "2019-05-10,53,80;" +
    "2019-05-11,51,76;" +
    "2019-05-11,51,77;" +
    "2019-05-12,51,75;" +
    "2019-05-12,51,76;" +
    "2019-05-13,49,77;" +
    "2019-05-13,48,79;" +
    "2019-05-14,53,69;" +
    "2019-05-14,51,71;" +
    "2019-05-15,52,68;" +
    "2019-05-15,55,66;" +
    "2019-05-16,50,59;" +
    "2019-05-16,49,62;" +
    "2019-05-17,49,64;" +
    "2019-05-17,48,65;" +
    "2019-05-18,46,59;" +
    "2019-05-18,44,61;" +
    "2019-05-19,47,60;" +
    "2019-05-19,46,61;" +
    "2019-05-20,47,65;" +
    "2019-05-20,46,65;" +
    "2019-05-21,50,62;" +
    "2019-05-21,49,62;" +
    "2019-05-22,49,72;" +
    "2019-05-22,49,73;" +
    "2019-05-23,50,74;" +
    "2019-05-23,49,76;" +
    "2019-05-24,52,75;" +
    "2019-05-24,50,76;" +
    "2019-05-25,51,72;" +
    "2019-05-25,52,73;" +
    "2019-05-26,50,62;" +
    "2019-05-26,50,66;" +
    "2019-05-27,48,66;" +
    "2019-05-27,48,66;" +
    "2019-05-28,53,75;" +
    "2019-05-28,51,77;" +
    "2019-05-29,52,81;" +
    "2019-05-29,51,82;" +
    "2019-05-30,52,71;" +
    "2019-05-30,52,76;" +
    "2019-05-31,52,82;" +
    "2019-05-31,51,84;" +
    "2019-06-01,54,86;" +
    "2019-06-01,54,88;" +
    "2019-06-02,54,79;" +
    "2019-06-02,55,80;" +
    "2019-06-03,54,86;" +
    "2019-06-03,54,87;" +
    "2019-06-04,58,94;" +
    "2019-06-04,56,96;" +
    "2019-06-05,58,94;" +
    "2019-06-05,61,94;" +
    "2019-06-06,50,73;" +
    "2019-06-06,53,85;" +
    "2019-06-07,47,79;" +
    "2019-06-07,47,78;" +
    "2019-06-08,49,86;" +
    "2019-06-08,50,85;" +
    "2019-06-09,55,93;" +
    "2019-06-09,58,92;" +
    "2019-06-10,64,102;" +
    "2019-06-10,66,100;" +
    "2019-06-11,64,104;" +
    "2019-06-11,65,102;" +
    "2019-06-12,61,98;" +
    "2019-06-12,70,98;" +
    "2019-06-13,56,82;" +
    "2019-06-13,57,93;" +
    "2019-06-14,53,79;" +
    "2019-06-14,55,79;" +
    "2019-06-15,52,72;" +
    "2019-06-15,52,73;" +
    "2019-06-16,54,78;" +
    "2019-06-16,53,80;" +
    "2019-06-17,54,87;" +
    "2019-06-17,53,88;" +
    "2019-06-18,60,93;" +
    "2019-06-18,59,93;" +
    "2019-06-19,57,86;" +
    "2019-06-19,57,88;" +
    "2019-06-20,56,80;" +
    "2019-06-20,57,81;" +
    "2019-06-21,54,83;" +
    "2019-06-21,53,83;" +
    "2019-06-22,52,94;" +
    "2019-06-22,54,93;" +
    "2019-06-23,57,96;" +
    "2019-06-23,57,95;" +
    "2019-06-24,59,89;" +
    "2019-06-24,58,90;" +
    "2019-06-25,56,85;" +
    "2019-06-25,56,85;" +
    "2019-06-26,54,72;" +
    "2019-06-26,54,77;" +
    "2019-06-27,54,75;" +
    "2019-06-27,54,76;" +
    "2019-06-28,55,81;" +
    "2019-06-28,56,82;" +
    "2019-06-29,50,83;" +
    "2019-06-29,47,84;" +
    "2019-06-30,54,84;" +
    "2019-06-30,53,84;" +
    "2019-07-01,53,81;" +
    "2019-07-01,51,82;" +
    "2019-07-02,55,78;" +
    "2019-07-02,53,78;" +
    "2019-07-03,54,83;" +
    "2019-07-03,52,83;" +
    "2019-07-04,54,85;" +
    "2019-07-04,52,87;" +
    "2019-07-05,55,90;" +
    "2019-07-05,54,90;" +
    "2019-07-06,54,88;" +
    "2019-07-06,53,88;" +
    "2019-07-07,54,80;" +
    "2019-07-07,53,82;" +
    "2019-07-08,53,73;" +
    "2019-07-08,54,73;" +
    "2019-07-09,53,81;" +
    "2019-07-09,52,80;" +
    "2019-07-10,59,82;" +
    "2019-07-10,61,83;" +
    "2019-07-11,58,87;" +
    "2019-07-11,57,87;" +
    "2019-07-12,56,90;" +
    "2019-07-12,55,90;" +
    "2019-07-13,55,92;" +
    "2019-07-13,54,92;" +
    "2019-07-14,58,87;" +
    "2019-07-14,57,88;" +
    "2019-07-15,58,91;" +
    "2019-07-15,59,89;" +
    "2019-07-16,58,89;" +
    "2019-07-16,57,87;" +
    "2019-07-17,56,92;" +
    "2019-07-17,54,92;" +
    "2019-07-18,58,87;" +
    "2019-07-18,59,86;" +
    "2019-07-19,58,87;" +
    "2019-07-19,57,87;" +
    "2019-07-20,57,81;" +
    "2019-07-20,57,82;" +
    "2019-07-21,56,91;" +
    "2019-07-21,55,91;" +
    "2019-07-22,63,96;" +
    "2019-07-22,62,96;" +
    "2019-07-23,62,90;" +
    "2019-07-23,61,91;" +
    "2019-07-24,58,101;" +
    "2019-07-24,58,100;" +
    "2019-07-25,65,97;" +
    "2019-07-25,65,97;" +
    "2019-07-26,58,91;" +
    "2019-07-26,58,92;" +
    "2019-07-27,57,101;" +
    "2019-07-27,57,101;" +
    "2019-07-28,64,102;" +
    "2019-07-28,64,101;" +
    "2019-07-29,56,84;" +
    "2019-07-29,57,91;" +
    "2019-07-30,56,81;" +
    "2019-07-30,54,81;" +
    "2019-07-31,53,90;" +
    "2019-07-31,51,90;" +
    "2019-08-01,58,77;" +
    "2019-08-01,56,84;" +
    "2019-08-02,59,88;" +
    "2019-08-02,58,88;" +
    "2019-08-03,56,96;" +
    "2019-08-03,56,96;" +
    "2019-08-04,59,91;" +
    "2019-08-04,58,91;" +
    "2019-08-05,58,93;" +
    "2019-08-05,58,93;" +
    "2019-08-06,59,92;" +
    "2019-08-06,59,92;" +
    "2019-08-07,58,83;" +
    "2019-08-07,56,83;" +
    "2019-08-08,58,76;" +
    "2019-08-08,57,76;" +
    "2019-08-09,59,82;" +
    "2019-08-09,58,81;" +
    "2019-08-10,59,81;" +
    "2019-08-10,63,80;" +
    "2019-08-11,58,89;" +
    "2019-08-11,55,88;" +
    "2019-08-12,58,96;" +
    "2019-08-12,57,96;" +
    "2019-08-13,57,99;" +
    "2019-08-13,56,99;" +
    "2019-08-14,60,104;" +
    "2019-08-14,60,103;" +
    "2019-08-15,64,106;" +
    "2019-08-15,64,105;" +
    "2019-08-16,63,101;" +
    "2019-08-16,63,101;" +
    "2019-08-17,60,86;" +
    "2019-08-17,58,93;" +
    "2019-08-18,54,84;" +
    "2019-08-18,52,83;" +
    "2019-08-19,56,78;" +
    "2019-08-19,57,77;" +
    "2019-08-20,57,80;" +
    "2019-08-20,57,80;" +
    "2019-08-21,56,93;" +
    "2019-08-21,54,91;" +
    "2019-08-22,66,95;" +
    "2019-08-22,65,94;" +
    "2019-08-23,61,94;" +
    "2019-08-23,60,93;" +
    "2019-08-24,60,92;" +
    "2019-08-24,60,92;" +
    "2019-08-25,63,95;" +
    "2019-08-25,62,95;" +
    "2019-08-26,62,98;" +
    "2019-08-26,61,98;" +
    "2019-08-27,62,97;" +
    "2019-08-27,60,98;" +
    "2019-08-28,60,83;" +
    "2019-08-28,61,87;" +
    "2019-08-29,62,82;" +
    "2019-08-29,62,80;" +
    "2019-08-30,57,89;" +
    "2019-08-30,57,89;" +
    "2019-08-31,55,97;" +
    "2019-08-31,55,97;" +
    "2019-09-01,58,96;" +
    "2019-09-01,58,95;" +
    "2019-09-02,60,92;" +
    "2019-09-02,61,91;" +
    "2019-09-03,57,90;" +
    "2019-09-03,56,89;" +
    "2019-09-04,60,95;" +
    "2019-09-04,59,95;" +
    "2019-09-05,59,83;" +
    "2019-09-05,58,85;" +
    "2019-09-06,57,84;" +
    "2019-09-06,56,84;" +
    "2019-09-07,57,71;" +
    "2019-09-07,57,79;" +
    "2019-09-08,55,80;" +
    "2019-09-08,55,79;" +
    "2019-09-09,55,81;" +
    "2019-09-09,54,79;" +
    "2019-09-10,58,76;" +
    "2019-09-10,57,76;" +
    "2019-09-11,53,88;" +
    "2019-09-11,52,87;" +
    "2019-09-12,56,94;" +
    "2019-09-12,55,92;" +
    "2019-09-13,58,100;" +
    "2019-09-13,57,97;" +
    "2019-09-14,57,98;" +
    "2019-09-14,58,97;" +
    "2019-09-15,54,81;" +
    "2019-09-15,54,85;" +
    "2019-09-16,58,73;" +
    "2019-09-16,59,72;" +
    "2019-09-17,51,80;" +
    "2019-09-17,50,79;" +
    "2019-09-18,56,76;" +
    "2019-09-18,59,75;" +
    "2019-09-19,51,80;" +
    "2019-09-19,51,77;" +
    "2019-09-20,50,85;" +
    "2019-09-20,50,83;" +
    "2019-09-21,52,88;" +
    "2019-09-21,52,86;" +
    "2019-09-22,53,88;" +
    "2019-09-22,53,87;" +
    "2019-09-23,57,88;" +
    "2019-09-23,55,86;" +
    "2019-09-24,58,98;" +
    "2019-09-24,58,96;" +
    "2019-09-25,61,97;" +
    "2019-09-25,62,96;" +
    "2019-09-26,63,87;" +
    "2019-09-26,63,91;" +
    "2019-09-27,55,74;" +
    "2019-09-27,62,76;" +
    "2019-09-28,51,70;" +
    "2019-09-28,52,69;" +
    "2019-09-29,44,68;" +
    "2019-09-29,43,67;" +
    "2019-09-30,47,68;" +
    "2019-09-30,46,67;" +
    "2019-10-01,41,73;" +
    "2019-10-01,41,72;" +
    "2019-10-02,43,78;" +
    "2019-10-02,42,78;" +
    "2019-10-03,43,75;" +
    "2019-10-03,43,74;" +
    "2019-10-04,43,77;" +
    "2019-10-04,44,74;" +
    "2019-10-05,44,82;" +
    "2019-10-05,44,80;" +
    "2019-10-06,49,85;" +
    "2019-10-06,51,83;" +
    "2019-10-07,52,91;" +
    "2019-10-07,53,88;" +
    "2019-10-08,49,90;" +
    "2019-10-08,50,88;" +
    "2019-10-09,47,78;" +
    "2019-10-09,48,76;" +
    "2019-10-10,47,76;" +
    "2019-10-10,55,74;" +
    "2019-10-11,44,79;" +
    "2019-10-11,44,78;" +
    "2019-10-12,42,81;" +
    "2019-10-12,43,78;" +
    "2019-10-13,46,79;" +
    "2019-10-13,45,78;" +
    "2019-10-14,42,80;" +
    "2019-10-14,41,80;" +
    "2019-10-15,44,81;" +
    "2019-10-15,43,79;" +
    "2019-10-16,46,74;" +
    "2019-10-16,45,75;" +
    "2019-10-17,48,72;" +
    "2019-10-17,54,71;" +
    "2019-10-18,43,71;" +
    "2019-10-18,43,69;" +
    "2019-10-19,46,71;" +
    "2019-10-19,45,70;" +
    "2019-10-20,50,76;" +
    "2019-10-20,50,76;" +
    "2019-10-21,47,85;" +
    "2019-10-21,47,84;" +
    "2019-10-22,53,87;" +
    "2019-10-22,58,84;" +
    "2019-10-23,54,84;" +
    "2019-10-23,57,83;" +
    "2019-10-24,55,88;" +
    "2019-10-24,60,85;" +
    "2019-10-25,51,92;" +
    "2019-10-25,51,90;" +
    "2019-10-26,48,85;" +
    "2019-10-26,49,84;" +
    "2019-10-27,52,70;" +
    "2019-10-27,53,75;" +
    "2019-10-28,43,72;" +
    "2019-10-28,46,75;" +
    "2019-10-29,38,70;" +
    "2019-10-29,38,69;" +
    "2019-10-30,42,66;" +
    "2019-10-30,39,65;" +
    "2019-10-31,39,72;" +
    "2019-10-31,38,70;" +
    "2019-11-01,37,74;" +
    "2019-11-01,35,71;" +
    "2019-11-02,37,75;" +
    "2019-11-02,36,73;" +
    "2019-11-03,38,81;" +
    "2019-11-03,38,80;" +
    "2019-11-04,42,79;" +
    "2019-11-04,41,76;" +
    "2019-11-05,43,79;" +
    "2019-11-05,42,77;" +
    "2019-11-06,44,77;" +
    "2019-11-06,44,78;" +
    "2019-11-07,42,77;" +
    "2019-11-07,42,75;" +
    "2019-11-08,40,78;" +
    "2019-11-08,41,78;" +
    "2019-11-09,41,79;" +
    "2019-11-09,40,79;" +
    "2019-11-10,42,79;" +
    "2019-11-10,42,78;" +
    "2019-11-11,45,80;" +
    "2019-11-11,44,78;" +
    "2019-11-12,46,78;" +
    "2019-11-12,45,76;" +
    "2019-11-13,46,75;" +
    "2019-11-13,46,74;" +
    "2019-11-14,51,67;" +
    "2019-11-14,50,65;" +
    "2019-11-15,52,71;" +
    "2019-11-15,51,69;" +
    "2019-11-16,46,76;" +
    "2019-11-16,45,73;" +
    "2019-11-17,44,74;" +
    "2019-11-17,44,72;" +
    "2019-11-18,43,76;" +
    "2019-11-18,43,74;" +
    "2019-11-19,48,64;" +
    "2019-11-19,48,63;" +
    "2019-11-20,43,68;" +
    "2019-11-20,44,67;" +
    "2019-11-21,38,65;" +
    "2019-11-21,38,64;" +
    "2019-11-22,38,67;" +
    "2019-11-22,39,66;" +
    "2019-11-23,39,71;" +
    "2019-11-23,38,71;" +
    "2019-11-24,35,72;" +
    "2019-11-24,36,71;" +
    "2019-11-25,36,65;" +
    "2019-11-25,37,63;" +
    "2019-11-26,38,53;" +
    "2019-11-26,37,53;" +
    "2019-11-27,39,49;" +
    "2019-11-27,41,49;" +
    "2019-11-28,36,45;" +
    "2019-11-28,34,43;" +
    "2019-11-29,32,54;" +
    "2019-11-29,31,52;" +
    "2019-11-30,33,51;" +
    "2019-11-30,34,49;" +
    "2019-12-01,48,57;" +
    "2019-12-01,48,55;" +
    "2019-12-02,50,56;" +
    "2019-12-02,49,55;" +
    "2019-12-03,50,61;" +
    "2019-12-03,49,59;" +
    "2019-12-04,49,55;" +
    "2019-12-04,48,53;" +
    "2019-12-05,47,57;" +
    "2019-12-05,46,55;" +
    "2019-12-06,45,67;" +
    "2019-12-06,44,65;" +
    "2019-12-07,54,66;" +
    "2019-12-07,54,65;" +
    "2019-12-08,47,62;" +
    "2019-12-08,51,62;" +
    "2019-12-09,44,56;" +
    "2019-12-09,43,54;" +
    "2019-12-10,43,58;" +
    "2019-12-10,43,56;" +
    "2019-12-11,51,59;" +
    "2019-12-11,49,57;" +
    "2019-12-12,56,66;" +
    "2019-12-12,53,65;" +
    "2019-12-13,54,60;" +
    "2019-12-13,52,59;" +
    "2019-12-14,45,58;" +
    "2019-12-14,48,56;" +
    "2019-12-15,37,57;" +
    "2019-12-15,37,56;" +
    "2019-12-16,34,55;" +
    "2019-12-16,35,54;" +
    "2019-12-17,36,52;" +
    "2019-12-17,35,50;" +
    "2019-12-18,44,58;" +
    "2019-12-18,40,58;" +
    "2019-12-19,44,62;" +
    "2019-12-19,47,61;" +
    "2019-12-20,41,62;" +
    "2019-12-20,40,61;" +
    "2019-12-21,41,58;" +
    "2019-12-21,40,56;" +
    "2019-12-22,37,60;" +
    "2019-12-22,41,60;" +
    "2019-12-23,35,54;" +
    "2019-12-23,35,53;" +
    "2019-12-24,34,53;" +
    "2019-12-24,33,52;" +
    "2019-12-25,44,53;" +
    "2019-12-25,42,52;" +
    "2019-12-26,37,58;" +
    "2019-12-26,41,58;" +
    "2019-12-27,30,60;" +
    "2019-12-27,29,59;" +
    "2019-12-28,30,60;" +
    "2019-12-28,30,61;" +
    "2019-12-29,39,55;" +
    "2019-12-29,39,55;" +
    "2019-12-30,40,61;" +
    "2019-12-30,41,60;" +
    "2019-12-31,37,64;" +
    "2019-12-31,39,62;"