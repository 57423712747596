export const data = "2004-01-01,37,52;" +
    "2004-01-01,47,52;" +
    "2004-01-02,33,50;" +
    "2004-01-02,34,52;" +
    "2004-01-03,27,49;" +
    "2004-01-03,29,50;" +
    "2004-01-04,25,51;" +
    "2004-01-04,27,52;" +
    "2004-01-05,34,52;" +
    "2004-01-05,36,51;" +
    "2004-01-06,41,49;" +
    "2004-01-06,38,49;" +
    "2004-01-07,45,58;" +
    "2004-01-07,44,58;" +
    "2004-01-08,44,64;" +
    "2004-01-08,46,63;" +
    "2004-01-09,40,68;" +
    "2004-01-09,41,67;" +
    "2004-01-10,44,60;" +
    "2004-01-10,47,59;" +
    "2004-01-11,39,62;" +
    "2004-01-11,42,59;" +
    "2004-01-12,44,55;" +
    "2004-01-12,44,55;" +
    "2004-01-13,42,49;" +
    "2004-01-13,42,49;" +
    "2004-01-14,41,48;" +
    "2004-01-14,41,48;" +
    "2004-01-15,40,60;" +
    "2004-01-15,41,62;" +
    "2004-01-16,46,50;" +
    "2004-01-16,45,50;" +
    "2004-01-17,43,49;" +
    "2004-01-17,44,48;" +
    "2004-01-18,35,52;" +
    "2004-01-18,43,52;" +
    "2004-01-19,37,50;" +
    "2004-01-19,37,48;" +
    "2004-01-20,34,53;" +
    "2004-01-20,44,53;" +
    "2004-01-21,34,59;" +
    "2004-01-21,38,59;" +
    "2004-01-22,29,58;" +
    "2004-01-22,30,58;" +
    "2004-01-23,28,54;" +
    "2004-01-23,30,54;" +
    "2004-01-24,46,53;" +
    "2004-01-24,44,52;" +
    "2004-01-25,35,56;" +
    "2004-01-25,40,58;" +
    "2004-01-26,36,54;" +
    "2004-01-26,38,54;" +
    "2004-01-27,46,57;" +
    "2004-01-27,46,57;" +
    "2004-01-28,39,57;" +
    "2004-01-28,42,59;" +
    "2004-01-29,38,53;" +
    "2004-01-29,39,55;" +
    "2004-01-30,43,56;" +
    "2004-01-30,48,57;" +
    "2004-01-31,32,57;" +
    "2004-01-31,35,58;" +
    "2004-02-01,35,53;" +
    "2004-02-01,38,54;" +
    "2004-02-02,41,56;" +
    "2004-02-02,45,57;" +
    "2004-02-03,40,54;" +
    "2004-02-03,42,55;" +
    "2004-02-04,36,57;" +
    "2004-02-04,45,58;" +
    "2004-02-05,33,61;" +
    "2004-02-05,34,60;" +
    "2004-02-06,34,61;" +
    "2004-02-06,38,63;" +
    "2004-02-07,33,59;" +
    "2004-02-07,35,61;" +
    "2004-02-08,31,58;" +
    "2004-02-08,36,57;" +
    "2004-02-09,33,60;" +
    "2004-02-09,36,60;" +
    "2004-02-10,33,60;" +
    "2004-02-10,40,60;" +
    "2004-02-11,30,61;" +
    "2004-02-11,32,61;" +
    "2004-02-12,32,63;" +
    "2004-02-12,35,64;" +
    "2004-02-13,34,61;" +
    "2004-02-13,38,63;" +
    "2004-02-14,34,62;" +
    "2004-02-14,38,63;" +
    "2004-02-15,39,65;" +
    "2004-02-15,43,65;" +
    "2004-02-16,50,62;" +
    "2004-02-16,50,62;" +
    "2004-02-17,56,66;" +
    "2004-02-17,53,67;" +
    "2004-02-18,43,60;" +
    "2004-02-18,51,62;" +
    "2004-02-19,36,54;" +
    "2004-02-19,39,56;" +
    "2004-02-20,45,54;" +
    "2004-02-20,45,54;" +
    "2004-02-21,44,54;" +
    "2004-02-21,45,54;" +
    "2004-02-22,40,56;" +
    "2004-02-22,47,55;" +
    "2004-02-23,35,59;" +
    "2004-02-23,38,60;" +
    "2004-02-24,48,60;" +
    "2004-02-24,48,62;" +
    "2004-02-25,47,60;" +
    "2004-02-25,52,62;" +
    "2004-02-26,40,55;" +
    "2004-02-26,46,54;" +
    "2004-02-27,36,56;" +
    "2004-02-27,40,58;" +
    "2004-02-28,33,59;" +
    "2004-02-28,35,62;" +
    "2004-02-29,34,55;" +
    "2004-02-29,37,56;" +
    "2004-03-01,43,54;" +
    "2004-03-01,46,53;" +
    "2004-03-02,41,64;" +
    "2004-03-02,42,64;" +
    "2004-03-03,38,62;" +
    "2004-03-03,46,62;" +
    "2004-03-04,34,65;" +
    "2004-03-04,36,68;" +
    "2004-03-05,38,65;" +
    "2004-03-05,40,68;" +
    "2004-03-06,42,67;" +
    "2004-03-06,43,69;" +
    "2004-03-07,40,74;" +
    "2004-03-07,44,74;" +
    "2004-03-08,43,75;" +
    "2004-03-08,45,75;" +
    "2004-03-09,53,79;" +
    "2004-03-09,47,81;" +
    "2004-03-10,49,79;" +
    "2004-03-10,58,80;" +
    "2004-03-11,44,78;" +
    "2004-03-11,52,79;" +
    "2004-03-12,41,79;" +
    "2004-03-12,46,81;" +
    "2004-03-13,44,81;" +
    "2004-03-13,49,82;" +
    "2004-03-14,45,82;" +
    "2004-03-14,48,85;" +
    "2004-03-15,48,81;" +
    "2004-03-15,53,;" +
    "2004-03-16,52,81;" +
    "2004-03-16,,;" +
    "2004-03-17,47,82;" +
    "2004-03-17,,;" +
    "2004-03-18,44,84;" +
    "2004-03-18,49,86;" +
    "2004-03-19,41,78;" +
    "2004-03-19,46,81;" +
    "2004-03-20,40,80;" +
    "2004-03-20,45,80;" +
    "2004-03-21,49,73;" +
    "2004-03-21,52,76;" +
    "2004-03-22,49,68;" +
    "2004-03-22,49,71;" +
    "2004-03-23,50,64;" +
    "2004-03-23,50,67;" +
    "2004-03-24,50,69;" +
    "2004-03-24,51,70;" +
    "2004-03-25,43,61;" +
    "2004-03-25,46,61;" +
    "2004-03-26,37,61;" +
    "2004-03-26,41,62;" +
    "2004-03-27,45,67;" +
    "2004-03-27,49,68;" +
    "2004-03-28,42,73;" +
    "2004-03-28,49,73;" +
    "2004-03-29,47,81;" +
    "2004-03-29,49,83;" +
    "2004-03-30,47,60;" +
    "2004-03-30,51,68;" +
    "2004-03-31,41,66;" +
    "2004-03-31,45,68;" +
    "2004-04-01,37,69;" +
    "2004-04-01,40,70;" +
    "2004-04-02,46,71;" +
    "2004-04-02,53,71;" +
    "2004-04-03,39,76;" +
    "2004-04-03,44,78;" +
    "2004-04-04,44,63;" +
    "2004-04-04,45,65;" +
    "2004-04-05,47,61;" +
    "2004-04-05,47,63;" +
    "2004-04-06,46,63;" +
    "2004-04-06,47,65;" +
    "2004-04-07,41,74;" +
    "2004-04-07,43,76;" +
    "2004-04-08,47,77;" +
    "2004-04-08,47,79;" +
    "2004-04-09,49,82;" +
    "2004-04-09,48,85;" +
    "2004-04-10,44,85;" +
    "2004-04-10,48,86;" +
    "2004-04-11,47,74;" +
    "2004-04-11,47,82;" +
    "2004-04-12,49,69;" +
    "2004-04-12,49,71;" +
    "2004-04-13,48,63;" +
    "2004-04-13,48,64;" +
    "2004-04-14,48,66;" +
    "2004-04-14,49,67;" +
    "2004-04-15,43,61;" +
    "2004-04-15,49,63;" +
    "2004-04-16,38,63;" +
    "2004-04-16,42,64;" +
    "2004-04-17,41,62;" +
    "2004-04-17,45,63;" +
    "2004-04-18,46,59;" +
    "2004-04-18,45,62;" +
    "2004-04-19,48,64;" +
    "2004-04-19,46,65;" +
    "2004-04-20,53,66;" +
    "2004-04-20,55,68;" +
    "2004-04-21,47,65;" +
    "2004-04-21,51,65;" +
    "2004-04-22,37,75;" +
    "2004-04-22,41,75;" +
    "2004-04-23,43,81;" +
    "2004-04-23,47,82;" +
    "2004-04-24,44,85;" +
    "2004-04-24,49,84;" +
    "2004-04-25,47,89;" +
    "2004-04-25,52,92;" +
    "2004-04-26,50,93;" +
    "2004-04-26,54,93;" +
    "2004-04-27,52,93;" +
    "2004-04-27,56,95;" +
    "2004-04-28,51,82;" +
    "2004-04-28,52,84;" +
    "2004-04-29,51,80;" +
    "2004-04-29,57,82;" +
    "2004-04-30,44,81;" +
    "2004-04-30,48,83;" +
    "2004-05-01,44,86;" +
    "2004-05-01,49,88;" +
    "2004-05-02,50,93;" +
    "2004-05-02,53,95;" +
    "2004-05-03,53,92;" +
    "2004-05-03,56,94;" +
    "2004-05-04,52,79;" +
    "2004-05-04,52,90;" +
    "2004-05-05,51,70;" +
    "2004-05-05,51,73;" +
    "2004-05-06,49,76;" +
    "2004-05-06,51,78;" +
    "2004-05-07,49,76;" +
    "2004-05-07,50,77;" +
    "2004-05-08,50,72;" +
    "2004-05-08,51,73;" +
    "2004-05-09,48,76;" +
    "2004-05-09,50,78;" +
    "2004-05-10,50,70;" +
    "2004-05-10,51,70;" +
    "2004-05-11,47,66;" +
    "2004-05-11,49,66;" +
    "2004-05-12,43,77;" +
    "2004-05-12,45,78;" +
    "2004-05-13,45,81;" +
    "2004-05-13,46,83;" +
    "2004-05-14,48,82;" +
    "2004-05-14,48,83;" +
    "2004-05-15,49,73;" +
    "2004-05-15,50,74;" +
    "2004-05-16,47,70;" +
    "2004-05-16,51,75;" +
    "2004-05-17,50,63;" +
    "2004-05-17,53,67;" +
    "2004-05-18,50,69;" +
    "2004-05-18,51,71;" +
    "2004-05-19,44,74;" +
    "2004-05-19,46,77;" +
    "2004-05-20,51,65;" +
    "2004-05-20,53,68;" +
    "2004-05-21,52,63;" +
    "2004-05-21,53,66;" +
    "2004-05-22,50,62;" +
    "2004-05-22,54,68;" +
    "2004-05-23,50,68;" +
    "2004-05-23,54,72;" +
    "2004-05-24,49,71;" +
    "2004-05-24,47,72;" +
    "2004-05-25,49,73;" +
    "2004-05-25,50,75;" +
    "2004-05-26,53,80;" +
    "2004-05-26,53,82;" +
    "2004-05-27,56,77;" +
    "2004-05-27,56,79;" +
    "2004-05-28,53,68;" +
    "2004-05-28,57,71;" +
    "2004-05-29,46,77;" +
    "2004-05-29,48,78;" +
    "2004-05-30,48,89;" +
    "2004-05-30,49,90;" +
    "2004-05-31,50,88;" +
    "2004-05-31,53,90;" +
    "2004-06-01,53,91;" +
    "2004-06-01,53,92;" +
    "2004-06-02,52,86;" +
    "2004-06-02,54,88;" +
    "2004-06-03,49,78;" +
    "2004-06-03,52,80;" +
    "2004-06-04,50,81;" +
    "2004-06-04,52,82;" +
    "2004-06-05,52,82;" +
    "2004-06-05,52,83;" +
    "2004-06-06,54,82;" +
    "2004-06-06,54,83;" +
    "2004-06-07,53,72;" +
    "2004-06-07,53,71;" +
    "2004-06-08,49,69;" +
    "2004-06-08,51,68;" +
    "2004-06-09,47,76;" +
    "2004-06-09,49,77;" +
    "2004-06-10,50,72;" +
    "2004-06-10,55,75;" +
    "2004-06-11,47,76;" +
    "2004-06-11,49,80;" +
    "2004-06-12,49,82;" +
    "2004-06-12,51,85;" +
    "2004-06-13,48,82;" +
    "2004-06-13,52,86;" +
    "2004-06-14,52,88;" +
    "2004-06-14,54,91;" +
    "2004-06-15,56,98;" +
    "2004-06-15,61,101;" +
    "2004-06-16,56,89;" +
    "2004-06-16,62,97;" +
    "2004-06-17,54,77;" +
    "2004-06-17,57,81;" +
    "2004-06-18,53,77;" +
    "2004-06-18,56,81;" +
    "2004-06-19,52,73;" +
    "2004-06-19,58,77;" +
    "2004-06-20,53,74;" +
    "2004-06-20,56,78;" +
    "2004-06-21,53,75;" +
    "2004-06-21,54,79;" +
    "2004-06-22,51,72;" +
    "2004-06-22,54,76;" +
    "2004-06-23,51,74;" +
    "2004-06-23,52,78;" +
    "2004-06-24,52,77;" +
    "2004-06-24,55,81;" +
    "2004-06-25,50,83;" +
    "2004-06-25,52,85;" +
    "2004-06-26,54,80;" +
    "2004-06-26,59,83;" +
    "2004-06-27,51,89;" +
    "2004-06-27,53,93;" +
    "2004-06-28,54,83;" +
    "2004-06-28,57,86;" +
    "2004-06-29,54,74;" +
    "2004-06-29,57,81;" +
    "2004-06-30,54,74;" +
    "2004-06-30,57,79;" +
    "2004-07-01,50,74;" +
    "2004-07-01,57,78;" +
    "2004-07-02,48,85;" +
    "2004-07-02,55,88;" +
    "2004-07-03,54,84;" +
    "2004-07-03,55,88;" +
    "2004-07-04,52,86;" +
    "2004-07-04,56,90;" +
    "2004-07-05,52,92;" +
    "2004-07-05,59,94;" +
    "2004-07-06,50,89;" +
    "2004-07-06,58,93;" +
    "2004-07-07,52,80;" +
    "2004-07-07,57,84;" +
    "2004-07-08,50,82;" +
    "2004-07-08,55,83;" +
    "2004-07-09,56,77;" +
    "2004-07-09,56,79;" +
    "2004-07-10,57,82;" +
    "2004-07-10,57,83;" +
    "2004-07-11,49,92;" +
    "2004-07-11,53,94;" +
    "2004-07-12,55,86;" +
    "2004-07-12,56,88;" +
    "2004-07-13,56,81;" +
    "2004-07-13,56,83;" +
    "2004-07-14,53,84;" +
    "2004-07-14,54,85;" +
    "2004-07-15,53,90;" +
    "2004-07-15,52,91;" +
    "2004-07-16,58,92;" +
    "2004-07-16,59,92;" +
    "2004-07-17,59,88;" +
    "2004-07-17,59,88;" +
    "2004-07-18,60,90;" +
    "2004-07-18,62,90;" +
    "2004-07-19,62,87;" +
    "2004-07-19,62,88;" +
    "2004-07-20,59,86;" +
    "2004-07-20,60,88;" +
    "2004-07-21,58,97;" +
    "2004-07-21,58,98;" +
    "2004-07-22,58,90;" +
    "2004-07-22,58,93;" +
    "2004-07-23,58,84;" +
    "2004-07-23,59,86;" +
    "2004-07-24,61,82;" +
    "2004-07-24,62,84;" +
    "2004-07-25,62,98;" +
    "2004-07-25,61,99;" +
    "2004-07-26,60,96;" +
    "2004-07-26,60,97;" +
    "2004-07-27,58,86;" +
    "2004-07-27,58,90;" +
    "2004-07-28,58,84;" +
    "2004-07-28,58,86;" +
    "2004-07-29,57,82;" +
    "2004-07-29,58,85;" +
    "2004-07-30,58,85;" +
    "2004-07-30,58,86;" +
    "2004-07-31,58,76;" +
    "2004-07-31,58,80;" +
    "2004-08-01,58,76;" +
    "2004-08-01,58,77;" +
    "2004-08-02,57,70;" +
    "2004-08-02,58,71;" +
    "2004-08-03,58,87;" +
    "2004-08-03,58,89;" +
    "2004-08-04,56,82;" +
    "2004-08-04,56,84;" +
    "2004-08-05,56,82;" +
    "2004-08-05,56,82;" +
    "2004-08-06,54,83;" +
    "2004-08-06,56,84;" +
    "2004-08-07,58,96;" +
    "2004-08-07,58,96;" +
    "2004-08-08,58,97;" +
    "2004-08-08,61,98;" +
    "2004-08-09,58,86;" +
    "2004-08-09,59,88;" +
    "2004-08-10,58,95;" +
    "2004-08-10,58,97;" +
    "2004-08-11,58,103;" +
    "2004-08-11,58,104;" +
    "2004-08-12,59,89;" +
    "2004-08-12,60,97;" +
    "2004-08-13,59,86;" +
    "2004-08-13,58,87;" +
    "2004-08-14,59,73;" +
    "2004-08-14,59,78;" +
    "2004-08-15,60,83;" +
    "2004-08-15,59,84;" +
    "2004-08-16,56,86;" +
    "2004-08-16,56,88;" +
    "2004-08-17,58,94;" +
    "2004-08-17,58,96;" +
    "2004-08-18,59,97;" +
    "2004-08-18,59,99;" +
    "2004-08-19,58,89;" +
    "2004-08-19,58,92;" +
    "2004-08-20,59,90;" +
    "2004-08-20,59,91;" +
    "2004-08-21,58,78;" +
    "2004-08-21,59,84;" +
    "2004-08-22,59,77;" +
    "2004-08-22,59,78;" +
    "2004-08-23,63,81;" +
    "2004-08-23,63,82;" +
    "2004-08-24,61,79;" +
    "2004-08-24,63,80;" +
    "2004-08-25,58,81;" +
    "2004-08-25,59,82;" +
    "2004-08-26,59,87;" +
    "2004-08-26,59,87;" +
    "2004-08-27,58,95;" +
    "2004-08-27,58,95;" +
    "2004-08-28,58,100;" +
    "2004-08-28,59,100;" +
    "2004-08-29,59,95;" +
    "2004-08-29,60,96;" +
    "2004-08-30,57,92;" +
    "2004-08-30,58,93;" +
    "2004-08-31,58,87;" +
    "2004-08-31,58,89;" +
    "2004-09-01,59,84;" +
    "2004-09-01,60,86;" +
    "2004-09-02,57,87;" +
    "2004-09-02,56,89;" +
    "2004-09-03,51,87;" +
    "2004-09-03,54,86;" +
    "2004-09-04,56,92;" +
    "2004-09-04,60,91;" +
    "2004-09-05,56,98;" +
    "2004-09-05,60,98;" +
    "2004-09-06,57,102;" +
    "2004-09-06,60,102;" +
    "2004-09-07,57,103;" +
    "2004-09-07,59,103;" +
    "2004-09-08,58,103;" +
    "2004-09-08,59,104;" +
    "2004-09-09,58,97;" +
    "2004-09-09,58,98;" +
    "2004-09-10,54,91;" +
    "2004-09-10,55,93;" +
    "2004-09-11,53,94;" +
    "2004-09-11,54,95;" +
    "2004-09-12,60,79;" +
    "2004-09-12,63,86;" +
    "2004-09-13,57,81;" +
    "2004-09-13,57,82;" +
    "2004-09-14,52,89;" +
    "2004-09-14,54,90;" +
    "2004-09-15,56,95;" +
    "2004-09-15,58,95;" +
    "2004-09-16,59,91;" +
    "2004-09-16,60,92;" +
    "2004-09-17,57,82;" +
    "2004-09-17,57,82;" +
    "2004-09-18,54,66;" +
    "2004-09-18,57,67;" +
    "2004-09-19,52,65;" +
    "2004-09-19,52,66;" +
    "2004-09-20,45,72;" +
    "2004-09-20,46,72;" +
    "2004-09-21,44,82;" +
    "2004-09-21,47,81;" +
    "2004-09-22,47,86;" +
    "2004-09-22,50,86;" +
    "2004-09-23,51,89;" +
    "2004-09-23,53,89;" +
    "2004-09-24,52,93;" +
    "2004-09-24,54,91;" +
    "2004-09-25,52,88;" +
    "2004-09-25,54,89;" +
    "2004-09-26,47,91;" +
    "2004-09-26,49,93;" +
    "2004-09-27,51,72;" +
    "2004-09-27,50,83;" +
    "2004-09-28,54,73;" +
    "2004-09-28,59,75;" +
    "2004-09-29,52,71;" +
    "2004-09-29,52,72;" +
    "2004-09-30,53,72;" +
    "2004-09-30,56,74;" +
    "2004-10-01,49,71;" +
    "2004-10-01,48,72;" +
    "2004-10-02,48,83;" +
    "2004-10-02,48,85;" +
    "2004-10-03,52,78;" +
    "2004-10-03,52,80;" +
    "2004-10-04,55,68;" +
    "2004-10-04,54,70;" +
    "2004-10-05,55,81;" +
    "2004-10-05,54,82;" +
    "2004-10-06,48,88;" +
    "2004-10-06,50,89;" +
    "2004-10-07,52,83;" +
    "2004-10-07,54,84;" +
    "2004-10-08,51,81;" +
    "2004-10-08,54,81;" +
    "2004-10-09,49,74;" +
    "2004-10-09,57,76;" +
    "2004-10-10,44,81;" +
    "2004-10-10,46,81;" +
    "2004-10-11,51,86;" +
    "2004-10-11,55,85;" +
    "2004-10-12,49,91;" +
    "2004-10-12,51,91;" +
    "2004-10-13,50,92;" +
    "2004-10-13,53,91;" +
    "2004-10-14,53,91;" +
    "2004-10-14,55,92;" +
    "2004-10-15,51,84;" +
    "2004-10-15,53,85;" +
    "2004-10-16,52,70;" +
    "2004-10-16,51,73;" +
    "2004-10-17,53,68;" +
    "2004-10-17,54,67;" +
    "2004-10-18,46,65;" +
    "2004-10-18,47,66;" +
    "2004-10-19,50,63;" +
    "2004-10-19,52,62;" +
    "2004-10-20,48,64;" +
    "2004-10-20,49,64;" +
    "2004-10-21,41,67;" +
    "2004-10-21,42,68;" +
    "2004-10-22,47,67;" +
    "2004-10-22,46,68;" +
    "2004-10-23,52,61;" +
    "2004-10-23,51,62;" +
    "2004-10-24,46,65;" +
    "2004-10-24,54,65;" +
    "2004-10-25,44,65;" +
    "2004-10-25,44,65;" +
    "2004-10-26,44,61;" +
    "2004-10-26,47,62;" +
    "2004-10-27,41,58;" +
    "2004-10-27,42,58;" +
    "2004-10-28,38,60;" +
    "2004-10-28,39,61;" +
    "2004-10-29,44,64;" +
    "2004-10-29,44,63;" +
    "2004-10-30,40,63;" +
    "2004-10-30,42,64;" +
    "2004-10-31,44,69;" +
    "2004-10-31,49,68;" +
    "2004-11-01,42,67;" +
    "2004-11-01,47,66;" +
    "2004-11-02,38,67;" +
    "2004-11-02,40,67;" +
    "2004-11-03,46,58;" +
    "2004-11-03,48,57;" +
    "2004-11-04,40,55;" +
    "2004-11-04,42,54;" +
    "2004-11-05,40,68;" +
    "2004-11-05,42,68;" +
    "2004-11-06,38,69;" +
    "2004-11-06,40,72;" +
    "2004-11-07,47,65;" +
    "2004-11-07,47,66;" +
    "2004-11-08,47,58;" +
    "2004-11-08,48,58;" +
    "2004-11-09,49,59;" +
    "2004-11-09,51,59;" +
    "2004-11-10,50,64;" +
    "2004-11-10,50,65;" +
    "2004-11-11,49,62;" +
    "2004-11-11,51,61;" +
    "2004-11-12,50,65;" +
    "2004-11-12,48,66;" +
    "2004-11-13,47,68;" +
    "2004-11-13,51,69;" +
    "2004-11-14,40,61;" +
    "2004-11-14,42,63;" +
    "2004-11-15,46,61;" +
    "2004-11-15,46,60;" +
    "2004-11-16,44,60;" +
    "2004-11-16,46,60;" +
    "2004-11-17,41,65;" +
    "2004-11-17,42,64;" +
    "2004-11-18,39,57;" +
    "2004-11-18,41,56;" +
    "2004-11-19,40,61;" +
    "2004-11-19,44,61;" +
    "2004-11-20,42,67;" +
    "2004-11-20,41,67;" +
    "2004-11-21,48,60;" +
    "2004-11-21,44,60;" +
    "2004-11-22,37,64;" +
    "2004-11-22,41,66;" +
    "2004-11-23,33,60;" +
    "2004-11-23,34,62;" +
    "2004-11-24,34,62;" +
    "2004-11-24,36,63;" +
    "2004-11-25,38,56;" +
    "2004-11-25,40,56;" +
    "2004-11-26,48,61;" +
    "2004-11-26,48,61;" +
    "2004-11-27,48,59;" +
    "2004-11-27,47,59;" +
    "2004-11-28,38,56;" +
    "2004-11-28,43,55;" +
    "2004-11-29,30,53;" +
    "2004-11-29,35,52;" +
    "2004-11-30,26,52;" +
    "2004-11-30,28,52;" +
    "2004-12-01,27,56;" +
    "2004-12-01,28,57;" +
    "2004-12-02,27,56;" +
    "2004-12-02,30,56;" +
    "2004-12-03,27,59;" +
    "2004-12-03,34,60;" +
    "2004-12-04,23,55;" +
    "2004-12-04,27,55;" +
    "2004-12-05,31,56;" +
    "2004-12-05,35,58;" +
    "2004-12-06,37,56;" +
    "2004-12-06,40,57;" +
    "2004-12-07,50,59;" +
    "2004-12-07,50,58;" +
    "2004-12-08,51,61;" +
    "2004-12-08,51,63;" +
    "2004-12-09,46,65;" +
    "2004-12-09,51,65;" +
    "2004-12-10,45,61;" +
    "2004-12-10,47,61;" +
    "2004-12-11,41,56;" +
    "2004-12-11,49,57;" +
    "2004-12-12,44,59;" +
    "2004-12-12,44,58;" +
    "2004-12-13,43,62;" +
    "2004-12-13,43,61;" +
    "2004-12-14,47,53;" +
    "2004-12-14,49,52;" +
    "2004-12-15,42,61;" +
    "2004-12-15,46,60;" +
    "2004-12-16,40,56;" +
    "2004-12-16,42,56;" +
    "2004-12-17,34,63;" +
    "2004-12-17,35,63;" +
    "2004-12-18,31,62;" +
    "2004-12-18,35,62;" +
    "2004-12-19,34,61;" +
    "2004-12-19,34,59;" +
    "2004-12-20,32,54;" +
    "2004-12-20,32,57;" +
    "2004-12-21,35,61;" +
    "2004-12-21,36,61;" +
    "2004-12-22,45,57;" +
    "2004-12-22,45,57;" +
    "2004-12-23,41,61;" +
    "2004-12-23,43,60;" +
    "2004-12-24,32,57;" +
    "2004-12-24,35,57;" +
    "2004-12-25,31,56;" +
    "2004-12-25,30,58;" +
    "2004-12-26,41,58;" +
    "2004-12-26,41,59;" +
    "2004-12-27,45,59;" +
    "2004-12-27,45,60;" +
    "2004-12-28,44,50;" +
    "2004-12-28,45,49;" +
    "2004-12-29,44,56;" +
    "2004-12-29,44,56;" +
    "2004-12-30,48,55;" +
    "2004-12-30,47,57;" +
    "2004-12-31,45,54;" +
    "2004-12-31,45,55;"