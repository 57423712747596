export const data = "1990-01-01,44,53;" +
    "1990-01-02,37,55;" +
    "1990-01-03,30,54;" +
    "1990-01-04,29,57;" +
    "1990-01-05,31,61;" +
    "1990-01-06,40,52;" +
    "1990-01-07,46,62;" +
    "1990-01-08,50,67;" +
    "1990-01-09,48,65;" +
    "1990-01-10,48,61;" +
    "1990-01-11,40,64;" +
    "1990-01-12,46,62;" +
    "1990-01-13,46,56;" +
    "1990-01-14,47,55;" +
    "1990-01-15,46,56;" +
    "1990-01-16,42,51;" +
    "1990-01-17,35,56;" +
    "1990-01-18,37,55;" +
    "1990-01-19,30,67;" +
    "1990-01-20,31,57;" +
    "1990-01-21,29,54;" +
    "1990-01-22,31,53;" +
    "1990-01-23,31,61;" +
    "1990-01-24,41,59;" +
    "1990-01-25,32,62;" +
    "1990-01-26,40,56;" +
    "1990-01-27,31,56;" +
    "1990-01-28,30,63;" +
    "1990-01-29,39,60;" +
    "1990-01-30,46,55;" +
    "1990-01-31,33,54;" +
    "1990-02-01,37,55;" +
    "1990-02-02,34,57;" +
    "1990-02-03,37,61;" +
    "1990-02-04,35,54;" +
    "1990-02-05,33,58;" +
    "1990-02-06,44,53;" +
    "1990-02-07,28,54;" +
    "1990-02-08,32,56;" +
    "1990-02-09,45,59;" +
    "1990-02-10,37,64;" +
    "1990-02-11,36,57;" +
    "1990-02-12,42,61;" +
    "1990-02-13,35,52;" +
    "1990-02-14,33,47;" +
    "1990-02-15,24,53;" +
    "1990-02-16,41,47;" +
    "1990-02-17,34,47;" +
    "1990-02-18,30,47;" +
    "1990-02-19,28,55;" +
    "1990-02-20,29,48;" +
    "1990-02-21,42,62;" +
    "1990-02-22,37,61;" +
    "1990-02-23,38,66;" +
    "1990-02-24,42,70;" +
    "1990-02-25,43,70;" +
    "1990-02-26,46,65;" +
    "1990-02-27,46,64;" +
    "1990-02-28,46,64;" +
    "1990-03-01,47,56;" +
    "1990-03-02,48,57;" +
    "1990-03-03,47,68;" +
    "1990-03-04,46,56;" +
    "1990-03-05,42,63;" +
    "1990-03-06,39,68;" +
    "1990-03-07,40,63;" +
    "1990-03-08,42,59;" +
    "1990-03-09,34,63;" +
    "1990-03-10,43,55;" +
    "1990-03-11,38,55;" +
    "1990-03-12,39,57;" +
    "1990-03-13,33,60;" +
    "1990-03-14,42,61;" +
    "1990-03-15,43,65;" +
    "1990-03-16,44,71;" +
    "1990-03-17,45,72;" +
    "1990-03-18,42,75;" +
    "1990-03-19,44,70;" +
    "1990-03-20,44,76;" +
    "1990-03-21,45,80;" +
    "1990-03-22,45,72;" +
    "1990-03-23,51,75;" +
    "1990-03-24,43,70;" +
    "1990-03-25,48,62;" +
    "1990-03-26,47,73;" +
    "1990-03-27,42,75;" +
    "1990-03-28,46,72;" +
    "1990-03-29,43,74;" +
    "1990-03-30,46,73;" +
    "1990-03-31,46,68;" +
    "1990-04-01,44,73;" +
    "1990-04-02,40,79;" +
    "1990-04-03,46,84;" +
    "1990-04-04,53,79;" +
    "1990-04-05,48,72;" +
    "1990-04-06,50,68;" +
    "1990-04-07,52,60;" +
    "1990-04-08,49,64;" +
    "1990-04-09,40,77;" +
    "1990-04-10,40,81;" +
    "1990-04-11,40,81;" +
    "1990-04-12,46,86;" +
    "1990-04-13,49,91;" +
    "1990-04-14,52,79;" +
    "1990-04-15,50,73;" +
    "1990-04-16,54,68;" +
    "1990-04-17,53,70;" +
    "1990-04-18,53,68;" +
    "1990-04-19,50,66;" +
    "1990-04-20,53,70;" +
    "1990-04-21,52,67;" +
    "1990-04-22,51,67;" +
    "1990-04-23,55,65;" +
    "1990-04-24,43,72;" +
    "1990-04-25,45,81;" +
    "1990-04-26,49,86;" +
    "1990-04-27,50,88;" +
    "1990-04-28,53,79;" +
    "1990-04-29,43,69;" +
    "1990-04-30,43,78;" +
    "1990-05-01,50,82;" +
    "1990-05-02,49,88;" +
    "1990-05-02,70,86;" +
    "1990-05-03,59,91;" +
    "1990-05-03,64,89;" +
    "1990-05-04,54,96;" +
    "1990-05-04,57,94;" +
    "1990-05-05,55,93;" +
    "1990-05-05,60,88;" +
    "1990-05-06,50,85;" +
    "1990-05-06,50,77;" +
    "1990-05-07,46,82;" +
    "1990-05-07,45,83;" +
    "1990-05-08,48,85;" +
    "1990-05-08,51,76;" +
    "1990-05-09,49,73;" +
    "1990-05-09,49,68;" +
    "1990-05-10,53,69;" +
    "1990-05-10,49,64;" +
    "1990-05-11,48,71;" +
    "1990-05-11,47,65;" +
    "1990-05-12,47,70;" +
    "1990-05-12,47,47;" +
    "1990-05-13,43,79;" +
    "1990-05-14,48,68;" +
    "1990-05-14,47,63;" +
    "1990-05-15,46,77;" +
    "1990-05-15,45,72;" +
    "1990-05-16,44,77;" +
    "1990-05-16,45,71;" +
    "1990-05-17,46,67;" +
    "1990-05-17,47,63;" +
    "1990-05-18,50,63;" +
    "1990-05-18,49,57;" +
    "1990-05-19,50,65;" +
    "1990-05-19,49,60;" +
    "1990-05-20,55,69;" +
    "1990-05-20,54,63;" +
    "1990-05-21,51,75;" +
    "1990-05-21,51,68;" +
    "1990-05-22,53,76;" +
    "1990-05-22,55,71;" +
    "1990-05-23,54,67;" +
    "1990-05-23,48,63;" +
    "1990-05-24,49,72;" +
    "1990-05-24,46,67;" +
    "1990-05-25,45,70;" +
    "1990-05-25,45,66;" +
    "1990-05-26,51,77;" +
    "1990-05-26,50,72;" +
    "1990-05-27,51,65;" +
    "1990-05-27,54,62;" +
    "1990-05-28,53,66;" +
    "1990-05-28,52,62;" +
    "1990-05-29,54,70;" +
    "1990-05-29,52,65;" +
    "1990-05-30,53,67;" +
    "1990-05-30,52,63;" +
    "1990-05-31,50,67;" +
    "1990-05-31,49,63;" +
    "1990-06-01,46,76;" +
    "1990-06-01,45,73;" +
    "1990-06-02,48,83;" +
    "1990-06-02,48,79;" +
    "1990-06-03,52,81;" +
    "1990-06-03,52,77;" +
    "1990-06-04,53,81;" +
    "1990-06-04,51,76;" +
    "1990-06-05,50,85;" +
    "1990-06-05,50,79;" +
    "1990-06-06,56,75;" +
    "1990-06-06,55,69;" +
    "1990-06-07,53,87;" +
    "1990-06-07,55,83;" +
    "1990-06-08,57,100;" +
    "1990-06-08,59,95;" +
    "1990-06-09,62,95;" +
    "1990-06-09,56,87;" +
    "1990-06-10,54,78;" +
    "1990-06-10,53,72;" +
    "1990-06-11,50,84;" +
    "1990-06-11,51,80;" +
    "1990-06-12,50,83;" +
    "1990-06-12,50,78;" +
    "1990-06-13,49,76;" +
    "1990-06-13,49,67;" +
    "1990-06-14,49,76;" +
    "1990-06-14,52,71;" +
    "1990-06-15,56,69;" +
    "1990-06-15,54,64;" +
    "1990-06-16,54,77;" +
    "1990-06-16,51,74;" +
    "1990-06-17,49,81;" +
    "1990-06-17,49,76;" +
    "1990-06-18,53,81;" +
    "1990-06-18,52,74;" +
    "1990-06-19,55,98;" +
    "1990-06-19,56,95;" +
    "1990-06-20,63,104;" +
    "1990-06-20,71,103;" +
    "1990-06-21,64,95;" +
    "1990-06-21,56,88;" +
    "1990-06-22,55,82;" +
    "1990-06-22,55,73;" +
    "1990-06-23,57,82;" +
    "1990-06-23,54,77;" +
    "1990-06-24,55,80;" +
    "1990-06-24,52,72;" +
    "1990-06-25,50,86;" +
    "1990-06-25,50,82;" +
    "1990-06-26,50,88;" +
    "1990-06-26,51,84;" +
    "1990-06-27,54,83;" +
    "1990-06-27,53,77;" +
    "1990-06-28,55,86;" +
    "1990-06-28,54,83;" +
    "1990-06-29,54,80;" +
    "1990-06-29,54,71;" +
    "1990-06-30,53,91;" +
    "1990-07-01,56,85;" +
    "1990-07-02,53,82;" +
    "1990-07-03,52,82;" +
    "1990-07-03,53,77;" +
    "1990-07-04,55,80;" +
    "1990-07-04,53,77;" +
    "1990-07-05,57,85;" +
    "1990-07-05,57,81;" +
    "1990-07-06,62,80;" +
    "1990-07-06,60,76;" +
    "1990-07-07,53,89;" +
    "1990-07-07,55,81;" +
    "1990-07-08,56,83;" +
    "1990-07-08,55,77;" +
    "1990-07-09,56,94;" +
    "1990-07-09,56,91;" +
    "1990-07-10,59,103;" +
    "1990-07-10,63,101;" +
    "1990-07-11,65,100;" +
    "1990-07-11,71,97;" +
    "1990-07-12,73,103;" +
    "1990-07-12,73,99;" +
    "1990-07-13,70,102;" +
    "1990-07-13,69,100;" +
    "1990-07-14,60,95;" +
    "1990-07-14,57,85;" +
    "1990-07-15,57,90;" +
    "1990-07-15,57,86;" +
    "1990-07-16,58,78;" +
    "1990-07-16,58,77;" +
    "1990-07-17,59,90;" +
    "1990-07-17,59,87;" +
    "1990-07-18,60,91;" +
    "1990-07-18,59,87;" +
    "1990-07-19,58,90;" +
    "1990-07-19,59,87;" +
    "1990-07-20,58,98;" +
    "1990-07-20,69,95;" +
    "1990-07-21,58,98;" +
    "1990-07-21,60,93;" +
    "1990-07-22,57,91;" +
    "1990-07-22,55,79;" +
    "1990-07-23,56,77;" +
    "1990-07-23,55,71;" +
    "1990-07-24,55,76;" +
    "1990-07-24,55,74;" +
    "1990-07-25,53,82;" +
    "1990-07-25,54,78;" +
    "1990-07-26,50,91;" +
    "1990-07-26,52,88;" +
    "1990-07-27,56,91;" +
    "1990-07-27,55,80;" +
    "1990-07-28,57,90;" +
    "1990-07-29,56,90;" +
    "1990-07-30,57,93;" +
    "1990-07-30,59,77;" +
    "1990-07-31,57,89;" +
    "1990-07-31,56,84;" +
    "1990-08-01,56,89;" +
    "1990-08-01,56,85;" +
    "1990-08-02,58,89;" +
    "1990-08-02,56,85;" +
    "1990-08-03,58,87;" +
    "1990-08-03,57,82;" +
    "1990-08-04,57,91;" +
    "1990-08-04,56,87;" +
    "1990-08-05,55,102;" +
    "1990-08-05,54,99;" +
    "1990-08-06,65,104;" +
    "1990-08-06,70,101;" +
    "1990-08-07,70,101;" +
    "1990-08-07,72,99;" +
    "1990-08-08,69,105;" +
    "1990-08-08,69,100;" +
    "1990-08-09,71,104;" +
    "1990-08-09,70,96;" +
    "1990-08-10,64,101;" +
    "1990-08-10,63,96;" +
    "1990-08-11,63,101;" +
    "1990-08-11,63,98;" +
    "1990-08-12,58,93;" +
    "1990-08-12,57,85;" +
    "1990-08-13,56,90;" +
    "1990-08-13,54,85;" +
    "1990-08-14,56,80;" +
    "1990-08-14,55,70;" +
    "1990-08-15,59,77;" +
    "1990-08-15,58,71;" +
    "1990-08-16,60,76;" +
    "1990-08-16,59,73;" +
    "1990-08-17,61,78;" +
    "1990-08-17,20,74;" +
    "1990-08-18,61,77;" +
    "1990-08-18,61,73;" +
    "1990-08-19,57,81;" +
    "1990-08-19,58,78;" +
    "1990-08-20,55,81;" +
    "1990-08-20,55,78;" +
    "1990-08-21,60,85;" +
    "1990-08-21,59,83;" +
    "1990-08-22,55,93;" +
    "1990-08-22,56,90;" +
    "1990-08-23,58,93;" +
    "1990-08-23,56,85;" +
    "1990-08-24,57,77;" +
    "1990-08-24,56,65;" +
    "1990-08-25,59,75;" +
    "1990-08-25,58,69;" +
    "1990-08-26,55,80;" +
    "1990-08-26,55,77;" +
    "1990-08-27,54,85;" +
    "1990-08-27,57,81;" +
    "1990-08-28,57,89;" +
    "1990-08-28,58,84;" +
    "1990-08-29,60,85;" +
    "1990-08-29,60,82;" +
    "1990-08-30,58,80;" +
    "1990-08-30,56,76;" +
    "1990-08-31,56,88;" +
    "1990-08-31,56,84;" +
    "1990-09-01,54,80;" +
    "1990-09-01,55,76;" +
    "1990-09-02,50,90;" +
    "1990-09-02,52,88;" +
    "1990-09-03,55,80;" +
    "1990-09-03,55,78;" +
    "1990-09-04,55,84;" +
    "1990-09-04,56,82;" +
    "1990-09-05,55,90;" +
    "1990-09-05,55,87;" +
    "1990-09-06,54,84;" +
    "1990-09-06,54,82;" +
    "1990-09-07,55,85;" +
    "1990-09-07,55,83;" +
    "1990-09-08,53,85;" +
    "1990-09-08,53,82;" +
    "1990-09-09,51,97;" +
    "1990-09-09,54,96;" +
    "1990-09-10,56,90;" +
    "1990-09-10,56,85;" +
    "1990-09-11,58,86;" +
    "1990-09-11,56,81;" +
    "1990-09-12,52,87;" +
    "1990-09-12,55,82;" +
    "1990-09-13,51,81;" +
    "1990-09-13,53,79;" +
    "1990-09-14,56,79;" +
    "1990-09-14,56,77;" +
    "1990-09-15,52,80;" +
    "1990-09-15,53,77;" +
    "1990-09-16,51,87;" +
    "1990-09-16,54,85;" +
    "1990-09-17,56,85;" +
    "1990-09-17,56,83;" +
    "1990-09-18,57,81;" +
    "1990-09-18,56,77;" +
    "1990-09-19,52,86;" +
    "1990-09-19,54,84;" +
    "1990-09-20,55,96;" +
    "1990-09-20,59,95;" +
    "1990-09-21,57,92;" +
    "1990-09-21,59,85;" +
    "1990-09-22,59,81;" +
    "1990-09-22,60,79;" +
    "1990-09-23,56,78;" +
    "1990-09-23,57,75;" +
    "1990-09-24,58,78;" +
    "1990-09-24,58,75;" +
    "1990-09-25,59,76;" +
    "1990-09-25,58,74;" +
    "1990-09-26,59,79;" +
    "1990-09-26,57,76;" +
    "1990-09-27,53,82;" +
    "1990-09-27,55,80;" +
    "1990-09-28,57,87;" +
    "1990-09-28,56,86;" +
    "1990-09-29,55,95;" +
    "1990-09-29,57,95;" +
    "1990-09-30,57,96;" +
    "1990-09-30,30,95;" +
    "1990-10-01,54,86;" +
    "1990-10-01,10,90;" +
    "1990-10-02,55,85;" +
    "1990-10-02,54,83;" +
    "1990-10-03,57,89;" +
    "1990-10-03,60,89;" +
    "1990-10-04,57,91;" +
    "1990-10-04,60,88;" +
    "1990-10-05,60,76;" +
    "1990-10-05,53,71;" +
    "1990-10-06,49,77;" +
    "1990-10-06,50,78;" +
    "1990-10-07,46,78;" +
    "1990-10-07,52,76;" +
    "1990-10-08,55,80;" +
    "1990-10-08,61,81;" +
    "1990-10-09,45,83;" +
    "1990-10-09,54,84;" +
    "1990-10-10,46,89;" +
    "1990-10-10,56,88;" +
    "1990-10-11,49,88;" +
    "1990-10-11,56,88;" +
    "1990-10-12,47,89;" +
    "1990-10-12,56,89;" +
    "1990-10-13,53,81;" +
    "1990-10-13,54,80;" +
    "1990-10-14,48,84;" +
    "1990-10-14,52,82;" +
    "1990-10-15,47,78;" +
    "1990-10-15,51,75;" +
    "1990-10-16,53,80;" +
    "1990-10-16,52,79;" +
    "1990-10-17,46,82;" +
    "1990-10-17,55,84;" +
    "1990-10-18,55,71;" +
    "1990-10-18,56,65;" +
    "1990-10-19,52,71;" +
    "1990-10-19,51,68;" +
    "1990-10-20,42,74;" +
    "1990-10-20,51,76;" +
    "1990-10-21,43,78;" +
    "1990-10-21,49,78;" +
    "1990-10-22,48,82;" +
    "1990-10-22,53,80;" +
    "1990-10-23,47,81;" +
    "1990-10-23,48,82;" +
    "1990-10-24,48,81;" +
    "1990-10-24,54,83;" +
    "1990-10-25,48,83;" +
    "1990-10-25,55,81;" +
    "1990-10-26,47,85;" +
    "1990-10-26,52,83;" +
    "1990-10-27,46,85;" +
    "1990-10-27,52,84;" +
    "1990-10-28,48,76;" +
    "1990-10-28,51,74;" +
    "1990-10-29,47,71;" +
    "1990-10-29,49,69;" +
    "1990-10-30,46,71;" +
    "1990-10-30,49,69;" +
    "1990-10-31,53,65;" +
    "1990-10-31,47,64;" +
    "1990-11-01,38,65;" +
    "1990-11-01,42,62;" +
    "1990-11-02,48,64;" +
    "1990-11-02,50,63;" +
    "1990-11-03,47,70;" +
    "1990-11-03,53,72;" +
    "1990-11-04,39,74;" +
    "1990-11-04,50,77;" +
    "1990-11-05,44,72;" +
    "1990-11-05,50,70;" +
    "1990-11-06,50,66;" +
    "1990-11-06,53,64;" +
    "1990-11-07,48,71;" +
    "1990-11-07,52,71;" +
    "1990-11-08,43,78;" +
    "1990-11-08,50,78;" +
    "1990-11-09,44,76;" +
    "1990-11-09,53,77;" +
    "1990-11-10,40,71;" +
    "1990-11-10,49,72;" +
    "1990-11-11,42,70;" +
    "1990-11-11,54,71;" +
    "1990-11-12,43,77;" +
    "1990-11-12,52,78;" +
    "1990-11-13,44,75;" +
    "1990-11-13,47,74;" +
    "1990-11-14,46,55;" +
    "1990-11-14,47,54;" +
    "1990-11-15,49,65;" +
    "1990-11-15,50,66;" +
    "1990-11-16,43,59;" +
    "1990-11-16,48,59;" +
    "1990-11-17,44,70;" +
    "1990-11-17,48,68;" +
    "1990-11-18,45,63;" +
    "1990-11-18,47,62;" +
    "1990-11-19,44,63;" +
    "1990-11-19,45,60;" +
    "1990-11-20,40,55;" +
    "1990-11-20,43,54;" +
    "1990-11-21,43,59;" +
    "1990-11-21,45,59;" +
    "1990-11-22,37,63;" +
    "1990-11-22,43,63;" +
    "1990-11-23,36,64;" +
    "1990-11-23,45,66;" +
    "1990-11-24,34,67;" +
    "1990-11-24,44,69;" +
    "1990-11-25,39,64;" +
    "1990-11-25,43,61;" +
    "1990-11-26,36,56;" +
    "1990-11-26,39,54;" +
    "1990-11-27,31,58;" +
    "1990-11-27,38,59;" +
    "1990-11-28,32,54;" +
    "1990-11-28,40,80;" +
    "1990-11-29,34,56;" +
    "1990-11-29,41,58;" +
    "1990-11-30,37,62;" +
    "1990-11-30,43,64;" +
    "1990-12-01,31,64;" +
    "1990-12-01,40,65;" +
    "1990-12-02,29,58;" +
    "1990-12-02,39,58;" +
    "1990-12-03,33,55;" +
    "1990-12-03,41,55;" +
    "1990-12-04,30,61;" +
    "1990-12-04,40,59;" +
    "1990-12-05,36,64;" +
    "1990-12-05,43,63;" +
    "1990-12-06,37,59;" +
    "1990-12-06,46,63;" +
    "1990-12-07,35,60;" +
    "1990-12-07,44,62;" +
    "1990-12-08,34,62;" +
    "1990-12-08,46,64;" +
    "1990-12-09,35,63;" +
    "1990-12-09,45,65;" +
    "1990-12-10,40,53;" +
    "1990-12-10,46,53;" +
    "1990-12-11,45,55;" +
    "1990-12-11,43,57;" +
    "1990-12-12,39,58;" +
    "1990-12-12,40,57;" +
    "1990-12-13,35,56;" +
    "1990-12-13,39,56;" +
    "1990-12-14,31,51;" +
    "1990-12-14,38,51;" +
    "1990-12-15,36,46;" +
    "1990-12-15,41,46;" +
    "1990-12-16,39,52;" +
    "1990-12-16,38,50;" +
    "1990-12-17,32,56;" +
    "1990-12-17,36,58;" +
    "1990-12-18,32,56;" +
    "1990-12-18,36,53;" +
    "1990-12-19,38,49;" +
    "1990-12-19,33,45;" +
    "1990-12-20,29,44;" +
    "1990-12-20,26,42;" +
    "1990-12-21,24,35;" +
    "1990-12-21,21,34;" +
    "1990-12-22,18,34;" +
    "1990-12-22,20,29;" +
    "1990-12-23,19,36;" +
    "1990-12-23,23,35;" +
    "1990-12-24,20,45;" +
    "1990-12-24,25,46;" +
    "1990-12-25,24,52;" +
    "1990-12-25,30,51;" +
    "1990-12-26,28,53;" +
    "1990-12-26,32,53;" +
    "1990-12-27,27,55;" +
    "1990-12-27,34,58;" +
    "1990-12-28,29,53;" +
    "1990-12-28,34,50;" +
    "1990-12-29,27,51;" +
    "1990-12-29,33,49;" +
    "1990-12-30,28,48;" +
    "1990-12-30,30,48;" +
    "1990-12-31,26,51;" +
    "1990-12-31,30,52;"