export const data = "2017-01-01,40,52;" +
    "2017-01-01,36,52;" +
    "2017-01-02,40,49;" +
    "2017-01-02,39,49;" +
    "2017-01-03,45,57;" +
    "2017-01-03,43,53;" +
    "2017-01-04,46,57;" +
    "2017-01-04,46,56;" +
    "2017-01-05,32,50;" +
    "2017-01-05,40,51;" +
    "2017-01-06,28,46;" +
    "2017-01-06,30,45;" +
    "2017-01-07,40,62;" +
    "2017-01-07,39,60;" +
    "2017-01-08,55,65;" +
    "2017-01-08,54,64;" +
    "2017-01-09,49,58;" +
    "2017-01-09,49,59;" +
    "2017-01-10,52,58;" +
    "2017-01-10,50,56;" +
    "2017-01-11,43,55;" +
    "2017-01-11,47,56;" +
    "2017-01-12,39,48;" +
    "2017-01-12,42,47;" +
    "2017-01-13,36,57;" +
    "2017-01-13,34,56;" +
    "2017-01-14,33,56;" +
    "2017-01-14,32,54;" +
    "2017-01-15,29,48;" +
    "2017-01-15,30,48;" +
    "2017-01-16,32,50;" +
    "2017-01-16,33,50;" +
    "2017-01-17,30,48;" +
    "2017-01-17,31,47;" +
    "2017-01-18,42,55;" +
    "2017-01-18,41,50;" +
    "2017-01-19,46,55;" +
    "2017-01-19,46,56;" +
    "2017-01-20,44,53;" +
    "2017-01-20,43,52;" +
    "2017-01-21,43,54;" +
    "2017-01-21,42,54;" +
    "2017-01-22,45,58;" +
    "2017-01-22,44,57;" +
    "2017-01-23,39,51;" +
    "2017-01-23,40,51;" +
    "2017-01-24,33,51;" +
    "2017-01-24,34,51;" +
    "2017-01-25,30,55;" +
    "2017-01-25,30,56;" +
    "2017-01-26,36,55;" +
    "2017-01-26,37,54;" +
    "2017-01-27,31,56;" +
    "2017-01-27,33,56;" +
    "2017-01-28,31,57;" +
    "2017-01-28,32,57;" +
    "2017-01-29,31,60;" +
    "2017-01-29,31,59;" +
    "2017-01-30,33,59;" +
    "2017-01-30,34,57;" +
    "2017-01-31,33,59;" +
    "2017-01-31,32,58;" +
    "2017-02-01,40,56;" +
    "2017-02-01,41,53;" +
    "2017-02-02,47,64;" +
    "2017-02-02,47,64;" +
    "2017-02-03,51,62;" +
    "2017-02-03,50,62;" +
    "2017-02-04,44,61;" +
    "2017-02-04,50,62;" +
    "2017-02-05,43,60;" +
    "2017-02-05,43,61;" +
    "2017-02-06,52,59;" +
    "2017-02-06,52,57;" +
    "2017-02-07,56,62;" +
    "2017-02-07,51,61;" +
    "2017-02-08,56,65;" +
    "2017-02-08,54,65;" +
    "2017-02-09,56,63;" +
    "2017-02-09,56,63;" +
    "2017-02-10,48,61;" +
    "2017-02-10,53,60;" +
    "2017-02-11,44,62;" +
    "2017-02-11,42,62;" +
    "2017-02-12,39,63;" +
    "2017-02-12,40,63;" +
    "2017-02-13,36,61;" +
    "2017-02-13,36,60;" +
    "2017-02-14,39,65;" +
    "2017-02-14,39,64;" +
    "2017-02-15,44,68;" +
    "2017-02-15,44,68;" +
    "2017-02-16,49,64;" +
    "2017-02-16,52,65;" +
    "2017-02-17,50,59;" +
    "2017-02-17,49,58;" +
    "2017-02-18,47,55;" +
    "2017-02-18,48,54;" +
    "2017-02-19,46,57;" +
    "2017-02-19,45,56;" +
    "2017-02-20,52,58;" +
    "2017-02-20,51,56;" +
    "2017-02-21,46,60;" +
    "2017-02-21,49,60;" +
    "2017-02-22,37,56;" +
    "2017-02-22,38,56;" +
    "2017-02-23,33,53;" +
    "2017-02-23,34,55;" +
    "2017-02-24,30,54;" +
    "2017-02-24,31,56;" +
    "2017-02-25,34,55;" +
    "2017-02-25,36,55;" +
    "2017-02-26,33,57;" +
    "2017-02-26,33,59;" +
    "2017-02-27,37,56;" +
    "2017-02-27,37,56;" +
    "2017-02-28,33,59;" +
    "2017-02-28,33,59;" +
    "2017-03-01,32,61;" +
    "2017-03-01,33,62;" +
    "2017-03-02,32,64;" +
    "2017-03-02,34,64;" +
    "2017-03-03,34,69;" +
    "2017-03-03,35,70;" +
    "2017-03-04,47,60;" +
    "2017-03-04,46,61;" +
    "2017-03-05,40,51;" +
    "2017-03-05,41,54;" +
    "2017-03-06,39,56;" +
    "2017-03-06,39,56;" +
    "2017-03-07,33,60;" +
    "2017-03-07,34,59;" +
    "2017-03-08,37,69;" +
    "2017-03-08,38,69;" +
    "2017-03-09,42,72;" +
    "2017-03-09,44,72;" +
    "2017-03-10,49,70;" +
    "2017-03-10,49,70;" +
    "2017-03-11,45,75;" +
    "2017-03-11,46,77;" +
    "2017-03-12,43,77;" +
    "2017-03-12,44,76;" +
    "2017-03-13,42,75;" +
    "2017-03-13,42,76;" +
    "2017-03-14,46,82;" +
    "2017-03-14,47,81;" +
    "2017-03-15,48,70;" +
    "2017-03-15,49,75;" +
    "2017-03-16,50,73;" +
    "2017-03-16,50,74;" +
    "2017-03-17,43,77;" +
    "2017-03-17,43,77;" +
    "2017-03-18,49,71;" +
    "2017-03-18,49,74;" +
    "2017-03-19,50,70;" +
    "2017-03-19,49,71;" +
    "2017-03-20,53,63;" +
    "2017-03-20,52,64;" +
    "2017-03-21,55,67;" +
    "2017-03-21,55,67;" +
    "2017-03-22,46,61;" +
    "2017-03-22,50,62;" +
    "2017-03-23,42,63;" +
    "2017-03-23,41,64;" +
    "2017-03-24,50,58;" +
    "2017-03-24,49,57;" +
    "2017-03-25,49,65;" +
    "2017-03-25,49,66;" +
    "2017-03-26,49,62;" +
    "2017-03-26,48,63;" +
    "2017-03-27,46,64;" +
    "2017-03-27,46,65;" +
    "2017-03-28,41,72;" +
    "2017-03-28,41,72;" +
    "2017-03-29,45,75;" +
    "2017-03-29,45,76;" +
    "2017-03-30,50,66;" +
    "2017-03-30,49,72;" +
    "2017-03-31,52,70;" +
    "2017-03-31,50,70;" +
    "2017-04-01,49,74;" +
    "2017-04-01,52,75;" +
    "2017-04-02,46,78;" +
    "2017-04-02,46,78;" +
    "2017-04-03,42,74;" +
    "2017-04-03,44,76;" +
    "2017-04-04,43,74;" +
    "2017-04-04,44,76;" +
    "2017-04-05,49,77;" +
    "2017-04-05,48,79;" +
    "2017-04-06,52,64;" +
    "2017-04-06,52,69;" +
    "2017-04-07,47,64;" +
    "2017-04-07,51,64;" +
    "2017-04-08,39,55;" +
    "2017-04-08,44,60;" +
    "2017-04-09,36,64;" +
    "2017-04-09,36,65;" +
    "2017-04-10,38,69;" +
    "2017-04-10,38,70;" +
    "2017-04-11,53,69;" +
    "2017-04-11,51,67;" +
    "2017-04-12,51,65;" +
    "2017-04-12,50,66;" +
    "2017-04-13,44,61;" +
    "2017-04-13,47,63;" +
    "2017-04-14,40,63;" +
    "2017-04-14,39,63;" +
    "2017-04-15,38,67;" +
    "2017-04-15,38,67;" +
    "2017-04-16,46,62;" +
    "2017-04-16,47,64;" +
    "2017-04-17,53,64;" +
    "2017-04-17,52,63;" +
    "2017-04-18,49,67;" +
    "2017-04-18,51,67;" +
    "2017-04-19,42,69;" +
    "2017-04-19,42,70;" +
    "2017-04-20,50,69;" +
    "2017-04-20,50,70;" +
    "2017-04-21,44,77;" +
    "2017-04-21,44,77;" +
    "2017-04-22,43,69;" +
    "2017-04-22,44,74;" +
    "2017-04-23,50,66;" +
    "2017-04-23,50,67;" +
    "2017-04-24,50,61;" +
    "2017-04-24,50,60;" +
    "2017-04-25,49,67;" +
    "2017-04-25,48,68;" +
    "2017-04-26,55,67;" +
    "2017-04-26,54,68;" +
    "2017-04-27,52,72;" +
    "2017-04-27,51,73;" +
    "2017-04-28,43,74;" +
    "2017-04-28,43,75;" +
    "2017-04-29,46,79;" +
    "2017-04-29,46,79;" +
    "2017-04-30,46,83;" +
    "2017-04-30,46,84;" +
    "2017-05-01,49,88;" +
    "2017-05-01,50,89;" +
    "2017-05-02,57,89;" +
    "2017-05-02,57,91;" +
    "2017-05-03,58,93;" +
    "2017-05-03,58,94;" +
    "2017-05-04,53,86;" +
    "2017-05-04,60,91;" +
    "2017-05-05,51,69;" +
    "2017-05-05,52,76;" +
    "2017-05-06,48,64;" +
    "2017-05-06,47,64;" +
    "2017-05-07,47,74;" +
    "2017-05-07,48,75;" +
    "2017-05-08,49,80;" +
    "2017-05-08,49,82;" +
    "2017-05-09,49,87;" +
    "2017-05-09,50,88;" +
    "2017-05-10,50,71;" +
    "2017-05-10,52,82;" +
    "2017-05-11,50,70;" +
    "2017-05-11,48,71;" +
    "2017-05-12,48,66;" +
    "2017-05-12,47,67;" +
    "2017-05-13,46,68;" +
    "2017-05-13,45,69;" +
    "2017-05-14,48,68;" +
    "2017-05-14,47,70;" +
    "2017-05-15,45,70;" +
    "2017-05-15,44,71;" +
    "2017-05-16,51,62;" +
    "2017-05-16,49,62;" +
    "2017-05-17,50,73;" +
    "2017-05-17,50,74;" +
    "2017-05-18,44,83;" +
    "2017-05-18,44,84;" +
    "2017-05-19,49,90;" +
    "2017-05-19,50,90;" +
    "2017-05-20,51,92;" +
    "2017-05-20,53,93;" +
    "2017-05-21,53,93;" +
    "2017-05-21,55,95;" +
    "2017-05-22,58,96;" +
    "2017-05-22,56,96;" +
    "2017-05-23,59,92;" +
    "2017-05-23,57,92;" +
    "2017-05-24,53,78;" +
    "2017-05-24,52,82;" +
    "2017-05-25,54,72;" +
    "2017-05-25,52,72;" +
    "2017-05-26,51,68;" +
    "2017-05-26,53,71;" +
    "2017-05-27,50,75;" +
    "2017-05-27,50,76;" +
    "2017-05-28,53,74;" +
    "2017-05-28,53,76;" +
    "2017-05-29,53,70;" +
    "2017-05-29,51,74;" +
    "2017-05-30,52,71;" +
    "2017-05-30,51,70;" +
    "2017-05-31,53,76;" +
    "2017-05-31,52,77;" +
    "2017-06-01,53,76;" +
    "2017-06-01,54,77;" +
    "2017-06-02,51,87;" +
    "2017-06-02,50,88;" +
    "2017-06-03,54,83;" +
    "2017-06-03,53,84;" +
    "2017-06-04,51,80;" +
    "2017-06-04,50,81;" +
    "2017-06-05,46,89;" +
    "2017-06-05,45,91;" +
    "2017-06-06,51,80;" +
    "2017-06-06,50,83;" +
    "2017-06-07,52,78;" +
    "2017-06-07,50,78;" +
    "2017-06-08,59,73;" +
    "2017-06-08,57,72;" +
    "2017-06-09,53,69;" +
    "2017-06-09,59,70;" +
    "2017-06-10,52,70;" +
    "2017-06-10,51,71;" +
    "2017-06-11,51,65;" +
    "2017-06-11,49,66;" +
    "2017-06-12,51,71;" +
    "2017-06-12,50,72;" +
    "2017-06-13,48,80;" +
    "2017-06-13,47,81;" +
    "2017-06-14,52,89;" +
    "2017-06-14,51,90;" +
    "2017-06-15,53,88;" +
    "2017-06-15,52,88;" +
    "2017-06-16,61,94;" +
    "2017-06-16,59,95;" +
    "2017-06-17,64,97;" +
    "2017-06-17,64,98;" +
    "2017-06-18,66,105;" +
    "2017-06-18,96,106;" +
    "2017-06-19,72,104;" +
    "2017-06-19,71,104;" +
    "2017-06-20,62,98;" +
    "2017-06-20,66,99;" +
    "2017-06-21,58,103;" +
    "2017-06-21,58,104;" +
    "2017-06-22,69,106;" +
    "2017-06-22,70,106;" +
    "2017-06-23,61,94;" +
    "2017-06-23,62,99;" +
    "2017-06-24,58,85;" +
    "2017-06-24,58,89;" +
    "2017-06-25,56,80;" +
    "2017-06-25,56,82;" +
    "2017-06-26,58,78;" +
    "2017-06-26,57,80;" +
    "2017-06-27,56,76;" +
    "2017-06-27,56,78;" +
    "2017-06-28,55,81;" +
    "2017-06-28,54,84;" +
    "2017-06-29,53,83;" +
    "2017-06-29,51,84;" +
    "2017-06-30,54,80;" +
    "2017-06-30,54,82;" +
    "2017-07-01,53,79;" +
    "2017-07-01,53,81;" +
    "2017-07-02,54,86;" +
    "2017-07-02,53,88;" +
    "2017-07-03,55,91;" +
    "2017-07-03,54,91;" +
    "2017-07-04,55,88;" +
    "2017-07-04,55,90;" +
    "2017-07-05,,;" +
    "2017-07-05,54,88;" +
    "2017-07-06,58,97;" +
    "2017-07-06,58,98;" +
    "2017-07-07,62,104;" +
    "2017-07-07,61,104;" +
    "2017-07-08,64,101;" +
    "2017-07-08,65,101;" +
    "2017-07-09,61,97;" +
    "2017-07-09,60,99;" +
    "2017-07-10,59,94;" +
    "2017-07-10,58,94;" +
    "2017-07-11,58,89;" +
    "2017-07-11,57,91;" +
    "2017-07-12,56,91;" +
    "2017-07-12,55,93;" +
    "2017-07-13,58,90;" +
    "2017-07-13,56,91;" +
    "2017-07-14,57,92;" +
    "2017-07-14,56,94;" +
    "2017-07-15,60,101;" +
    "2017-07-15,58,101;" +
    "2017-07-16,64,102;" +
    "2017-07-16,65,102;" +
    "2017-07-17,63,97;" +
    "2017-07-17,65,99;" +
    "2017-07-18,56,81;" +
    "2017-07-18,55,92;" +
    "2017-07-19,54,88;" +
    "2017-07-19,53,89;" +
    "2017-07-20,56,84;" +
    "2017-07-20,54,86;" +
    "2017-07-21,55,87;" +
    "2017-07-21,54,90;" +
    "2017-07-22,55,101;" +
    "2017-07-22,53,101;" +
    "2017-07-23,63,97;" +
    "2017-07-23,62,100;" +
    "2017-07-24,57,89;" +
    "2017-07-24,57,90;" +
    "2017-07-25,58,87;" +
    "2017-07-25,56,88;" +
    "2017-07-26,57,90;" +
    "2017-07-26,56,92;" +
    "2017-07-27,59,98;" +
    "2017-07-27,58,100;" +
    "2017-07-28,60,91;" +
    "2017-07-28,61,95;" +
    "2017-07-29,57,89;" +
    "2017-07-29,55,91;" +
    "2017-07-30,56,92;" +
    "2017-07-30,55,95;" +
    "2017-07-31,58,95;" +
    "2017-07-31,57,96;" +
    "2017-08-01,61,101;" +
    "2017-08-01,60,103;" +
    "2017-08-02,67,100;" +
    "2017-08-02,66,101;" +
    "2017-08-03,73,96;" +
    "2017-08-03,72,99;" +
    "2017-08-04,68,83;" +
    "2017-08-04,74,85;" +
    "2017-08-05,63,87;" +
    "2017-08-05,62,90;" +
    "2017-08-06,59,84;" +
    "2017-08-06,59,86;" +
    "2017-08-07,58,81;" +
    "2017-08-07,58,82;" +
    "2017-08-08,58,85;" +
    "2017-08-08,58,87;" +
    "2017-08-09,59,76;" +
    "2017-08-09,58,78;" +
    "2017-08-10,59,83;" +
    "2017-08-10,58,86;" +
    "2017-08-11,58,89;" +
    "2017-08-11,57,91;" +
    "2017-08-12,57,83;" +
    "2017-08-12,56,85;" +
    "2017-08-13,56,82;" +
    "2017-08-13,54,84;" +
    "2017-08-14,60,72;" +
    "2017-08-14,59,74;" +
    "2017-08-15,57,75;" +
    "2017-08-15,57,76;" +
    "2017-08-16,57,82;" +
    "2017-08-16,55,84;" +
    "2017-08-17,59,87;" +
    "2017-08-17,58,89;" +
    "2017-08-18,59,90;" +
    "2017-08-18,57,92;" +
    "2017-08-19,59,85;" +
    "2017-08-19,57,86;" +
    "2017-08-20,59,84;" +
    "2017-08-20,57,84;" +
    "2017-08-21,59,82;" +
    "2017-08-21,60,83;" +
    "2017-08-22,58,90;" +
    "2017-08-22,57,90;" +
    "2017-08-23,58,87;" +
    "2017-08-23,57,88;" +
    "2017-08-24,57,83;" +
    "2017-08-24,56,84;" +
    "2017-08-25,55,94;" +
    "2017-08-25,53,95;" +
    "2017-08-26,58,104;" +
    "2017-08-26,58,105;" +
    "2017-08-27,64,107;" +
    "2017-08-27,64,105;" +
    "2017-08-28,65,107;" +
    "2017-08-28,64,108;" +
    "2017-08-29,59,87;" +
    "2017-08-29,59,97;" +
    "2017-08-30,56,90;" +
    "2017-08-30,55,91;" +
    "2017-08-31,53,100;" +
    "2017-08-31,53,98;" +
    "2017-09-01,66,109;" +
    "2017-09-01,65,109;" +
    "2017-09-02,69,108;" +
    "2017-09-02,68,108;" +
    "2017-09-03,76,106;" +
    "2017-09-03,75,105;" +
    "2017-09-04,69,88;" +
    "2017-09-04,69,100;" +
    "2017-09-05,68,92;" +
    "2017-09-05,68,92;" +
    "2017-09-06,65,89;" +
    "2017-09-06,64,90;" +
    "2017-09-07,62,78;" +
    "2017-09-07,64,80;" +
    "2017-09-08,61,84;" +
    "2017-09-08,60,84;" +
    "2017-09-09,61,90;" +
    "2017-09-09,60,91;" +
    "2017-09-10,61,100;" +
    "2017-09-10,60,99;" +
    "2017-09-11,68,96;" +
    "2017-09-11,69,97;" +
    "2017-09-12,64,91;" +
    "2017-09-12,63,91;" +
    "2017-09-13,63,78;" +
    "2017-09-13,63,80;" +
    "2017-09-14,59,75;" +
    "2017-09-14,62,76;" +
    "2017-09-15,53,79;" +
    "2017-09-15,54,80;" +
    "2017-09-16,56,86;" +
    "2017-09-16,54,85;" +
    "2017-09-17,53,82;" +
    "2017-09-17,50,83;" +
    "2017-09-18,59,77;" +
    "2017-09-18,59,76;" +
    "2017-09-19,53,77;" +
    "2017-09-19,52,77;" +
    "2017-09-20,55,73;" +
    "2017-09-20,55,71;" +
    "2017-09-21,51,70;" +
    "2017-09-21,50,70;" +
    "2017-09-22,50,73;" +
    "2017-09-22,50,75;" +
    "2017-09-23,45,79;" +
    "2017-09-23,46,79;" +
    "2017-09-24,47,82;" +
    "2017-09-24,46,80;" +
    "2017-09-25,49,86;" +
    "2017-09-25,48,85;" +
    "2017-09-26,53,88;" +
    "2017-09-26,52,87;" +
    "2017-09-27,55,92;" +
    "2017-09-27,54,92;" +
    "2017-09-28,54,93;" +
    "2017-09-28,54,93;" +
    "2017-09-29,54,79;" +
    "2017-09-29,53,84;" +
    "2017-09-30,58,82;" +
    "2017-09-30,56,82;" +
    "2017-10-01,50,82;" +
    "2017-10-01,50,83;" +
    "2017-10-02,51,79;" +
    "2017-10-02,54,80;" +
    "2017-10-03,45,77;" +
    "2017-10-03,44,77;" +
    "2017-10-04,45,80;" +
    "2017-10-04,45,79;" +
    "2017-10-05,46,84;" +
    "2017-10-05,45,83;" +
    "2017-10-06,48,87;" +
    "2017-10-06,46,86;" +
    "2017-10-07,47,90;" +
    "2017-10-07,48,89;" +
    "2017-10-08,49,81;" +
    "2017-10-08,48,79;" +
    "2017-10-09,54,82;" +
    "2017-10-09,58,80;" +
    "2017-10-10,49,85;" +
    "2017-10-10,47,84;" +
    "2017-10-11,46,74;" +
    "2017-10-11,48,73;" +
    "2017-10-12,41,75;" +
    "2017-10-12,44,74;" +
    "2017-10-13,42,78;" +
    "2017-10-13,41,76;" +
    "2017-10-14,46,74;" +
    "2017-10-14,47,73;" +
    "2017-10-15,43,80;" +
    "2017-10-15,44,78;" +
    "2017-10-16,47,85;" +
    "2017-10-16,46,85;" +
    "2017-10-17,50,85;" +
    "2017-10-17,48,83;" +
    "2017-10-18,44,82;" +
    "2017-10-18,43,82;" +
    "2017-10-19,50,67;" +
    "2017-10-19,49,68;" +
    "2017-10-20,49,64;" +
    "2017-10-20,48,64;" +
    "2017-10-21,41,71;" +
    "2017-10-21,42,71;" +
    "2017-10-22,45,76;" +
    "2017-10-22,44,76;" +
    "2017-10-23,54,85;" +
    "2017-10-23,56,83;" +
    "2017-10-24,55,91;" +
    "2017-10-24,55,88;" +
    "2017-10-25,52,89;" +
    "2017-10-25,52,87;" +
    "2017-10-26,51,88;" +
    "2017-10-26,51,86;" +
    "2017-10-27,50,89;" +
    "2017-10-27,50,87;" +
    "2017-10-28,50,86;" +
    "2017-10-28,50,85;" +
    "2017-10-29,51,72;" +
    "2017-10-29,50,77;" +
    "2017-10-30,52,64;" +
    "2017-10-30,52,64;" +
    "2017-10-31,45,71;" +
    "2017-10-31,45,70;" +
    "2017-11-01,45,71;" +
    "2017-11-01,44,71;" +
    "2017-11-02,45,67;" +
    "2017-11-02,44,68;" +
    "2017-11-03,52,65;" +
    "2017-11-03,51,64;" +
    "2017-11-04,46,61;" +
    "2017-11-04,53,60;" +
    "2017-11-05,41,60;" +
    "2017-11-05,40,61;" +
    "2017-11-06,40,64;" +
    "2017-11-06,41,65;" +
    "2017-11-07,39,64;" +
    "2017-11-07,38,63;" +
    "2017-11-08,43,67;" +
    "2017-11-08,43,66;" +
    "2017-11-09,51,66;" +
    "2017-11-09,55,65;" +
    "2017-11-10,47,67;" +
    "2017-11-10,47,66;" +
    "2017-11-11,43,66;" +
    "2017-11-11,44,66;" +
    "2017-11-12,44,67;" +
    "2017-11-12,43,66;" +
    "2017-11-13,44,69;" +
    "2017-11-13,44,69;" +
    "2017-11-14,50,67;" +
    "2017-11-14,50,67;" +
    "2017-11-15,50,68;" +
    "2017-11-15,48,65;" +
    "2017-11-16,54,61;" +
    "2017-11-16,53,63;" +
    "2017-11-17,43,61;" +
    "2017-11-17,44,60;" +
    "2017-11-18,39,62;" +
    "2017-11-18,40,62;" +
    "2017-11-19,36,62;" +
    "2017-11-19,36,61;" +
    "2017-11-20,51,69;" +
    "2017-11-20,52,68;" +
    "2017-11-21,49,69;" +
    "2017-11-21,50,66;" +
    "2017-11-22,46,70;" +
    "2017-11-22,46,69;" +
    "2017-11-23,51,73;" +
    "2017-11-23,51,74;" +
    "2017-11-24,51,74;" +
    "2017-11-24,53,75;" +
    "2017-11-25,50,69;" +
    "2017-11-25,55,68;" +
    "2017-11-26,54,67;" +
    "2017-11-26,54,66;" +
    "2017-11-27,41,60;" +
    "2017-11-27,44,59;" +
    "2017-11-28,37,62;" +
    "2017-11-28,37,63;" +
    "2017-11-29,39,64;" +
    "2017-11-29,42,62;" +
    "2017-11-30,36,65;" +
    "2017-11-30,38,65;" +
    "2017-12-01,35,64;" +
    "2017-12-01,36,64;" +
    "2017-12-02,38,62;" +
    "2017-12-02,38,62;" +
    "2017-12-03,38,58;" +
    "2017-12-03,41,58;" +
    "2017-12-04,45,61;" +
    "2017-12-04,38,59;" +
    "2017-12-05,37,63;" +
    "2017-12-05,42,61;" +
    "2017-12-06,32,61;" +
    "2017-12-06,33,60;" +
    "2017-12-07,34,64;" +
    "2017-12-07,34,61;" +
    "2017-12-08,33,64;" +
    "2017-12-08,33,62;" +
    "2017-12-09,32,64;" +
    "2017-12-09,33,62;" +
    "2017-12-10,31,65;" +
    "2017-12-10,32,63;" +
    "2017-12-11,32,67;" +
    "2017-12-11,33,64;" +
    "2017-12-12,31,65;" +
    "2017-12-12,32,64;" +
    "2017-12-13,28,62;" +
    "2017-12-13,30,61;" +
    "2017-12-14,34,69;" +
    "2017-12-14,36,68;" +
    "2017-12-15,33,61;" +
    "2017-12-15,34,61;" +
    "2017-12-16,36,63;" +
    "2017-12-16,38,62;" +
    "2017-12-17,38,61;" +
    "2017-12-17,44,61;" +
    "2017-12-18,33,60;" +
    "2017-12-18,33,59;" +
    "2017-12-19,33,64;" +
    "2017-12-19,33,64;" +
    "2017-12-20,33,55;" +
    "2017-12-20,41,55;" +
    "2017-12-21,32,55;" +
    "2017-12-21,33,54;" +
    "2017-12-22,27,51;" +
    "2017-12-22,28,50;" +
    "2017-12-23,34,56;" +
    "2017-12-23,35,54;" +
    "2017-12-24,40,54;" +
    "2017-12-24,41,52;" +
    "2017-12-25,38,61;" +
    "2017-12-25,38,60;" +
    "2017-12-26,33,64;" +
    "2017-12-26,34,64;" +
    "2017-12-27,31,63;" +
    "2017-12-27,32,63;" +
    "2017-12-28,34,63;" +
    "2017-12-28,34,62;" +
    "2017-12-29,35,62;" +
    "2017-12-29,35,60;" +
    "2017-12-30,34,62;" +
    "2017-12-30,34,59;" +
    "2017-12-31,37,61;" +
    "2017-12-31,38,61;"