export const data = "1983-01-01,25,43;" +
    "1983-01-02,29,43;" +
    "1983-01-03,27,52;" +
    "1983-01-04,29,46;" +
    "1983-01-05,34,41;" +
    "1983-01-06,33,53;" +
    "1983-01-07,32,60;" +
    "1983-01-08,35,46;" +
    "1983-01-09,32,62;" +
    "1983-01-10,42,58;" +
    "1983-01-11,36,56;" +
    "1983-01-12,31,47;" +
    "1983-01-13,25,58;" +
    "1983-01-14,29,52;" +
    "1983-01-15,35,51;" +
    "1983-01-16,42,55;" +
    "1983-01-17,39,58;" +
    "1983-01-18,43,59;" +
    "1983-01-19,38,54;" +
    "1983-01-20,29,55;" +
    "1983-01-21,36,50;" +
    "1983-01-22,44,59;" +
    "1983-01-23,33,63;" +
    "1983-01-24,48,60;" +
    "1983-01-25,39,61;" +
    "1983-01-26,51,65;" +
    "1983-01-27,45,56;" +
    "1983-01-28,38,55;" +
    "1983-01-29,42,58;" +
    "1983-01-30,39,57;" +
    "1983-01-31,35,58;" +
    "1983-02-01,32,51;" +
    "1983-02-02,43,52;" +
    "1983-02-03,37,58;" +
    "1983-02-04,37,63;" +
    "1983-02-05,43,55;" +
    "1983-02-06,46,57;" +
    "1983-02-07,51,60;" +
    "1983-02-08,46,57;" +
    "1983-02-09,46,63;" +
    "1983-02-10,44,68;" +
    "1983-02-11,44,72;" +
    "1983-02-12,54,62;" +
    "1983-02-13,45,61;" +
    "1983-02-14,41,60;" +
    "1983-02-15,40,62;" +
    "1983-02-16,39,65;" +
    "1983-02-17,37,71;" +
    "1983-02-18,47,60;" +
    "1983-02-19,36,63;" +
    "1983-02-20,39,69;" +
    "1983-02-21,42,62;" +
    "1983-02-22,44,65;" +
    "1983-02-23,47,60;" +
    "1983-02-24,46,59;" +
    "1983-02-25,40,59;" +
    "1983-02-26,46,62;" +
    "1983-02-27,49,62;" +
    "1983-02-28,50,63;" +
    "1983-03-01,49,63;" +
    "1983-03-02,50,63;" +
    "1983-03-03,45,64;" +
    "1983-03-04,45,65;" +
    "1983-03-05,48,60;" +
    "1983-03-06,40,64;" +
    "1983-03-07,49,61;" +
    "1983-03-08,51,74;" +
    "1983-03-09,53,70;" +
    "1983-03-10,49,69;" +
    "1983-03-11,45,64;" +
    "1983-03-12,49,63;" +
    "1983-03-13,53,65;" +
    "1983-03-14,40,63;" +
    "1983-03-15,36,65;" +
    "1983-03-16,38,56;" +
    "1983-03-17,42,60;" +
    "1983-03-18,43,58;" +
    "1983-03-19,38,67;" +
    "1983-03-20,46,56;" +
    "1983-03-21,38,60;" +
    "1983-03-22,39,60;" +
    "1983-03-23,40,57;" +
    "1983-03-24,44,52;" +
    "1983-03-25,37,62;" +
    "1983-03-26,39,61;" +
    "1983-03-27,47,62;" +
    "1983-03-28,40,64;" +
    "1983-03-29,48,70;" +
    "1983-03-30,43,73;" +
    "1983-03-31,49,69;" +
    "1983-04-01,44,74;" +
    "1983-04-02,46,64;" +
    "1983-04-03,40,64;" +
    "1983-04-04,46,66;" +
    "1983-04-05,46,66;" +
    "1983-04-06,37,70;" +
    "1983-04-07,36,75;" +
    "1983-04-08,43,68;" +
    "1983-04-09,45,57;" +
    "1983-04-10,42,60;" +
    "1983-04-11,40,60;" +
    "1983-04-12,34,60;" +
    "1983-04-13,34,66;" +
    "1983-04-14,37,67;" +
    "1983-04-15,38,69;" +
    "1983-04-16,45,75;" +
    "1983-04-17,49,69;" +
    "1983-04-18,49,65;" +
    "1983-04-19,46,70;" +
    "1983-04-20,43,65;" +
    "1983-04-21,44,61;" +
    "1983-04-22,45,63;" +
    "1983-04-23,49,60;" +
    "1983-04-24,38,58;" +
    "1983-04-25,40,63;" +
    "1983-04-26,40,68;" +
    "1983-04-27,44,61;" +
    "1983-04-28,49,64;" +
    "1983-04-29,50,67;" +
    "1983-04-30,45,63;" +
    "1983-05-01,41,65;" +
    "1983-05-02,49,66;" +
    "1983-05-03,47,70;" +
    "1983-05-04,48,63;" +
    "1983-05-05,47,62;" +
    "1983-05-06,38,68;" +
    "1983-05-07,43,68;" +
    "1983-05-08,45,67;" +
    "1983-05-09,37,70;" +
    "1983-05-10,42,70;" +
    "1983-05-11,42,75;" +
    "1983-05-12,45,68;" +
    "1983-05-13,51,72;" +
    "1983-05-14,46,80;" +
    "1983-05-15,39,74;" +
    "1983-05-16,43,80;" +
    "1983-05-17,44,84;" +
    "1983-05-18,48,88;" +
    "1983-05-19,55,89;" +
    "1983-05-20,53,93;" +
    "1983-05-21,53,87;" +
    "1983-05-22,50,85;" +
    "1983-05-23,50,80;" +
    "1983-05-24,51,78;" +
    "1983-05-25,50,85;" +
    "1983-05-26,50,88;" +
    "1983-05-27,50,88;" +
    "1983-05-28,51,78;" +
    "1983-05-29,52,70;" +
    "1983-05-30,51,72;" +
    "1983-05-31,51,70;" +
    "1983-06-01,51,71;" +
    "1983-06-02,51,73;" +
    "1983-06-03,50,79;" +
    "1983-06-04,51,86;" +
    "1983-06-05,53,96;" +
    "1983-06-06,56,97;" +
    "1983-06-07,63,91;" +
    "1983-06-08,54,76;" +
    "1983-06-09,54,76;" +
    "1983-06-10,51,80;" +
    "1983-06-11,51,76;" +
    "1983-06-12,46,85;" +
    "1983-06-13,49,95;" +
    "1983-06-14,53,89;" +
    "1983-06-15,53,88;" +
    "1983-06-16,54,97;" +
    "1983-06-17,54,87;" +
    "1983-06-18,48,80;" +
    "1983-06-19,48,87;" +
    "1983-06-20,51,80;" +
    "1983-06-21,49,95;" +
    "1983-06-22,49,85;" +
    "1983-06-23,59,76;" +
    "1983-06-24,46,85;" +
    "1983-06-25,51,89;" +
    "1983-06-26,53,85;" +
    "1983-06-27,54,72;" +
    "1983-06-28,55,74;" +
    "1983-06-29,54,75;" +
    "1983-06-30,52,71;" +
    "1983-07-01,51,73;" +
    "1983-07-02,49,90;" +
    "1983-07-03,51,91;" +
    "1983-07-04,55,97;" +
    "1983-07-05,57,89;" +
    "1983-07-06,53,75;" +
    "1983-07-07,52,72;" +
    "1983-07-08,51,76;" +
    "1983-07-09,48,87;" +
    "1983-07-10,51,95;" +
    "1983-07-11,57,104;" +
    "1983-07-12,60,105;" +
    "1983-07-13,60,105;" +
    "1983-07-14,61,95;" +
    "1983-07-15,53,90;" +
    "1983-07-16,52,78;" +
    "1983-07-17,54,73;" +
    "1983-07-18,51,74;" +
    "1983-07-19,54,77;" +
    "1983-07-20,50,90;" +
    "1983-07-21,51,91;" +
    "1983-07-22,55,77;" +
    "1983-07-23,56,82;" +
    "1983-07-24,55,76;" +
    "1983-07-25,56,76;" +
    "1983-07-26,50,85;" +
    "1983-07-27,53,86;" +
    "1983-07-28,53,84;" +
    "1983-07-29,54,93;" +
    "1983-07-30,54,87;" +
    "1983-07-31,57,93;" +
    "1983-08-01,56,92;" +
    "1983-08-02,56,90;" +
    "1983-08-03,54,88;" +
    "1983-08-04,55,81;" +
    "1983-08-05,49,96;" +
    "1983-08-06,56,106;" +
    "1983-08-07,67,102;" +
    "1983-08-08,63,94;" +
    "1983-08-09,56,86;" +
    "1983-08-10,56,83;" +
    "1983-08-11,49,95;" +
    "1983-08-12,57,103;" +
    "1983-08-13,60,95;" +
    "1983-08-14,59,90;" +
    "1983-08-15,65,102;" +
    "1983-08-16,64,100;" +
    "1983-08-17,57,92;" +
    "1983-08-18,57,80;" +
    "1983-08-19,59,71;" +
    "1983-08-20,55,80;" +
    "1983-08-21,54,79;" +
    "1983-08-22,55,80;" +
    "1983-08-23,52,81;" +
    "1983-08-24,54,83;" +
    "1983-08-25,55,75;" +
    "1983-08-26,53,88;" +
    "1983-08-27,53,89;" +
    "1983-08-28,54,86;" +
    "1983-08-29,55,84;" +
    "1983-08-30,58,86;" +
    "1983-08-31,55,80;" +
    "1983-09-01,56,84;" +
    "1983-09-02,50,89;" +
    "1983-09-03,51,95;" +
    "1983-09-04,56,100;" +
    "1983-09-05,58,101;" +
    "1983-09-06,59,90;" +
    "1983-09-07,60,80;" +
    "1983-09-08,56,80;" +
    "1983-09-09,47,88;" +
    "1983-09-10,47,97;" +
    "1983-09-11,51,100;" +
    "1983-09-12,58,105;" +
    "1983-09-13,63,100;" +
    "1983-09-14,56,104;" +
    "1983-09-15,60,100;" +
    "1983-09-16,53,91;" +
    "1983-09-17,54,94;" +
    "1983-09-18,52,90;" +
    "1983-09-19,51,90;" +
    "1983-09-20,65,94;" +
    "1983-09-21,64,93;" +
    "1983-09-22,62,82;" +
    "1983-09-23,60,78;" +
    "1983-09-24,53,85;" +
    "1983-09-25,53,85;" +
    "1983-09-26,58,70;" +
    "1983-09-27,57,76;" +
    "1983-09-28,52,82;" +
    "1983-09-29,54,73;" +
    "1983-09-30,55,70;" +
    "1983-10-01,54,72;" +
    "1983-10-02,49,75;" +
    "1983-10-03,46,80;" +
    "1983-10-04,49,89;" +
    "1983-10-05,50,84;" +
    "1983-10-06,51,87;" +
    "1983-10-07,54,83;" +
    "1983-10-08,50,76;" +
    "1983-10-09,52,79;" +
    "1983-10-10,48,82;" +
    "1983-10-11,49,86;" +
    "1983-10-12,50,81;" +
    "1983-10-13,52,72;" +
    "1983-10-14,44,76;" +
    "1983-10-15,44,78;" +
    "1983-10-16,45,75;" +
    "1983-10-17,45,79;" +
    "1983-10-18,48,83;" +
    "1983-10-19,43,84;" +
    "1983-10-20,45,85;" +
    "1983-10-21,45,83;" +
    "1983-10-22,45,84;" +
    "1983-10-23,53,76;" +
    "1983-10-24,49,82;" +
    "1983-10-25,45,80;" +
    "1983-10-26,45,85;" +
    "1983-10-27,48,89;" +
    "1983-10-28,49,86;" +
    "1983-10-29,50,74;" +
    "1983-10-30,55,71;" +
    "1983-10-31,57,73;" +
    "1983-11-01,55,70;" +
    "1983-11-02,45,74;" +
    "1983-11-03,46,79;" +
    "1983-11-04,53,71;" +
    "1983-11-05,45,70;" +
    "1983-11-06,43,76;" +
    "1983-11-07,43,65;" +
    "1983-11-08,37,63;" +
    "1983-11-09,45,55;" +
    "1983-11-10,47,70;" +
    "1983-11-11,50,70;" +
    "1983-11-12,46,65;" +
    "1983-11-13,42,58;" +
    "1983-11-14,36,63;" +
    "1983-11-15,36,65;" +
    "1983-11-16,49,62;" +
    "1983-11-17,36,60;" +
    "1983-11-18,42,65;" +
    "1983-11-19,45,60;" +
    "1983-11-20,44,60;" +
    "1983-11-21,30,59;" +
    "1983-11-22,32,50;" +
    "1983-11-23,42,51;" +
    "1983-11-24,44,60;" +
    "1983-11-25,37,57;" +
    "1983-11-26,34,58;" +
    "1983-11-27,41,60;" +
    "1983-11-28,34,61;" +
    "1983-11-29,32,54;" +
    "1983-11-30,26,56;" +
    "1983-12-01,42,61;" +
    "1983-12-02,46,63;" +
    "1983-12-03,48,56;" +
    "1983-12-04,35,59;" +
    "1983-12-05,30,59;" +
    "1983-12-06,43,63;" +
    "1983-12-07,34,62;" +
    "1983-12-08,49,67;" +
    "1983-12-09,50,59;" +
    "1983-12-10,48,56;" +
    "1983-12-11,46,58;" +
    "1983-12-12,37,57;" +
    "1983-12-13,44,60;" +
    "1983-12-14,51,65;" +
    "1983-12-15,51,63;" +
    "1983-12-16,46,56;" +
    "1983-12-17,47,58;" +
    "1983-12-18,40,54;" +
    "1983-12-19,44,54;" +
    "1983-12-20,35,54;" +
    "1983-12-21,29,49;" +
    "1983-12-22,38,46;" +
    "1983-12-23,36,44;" +
    "1983-12-24,40,57;" +
    "1983-12-25,51,62;" +
    "1983-12-26,46,57;" +
    "1983-12-27,47,57;" +
    "1983-12-28,40,56;" +
    "1983-12-29,44,57;" +
    "1983-12-30,49,63;" +
    "1983-12-31,43,60;"