export const data = "1998-01-01,37,56;" +
    "1998-01-01,41,55;" +
    "1998-01-02,42,61;" +
    "1998-01-02,49,59;" +
    "1998-01-03,43,57;" +
    "1998-01-03,49,55;" +
    "1998-01-04,43,55;" +
    "1998-01-04,38,53;" +
    "1998-01-05,30,52;" +
    "1998-01-05,33,53;" +
    "1998-01-06,33,54;" +
    "1998-01-06,35,52;" +
    "1998-01-07,43,46;" +
    "1998-01-07,43,47;" +
    "1998-01-08,43,46;" +
    "1998-01-08,42,46;" +
    "1998-01-09,44,49;" +
    "1998-01-09,44,49;" +
    "1998-01-10,42,55;" +
    "1998-01-10,47,54;" +
    "1998-01-11,50,56;" +
    "1998-01-11,50,56;" +
    "1998-01-12,50,59;" +
    "1998-01-12,46,57;" +
    "1998-01-13,41,56;" +
    "1998-01-13,44,55;" +
    "1998-01-14,46,52;" +
    "1998-01-14,49,56;" +
    "1998-01-15,51,59;" +
    "1998-01-15,49,58;" +
    "1998-01-16,50,63;" +
    "1998-01-16,49,58;" +
    "1998-01-17,48,69;" +
    "1998-01-17,51,66;" +
    "1998-01-18,43,64;" +
    "1998-01-18,44,62;" +
    "1998-01-19,41,54;" +
    "1998-01-19,41,52;" +
    "1998-01-20,37,54;" +
    "1998-01-20,38,54;" +
    "1998-01-21,36,56;" +
    "1998-01-21,37,55;" +
    "1998-01-22,36,54;" +
    "1998-01-22,39,55;" +
    "1998-01-23,37,57;" +
    "1998-01-23,37,56;" +
    "1998-01-24,39,60;" +
    "1998-01-24,42,62;" +
    "1998-01-25,37,57;" +
    "1998-01-25,37,60;" +
    "1998-01-26,44,65;" +
    "1998-01-26,47,61;" +
    "1998-01-27,49,58;" +
    "1998-01-27,50,56;" +
    "1998-01-28,46,58;" +
    "1998-01-28,49,59;" +
    "1998-01-29,49,61;" +
    "1998-01-29,45,59;" +
    "1998-01-30,37,53;" +
    "1998-01-30,41,52;" +
    "1998-01-31,46,62;" +
    "1998-01-31,45,60;" +
    "1998-02-01,45,62;" +
    "1998-02-01,51,58;" +
    "1998-02-02,47,61;" +
    "1998-02-02,50,61;" +
    "1998-02-03,48,61;" +
    "1998-02-03,47,52;" +
    "1998-02-04,41,59;" +
    "1998-02-04,45,56;" +
    "1998-02-05,48,58;" +
    "1998-02-05,48,58;" +
    "1998-02-06,49,60;" +
    "1998-02-06,48,59;" +
    "1998-02-07,43,58;" +
    "1998-02-07,48,55;" +
    "1998-02-08,42,54;" +
    "1998-02-08,45,49;" +
    "1998-02-09,41,58;" +
    "1998-02-09,41,59;" +
    "1998-02-10,39,51;" +
    "1998-02-10,42,50;" +
    "1998-02-11,44,59;" +
    "1998-02-11,46,60;" +
    "1998-02-12,45,54;" +
    "1998-02-12,45,54;" +
    "1998-02-13,45,61;" +
    "1998-02-13,47,60;" +
    "1998-02-14,46,57;" +
    "1998-02-14,43,55;" +
    "1998-02-15,41,59;" +
    "1998-02-15,42,58;" +
    "1998-02-16,36,54;" +
    "1998-02-16,40,54;" +
    "1998-02-17,38,56;" +
    "1998-02-17,41,56;" +
    "1998-02-18,39,61;" +
    "1998-02-18,43,60;" +
    "1998-02-19,43,56;" +
    "1998-02-19,40,55;" +
    "1998-02-20,35,55;" +
    "1998-02-20,36,54;" +
    "1998-02-21,45,54;" +
    "1998-02-21,45,52;" +
    "1998-02-22,39,58;" +
    "1998-02-22,42,56;" +
    "1998-02-23,45,56;" +
    "1998-02-23,44,53;" +
    "1998-02-24,37,58;" +
    "1998-02-24,40,56;" +
    "1998-02-25,45,58;" +
    "1998-02-25,44,55;" +
    "1998-02-26,41,59;" +
    "1998-02-26,42,56;" +
    "1998-02-27,49,64;" +
    "1998-02-27,47,60;" +
    "1998-02-28,45,62;" +
    "1998-02-28,47,61;" +
    "1998-03-01,45,60;" +
    "1998-03-01,45,59;" +
    "1998-03-02,44,64;" +
    "1998-03-02,47,61;" +
    "1998-03-03,44,58;" +
    "1998-03-03,43,60;" +
    "1998-03-04,36,58;" +
    "1998-03-04,39,58;" +
    "1998-03-05,37,52;" +
    "1998-03-05,39,47;" +
    "1998-03-06,36,58;" +
    "1998-03-06,37,56;" +
    "1998-03-07,33,52;" +
    "1998-03-07,36,53;" +
    "1998-03-08,44,59;" +
    "1998-03-08,44,58;" +
    "1998-03-09,40,64;" +
    "1998-03-09,41,62;" +
    "1998-03-10,38,69;" +
    "1998-03-10,43,70;" +
    "1998-03-11,44,70;" +
    "1998-03-11,48,71;" +
    "1998-03-12,45,64;" +
    "1998-03-12,49,61;" +
    "1998-03-13,49,60;" +
    "1998-03-13,48,59;" +
    "1998-03-14,42,73;" +
    "1998-03-14,45,72;" +
    "1998-03-15,50,71;" +
    "1998-03-15,50,68;" +
    "1998-03-16,52,68;" +
    "1998-03-16,51,65;" +
    "1998-03-17,47,71;" +
    "1998-03-17,48,73;" +
    "1998-03-18,45,73;" +
    "1998-03-18,46,73;" +
    "1998-03-19,48,73;" +
    "1998-03-19,49,70;" +
    "1998-03-20,54,63;" +
    "1998-03-20,51,60;" +
    "1998-03-21,49,65;" +
    "1998-03-21,50,63;" +
    "1998-03-22,52,73;" +
    "1998-03-22,53,71;" +
    "1998-03-23,47,73;" +
    "1998-03-23,50,67;" +
    "1998-03-24,53,70;" +
    "1998-03-24,51,68;" +
    "1998-03-25,48,64;" +
    "1998-03-25,48,60;" +
    "1998-03-26,47,62;" +
    "1998-03-26,47,58;" +
    "1998-03-27,49,61;" +
    "1998-03-27,42,56;" +
    "1998-03-28,38,57;" +
    "1998-03-28,38,52;" +
    "1998-03-29,34,61;" +
    "1998-03-29,35,58;" +
    "1998-03-30,35,66;" +
    "1998-03-30,38,64;" +
    "1998-03-31,44,53;" +
    "1998-03-31,43,49;" +
    "1998-04-01,42,57;" +
    "1998-04-01,43,55;" +
    "1998-04-02,48,59;" +
    "1998-04-02,46,56;" +
    "1998-04-03,43,57;" +
    "1998-04-03,42,55;" +
    "1998-04-04,43,57;" +
    "1998-04-04,43,55;" +
    "1998-04-05,43,60;" +
    "1998-04-05,44,59;" +
    "1998-04-06,46,57;" +
    "1998-04-06,45,58;" +
    "1998-04-06,44,54;" +
    "1998-04-07,45,58;" +
    "1998-04-07,45,61;" +
    "1998-04-07,44,57;" +
    "1998-04-08,43,60;" +
    "1998-04-08,43,62;" +
    "1998-04-08,43,58;" +
    "1998-04-09,43,65;" +
    "1998-04-09,43,67;" +
    "1998-04-09,43,62;" +
    "1998-04-10,48,62;" +
    "1998-04-10,48,64;" +
    "1998-04-10,47,59;" +
    "1998-04-11,46,59;" +
    "1998-04-11,47,60;" +
    "1998-04-11,44,57;" +
    "1998-04-12,40,58;" +
    "1998-04-12,40,59;" +
    "1998-04-12,40,56;" +
    "1998-04-13,42,55;" +
    "1998-04-13,43,56;" +
    "1998-04-13,41,53;" +
    "1998-04-14,38,55;" +
    "1998-04-14,37,56;" +
    "1998-04-14,38,54;" +
    "1998-04-15,36,60;" +
    "1998-04-15,36,63;" +
    "1998-04-15,38,60;" +
    "1998-04-16,39,68;" +
    "1998-04-16,38,69;" +
    "1998-04-16,41,66;" +
    "1998-04-17,41,69;" +
    "1998-04-17,40,70;" +
    "1998-04-17,42,66;" +
    "1998-04-18,41,70;" +
    "1998-04-18,41,72;" +
    "1998-04-18,44,68;" +
    "1998-04-19,43,73;" +
    "1998-04-19,43,75;" +
    "1998-04-19,45,72;" +
    "1998-04-20,47,79;" +
    "1998-04-20,47,80;" +
    "1998-04-20,47,78;" +
    "1998-04-21,47,82;" +
    "1998-04-21,49,82;" +
    "1998-04-21,52,81;" +
    "1998-04-22,54,78;" +
    "1998-04-22,56,80;" +
    "1998-04-22,53,76;" +
    "1998-04-23,54,67;" +
    "1998-04-23,53,70;" +
    "1998-04-23,52,61;" +
    "1998-04-24,49,62;" +
    "1998-04-24,51,63;" +
    "1998-04-24,46,59;" +
    "1998-04-25,42,71;" +
    "1998-04-25,43,73;" +
    "1998-04-25,43,68;" +
    "1998-04-26,44,78;" +
    "1998-04-26,45,79;" +
    "1998-04-26,47,76;" +
    "1998-04-27,47,80;" +
    "1998-04-27,48,80;" +
    "1998-04-27,52,81;" +
    "1998-04-28,51,83;" +
    "1998-04-28,53,84;" +
    "1998-04-28,54,84;" +
    "1998-04-29,53,86;" +
    "1998-04-29,55,87;" +
    "1998-04-29,51,85;" +
    "1998-04-30,52,72;" +
    "1998-04-30,52,80;" +
    "1998-04-30,51,70;" +
    "1998-05-01,55,68;" +
    "1998-05-01,55,69;" +
    "1998-05-01,54,64;" +
    "1998-05-02,54,69;" +
    "1998-05-02,54,66;" +
    "1998-05-02,54,67;" +
    "1998-05-03,49,71;" +
    "1998-05-03,49,71;" +
    "1998-05-03,52,71;" +
    "1998-05-04,51,66;" +
    "1998-05-04,54,66;" +
    "1998-05-04,52,67;" +
    "1998-05-05,52,61;" +
    "1998-05-05,51,60;" +
    "1998-05-05,52,60;" +
    "1998-05-06,53,64;" +
    "1998-05-06,52,65;" +
    "1998-05-06,52,61;" +
    "1998-05-07,54,61;" +
    "1998-05-07,53,63;" +
    "1998-05-07,52,59;" +
    "1998-05-08,51,60;" +
    "1998-05-08,52,60;" +
    "1998-05-08,49,56;" +
    "1998-05-09,48,60;" +
    "1998-05-09,48,62;" +
    "1998-05-09,46,58;" +
    "1998-05-10,50,66;" +
    "1998-05-10,49,68;" +
    "1998-05-10,49,64;" +
    "1998-05-11,48,56;" +
    "1998-05-11,49,62;" +
    "1998-05-11,47,53;" +
    "1998-05-12,48,58;" +
    "1998-05-12,47,57;" +
    "1998-05-12,46,57;" +
    "1998-05-13,49,62;" +
    "1998-05-13,48,63;" +
    "1998-05-13,47,59;" +
    "1998-05-14,47,59;" +
    "1998-05-14,51,61;" +
    "1998-05-14,47,60;" +
    "1998-05-15,44,66;" +
    "1998-05-15,46,67;" +
    "1998-05-15,46,64;" +
    "1998-05-16,47,62;" +
    "1998-05-16,49,63;" +
    "1998-05-16,45,58;" +
    "1998-05-17,39,68;" +
    "1998-05-17,41,68;" +
    "1998-05-17,42,65;" +
    "1998-05-18,41,73;" +
    "1998-05-18,42,74;" +
    "1998-05-18,44,70;" +
    "1998-05-19,50,65;" +
    "1998-05-19,50,67;" +
    "1998-05-19,46,61;" +
    "1998-05-20,45,66;" +
    "1998-05-20,46,66;" +
    "1998-05-20,45,63;" +
    "1998-05-21,48,64;" +
    "1998-05-21,48,66;" +
    "1998-05-21,47,63;" +
    "1998-05-22,46,74;" +
    "1998-05-22,46,74;" +
    "1998-05-22,47,70;" +
    "1998-05-23,55,71;" +
    "1998-05-23,54,71;" +
    "1998-05-23,53,67;" +
    "1998-05-24,53,69;" +
    "1998-05-24,53,70;" +
    "1998-05-24,51,66;" +
    "1998-05-25,49,64;" +
    "1998-05-25,51,66;" +
    "1998-05-25,47,60;" +
    "1998-05-26,45,63;" +
    "1998-05-26,46,63;" +
    "1998-05-26,45,61;" +
    "1998-05-27,44,63;" +
    "1998-05-27,44,63;" +
    "1998-05-27,46,63;" +
    "1998-05-28,51,59;" +
    "1998-05-28,50,62;" +
    "1998-05-28,49,56;" +
    "1998-05-29,51,61;" +
    "1998-05-29,50,63;" +
    "1998-05-29,50,60;" +
    "1998-05-30,51,73;" +
    "1998-05-30,51,75;" +
    "1998-05-30,50,72;" +
    "1998-05-31,49,77;" +
    "1998-05-31,49,78;" +
    "1998-05-31,51,75;" +
    "1998-06-01,54,70;" +
    "1998-06-01,53,72;" +
    "1998-06-01,52,66;" +
    "1998-06-02,55,66;" +
    "1998-06-02,53,69;" +
    "1998-06-02,52,63;" +
    "1998-06-03,54,64;" +
    "1998-06-03,55,64;" +
    "1998-06-03,52,62;" +
    "1998-06-04,47,66;" +
    "1998-06-04,50,68;" +
    "1998-06-04,49,64;" +
    "1998-06-05,50,75;" +
    "1998-06-05,51,76;" +
    "1998-06-05,50,72;" +
    "1998-06-06,53,70;" +
    "1998-06-06,52,61;" +
    "1998-06-07,55,63;" +
    "1998-06-07,54,61;" +
    "1998-06-08,,;" +
    "1998-06-08,54,74;" +
    "1998-06-08,53,71;" +
    "1998-06-09,54,74;" +
    "1998-06-09,55,76;" +
    "1998-06-09,54,71;" +
    "1998-06-10,56,63;" +
    "1998-06-10,56,68;" +
    "1998-06-10,54,61;" +
    "1998-06-11,53,67;" +
    "1998-06-11,52,68;" +
    "1998-06-11,52,66;" +
    "1998-06-12,50,73;" +
    "1998-06-12,50,74;" +
    "1998-06-12,51,69;" +
    "1998-06-13,57,78;" +
    "1998-06-13,57,80;" +
    "1998-06-13,55,75;" +
    "1998-06-14,54,89;" +
    "1998-06-14,54,89;" +
    "1998-06-14,54,87;" +
    "1998-06-15,59,93;" +
    "1998-06-15,59,94;" +
    "1998-06-15,59,90;" +
    "1998-06-16,57,85;" +
    "1998-06-16,56,89;" +
    "1998-06-16,56,82;" +
    "1998-06-17,57,85;" +
    "1998-06-17,59,86;" +
    "1998-06-17,60,83;" +
    "1998-06-18,52,88;" +
    "1998-06-18,54,88;" +
    "1998-06-18,54,84;" +
    "1998-06-19,53,79;" +
    "1998-06-19,52,82;" +
    "1998-06-19,50,75;" +
    "1998-06-20,52,75;" +
    "1998-06-20,52,76;" +
    "1998-06-20,51,72;" +
    "1998-06-21,55,74;" +
    "1998-06-21,54,76;" +
    "1998-06-21,53,70;" +
    "1998-06-22,56,75;" +
    "1998-06-22,55,77;" +
    "1998-06-22,53,73;" +
    "1998-06-23,54,71;" +
    "1998-06-23,56,72;" +
    "1998-06-23,52,67;" +
    "1998-06-24,57,72;" +
    "1998-06-24,56,73;" +
    "1998-06-24,55,68;" +
    "1998-06-25,55,69;" +
    "1998-06-25,57,71;" +
    "1998-06-25,52,66;" +
    "1998-06-26,51,80;" +
    "1998-06-26,52,82;" +
    "1998-06-26,51,78;" +
    "1998-06-27,50,88;" +
    "1998-06-27,52,90;" +
    "1998-06-27,54,87;" +
    "1998-06-28,54,75;" +
    "1998-06-28,54,83;" +
    "1998-06-28,52,72;" +
    "1998-06-29,53,70;" +
    "1998-06-29,54,73;" +
    "1998-06-29,52,67;" +
    "1998-06-30,55,71;" +
    "1998-06-30,54,73;" +
    "1998-06-30,52,66;" +
    "1998-07-01,54,69;" +
    "1998-07-01,53,71;" +
    "1998-07-01,52,65;" +
    "1998-07-02,54,69;" +
    "1998-07-02,54,71;" +
    "1998-07-02,52,66;" +
    "1998-07-03,54,78;" +
    "1998-07-03,54,80;" +
    "1998-07-03,52,76;" +
    "1998-07-04,54,84;" +
    "1998-07-04,54,86;" +
    "1998-07-04,52,83;" +
    "1998-07-05,54,85;" +
    "1998-07-05,54,88;" +
    "1998-07-05,53,84;" +
    "1998-07-06,56,91;" +
    "1998-07-06,56,93;" +
    "1998-07-06,55,89;" +
    "1998-07-07,58,93;" +
    "1998-07-07,60,96;" +
    "1998-07-07,63,92;" +
    "1998-07-08,55,84;" +
    "1998-07-08,56,88;" +
    "1998-07-08,53,83;" +
    "1998-07-09,55,75;" +
    "1998-07-09,54,81;" +
    "1998-07-09,52,71;" +
    "1998-07-10,54,73;" +
    "1998-07-10,54,75;" +
    "1998-07-10,52,70;" +
    "1998-07-11,56,84;" +
    "1998-07-11,55,86;" +
    "1998-07-11,54,83;" +
    "1998-07-12,53,92;" +
    "1998-07-12,56,95;" +
    "1998-07-12,57,92;" +
    "1998-07-13,58,83;" +
    "1998-07-13,59,90;" +
    "1998-07-13,57,82;" +
    "1998-07-14,56,89;" +
    "1998-07-14,56,91;" +
    "1998-07-14,55,87;" +
    "1998-07-15,57,99;" +
    "1998-07-15,59,102;" +
    "1998-07-15,61,99;" +
    "1998-07-16,60,99;" +
    "1998-07-16,62,102;" +
    "1998-07-16,64,100;" +
    "1998-07-17,60,95;" +
    "1998-07-17,60,99;" +
    "1998-07-17,59,94;" +
    "1998-07-18,57,102;" +
    "1998-07-18,60,104;" +
    "1998-07-18,61,104;" +
    "1998-07-19,62,98;" +
    "1998-07-19,65,101;" +
    "1998-07-19,67,98;" +
    "1998-07-20,57,82;" +
    "1998-07-20,62,93;" +
    "1998-07-20,55,80;" +
    "1998-07-21,56,80;" +
    "1998-07-21,57,82;" +
    "1998-07-21,54,79;" +
    "1998-07-22,56,78;" +
    "1998-07-22,56,82;" +
    "1998-07-22,54,77;" +
    "1998-07-23,57,83;" +
    "1998-07-23,57,84;" +
    "1998-07-23,56,80;" +
    "1998-07-24,57,82;" +
    "1998-07-24,56,84;" +
    "1998-07-24,55,81;" +
    "1998-07-25,57,93;" +
    "1998-07-25,58,96;" +
    "1998-07-25,57,92;" +
    "1998-07-26,62,93;" +
    "1998-07-26,62,95;" +
    "1998-07-26,60,91;" +
    "1998-07-27,58,93;" +
    "1998-07-27,59,94;" +
    "1998-07-27,57,91;" +
    "1998-07-28,56,82;" +
    "1998-07-28,55,86;" +
    "1998-07-28,53,78;" +
    "1998-07-29,58,73;" +
    "1998-07-29,58,74;" +
    "1998-07-29,56,70;" +
    "1998-07-30,58,72;" +
    "1998-07-30,58,73;" +
    "1998-07-30,57,70;" +
    "1998-07-31,59,84;" +
    "1998-07-31,58,85;" +
    "1998-07-31,57,82;" +
    "1998-08-01,58,97;" +
    "1998-08-01,59,97;" +
    "1998-08-01,58,94;" +
    "1998-08-02,62,105;" +
    "1998-08-02,63,103;" +
    "1998-08-02,66,103;" +
    "1998-08-03,63,109;" +
    "1998-08-03,65,108;" +
    "1998-08-03,70,105;" +
    "1998-08-04,66,112;" +
    "1998-08-04,68,110;" +
    "1998-08-04,75,110;" +
    "1998-08-05,67,103;" +
    "1998-08-05,68,105;" +
    "1998-08-05,69,100;" +
    "1998-08-06,62,93;" +
    "1998-08-06,64,98;" +
    "1998-08-06,63,90;" +
    "1998-08-07,56,82;" +
    "1998-08-07,57,89;" +
    "1998-08-07,54,79;" +
    "1998-08-08,55,79;" +
    "1998-08-08,54,81;" +
    "1998-08-08,54,75;" +
    "1998-08-09,53,88;" +
    "1998-08-09,54,90;" +
    "1998-08-09,53,79;" +
    "1998-08-10,53,98;" +
    "1998-08-10,55,99;" +
    "1998-08-10,57,93;" +
    "1998-08-11,61,101;" +
    "1998-08-11,62,101;" +
    "1998-08-11,66,72;" +
    "1998-08-12,66,106;" +
    "1998-08-12,66,105;" +
    "1998-08-12,73,74;" +
    "1998-08-13,69,100;" +
    "1998-08-13,70,100;" +
    "1998-08-13,70,72;" +
    "1998-08-14,67,92;" +
    "1998-08-14,68,94;" +
    "1998-08-14,69,69;" +
    "1998-08-15,58,86;" +
    "1998-08-15,59,90;" +
    "1998-08-16,57,85;" +
    "1998-08-16,55,86;" +
    "1998-08-17,56,73;" +
    "1998-08-17,57,77;" +
    "1998-08-18,55,73;" +
    "1998-08-18,56,75;" +
    "1998-08-19,55,80;" +
    "1998-08-19,55,82;" +
    "1998-08-20,57,75;" +
    "1998-08-20,57,76;" +
    "1998-08-21,51,86;" +
    "1998-08-21,50,87;" +
    "1998-08-22,54,90;" +
    "1998-08-22,54,90;" +
    "1998-08-23,53,91;" +
    "1998-08-23,53,92;" +
    "1998-08-24,55,94;" +
    "1998-08-24,55,94;" +
    "1998-08-25,56,74;" +
    "1998-08-25,56,86;" +
    "1998-08-26,57,88;" +
    "1998-08-26,55,89;" +
    "1998-08-27,54,96;" +
    "1998-08-27,54,97;" +
    "1998-08-28,59,96;" +
    "1998-08-28,59,96;" +
    "1998-08-29,54,97;" +
    "1998-08-29,56,99;" +
    "1998-08-30,57,95;" +
    "1998-08-30,56,96;" +
    "1998-08-31,56,103;" +
    "1998-08-31,56,104;" +
    "1998-09-01,62,100;" +
    "1998-09-01,63,101;" +
    "1998-09-02,62,104;" +
    "1998-09-02,63,103;" +
    "1998-09-02,89,97;" +
    "1998-09-03,66,100;" +
    "1998-09-03,65,100;" +
    "1998-09-04,68,94;" +
    "1998-09-04,68,94;" +
    "1998-09-04,72,93;" +
    "1998-09-05,67,90;" +
    "1998-09-05,68,91;" +
    "1998-09-05,66,91;" +
    "1998-09-06,71,95;" +
    "1998-09-06,69,96;" +
    "1998-09-06,69,94;" +
    "1998-09-07,67,98;" +
    "1998-09-07,68,99;" +
    "1998-09-07,70,97;" +
    "1998-09-08,57,84;" +
    "1998-09-08,64,90;" +
    "1998-09-08,55,83;" +
    "1998-09-09,53,64;" +
    "1998-09-09,56,67;" +
    "1998-09-09,52,61;" +
    "1998-09-10,48,82;" +
    "1998-09-10,48,83;" +
    "1998-09-10,49,82;" +
    "1998-09-11,53,88;" +
    "1998-09-11,55,88;" +
    "1998-09-11,58,87;" +
    "1998-09-12,58,94;" +
    "1998-09-12,59,93;" +
    "1998-09-12,62,94;" +
    "1998-09-13,60,96;" +
    "1998-09-13,62,97;" +
    "1998-09-13,64,95;" +
    "1998-09-14,61,90;" +
    "1998-09-14,62,91;" +
    "1998-09-14,59,89;" +
    "1998-09-15,57,81;" +
    "1998-09-15,59,84;" +
    "1998-09-15,55,80;" +
    "1998-09-16,55,85;" +
    "1998-09-16,56,88;" +
    "1998-09-16,55,84;" +
    "1998-09-17,55,79;" +
    "1998-09-17,55,79;" +
    "1998-09-17,54,77;" +
    "1998-09-18,55,78;" +
    "1998-09-18,55,79;" +
    "1998-09-18,54,77;" +
    "1998-09-19,51,85;" +
    "1998-09-19,52,86;" +
    "1998-09-19,54,84;" +
    "1998-09-20,52,79;" +
    "1998-09-20,53,81;" +
    "1998-09-20,54,79;" +
    "1998-09-21,50,75;" +
    "1998-09-21,50,76;" +
    "1998-09-21,51,73;" +
    "1998-09-22,55,73;" +
    "1998-09-22,55,75;" +
    "1998-09-22,54,74;" +
    "1998-09-23,52,73;" +
    "1998-09-23,51,75;" +
    "1998-09-23,54,74;" +
    "1998-09-24,52,71;" +
    "1998-09-24,53,72;" +
    "1998-09-24,53,69;" +
    "1998-09-25,56,69;" +
    "1998-09-25,55,69;" +
    "1998-09-25,54,67;" +
    "1998-09-26,50,62;" +
    "1998-09-26,56,66;" +
    "1998-09-26,51,60;" +
    "1998-09-27,52,63;" +
    "1998-09-27,50,63;" +
    "1998-09-27,51,63;" +
    "1998-09-28,57,74;" +
    "1998-09-28,57,75;" +
    "1998-09-28,56,73;" +
    "1998-09-29,55,66;" +
    "1998-09-29,55,68;" +
    "1998-09-29,54,65;" +
    "1998-09-30,55,73;" +
    "1998-09-30,55,74;" +
    "1998-09-30,53,72;" +
    "1998-10-01,56,65;" +
    "1998-10-01,56,66;" +
    "1998-10-01,55,64;" +
    "1998-10-02,54,67;" +
    "1998-10-02,56,68;" +
    "1998-10-02,52,65;" +
    "1998-10-03,47,69;" +
    "1998-10-03,52,69;" +
    "1998-10-03,49,68;" +
    "1998-10-04,44,76;" +
    "1998-10-04,44,77;" +
    "1998-10-04,47,75;" +
    "1998-10-05,48,78;" +
    "1998-10-05,50,77;" +
    "1998-10-05,58,78;" +
    "1998-10-06,48,85;" +
    "1998-10-06,48,84;" +
    "1998-10-06,54,84;" +
    "1998-10-07,46,78;" +
    "1998-10-07,47,81;" +
    "1998-10-07,52,76;" +
    "1998-10-08,50,72;" +
    "1998-10-08,54,74;" +
    "1998-10-08,51,72;" +
    "1998-10-09,45,70;" +
    "1998-10-09,47,72;" +
    "1998-10-09,49,69;" +
    "1998-10-10,43,74;" +
    "1998-10-10,44,75;" +
    "1998-10-10,48,74;" +
    "1998-10-11,40,77;" +
    "1998-10-11,42,78;" +
    "1998-10-11,47,77;" +
    "1998-10-12,46,73;" +
    "1998-10-12,47,75;" +
    "1998-10-12,49,74;" +
    "1998-10-13,50,74;" +
    "1998-10-13,51,74;" +
    "1998-10-13,52,73;" +
    "1998-10-14,48,72;" +
    "1998-10-14,50,72;" +
    "1998-10-14,50,69;" +
    "1998-10-15,44,72;" +
    "1998-10-15,46,73;" +
    "1998-10-15,46,71;" +
    "1998-10-16,44,72;" +
    "1998-10-16,46,71;" +
    "1998-10-16,52,70;" +
    "1998-10-17,46,75;" +
    "1998-10-17,49,74;" +
    "1998-10-17,54,74;" +
    "1998-10-18,40,78;" +
    "1998-10-18,43,80;" +
    "1998-10-18,47,78;" +
    "1998-10-19,44,81;" +
    "1998-10-19,45,81;" +
    "1998-10-19,50,81;" +
    "1998-10-20,45,82;" +
    "1998-10-20,47,82;" +
    "1998-10-20,56,81;" +
    "1998-10-21,46,85;" +
    "1998-10-21,46,84;" +
    "1998-10-21,55,86;" +
    "1998-10-22,47,74;" +
    "1998-10-22,48,76;" +
    "1998-10-22,48,73;" +
    "1998-10-23,43,74;" +
    "1998-10-23,45,76;" +
    "1998-10-23,48,74;" +
    "1998-10-24,50,64;" +
    "1998-10-24,55,65;" +
    "1998-10-24,49,63;" +
    "1998-10-25,43,69;" +
    "1998-10-25,45,71;" +
    "1998-10-25,47,67;" +
    "1998-10-26,47,72;" +
    "1998-10-26,48,72;" +
    "1998-10-26,53,72;" +
    "1998-10-27,46,66;" +
    "1998-10-27,46,68;" +
    "1998-10-27,49,66;" +
    "1998-10-28,53,60;" +
    "1998-10-28,55,60;" +
    "1998-10-28,52,59;" +
    "1998-10-29,45,65;" +
    "1998-10-29,49,67;" +
    "1998-10-29,49,64;" +
    "1998-10-30,39,71;" +
    "1998-10-30,41,73;" +
    "1998-10-30,44,70;" +
    "1998-10-31,37,72;" +
    "1998-10-31,39,75;" +
    "1998-10-31,44,73;" +
    "1998-11-01,44,63;" +
    "1998-11-01,50,65;" +
    "1998-11-01,46,62;" +
    "1998-11-02,41,66;" +
    "1998-11-02,42,69;" +
    "1998-11-02,44,69;" +
    "1998-11-03,42,68;" +
    "1998-11-03,43,68;" +
    "1998-11-03,45,66;" +
    "1998-11-04,50,66;" +
    "1998-11-04,51,68;" +
    "1998-11-04,51,67;" +
    "1998-11-05,45,65;" +
    "1998-11-05,48,67;" +
    "1998-11-05,46,63;" +
    "1998-11-06,40,57;" +
    "1998-11-06,44,59;" +
    "1998-11-06,44,57;" +
    "1998-11-07,47,57;" +
    "1998-11-07,50,58;" +
    "1998-11-07,48,56;" +
    "1998-11-08,40,57;" +
    "1998-11-08,46,59;" +
    "1998-11-08,41,56;" +
    "1998-11-09,40,59;" +
    "1998-11-09,38,62;" +
    "1998-11-09,39,84;" +
    "1998-11-10,45,55;" +
    "1998-11-10,46,55;" +
    "1998-11-10,40,74;" +
    "1998-11-11,39,54;" +
    "1998-11-11,46,53;" +
    "1998-11-11,45,68;" +
    "1998-11-12,35,61;" +
    "1998-11-12,35,62;" +
    "1998-11-12,42,100;" +
    "1998-11-13,37,60;" +
    "1998-11-13,38,61;" +
    "1998-11-13,54,82;" +
    "1998-11-14,36,63;" +
    "1998-11-14,36,65;" +
    "1998-11-14,49,78;" +
    "1998-11-15,40,67;" +
    "1998-11-15,41,71;" +
    "1998-11-15,57,83;" +
    "1998-11-16,49,63;" +
    "1998-11-16,52,63;" +
    "1998-11-16,55,71;" +
    "1998-11-17,41,61;" +
    "1998-11-17,51,63;" +
    "1998-11-17,49,66;" +
    "1998-11-18,39,62;" +
    "1998-11-18,40,64;" +
    "1998-11-18,44,65;" +
    "1998-11-19,35,65;" +
    "1998-11-19,37,64;" +
    "1998-11-19,44,65;" +
    "1998-11-20,33,64;" +
    "1998-11-20,35,66;" +
    "1998-11-20,39,64;" +
    "1998-11-21,42,66;" +
    "1998-11-21,44,69;" +
    "1998-11-21,46,65;" +
    "1998-11-22,49,65;" +
    "1998-11-22,51,66;" +
    "1998-11-22,53,70;" +
    "1998-11-23,53,65;" +
    "1998-11-23,54,66;" +
    "1998-11-23,57,65;" +
    "1998-11-24,45,59;" +
    "1998-11-24,46,61;" +
    "1998-11-24,50,62;" +
    "1998-11-25,38,63;" +
    "1998-11-25,39,66;" +
    "1998-11-25,43,65;" +
    "1998-11-26,39,67;" +
    "1998-11-26,40,69;" +
    "1998-11-26,45,67;" +
    "1998-11-27,49,55;" +
    "1998-11-27,50,58;" +
    "1998-11-27,51,54;" +
    "1998-11-28,45,56;" +
    "1998-11-28,46,58;" +
    "1998-11-28,48,59;" +
    "1998-11-29,47,60;" +
    "1998-11-29,47,54;" +
    "1998-11-29,47,61;" +
    "1998-11-30,51,63;" +
    "1998-11-30,52,65;" +
    "1998-11-30,50,62;" +
    "1998-12-01,40,58;" +
    "1998-12-01,40,60;" +
    "1998-12-01,48,62;" +
    "1998-12-02,43,59;" +
    "1998-12-02,44,61;" +
    "1998-12-02,49,61;" +
    "1998-12-03,37,57;" +
    "1998-12-03,46,57;" +
    "1998-12-03,44,59;" +
    "1998-12-04,32,49;" +
    "1998-12-04,33,49;" +
    "1998-12-04,40,55;" +
    "1998-12-05,28,50;" +
    "1998-12-05,29,52;" +
    "1998-12-05,37,52;" +
    "1998-12-06,31,52;" +
    "1998-12-06,32,54;" +
    "1998-12-06,37,55;" +
    "1998-12-07,29,51;" +
    "1998-12-07,29,54;" +
    "1998-12-07,32,53;" +
    "1998-12-08,34,47;" +
    "1998-12-08,42,46;" +
    "1998-12-08,40,47;" +
    "1998-12-09,32,53;" +
    "1998-12-09,34,54;" +
    "1998-12-09,40,53;" +
    "1998-12-10,32,54;" +
    "1998-12-10,33,54;" +
    "1998-12-10,37,56;" +
    "1998-12-11,34,56;" +
    "1998-12-11,35,57;" +
    "1998-12-11,39,59;" +
    "1998-12-12,33,56;" +
    "1998-12-12,36,56;" +
    "1998-12-12,40,57;" +
    "1998-12-13,38,61;" +
    "1998-12-13,40,64;" +
    "1998-12-13,41,62;" +
    "1998-12-14,38,58;" +
    "1998-12-14,46,59;" +
    "1998-12-14,45,57;" +
    "1998-12-15,38,61;" +
    "1998-12-15,40,61;" +
    "1998-12-15,49,60;" +
    "1998-12-16,42,69;" +
    "1998-12-16,43,70;" +
    "1998-12-16,51,73;" +
    "1998-12-17,39,69;" +
    "1998-12-17,42,70;" +
    "1998-12-17,49,70;" +
    "1998-12-18,35,67;" +
    "1998-12-18,38,69;" +
    "1998-12-18,45,67;" +
    "1998-12-19,35,50;" +
    "1998-12-19,37,53;" +
    "1998-12-19,38,50;" +
    "1998-12-20,32,44;" +
    "1998-12-20,36,45;" +
    "1998-12-20,31,43;" +
    "1998-12-21,23,41;" +
    "1998-12-21,28,43;" +
    "1998-12-21,26,44;" +
    "1998-12-22,23,46;" +
    "1998-12-22,25,47;" +
    "1998-12-22,27,45;" +
    "1998-12-23,23,46;" +
    "1998-12-23,28,47;" +
    "1998-12-23,29,46;" +
    "1998-12-24,21,45;" +
    "1998-12-24,23,44;" +
    "1998-12-24,26,44;" +
    "1998-12-25,25,52;" +
    "1998-12-25,27,53;" +
    "1998-12-25,30,52;" +
    "1998-12-26,28,57;" +
    "1998-12-26,30,59;" +
    "1998-12-26,32,56;" +
    "1998-12-27,28,54;" +
    "1998-12-27,31,58;" +
    "1998-12-27,35,56;" +
    "1998-12-28,,;" +
    "1998-12-28,32,57;" +
    "1998-12-28,36,55;" +
    "1998-12-29,30,60;" +
    "1998-12-29,31,63;" +
    "1998-12-29,37,63;" +
    "1998-12-30,32,58;" +
    "1998-12-30,34,59;" +
    "1998-12-30,38,59;" +
    "1998-12-31,37,60;" +
    "1998-12-31,46,61;" +
    "1998-12-31,46,59;"