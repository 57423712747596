export const data = "2020-01-01,38,61;" +
    "2020-01-01,39,59;" +
    "2020-01-02,40,62;" +
    "2020-01-02,41,62;" +
    "2020-01-03,36,62;" +
    "2020-01-03,36,61;" +
    "2020-01-04,37,60;" +
    "2020-01-04,37,59;" +
    "2020-01-05,34,60;" +
    "2020-01-05,35,59;" +
    "2020-01-06,33,58;" +
    "2020-01-06,34,58;" +
    "2020-01-07,33,61;" +
    "2020-01-07,33,60;" +
    "2020-01-08,42,57;" +
    "2020-01-08,44,56;" +
    "2020-01-09,35,53;" +
    "2020-01-09,43,56;" +
    "2020-01-10,32,58;" +
    "2020-01-10,31,58;" +
    "2020-01-11,36,58;" +
    "2020-01-11,42,56;" +
    "2020-01-12,32,54;" +
    "2020-01-12,33,52;" +
    "2020-01-13,38,56;" +
    "2020-01-13,39,55;" +
    "2020-01-14,36,56;" +
    "2020-01-14,43,55;" +
    "2020-01-15,32,52;" +
    "2020-01-15,31,51;" +
    "2020-01-16,37,55;" +
    "2020-01-16,37,55;" +
    "2020-01-17,32,56;" +
    "2020-01-17,33,55;" +
    "2020-01-18,35,51;" +
    "2020-01-18,35,50;" +
    "2020-01-19,39,46;" +
    "2020-01-19,38,45;" +
    "2020-01-20,40,53;" +
    "2020-01-20,39,51;" +
    "2020-01-21,43,60;" +
    "2020-01-21,43,59;" +
    "2020-01-22,44,63;" +
    "2020-01-22,50,64;" +
    "2020-01-23,42,57;" +
    "2020-01-23,42,56;" +
    "2020-01-24,40,67;" +
    "2020-01-24,41,67;" +
    "2020-01-25,45,65;" +
    "2020-01-25,45,64;" +
    "2020-01-26,44,61;" +
    "2020-01-26,52,61;" +
    "2020-01-27,40,58;" +
    "2020-01-27,39,57;" +
    "2020-01-28,42,59;" +
    "2020-01-28,44,60;" +
    "2020-01-29,39,63;" +
    "2020-01-29,39,64;" +
    "2020-01-30,40,65;" +
    "2020-01-30,41,64;" +
    "2020-01-31,40,67;" +
    "2020-01-31,40,66;" +
    "2020-02-01,38,66;" +
    "2020-02-01,39,66;" +
    "2020-02-02,37,57;" +
    "2020-02-02,42,56;" +
    "2020-02-03,29,53;" +
    "2020-02-03,29,53;" +
    "2020-02-04,34,54;" +
    "2020-02-04,36,53;" +
    "2020-02-05,31,65;" +
    "2020-02-05,31,65;" +
    "2020-02-06,35,65;" +
    "2020-02-06,35,65;" +
    "2020-02-07,37,70;" +
    "2020-02-07,36,70;" +
    "2020-02-08,38,63;" +
    "2020-02-08,40,62;" +
    "2020-02-09,38,60;" +
    "2020-02-09,38,58;" +
    "2020-02-10,42,71;" +
    "2020-02-10,43,71;" +
    "2020-02-11,44,73;" +
    "2020-02-11,50,72;" +
    "2020-02-12,38,70;" +
    "2020-02-12,40,69;" +
    "2020-02-13,37,60;" +
    "2020-02-13,38,61;" +
    "2020-02-14,44,64;" +
    "2020-02-14,46,63;" +
    "2020-02-15,37,69;" +
    "2020-02-15,38,68;" +
    "2020-02-16,42,64;" +
    "2020-02-16,42,65;" +
    "2020-02-17,40,71;" +
    "2020-02-17,40,73;" +
    "2020-02-18,38,67;" +
    "2020-02-18,46,66;" +
    "2020-02-19,34,67;" +
    "2020-02-19,34,66;" +
    "2020-02-20,37,67;" +
    "2020-02-20,37,65;" +
    "2020-02-21,39,71;" +
    "2020-02-21,40,70;" +
    "2020-02-22,46,68;" +
    "2020-02-22,49,68;" +
    "2020-02-23,42,61;" +
    "2020-02-23,46,61;" +
    "2020-02-24,39,74;" +
    "2020-02-24,38,74;" +
    "2020-02-25,39,74;" +
    "2020-02-25,42,73;" +
    "2020-02-26,40,78;" +
    "2020-02-26,41,78;" +
    "2020-02-27,41,75;" +
    "2020-02-27,42,74;" +
    "2020-02-28,46,80;" +
    "2020-02-28,45,79;" +
    "2020-02-29,45,60;" +
    "2020-02-29,46,66;" +
    "2020-03-01,43,57;" +
    "2020-03-01,43,56;" +
    "2020-03-02,50,70;" +
    "2020-03-02,43,69;" +
    "2020-03-03,47,75;" +
    "2020-03-03,50,74;" +
    "2020-03-04,42,78;" +
    "2020-03-04,43,76;" +
    "2020-03-05,42,69;" +
    "2020-03-05,43,70;" +
    "2020-03-06,50,64;" +
    "2020-03-06,50,64;" +
    "2020-03-07,46,57;" +
    "2020-03-07,26,57;" +
    "2020-03-08,41,58;" +
    "2020-03-08,42,56;" +
    "2020-03-09,42,65;" +
    "2020-03-09,41,64;" +
    "2020-03-10,44,73;" +
    "2020-03-10,44,70;" +
    "2020-03-11,49,74;" +
    "2020-03-11,53,73;" +
    "2020-03-12,44,76;" +
    "2020-03-12,44,76;" +
    "2020-03-13,42,68;" +
    "2020-03-13,44,68;" +
    "2020-03-14,47,55;" +
    "2020-03-14,57,67;" +
    "2020-03-15,44,53;" +
    "2020-03-15,44,60;" +
    "2020-03-16,38,52;" +
    "2020-03-16,36,50;" +
    "2020-03-17,34,55;" +
    "2020-03-17,33,54;" +
    "2020-03-18,40,54;" +
    "2020-03-18,39,54;" +
    "2020-03-19,41,61;" +
    "2020-03-19,44,61;" +
    "2020-03-20,39,62;" +
    "2020-03-20,38,62;" +
    "2020-03-21,39,62;" +
    "2020-03-21,40,61;" +
    "2020-03-22,40,66;" +
    "2020-03-22,41,65;" +
    "2020-03-23,41,56;" +
    "2020-03-23,42,56;" +
    "2020-03-24,45,57;" +
    "2020-03-24,43,57;" +
    "2020-03-25,38,57;" +
    "2020-03-25,41,56;" +
    "2020-03-26,33,58;" +
    "2020-03-26,34,58;" +
    "2020-03-27,38,57;" +
    "2020-03-27,40,57;" +
    "2020-03-28,47,56;" +
    "2020-03-28,45,56;" +
    "2020-03-29,47,61;" +
    "2020-03-29,46,62;" +
    "2020-03-30,49,65;" +
    "2020-03-30,49,66;" +
    "2020-03-31,44,68;" +
    "2020-03-31,43,69;" +
    "2020-04-01,42,62;" +
    "2020-04-01,45,64;" +
    "2020-04-02,37,66;" +
    "2020-04-02,38,67;" +
    "2020-04-03,41,65;" +
    "2020-04-03,40,65;" +
    "2020-04-04,46,60;" +
    "2020-04-04,45,59;" +
    "2020-04-05,46,56;" +
    "2020-04-05,47,55;" +
    "2020-04-06,39,52;" +
    "2020-04-06,40,53;" +
    "2020-04-07,37,64;" +
    "2020-04-07,37,64;" +
    "2020-04-08,47,67;" +
    "2020-04-08,47,67;" +
    "2020-04-09,51,61;" +
    "2020-04-09,49,62;" +
    "2020-04-10,50,66;" +
    "2020-04-10,50,67;" +
    "2020-04-11,50,63;" +
    "2020-04-11,49,64;" +
    "2020-04-12,51,67;" +
    "2020-04-12,49,67;" +
    "2020-04-13,47,74;" +
    "2020-04-13,48,76;" +
    "2020-04-14,40,77;" +
    "2020-04-14,41,77;" +
    "2020-04-15,42,81;" +
    "2020-04-15,44,82;" +
    "2020-04-16,50,68;" +
    "2020-04-16,51,78;" +
    "2020-04-17,51,65;" +
    "2020-04-17,51,66;" +
    "2020-04-18,48,66;" +
    "2020-04-18,52,67;" +
    "2020-04-19,44,68;" +
    "2020-04-19,46,69;" +
    "2020-04-20,50,65;" +
    "2020-04-20,51,65;" +
    "2020-04-21,50,71;" +
    "2020-04-21,50,72;" +
    "2020-04-22,45,79;" +
    "2020-04-22,45,79;" +
    "2020-04-23,53,81;" +
    "2020-04-23,53,81;" +
    "2020-04-24,49,88;" +
    "2020-04-24,49,87;" +
    "2020-04-25,52,87;" +
    "2020-04-25,53,87;" +
    "2020-04-26,49,81;" +
    "2020-04-26,51,78;" +
    "2020-04-27,58,80;" +
    "2020-04-27,49,80;" +
    "2020-04-28,52,88;" +
    "2020-04-28,53,88;" +
    "2020-04-29,53,73;" +
    "2020-04-29,54,78;" +
    "2020-04-30,51,77;" +
    "2020-04-30,50,76;" +
    "2020-05-01,47,77;" +
    "2020-05-01,47,77;" +
    "2020-05-02,55,72;" +
    "2020-05-02,53,71;" +
    "2020-05-03,47,72;" +
    "2020-05-03,47,73;" +
    "2020-05-04,41,84;" +
    "2020-05-04,41,83;" +
    "2020-05-05,46,80;" +
    "2020-05-05,48,83;" +
    "2020-05-06,48,85;" +
    "2020-05-06,48,90;" +
    "2020-05-07,46,91;" +
    "2020-05-07,52,96;" +
    "2020-05-08,52,96;" +
    "2020-05-08,54,89;" +
    "2020-05-09,53,86;" +
    "2020-05-09,52,79;" +
    "2020-05-10,53,76;" +
    "2020-05-10,50,75;" +
    "2020-05-11,49,78;" +
    "2020-05-11,50,76;" +
    "2020-05-12,53,67;" +
    "2020-05-12,53,66;" +
    "2020-05-13,49,70;" +
    "2020-05-13,47,71;" +
    "2020-05-14,51,72;" +
    "2020-05-14,51,71;" +
    "2020-05-15,53,74;" +
    "2020-05-15,53,75;" +
    "2020-05-16,53,76;" +
    "2020-05-16,53,76;" +
    "2020-05-17,59,77;" +
    "2020-05-17,60,75;" +
    "2020-05-18,51,69;" +
    "2020-05-18,54,67;" +
    "2020-05-19,51,69;" +
    "2020-05-19,51,69;" +
    "2020-05-20,53,74;" +
    "2020-05-20,53,75;" +
    "2020-05-21,50,83;" +
    "2020-05-21,48,83;" +
    "2020-05-22,52,77;" +
    "2020-05-22,59,77;" +
    "2020-05-23,47,83;" +
    "2020-05-23,46,83;" +
    "2020-05-24,49,92;" +
    "2020-05-24,50,91;" +
    "2020-05-25,57,98;" +
    "2020-05-25,56,97;" +
    "2020-05-26,62,102;" +
    "2020-05-26,62,101;" +
    "2020-05-27,65,100;" +
    "2020-05-27,64,99;" +
    "2020-05-28,58,93;" +
    "2020-05-28,59,95;" +
    "2020-05-29,57,83;" +
    "2020-05-29,56,86;" +
    "2020-05-30,58,73;" +
    "2020-05-30,60,72;" +
    "2020-05-31,55,73;" +
    "2020-05-31,54,73;" +
    "2020-06-01,57,83;" +
    "2020-06-02,57,94;" +
    "2020-06-03,63,101;" +
    "2020-06-04,60,100;" +
    "2020-06-05,55,71;" +
    "2020-06-06,53,70;" +
    "2020-06-07,48,69;" +
    "2020-06-08,48,82;" +
    "2020-06-09,50,91;" +
    "2020-06-10,58,95;" +
    "2020-06-11,58,91;" +
    "2020-06-12,56,70;" +
    "2020-06-13,56,71;" +
    "2020-06-14,56,82;" +
    "2020-06-15,56,79;" +
    "2020-06-16,55,75;" +
    "2020-06-17,51,89;" +
    "2020-06-18,56,96;" +
    "2020-06-19,58,91;" +
    "2020-06-20,56,84;" +
    "2020-06-21,56,89;" +
    "2020-06-22,57,95;" +
    "2020-06-23,58,95;" +
    "2020-06-24,58,92;" +
    "2020-06-25,57,91;" +
    "2020-06-26,58,93;" +
    "2020-06-27,57,89;" +
    "2020-06-28,54,77;" +
    "2020-06-29,51,85;" +
    "2020-06-30,56,94;" +
    "2020-07-01,55,90;" +
    "2020-07-02,53,84;" +
    "2020-07-03,54,85;" +
    "2020-07-04,53,95;" +
    "2020-07-05,59,93;" +
    "2020-07-06,55,81;" +
    "2020-07-07,52,89;" +
    "2020-07-08,55,92;" +
    "2020-07-09,54,95;" +
    "2020-07-10,57,96;" +
    "2020-07-11,57,100;" +
    "2020-07-12,60,102;" +
    "2020-07-13,57,85;" +
    "2020-07-14,56,87;" +
    "2020-07-15,57,90;" +
    "2020-07-16,57,84;" +
    "2020-07-17,57,87;" +
    "2020-07-18,58,93;" +
    "2020-07-19,57,84;" +
    "2020-07-20,57,81;" +
    "2020-07-21,56,84;" +
    "2020-07-22,57,86;" +
    "2020-07-23,58,87;" +
    "2020-07-24,56,83;" +
    "2020-07-25,54,92;" +
    "2020-07-26,57,92;" +
    "2020-07-27,57,90;" +
    "2020-07-28,55,91;" +
    "2020-07-29,56,90;" +
    "2020-07-30,56,90;" +
    "2020-07-31,55,88;" +
    "2020-08-01,54,90;" +
    "2020-08-02,55,91;" +
    "2020-08-03,59,95;" +
    "2020-08-04,57,83;" +
    "2020-08-05,60,69;" +
    "2020-08-06,59,83;" +
    "2020-08-07,57,91;" +
    "2020-08-08,59,93;" +
    "2020-08-09,60,98;" +
    "2020-08-10,60,94;" +
    "2020-08-11,58,84;" +
    "2020-08-12,58,96;" +
    "2020-08-13,63,102;" +
    "2020-08-14,73,106;" +
    "2020-08-15,72,108;" +
    "2020-08-16,75,106;" +
    "2020-08-17,74,103;" +
    "2020-08-18,72,105;" +
    "2020-08-19,69,104;" +
    "2020-08-20,60,93;" +
    "2020-08-21,60,96;" +
    "2020-08-22,60,97;" +
    "2020-08-23,64,93;" +
    "2020-08-24,68,94;" +
    "2020-08-25,60,86;" +
    "2020-08-26,55,82;" +
    "2020-08-27,55,87;" +
    "2020-08-28,57,91;" +
    "2020-08-29,57,89;" +
    "2020-08-30,55,90;" +
    "2020-08-31,57,88;" +
    "2020-09-01,57,80;" +
    "2020-09-02,58,82;" +
    "2020-09-03,57,83;" +
    "2020-09-04,56,93;" +
    "2020-09-05,61,103;" +
    "2020-09-06,68,111;" +
    "2020-09-07,69,112;" +
    "2020-09-08,67,95;" +
    "2020-09-09,58,70;" +
    "2020-09-10,55,70;" +
    "2020-09-11,53,85;" +
    "2020-09-12,55,91;" +
    "2020-09-13,56,82;" +
    "2020-09-14,56,87;" +
    "2020-09-15,55,89;" +
    "2020-09-16,58,89;" +
    "2020-09-17,61,88;" +
    "2020-09-18,61,81;" +
    "2020-09-19,56,86;" +
    "2020-09-20,55,93;" +
    "2020-09-21,59,89;" +
    "2020-09-22,57,86;" +
    "2020-09-23,55,88;" +
    "2020-09-24,59,85;" +
    "2020-09-25,58,83;" +
    "2020-09-26,55,89;" +
    "2020-09-27,58,92;" +
    "2020-09-28,62,98;" +
    "2020-09-29,62,100;" +
    "2020-09-30,59,101;" +
    "2020-10-01,62,97;" +
    "2020-10-02,61,95;" +
    "2020-10-03,59,100;" +
    "2020-10-04,54,98;" +
    "2020-10-05,54,95;" +
    "2020-10-06,56,94;" +
    "2020-10-07,56,79;" +
    "2020-10-08,56,73;" +
    "2020-10-09,58,75;" +
    "2020-10-10,55,76;" +
    "2020-10-11,52,83;" +
    "2020-10-12,51,87;" +
    "2020-10-13,52,93;" +
    "2020-10-14,56,94;" +
    "2020-10-15,63,91;" +
    "2020-10-16,60,92;" +
    "2020-10-17,53,95;" +
    "2020-10-18,54,92;" +
    "2020-10-19,54,90;" +
    "2020-10-20,54,91;" +
    "2020-10-21,52,90;" +
    "2020-10-22,54,81;" +
    "2020-10-23,47,76;" +
    "2020-10-24,49,72;" +
    "2020-10-25,52,69;" +
    "2020-10-26,48,74;" +
    "2020-10-27,47,74;" +
    "2020-10-28,43,76;" +
    "2020-10-29,43,78;" +
    "2020-10-30,43,81;" +
    "2020-10-31,43,81;" +
    "2020-11-01,43,80;" +
    "2020-11-02,44,85;" +
    "2020-11-03,46,83;" +
    "2020-11-04,44,82;" +
    "2020-11-05,48,85;" +
    "2020-11-06,49,61;" +
    "2020-11-07,43,63;" +
    "2020-11-08,36,59;" +
    "2020-11-09,32,58;" +
    "2020-11-10,34,64;" +
    "2020-11-11,42,58;" +
    "2020-11-12,36,66;" +
    "2020-11-13,39,62;" +
    "2020-11-14,41,61;" +
    "2020-11-15,37,70;" +
    "2020-11-16,43,67;" +
    "2020-11-17,44,70;" +
    "2020-11-18,47,65;" +
    "2020-11-19,40,64;" +
    "2020-11-20,39,64;" +
    "2020-11-21,37,63;" +
    "2020-11-22,33,67;" +
    "2020-11-23,42,63;" +
    "2020-11-24,37,69;" +
    "2020-11-25,38,63;" +
    "2020-11-26,35,62;" +
    "2020-11-27,35,64;" +
    "2020-11-28,31,62;" +
    "2020-11-29,30,65;" +
    "2020-11-30,31,69;" +
    "2020-12-01,34,66;" +
    "2020-12-01,,;" +
    "2020-12-02,33,65;" +
    "2020-12-02,,;" +
    "2020-12-03,36,67;" +
    "2020-12-03,,;" +
    "2020-12-04,35,67;" +
    "2020-12-04,,;" +
    "2020-12-05,35,68;" +
    "2020-12-05,,;" +
    "2020-12-06,36,64;" +
    "2020-12-06,,;" +
    "2020-12-07,39,72;" +
    "2020-12-07,,;" +
    "2020-12-08,40,72;" +
    "2020-12-08,,;" +
    "2020-12-09,35,65;" +
    "2020-12-09,,;" +
    "2020-12-10,40,68;" +
    "2020-12-10,,;" +
    "2020-12-11,40,55;" +
    "2020-12-11,,;" +
    "2020-12-12,41,56;" +
    "2020-12-12,,;" +
    "2020-12-13,41,58;" +
    "2020-12-13,,;" +
    "2020-12-14,37,58;" +
    "2020-12-14,,;" +
    "2020-12-15,35,59;" +
    "2020-12-15,,;" +
    "2020-12-16,36,59;" +
    "2020-12-16,,;" +
    "2020-12-17,39,58;" +
    "2020-12-17,,;" +
    "2020-12-18,36,59;" +
    "2020-12-18,,;" +
    "2020-12-19,33,57;" +
    "2020-12-19,,;" +
    "2020-12-20,32,60;" +
    "2020-12-20,,;" +
    "2020-12-21,32,61;" +
    "2020-12-21,,;" +
    "2020-12-22,39,59;" +
    "2020-12-22,,;" +
    "2020-12-23,38,55;" +
    "2020-12-23,,;" +
    "2020-12-24,36,56;" +
    "2020-12-24,,;" +
    "2020-12-25,37,63;" +
    "2020-12-25,,;" +
    "2020-12-26,41,62;" +
    "2020-12-26,,;" +
    "2020-12-27,39,59;" +
    "2020-12-27,,;" +
    "2020-12-28,38,54;" +
    "2020-12-28,,;" +
    "2020-12-29,32,61;" +
    "2020-12-29,,;" +
    "2020-12-30,30,60;" +
    "2020-12-30,,;" +
    "2020-12-31,39,61;" +
    "2020-12-31,,;"