export const data = "1995-01-01,42,48;" +
    "1995-01-01,42,51;" +
    "1995-01-02,40,48;" +
    "1995-01-02,41,50;" +
    "1995-01-03,44,57;" +
    "1995-01-03,47,57;" +
    "1995-01-04,45,52;" +
    "1995-01-04,45,52;" +
    "1995-01-05,45,54;" +
    "1995-01-05,47,53;" +
    "1995-01-06,44,53;" +
    "1995-01-06,44,54;" +
    "1995-01-07,48,59;" +
    "1995-01-07,50,58;" +
    "1995-01-08,52,61;" +
    "1995-01-08,54,60;" +
    "1995-01-09,55,67;" +
    "1995-01-09,52,63;" +
    "1995-01-10,53,61;" +
    "1995-01-10,52,59;" +
    "1995-01-11,44,60;" +
    "1995-01-11,49,59;" +
    "1995-01-12,51,58;" +
    "1995-01-12,51,58;" +
    "1995-01-13,53,65;" +
    "1995-01-13,55,61;" +
    "1995-01-14,51,61;" +
    "1995-01-14,46,60;" +
    "1995-01-15,45,55;" +
    "1995-01-15,43,51;" +
    "1995-01-16,39,51;" +
    "1995-01-16,38,50;" +
    "1995-01-17,32,52;" +
    "1995-01-17,34,54;" +
    "1995-01-18,37,57;" +
    "1995-01-18,39,59;" +
    "1995-01-19,41,51;" +
    "1995-01-19,40,52;" +
    "1995-01-20,39,52;" +
    "1995-01-20,39,52;" +
    "1995-01-21,45,54;" +
    "1995-01-21,47,56;" +
    "1995-01-22,49,58;" +
    "1995-01-22,49,59;" +
    "1995-01-23,49,60;" +
    "1995-01-23,49,61;" +
    "1995-01-24,48,61;" +
    "1995-01-24,48,61;" +
    "1995-01-25,47,59;" +
    "1995-01-25,46,58;" +
    "1995-01-26,46,57;" +
    "1995-01-26,47,54;" +
    "1995-01-27,51,57;" +
    "1995-01-27,51,56;" +
    "1995-01-28,51,62;" +
    "1995-01-28,53,65;" +
    "1995-01-29,50,62;" +
    "1995-01-29,51,61;" +
    "1995-01-30,52,61;" +
    "1995-01-30,52,60;" +
    "1995-01-31,52,71;" +
    "1995-01-31,54,73;" +
    "1995-02-01,53,70;" +
    "1995-02-01,53,69;" +
    "1995-02-02,53,63;" +
    "1995-02-02,51,64;" +
    "1995-02-03,50,57;" +
    "1995-02-03,49,55;" +
    "1995-02-04,44,63;" +
    "1995-02-04,45,62;" +
    "1995-02-05,47,57;" +
    "1995-02-05,47,53;" +
    "1995-02-06,49,54;" +
    "1995-02-06,47,54;" +
    "1995-02-07,47,55;" +
    "1995-02-07,47,55;" +
    "1995-02-08,47,59;" +
    "1995-02-08,47,61;" +
    "1995-02-09,39,66;" +
    "1995-02-09,42,67;" +
    "1995-02-10,44,60;" +
    "1995-02-10,45,62;" +
    "1995-02-11,44,55;" +
    "1995-02-11,46,51;" +
    "1995-02-12,46,58;" +
    "1995-02-12,47,56;" +
    "1995-02-13,49,57;" +
    "1995-02-13,41,54;" +
    "1995-02-14,33,55;" +
    "1995-02-14,34,57;" +
    "1995-02-15,30,59;" +
    "1995-02-15,33,63;" +
    "1995-02-16,35,59;" +
    "1995-02-16,37,59;" +
    "1995-02-17,35,68;" +
    "1995-02-17,38,71;" +
    "1995-02-18,42,67;" +
    "1995-02-18,45,69;" +
    "1995-02-19,41,68;" +
    "1995-02-19,48,68;" +
    "1995-02-20,40,67;" +
    "1995-02-20,45,67;" +
    "1995-02-21,41,73;" +
    "1995-02-21,46,78;" +
    "1995-02-22,45,67;" +
    "1995-02-22,47,68;" +
    "1995-02-23,50,62;" +
    "1995-02-23,50,66;" +
    "1995-02-24,51,58;" +
    "1995-02-24,50,56;" +
    "1995-02-25,46,59;" +
    "1995-02-25,48,60;" +
    "1995-02-26,47,59;" +
    "1995-02-26,50,58;" +
    "1995-02-27,52,59;" +
    "1995-02-27,52,59;" +
    "1995-02-28,52,58;" +
    "1995-02-28,51,58;" +
    "1995-03-01,51,64;" +
    "1995-03-01,51,65;" +
    "1995-03-02,50,61;" +
    "1995-03-02,51,59;" +
    "1995-03-03,47,57;" +
    "1995-03-03,40,55;" +
    "1995-03-04,35,59;" +
    "1995-03-04,39,59;" +
    "1995-03-05,49,54;" +
    "1995-03-05,43,54;" +
    "1995-03-06,35,62;" +
    "1995-03-06,38,63;" +
    "1995-03-07,37,62;" +
    "1995-03-07,39,63;" +
    "1995-03-08,51,62;" +
    "1995-03-08,51,60;" +
    "1995-03-09,56,65;" +
    "1995-03-09,56,63;" +
    "1995-03-10,54,65;" +
    "1995-03-10,50,62;" +
    "1995-03-11,46,62;" +
    "1995-03-11,50,59;" +
    "1995-03-12,48,63;" +
    "1995-03-12,48,60;" +
    "1995-03-13,51,59;" +
    "1995-03-13,51,59;" +
    "1995-03-14,51,68;" +
    "1995-03-14,48,66;" +
    "1995-03-15,40,63;" +
    "1995-03-15,42,65;" +
    "1995-03-16,42,69;" +
    "1995-03-16,44,72;" +
    "1995-03-17,42,69;" +
    "1995-03-17,45,64;" +
    "1995-03-18,51,63;" +
    "1995-03-18,48,62;" +
    "1995-03-19,43,63;" +
    "1995-03-19,45,60;" +
    "1995-03-20,49,59;" +
    "1995-03-20,46,57;" +
    "1995-03-21,43,55;" +
    "1995-03-21,41,51;" +
    "1995-03-22,43,54;" +
    "1995-03-22,40,54;" +
    "1995-03-23,37,51;" +
    "1995-03-23,38,49;" +
    "1995-03-24,35,57;" +
    "1995-03-24,37,56;" +
    "1995-03-25,35,59;" +
    "1995-03-25,37,59;" +
    "1995-03-26,36,60;" +
    "1995-03-26,43,59;" +
    "1995-03-27,36,64;" +
    "1995-03-27,41,63;" +
    "1995-03-28,37,69;" +
    "1995-03-28,42,67;" +
    "1995-03-29,41,68;" +
    "1995-03-29,47,66;" +
    "1995-03-30,40,72;" +
    "1995-03-30,44,69;" +
    "1995-03-31,41,70;" +
    "1995-03-31,45,67;" +
    "1995-04-01,51,66;" +
    "1995-04-01,47,62;" +
    "1995-04-02,40,73;" +
    "1995-04-02,44,71;" +
    "1995-04-03,42,78;" +
    "1995-04-03,47,80;" +
    "1995-04-04,44,78;" +
    "1995-04-04,47,75;" +
    "1995-04-05,46,74;" +
    "1995-04-05,47,70;" +
    "1995-04-06,56,65;" +
    "1995-04-06,54,63;" +
    "1995-04-07,54,65;" +
    "1995-04-07,48,60;" +
    "1995-04-08,43,60;" +
    "1995-04-08,43,57;" +
    "1995-04-09,36,64;" +
    "1995-04-09,37,60;" +
    "1995-04-10,39,72;" +
    "1995-04-10,43,70;" +
    "1995-04-11,44,73;" +
    "1995-04-11,45,69;" +
    "1995-04-12,51,74;" +
    "1995-04-12,50,73;" +
    "1995-04-13,49,64;" +
    "1995-04-13,45,58;" +
    "1995-04-14,38,64;" +
    "1995-04-14,39,61;" +
    "1995-04-15,39,58;" +
    "1995-04-15,40,51;" +
    "1995-04-16,41,63;" +
    "1995-04-16,41,59;" +
    "1995-04-17,47,57;" +
    "1995-04-17,46,53;" +
    "1995-04-18,44,61;" +
    "1995-04-18,43,57;" +
    "1995-04-19,39,61;" +
    "1995-04-19,40,59;" +
    "1995-04-20,48,62;" +
    "1995-04-20,44,59;" +
    "1995-04-21,39,69;" +
    "1995-04-21,40,67;" +
    "1995-04-22,40,75;" +
    "1995-04-22,45,75;" +
    "1995-04-23,44,79;" +
    "1995-04-23,49,79;" +
    "1995-04-24,47,81;" +
    "1995-04-24,46,77;" +
    "1995-04-25,46,74;" +
    "1995-04-25,45,63;" +
    "1995-04-26,46,71;" +
    "1995-04-26,46,66;" +
    "1995-04-27,47,67;" +
    "1995-04-27,48,61;" +
    "1995-04-28,54,65;" +
    "1995-04-28,54,62;" +
    "1995-04-29,57,68;" +
    "1995-04-29,56,64;" +
    "1995-04-30,54,69;" +
    "1995-04-30,55,71;" +
    "1995-05-01,58,65;" +
    "1995-05-01,53,63;" +
    "1995-05-02,52,70;" +
    "1995-05-02,49,54;" +
    "1995-05-03,47,72;" +
    "1995-05-03,48,70;" +
    "1995-05-04,49,66;" +
    "1995-05-04,48,62;" +
    "1995-05-05,50,62;" +
    "1995-05-05,46,58;" +
    "1995-05-06,43,66;" +
    "1995-05-06,44,68;" +
    "1995-05-07,45,70;" +
    "1995-05-07,45,66;" +
    "1995-05-08,50,68;" +
    "1995-05-08,48,65;" +
    "1995-05-09,46,64;" +
    "1995-05-09,46,64;" +
    "1995-05-10,52,70;" +
    "1995-05-10,51,67;" +
    "1995-05-11,52,66;" +
    "1995-05-11,46,62;" +
    "1995-05-12,45,63;" +
    "1995-05-12,45,60;" +
    "1995-05-13,49,60;" +
    "1995-05-13,48,59;" +
    "1995-05-14,45,61;" +
    "1995-05-14,48,50;" +
    "1995-05-15,45,66;" +
    "1995-05-15,47,66;" +
    "1995-05-16,46,71;" +
    "1995-05-16,48,67;" +
    "1995-05-17,48,74;" +
    "1995-05-17,48,70;" +
    "1995-05-18,50,81;" +
    "1995-05-18,50,79;" +
    "1995-05-19,50,84;" +
    "1995-05-19,51,81;" +
    "1995-05-20,51,79;" +
    "1995-05-20,49,67;" +
    "1995-05-21,52,63;" +
    "1995-05-21,51,57;" +
    "1995-05-22,52,70;" +
    "1995-05-22,50,67;" +
    "1995-05-23,49,77;" +
    "1995-05-23,49,72;" +
    "1995-05-24,52,70;" +
    "1995-05-24,50,66;" +
    "1995-05-25,52,75;" +
    "1995-05-25,51,61;" +
    "1995-05-26,52,72;" +
    "1995-05-26,50,67;" +
    "1995-05-27,52,72;" +
    "1995-05-27,50,68;" +
    "1995-05-28,48,86;" +
    "1995-05-28,49,83;" +
    "1995-05-29,51,89;" +
    "1995-05-29,50,85;" +
    "1995-05-30,52,88;" +
    "1995-05-30,51,85;" +
    "1995-05-31,54,91;" +
    "1995-05-31,57,87;" +
    "1995-06-01,59,82;" +
    "1995-06-01,52,69;" +
    "1995-06-02,53,60;" +
    "1995-06-02,52,57;" +
    "1995-06-03,50,76;" +
    "1995-06-03,50,73;" +
    "1995-06-04,50,72;" +
    "1995-06-04,50,68;" +
    "1995-06-05,51,73;" +
    "1995-06-05,50,68;" +
    "1995-06-06,44,73;" +
    "1995-06-06,45,70;" +
    "1995-06-07,55,73;" +
    "1995-06-07,53,69;" +
    "1995-06-08,47,79;" +
    "1995-06-08,49,76;" +
    "1995-06-09,52,73;" +
    "1995-06-09,51,70;" +
    "1995-06-10,51,76;" +
    "1995-06-10,51,72;" +
    "1995-06-11,56,75;" +
    "1995-06-11,50,71;" +
    "1995-06-12,50,80;" +
    "1995-06-12,48,76;" +
    "1995-06-13,49,77;" +
    "1995-06-13,49,72;" +
    "1995-06-14,54,68;" +
    "1995-06-14,53,64;" +
    "1995-06-15,56,65;" +
    "1995-06-15,47,61;" +
    "1995-06-16,49,67;" +
    "1995-06-16,49,65;" +
    "1995-06-17,50,68;" +
    "1995-06-17,50,64;" +
    "1995-06-18,54,67;" +
    "1995-06-18,49,63;" +
    "1995-06-19,48,75;" +
    "1995-06-19,48,71;" +
    "1995-06-20,49,83;" +
    "1995-06-20,49,80;" +
    "1995-06-21,52,89;" +
    "1995-06-21,54,85;" +
    "1995-06-22,55,94;" +
    "1995-06-22,57,90;" +
    "1995-06-23,60,100;" +
    "1995-06-23,63,98;" +
    "1995-06-24,63,104;" +
    "1995-06-24,70,101;" +
    "1995-06-25,65,104;" +
    "1995-06-25,70,102;" +
    "1995-06-26,67,98;" +
    "1995-06-26,60,90;" +
    "1995-06-27,57,88;" +
    "1995-06-27,56,82;" +
    "1995-06-28,57,89;" +
    "1995-06-28,55,86;" +
    "1995-06-29,56,82;" +
    "1995-06-29,55,75;" +
    "1995-06-30,57,77;" +
    "1995-06-30,56,74;" +
    "1995-07-01,58,78;" +
    "1995-07-01,54,74;" +
    "1995-07-02,54,80;" +
    "1995-07-02,54,76;" +
    "1995-07-03,57,77;" +
    "1995-07-03,56,73;" +
    "1995-07-04,56,87;" +
    "1995-07-04,56,85;" +
    "1995-07-05,55,89;" +
    "1995-07-05,55,84;" +
    "1995-07-06,55,84;" +
    "1995-07-06,54,81;" +
    "1995-07-07,56,93;" +
    "1995-07-07,56,90;" +
    "1995-07-08,62,90;" +
    "1995-07-08,58,87;" +
    "1995-07-09,57,80;" +
    "1995-07-09,55,74;" +
    "1995-07-10,55,77;" +
    "1995-07-10,54,73;" +
    "1995-07-11,56,73;" +
    "1995-07-11,53,69;" +
    "1995-07-12,59,77;" +
    "1995-07-12,57,75;" +
    "1995-07-13,52,88;" +
    "1995-07-13,54,85;" +
    "1995-07-14,59,99;" +
    "1995-07-14,63,97;" +
    "1995-07-15,63,101;" +
    "1995-07-15,70,103;" +
    "1995-07-16,65,100;" +
    "1995-07-16,67,93;" +
    "1995-07-17,66,86;" +
    "1995-07-17,63,81;" +
    "1995-07-18,61,90;" +
    "1995-07-18,61,87;" +
    "1995-07-19,59,84;" +
    "1995-07-19,57,78;" +
    "1995-07-20,59,77;" +
    "1995-07-20,58,74;" +
    "1995-07-21,59,78;" +
    "1995-07-21,58,76;" +
    "1995-07-22,58,76;" +
    "1995-07-22,57,74;" +
    "1995-07-23,59,77;" +
    "1995-07-23,56,74;" +
    "1995-07-24,58,78;" +
    "1995-07-24,58,75;" +
    "1995-07-25,58,81;" +
    "1995-07-25,55,79;" +
    "1995-07-26,51,96;" +
    "1995-07-26,52,96;" +
    "1995-07-27,62,101;" +
    "1995-07-27,67,101;" +
    "1995-07-28,64,95;" +
    "1995-07-28,57,92;" +
    "1995-07-29,58,95;" +
    "1995-07-29,57,87;" +
    "1995-07-30,57,98;" +
    "1995-07-30,61,99;" +
    "1995-07-31,63,103;" +
    "1995-07-31,68,102;" +
    "1995-08-01,65,100;" +
    "1995-08-01,68,99;" +
    "1995-08-02,64,98;" +
    "1995-08-02,63,95;" +
    "1995-08-03,60,93;" +
    "1995-08-03,60,89;" +
    "1995-08-04,58,86;" +
    "1995-08-04,57,63;" +
    "1995-08-05,58,86;" +
    "1995-08-05,58,85;" +
    "1995-08-06,59,91;" +
    "1995-08-06,57,89;" +
    "1995-08-07,56,84;" +
    "1995-08-07,57,81;" +
    "1995-08-08,59,93;" +
    "1995-08-08,62,95;" +
    "1995-08-09,59,94;" +
    "1995-08-09,56,92;" +
    "1995-08-10,56,83;" +
    "1995-08-10,54,75;" +
    "1995-08-11,52,84;" +
    "1995-08-11,53,82;" +
    "1995-08-12,55,94;" +
    "1995-08-12,58,95;" +
    "1995-08-13,59,98;" +
    "1995-08-13,65,96;" +
    "1995-08-14,61,99;" +
    "1995-08-14,68,98;" +
    "1995-08-15,61,95;" +
    "1995-08-15,57,88;" +
    "1995-08-16,56,78;" +
    "1995-08-16,56,75;" +
    "1995-08-17,55,78;" +
    "1995-08-17,54,77;" +
    "1995-08-18,50,90;" +
    "1995-08-18,51,88;" +
    "1995-08-19,56,98;" +
    "1995-08-19,62,97;" +
    "1995-08-20,61,94;" +
    "1995-08-20,64,92;" +
    "1995-08-21,58,88;" +
    "1995-08-21,58,86;" +
    "1995-08-22,63,97;" +
    "1995-08-22,63,95;" +
    "1995-08-23,60,91;" +
    "1995-08-23,59,89;" +
    "1995-08-24,56,84;" +
    "1995-08-24,55,81;" +
    "1995-08-25,51,77;" +
    "1995-08-25,53,74;" +
    "1995-08-26,50,84;" +
    "1995-08-26,51,83;" +
    "1995-08-27,50,79;" +
    "1995-08-27,52,76;" +
    "1995-08-28,49,79;" +
    "1995-08-28,52,77;" +
    "1995-08-29,54,88;" +
    "1995-08-29,56,85;" +
    "1995-08-30,51,86;" +
    "1995-08-30,54,86;" +
    "1995-08-31,48,88;" +
    "1995-08-31,50,88;" +
    "1995-09-01,55,81;" +
    "1995-09-01,54,77;" +
    "1995-09-02,53,81;" +
    "1995-09-02,53,80;" +
    "1995-09-03,55,78;" +
    "1995-09-03,53,76;" +
    "1995-09-04,53,82;" +
    "1995-09-04,53,80;" +
    "1995-09-05,51,91;" +
    "1995-09-05,53,92;" +
    "1995-09-06,56,97;" +
    "1995-09-06,61,96;" +
    "1995-09-07,57,97;" +
    "1995-09-07,63,95;" +
    "1995-09-08,55,89;" +
    "1995-09-08,57,88;" +
    "1995-09-09,54,78;" +
    "1995-09-09,53,75;" +
    "1995-09-10,57,80;" +
    "1995-09-10,55,79;" +
    "1995-09-11,54,89;" +
    "1995-09-11,54,88;" +
    "1995-09-12,55,89;" +
    "1995-09-12,55,87;" +
    "1995-09-13,55,84;" +
    "1995-09-13,54,83;" +
    "1995-09-14,56,80;" +
    "1995-09-14,56,78;" +
    "1995-09-15,57,80;" +
    "1995-09-15,56,78;" +
    "1995-09-16,54,74;" +
    "1995-09-16,54,73;" +
    "1995-09-17,56,88;" +
    "1995-09-17,56,87;" +
    "1995-09-18,56,97;" +
    "1995-09-18,59,97;" +
    "1995-09-19,60,99;" +
    "1995-09-19,67,100;" +
    "1995-09-20,59,94;" +
    "1995-09-20,62,92;" +
    "1995-09-21,56,88;" +
    "1995-09-21,56,86;" +
    "1995-09-22,56,81;" +
    "1995-09-22,54,79;" +
    "1995-09-23,55,82;" +
    "1995-09-23,54,82;" +
    "1995-09-24,56,76;" +
    "1995-09-24,56,76;" +
    "1995-09-25,60,73;" +
    "1995-09-25,56,72;" +
    "1995-09-26,51,80;" +
    "1995-09-26,53,79;" +
    "1995-09-27,55,77;" +
    "1995-09-27,55,76;" +
    "1995-09-28,57,72;" +
    "1995-09-28,54,71;" +
    "1995-09-29,50,79;" +
    "1995-09-29,51,77;" +
    "1995-09-30,48,83;" +
    "1995-09-30,51,85;" +
    "1995-10-01,51,84;" +
    "1995-10-01,56,86;" +
    "1995-10-02,52,88;" +
    "1995-10-02,62,89;" +
    "1995-10-03,54,89;" +
    "1995-10-03,62,89;" +
    "1995-10-04,60,81;" +
    "1995-10-04,65,81;" +
    "1995-10-05,56,86;" +
    "1995-10-05,58,86;" +
    "1995-10-06,48,83;" +
    "1995-10-06,54,83;" +
    "1995-10-07,47,81;" +
    "1995-10-07,50,80;" +
    "1995-10-08,47,80;" +
    "1995-10-08,50,79;" +
    "1995-10-09,46,82;" +
    "1995-10-09,49,82;" +
    "1995-10-10,50,86;" +
    "1995-10-10,55,88;" +
    "1995-10-11,52,78;" +
    "1995-10-11,54,72;" +
    "1995-10-12,48,76;" +
    "1995-10-12,51,75;" +
    "1995-10-13,59,79;" +
    "1995-10-13,63,82;" +
    "1995-10-14,50,86;" +
    "1995-10-14,57,88;" +
    "1995-10-15,55,81;" +
    "1995-10-15,55,72;" +
    "1995-10-16,55,73;" +
    "1995-10-16,54,72;" +
    "1995-10-17,47,84;" +
    "1995-10-17,50,84;" +
    "1995-10-18,50,83;" +
    "1995-10-18,53,85;" +
    "1995-10-19,51,87;" +
    "1995-10-19,59,89;" +
    "1995-10-20,50,82;" +
    "1995-10-20,53,77;" +
    "1995-10-21,51,78;" +
    "1995-10-21,52,78;" +
    "1995-10-22,53,71;" +
    "1995-10-22,56,71;" +
    "1995-10-23,47,76;" +
    "1995-10-23,53,76;" +
    "1995-10-24,42,76;" +
    "1995-10-24,49,77;" +
    "1995-10-25,46,69;" +
    "1995-10-25,49,68;" +
    "1995-10-26,44,81;" +
    "1995-10-26,47,81;" +
    "1995-10-27,49,82;" +
    "1995-10-27,55,84;" +
    "1995-10-28,48,81;" +
    "1995-10-28,54,81;" +
    "1995-10-29,47,72;" +
    "1995-10-29,49,71;" +
    "1995-10-30,51,69;" +
    "1995-10-30,50,70;" +
    "1995-10-31,56,67;" +
    "1995-10-31,54,64;" +
    "1995-11-01,53,65;" +
    "1995-11-01,50,64;" +
    "1995-11-02,49,67;" +
    "1995-11-02,47,66;" +
    "1995-11-03,43,70;" +
    "1995-11-03,42,71;" +
    "1995-11-04,40,75;" +
    "1995-11-04,46,76;" +
    "1995-11-05,42,69;" +
    "1995-11-05,45,68;" +
    "1995-11-06,42,70;" +
    "1995-11-06,45,71;" +
    "1995-11-07,44,74;" +
    "1995-11-07,47,77;" +
    "1995-11-08,46,78;" +
    "1995-11-08,53,78;" +
    "1995-11-09,50,68;" +
    "1995-11-09,49,64;" +
    "1995-11-10,43,73;" +
    "1995-11-10,45,71;" +
    "1995-11-11,43,74;" +
    "1995-11-11,51,75;" +
    "1995-11-12,46,81;" +
    "1995-11-12,52,81;" +
    "1995-11-13,49,82;" +
    "1995-11-13,55,82;" +
    "1995-11-14,48,80;" +
    "1995-11-14,53,79;" +
    "1995-11-15,52,71;" +
    "1995-11-15,51,71;" +
    "1995-11-16,50,75;" +
    "1995-11-16,51,75;" +
    "1995-11-17,50,75;" +
    "1995-11-17,52,74;" +
    "1995-11-18,50,72;" +
    "1995-11-18,51,72;" +
    "1995-11-19,48,70;" +
    "1995-11-19,49,71;" +
    "1995-11-20,44,69;" +
    "1995-11-20,49,70;" +
    "1995-11-21,53,65;" +
    "1995-11-21,52,65;" +
    "1995-11-22,47,69;" +
    "1995-11-22,48,70;" +
    "1995-11-23,45,71;" +
    "1995-11-23,52,75;" +
    "1995-11-24,44,72;" +
    "1995-11-24,49,76;" +
    "1995-11-25,43,76;" +
    "1995-11-25,49,76;" +
    "1995-11-26,43,66;" +
    "1995-11-26,45,64;" +
    "1995-11-27,42,68;" +
    "1995-11-27,48,69;" +
    "1995-11-28,41,70;" +
    "1995-11-28,46,74;" +
    "1995-11-29,42,77;" +
    "1995-11-29,48,78;" +
    "1995-11-30,43,72;" +
    "1995-11-30,46,73;" +
    "1995-12-01,46,67;" +
    "1995-12-01,50,67;" +
    "1995-12-02,47,62;" +
    "1995-12-02,45,63;" +
    "1995-12-03,37,62;" +
    "1995-12-03,40,62;" +
    "1995-12-04,54,67;" +
    "1995-12-04,55,66;" +
    "1995-12-05,52,67;" +
    "1995-12-05,55,67;" +
    "1995-12-06,53,69;" +
    "1995-12-06,55,67;" +
    "1995-12-07,56,66;" +
    "1995-12-07,50,69;" +
    "1995-12-08,42,67;" +
    "1995-12-08,43,68;" +
    "1995-12-09,44,56;" +
    "1995-12-09,48,57;" +
    "1995-12-10,45,59;" +
    "1995-12-10,47,59;" +
    "1995-12-11,52,62;" +
    "1995-12-11,54,61;" +
    "1995-12-12,55,64;" +
    "1995-12-12,52,61;" +
    "1995-12-13,49,59;" +
    "1995-12-13,46,59;" +
    "1995-12-14,39,62;" +
    "1995-12-14,40,63;" +
    "1995-12-15,50,58;" +
    "1995-12-15,45,58;" +
    "1995-12-16,36,58;" +
    "1995-12-16,40,58;" +
    "1995-12-17,37,55;" +
    "1995-12-17,41,57;" +
    "1995-12-18,43,46;" +
    "1995-12-18,43,46;" +
    "1995-12-19,44,58;" +
    "1995-12-19,43,58;" +
    "1995-12-20,39,59;" +
    "1995-12-20,42,60;" +
    "1995-12-21,35,48;" +
    "1995-12-21,34,48;" +
    "1995-12-22,38,46;" +
    "1995-12-22,40,47;" +
    "1995-12-23,42,51;" +
    "1995-12-23,42,51;" +
    "1995-12-24,35,54;" +
    "1995-12-24,40,54;" +
    "1995-12-25,35,54;" +
    "1995-12-25,41,57;" +
    "1995-12-26,35,59;" +
    "1995-12-26,41,59;" +
    "1995-12-27,38,65;" +
    "1995-12-27,43,64;" +
    "1995-12-28,44,54;" +
    "1995-12-28,45,53;" +
    "1995-12-29,46,57;" +
    "1995-12-29,48,57;" +
    "1995-12-30,56,60;" +
    "1995-12-30,56,59;" +
    "1995-12-31,54,66;" +
    "1995-12-31,52,65;"