export const data = "2018-01-01,35,64;" +
    "2018-01-01,37,63;" +
    "2018-01-02,42,62;" +
    "2018-01-02,42,61;" +
    "2018-01-03,44,56;" +
    "2018-01-03,45,55;" +
    "2018-01-04,46,68;" +
    "2018-01-04,45,69;" +
    "2018-01-05,48,65;" +
    "2018-01-05,48,64;" +
    "2018-01-06,44,60;" +
    "2018-01-06,47,61;" +
    "2018-01-07,38,53;" +
    "2018-01-07,40,52;" +
    "2018-01-08,48,56;" +
    "2018-01-08,47,55;" +
    "2018-01-09,51,59;" +
    "2018-01-09,51,56;" +
    "2018-01-10,45,57;" +
    "2018-01-10,45,57;" +
    "2018-01-11,45,63;" +
    "2018-01-11,50,62;" +
    "2018-01-12,43,58;" +
    "2018-01-12,44,58;" +
    "2018-01-13,44,51;" +
    "2018-01-13,44,51;" +
    "2018-01-14,46,54;" +
    "2018-01-14,44,53;" +
    "2018-01-15,42,62;" +
    "2018-01-15,44,62;" +
    "2018-01-16,50,61;" +
    "2018-01-16,48,60;" +
    "2018-01-17,47,59;" +
    "2018-01-17,46,59;" +
    "2018-01-18,47,65;" +
    "2018-01-18,46,65;" +
    "2018-01-19,39,56;" +
    "2018-01-19,43,56;" +
    "2018-01-20,30,57;" +
    "2018-01-20,32,57;" +
    "2018-01-21,31,57;" +
    "2018-01-21,32,57;" +
    "2018-01-22,48,57;" +
    "2018-01-22,47,55;" +
    "2018-01-23,40,58;" +
    "2018-01-23,41,58;" +
    "2018-01-24,35,60;" +
    "2018-01-24,36,60;" +
    "2018-01-25,38,55;" +
    "2018-01-25,39,54;" +
    "2018-01-26,37,57;" +
    "2018-01-26,36,57;" +
    "2018-01-27,34,63;" +
    "2018-01-27,36,63;" +
    "2018-01-28,43,65;" +
    "2018-01-28,42,63;" +
    "2018-01-29,40,59;" +
    "2018-01-29,42,56;" +
    "2018-01-30,40,69;" +
    "2018-01-30,39,70;" +
    "2018-01-31,38,70;" +
    "2018-01-31,37,68;" +
    "2018-02-01,42,74;" +
    "2018-02-01,44,74;" +
    "2018-02-02,43,75;" +
    "2018-02-02,43,72;" +
    "2018-02-03,45,74;" +
    "2018-02-03,49,73;" +
    "2018-02-04,45,73;" +
    "2018-02-04,48,72;" +
    "2018-02-05,42,76;" +
    "2018-02-05,43,78;" +
    "2018-02-06,46,74;" +
    "2018-02-06,49,73;" +
    "2018-02-07,41,71;" +
    "2018-02-07,43,69;" +
    "2018-02-08,39,75;" +
    "2018-02-08,41,74;" +
    "2018-02-09,40,78;" +
    "2018-02-09,42,80;" +
    "2018-02-10,42,71;" +
    "2018-02-10,43,72;" +
    "2018-02-11,38,61;" +
    "2018-02-11,42,61;" +
    "2018-02-12,38,59;" +
    "2018-02-12,38,58;" +
    "2018-02-13,36,65;" +
    "2018-02-13,38,61;" +
    "2018-02-14,33,63;" +
    "2018-02-14,34,64;" +
    "2018-02-15,36,63;" +
    "2018-02-15,37,62;" +
    "2018-02-16,30,68;" +
    "2018-02-16,32,67;" +
    "2018-02-17,34,71;" +
    "2018-02-17,35,72;" +
    "2018-02-18,39,57;" +
    "2018-02-18,46,61;" +
    "2018-02-19,30,52;" +
    "2018-02-19,36,53;" +
    "2018-02-20,26,54;" +
    "2018-02-20,27,54;" +
    "2018-02-21,31,55;" +
    "2018-02-21,32,55;" +
    "2018-02-22,34,54;" +
    "2018-02-22,36,54;" +
    "2018-02-23,27,53;" +
    "2018-02-23,27,53;" +
    "2018-02-24,26,57;" +
    "2018-02-24,27,58;" +
    "2018-02-25,29,62;" +
    "2018-02-25,31,63;" +
    "2018-02-26,39,53;" +
    "2018-02-26,38,52;" +
    "2018-02-27,33,57;" +
    "2018-02-27,36,56;" +
    "2018-02-28,29,56;" +
    "2018-02-28,29,56;" +
    "2018-03-01,45,58;" +
    "2018-03-01,48,56;" +
    "2018-03-02,38,55;" +
    "2018-03-02,39,54;" +
    "2018-03-03,35,52;" +
    "2018-03-03,35,52;" +
    "2018-03-04,34,56;" +
    "2018-03-04,33,56;" +
    "2018-03-05,31,58;" +
    "2018-03-05,31,58;" +
    "2018-03-06,35,63;" +
    "2018-03-06,34,64;" +
    "2018-03-07,44,72;" +
    "2018-03-07,44,73;" +
    "2018-03-08,50,70;" +
    "2018-03-08,48,72;" +
    "2018-03-09,45,67;" +
    "2018-03-09,44,67;" +
    "2018-03-10,45,59;" +
    "2018-03-10,48,60;" +
    "2018-03-11,39,68;" +
    "2018-03-11,40,67;" +
    "2018-03-12,45,73;" +
    "2018-03-12,44,73;" +
    "2018-03-13,48,61;" +
    "2018-03-13,54,61;" +
    "2018-03-14,44,54;" +
    "2018-03-14,44,55;" +
    "2018-03-15,37,56;" +
    "2018-03-15,38,54;" +
    "2018-03-16,44,54;" +
    "2018-03-16,44,54;" +
    "2018-03-17,35,53;" +
    "2018-03-17,39,54;" +
    "2018-03-18,32,56;" +
    "2018-03-18,32,55;" +
    "2018-03-19,33,62;" +
    "2018-03-19,33,61;" +
    "2018-03-20,50,54;" +
    "2018-03-20,49,62;" +
    "2018-03-21,51,68;" +
    "2018-03-21,50,67;" +
    "2018-03-22,43,63;" +
    "2018-03-22,50,64;" +
    "2018-03-23,37,59;" +
    "2018-03-23,36,60;" +
    "2018-03-24,44,56;" +
    "2018-03-24,44,57;" +
    "2018-03-25,35,58;" +
    "2018-03-25,35,59;" +
    "2018-03-26,33,65;" +
    "2018-03-26,34,66;" +
    "2018-03-27,38,73;" +
    "2018-03-27,40,73;" +
    "2018-03-28,43,77;" +
    "2018-03-28,44,77;" +
    "2018-03-29,46,81;" +
    "2018-03-29,47,80;" +
    "2018-03-30,47,82;" +
    "2018-03-30,48,82;" +
    "2018-03-31,46,80;" +
    "2018-03-31,47,81;" +
    "2018-04-01,42,75;" +
    "2018-04-01,43,76;" +
    "2018-04-02,48,72;" +
    "2018-04-02,47,73;" +
    "2018-04-03,41,75;" +
    "2018-04-03,41,77;" +
    "2018-04-04,48,72;" +
    "2018-04-04,47,72;" +
    "2018-04-05,49,64;" +
    "2018-04-05,49,65;" +
    "2018-04-06,54,63;" +
    "2018-04-06,53,62;" +
    "2018-04-07,49,66;" +
    "2018-04-07,55,68;" +
    "2018-04-08,46,67;" +
    "2018-04-08,45,68;" +
    "2018-04-09,43,75;" +
    "2018-04-09,43,74;" +
    "2018-04-10,50,68;" +
    "2018-04-10,54,72;" +
    "2018-04-11,44,64;" +
    "2018-04-11,42,65;" +
    "2018-04-12,39,60;" +
    "2018-04-12,41,61;" +
    "2018-04-13,38,71;" +
    "2018-04-13,38,71;" +
    "2018-04-14,44,76;" +
    "2018-04-14,44,78;" +
    "2018-04-15,47,65;" +
    "2018-04-15,46,72;" +
    "2018-04-16,41,54;" +
    "2018-04-16,41,54;" +
    "2018-04-17,36,63;" +
    "2018-04-17,36,62;" +
    "2018-04-18,42,60;" +
    "2018-04-18,42,60;" +
    "2018-04-19,37,67;" +
    "2018-04-19,37,68;" +
    "2018-04-20,42,77;" +
    "2018-04-20,41,77;" +
    "2018-04-21,43,82;" +
    "2018-04-21,43,82;" +
    "2018-04-22,48,84;" +
    "2018-04-22,48,83;" +
    "2018-04-23,51,85;" +
    "2018-04-23,51,83;" +
    "2018-04-24,50,78;" +
    "2018-04-24,48,80;" +
    "2018-04-25,49,75;" +
    "2018-04-25,47,77;" +
    "2018-04-26,49,69;" +
    "2018-04-26,49,70;" +
    "2018-04-27,51,67;" +
    "2018-04-27,49,67;" +
    "2018-04-28,49,64;" +
    "2018-04-28,48,68;" +
    "2018-04-29,49,65;" +
    "2018-04-29,48,66;" +
    "2018-04-30,47,64;" +
    "2018-04-30,49,64;" +
    "2018-05-01,45,72;" +
    "2018-05-01,42,73;" +
    "2018-05-02,46,73;" +
    "2018-05-02,47,73;" +
    "2018-05-03,49,72;" +
    "2018-05-03,47,74;" +
    "2018-05-04,47,83;" +
    "2018-05-04,48,85;" +
    "2018-05-05,49,76;" +
    "2018-05-05,50,81;" +
    "2018-05-06,48,68;" +
    "2018-05-06,47,70;" +
    "2018-05-07,48,81;" +
    "2018-05-07,46,82;" +
    "2018-05-08,50,79;" +
    "2018-05-08,48,80;" +
    "2018-05-09,53,72;" +
    "2018-05-09,54,74;" +
    "2018-05-10,49,75;" +
    "2018-05-10,49,77;" +
    "2018-05-11,48,80;" +
    "2018-05-11,47,80;" +
    "2018-05-12,53,79;" +
    "2018-05-12,56,80;" +
    "2018-05-13,51,71;" +
    "2018-05-13,50,73;" +
    "2018-05-14,50,67;" +
    "2018-05-14,50,68;" +
    "2018-05-15,54,68;" +
    "2018-05-15,53,68;" +
    "2018-05-16,52,68;" +
    "2018-05-16,52,68;" +
    "2018-05-17,51,72;" +
    "2018-05-17,48,72;" +
    "2018-05-18,51,71;" +
    "2018-05-18,52,72;" +
    "2018-05-19,51,65;" +
    "2018-05-19,51,66;" +
    "2018-05-20,52,64;" +
    "2018-05-20,51,64;" +
    "2018-05-21,51,75;" +
    "2018-05-21,46,76;" +
    "2018-05-22,53,66;" +
    "2018-05-22,52,67;" +
    "2018-05-23,53,66;" +
    "2018-05-23,52,67;" +
    "2018-05-24,52,66;" +
    "2018-05-24,51,67;" +
    "2018-05-25,52,65;" +
    "2018-05-25,52,63;" +
    "2018-05-26,51,70;" +
    "2018-05-26,52,71;" +
    "2018-05-27,47,84;" +
    "2018-05-27,47,84;" +
    "2018-05-28,54,95;" +
    "2018-05-28,54,94;" +
    "2018-05-29,54,93;" +
    "2018-05-29,58,94;" +
    "2018-05-30,50,69;" +
    "2018-05-30,50,85;" +
    "2018-05-31,48,64;" +
    "2018-05-31,51,63;" +
    "2018-06-01,46,83;" +
    "2018-06-01,44,83;" +
    "2018-06-02,53,96;" +
    "2018-06-02,52,97;" +
    "2018-06-03,58,98;" +
    "2018-06-03,58,98;" +
    "2018-06-04,52,82;" +
    "2018-06-04,57,88;" +
    "2018-06-05,50,74;" +
    "2018-06-05,48,76;" +
    "2018-06-06,50,68;" +
    "2018-06-06,49,67;" +
    "2018-06-07,52,72;" +
    "2018-06-07,52,74;" +
    "2018-06-08,50,83;" +
    "2018-06-08,49,83;" +
    "2018-06-09,53,71;" +
    "2018-06-09,54,74;" +
    "2018-06-10,46,77;" +
    "2018-06-10,46,77;" +
    "2018-06-11,50,87;" +
    "2018-06-11,50,87;" +
    "2018-06-12,56,96;" +
    "2018-06-12,55,96;" +
    "2018-06-13,54,93;" +
    "2018-06-13,54,94;" +
    "2018-06-14,54,85;" +
    "2018-06-14,52,85;" +
    "2018-06-15,53,77;" +
    "2018-06-15,52,78;" +
    "2018-06-16,50,78;" +
    "2018-06-16,50,78;" +
    "2018-06-17,54,;" +
    "2018-06-17,52,78;" +
    "2018-06-18,60,83;" +
    "2018-06-18,54,83;" +
    "2018-06-19,54,87;" +
    "2018-06-19,53,85;" +
    "2018-06-20,52,81;" +
    "2018-06-20,52,83;" +
    "2018-06-21,50,88;" +
    "2018-06-21,50,89;" +
    "2018-06-22,54,99;" +
    "2018-06-22,54,100;" +
    "2018-06-23,61,102;" +
    "2018-06-23,61,102;" +
    "2018-06-24,59,87;" +
    "2018-06-24,60,100;" +
    "2018-06-25,57,83;" +
    "2018-06-25,58,84;" +
    "2018-06-26,53,87;" +
    "2018-06-26,53,88;" +
    "2018-06-27,54,78;" +
    "2018-06-27,52,81;" +
    "2018-06-28,53,79;" +
    "2018-06-28,53,78;" +
    "2018-06-29,53,91;" +
    "2018-06-29,53,92;" +
    "2018-06-30,57,98;" +
    "2018-06-30,58,98;" +
    "2018-07-01,58,89;" +
    "2018-07-01,59,90;" +
    "2018-07-02,57,79;" +
    "2018-07-02,56,81;" +
    "2018-07-03,54,81;" +
    "2018-07-03,53,82;" +
    "2018-07-04,54,77;" +
    "2018-07-04,52,78;" +
    "2018-07-05,54,89;" +
    "2018-07-05,52,90;" +
    "2018-07-06,64,96;" +
    "2018-07-06,64,97;" +
    "2018-07-07,60,94;" +
    "2018-07-07,62,95;" +
    "2018-07-08,59,92;" +
    "2018-07-08,57,92;" +
    "2018-07-09,56,96;" +
    "2018-07-09,56,96;" +
    "2018-07-10,58,94;" +
    "2018-07-10,61,94;" +
    "2018-07-11,55,95;" +
    "2018-07-11,54,95;" +
    "2018-07-12,60,93;" +
    "2018-07-12,58,93;" +
    "2018-07-13,66,93;" +
    "2018-07-13,65,93;" +
    "2018-07-14,58,88;" +
    "2018-07-14,60,88;" +
    "2018-07-15,55,87;" +
    "2018-07-15,55,88;" +
    "2018-07-16,56,93;" +
    "2018-07-16,56,94;" +
    "2018-07-17,56,96;" +
    "2018-07-17,56,96;" +
    "2018-07-18,58,96;" +
    "2018-07-18,57,98;" +
    "2018-07-19,60,98;" +
    "2018-07-19,58,99;" +
    "2018-07-20,61,94;" +
    "2018-07-20,60,94;" +
    "2018-07-21,62,89;" +
    "2018-07-21,62,89;" +
    "2018-07-22,60,89;" +
    "2018-07-22,60,88;" +
    "2018-07-23,59,93;" +
    "2018-07-23,58,94;" +
    "2018-07-24,57,95;" +
    "2018-07-24,57,95;" +
    "2018-07-25,60,99;" +
    "2018-07-25,60,99;" +
    "2018-07-26,57,92;" +
    "2018-07-26,56,93;" +
    "2018-07-27,55,87;" +
    "2018-07-27,55,90;" +
    "2018-07-28,55,93;" +
    "2018-07-28,54,94;" +
    "2018-07-29,55,87;" +
    "2018-07-29,53,90;" +
    "2018-07-30,56,92;" +
    "2018-07-30,55,93;" +
    "2018-07-31,56,90;" +
    "2018-07-31,55,91;" +
    "2018-08-01,55,88;" +
    "2018-08-01,54,88;" +
    "2018-08-02,54,87;" +
    "2018-08-02,53,87;" +
    "2018-08-03,54,95;" +
    "2018-08-03,53,95;" +
    "2018-08-04,59,95;" +
    "2018-08-04,58,95;" +
    "2018-08-05,56,90;" +
    "2018-08-05,55,90;" +
    "2018-08-06,57,96;" +
    "2018-08-06,55,95;" +
    "2018-08-07,58,86;" +
    "2018-08-07,57,90;" +
    "2018-08-08,55,93;" +
    "2018-08-08,55,94;" +
    "2018-08-09,57,100;" +
    "2018-08-09,56,100;" +
    "2018-08-10,60,98;" +
    "2018-08-10,58,98;" +
    "2018-08-11,63,93;" +
    "2018-08-11,61,94;" +
    "2018-08-12,58,94;" +
    "2018-08-12,57,94;" +
    "2018-08-13,57,82;" +
    "2018-08-13,56,83;" +
    "2018-08-14,57,75;" +
    "2018-08-14,56,76;" +
    "2018-08-15,58,77;" +
    "2018-08-15,59,78;" +
    "2018-08-16,58,86;" +
    "2018-08-16,58,87;" +
    "2018-08-17,57,96;" +
    "2018-08-17,55,96;" +
    "2018-08-18,57,99;" +
    "2018-08-18,56,99;" +
    "2018-08-19,56,93;" +
    "2018-08-19,55,94;" +
    "2018-08-20,55,82;" +
    "2018-08-20,54,87;" +
    "2018-08-21,57,73;" +
    "2018-08-21,55,74;" +
    "2018-08-22,58,79;" +
    "2018-08-22,60,79;" +
    "2018-08-23,58,76;" +
    "2018-08-23,58,76;" +
    "2018-08-24,56,79;" +
    "2018-08-24,57,80;" +
    "2018-08-25,54,85;" +
    "2018-08-25,54,85;" +
    "2018-08-26,52,82;" +
    "2018-08-26,51,83;" +
    "2018-08-27,56,78;" +
    "2018-08-27,55,80;" +
    "2018-08-28,57,75;" +
    "2018-08-28,56,75;" +
    "2018-08-29,53,79;" +
    "2018-08-29,52,79;" +
    "2018-08-30,58,75;" +
    "2018-08-30,57,76;" +
    "2018-08-31,57,84;" +
    "2018-08-31,57,85;" +
    "2018-09-01,54,85;" +
    "2018-09-01,54,85;" +
    "2018-09-02,54,92;" +
    "2018-09-02,54,92;" +
    "2018-09-03,54,86;" +
    "2018-09-03,54,87;" +
    "2018-09-04,56,90;" +
    "2018-09-04,55,90;" +
    "2018-09-05,55,81;" +
    "2018-09-05,55,83;" +
    "2018-09-06,55,86;" +
    "2018-09-06,54,86;" +
    "2018-09-07,54,95;" +
    "2018-09-07,52,97;" +
    "2018-09-08,54,91;" +
    "2018-09-08,53,90;" +
    "2018-09-09,51,92;" +
    "2018-09-09,50,92;" +
    "2018-09-10,52,91;" +
    "2018-09-10,49,90;" +
    "2018-09-11,55,86;" +
    "2018-09-11,52,85;" +
    "2018-09-12,51,76;" +
    "2018-09-12,53,74;" +
    "2018-09-13,56,75;" +
    "2018-09-13,55,75;" +
    "2018-09-14,49,80;" +
    "2018-09-14,48,81;" +
    "2018-09-15,54,73;" +
    "2018-09-15,54,73;" +
    "2018-09-16,48,77;" +
    "2018-09-16,46,78;" +
    "2018-09-17,49,77;" +
    "2018-09-17,47,77;" +
    "2018-09-18,49,79;" +
    "2018-09-18,48,79;" +
    "2018-09-19,48,86;" +
    "2018-09-19,46,86;" +
    "2018-09-20,49,92;" +
    "2018-09-20,50,92;" +
    "2018-09-21,53,92;" +
    "2018-09-21,52,92;" +
    "2018-09-22,52,90;" +
    "2018-09-22,52,89;" +
    "2018-09-23,51,86;" +
    "2018-09-23,50,86;" +
    "2018-09-24,48,92;" +
    "2018-09-24,48,91;" +
    "2018-09-25,50,93;" +
    "2018-09-25,50,94;" +
    "2018-09-26,53,96;" +
    "2018-09-26,52,94;" +
    "2018-09-27,55,92;" +
    "2018-09-27,54,92;" +
    "2018-09-28,55,71;" +
    "2018-09-28,54,77;" +
    "2018-09-29,56,75;" +
    "2018-09-29,54,75;" +
    "2018-09-30,54,79;" +
    "2018-09-30,53,79;" +
    "2018-10-01,56,81;" +
    "2018-10-01,54,82;" +
    "2018-10-02,62,83;" +
    "2018-10-02,61,81;" +
    "2018-10-03,61,80;" +
    "2018-10-03,60,78;" +
    "2018-10-04,54,75;" +
    "2018-10-04,58,74;" +
    "2018-10-05,50,78;" +
    "2018-10-05,49,77;" +
    "2018-10-06,51,78;" +
    "2018-10-06,52,78;" +
    "2018-10-07,60,80;" +
    "2018-10-07,58,78;" +
    "2018-10-08,53,82;" +
    "2018-10-08,53,80;" +
    "2018-10-09,53,82;" +
    "2018-10-09,52,82;" +
    "2018-10-10,51,73;" +
    "2018-10-10,49,74;" +
    "2018-10-11,49,76;" +
    "2018-10-11,47,75;" +
    "2018-10-12,49,85;" +
    "2018-10-12,48,82;" +
    "2018-10-13,48,83;" +
    "2018-10-13,48,82;" +
    "2018-10-14,45,80;" +
    "2018-10-14,45,78;" +
    "2018-10-15,48,81;" +
    "2018-10-15,52,80;" +
    "2018-10-16,42,83;" +
    "2018-10-16,43,84;" +
    "2018-10-17,45,81;" +
    "2018-10-17,44,81;" +
    "2018-10-18,44,82;" +
    "2018-10-18,44,80;" +
    "2018-10-19,47,84;" +
    "2018-10-19,45,83;" +
    "2018-10-20,48,86;" +
    "2018-10-20,46,86;" +
    "2018-10-21,45,78;" +
    "2018-10-21,45,78;" +
    "2018-10-22,50,74;" +
    "2018-10-22,50,74;" +
    "2018-10-23,48,69;" +
    "2018-10-23,46,69;" +
    "2018-10-24,44,78;" +
    "2018-10-24,43,78;" +
    "2018-10-25,51,81;" +
    "2018-10-25,50,81;" +
    "2018-10-26,49,84;" +
    "2018-10-26,48,82;" +
    "2018-10-27,50,84;" +
    "2018-10-27,50,83;" +
    "2018-10-28,52,75;" +
    "2018-10-28,51,75;" +
    "2018-10-29,45,73;" +
    "2018-10-29,46,71;" +
    "2018-10-30,40,76;" +
    "2018-10-30,41,76;" +
    "2018-10-31,42,78;" +
    "2018-10-31,43,75;" +
    "2018-11-01,55,83;" +
    "2018-11-01,55,80;" +
    "2018-11-02,48,85;" +
    "2018-11-02,48,84;" +
    "2018-11-03,47,85;" +
    "2018-11-03,49,83;" +
    "2018-11-04,46,81;" +
    "2018-11-04,47,81;" +
    "2018-11-05,46,80;" +
    "2018-11-05,45,79;" +
    "2018-11-06,41,78;" +
    "2018-11-06,44,76;" +
    "2018-11-07,42,77;" +
    "2018-11-07,42,76;" +
    "2018-11-08,45,70;" +
    "2018-11-08,52,68;" +
    "2018-11-09,40,71;" +
    "2018-11-09,44,70;" +
    "2018-11-10,33,63;" +
    "2018-11-10,33,61;" +
    "2018-11-11,35,66;" +
    "2018-11-11,38,64;" +
    "2018-11-12,34,69;" +
    "2018-11-12,34,67;" +
    "2018-11-13,37,65;" +
    "2018-11-13,36,64;" +
    "2018-11-14,33,68;" +
    "2018-11-14,34,66;" +
    "2018-11-15,34,66;" +
    "2018-11-15,34,64;" +
    "2018-11-16,35,68;" +
    "2018-11-16,34,66;" +
    "2018-11-17,34,66;" +
    "2018-11-17,34,67;" +
    "2018-11-18,33,65;" +
    "2018-11-18,33,64;" +
    "2018-11-19,36,66;" +
    "2018-11-19,36,64;" +
    "2018-11-20,34,67;" +
    "2018-11-20,34,68;" +
    "2018-11-21,42,65;" +
    "2018-11-21,43,64;" +
    "2018-11-22,49,64;" +
    "2018-11-22,50,63;" +
    "2018-11-23,53,62;" +
    "2018-11-23,52,61;" +
    "2018-11-24,48,64;" +
    "2018-11-24,54,63;" +
    "2018-11-25,45,66;" +
    "2018-11-25,46,66;" +
    "2018-11-26,40,65;" +
    "2018-11-26,40,64;" +
    "2018-11-27,45,62;" +
    "2018-11-27,44,60;" +
    "2018-11-28,56,64;" +
    "2018-11-28,55,64;" +
    "2018-11-29,50,59;" +
    "2018-11-29,51,59;" +
    "2018-11-30,42,60;" +
    "2018-11-30,45,59;" +
    "2018-12-01,40,58;" +
    "2018-12-01,42,56;" +
    "2018-12-02,33,56;" +
    "2018-12-02,34,55;" +
    "2018-12-03,38,53;" +
    "2018-12-03,40,52;" +
    "2018-12-04,45,51;" +
    "2018-12-04,44,49;" +
    "2018-12-05,47,55;" +
    "2018-12-05,45,53;" +
    "2018-12-06,41,61;" +
    "2018-12-06,47,59;" +
    "2018-12-07,36,62;" +
    "2018-12-07,37,61;" +
    "2018-12-08,35,58;" +
    "2018-12-08,36,55;" +
    "2018-12-09,37,49;" +
    "2018-12-09,37,48;" +
    "2018-12-10,38,56;" +
    "2018-12-10,41,55;" +
    "2018-12-11,33,61;" +
    "2018-12-11,32,61;" +
    "2018-12-12,37,60;" +
    "2018-12-12,41,58;" +
    "2018-12-13,35,60;" +
    "2018-12-13,36,57;" +
    "2018-12-14,40,63;" +
    "2018-12-14,39,61;" +
    "2018-12-15,41,61;" +
    "2018-12-15,41,61;" +
    "2018-12-16,42,62;" +
    "2018-12-16,42,60;" +
    "2018-12-17,44,60;" +
    "2018-12-17,44,59;" +
    "2018-12-18,38,63;" +
    "2018-12-18,38,63;" +
    "2018-12-19,42,58;" +
    "2018-12-19,44,57;" +
    "2018-12-20,40,53;" +
    "2018-12-20,40,52;" +
    "2018-12-21,44,59;" +
    "2018-12-21,47,57;" +
    "2018-12-22,40,60;" +
    "2018-12-22,42,59;" +
    "2018-12-23,41,59;" +
    "2018-12-23,41,59;" +
    "2018-12-24,41,61;" +
    "2018-12-24,41,60;" +
    "2018-12-25,42,60;" +
    "2018-12-25,42,60;" +
    "2018-12-26,34,59;" +
    "2018-12-26,34,59;" +
    "2018-12-27,38,57;" +
    "2018-12-27,39,57;" +
    "2018-12-28,36,56;" +
    "2018-12-28,40,55;" +
    "2018-12-29,34,60;" +
    "2018-12-29,37,58;" +
    "2018-12-30,33,58;" +
    "2018-12-30,33,58;" +
    "2018-12-31,36,53;" +
    "2018-12-31,36,53;"