export const data = "1980-01-01,49,61;" +
    "1980-01-02,35,59;" +
    "1980-01-03,38,55;" +
    "1980-01-04,41,49;" +
    "1980-01-05,42,54;" +
    "1980-01-06,47,57;" +
    "1980-01-07,40,64;" +
    "1980-01-08,41,58;" +
    "1980-01-09,48,58;" +
    "1980-01-10,45,56;" +
    "1980-01-11,45,61;" +
    "1980-01-12,56,65;" +
    "1980-01-13,57,66;" +
    "1980-01-14,51,63;" +
    "1980-01-15,47,57;" +
    "1980-01-16,49,59;" +
    "1980-01-17,49,57;" +
    "1980-01-18,38,54;" +
    "1980-01-19,36,54;" +
    "1980-01-20,31,58;" +
    "1980-01-21,30,61;" +
    "1980-01-22,33,60;" +
    "1980-01-23,31,64;" +
    "1980-01-24,31,60;" +
    "1980-01-25,36,58;" +
    "1980-01-26,34,51;" +
    "1980-01-27,37,50;" +
    "1980-01-28,33,52;" +
    "1980-01-29,29,51;" +
    "1980-01-30,32,51;" +
    "1980-01-31,36,56;" +
    "1980-02-01,35,63;" +
    "1980-02-02,41,62;" +
    "1980-02-03,41,67;" +
    "1980-02-04,47,62;" +
    "1980-02-05,48,61;" +
    "1980-02-06,44,68;" +
    "1980-02-07,43,62;" +
    "1980-02-08,44,62;" +
    "1980-02-09,31,63;" +
    "1980-02-10,32,65;" +
    "1980-02-11,35,62;" +
    "1980-02-12,32,60;" +
    "1980-02-13,32,63;" +
    "1980-02-14,47,55;" +
    "1980-02-15,47,61;" +
    "1980-02-16,50,60;" +
    "1980-02-17,54,63;" +
    "1980-02-18,54,65;" +
    "1980-02-19,50,60;" +
    "1980-02-20,44,58;" +
    "1980-02-21,49,62;" +
    "1980-02-22,43,65;" +
    "1980-02-23,38,61;" +
    "1980-02-24,46,64;" +
    "1980-02-25,45,66;" +
    "1980-02-26,47,69;" +
    "1980-02-27,48,70;" +
    "1980-02-28,47,66;" +
    "1980-02-29,38,67;" +
    "1980-03-01,41,63;" +
    "1980-03-02,48,59;" +
    "1980-03-03,46,58;" +
    "1980-03-04,38,66;" +
    "1980-03-05,45,59;" +
    "1980-03-06,41,59;" +
    "1980-03-07,37,61;" +
    "1980-03-08,37,64;" +
    "1980-03-09,36,68;" +
    "1980-03-10,36,70;" +
    "1980-03-11,48,67;" +
    "1980-03-12,32,63;" +
    "1980-03-13,35,64;" +
    "1980-03-14,41,63;" +
    "1980-03-15,39,60;" +
    "1980-03-16,33,65;" +
    "1980-03-17,33,63;" +
    "1980-03-18,37,64;" +
    "1980-03-19,45,72;" +
    "1980-03-20,43,67;" +
    "1980-03-21,42,64;" +
    "1980-03-22,40,71;" +
    "1980-03-23,38,72;" +
    "1980-03-24,34,68;" +
    "1980-03-25,44,63;" +
    "1980-03-26,33,62;" +
    "1980-03-27,41,68;" +
    "1980-03-28,35,71;" +
    "1980-03-29,35,76;" +
    "1980-03-30,42,70;" +
    "1980-03-31,47,69;" +
    "1980-04-01,37,67;" +
    "1980-04-02,36,67;" +
    "1980-04-03,39,66;" +
    "1980-04-04,45,60;" +
    "1980-04-05,50,62;" +
    "1980-04-06,48,63;" +
    "1980-04-07,35,68;" +
    "1980-04-08,36,74;" +
    "1980-04-09,49,69;" +
    "1980-04-10,37,76;" +
    "1980-04-11,44,78;" +
    "1980-04-12,41,85;" +
    "1980-04-13,40,85;" +
    "1980-04-14,51,70;" +
    "1980-04-15,41,78;" +
    "1980-04-16,40,84;" +
    "1980-04-17,46,79;" +
    "1980-04-18,43,78;" +
    "1980-04-19,46,79;" +
    "1980-04-20,50,70;" +
    "1980-04-21,42,54;" +
    "1980-04-22,42,55;" +
    "1980-04-23,46,59;" +
    "1980-04-24,47,69;" +
    "1980-04-25,45,66;" +
    "1980-04-26,39,72;" +
    "1980-04-27,46,76;" +
    "1980-04-28,51,68;" +
    "1980-04-29,48,72;" +
    "1980-04-30,44,82;" +
    "1980-05-01,47,76;" +
    "1980-05-02,45,79;" +
    "1980-05-03,46,83;" +
    "1980-05-04,46,83;" +
    "1980-05-05,48,73;" +
    "1980-05-06,49,71;" +
    "1980-05-07,42,80;" +
    "1980-05-08,45,75;" +
    "1980-05-09,50,62;" +
    "1980-05-10,42,65;" +
    "1980-05-11,40,59;" +
    "1980-05-12,48,63;" +
    "1980-05-13,49,62;" +
    "1980-05-14,49,72;" +
    "1980-05-15,46,74;" +
    "1980-05-16,42,78;" +
    "1980-05-17,43,87;" +
    "1980-05-18,47,89;" +
    "1980-05-19,49,95;" +
    "1980-05-20,52,91;" +
    "1980-05-21,50,72;" +
    "1980-05-22,49,69;" +
    "1980-05-23,43,65;" +
    "1980-05-24,40,65;" +
    "1980-05-25,39,71;" +
    "1980-05-26,38,74;" +
    "1980-05-27,45,69;" +
    "1980-05-28,49,67;" +
    "1980-05-29,49,75;" +
    "1980-05-30,45,71;" +
    "1980-05-31,51,75;" +
    "1980-06-01,50,75;" +
    "1980-06-02,47,73;" +
    "1980-06-03,48,70;" +
    "1980-06-04,50,68;" +
    "1980-06-05,42,71;" +
    "1980-06-06,47,72;" +
    "1980-06-07,46,76;" +
    "1980-06-08,48,79;" +
    "1980-06-09,47,83;" +
    "1980-06-10,48,80;" +
    "1980-06-11,51,71;" +
    "1980-06-12,51,72;" +
    "1980-06-13,49,74;" +
    "1980-06-14,49,82;" +
    "1980-06-15,46,90;" +
    "1980-06-16,49,88;" +
    "1980-06-17,50,93;" +
    "1980-06-18,50,91;" +
    "1980-06-19,49,73;" +
    "1980-06-20,51,78;" +
    "1980-06-21,50,77;" +
    "1980-06-22,48,74;" +
    "1980-06-23,51,77;" +
    "1980-06-24,52,78;" +
    "1980-06-25,56,77;" +
    "1980-06-26,48,87;" +
    "1980-06-27,49,94;" +
    "1980-06-28,49,101;" +
    "1980-06-29,57,94;" +
    "1980-06-30,52,91;" +
    "1980-07-01,56,86;" +
    "1980-07-02,55,73;" +
    "1980-07-03,53,74;" +
    "1980-07-04,50,75;" +
    "1980-07-05,50,77;" +
    "1980-07-06,50,76;" +
    "1980-07-07,51,74;" +
    "1980-07-08,53,74;" +
    "1980-07-09,53,78;" +
    "1980-07-10,47,90;" +
    "1980-07-11,52,89;" +
    "1980-07-12,51,82;" +
    "1980-07-13,55,77;" +
    "1980-07-14,53,88;" +
    "1980-07-15,50,98;" +
    "1980-07-16,57,96;" +
    "1980-07-17,53,94;" +
    "1980-07-18,52,92;" +
    "1980-07-19,52,92;" +
    "1980-07-20,53,93;" +
    "1980-07-21,53,95;" +
    "1980-07-22,53,95;" +
    "1980-07-23,54,96;" +
    "1980-07-24,51,103;" +
    "1980-07-25,55,111;" +
    "1980-07-26,61,106;" +
    "1980-07-27,61,107;" +
    "1980-07-28,68,106;" +
    "1980-07-29,65,104;" +
    "1980-07-30,63,101;" +
    "1980-07-31,68,101;" +
    "1980-08-01,62,106;" +
    "1980-08-02,58,99;" +
    "1980-08-03,54,93;" +
    "1980-08-04,53,90;" +
    "1980-08-05,54,85;" +
    "1980-08-06,57,90;" +
    "1980-08-07,54,96;" +
    "1980-08-08,53,92;" +
    "1980-08-09,52,98;" +
    "1980-08-10,53,100;" +
    "1980-08-11,54,98;" +
    "1980-08-12,52,94;" +
    "1980-08-13,52,89;" +
    "1980-08-14,54,84;" +
    "1980-08-15,50,84;" +
    "1980-08-16,53,89;" +
    "1980-08-17,53,89;" +
    "1980-08-18,52,85;" +
    "1980-08-19,50,84;" +
    "1980-08-20,53,85;" +
    "1980-08-21,54,90;" +
    "1980-08-22,53,87;" +
    "1980-08-23,51,81;" +
    "1980-08-24,51,89;" +
    "1980-08-25,52,88;" +
    "1980-08-26,53,88;" +
    "1980-08-27,51,83;" +
    "1980-08-28,44,83;" +
    "1980-08-29,48,79;" +
    "1980-08-30,51,83;" +
    "1980-08-31,51,89;" +
    "1980-09-01,51,90;" +
    "1980-09-02,52,86;" +
    "1980-09-03,53,87;" +
    "1980-09-04,53,94;" +
    "1980-09-05,52,88;" +
    "1980-09-06,53,80;" +
    "1980-09-07,55,73;" +
    "1980-09-08,58,84;" +
    "1980-09-09,55,85;" +
    "1980-09-10,54,80;" +
    "1980-09-11,55,85;" +
    "1980-09-12,55,84;" +
    "1980-09-13,52,77;" +
    "1980-09-14,52,74;" +
    "1980-09-15,51,83;" +
    "1980-09-16,47,83;" +
    "1980-09-17,56,85;" +
    "1980-09-18,55,80;" +
    "1980-09-19,57,80;" +
    "1980-09-20,47,75;" +
    "1980-09-21,46,86;" +
    "1980-09-22,46,91;" +
    "1980-09-23,48,98;" +
    "1980-09-24,50,99;" +
    "1980-09-25,54,98;" +
    "1980-09-26,53,92;" +
    "1980-09-27,52,78;" +
    "1980-09-28,55,89;" +
    "1980-09-29,48,94;" +
    "1980-09-30,51,98;" +
    "1980-10-01,54,100;" +
    "1980-10-02,54,106;" +
    "1980-10-03,55,106;" +
    "1980-10-04,58,106;" +
    "1980-10-05,55,100;" +
    "1980-10-06,55,97;" +
    "1980-10-07,53,101;" +
    "1980-10-08,52,100;" +
    "1980-10-09,50,95;" +
    "1980-10-10,53,85;" +
    "1980-10-11,45,74;" +
    "1980-10-12,53,72;" +
    "1980-10-13,51,67;" +
    "1980-10-14,46,65;" +
    "1980-10-15,40,71;" +
    "1980-10-16,37,74;" +
    "1980-10-17,38,75;" +
    "1980-10-18,40,78;" +
    "1980-10-19,41,81;" +
    "1980-10-20,42,82;" +
    "1980-10-21,42,82;" +
    "1980-10-22,44,85;" +
    "1980-10-23,44,84;" +
    "1980-10-24,49,79;" +
    "1980-10-25,51,65;" +
    "1980-10-26,41,72;" +
    "1980-10-27,41,73;" +
    "1980-10-28,45,76;" +
    "1980-10-29,39,76;" +
    "1980-10-30,38,74;" +
    "1980-10-31,40,80;" +
    "1980-11-01,41,70;" +
    "1980-11-02,42,78;" +
    "1980-11-03,45,79;" +
    "1980-11-04,46,82;" +
    "1980-11-05,45,75;" +
    "1980-11-06,43,78;" +
    "1980-11-07,47,76;" +
    "1980-11-08,44,70;" +
    "1980-11-09,39,70;" +
    "1980-11-10,41,65;" +
    "1980-11-11,47,64;" +
    "1980-11-12,32,67;" +
    "1980-11-13,30,67;" +
    "1980-11-14,31,69;" +
    "1980-11-15,39,65;" +
    "1980-11-16,43,67;" +
    "1980-11-17,39,67;" +
    "1980-11-18,35,70;" +
    "1980-11-19,35,65;" +
    "1980-11-20,34,69;" +
    "1980-11-21,33,62;" +
    "1980-11-22,51,61;" +
    "1980-11-23,52,58;" +
    "1980-11-24,40,65;" +
    "1980-11-25,40,65;" +
    "1980-11-26,34,63;" +
    "1980-11-27,32,68;" +
    "1980-11-28,33,69;" +
    "1980-11-29,38,61;" +
    "1980-11-30,47,58;" +
    "1980-12-01,34,60;" +
    "1980-12-02,44,61;" +
    "1980-12-03,54,61;" +
    "1980-12-04,48,59;" +
    "1980-12-05,32,56;" +
    "1980-12-06,30,57;" +
    "1980-12-07,32,52;" +
    "1980-12-08,33,55;" +
    "1980-12-09,26,57;" +
    "1980-12-10,27,57;" +
    "1980-12-11,26,60;" +
    "1980-12-12,26,56;" +
    "1980-12-13,27,62;" +
    "1980-12-14,28,63;" +
    "1980-12-15,35,67;" +
    "1980-12-16,33,67;" +
    "1980-12-17,35,66;" +
    "1980-12-18,45,59;" +
    "1980-12-19,44,62;" +
    "1980-12-20,42,61;" +
    "1980-12-21,40,55;" +
    "1980-12-22,52,61;" +
    "1980-12-23,46,57;" +
    "1980-12-24,41,56;" +
    "1980-12-25,43,61;" +
    "1980-12-26,40,57;" +
    "1980-12-27,36,61;" +
    "1980-12-28,39,64;" +
    "1980-12-29,37,56;" +
    "1980-12-30,40,49;" +
    "1980-12-31,37,44;"