export const data = "2013-01-01,33,50;" +
    "2013-01-01,32,49;" +
    "2013-01-02,28,53;" +
    "2013-01-02,28,52;" +
    "2013-01-03,27,54;" +
    "2013-01-03,28,52;" +
    "2013-01-04,32,55;" +
    "2013-01-04,31,53;" +
    "2013-01-05,33,53;" +
    "2013-01-05,32,52;" +
    "2013-01-06,36,48;" +
    "2013-01-06,42,46;" +
    "2013-01-07,34,56;" +
    "2013-01-07,31,57;" +
    "2013-01-08,36,59;" +
    "2013-01-08,35,58;" +
    "2013-01-09,35,53;" +
    "2013-01-09,35,52;" +
    "2013-01-10,33,51;" +
    "2013-01-10,34,51;" +
    "2013-01-11,29,50;" +
    "2013-01-11,29,50;" +
    "2013-01-12,30,49;" +
    "2013-01-12,29,48;" +
    "2013-01-13,25,48;" +
    "2013-01-13,25,48;" +
    "2013-01-14,31,49;" +
    "2013-01-14,33,49;" +
    "2013-01-15,28,51;" +
    "2013-01-15,27,50;" +
    "2013-01-16,30,53;" +
    "2013-01-16,28,52;" +
    "2013-01-17,31,59;" +
    "2013-01-17,30,57;" +
    "2013-01-18,31,62;" +
    "2013-01-18,30,61;" +
    "2013-01-19,29,60;" +
    "2013-01-19,29,58;" +
    "2013-01-20,30,61;" +
    "2013-01-20,30,60;" +
    "2013-01-21,30,60;" +
    "2013-01-21,30,58;" +
    "2013-01-22,32,61;" +
    "2013-01-22,32,61;" +
    "2013-01-23,42,53;" +
    "2013-01-23,43,52;" +
    "2013-01-24,48,54;" +
    "2013-01-24,47,53;" +
    "2013-01-25,49,66;" +
    "2013-01-25,49,65;" +
    "2013-01-26,45,58;" +
    "2013-01-26,44,58;" +
    "2013-01-27,36,56;" +
    "2013-01-27,36,56;" +
    "2013-01-28,35,58;" +
    "2013-01-28,36,59;" +
    "2013-01-29,37,59;" +
    "2013-01-29,38,59;" +
    "2013-01-30,40,65;" +
    "2013-01-30,42,64;" +
    "2013-01-31,35,62;" +
    "2013-01-31,35,62;" +
    "2013-02-01,37,64;" +
    "2013-02-01,36,63;" +
    "2013-02-02,41,62;" +
    "2013-02-02,40,63;" +
    "2013-02-03,41,60;" +
    "2013-02-03,44,62;" +
    "2013-02-04,36,65;" +
    "2013-02-04,35,66;" +
    "2013-02-05,44,55;" +
    "2013-02-05,45,56;" +
    "2013-02-06,37,61;" +
    "2013-02-06,37,62;" +
    "2013-02-07,34,55;" +
    "2013-02-07,34,55;" +
    "2013-02-08,33,54;" +
    "2013-02-08,37,53;" +
    "2013-02-09,31,58;" +
    "2013-02-09,30,57;" +
    "2013-02-10,29,60;" +
    "2013-02-10,29,59;" +
    "2013-02-11,31,61;" +
    "2013-02-11,31,60;" +
    "2013-02-12,33,60;" +
    "2013-02-12,32,59;" +
    "2013-02-13,32,66;" +
    "2013-02-13,33,65;" +
    "2013-02-14,35,66;" +
    "2013-02-14,36,66;" +
    "2013-02-15,38,68;" +
    "2013-02-15,38,68;" +
    "2013-02-16,38,67;" +
    "2013-02-16,38,66;" +
    "2013-02-17,39,66;" +
    "2013-02-17,41,66;" +
    "2013-02-18,36,53;" +
    "2013-02-18,36,57;" +
    "2013-02-19,36,50;" +
    "2013-02-19,40,45;" +
    "2013-02-20,30,58;" +
    "2013-02-20,30,59;" +
    "2013-02-21,35,60;" +
    "2013-02-21,35,61;" +
    "2013-02-22,34,63;" +
    "2013-02-22,33,65;" +
    "2013-02-23,42,59;" +
    "2013-02-23,45,61;" +
    "2013-02-24,36,61;" +
    "2013-02-24,36,61;" +
    "2013-02-25,32,63;" +
    "2013-02-25,33,65;" +
    "2013-02-26,33,68;" +
    "2013-02-26,33,67;" +
    "2013-02-27,34,70;" +
    "2013-02-27,34,71;" +
    "2013-02-28,39,69;" +
    "2013-02-28,39,71;" +
    "2013-03-01,41,71;" +
    "2013-03-01,41,70;" +
    "2013-03-02,45,73;" +
    "2013-03-02,45,72;" +
    "2013-03-03,47,64;" +
    "2013-03-03,50,66;" +
    "2013-03-04,44,61;" +
    "2013-03-04,46,63;" +
    "2013-03-05,40,61;" +
    "2013-03-05,40,63;" +
    "2013-03-06,41,56;" +
    "2013-03-06,46,57;" +
    "2013-03-07,35,57;" +
    "2013-03-07,36,57;" +
    "2013-03-08,41,60;" +
    "2013-03-08,40,63;" +
    "2013-03-09,40,68;" +
    "2013-03-09,41,69;" +
    "2013-03-10,35,69;" +
    "2013-03-10,35,68;" +
    "2013-03-11,38,74;" +
    "2013-03-11,38,75;" +
    "2013-03-12,42,75;" +
    "2013-03-12,42,75;" +
    "2013-03-13,44,77;" +
    "2013-03-13,44,77;" +
    "2013-03-14,48,76;" +
    "2013-03-14,48,78;" +
    "2013-03-15,42,73;" +
    "2013-03-15,44,74;" +
    "2013-03-16,46,73;" +
    "2013-03-16,44,75;" +
    "2013-03-17,39,70;" +
    "2013-03-17,41,72;" +
    "2013-03-18,38,68;" +
    "2013-03-18,40,69;" +
    "2013-03-19,44,72;" +
    "2013-03-19,44,73;" +
    "2013-03-20,51,66;" +
    "2013-03-20,48,67;" +
    "2013-03-21,43,66;" +
    "2013-03-21,45,68;" +
    "2013-03-22,39,69;" +
    "2013-03-22,39,69;" +
    "2013-03-23,36,70;" +
    "2013-03-23,40,71;" +
    "2013-03-24,38,74;" +
    "2013-03-24,38,76;" +
    "2013-03-25,42,62;" +
    "2013-03-25,42,69;" +
    "2013-03-26,46,70;" +
    "2013-03-26,46,72;" +
    "2013-03-27,47,67;" +
    "2013-03-27,46,70;" +
    "2013-03-28,53,73;" +
    "2013-03-28,52,70;" +
    "2013-03-29,48,76;" +
    "2013-03-29,50,76;" +
    "2013-03-30,54,74;" +
    "2013-03-30,55,75;" +
    "2013-03-31,50,69;" +
    "2013-03-31,49,70;" +
    "2013-04-01,50,67;" +
    "2013-04-01,52,68;" +
    "2013-04-02,43,72;" +
    "2013-04-02,44,73;" +
    "2013-04-03,47,77;" +
    "2013-04-03,46,77;" +
    "2013-04-04,53,68;" +
    "2013-04-04,50,74;" +
    "2013-04-05,53,66;" +
    "2013-04-05,51,67;" +
    "2013-04-06,51,67;" +
    "2013-04-06,53,68;" +
    "2013-04-07,50,66;" +
    "2013-04-07,49,67;" +
    "2013-04-08,46,67;" +
    "2013-04-08,44,68;" +
    "2013-04-09,54,75;" +
    "2013-04-09,49,74;" +
    "2013-04-10,48,86;" +
    "2013-04-10,50,87;" +
    "2013-04-11,49,72;" +
    "2013-04-11,48,79;" +
    "2013-04-12,43,80;" +
    "2013-04-12,42,81;" +
    "2013-04-13,47,70;" +
    "2013-04-13,46,71;" +
    "2013-04-14,43,69;" +
    "2013-04-14,43,71;" +
    "2013-04-15,45,61;" +
    "2013-04-15,45,62;" +
    "2013-04-16,36,68;" +
    "2013-04-16,37,68;" +
    "2013-04-17,47,70;" +
    "2013-04-17,47,70;" +
    "2013-04-18,40,77;" +
    "2013-04-18,40,78;" +
    "2013-04-19,45,84;" +
    "2013-04-19,46,85;" +
    "2013-04-20,50,82;" +
    "2013-04-20,48,84;" +
    "2013-04-21,45,89;" +
    "2013-04-21,47,88;" +
    "2013-04-22,51,89;" +
    "2013-04-22,53,89;" +
    "2013-04-23,53,84;" +
    "2013-04-23,58,85;" +
    "2013-04-24,46,76;" +
    "2013-04-24,45,79;" +
    "2013-04-25,50,71;" +
    "2013-04-25,50,72;" +
    "2013-04-26,50,78;" +
    "2013-04-26,51,80;" +
    "2013-04-27,48,86;" +
    "2013-04-27,47,87;" +
    "2013-04-28,49,91;" +
    "2013-04-28,47,91;" +
    "2013-04-29,55,91;" +
    "2013-04-29,55,92;" +
    "2013-04-30,51,86;" +
    "2013-04-30,52,84;" +
    "2013-05-01,61,86;" +
    "2013-05-01,63,84;" +
    "2013-05-02,55,93;" +
    "2013-05-02,55,93;" +
    "2013-05-03,53,93;" +
    "2013-05-03,53,93;" +
    "2013-05-04,53,90;" +
    "2013-05-04,55,91;" +
    "2013-05-05,55,73;" +
    "2013-05-05,53,82;" +
    "2013-05-06,57,71;" +
    "2013-05-06,56,71;" +
    "2013-05-07,52,74;" +
    "2013-05-07,52,75;" +
    "2013-05-08,52,70;" +
    "2013-05-08,51,71;" +
    "2013-05-09,52,73;" +
    "2013-05-09,52,73;" +
    "2013-05-10,52,78;" +
    "2013-05-10,51,80;" +
    "2013-05-11,51,94;" +
    "2013-05-11,50,94;" +
    "2013-05-12,54,88;" +
    "2013-05-12,54,88;" +
    "2013-05-13,52,85;" +
    "2013-05-13,51,86;" +
    "2013-05-14,53,78;" +
    "2013-05-14,51,80;" +
    "2013-05-15,49,71;" +
    "2013-05-15,49,73;" +
    "2013-05-16,54,70;" +
    "2013-05-16,52,72;" +
    "2013-05-17,51,70;" +
    "2013-05-17,50,71;" +
    "2013-05-18,49,77;" +
    "2013-05-18,48,78;" +
    "2013-05-19,50,86;" +
    "2013-05-19,49,86;" +
    "2013-05-20,51,92;" +
    "2013-05-20,51,91;" +
    "2013-05-21,51,72;" +
    "2013-05-21,52,82;" +
    "2013-05-22,44,68;" +
    "2013-05-22,43,69;" +
    "2013-05-23,49,71;" +
    "2013-05-23,47,72;" +
    "2013-05-24,46,74;" +
    "2013-05-24,43,76;" +
    "2013-05-25,48,69;" +
    "2013-05-25,46,70;" +
    "2013-05-26,53,72;" +
    "2013-05-26,52,73;" +
    "2013-05-27,52,66;" +
    "2013-05-27,52,66;" +
    "2013-05-28,56,72;" +
    "2013-05-28,56,73;" +
    "2013-05-29,53,72;" +
    "2013-05-29,54,74;" +
    "2013-05-30,49,80;" +
    "2013-05-30,47,82;" +
    "2013-05-31,49,92;" +
    "2013-05-31,49,93;" +
    "2013-06-01,55,96;" +
    "2013-06-01,55,97;" +
    "2013-06-02,55,88;" +
    "2013-06-02,56,91;" +
    "2013-06-03,54,83;" +
    "2013-06-03,53,84;" +
    "2013-06-04,54,76;" +
    "2013-06-04,52,78;" +
    "2013-06-05,54,78;" +
    "2013-06-05,53,80;" +
    "2013-06-06,53,86;" +
    "2013-06-06,52,88;" +
    "2013-06-07,54,102;" +
    "2013-06-07,53,102;" +
    "2013-06-08,64,107;" +
    "2013-06-08,64,107;" +
    "2013-06-09,59,78;" +
    "2013-06-09,58,89;" +
    "2013-06-10,56,76;" +
    "2013-06-10,56,76;" +
    "2013-06-11,55,81;" +
    "2013-06-11,54,83;" +
    "2013-06-12,53,83;" +
    "2013-06-12,53,83;" +
    "2013-06-13,50,83;" +
    "2013-06-13,49,84;" +
    "2013-06-14,54,89;" +
    "2013-06-14,52,89;" +
    "2013-06-15,53,79;" +
    "2013-06-15,51,83;" +
    "2013-06-16,52,85;" +
    "2013-06-16,49,85;" +
    "2013-06-17,53,78;" +
    "2013-06-17,52,76;" +
    "2013-06-18,55,71;" +
    "2013-06-18,53,70;" +
    "2013-06-19,50,76;" +
    "2013-06-19,51,78;" +
    "2013-06-20,51,82;" +
    "2013-06-20,50,83;" +
    "2013-06-21,52,86;" +
    "2013-06-21,49,87;" +
    "2013-06-22,52,91;" +
    "2013-06-22,52,92;" +
    "2013-06-23,57,71;" +
    "2013-06-23,56,78;" +
    "2013-06-24,59,70;" +
    "2013-06-24,59,70;" +
    "2013-06-25,63,81;" +
    "2013-06-25,61,81;" +
    "2013-06-26,63,87;" +
    "2013-06-26,61,88;" +
    "2013-06-27,61,95;" +
    "2013-06-27,60,95;" +
    "2013-06-28,64,103;" +
    "2013-06-28,63,103;" +
    "2013-06-29,66,105;" +
    "2013-06-29,65,104;" +
    "2013-06-30,66,101;" +
    "2013-06-30,66,100;" +
    "2013-07-01,64,103;" +
    "2013-07-01,63,103;" +
    "2013-07-02,66,103;" +
    "2013-07-02,69,104;" +
    "2013-07-03,64,102;" +
    "2013-07-03,62,102;" +
    "2013-07-04,69,103;" +
    "2013-07-04,71,103;" +
    "2013-07-05,57,78;" +
    "2013-07-05,56,96;" +
    "2013-07-06,56,84;" +
    "2013-07-06,54,85;" +
    "2013-07-07,54,90;" +
    "2013-07-07,52,91;" +
    "2013-07-08,56,92;" +
    "2013-07-08,54,92;" +
    "2013-07-09,59,101;" +
    "2013-07-09,58,101;" +
    "2013-07-10,56,92;" +
    "2013-07-10,57,93;" +
    "2013-07-11,55,82;" +
    "2013-07-11,54,84;" +
    "2013-07-12,54,85;" +
    "2013-07-12,53,87;" +
    "2013-07-13,55,92;" +
    "2013-07-13,54,94;" +
    "2013-07-14,55,88;" +
    "2013-07-14,54,90;" +
    "2013-07-15,55,79;" +
    "2013-07-15,54,81;" +
    "2013-07-16,57,74;" +
    "2013-07-16,54,81;" +
    "2013-07-17,57,83;" +
    "2013-07-17,57,85;" +
    "2013-07-18,54,93;" +
    "2013-07-18,54,93;" +
    "2013-07-19,55,94;" +
    "2013-07-19,54,95;" +
    "2013-07-20,56,94;" +
    "2013-07-20,54,95;" +
    "2013-07-21,57,93;" +
    "2013-07-21,55,94;" +
    "2013-07-22,58,89;" +
    "2013-07-22,57,89;" +
    "2013-07-23,65,89;" +
    "2013-07-23,64,89;" +
    "2013-07-24,61,95;" +
    "2013-07-24,60,96;" +
    "2013-07-25,63,89;" +
    "2013-07-25,61,92;" +
    "2013-07-26,57,90;" +
    "2013-07-26,56,92;" +
    "2013-07-27,57,87;" +
    "2013-07-27,56,90;" +
    "2013-07-28,57,83;" +
    "2013-07-28,56,85;" +
    "2013-07-29,55,81;" +
    "2013-07-29,53,83;" +
    "2013-07-30,55,86;" +
    "2013-07-30,54,87;" +
    "2013-07-31,55,83;" +
    "2013-07-31,54,84;" +
    "2013-08-01,54,78;" +
    "2013-08-01,53,79;" +
    "2013-08-02,53,87;" +
    "2013-08-02,50,87;" +
    "2013-08-03,56,88;" +
    "2013-08-03,53,87;" +
    "2013-08-04,56,84;" +
    "2013-08-04,54,84;" +
    "2013-08-05,56,83;" +
    "2013-08-05,56,84;" +
    "2013-08-06,57,79;" +
    "2013-08-06,54,79;" +
    "2013-08-07,59,77;" +
    "2013-08-07,57,77;" +
    "2013-08-08,58,80;" +
    "2013-08-08,57,80;" +
    "2013-08-09,58,77;" +
    "2013-08-09,55,78;" +
    "2013-08-10,58,83;" +
    "2013-08-10,61,82;" +
    "2013-08-11,59,86;" +
    "2013-08-11,57,85;" +
    "2013-08-12,56,88;" +
    "2013-08-12,53,88;" +
    "2013-08-13,56,97;" +
    "2013-08-13,53,96;" +
    "2013-08-14,57,90;" +
    "2013-08-14,55,89;" +
    "2013-08-15,63,89;" +
    "2013-08-15,61,89;" +
    "2013-08-16,63,92;" +
    "2013-08-16,60,91;" +
    "2013-08-17,62,87;" +
    "2013-08-17,59,86;" +
    "2013-08-18,60,102;" +
    "2013-08-18,56,100;" +
    "2013-08-19,68,104;" +
    "2013-08-19,65,103;" +
    "2013-08-20,62,92;" +
    "2013-08-20,60,92;" +
    "2013-08-21,59,86;" +
    "2013-08-21,56,86;" +
    "2013-08-22,58,80;" +
    "2013-08-22,56,80;" +
    "2013-08-23,58,82;" +
    "2013-08-23,56,82;" +
    "2013-08-24,58,84;" +
    "2013-08-24,55,84;" +
    "2013-08-25,63,89;" +
    "2013-08-25,61,88;" +
    "2013-08-26,61,84;" +
    "2013-08-26,61,84;" +
    "2013-08-27,58,89;" +
    "2013-08-27,54,89;" +
    "2013-08-28,58,89;" +
    "2013-08-28,57,87;" +
    "2013-08-29,63,88;" +
    "2013-08-29,60,88;" +
    "2013-08-30,61,97;" +
    "2013-08-30,57,96;" +
    "2013-08-31,62,87;" +
    "2013-08-31,59,87;" +
    "2013-09-01,62,88;" +
    "2013-09-01,59,88;" +
    "2013-09-02,65,87;" +
    "2013-09-02,64,87;" +
    "2013-09-03,61,82;" +
    "2013-09-03,59,81;" +
    "2013-09-04,61,83;" +
    "2013-09-04,59,82;" +
    "2013-09-05,61,83;" +
    "2013-09-05,59,82;" +
    "2013-09-06,54,93;" +
    "2013-09-06,52,90;" +
    "2013-09-07,58,100;" +
    "2013-09-07,57,99;" +
    "2013-09-08,61,100;" +
    "2013-09-08,59,100;" +
    "2013-09-09,63,102;" +
    "2013-09-09,61,100;" +
    "2013-09-10,62,85;" +
    "2013-09-10,60,87;" +
    "2013-09-11,59,80;" +
    "2013-09-11,57,79;" +
    "2013-09-12,62,85;" +
    "2013-09-12,61,85;" +
    "2013-09-13,59,86;" +
    "2013-09-13,58,86;" +
    "2013-09-14,57,75;" +
    "2013-09-14,56,75;" +
    "2013-09-15,58,83;" +
    "2013-09-15,55,81;" +
    "2013-09-16,61,86;" +
    "2013-09-16,59,86;" +
    "2013-09-17,59,78;" +
    "2013-09-17,58,74;" +
    "2013-09-18,54,87;" +
    "2013-09-18,50,86;" +
    "2013-09-19,52,94;" +
    "2013-09-19,50,92;" +
    "2013-09-20,56,78;" +
    "2013-09-20,54,85;" +
    "2013-09-21,58,72;" +
    "2013-09-21,59,69;" +
    "2013-09-22,54,76;" +
    "2013-09-22,53,75;" +
    "2013-09-23,54,85;" +
    "2013-09-23,51,84;" +
    "2013-09-24,59,77;" +
    "2013-09-24,56,77;" +
    "2013-09-25,53,72;" +
    "2013-09-25,52,71;" +
    "2013-09-26,47,78;" +
    "2013-09-26,44,77;" +
    "2013-09-27,49,80;" +
    "2013-09-27,47,77;" +
    "2013-09-28,50,87;" +
    "2013-09-28,62,86;" +
    "2013-09-29,56,80;" +
    "2013-09-29,52,79;" +
    "2013-09-30,60,78;" +
    "2013-09-30,58,76;" +
    "2013-10-01,55,77;" +
    "2013-10-01,52,76;" +
    "2013-10-02,50,81;" +
    "2013-10-02,48,80;" +
    "2013-10-03,48,76;" +
    "2013-10-03,45,73;" +
    "2013-10-04,56,81;" +
    "2013-10-04,59,78;" +
    "2013-10-05,48,85;" +
    "2013-10-05,48,82;" +
    "2013-10-06,47,88;" +
    "2013-10-06,46,83;" +
    "2013-10-07,51,87;" +
    "2013-10-07,49,86;" +
    "2013-10-08,50,79;" +
    "2013-10-08,48,79;" +
    "2013-10-09,49,72;" +
    "2013-10-09,46,72;" +
    "2013-10-10,48,77;" +
    "2013-10-10,46,76;" +
    "2013-10-11,46,76;" +
    "2013-10-11,45,74;" +
    "2013-10-12,43,77;" +
    "2013-10-12,42,76;" +
    "2013-10-13,47,77;" +
    "2013-10-13,44,74;" +
    "2013-10-14,46,80;" +
    "2013-10-14,44,76;" +
    "2013-10-15,45,81;" +
    "2013-10-15,45,78;" +
    "2013-10-16,47,85;" +
    "2013-10-16,44,83;" +
    "2013-10-17,48,85;" +
    "2013-10-17,46,83;" +
    "2013-10-18,47,86;" +
    "2013-10-18,45,84;" +
    "2013-10-19,48,86;" +
    "2013-10-19,46,82;" +
    "2013-10-20,46,87;" +
    "2013-10-20,45,86;" +
    "2013-10-21,50,84;" +
    "2013-10-21,45,83;" +
    "2013-10-22,48,86;" +
    "2013-10-22,45,82;" +
    "2013-10-23,49,84;" +
    "2013-10-23,45,82;" +
    "2013-10-24,50,64;" +
    "2013-10-24,49,69;" +
    "2013-10-25,50,79;" +
    "2013-10-25,46,79;" +
    "2013-10-26,45,83;" +
    "2013-10-26,44,79;" +
    "2013-10-27,45,65;" +
    "2013-10-27,45,73;" +
    "2013-10-28,51,65;" +
    "2013-10-28,48,63;" +
    "2013-10-29,46,65;" +
    "2013-10-29,50,64;" +
    "2013-10-30,42,70;" +
    "2013-10-30,40,68;" +
    "2013-10-31,43,75;" +
    "2013-10-31,41,73;" +
    "2013-11-01,45,76;" +
    "2013-11-01,42,73;" +
    "2013-11-02,43,77;" +
    "2013-11-02,42,75;" +
    "2013-11-03,42,68;" +
    "2013-11-03,44,65;" +
    "2013-11-04,40,71;" +
    "2013-11-04,37,68;" +
    "2013-11-05,47,73;" +
    "2013-11-05,51,70;" +
    "2013-11-06,44,72;" +
    "2013-11-06,42,69;" +
    "2013-11-07,48,78;" +
    "2013-11-07,46,77;" +
    "2013-11-08,46,79;" +
    "2013-11-08,43,78;" +
    "2013-11-09,45,76;" +
    "2013-11-09,43,74;" +
    "2013-11-10,42,73;" +
    "2013-11-10,41,72;" +
    "2013-11-11,44,70;" +
    "2013-11-11,43,67;" +
    "2013-11-12,49,77;" +
    "2013-11-12,53,75;" +
    "2013-11-13,44,79;" +
    "2013-11-13,43,79;" +
    "2013-11-14,47,74;" +
    "2013-11-14,44,72;" +
    "2013-11-15,41,68;" +
    "2013-11-15,41,67;" +
    "2013-11-16,41,66;" +
    "2013-11-16,40,64;" +
    "2013-11-17,44,67;" +
    "2013-11-17,42,65;" +
    "2013-11-18,41,62;" +
    "2013-11-18,40,60;" +
    "2013-11-19,48,65;" +
    "2013-11-19,45,64;" +
    "2013-11-20,53,64;" +
    "2013-11-20,54,61;" +
    "2013-11-21,51,62;" +
    "2013-11-21,46,59;" +
    "2013-11-22,46,70;" +
    "2013-11-22,46,67;" +
    "2013-11-23,39,65;" +
    "2013-11-23,37,62;" +
    "2013-11-24,37,66;" +
    "2013-11-24,35,65;" +
    "2013-11-25,38,64;" +
    "2013-11-25,36,61;" +
    "2013-11-26,41,63;" +
    "2013-11-26,39,58;" +
    "2013-11-27,43,63;" +
    "2013-11-27,40,59;" +
    "2013-11-28,43,70;" +
    "2013-11-28,41,68;" +
    "2013-11-29,38,71;" +
    "2013-11-29,38,68;" +
    "2013-11-30,38,68;" +
    "2013-11-30,38,66;" +
    "2013-12-01,38,73;" +
    "2013-12-01,38,69;" +
    "2013-12-02,39,68;" +
    "2013-12-02,38,67;" +
    "2013-12-03,39,57;" +
    "2013-12-03,44,54;" +
    "2013-12-04,30,51;" +
    "2013-12-04,32,48;" +
    "2013-12-05,24,51;" +
    "2013-12-05,25,50;" +
    "2013-12-06,26,54;" +
    "2013-12-06,26,52;" +
    "2013-12-07,34,49;" +
    "2013-12-07,34,47;" +
    "2013-12-08,27,45;" +
    "2013-12-08,28,42;" +
    "2013-12-09,29,48;" +
    "2013-12-09,27,44;" +
    "2013-12-10,27,52;" +
    "2013-12-10,25,48;" +
    "2013-12-11,30,54;" +
    "2013-12-11,28,50;" +
    "2013-12-12,30,60;" +
    "2013-12-12,28,58;" +
    "2013-12-13,37,62;" +
    "2013-12-13,28,59;" +
    "2013-12-14,30,59;" +
    "2013-12-14,30,56;" +
    "2013-12-15,31,64;" +
    "2013-12-15,30,59;" +
    "2013-12-16,37,67;" +
    "2013-12-16,39,63;" +
    "2013-12-17,38,66;" +
    "2013-12-17,36,62;" +
    "2013-12-18,42,65;" +
    "2013-12-18,40,65;" +
    "2013-12-19,37,61;" +
    "2013-12-19,37,60;" +
    "2013-12-20,37,64;" +
    "2013-12-20,38,63;" +
    "2013-12-21,34,67;" +
    "2013-12-21,33,66;" +
    "2013-12-22,35,66;" +
    "2013-12-22,34,64;" +
    "2013-12-23,36,64;" +
    "2013-12-23,34,61;" +
    "2013-12-24,37,71;" +
    "2013-12-24,36,67;" +
    "2013-12-25,36,64;" +
    "2013-12-25,36,60;" +
    "2013-12-26,34,68;" +
    "2013-12-26,34,64;" +
    "2013-12-27,33,63;" +
    "2013-12-27,33,60;" +
    "2013-12-28,32,70;" +
    "2013-12-28,30,66;" +
    "2013-12-29,39,66;" +
    "2013-12-29,42,62;" +
    "2013-12-30,33,68;" +
    "2013-12-30,32,65;" +
    "2013-12-31,34,65;" +
    "2013-12-31,34,62;"