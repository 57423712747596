export const data = "1982-01-01,36,54;" +
    "1982-01-02,36,49;" +
    "1982-01-03,29,50;" +
    "1982-01-04,38,55;" +
    "1982-01-05,43,52;" +
    "1982-01-06,32,46;" +
    "1982-01-07,31,46;" +
    "1982-01-08,29,48;" +
    "1982-01-09,30,55;" +
    "1982-01-10,28,57;" +
    "1982-01-11,30,58;" +
    "1982-01-12,29,59;" +
    "1982-01-13,37,52;" +
    "1982-01-14,28,53;" +
    "1982-01-15,27,53;" +
    "1982-01-16,32,47;" +
    "1982-01-17,30,45;" +
    "1982-01-18,37,55;" +
    "1982-01-19,35,52;" +
    "1982-01-20,38,47;" +
    "1982-01-21,38,51;" +
    "1982-01-22,28,51;" +
    "1982-01-23,31,52;" +
    "1982-01-24,33,53;" +
    "1982-01-25,36,53;" +
    "1982-01-26,42,54;" +
    "1982-01-27,29,55;" +
    "1982-01-28,35,53;" +
    "1982-01-29,32,58;" +
    "1982-01-30,33,55;" +
    "1982-01-31,31,66;" +
    "1982-02-01,38,67;" +
    "1982-02-02,44,64;" +
    "1982-02-03,38,68;" +
    "1982-02-04,38,52;" +
    "1982-02-05,30,52;" +
    "1982-02-06,29,54;" +
    "1982-02-07,30,60;" +
    "1982-02-08,34,59;" +
    "1982-02-09,38,57;" +
    "1982-02-10,31,55;" +
    "1982-02-11,30,58;" +
    "1982-02-12,33,59;" +
    "1982-02-13,47,63;" +
    "1982-02-14,54,66;" +
    "1982-02-15,54,64;" +
    "1982-02-16,53,67;" +
    "1982-02-17,48,65;" +
    "1982-02-18,41,68;" +
    "1982-02-19,46,72;" +
    "1982-02-20,45,74;" +
    "1982-02-21,50,73;" +
    "1982-02-22,45,65;" +
    "1982-02-23,32,59;" +
    "1982-02-24,31,64;" +
    "1982-02-25,35,67;" +
    "1982-02-26,44,59;" +
    "1982-02-27,46,62;" +
    "1982-02-28,47,63;" +
    "1982-03-01,51,59;" +
    "1982-03-02,47,58;" +
    "1982-03-03,38,60;" +
    "1982-03-04,37,60;" +
    "1982-03-05,35,61;" +
    "1982-03-06,35,63;" +
    "1982-03-07,43,60;" +
    "1982-03-08,43,68;" +
    "1982-03-09,45,63;" +
    "1982-03-10,51,67;" +
    "1982-03-11,52,65;" +
    "1982-03-12,46,67;" +
    "1982-03-13,39,63;" +
    "1982-03-14,45,53;" +
    "1982-03-15,39,54;" +
    "1982-03-16,39,53;" +
    "1982-03-17,37,55;" +
    "1982-03-18,37,54;" +
    "1982-03-19,35,58;" +
    "1982-03-20,32,61;" +
    "1982-03-21,36,60;" +
    "1982-03-22,34,67;" +
    "1982-03-23,35,67;" +
    "1982-03-24,41,64;" +
    "1982-03-25,39,65;" +
    "1982-03-26,46,59;" +
    "1982-03-27,45,62;" +
    "1982-03-28,40,55;" +
    "1982-03-29,39,52;" +
    "1982-03-30,38,57;" +
    "1982-03-31,40,52;" +
    "1982-04-01,35,49;" +
    "1982-04-02,38,56;" +
    "1982-04-03,49,62;" +
    "1982-04-04,39,60;" +
    "1982-04-05,39,58;" +
    "1982-04-06,35,57;" +
    "1982-04-07,34,60;" +
    "1982-04-08,37,61;" +
    "1982-04-09,37,66;" +
    "1982-04-10,51,70;" +
    "1982-04-11,53,68;" +
    "1982-04-12,46,64;" +
    "1982-04-13,41,63;" +
    "1982-04-14,50,61;" +
    "1982-04-15,37,64;" +
    "1982-04-16,35,69;" +
    "1982-04-17,37,73;" +
    "1982-04-18,40,79;" +
    "1982-04-19,44,74;" +
    "1982-04-20,49,75;" +
    "1982-04-21,47,78;" +
    "1982-04-22,45,82;" +
    "1982-04-23,44,80;" +
    "1982-04-24,43,77;" +
    "1982-04-25,45,71;" +
    "1982-04-26,49,69;" +
    "1982-04-27,44,76;" +
    "1982-04-28,46,73;" +
    "1982-04-29,43,80;" +
    "1982-04-30,43,75;" +
    "1982-05-01,45,66;" +
    "1982-05-02,39,81;" +
    "1982-05-03,43,83;" +
    "1982-05-04,51,85;" +
    "1982-05-05,50,85;" +
    "1982-05-06,49,83;" +
    "1982-05-07,48,80;" +
    "1982-05-08,45,69;" +
    "1982-05-09,45,66;" +
    "1982-05-10,41,65;" +
    "1982-05-11,41,72;" +
    "1982-05-12,45,68;" +
    "1982-05-13,47,67;" +
    "1982-05-14,49,73;" +
    "1982-05-15,44,80;" +
    "1982-05-16,44,83;" +
    "1982-05-17,48,73;" +
    "1982-05-18,41,75;" +
    "1982-05-19,42,85;" +
    "1982-05-20,43,82;" +
    "1982-05-21,47,73;" +
    "1982-05-22,49,87;" +
    "1982-05-23,53,97;" +
    "1982-05-24,59,98;" +
    "1982-05-25,55,93;" +
    "1982-05-26,53,75;" +
    "1982-05-27,51,78;" +
    "1982-05-28,48,79;" +
    "1982-05-29,47,64;" +
    "1982-05-30,52,75;" +
    "1982-05-31,50,67;" +
    "1982-06-01,51,65;" +
    "1982-06-02,43,66;" +
    "1982-06-03,44,68;" +
    "1982-06-04,46,68;" +
    "1982-06-05,43,75;" +
    "1982-06-06,43,70;" +
    "1982-06-07,48,73;" +
    "1982-06-08,42,84;" +
    "1982-06-09,48,81;" +
    "1982-06-10,50,73;" +
    "1982-06-11,50,66;" +
    "1982-06-12,50,73;" +
    "1982-06-13,47,75;" +
    "1982-06-14,50,89;" +
    "1982-06-15,55,93;" +
    "1982-06-16,55,87;" +
    "1982-06-17,53,81;" +
    "1982-06-18,54,75;" +
    "1982-06-19,54,78;" +
    "1982-06-20,53,76;" +
    "1982-06-21,53,78;" +
    "1982-06-22,53,79;" +
    "1982-06-23,54,79;" +
    "1982-06-24,55,77;" +
    "1982-06-25,56,80;" +
    "1982-06-26,56,87;" +
    "1982-06-27,59,80;" +
    "1982-06-28,56,72;" +
    "1982-06-29,53,70;" +
    "1982-06-30,51,68;" +
    "1982-07-01,54,65;" +
    "1982-07-02,53,76;" +
    "1982-07-03,49,73;" +
    "1982-07-04,51,74;" +
    "1982-07-05,49,83;" +
    "1982-07-06,49,89;" +
    "1982-07-07,52,77;" +
    "1982-07-08,49,86;" +
    "1982-07-09,50,90;" +
    "1982-07-10,53,89;" +
    "1982-07-11,53,90;" +
    "1982-07-12,53,93;" +
    "1982-07-13,57,94;" +
    "1982-07-14,55,95;" +
    "1982-07-15,59,94;" +
    "1982-07-16,51,75;" +
    "1982-07-17,51,83;" +
    "1982-07-18,53,85;" +
    "1982-07-19,52,88;" +
    "1982-07-20,51,87;" +
    "1982-07-21,50,92;" +
    "1982-07-22,54,94;" +
    "1982-07-23,52,92;" +
    "1982-07-24,52,86;" +
    "1982-07-25,51,82;" +
    "1982-07-26,51,84;" +
    "1982-07-27,51,87;" +
    "1982-07-28,52,96;" +
    "1982-07-29,54,94;" +
    "1982-07-30,51,95;" +
    "1982-07-31,54,95;" +
    "1982-08-01,50,87;" +
    "1982-08-02,50,84;" +
    "1982-08-03,52,73;" +
    "1982-08-04,49,84;" +
    "1982-08-05,50,93;" +
    "1982-08-07,65,100;" +
    "1982-08-08,60,95;" +
    "1982-08-09,55,77;" +
    "1982-08-10,51,79;" +
    "1982-08-11,56,78;" +
    "1982-08-12,49,92;" +
    "1982-08-13,55,81;" +
    "1982-08-14,53,78;" +
    "1982-08-15,51,88;" +
    "1982-08-16,52,93;" +
    "1982-08-17,54,94;" +
    "1982-08-18,54,87;" +
    "1982-08-19,55,83;" +
    "1982-08-20,53,92;" +
    "1982-08-21,56,103;" +
    "1982-08-22,60,100;" +
    "1982-08-23,56,92;" +
    "1982-08-24,56,84;" +
    "1982-08-25,54,74;" +
    "1982-08-26,54,77;" +
    "1982-08-27,52,84;" +
    "1982-08-28,53,84;" +
    "1982-08-29,57,80;" +
    "1982-08-30,49,88;" +
    "1982-08-31,50,93;" +
    "1982-09-01,55,95;" +
    "1982-09-02,57,94;" +
    "1982-09-03,58,84;" +
    "1982-09-04,49,93;" +
    "1982-09-05,52,100;" +
    "1982-09-06,58,94;" +
    "1982-09-07,56,101;" +
    "1982-09-08,59,99;" +
    "1982-09-09,59,92;" +
    "1982-09-10,55,88;" +
    "1982-09-11,61,91;" +
    "1982-09-12,54,91;" +
    "1982-09-13,54,88;" +
    "1982-09-14,51,80;" +
    "1982-09-15,56,68;" +
    "1982-09-16,55,70;" +
    "1982-09-17,53,67;" +
    "1982-09-18,55,74;" +
    "1982-09-19,51,80;" +
    "1982-09-20,47,82;" +
    "1982-09-21,49,87;" +
    "1982-09-22,50,91;" +
    "1982-09-23,50,83;" +
    "1982-09-24,61,79;" +
    "1982-09-25,60,75;" +
    "1982-09-26,50,74;" +
    "1982-09-27,48,77;" +
    "1982-09-28,46,80;" +
    "1982-09-29,50,73;" +
    "1982-09-30,48,78;" +
    "1982-10-01,43,80;" +
    "1982-10-02,44,81;" +
    "1982-10-03,46,82;" +
    "1982-10-04,50,73;" +
    "1982-10-05,43,80;" +
    "1982-10-06,44,72;" +
    "1982-10-07,41,75;" +
    "1982-10-08,41,78;" +
    "1982-10-09,50,79;" +
    "1982-10-10,45,83;" +
    "1982-10-11,45,87;" +
    "1982-10-12,46,83;" +
    "1982-10-13,48,85;" +
    "1982-10-14,49,90;" +
    "1982-10-15,48,80;" +
    "1982-10-16,48,74;" +
    "1982-10-17,54,78;" +
    "1982-10-18,42,75;" +
    "1982-10-19,54,78;" +
    "1982-10-20,52,78;" +
    "1982-10-21,52,73;" +
    "1982-10-22,56,84;" +
    "1982-10-23,56,83;" +
    "1982-10-24,57,77;" +
    "1982-10-25,59,67;" +
    "1982-10-26,49,66;" +
    "1982-10-27,39,69;" +
    "1982-10-28,39,68;" +
    "1982-10-29,45,71;" +
    "1982-10-30,50,62;" +
    "1982-10-31,40,70;" +
    "1982-11-01,38,66;" +
    "1982-11-02,46,70;" +
    "1982-11-03,38,68;" +
    "1982-11-04,38,71;" +
    "1982-11-05,38,71;" +
    "1982-11-06,39,70;" +
    "1982-11-07,46,64;" +
    "1982-11-08,40,56;" +
    "1982-11-09,35,45;" +
    "1982-11-10,40,57;" +
    "1982-11-11,32,53;" +
    "1982-11-12,35,53;" +
    "1982-11-13,30,60;" +
    "1982-11-14,33,50;" +
    "1982-11-15,30,53;" +
    "1982-11-16,32,58;" +
    "1982-11-17,38,54;" +
    "1982-11-18,50,59;" +
    "1982-11-19,45,62;" +
    "1982-11-20,35,61;" +
    "1982-11-21,32,62;" +
    "1982-11-22,43,51;" +
    "1982-11-23,46,53;" +
    "1982-11-24,44,62;" +
    "1982-11-25,34,58;" +
    "1982-11-26,38,51;" +
    "1982-11-27,42,62;" +
    "1982-11-28,50,60;" +
    "1982-11-29,49,60;" +
    "1982-11-30,45,59;" +
    "1982-12-01,43,57;" +
    "1982-12-02,33,51;" +
    "1982-12-03,42,59;" +
    "1982-12-04,35,65;" +
    "1982-12-05,40,62;" +
    "1982-12-06,43,50;" +
    "1982-12-07,39,57;" +
    "1982-12-08,40,59;" +
    "1982-12-09,39,63;" +
    "1982-12-10,29,55;" +
    "1982-12-11,30,60;" +
    "1982-12-12,30,61;" +
    "1982-12-13,37,59;" +
    "1982-12-14,32,47;" +
    "1982-12-15,42,61;" +
    "1982-12-16,43,62;" +
    "1982-12-17,43,61;" +
    "1982-12-18,32,57;" +
    "1982-12-19,32,49;" +
    "1982-12-20,39,63;" +
    "1982-12-21,49,60;" +
    "1982-12-22,49,60;" +
    "1982-12-23,39,53;" +
    "1982-12-24,33,55;" +
    "1982-12-25,37,53;" +
    "1982-12-26,34,49;" +
    "1982-12-27,38,52;" +
    "1982-12-28,36,57;" +
    "1982-12-29,36,53;" +
    "1982-12-30,28,52;" +
    "1982-12-31,26,48;"