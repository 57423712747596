export const data = "1988-01-01,30,42;" +
    "1988-01-02,35,42;" +
    "1988-01-03,39,55;" +
    "1988-01-04,44,53;" +
    "1988-01-05,45,55;" +
    "1988-01-06,39,50;" +
    "1988-01-07,34,58;" +
    "1988-01-08,45,55;" +
    "1988-01-09,46,59;" +
    "1988-01-10,51,60;" +
    "1988-01-11,43,55;" +
    "1988-01-12,31,55;" +
    "1988-01-13,35,60;" +
    "1988-01-14,39,60;" +
    "1988-01-15,44,56;" +
    "1988-01-16,41,56;" +
    "1988-01-17,42,47;" +
    "1988-01-18,39,55;" +
    "1988-01-19,33,54;" +
    "1988-01-20,34,54;" +
    "1988-01-21,32,65;" +
    "1988-01-22,40,64;" +
    "1988-01-23,36,63;" +
    "1988-01-24,49,65;" +
    "1988-01-25,38,63;" +
    "1988-01-26,40,69;" +
    "1988-01-27,39,65;" +
    "1988-01-28,45,62;" +
    "1988-01-29,48,61;" +
    "1988-01-30,41,58;" +
    "1988-01-31,32,56;" +
    "1988-02-01,37,54;" +
    "1988-02-02,30,54;" +
    "1988-02-03,29,56;" +
    "1988-02-04,29,61;" +
    "1988-02-05,30,60;" +
    "1988-02-06,31,60;" +
    "1988-02-07,33,65;" +
    "1988-02-08,36,71;" +
    "1988-02-09,39,72;" +
    "1988-02-10,45,73;" +
    "1988-02-11,49,71;" +
    "1988-02-12,38,69;" +
    "1988-02-13,38,65;" +
    "1988-02-14,43,72;" +
    "1988-02-15,40,70;" +
    "1988-02-16,42,66;" +
    "1988-02-17,47,68;" +
    "1988-02-18,38,63;" +
    "1988-02-19,46,68;" +
    "1988-02-20,37,65;" +
    "1988-02-21,38,69;" +
    "1988-02-22,39,71;" +
    "1988-02-23,44,68;" +
    "1988-02-24,42,68;" +
    "1988-02-25,39,67;" +
    "1988-02-26,49,71;" +
    "1988-02-27,57,66;" +
    "1988-02-28,51,68;" +
    "1988-02-29,52,64;" +
    "1988-03-01,47,58;" +
    "1988-03-02,37,68;" +
    "1988-03-03,42,69;" +
    "1988-03-04,46,64;" +
    "1988-03-05,52,62;" +
    "1988-03-06,47,65;" +
    "1988-03-07,43,69;" +
    "1988-03-08,41,75;" +
    "1988-03-09,45,62;" +
    "1988-03-10,36,61;" +
    "1988-03-11,42,60;" +
    "1988-03-12,33,64;" +
    "1988-03-13,33,70;" +
    "1988-03-14,36,70;" +
    "1988-03-15,40,72;" +
    "1988-03-16,35,73;" +
    "1988-03-17,37,76;" +
    "1988-03-18,41,75;" +
    "1988-03-19,43,78;" +
    "1988-03-20,44,80;" +
    "1988-03-21,48,71;" +
    "1988-03-22,46,71;" +
    "1988-03-23,51,66;" +
    "1988-03-24,41,78;" +
    "1988-03-25,44,81;" +
    "1988-03-26,47,88;" +
    "1988-03-27,44,68;" +
    "1988-03-28,50,69;" +
    "1988-03-29,40,74;" +
    "1988-03-30,44,69;" +
    "1988-03-31,53,73;" +
    "1988-04-01,42,80;" +
    "1988-04-02,41,77;" +
    "1988-04-03,45,63;" +
    "1988-04-04,45,70;" +
    "1988-04-05,40,80;" +
    "1988-04-06,48,86;" +
    "1988-04-07,46,74;" +
    "1988-04-08,66,71;" +
    "1988-04-09,46,81;" +
    "1988-04-10,50,88;" +
    "1988-04-11,52,91;" +
    "1988-04-12,49,81;" +
    "1988-04-13,51,65;" +
    "1988-04-14,50,60;" +
    "1988-04-15,49,66;" +
    "1988-04-16,50,57;" +
    "1988-04-17,49,67;" +
    "1988-04-18,49,69;" +
    "1988-04-19,53,58;" +
    "1988-04-20,46,64;" +
    "1988-04-21,44,66;" +
    "1988-04-22,48,61;" +
    "1988-04-23,45,64;" +
    "1988-04-24,42,65;" +
    "1988-04-25,44,75;" +
    "1988-04-26,44,82;" +
    "1988-04-27,53,77;" +
    "1988-04-28,51,69;" +
    "1988-04-29,52,69;" +
    "1988-04-30,42,63;" +
    "1988-05-01,38,69;" +
    "1988-05-02,42,74;" +
    "1988-05-03,45,71;" +
    "1988-05-04,46,63;" +
    "1988-05-05,45,62;" +
    "1988-05-06,45,60;" +
    "1988-05-07,49,62;" +
    "1988-05-08,48,68;" +
    "1988-05-09,47,76;" +
    "1988-05-10,48,85;" +
    "1988-05-11,54,94;" +
    "1988-05-12,53,85;" +
    "1988-05-13,48,75;" +
    "1988-05-14,46,85;" +
    "1988-05-15,50,78;" +
    "1988-05-16,53,63;" +
    "1988-05-17,45,70;" +
    "1988-05-18,48,81;" +
    "1988-05-19,49,89;" +
    "1988-05-20,54,96;" +
    "1988-05-21,58,96;" +
    "1988-05-22,57,86;" +
    "1988-05-23,50,78;" +
    "1988-05-24,47,74;" +
    "1988-05-25,51,72;" +
    "1988-05-26,50,70;" +
    "1988-05-27,54,75;" +
    "1988-05-28,57,69;" +
    "1988-05-29,46,66;" +
    "1988-05-30,45,72;" +
    "1988-05-31,50,76;" +
    "1988-06-01,56,84;" +
    "1988-06-02,53,80;" +
    "1988-06-03,57,73;" +
    "1988-06-04,51,67;" +
    "1988-06-05,48,66;" +
    "1988-06-06,46,66;" +
    "1988-06-07,48,68;" +
    "1988-06-08,44,74;" +
    "1988-06-09,53,75;" +
    "1988-06-10,53,80;" +
    "1988-06-11,52,72;" +
    "1988-06-12,50,86;" +
    "1988-06-13,53,96;" +
    "1988-06-14,57,92;" +
    "1988-06-15,55,83;" +
    "1988-06-16,55,78;" +
    "1988-06-17,56,82;" +
    "1988-06-18,55,100;" +
    "1988-06-19,65,98;" +
    "1988-06-20,54,82;" +
    "1988-06-21,55,84;" +
    "1988-06-22,58,94;" +
    "1988-06-23,60,91;" +
    "1988-06-24,58,88;" +
    "1988-06-25,58,86;" +
    "1988-06-26,52,87;" +
    "1988-06-27,57,88;" +
    "1988-06-28,51,88;" +
    "1988-06-29,52,91;" +
    "1988-06-30,55,98;" +
    "1988-07-01,60,99;" +
    "1988-07-02,62,99;" +
    "1988-07-03,57,89;" +
    "1988-07-04,53,76;" +
    "1988-07-05,52,84;" +
    "1988-07-06,51,93;" +
    "1988-07-07,57,95;" +
    "1988-07-08,53,93;" +
    "1988-07-09,58,100;" +
    "1988-07-10,63,97;" +
    "1988-07-11,52,78;" +
    "1988-07-12,54,79;" +
    "1988-07-13,58,82;" +
    "1988-07-14,54,82;" +
    "1988-07-15,57,90;" +
    "1988-07-16,58,102;" +
    "1988-07-17,65,109;" +
    "1988-07-18,66,104;" +
    "1988-07-19,61,95;" +
    "1988-07-20,57,92;" +
    "1988-07-21,59,95;" +
    "1988-07-22,61,100;" +
    "1988-07-23,63,99;" +
    "1988-07-24,64,95;" +
    "1988-07-25,62,99;" +
    "1988-07-26,64,98;" +
    "1988-07-27,63,96;" +
    "1988-07-28,57,97;" +
    "1988-07-29,61,100;" +
    "1988-07-30,63,93;" +
    "1988-07-31,59,84;" +
    "1988-08-01,59,86;" +
    "1988-08-02,58,83;" +
    "1988-08-03,59,85;" +
    "1988-08-04,57,82;" +
    "1988-08-05,57,75;" +
    "1988-08-06,56,77;" +
    "1988-08-07,55,85;" +
    "1988-08-08,54,88;" +
    "1988-08-09,53,90;" +
    "1988-08-10,55,83;" +
    "1988-08-11,56,76;" +
    "1988-08-12,56,71;" +
    "1988-08-13,55,74;" +
    "1988-08-14,55,74;" +
    "1988-08-15,56,82;" +
    "1988-08-16,53,92;" +
    "1988-08-17,55,96;" +
    "1988-08-18,56,100;" +
    "1988-08-19,58,95;" +
    "1988-08-20,56,86;" +
    "1988-08-21,56,78;" +
    "1988-08-22,56,86;" +
    "1988-08-23,57,88;" +
    "1988-08-24,55,98;" +
    "1988-08-25,58,104;" +
    "1988-08-26,66,103;" +
    "1988-08-27,60,96;" +
    "1988-08-28,61,93;" +
    "1988-08-29,61,101;" +
    "1988-08-30,64,99;" +
    "1988-08-31,60,96;" +
    "1988-09-01,58,94;" +
    "1988-09-02,56,90;" +
    "1988-09-03,57,102;" +
    "1988-09-04,61,110;" +
    "1988-09-05,64,104;" +
    "1988-09-06,59,93;" +
    "1988-09-07,52,93;" +
    "1988-09-08,54,86;" +
    "1988-09-09,55,77;" +
    "1988-09-10,56,86;" +
    "1988-09-11,54,89;" +
    "1988-09-12,55,88;" +
    "1988-09-13,54,85;" +
    "1988-09-14,53,87;" +
    "1988-09-15,53,76;" +
    "1988-09-16,51,83;" +
    "1988-09-17,46,86;" +
    "1988-09-18,46,85;" +
    "1988-09-19,49,85;" +
    "1988-09-20,49,79;" +
    "1988-09-21,49,77;" +
    "1988-09-22,49,81;" +
    "1988-09-23,48,88;" +
    "1988-09-24,52,77;" +
    "1988-09-25,52,70;" +
    "1988-09-26,48,80;" +
    "1988-09-27,55,82;" +
    "1988-09-28,50,91;" +
    "1988-09-29,52,92;" +
    "1988-09-30,55,96;" +
    "1988-10-01,53,91;" +
    "1988-10-02,54,76;" +
    "1988-10-03,55,87;" +
    "1988-10-04,53,81;" +
    "1988-10-05,56,75;" +
    "1988-10-06,55,70;" +
    "1988-10-07,55,71;" +
    "1988-10-08,48,86;" +
    "1988-10-09,52,91;" +
    "1988-10-10,50,79;" +
    "1988-10-11,55,68;" +
    "1988-10-12,53,67;" +
    "1988-10-13,51,65;" +
    "1988-10-14,43,71;" +
    "1988-10-15,43,81;" +
    "1988-10-16,54,89;" +
    "1988-10-17,55,91;" +
    "1988-10-18,54,93;" +
    "1988-10-19,52,85;" +
    "1988-10-20,50,88;" +
    "1988-10-21,52,79;" +
    "1988-10-22,50,86;" +
    "1988-10-23,50,79;" +
    "1988-10-24,48,76;" +
    "1988-10-25,50,76;" +
    "1988-10-26,50,68;" +
    "1988-10-27,50,64;" +
    "1988-10-28,52,64;" +
    "1988-10-29,46,69;" +
    "1988-10-30,42,73;" +
    "1988-10-31,46,71;" +
    "1988-11-01,51,68;" +
    "1988-11-02,54,69;" +
    "1988-11-03,51,74;" +
    "1988-11-04,55,75;" +
    "1988-11-05,52,86;" +
    "1988-11-06,52,70;" +
    "1988-11-07,44,63;" +
    "1988-11-08,34,61;" +
    "1988-11-09,33,67;" +
    "1988-11-10,29,66;" +
    "1988-11-11,38,64;" +
    "1988-11-12,52,61;" +
    "1988-11-13,50,64;" +
    "1988-11-14,40,55;" +
    "1988-11-15,44,61;" +
    "1988-11-16,50,60;" +
    "1988-11-17,41,58;" +
    "1988-11-18,37,58;" +
    "1988-11-19,42,60;" +
    "1988-11-20,36,61;" +
    "1988-11-21,37,57;" +
    "1988-11-22,53,64;" +
    "1988-11-23,47,62;" +
    "1988-11-24,42,62;" +
    "1988-11-25,44,58;" +
    "1988-11-26,43,57;" +
    "1988-11-27,38,58;" +
    "1988-11-28,41,60;" +
    "1988-11-29,45,60;" +
    "1988-11-30,43,57;" +
    "1988-12-01,35,59;" +
    "1988-12-02,34,56;" +
    "1988-12-03,39,62;" +
    "1988-12-04,37,64;" +
    "1988-12-05,40,66;" +
    "1988-12-06,43,68;" +
    "1988-12-07,40,66;" +
    "1988-12-08,52,67;" +
    "1988-12-09,43,62;" +
    "1988-12-10,39,62;" +
    "1988-12-11,36,69;" +
    "1988-12-12,37,64;" +
    "1988-12-13,36,68;" +
    "1988-12-14,38,64;" +
    "1988-12-15,44,56;" +
    "1988-12-16,43,57;" +
    "1988-12-17,40,53;" +
    "1988-12-18,37,54;" +
    "1988-12-19,43,51;" +
    "1988-12-20,42,50;" +
    "1988-12-21,37,52;" +
    "1988-12-22,40,52;" +
    "1988-12-23,33,52;" +
    "1988-12-24,41,54;" +
    "1988-12-25,37,47;" +
    "1988-12-26,28,48;" +
    "1988-12-27,27,45;" +
    "1988-12-28,33,42;" +
    "1988-12-29,26,47;" +
    "1988-12-30,28,51;" +
    "1988-12-31,38,48;"