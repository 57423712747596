export const data = "2001-01-01,31,58;" +
    "2001-01-01,35,58;" +
    "2001-01-02,30,62;" +
    "2001-01-02,33,60;" +
    "2001-01-03,30,64;" +
    "2001-01-03,33,63;" +
    "2001-01-04,28,64;" +
    "2001-01-04,33,62;" +
    "2001-01-05,27,65;" +
    "2001-01-05,32,65;" +
    "2001-01-06,28,61;" +
    "2001-01-06,31,60;" +
    "2001-01-07,33,53;" +
    "2001-01-07,35,53;" +
    "2001-01-08,38,55;" +
    "2001-01-08,47,53;" +
    "2001-01-09,31,50;" +
    "2001-01-09,34,51;" +
    "2001-01-10,37,60;" +
    "2001-01-10,40,60;" +
    "2001-01-11,39,51;" +
    "2001-01-11,41,51;" +
    "2001-01-12,37,56;" +
    "2001-01-12,43,56;" +
    "2001-01-13,40,55;" +
    "2001-01-13,41,56;" +
    "2001-01-14,34,54;" +
    "2001-01-14,42,55;" +
    "2001-01-15,28,54;" +
    "2001-01-15,30,55;" +
    "2001-01-16,34,52;" +
    "2001-01-16,40,53;" +
    "2001-01-17,28,56;" +
    "2001-01-17,32,57;" +
    "2001-01-18,26,54;" +
    "2001-01-18,29,54;" +
    "2001-01-19,36,58;" +
    "2001-01-19,41,57;" +
    "2001-01-20,32,54;" +
    "2001-01-20,33,55;" +
    "2001-01-21,30,55;" +
    "2001-01-21,33,56;" +
    "2001-01-22,32,63;" +
    "2001-01-22,34,64;" +
    "2001-01-23,39,58;" +
    "2001-01-23,41,57;" +
    "2001-01-24,38,54;" +
    "2001-01-24,43,54;" +
    "2001-01-25,35,54;" +
    "2001-01-25,38,48;" +
    "2001-01-26,33,52;" +
    "2001-01-26,34,55;" +
    "2001-01-27,28,57;" +
    "2001-01-27,30,57;" +
    "2001-01-28,31,56;" +
    "2001-01-28,33,56;" +
    "2001-01-29,35,57;" +
    "2001-01-29,39,56;" +
    "2001-01-30,32,54;" +
    "2001-01-30,35,54;" +
    "2001-01-31,30,59;" +
    "2001-01-31,33,59;" +
    "2001-02-01,28,61;" +
    "2001-02-01,31,60;" +
    "2001-02-02,34,60;" +
    "2001-02-02,35,60;" +
    "2001-02-03,36,68;" +
    "2001-02-03,38,68;" +
    "2001-02-04,42,70;" +
    "2001-02-04,45,70;" +
    "2001-02-05,37,69;" +
    "2001-02-05,40,70;" +
    "2001-02-06,39,55;" +
    "2001-02-06,43,60;" +
    "2001-02-07,30,54;" +
    "2001-02-07,33,54;" +
    "2001-02-08,33,57;" +
    "2001-02-08,36,59;" +
    "2001-02-09,41,51;" +
    "2001-02-09,44,51;" +
    "2001-02-10,40,50;" +
    "2001-02-10,40,51;" +
    "2001-02-11,37,50;" +
    "2001-02-11,42,51;" +
    "2001-02-12,36,48;" +
    "2001-02-12,39,49;" +
    "2001-02-13,34,58;" +
    "2001-02-13,35,58;" +
    "2001-02-14,33,56;" +
    "2001-02-14,36,59;" +
    "2001-02-15,32,56;" +
    "2001-02-15,34,57;" +
    "2001-02-16,34,61;" +
    "2001-02-16,36,61;" +
    "2001-02-17,41,62;" +
    "2001-02-17,44,62;" +
    "2001-02-18,46,59;" +
    "2001-02-18,47,60;" +
    "2001-02-19,47,59;" +
    "2001-02-19,46,61;" +
    "2001-02-20,47,56;" +
    "2001-02-20,47,57;" +
    "2001-02-21,47,61;" +
    "2001-02-21,48,62;" +
    "2001-02-22,42,53;" +
    "2001-02-22,44,54;" +
    "2001-02-23,42,53;" +
    "2001-02-23,41,54;" +
    "2001-02-24,42,55;" +
    "2001-02-24,42,55;" +
    "2001-02-25,41,57;" +
    "2001-02-25,44,58;" +
    "2001-02-26,43,62;" +
    "2001-02-26,44,64;" +
    "2001-02-27,38,68;" +
    "2001-02-27,40,68;" +
    "2001-02-28,38,59;" +
    "2001-02-28,38,61;" +
    "2001-03-01,32,60;" +
    "2001-03-01,35,62;" +
    "2001-03-02,40,57;" +
    "2001-03-02,44,59;" +
    "2001-03-03,38,54;" +
    "2001-03-03,39,54;" +
    "2001-03-04,49,61;" +
    "2001-03-04,49,61;" +
    "2001-03-05,46,64;" +
    "2001-03-05,47,64;" +
    "2001-03-06,49,65;" +
    "2001-03-06,52,66;" +
    "2001-03-07,43,67;" +
    "2001-03-07,45,69;" +
    "2001-03-08,44,59;" +
    "2001-03-08,50,62;" +
    "2001-03-09,41,60;" +
    "2001-03-09,42,61;" +
    "2001-03-10,34,63;" +
    "2001-03-10,36,66;" +
    "2001-03-11,35,65;" +
    "2001-03-11,38,66;" +
    "2001-03-12,37,69;" +
    "2001-03-12,39,71;" +
    "2001-03-13,39,70;" +
    "2001-03-13,41,73;" +
    "2001-03-14,39,71;" +
    "2001-03-14,42,73;" +
    "2001-03-15,41,59;" +
    "2001-03-15,42,61;" +
    "2001-03-16,42,65;" +
    "2001-03-16,44,68;" +
    "2001-03-17,41,71;" +
    "2001-03-17,42,73;" +
    "2001-03-18,42,77;" +
    "2001-03-18,46,77;" +
    "2001-03-19,46,77;" +
    "2001-03-19,49,77;" +
    "2001-03-20,52,80;" +
    "2001-03-20,55,82;" +
    "2001-03-21,48,72;" +
    "2001-03-21,51,76;" +
    "2001-03-22,50,66;" +
    "2001-03-22,52,68;" +
    "2001-03-23,49,67;" +
    "2001-03-23,51,69;" +
    "2001-03-24,50,72;" +
    "2001-03-24,50,74;" +
    "2001-03-25,47,66;" +
    "2001-03-25,54,68;" +
    "2001-03-26,40,68;" +
    "2001-03-26,43,70;" +
    "2001-03-27,40,73;" +
    "2001-03-27,43,76;" +
    "2001-03-28,49,72;" +
    "2001-03-28,51,75;" +
    "2001-03-29,47,73;" +
    "2001-03-29,47,75;" +
    "2001-03-30,43,80;" +
    "2001-03-30,46,82;" +
    "2001-03-31,45,80;" +
    "2001-03-31,49,83;" +
    "2001-04-01,45,64;" +
    "2001-04-01,46,71;" +
    "2001-04-02,41,61;" +
    "2001-04-02,42,62;" +
    "2001-04-03,31,58;" +
    "2001-04-03,35,60;" +
    "2001-04-04,34,61;" +
    "2001-04-04,36,62;" +
    "2001-04-05,36,65;" +
    "2001-04-05,39,67;" +
    "2001-04-06,44,55;" +
    "2001-04-06,44,56;" +
    "2001-04-07,40,58;" +
    "2001-04-07,43,57;" +
    "2001-04-08,32,56;" +
    "2001-04-08,38,57;" +
    "2001-04-09,41,61;" +
    "2001-04-09,41,64;" +
    "2001-04-10,35,65;" +
    "2001-04-10,40,68;" +
    "2001-04-11,42,59;" +
    "2001-04-11,48,59;" +
    "2001-04-12,35,66;" +
    "2001-04-12,37,67;" +
    "2001-04-13,40,56;" +
    "2001-04-13,46,59;" +
    "2001-04-14,35,65;" +
    "2001-04-14,36,67;" +
    "2001-04-15,35,66;" +
    "2001-04-15,38,70;" +
    "2001-04-16,42,67;" +
    "2001-04-16,42,68;" +
    "2001-04-17,43,74;" +
    "2001-04-17,45,76;" +
    "2001-04-18,43,72;" +
    "2001-04-18,46,72;" +
    "2001-04-19,48,60;" +
    "2001-04-19,48,62;" +
    "2001-04-20,39,56;" +
    "2001-04-20,44,56;" +
    "2001-04-21,35,66;" +
    "2001-04-21,37,68;" +
    "2001-04-22,41,66;" +
    "2001-04-22,42,69;" +
    "2001-04-23,41,76;" +
    "2001-04-23,42,77;" +
    "2001-04-24,46,84;" +
    "2001-04-24,49,85;" +
    "2001-04-25,51,86;" +
    "2001-04-25,53,89;" +
    "2001-04-26,48,75;" +
    "2001-04-26,49,82;" +
    "2001-04-27,45,69;" +
    "2001-04-27,46,72;" +
    "2001-04-28,48,64;" +
    "2001-04-28,50,66;" +
    "2001-04-29,43,74;" +
    "2001-04-29,44,76;" +
    "2001-04-30,47,79;" +
    "2001-04-30,47,81;" +
    "2001-05-01,49,80;" +
    "2001-05-01,51,82;" +
    "2001-05-02,47,75;" +
    "2001-05-02,50,75;" +
    "2001-05-03,49,80;" +
    "2001-05-03,58,80;" +
    "2001-05-04,43,86;" +
    "2001-05-04,47,87;" +
    "2001-05-05,48,86;" +
    "2001-05-05,52,87;" +
    "2001-05-06,45,90;" +
    "2001-05-06,50,91;" +
    "2001-05-07,52,95;" +
    "2001-05-07,56,97;" +
    "2001-05-08,55,97;" +
    "2001-05-08,59,98;" +
    "2001-05-09,51,91;" +
    "2001-05-09,56,94;" +
    "2001-05-10,47,95;" +
    "2001-05-10,49,97;" +
    "2001-05-11,54,87;" +
    "2001-05-11,57,91;" +
    "2001-05-12,52,70;" +
    "2001-05-12,54,80;" +
    "2001-05-13,49,68;" +
    "2001-05-13,53,70;" +
    "2001-05-14,47,76;" +
    "2001-05-14,49,78;" +
    "2001-05-15,54,80;" +
    "2001-05-15,55,80;" +
    "2001-05-16,53,79;" +
    "2001-05-16,56,81;" +
    "2001-05-17,49,86;" +
    "2001-05-17,49,88;" +
    "2001-05-18,51,87;" +
    "2001-05-18,52,88;" +
    "2001-05-19,52,94;" +
    "2001-05-19,56,96;" +
    "2001-05-20,53,98;" +
    "2001-05-20,57,99;" +
    "2001-05-21,59,100;" +
    "2001-05-21,61,100;" +
    "2001-05-22,59,91;" +
    "2001-05-22,60,92;" +
    "2001-05-23,55,92;" +
    "2001-05-23,57,93;" +
    "2001-05-24,54,92;" +
    "2001-05-24,55,92;" +
    "2001-05-25,54,87;" +
    "2001-05-25,57,89;" +
    "2001-05-26,51,76;" +
    "2001-05-26,52,84;" +
    "2001-05-27,51,70;" +
    "2001-05-27,52,71;" +
    "2001-05-28,50,71;" +
    "2001-05-28,51,69;" +
    "2001-05-29,50,90;" +
    "2001-05-29,50,91;" +
    "2001-05-30,58,98;" +
    "2001-05-30,60,98;" +
    "2001-05-31,63,104;" +
    "2001-05-31,64,104;" +
    "2001-06-01,54,80;" +
    "2001-06-01,64,96;" +
    "2001-06-02,51,72;" +
    "2001-06-02,52,76;" +
    "2001-06-03,48,78;" +
    "2001-06-03,48,80;" +
    "2001-06-04,46,83;" +
    "2001-06-04,48,84;" +
    "2001-06-05,50,84;" +
    "2001-06-05,51,85;" +
    "2001-06-06,52,89;" +
    "2001-06-06,53,90;" +
    "2001-06-07,55,94;" +
    "2001-06-07,58,96;" +
    "2001-06-08,55,86;" +
    "2001-06-08,59,92;" +
    "2001-06-09,52,82;" +
    "2001-06-09,52,83;" +
    "2001-06-10,50,74;" +
    "2001-06-10,51,76;" +
    "2001-06-11,54,72;" +
    "2001-06-11,54,74;" +
    "2001-06-12,55,80;" +
    "2001-06-12,54,82;" +
    "2001-06-13,49,89;" +
    "2001-06-13,50,90;" +
    "2001-06-14,51,96;" +
    "2001-06-14,56,98;" +
    "2001-06-15,57,93;" +
    "2001-06-15,59,95;" +
    "2001-06-16,53,98;" +
    "2001-06-16,55,99;" +
    "2001-06-17,57,94;" +
    "2001-06-17,59,96;" +
    "2001-06-18,55,97;" +
    "2001-06-18,59,98;" +
    "2001-06-19,61,97;" +
    "2001-06-19,62,98;" +
    "2001-06-20,60,102;" +
    "2001-06-20,61,102;" +
    "2001-06-21,62,101;" +
    "2001-06-21,63,102;" +
    "2001-06-22,56,96;" +
    "2001-06-22,64,97;" +
    "2001-06-23,53,82;" +
    "2001-06-23,54,85;" +
    "2001-06-24,52,77;" +
    "2001-06-24,52,80;" +
    "2001-06-25,55,76;" +
    "2001-06-25,55,76;" +
    "2001-06-26,54,80;" +
    "2001-06-26,54,81;" +
    "2001-06-27,62,75;" +
    "2001-06-27,61,75;" +
    "2001-06-28,55,80;" +
    "2001-06-28,59,81;" +
    "2001-06-29,51,90;" +
    "2001-06-29,52,91;" +
    "2001-06-30,58,90;" +
    "2001-06-30,57,92;" +
    "2001-07-01,54,95;" +
    "2001-07-01,54,97;" +
    "2001-07-02,58,105;" +
    "2001-07-02,60,106;" +
    "2001-07-03,68,108;" +
    "2001-07-03,69,109;" +
    "2001-07-04,71,98;" +
    "2001-07-04,73,104;" +
    "2001-07-05,62,93;" +
    "2001-07-05,63,96;" +
    "2001-07-06,57,91;" +
    "2001-07-06,58,91;" +
    "2001-07-07,60,91;" +
    "2001-07-07,62,93;" +
    "2001-07-08,60,89;" +
    "2001-07-08,60,91;" +
    "2001-07-09,57,81;" +
    "2001-07-09,58,83;" +
    "2001-07-10,57,78;" +
    "2001-07-10,57,80;" +
    "2001-07-11,56,73;" +
    "2001-07-11,56,74;" +
    "2001-07-12,56,78;" +
    "2001-07-12,56,81;" +
    "2001-07-13,55,87;" +
    "2001-07-13,55,89;" +
    "2001-07-14,54,68;" +
    "2001-07-14,54,82;" +
    "2001-07-15,55,74;" +
    "2001-07-15,55,77;" +
    "2001-07-16,53,68;" +
    "2001-07-16,55,69;" +
    "2001-07-17,52,81;" +
    "2001-07-17,51,83;" +
    "2001-07-18,53,86;" +
    "2001-07-18,52,88;" +
    "2001-07-19,55,74;" +
    "2001-07-19,55,77;" +
    "2001-07-20,56,74;" +
    "2001-07-20,56,74;" +
    "2001-07-21,55,74;" +
    "2001-07-21,56,76;" +
    "2001-07-22,52,89;" +
    "2001-07-22,52,90;" +
    "2001-07-23,55,89;" +
    "2001-07-23,55,91;" +
    "2001-07-24,57,86;" +
    "2001-07-24,57,88;" +
    "2001-07-25,58,85;" +
    "2001-07-25,57,88;" +
    "2001-07-26,56,88;" +
    "2001-07-26,58,90;" +
    "2001-07-27,55,89;" +
    "2001-07-27,55,92;" +
    "2001-07-28,53,90;" +
    "2001-07-28,54,92;" +
    "2001-07-29,56,80;" +
    "2001-07-29,57,87;" +
    "2001-07-30,55,73;" +
    "2001-07-30,56,75;" +
    "2001-07-31,53,88;" +
    "2001-07-31,54,90;" +
    "2001-08-01,55,85;" +
    "2001-08-01,56,88;" +
    "2001-08-02,56,88;" +
    "2001-08-02,55,89;" +
    "2001-08-03,56,80;" +
    "2001-08-03,56,84;" +
    "2001-08-04,59,79;" +
    "2001-08-04,59,81;" +
    "2001-08-05,56,84;" +
    "2001-08-05,57,85;" +
    "2001-08-06,56,93;" +
    "2001-08-06,56,94;" +
    "2001-08-07,58,101;" +
    "2001-08-07,59,102;" +
    "2001-08-08,63,98;" +
    "2001-08-08,65,100;" +
    "2001-08-09,58,83;" +
    "2001-08-09,58,88;" +
    "2001-08-10,57,80;" +
    "2001-08-10,58,82;" +
    "2001-08-11,54,85;" +
    "2001-08-11,55,87;" +
    "2001-08-12,55,76;" +
    "2001-08-12,56,80;" +
    "2001-08-13,55,79;" +
    "2001-08-13,56,81;" +
    "2001-08-14,53,90;" +
    "2001-08-14,55,92;" +
    "2001-08-15,55,90;" +
    "2001-08-15,55,92;" +
    "2001-08-16,52,97;" +
    "2001-08-16,53,100;" +
    "2001-08-17,60,97;" +
    "2001-08-17,60,98;" +
    "2001-08-18,55,92;" +
    "2001-08-18,56,94;" +
    "2001-08-19,55,88;" +
    "2001-08-19,56,91;" +
    "2001-08-20,53,71;" +
    "2001-08-20,54,79;" +
    "2001-08-21,53,76;" +
    "2001-08-21,54,76;" +
    "2001-08-22,59,73;" +
    "2001-08-22,58,75;" +
    "2001-08-23,58,81;" +
    "2001-08-23,60,82;" +
    "2001-08-24,57,86;" +
    "2001-08-24,58,87;" +
    "2001-08-25,51,92;" +
    "2001-08-25,54,94;" +
    "2001-08-26,56,96;" +
    "2001-08-26,56,98;" +
    "2001-08-27,57,100;" +
    "2001-08-27,58,101;" +
    "2001-08-28,57,98;" +
    "2001-08-28,58,99;" +
    "2001-08-29,55,85;" +
    "2001-08-29,55,87;" +
    "2001-08-30,57,77;" +
    "2001-08-30,56,77;" +
    "2001-08-31,56,86;" +
    "2001-08-31,56,86;" +
    "2001-09-01,56,91;" +
    "2001-09-01,56,92;" +
    "2001-09-02,56,93;" +
    "2001-09-02,58,94;" +
    "2001-09-03,60,93;" +
    "2001-09-03,60,95;" +
    "2001-09-04,57,91;" +
    "2001-09-04,60,92;" +
    "2001-09-05,55,81;" +
    "2001-09-05,54,83;" +
    "2001-09-06,50,91;" +
    "2001-09-06,50,93;" +
    "2001-09-07,54,92;" +
    "2001-09-07,55,93;" +
    "2001-09-08,54,83;" +
    "2001-09-08,55,84;" +
    "2001-09-09,55,75;" +
    "2001-09-09,55,76;" +
    "2001-09-10,52,78;" +
    "2001-09-10,53,80;" +
    "2001-09-11,59,79;" +
    "2001-09-11,59,80;" +
    "2001-09-12,55,71;" +
    "2001-09-12,55,74;" +
    "2001-09-13,58,83;" +
    "2001-09-13,57,85;" +
    "2001-09-14,54,92;" +
    "2001-09-14,54,94;" +
    "2001-09-15,54,82;" +
    "2001-09-15,55,85;" +
    "2001-09-16,56,67;" +
    "2001-09-16,56,72;" +
    "2001-09-17,55,82;" +
    "2001-09-17,55,85;" +
    "2001-09-18,55,82;" +
    "2001-09-18,55,84;" +
    "2001-09-19,54,86;" +
    "2001-09-19,54,88;" +
    "2001-09-20,54,86;" +
    "2001-09-20,54,87;" +
    "2001-09-21,54,86;" +
    "2001-09-21,54,88;" +
    "2001-09-22,51,91;" +
    "2001-09-22,52,94;" +
    "2001-09-23,54,81;" +
    "2001-09-23,55,82;" +
    "2001-09-24,54,74;" +
    "2001-09-24,54,77;" +
    "2001-09-25,54,79;" +
    "2001-09-25,54,79;" +
    "2001-09-26,51,84;" +
    "2001-09-26,52,85;" +
    "2001-09-27,56,73;" +
    "2001-09-27,55,78;" +
    "2001-09-28,50,79;" +
    "2001-09-28,52,80;" +
    "2001-09-29,49,89;" +
    "2001-09-29,49,90;" +
    "2001-09-30,53,94;" +
    "2001-09-30,54,92;" +
    "2001-10-01,58,98;" +
    "2001-10-01,59,100;" +
    "2001-10-02,56,98;" +
    "2001-10-02,57,100;" +
    "2001-10-03,56,95;" +
    "2001-10-03,57,96;" +
    "2001-10-04,57,73;" +
    "2001-10-04,56,82;" +
    "2001-10-05,57,69;" +
    "2001-10-05,57,68;" +
    "2001-10-06,53,70;" +
    "2001-10-06,56,71;" +
    "2001-10-07,50,71;" +
    "2001-10-07,51,73;" +
    "2001-10-08,54,75;" +
    "2001-10-08,54,76;" +
    "2001-10-09,49,81;" +
    "2001-10-09,50,82;" +
    "2001-10-10,45,86;" +
    "2001-10-10,47,86;" +
    "2001-10-11,54,79;" +
    "2001-10-11,54,79;" +
    "2001-10-12,50,86;" +
    "2001-10-12,53,85;" +
    "2001-10-13,55,88;" +
    "2001-10-13,57,86;" +
    "2001-10-14,53,90;" +
    "2001-10-14,58,89;" +
    "2001-10-15,52,91;" +
    "2001-10-15,54,92;" +
    "2001-10-16,52,77;" +
    "2001-10-16,54,82;" +
    "2001-10-17,54,75;" +
    "2001-10-17,55,76;" +
    "2001-10-18,48,85;" +
    "2001-10-18,48,85;" +
    "2001-10-19,47,85;" +
    "2001-10-19,49,83;" +
    "2001-10-20,48,81;" +
    "2001-10-20,50,82;" +
    "2001-10-21,49,62;" +
    "2001-10-21,51,66;" +
    "2001-10-22,48,77;" +
    "2001-10-22,49,78;" +
    "2001-10-23,52,75;" +
    "2001-10-23,54,75;" +
    "2001-10-24,47,80;" +
    "2001-10-24,50,79;" +
    "2001-10-25,45,81;" +
    "2001-10-25,48,79;" +
    "2001-10-26,45,82;" +
    "2001-10-26,48,84;" +
    "2001-10-27,51,71;" +
    "2001-10-27,52,71;" +
    "2001-10-28,51,68;" +
    "2001-10-28,51,68;" +
    "2001-10-29,60,70;" +
    "2001-10-29,58,70;" +
    "2001-10-30,54,67;" +
    "2001-10-30,58,68;" +
    "2001-10-31,47,70;" +
    "2001-10-31,47,71;" +
    "2001-11-01,43,71;" +
    "2001-11-01,44,70;" +
    "2001-11-02,46,72;" +
    "2001-11-02,46,71;" +
    "2001-11-03,45,76;" +
    "2001-11-03,46,75;" +
    "2001-11-04,44,82;" +
    "2001-11-04,46,83;" +
    "2001-11-05,43,78;" +
    "2001-11-05,45,78;" +
    "2001-11-06,41,64;" +
    "2001-11-06,49,65;" +
    "2001-11-07,36,71;" +
    "2001-11-07,39,70;" +
    "2001-11-08,39,71;" +
    "2001-11-08,42,70;" +
    "2001-11-09,45,77;" +
    "2001-11-09,46,76;" +
    "2001-11-10,47,68;" +
    "2001-11-10,47,69;" +
    "2001-11-11,55,73;" +
    "2001-11-11,54,73;" +
    "2001-11-12,51,66;" +
    "2001-11-12,54,66;" +
    "2001-11-13,50,64;" +
    "2001-11-13,50,65;" +
    "2001-11-14,49,67;" +
    "2001-11-14,52,68;" +
    "2001-11-15,49,68;" +
    "2001-11-15,48,68;" +
    "2001-11-16,49,69;" +
    "2001-11-16,49,69;" +
    "2001-11-17,45,64;" +
    "2001-11-17,56,67;" +
    "2001-11-18,40,65;" +
    "2001-11-18,43,66;" +
    "2001-11-19,45,63;" +
    "2001-11-19,48,64;" +
    "2001-11-20,47,68;" +
    "2001-11-20,52,72;" +
    "2001-11-21,51,63;" +
    "2001-11-21,56,63;" +
    "2001-11-22,50,65;" +
    "2001-11-22,57,66;" +
    "2001-11-23,44,59;" +
    "2001-11-23,45,66;" +
    "2001-11-24,44,61;" +
    "2001-11-24,48,61;" +
    "2001-11-25,42,54;" +
    "2001-11-25,43,54;" +
    "2001-11-26,36,55;" +
    "2001-11-26,37,55;" +
    "2001-11-27,36,55;" +
    "2001-11-27,37,56;" +
    "2001-11-28,40,55;" +
    "2001-11-28,40,55;" +
    "2001-11-29,44,59;" +
    "2001-11-29,46,58;" +
    "2001-11-30,39,56;" +
    "2001-11-30,40,58;" +
    "2001-12-01,47,56;" +
    "2001-12-01,47,56;" +
    "2001-12-02,46,58;" +
    "2001-12-02,47,59;" +
    "2001-12-03,39,55;" +
    "2001-12-03,42,56;" +
    "2001-12-04,34,55;" +
    "2001-12-04,34,56;" +
    "2001-12-05,42,55;" +
    "2001-12-05,43,55;" +
    "2001-12-06,48,62;" +
    "2001-12-06,49,64;" +
    "2001-12-07,42,60;" +
    "2001-12-07,47,60;" +
    "2001-12-08,38,53;" +
    "2001-12-08,38,54;" +
    "2001-12-09,38,54;" +
    "2001-12-09,44,54;" +
    "2001-12-10,34,58;" +
    "2001-12-10,34,58;" +
    "2001-12-11,32,57;" +
    "2001-12-11,34,58;" +
    "2001-12-12,37,56;" +
    "2001-12-12,40,57;" +
    "2001-12-13,34,57;" +
    "2001-12-13,36,57;" +
    "2001-12-14,43,53;" +
    "2001-12-14,44,54;" +
    "2001-12-15,33,54;" +
    "2001-12-15,35,56;" +
    "2001-12-16,34,57;" +
    "2001-12-16,35,58;" +
    "2001-12-17,44,58;" +
    "2001-12-17,48,58;" +
    "2001-12-18,41,56;" +
    "2001-12-18,39,58;" +
    "2001-12-19,37,56;" +
    "2001-12-19,38,57;" +
    "2001-12-20,45,52;" +
    "2001-12-20,45,52;" +
    "2001-12-21,40,52;" +
    "2001-12-21,41,52;" +
    "2001-12-22,44,54;" +
    "2001-12-22,44,55;" +
    "2001-12-23,39,54;" +
    "2001-12-23,46,53;" +
    "2001-12-24,33,50;" +
    "2001-12-24,34,50;" +
    "2001-12-25,41,53;" +
    "2001-12-25,41,52;" +
    "2001-12-26,45,53;" +
    "2001-12-26,46,52;" +
    "2001-12-27,43,56;" +
    "2001-12-27,43,56;" +
    "2001-12-28,49,54;" +
    "2001-12-28,48,56;" +
    "2001-12-29,49,55;" +
    "2001-12-29,48,56;" +
    "2001-12-30,52,57;" +
    "2001-12-30,52,56;" +
    "2001-12-31,47,63;" +
    "2001-12-31,48,63;"