export const data = "2002-01-01,52,61;" +
    "2002-01-01,52,62;" +
    "2002-01-02,47,61;" +
    "2002-01-02,54,61;" +
    "2002-01-03,40,59;" +
    "2002-01-03,41,60;" +
    "2002-01-04,37,57;" +
    "2002-01-04,40,56;" +
    "2002-01-05,39,53;" +
    "2002-01-05,41,53;" +
    "2002-01-06,47,62;" +
    "2002-01-06,48,64;" +
    "2002-01-07,44,57;" +
    "2002-01-07,43,58;" +
    "2002-01-08,43,64;" +
    "2002-01-08,43,65;" +
    "2002-01-09,43,62;" +
    "2002-01-09,52,64;" +
    "2002-01-10,38,59;" +
    "2002-01-10,40,59;" +
    "2002-01-11,37,50;" +
    "2002-01-11,39,53;" +
    "2002-01-12,39,54;" +
    "2002-01-12,40,57;" +
    "2002-01-13,35,55;" +
    "2002-01-13,42,55;" +
    "2002-01-14,33,49;" +
    "2002-01-14,38,50;" +
    "2002-01-15,31,54;" +
    "2002-01-15,33,53;" +
    "2002-01-16,29,51;" +
    "2002-01-16,32,51;" +
    "2002-01-17,28,49;" +
    "2002-01-17,31,48;" +
    "2002-01-18,27,56;" +
    "2002-01-18,28,58;" +
    "2002-01-19,29,55;" +
    "2002-01-19,30,56;" +
    "2002-01-20,29,58;" +
    "2002-01-20,30,60;" +
    "2002-01-21,35,56;" +
    "2002-01-21,38,58;" +
    "2002-01-22,31,50;" +
    "2002-01-22,33,51;" +
    "2002-01-23,25,55;" +
    "2002-01-23,28,55;" +
    "2002-01-24,28,57;" +
    "2002-01-24,32,56;" +
    "2002-01-25,29,56;" +
    "2002-01-25,31,57;" +
    "2002-01-26,40,56;" +
    "2002-01-26,43,55;" +
    "2002-01-27,31,50;" +
    "2002-01-27,33,52;" +
    "2002-01-28,32,47;" +
    "2002-01-28,32,48;" +
    "2002-01-29,27,47;" +
    "2002-01-29,34,49;" +
    "2002-01-30,25,50;" +
    "2002-01-30,26,51;" +
    "2002-01-31,25,52;" +
    "2002-01-31,27,52;" +
    "2002-02-01,29,54;" +
    "2002-02-01,32,54;" +
    "2002-02-02,28,57;" +
    "2002-02-02,30,57;" +
    "2002-02-03,29,58;" +
    "2002-02-03,32,58;" +
    "2002-02-04,29,61;" +
    "2002-02-04,31,60;" +
    "2002-02-05,27,59;" +
    "2002-02-05,30,60;" +
    "2002-02-06,30,62;" +
    "2002-02-06,33,63;" +
    "2002-02-07,45,61;" +
    "2002-02-07,47,64;" +
    "2002-02-08,34,59;" +
    "2002-02-08,36,59;" +
    "2002-02-09,39,58;" +
    "2002-02-09,44,58;" +
    "2002-02-10,32,66;" +
    "2002-02-10,36,65;" +
    "2002-02-11,36,66;" +
    "2002-02-11,37,67;" +
    "2002-02-12,33,68;" +
    "2002-02-12,37,69;" +
    "2002-02-13,38,54;" +
    "2002-02-13,43,59;" +
    "2002-02-14,41,64;" +
    "2002-02-14,42,66;" +
    "2002-02-15,38,57;" +
    "2002-02-15,44,59;" +
    "2002-02-16,41,58;" +
    "2002-02-16,42,61;" +
    "2002-02-17,43,53;" +
    "2002-02-17,43,54;" +
    "2002-02-18,41,56;" +
    "2002-02-18,41,58;" +
    "2002-02-19,48,57;" +
    "2002-02-19,47,58;" +
    "2002-02-20,51,66;" +
    "2002-02-20,50,68;" +
    "2002-02-21,49,66;" +
    "2002-02-21,52,66;" +
    "2002-02-22,47,75;" +
    "2002-02-22,46,76;" +
    "2002-02-23,45,58;" +
    "2002-02-23,49,62;" +
    "2002-02-24,39,65;" +
    "2002-02-24,39,67;" +
    "2002-02-25,39,69;" +
    "2002-02-25,41,70;" +
    "2002-02-26,39,67;" +
    "2002-02-26,42,67;" +
    "2002-02-27,41,72;" +
    "2002-02-27,46,72;" +
    "2002-02-28,36,72;" +
    "2002-02-28,40,73;" +
    "2002-03-01,37,63;" +
    "2002-03-01,45,65;" +
    "2002-03-02,35,64;" +
    "2002-03-02,39,64;" +
    "2002-03-03,32,66;" +
    "2002-03-03,36,67;" +
    "2002-03-04,34,68;" +
    "2002-03-04,38,68;" +
    "2002-03-05,42,62;" +
    "2002-03-05,44,64;" +
    "2002-03-06,51,61;" +
    "2002-03-06,53,62;" +
    "2002-03-07,39,57;" +
    "2002-03-07,47,58;" +
    "2002-03-08,28,55;" +
    "2002-03-08,31,55;" +
    "2002-03-09,32,59;" +
    "2002-03-09,35,62;" +
    "2002-03-10,48,60;" +
    "2002-03-10,48,64;" +
    "2002-03-11,44,65;" +
    "2002-03-11,45,68;" +
    "2002-03-12,42,60;" +
    "2002-03-12,47,62;" +
    "2002-03-13,37,56;" +
    "2002-03-13,40,58;" +
    "2002-03-14,31,58;" +
    "2002-03-14,34,61;" +
    "2002-03-15,34,55;" +
    "2002-03-15,38,55;" +
    "2002-03-16,38,55;" +
    "2002-03-16,39,56;" +
    "2002-03-17,36,49;" +
    "2002-03-17,38,51;" +
    "2002-03-18,32,59;" +
    "2002-03-18,36,60;" +
    "2002-03-19,31,64;" +
    "2002-03-19,34,66;" +
    "2002-03-20,38,66;" +
    "2002-03-20,41,67;" +
    "2002-03-21,42,70;" +
    "2002-03-21,44,72;" +
    "2002-03-22,47,59;" +
    "2002-03-22,48,59;" +
    "2002-03-23,47,59;" +
    "2002-03-23,46,60;" +
    "2002-03-24,44,59;" +
    "2002-03-24,46,62;" +
    "2002-03-25,42,59;" +
    "2002-03-25,42,60;" +
    "2002-03-26,39,67;" +
    "2002-03-26,41,69;" +
    "2002-03-27,38,73;" +
    "2002-03-27,41,75;" +
    "2002-03-28,41,78;" +
    "2002-03-28,44,81;" +
    "2002-03-29,42,79;" +
    "2002-03-29,46,82;" +
    "2002-03-30,40,80;" +
    "2002-03-30,46,83;" +
    "2002-03-31,41,81;" +
    "2002-03-31,45,84;" +
    "2002-04-01,43,81;" +
    "2002-04-01,46,84;" +
    "2002-04-02,48,68;" +
    "2002-04-02,50,72;" +
    "2002-04-03,51,60;" +
    "2002-04-03,50,63;" +
    "2002-04-04,51,57;" +
    "2002-04-04,52,58;" +
    "2002-04-05,47,66;" +
    "2002-04-05,48,65;" +
    "2002-04-06,48,65;" +
    "2002-04-06,49,67;" +
    "2002-04-07,50,72;" +
    "2002-04-07,50,74;" +
    "2002-04-08,49,67;" +
    "2002-04-08,50,70;" +
    "2002-04-09,51,63;" +
    "2002-04-09,50,64;" +
    "2002-04-10,49,71;" +
    "2002-04-10,54,73;" +
    "2002-04-11,48,70;" +
    "2002-04-11,49,73;" +
    "2002-04-12,50,78;" +
    "2002-04-12,50,80;" +
    "2002-04-13,46,85;" +
    "2002-04-13,49,86;" +
    "2002-04-14,47,76;" +
    "2002-04-14,56,84;" +
    "2002-04-15,44,62;" +
    "2002-04-15,45,64;" +
    "2002-04-16,42,59;" +
    "2002-04-16,44,59;" +
    "2002-04-17,41,58;" +
    "2002-04-17,43,59;" +
    "2002-04-18,36,63;" +
    "2002-04-18,40,64;" +
    "2002-04-19,36,71;" +
    "2002-04-19,40,71;" +
    "2002-04-20,39,68;" +
    "2002-04-20,42,69;" +
    "2002-04-21,37,75;" +
    "2002-04-21,41,76;" +
    "2002-04-22,42,82;" +
    "2002-04-22,46,83;" +
    "2002-04-23,43,84;" +
    "2002-04-23,47,85;" +
    "2002-04-24,48,72;" +
    "2002-04-24,50,83;" +
    "2002-04-25,49,69;" +
    "2002-04-25,51,71;" +
    "2002-04-26,50,56;" +
    "2002-04-26,50,60;" +
    "2002-04-27,42,61;" +
    "2002-04-27,49,62;" +
    "2002-04-28,41,63;" +
    "2002-04-28,43,64;" +
    "2002-04-29,43,62;" +
    "2002-04-29,46,64;" +
    "2002-04-30,38,59;" +
    "2002-04-30,40,59;" +
    "2002-05-01,47,66;" +
    "2002-05-01,48,66;" +
    "2002-05-02,46,64;" +
    "2002-05-02,48,65;" +
    "2002-05-03,46,65;" +
    "2002-05-03,48,67;" +
    "2002-05-04,40,78;" +
    "2002-05-04,43,80;" +
    "2002-05-05,44,83;" +
    "2002-05-05,48,84;" +
    "2002-05-06,46,70;" +
    "2002-05-06,47,77;" +
    "2002-05-07,43,71;" +
    "2002-05-07,47,72;" +
    "2002-05-08,39,79;" +
    "2002-05-08,43,80;" +
    "2002-05-09,44,70;" +
    "2002-05-09,44,72;" +
    "2002-05-10,46,66;" +
    "2002-05-10,47,66;" +
    "2002-05-11,38,80;" +
    "2002-05-11,42,81;" +
    "2002-05-12,42,84;" +
    "2002-05-12,46,85;" +
    "2002-05-13,47,72;" +
    "2002-05-13,47,80;" +
    "2002-05-14,46,80;" +
    "2002-05-14,47,81;" +
    "2002-05-15,48,79;" +
    "2002-05-15,48,80;" +
    "2002-05-16,48,80;" +
    "2002-05-16,49,82;" +
    "2002-05-17,49,76;" +
    "2002-05-17,48,77;" +
    "2002-05-18,46,68;" +
    "2002-05-18,48,69;" +
    "2002-05-19,45,62;" +
    "2002-05-19,47,63;" +
    "2002-05-20,50,60;" +
    "2002-05-20,49,61;" +
    "2002-05-21,47,64;" +
    "2002-05-21,49,66;" +
    "2002-05-22,41,70;" +
    "2002-05-22,44,72;" +
    "2002-05-23,41,79;" +
    "2002-05-23,44,81;" +
    "2002-05-24,47,87;" +
    "2002-05-24,50,88;" +
    "2002-05-25,48,83;" +
    "2002-05-25,52,85;" +
    "2002-05-26,49,74;" +
    "2002-05-26,50,76;" +
    "2002-05-27,51,76;" +
    "2002-05-27,51,77;" +
    "2002-05-28,54,78;" +
    "2002-05-28,55,80;" +
    "2002-05-29,57,91;" +
    "2002-05-29,58,92;" +
    "2002-05-30,59,94;" +
    "2002-05-30,64,95;" +
    "2002-05-31,52,88;" +
    "2002-05-31,56,90;" +
    "2002-06-01,53,71;" +
    "2002-06-01,54,72;" +
    "2002-06-02,50,72;" +
    "2002-06-02,51,74;" +
    "2002-06-03,49,81;" +
    "2002-06-03,50,83;" +
    "2002-06-04,50,93;" +
    "2002-06-04,52,94;" +
    "2002-06-05,57,101;" +
    "2002-06-05,61,101;" +
    "2002-06-06,56,93;" +
    "2002-06-06,61,95;" +
    "2002-06-07,52,82;" +
    "2002-06-07,52,84;" +
    "2002-06-08,49,83;" +
    "2002-06-08,52,84;" +
    "2002-06-09,48,82;" +
    "2002-06-09,51,82;" +
    "2002-06-10,57,88;" +
    "2002-06-10,60,88;" +
    "2002-06-11,52,92;" +
    "2002-06-11,57,93;" +
    "2002-06-12,54,79;" +
    "2002-06-12,54,81;" +
    "2002-06-13,52,71;" +
    "2002-06-13,53,75;" +
    "2002-06-14,50,71;" +
    "2002-06-14,52,74;" +
    "2002-06-15,47,79;" +
    "2002-06-15,47,81;" +
    "2002-06-16,49,80;" +
    "2002-06-16,48,82;" +
    "2002-06-17,52,82;" +
    "2002-06-17,51,84;" +
    "2002-06-18,58,80;" +
    "2002-06-18,59,80;" +
    "2002-06-19,54,89;" +
    "2002-06-19,56,89;" +
    "2002-06-20,52,72;" +
    "2002-06-20,52,82;" +
    "2002-06-21,53,69;" +
    "2002-06-21,53,71;" +
    "2002-06-22,53,74;" +
    "2002-06-22,54,77;" +
    "2002-06-23,51,80;" +
    "2002-06-23,50,78;" +
    "2002-06-24,52,89;" +
    "2002-06-24,53,90;" +
    "2002-06-25,56,93;" +
    "2002-06-25,57,95;" +
    "2002-06-26,54,84;" +
    "2002-06-26,55,86;" +
    "2002-06-27,54,83;" +
    "2002-06-27,55,82;" +
    "2002-06-28,54,78;" +
    "2002-06-28,54,78;" +
    "2002-06-29,55,87;" +
    "2002-06-29,56,88;" +
    "2002-06-30,57,96;" +
    "2002-06-30,59,97;" +
    "2002-07-01,56,99;" +
    "2002-07-01,59,100;" +
    "2002-07-02,55,91;" +
    "2002-07-02,63,94;" +
    "2002-07-03,54,84;" +
    "2002-07-03,55,86;" +
    "2002-07-04,53,83;" +
    "2002-07-04,54,86;" +
    "2002-07-05,53,81;" +
    "2002-07-05,52,83;" +
    "2002-07-06,53,87;" +
    "2002-07-06,54,87;" +
    "2002-07-07,54,81;" +
    "2002-07-07,56,83;" +
    "2002-07-08,53,95;" +
    "2002-07-08,53,97;" +
    "2002-07-09,58,107;" +
    "2002-07-09,59,106;" +
    "2002-07-10,58,109;" +
    "2002-07-10,62,109;" +
    "2002-07-11,66,98;" +
    "2002-07-11,66,103;" +
    "2002-07-12,65,102;" +
    "2002-07-12,66,103;" +
    "2002-07-13,57,90;" +
    "2002-07-13,64,96;" +
    "2002-07-14,55,87;" +
    "2002-07-14,55,88;" +
    "2002-07-15,54,75;" +
    "2002-07-15,54,79;" +
    "2002-07-16,53,87;" +
    "2002-07-16,55,88;" +
    "2002-07-17,54,83;" +
    "2002-07-17,55,85;" +
    "2002-07-18,55,80;" +
    "2002-07-18,55,83;" +
    "2002-07-19,53,92;" +
    "2002-07-19,55,94;" +
    "2002-07-20,55,93;" +
    "2002-07-20,58,95;" +
    "2002-07-21,57,79;" +
    "2002-07-21,58,82;" +
    "2002-07-22,54,78;" +
    "2002-07-22,56,80;" +
    "2002-07-23,54,81;" +
    "2002-07-23,55,82;" +
    "2002-07-24,54,85;" +
    "2002-07-24,55,87;" +
    "2002-07-25,52,84;" +
    "2002-07-25,54,86;" +
    "2002-07-26,55,93;" +
    "2002-07-26,56,94;" +
    "2002-07-27,57,89;" +
    "2002-07-27,58,90;" +
    "2002-07-28,58,83;" +
    "2002-07-28,59,84;" +
    "2002-07-29,57,81;" +
    "2002-07-29,58,83;" +
    "2002-07-30,57,92;" +
    "2002-07-30,57,93;" +
    "2002-07-31,57,88;" +
    "2002-07-31,58,91;" +
    "2002-08-01,59,85;" +
    "2002-08-01,59,88;" +
    "2002-08-02,59,80;" +
    "2002-08-02,60,83;" +
    "2002-08-03,56,77;" +
    "2002-08-03,59,83;" +
    "2002-08-04,53,78;" +
    "2002-08-04,55,80;" +
    "2002-08-05,54,82;" +
    "2002-08-05,54,84;" +
    "2002-08-06,51,82;" +
    "2002-08-06,52,83;" +
    "2002-08-07,48,92;" +
    "2002-08-07,50,92;" +
    "2002-08-08,53,98;" +
    "2002-08-08,55,99;" +
    "2002-08-09,57,102;" +
    "2002-08-09,59,103;" +
    "2002-08-10,57,101;" +
    "2002-08-10,60,102;" +
    "2002-08-11,54,98;" +
    "2002-08-11,58,99;" +
    "2002-08-12,56,97;" +
    "2002-08-12,57,100;" +
    "2002-08-13,58,95;" +
    "2002-08-13,59,97;" +
    "2002-08-14,56,93;" +
    "2002-08-14,58,94;" +
    "2002-08-15,57,91;" +
    "2002-08-15,58,93;" +
    "2002-08-16,57,84;" +
    "2002-08-16,58,87;" +
    "2002-08-17,55,80;" +
    "2002-08-17,57,83;" +
    "2002-08-18,55,85;" +
    "2002-08-18,55,87;" +
    "2002-08-19,53,68;" +
    "2002-08-19,55,76;" +
    "2002-08-20,52,84;" +
    "2002-08-20,53,85;" +
    "2002-08-21,52,83;" +
    "2002-08-21,52,84;" +
    "2002-08-22,54,73;" +
    "2002-08-22,54,75;" +
    "2002-08-23,54,68;" +
    "2002-08-23,54,72;" +
    "2002-08-24,53,79;" +
    "2002-08-24,53,82;" +
    "2002-08-25,50,85;" +
    "2002-08-25,51,87;" +
    "2002-08-26,51,94;" +
    "2002-08-26,53,95;" +
    "2002-08-27,57,99;" +
    "2002-08-27,58,98;" +
    "2002-08-28,58,92;" +
    "2002-08-28,61,93;" +
    "2002-08-29,57,82;" +
    "2002-08-29,56,84;" +
    "2002-08-30,57,88;" +
    "2002-08-30,57,90;" +
    "2002-08-31,57,96;" +
    "2002-08-31,57,95;" +
    "2002-09-01,56,101;" +
    "2002-09-01,60,100;" +
    "2002-09-02,57,101;" +
    "2002-09-02,61,102;" +
    "2002-09-03,58,95;" +
    "2002-09-03,61,95;" +
    "2002-09-04,56,83;" +
    "2002-09-04,58,84;" +
    "2002-09-05,56,78;" +
    "2002-09-05,56,78;" +
    "2002-09-06,51,72;" +
    "2002-09-06,55,74;" +
    "2002-09-07,50,77;" +
    "2002-09-07,51,78;" +
    "2002-09-08,46,85;" +
    "2002-09-08,47,86;" +
    "2002-09-09,50,93;" +
    "2002-09-09,52,93;" +
    "2002-09-10,53,97;" +
    "2002-09-10,55,99;" +
    "2002-09-11,57,95;" +
    "2002-09-11,57,96;" +
    "2002-09-12,55,87;" +
    "2002-09-12,56,89;" +
    "2002-09-13,53,86;" +
    "2002-09-13,54,88;" +
    "2002-09-14,53,89;" +
    "2002-09-14,54,91;" +
    "2002-09-15,55,75;" +
    "2002-09-15,56,78;" +
    "2002-09-16,56,81;" +
    "2002-09-16,56,83;" +
    "2002-09-17,55,83;" +
    "2002-09-17,55,84;" +
    "2002-09-18,53,90;" +
    "2002-09-18,54,90;" +
    "2002-09-19,55,94;" +
    "2002-09-19,58,94;" +
    "2002-09-20,56,97;" +
    "2002-09-20,58,99;" +
    "2002-09-21,55,96;" +
    "2002-09-21,57,98;" +
    "2002-09-22,53,99;" +
    "2002-09-22,57,100;" +
    "2002-09-23,57,100;" +
    "2002-09-23,59,101;" +
    "2002-09-24,51,97;" +
    "2002-09-24,56,98;" +
    "2002-09-25,56,94;" +
    "2002-09-25,58,95;" +
    "2002-09-26,55,77;" +
    "2002-09-26,55,86;" +
    "2002-09-27,58,69;" +
    "2002-09-27,57,69;" +
    "2002-09-28,55,70;" +
    "2002-09-28,55,71;" +
    "2002-09-29,48,74;" +
    "2002-09-29,50,76;" +
    "2002-09-30,53,74;" +
    "2002-09-30,53,76;" +
    "2002-10-01,47,75;" +
    "2002-10-01,49,76;" +
    "2002-10-02,61,73;" +
    "2002-10-02,58,76;" +
    "2002-10-03,47,82;" +
    "2002-10-03,44,82;" +
    "2002-10-04,46,83;" +
    "2002-10-04,50,82;" +
    "2002-10-05,50,87;" +
    "2002-10-05,52,86;" +
    "2002-10-06,52,91;" +
    "2002-10-06,55,90;" +
    "2002-10-07,53,93;" +
    "2002-10-07,56,93;" +
    "2002-10-08,52,93;" +
    "2002-10-08,55,93;" +
    "2002-10-09,52,93;" +
    "2002-10-09,54,88;" +
    "2002-10-10,56,69;" +
    "2002-10-10,56,73;" +
    "2002-10-11,49,79;" +
    "2002-10-11,53,79;" +
    "2002-10-12,44,82;" +
    "2002-10-12,47,82;" +
    "2002-10-13,45,85;" +
    "2002-10-13,48,84;" +
    "2002-10-14,50,86;" +
    "2002-10-14,52,86;" +
    "2002-10-15,49,68;" +
    "2002-10-15,50,74;" +
    "2002-10-16,49,66;" +
    "2002-10-16,50,67;" +
    "2002-10-17,50,68;" +
    "2002-10-17,51,69;" +
    "2002-10-18,49,69;" +
    "2002-10-18,53,69;" +
    "2002-10-19,46,75;" +
    "2002-10-19,49,76;" +
    "2002-10-20,49,78;" +
    "2002-10-20,52,80;" +
    "2002-10-21,45,78;" +
    "2002-10-21,47,79;" +
    "2002-10-22,52,64;" +
    "2002-10-22,52,67;" +
    "2002-10-23,52,57;" +
    "2002-10-23,53,57;" +
    "2002-10-24,49,63;" +
    "2002-10-24,51,61;" +
    "2002-10-25,46,68;" +
    "2002-10-25,50,67;" +
    "2002-10-26,44,69;" +
    "2002-10-26,46,70;" +
    "2002-10-27,40,73;" +
    "2002-10-27,43,75;" +
    "2002-10-28,40,79;" +
    "2002-10-28,44,79;" +
    "2002-10-29,39,75;" +
    "2002-10-29,42,77;" +
    "2002-10-30,40,71;" +
    "2002-10-30,44,72;" +
    "2002-10-31,34,70;" +
    "2002-10-31,37,71;" +
    "2002-11-01,36,69;" +
    "2002-11-01,40,67;" +
    "2002-11-02,34,70;" +
    "2002-11-02,38,68;" +
    "2002-11-03,39,73;" +
    "2002-11-03,43,74;" +
    "2002-11-04,38,69;" +
    "2002-11-04,40,71;" +
    "2002-11-05,37,71;" +
    "2002-11-05,39,72;" +
    "2002-11-06,39,71;" +
    "2002-11-06,42,72;" +
    "2002-11-07,56,71;" +
    "2002-11-07,56,71;" +
    "2002-11-08,55,64;" +
    "2002-11-08,56,71;" +
    "2002-11-09,47,65;" +
    "2002-11-09,49,66;" +
    "2002-11-10,50,63;" +
    "2002-11-10,52,61;" +
    "2002-11-11,44,65;" +
    "2002-11-11,44,64;" +
    "2002-11-12,46,71;" +
    "2002-11-12,47,71;" +
    "2002-11-13,48,67;" +
    "2002-11-13,55,68;" +
    "2002-11-14,42,71;" +
    "2002-11-14,45,70;" +
    "2002-11-15,43,62;" +
    "2002-11-15,46,61;" +
    "2002-11-16,41,70;" +
    "2002-11-16,41,71;" +
    "2002-11-17,43,66;" +
    "2002-11-17,47,65;" +
    "2002-11-18,38,67;" +
    "2002-11-18,40,66;" +
    "2002-11-19,39,68;" +
    "2002-11-19,41,68;" +
    "2002-11-20,42,68;" +
    "2002-11-20,44,67;" +
    "2002-11-21,44,68;" +
    "2002-11-21,46,68;" +
    "2002-11-22,44,58;" +
    "2002-11-22,46,60;" +
    "2002-11-23,48,58;" +
    "2002-11-23,51,59;" +
    "2002-11-24,46,58;" +
    "2002-11-24,48,59;" +
    "2002-11-25,46,67;" +
    "2002-11-25,46,66;" +
    "2002-11-26,42,72;" +
    "2002-11-26,48,71;" +
    "2002-11-27,36,68;" +
    "2002-11-27,39,67;" +
    "2002-11-28,32,64;" +
    "2002-11-28,35,63;" +
    "2002-11-29,35,68;" +
    "2002-11-29,39,69;" +
    "2002-11-30,36,56;" +
    "2002-11-30,38,56;" +
    "2002-12-01,36,61;" +
    "2002-12-01,39,61;" +
    "2002-12-02,37,63;" +
    "2002-12-02,38,64;" +
    "2002-12-03,35,63;" +
    "2002-12-03,38,63;" +
    "2002-12-04,35,63;" +
    "2002-12-04,38,62;" +
    "2002-12-05,38,64;" +
    "2002-12-05,42,63;" +
    "2002-12-06,39,55;" +
    "2002-12-06,41,56;" +
    "2002-12-07,36,63;" +
    "2002-12-07,39,66;" +
    "2002-12-08,36,59;" +
    "2002-12-08,39,60;" +
    "2002-12-09,41,54;" +
    "2002-12-09,43,54;" +
    "2002-12-10,41,61;" +
    "2002-12-10,43,62;" +
    "2002-12-11,39,60;" +
    "2002-12-11,41,62;" +
    "2002-12-12,38,54;" +
    "2002-12-12,41,54;" +
    "2002-12-13,50,61;" +
    "2002-12-13,48,60;" +
    "2002-12-14,50,63;" +
    "2002-12-14,51,64;" +
    "2002-12-15,51,59;" +
    "2002-12-15,50,58;" +
    "2002-12-16,48,60;" +
    "2002-12-16,52,59;" +
    "2002-12-17,42,55;" +
    "2002-12-17,44,54;" +
    "2002-12-18,34,54;" +
    "2002-12-18,36,56;" +
    "2002-12-19,35,54;" +
    "2002-12-19,37,53;" +
    "2002-12-20,37,54;" +
    "2002-12-20,39,55;" +
    "2002-12-21,41,57;" +
    "2002-12-21,43,58;" +
    "2002-12-22,32,56;" +
    "2002-12-22,35,58;" +
    "2002-12-23,33,53;" +
    "2002-12-23,39,55;" +
    "2002-12-24,34,52;" +
    "2002-12-24,34,52;" +
    "2002-12-25,35,46;" +
    "2002-12-25,37,46;" +
    "2002-12-26,43,60;" +
    "2002-12-26,42,60;" +
    "2002-12-27,51,62;" +
    "2002-12-27,51,62;" +
    "2002-12-28,46,64;" +
    "2002-12-28,46,66;" +
    "2002-12-29,38,55;" +
    "2002-12-29,45,56;" +
    "2002-12-30,41,53;" +
    "2002-12-30,41,52;" +
    "2002-12-31,38,57;" +
    "2002-12-31,44,57;"