export const data = "1997-01-01,62,68;" +
    "1997-01-01,61,64;" +
    "1997-01-02,55,63;" +
    "1997-01-02,53,63;" +
    "1997-01-03,47,59;" +
    "1997-01-03,43,56;" +
    "1997-01-04,39,50;" +
    "1997-01-04,40,50;" +
    "1997-01-05,37,54;" +
    "1997-01-05,40,52;" +
    "1997-01-06,40,58;" +
    "1997-01-06,41,59;" +
    "1997-01-07,34,58;" +
    "1997-01-07,42,60;" +
    "1997-01-08,38,61;" +
    "1997-01-08,43,61;" +
    "1997-01-09,35,63;" +
    "1997-01-09,39,63;" +
    "1997-01-10,34,60;" +
    "1997-01-10,36,59;" +
    "1997-01-11,39,51;" +
    "1997-01-11,43,50;" +
    "1997-01-12,42,47;" +
    "1997-01-12,38,45;" +
    "1997-01-13,30,42;" +
    "1997-01-13,30,40;" +
    "1997-01-14,26,40;" +
    "1997-01-14,27,40;" +
    "1997-01-15,37,42;" +
    "1997-01-15,38,43;" +
    "1997-01-16,39,46;" +
    "1997-01-16,39,46;" +
    "1997-01-17,40,45;" +
    "1997-01-17,40,46;" +
    "1997-01-18,42,46;" +
    "1997-01-18,41,43;" +
    "1997-01-19,40,46;" +
    "1997-01-19,40,46;" +
    "1997-01-20,42,58;" +
    "1997-01-20,41,55;" +
    "1997-01-21,44,58;" +
    "1997-01-21,45,56;" +
    "1997-01-22,48,59;" +
    "1997-01-22,43,58;" +
    "1997-01-23,43,55;" +
    "1997-01-23,40,52;" +
    "1997-01-24,40,52;" +
    "1997-01-24,42,52;" +
    "1997-01-25,47,63;" +
    "1997-01-25,53,60;" +
    "1997-01-26,54,61;" +
    "1997-01-26,51,60;" +
    "1997-01-27,50,59;" +
    "1997-01-27,49,62;" +
    "1997-01-28,50,65;" +
    "1997-01-28,50,65;" +
    "1997-01-29,46,63;" +
    "1997-01-29,48,63;" +
    "1997-01-30,40,59;" +
    "1997-01-30,44,58;" +
    "1997-01-31,44,54;" +
    "1997-01-31,44,53;" +
    "1997-02-01,52,62;" +
    "1997-02-01,48,61;" +
    "1997-02-02,45,62;" +
    "1997-02-02,44,61;" +
    "1997-02-03,40,58;" +
    "1997-02-03,41,56;" +
    "1997-02-04,47,61;" +
    "1997-02-04,45,57;" +
    "1997-02-05,37,62;" +
    "1997-02-05,40,62;" +
    "1997-02-06,36,60;" +
    "1997-02-06,39,63;" +
    "1997-02-07,38,62;" +
    "1997-02-07,42,61;" +
    "1997-02-08,41,61;" +
    "1997-02-08,41,61;" +
    "1997-02-09,36,56;" +
    "1997-02-09,38,57;" +
    "1997-02-10,44,61;" +
    "1997-02-10,44,60;" +
    "1997-02-11,41,59;" +
    "1997-02-11,43,57;" +
    "1997-02-12,47,62;" +
    "1997-02-12,47,59;" +
    "1997-02-13,45,64;" +
    "1997-02-13,50,63;" +
    "1997-02-14,46,66;" +
    "1997-02-14,52,68;" +
    "1997-02-15,47,66;" +
    "1997-02-15,52,65;" +
    "1997-02-16,43,73;" +
    "1997-02-16,45,70;" +
    "1997-02-17,48,60;" +
    "1997-02-17,47,58;" +
    "1997-02-18,40,67;" +
    "1997-02-18,45,65;" +
    "1997-02-19,39,64;" +
    "1997-02-19,43,61;" +
    "1997-02-20,38,62;" +
    "1997-02-20,42,63;" +
    "1997-02-21,38,65;" +
    "1997-02-21,41,64;" +
    "1997-02-22,37,66;" +
    "1997-02-22,40,65;" +
    "1997-02-23,41,62;" +
    "1997-02-23,48,60;" +
    "1997-02-24,45,62;" +
    "1997-02-24,49,60;" +
    "1997-02-25,34,71;" +
    "1997-02-25,43,68;" +
    "1997-02-26,42,66;" +
    "1997-02-26,45,65;" +
    "1997-02-27,47,60;" +
    "1997-02-27,44,57;" +
    "1997-02-28,36,61;" +
    "1997-02-28,40,58;" +
    "1997-03-01,34,61;" +
    "1997-03-01,38,57;" +
    "1997-03-02,47,60;" +
    "1997-03-02,43,56;" +
    "1997-03-03,41,58;" +
    "1997-03-03,39,54;" +
    "1997-03-04,32,59;" +
    "1997-03-04,35,60;" +
    "1997-03-05,32,67;" +
    "1997-03-05,37,66;" +
    "1997-03-06,37,66;" +
    "1997-03-06,40,64;" +
    "1997-03-07,38,68;" +
    "1997-03-07,40,65;" +
    "1997-03-08,39,70;" +
    "1997-03-08,40,66;" +
    "1997-03-09,39,78;" +
    "1997-03-09,43,77;" +
    "1997-03-10,42,79;" +
    "1997-03-10,48,78;" +
    "1997-03-11,43,74;" +
    "1997-03-11,46,71;" +
    "1997-03-12,37,66;" +
    "1997-03-12,41,63;" +
    "1997-03-13,36,67;" +
    "1997-03-13,39,71;" +
    "1997-03-14,40,74;" +
    "1997-03-14,45,75;" +
    "1997-03-15,46,68;" +
    "1997-03-15,47,66;" +
    "1997-03-16,49,62;" +
    "1997-03-16,49,59;" +
    "1997-03-17,46,70;" +
    "1997-03-17,50,70;" +
    "1997-03-18,44,73;" +
    "1997-03-18,48,75;" +
    "1997-03-19,46,76;" +
    "1997-03-19,50,77;" +
    "1997-03-20,51,75;" +
    "1997-03-20,53,76;" +
    "1997-03-21,59,74;" +
    "1997-03-21,57,76;" +
    "1997-03-22,48,75;" +
    "1997-03-22,50,71;" +
    "1997-03-23,50,75;" +
    "1997-03-23,50,71;" +
    "1997-03-24,47,78;" +
    "1997-03-24,50,78;" +
    "1997-03-25,49,82;" +
    "1997-03-25,55,81;" +
    "1997-03-26,48,78;" +
    "1997-03-26,49,74;" +
    "1997-03-27,44,71;" +
    "1997-03-27,45,68;" +
    "1997-03-28,47,72;" +
    "1997-03-28,47,70;" +
    "1997-03-29,47,74;" +
    "1997-03-29,50,73;" +
    "1997-03-30,43,69;" +
    "1997-03-30,44,62;" +
    "1997-03-31,41,63;" +
    "1997-03-31,40,59;" +
    "1997-04-01,39,62;" +
    "1997-04-01,48,58;" +
    "1997-04-02,48,67;" +
    "1997-04-02,49,65;" +
    "1997-04-03,49,75;" +
    "1997-04-03,54,74;" +
    "1997-04-04,42,74;" +
    "1997-04-04,43,72;" +
    "1997-04-05,37,70;" +
    "1997-04-05,40,69;" +
    "1997-04-06,41,63;" +
    "1997-04-06,42,60;" +
    "1997-04-07,42,70;" +
    "1997-04-07,43,68;" +
    "1997-04-08,43,69;" +
    "1997-04-08,43,65;" +
    "1997-04-09,40,66;" +
    "1997-04-09,40,62;" +
    "1997-04-10,40,70;" +
    "1997-04-10,42,66;" +
    "1997-04-11,39,73;" +
    "1997-04-11,41,69;" +
    "1997-04-12,42,71;" +
    "1997-04-12,43,68;" +
    "1997-04-13,44,68;" +
    "1997-04-13,44,64;" +
    "1997-04-14,43,78;" +
    "1997-04-14,44,77;" +
    "1997-04-15,49,84;" +
    "1997-04-15,53,81;" +
    "1997-04-16,49,79;" +
    "1997-04-16,50,75;" +
    "1997-04-17,52,82;" +
    "1997-04-17,53,77;" +
    "1997-04-18,54,75;" +
    "1997-04-18,53,70;" +
    "1997-04-19,55,72;" +
    "1997-04-19,52,68;" +
    "1997-04-20,58,75;" +
    "1997-04-20,57,72;" +
    "1997-04-21,58,75;" +
    "1997-04-21,55,72;" +
    "1997-04-22,55,72;" +
    "1997-04-22,54,69;" +
    "1997-04-23,48,69;" +
    "1997-04-23,47,65;" +
    "1997-04-24,42,72;" +
    "1997-04-24,44,68;" +
    "1997-04-25,46,84;" +
    "1997-04-25,50,81;" +
    "1997-04-26,51,87;" +
    "1997-04-26,54,83;" +
    "1997-04-27,52,73;" +
    "1997-04-27,47,65;" +
    "1997-04-28,46,65;" +
    "1997-04-28,45,63;" +
    "1997-04-29,51,71;" +
    "1997-04-29,49,67;" +
    "1997-04-30,49,73;" +
    "1997-04-30,47,68;" +
    "1997-05-01,43,74;" +
    "1997-05-01,43,70;" +
    "1997-05-02,45,72;" +
    "1997-05-02,46,69;" +
    "1997-05-03,55,78;" +
    "1997-05-03,50,75;" +
    "1997-05-04,46,78;" +
    "1997-05-04,46,74;" +
    "1997-05-05,48,79;" +
    "1997-05-05,49,74;" +
    "1997-05-06,54,84;" +
    "1997-05-06,54,80;" +
    "1997-05-07,48,84;" +
    "1997-05-07,50,81;" +
    "1997-05-08,47,79;" +
    "1997-05-08,48,73;" +
    "1997-05-09,47,87;" +
    "1997-05-09,48,83;" +
    "1997-05-10,51,81;" +
    "1997-05-10,49,77;" +
    "1997-05-11,53,83;" +
    "1997-05-11,51,78;" +
    "1997-05-12,51,88;" +
    "1997-05-12,50,86;" +
    "1997-05-13,52,85;" +
    "1997-05-13,55,81;" +
    "1997-05-14,60,84;" +
    "1997-05-14,59,81;" +
    "1997-05-15,56,88;" +
    "1997-05-15,58,85;" +
    "1997-05-16,59,94;" +
    "1997-05-16,59,92;" +
    "1997-05-17,64,99;" +
    "1997-05-17,68,96;" +
    "1997-05-18,63,96;" +
    "1997-05-18,63,92;" +
    "1997-05-19,57,90;" +
    "1997-05-19,56,82;" +
    "1997-05-20,55,78;" +
    "1997-05-20,53,71;" +
    "1997-05-21,50,82;" +
    "1997-05-21,51,80;" +
    "1997-05-22,53,79;" +
    "1997-05-22,52,75;" +
    "1997-05-23,57,74;" +
    "1997-05-23,56,72;" +
    "1997-05-24,56,73;" +
    "1997-05-24,51,70;" +
    "1997-05-25,49,77;" +
    "1997-05-25,49,74;" +
    "1997-05-26,51,79;" +
    "1997-05-26,50,76;" +
    "1997-05-27,59,87;" +
    "1997-05-27,59,83;" +
    "1997-05-28,60,83;" +
    "1997-05-28,59,79;" +
    "1997-05-29,60,90;" +
    "1997-05-29,60,86;" +
    "1997-05-30,59,86;" +
    "1997-05-30,58,82;" +
    "1997-05-31,62,90;" +
    "1997-05-31,61,86;" +
    "1997-06-01,61,82;" +
    "1997-06-01,54,74;" +
    "1997-06-02,52,83;" +
    "1997-06-02,52,81;" +
    "1997-06-03,58,72;" +
    "1997-06-03,57,66;" +
    "1997-06-04,58,74;" +
    "1997-06-04,55,72;" +
    "1997-06-05,54,77;" +
    "1997-06-05,52,74;" +
    "1997-06-06,52,88;" +
    "1997-06-06,53,86;" +
    "1997-06-07,54,85;" +
    "1997-06-07,54,81;" +
    "1997-06-08,54,82;" +
    "1997-06-08,53,78;" +
    "1997-06-09,54,83;" +
    "1997-06-09,54,79;" +
    "1997-06-10,54,78;" +
    "1997-06-10,52,74;" +
    "1997-06-11,54,78;" +
    "1997-06-11,51,75;" +
    "1997-06-12,55,71;" +
    "1997-06-12,54,69;" +
    "1997-06-13,53,83;" +
    "1997-06-13,52,82;" +
    "1997-06-14,59,84;" +
    "1997-06-14,60,82;" +
    "1997-06-15,57,86;" +
    "1997-06-15,56,82;" +
    "1997-06-16,56,95;" +
    "1997-06-16,55,91;" +
    "1997-06-17,54,88;" +
    "1997-06-17,53,82;" +
    "1997-06-18,56,90;" +
    "1997-06-18,55,86;" +
    "1997-06-19,57,91;" +
    "1997-06-19,56,88;" +
    "1997-06-20,54,82;" +
    "1997-06-20,54,77;" +
    "1997-06-21,53,79;" +
    "1997-06-21,51,78;" +
    "1997-06-22,52,78;" +
    "1997-06-22,49,75;" +
    "1997-06-23,50,87;" +
    "1997-06-23,51,85;" +
    "1997-06-24,54,92;" +
    "1997-06-24,58,90;" +
    "1997-06-25,56,97;" +
    "1997-06-25,59,94;" +
    "1997-06-26,54,88;" +
    "1997-06-26,52,78;" +
    "1997-06-27,52,74;" +
    "1997-06-27,51,71;" +
    "1997-06-28,55,72;" +
    "1997-06-28,53,68;" +
    "1997-06-29,53,74;" +
    "1997-06-29,50,71;" +
    "1997-06-30,57,74;" +
    "1997-06-30,54,72;" +
    "1997-07-01,50,84;" +
    "1997-07-01,50,81;" +
    "1997-07-02,50,89;" +
    "1997-07-02,50,87;" +
    "1997-07-03,58,94;" +
    "1997-07-03,59,92;" +
    "1997-07-04,56,87;" +
    "1997-07-04,54,83;" +
    "1997-07-05,56,84;" +
    "1997-07-05,54,81;" +
    "1997-07-06,56,90;" +
    "1997-07-06,56,86;" +
    "1997-07-07,55,98;" +
    "1997-07-07,54,93;" +
    "1997-07-08,60,98;" +
    "1997-07-08,61,95;" +
    "1997-07-09,60,92;" +
    "1997-07-09,58,83;" +
    "1997-07-10,54,87;" +
    "1997-07-10,53,68;" +
    "1997-07-11,55,85;" +
    "1997-07-11,55,83;" +
    "1997-07-12,54,90;" +
    "1997-07-12,52,88;" +
    "1997-07-13,57,88;" +
    "1997-07-13,56,86;" +
    "1997-07-14,58,90;" +
    "1997-07-14,56,87;" +
    "1997-07-15,56,96;" +
    "1997-07-15,55,93;" +
    "1997-07-16,58,90;" +
    "1997-07-16,56,82;" +
    "1997-07-17,56,85;" +
    "1997-07-17,56,81;" +
    "1997-07-18,55,90;" +
    "1997-07-18,54,88;" +
    "1997-07-19,59,91;" +
    "1997-07-19,56,88;" +
    "1997-07-20,57,86;" +
    "1997-07-20,55,83;" +
    "1997-07-21,57,95;" +
    "1997-07-21,55,93;" +
    "1997-07-22,59,91;" +
    "1997-07-22,60,86;" +
    "1997-07-23,60,84;" +
    "1997-07-23,59,85;" +
    "1997-07-24,63,95;" +
    "1997-07-24,63,94;" +
    "1997-07-25,61,95;" +
    "1997-07-25,60,93;" +
    "1997-07-26,60,90;" +
    "1997-07-26,57,86;" +
    "1997-07-27,57,86;" +
    "1997-07-27,56,83;" +
    "1997-07-28,57,85;" +
    "1997-07-28,55,82;" +
    "1997-07-29,56,85;" +
    "1997-07-29,56,82;" +
    "1997-07-30,58,79;" +
    "1997-07-30,55,76;" +
    "1997-07-31,53,86;" +
    "1997-07-31,53,83;" +
    "1997-08-01,54,86;" +
    "1997-08-01,54,83;" +
    "1997-08-02,54,91;" +
    "1997-08-02,55,89;" +
    "1997-08-03,55,91;" +
    "1997-08-03,54,89;" +
    "1997-08-04,57,100;" +
    "1997-08-04,58,98;" +
    "1997-08-05,60,105;" +
    "1997-08-05,65,104;" +
    "1997-08-06,60,105;" +
    "1997-08-06,60,103;" +
    "1997-08-07,66,103;" +
    "1997-08-07,70,103;" +
    "1997-08-08,64,94;" +
    "1997-08-08,58,88;" +
    "1997-08-09,59,84;" +
    "1997-08-09,59,81;" +
    "1997-08-10,62,76;" +
    "1997-08-10,60,76;" +
    "1997-08-11,58,81;" +
    "1997-08-11,58,79;" +
    "1997-08-12,59,88;" +
    "1997-08-12,58,87;" +
    "1997-08-13,57,89;" +
    "1997-08-13,56,86;" +
    "1997-08-14,57,93;" +
    "1997-08-14,56,90;" +
    "1997-08-15,58,86;" +
    "1997-08-15,55,78;" +
    "1997-08-16,58,79;" +
    "1997-08-16,58,76;" +
    "1997-08-17,57,78;" +
    "1997-08-17,56,76;" +
    "1997-08-18,58,82;" +
    "1997-08-18,56,80;" +
    "1997-08-19,60,80;" +
    "1997-08-19,59,80;" +
    "1997-08-20,60,77;" +
    "1997-08-20,52,74;" +
    "1997-08-21,57,90;" +
    "1997-08-21,58,88;" +
    "1997-08-22,60,84;" +
    "1997-08-22,58,79;" +
    "1997-08-23,60,80;" +
    "1997-08-23,58,79;" +
    "1997-08-24,63,82;" +
    "1997-08-24,60,80;" +
    "1997-08-25,60,82;" +
    "1997-08-25,58,81;" +
    "1997-08-26,61,83;" +
    "1997-08-26,59,81;" +
    "1997-08-27,60,80;" +
    "1997-08-27,59,78;" +
    "1997-08-28,60,82;" +
    "1997-08-28,58,80;" +
    "1997-08-29,62,81;" +
    "1997-08-29,57,79;" +
    "1997-08-30,55,80;" +
    "1997-08-30,55,78;" +
    "1997-08-31,60,82;" +
    "1997-08-31,58,80;" +
    "1997-09-01,57,86;" +
    "1997-09-01,57,84;" +
    "1997-09-02,58,86;" +
    "1997-09-02,58,84;" +
    "1997-09-03,59,90;" +
    "1997-09-03,58,89;" +
    "1997-09-04,61,97;" +
    "1997-09-04,63,98;" +
    "1997-09-05,64,92;" +
    "1997-09-05,58,89;" +
    "1997-09-06,53,83;" +
    "1997-09-06,56,80;" +
    "1997-09-07,54,86;" +
    "1997-09-07,54,85;" +
    "1997-09-08,61,92;" +
    "1997-09-08,59,91;" +
    "1997-09-09,59,84;" +
    "1997-09-09,59,81;" +
    "1997-09-10,60,76;" +
    "1997-09-10,60,75;" +
    "1997-09-11,63,83;" +
    "1997-09-11,60,82;" +
    "1997-09-12,57,83;" +
    "1997-09-12,58,80;" +
    "1997-09-13,56,78;" +
    "1997-09-13,57,76;" +
    "1997-09-14,61,81;" +
    "1997-09-14,55,79;" +
    "1997-09-15,54,78;" +
    "1997-09-15,53,76;" +
    "1997-09-16,53,82;" +
    "1997-09-16,54,84;" +
    "1997-09-17,54,79;" +
    "1997-09-17,56,78;" +
    "1997-09-18,59,81;" +
    "1997-09-18,54,79;" +
    "1997-09-19,48,83;" +
    "1997-09-19,52,83;" +
    "1997-09-20,54,90;" +
    "1997-09-20,61,89;" +
    "1997-09-21,53,92;" +
    "1997-09-21,59,91;" +
    "1997-09-22,53,91;" +
    "1997-09-22,54,91;" +
    "1997-09-23,56,94;" +
    "1997-09-23,61,95;" +
    "1997-09-24,60,96;" +
    "1997-09-24,67,98;" +
    "1997-09-25,68,93;" +
    "1997-09-25,62,89;" +
    "1997-09-26,63,78;" +
    "1997-09-26,57,76;" +
    "1997-09-27,53,88;" +
    "1997-09-27,55,88;" +
    "1997-09-28,60,89;" +
    "1997-09-28,68,90;" +
    "1997-09-29,59,95;" +
    "1997-09-29,66,94;" +
    "1997-09-30,55,89;" +
    "1997-09-30,58,87;" +
    "1997-10-01,56,82;" +
    "1997-10-01,57,79;" +
    "1997-10-02,56,76;" +
    "1997-10-02,54,74;" +
    "1997-10-03,51,82;" +
    "1997-10-03,54,80;" +
    "1997-10-04,58,78;" +
    "1997-10-04,58,77;" +
    "1997-10-05,50,79;" +
    "1997-10-05,53,80;" +
    "1997-10-06,52,75;" +
    "1997-10-06,52,63;" +
    "1997-10-07,45,70;" +
    "1997-10-07,48,69;" +
    "1997-10-08,51,70;" +
    "1997-10-08,51,66;" +
    "1997-10-09,54,67;" +
    "1997-10-09,50,66;" +
    "1997-10-10,48,64;" +
    "1997-10-10,48,63;" +
    "1997-10-11,42,68;" +
    "1997-10-11,44,66;" +
    "1997-10-12,43,71;" +
    "1997-10-12,48,71;" +
    "1997-10-13,48,75;" +
    "1997-10-13,54,76;" +
    "1997-10-14,46,80;" +
    "1997-10-14,52,82;" +
    "1997-10-15,48,84;" +
    "1997-10-15,55,85;" +
    "1997-10-16,50,86;" +
    "1997-10-16,58,89;" +
    "1997-10-17,51,85;" +
    "1997-10-17,60,88;" +
    "1997-10-18,49,86;" +
    "1997-10-18,52,85;" +
    "1997-10-19,52,74;" +
    "1997-10-19,49,66;" +
    "1997-10-20,44,74;" +
    "1997-10-20,46,73;" +
    "1997-10-21,49,78;" +
    "1997-10-21,50,77;" +
    "1997-10-22,51,75;" +
    "1997-10-22,50,74;" +
    "1997-10-23,51,73;" +
    "1997-10-23,50,72;" +
    "1997-10-24,47,70;" +
    "1997-10-24,50,68;" +
    "1997-10-25,50,69;" +
    "1997-10-25,51,71;" +
    "1997-10-26,41,77;" +
    "1997-10-26,48,79;" +
    "1997-10-27,47,74;" +
    "1997-10-27,50,75;" +
    "1997-10-28,42,74;" +
    "1997-10-28,49,74;" +
    "1997-10-29,45,75;" +
    "1997-10-29,47,73;" +
    "1997-10-30,56,74;" +
    "1997-10-30,57,73;" +
    "1997-10-31,57,77;" +
    "1997-10-31,57,77;" +
    "1997-11-01,52,82;" +
    "1997-11-01,56,84;" +
    "1997-11-02,55,79;" +
    "1997-11-02,62,81;" +
    "1997-11-03,52,78;" +
    "1997-11-03,59,79;" +
    "1997-11-04,56,82;" +
    "1997-11-04,61,84;" +
    "1997-11-05,48,80;" +
    "1997-11-05,52,79;" +
    "1997-11-06,48,68;" +
    "1997-11-06,51,69;" +
    "1997-11-07,49,66;" +
    "1997-11-07,47,67;" +
    "1997-11-08,40,65;" +
    "1997-11-08,42,67;" +
    "1997-11-09,40,69;" +
    "1997-11-09,45,69;" +
    "1997-11-10,51,62;" +
    "1997-11-10,51,63;" +
    "1997-11-11,52,61;" +
    "1997-11-11,48,61;" +
    "1997-11-12,42,65;" +
    "1997-11-12,44,62;" +
    "1997-11-13,50,63;" +
    "1997-11-13,50,61;" +
    "1997-11-14,46,62;" +
    "1997-11-14,49,61;" +
    "1997-11-15,47,61;" +
    "1997-11-15,49,58;" +
    "1997-11-16,46,62;" +
    "1997-11-16,51,60;" +
    "1997-11-17,43,65;" +
    "1997-11-17,47,65;" +
    "1997-11-18,46,57;" +
    "1997-11-18,47,57;" +
    "1997-11-19,50,59;" +
    "1997-11-19,49,58;" +
    "1997-11-20,41,60;" +
    "1997-11-20,45,61;" +
    "1997-11-21,43,58;" +
    "1997-11-21,45,57;" +
    "1997-11-22,48,61;" +
    "1997-11-22,50,61;" +
    "1997-11-23,55,70;" +
    "1997-11-23,53,67;" +
    "1997-11-24,44,67;" +
    "1997-11-24,48,64;" +
    "1997-11-25,47,61;" +
    "1997-11-25,49,59;" +
    "1997-11-26,50,57;" +
    "1997-11-26,49,57;" +
    "1997-11-27,48,54;" +
    "1997-11-27,44,53;" +
    "1997-11-28,38,58;" +
    "1997-11-28,42,59;" +
    "1997-11-29,41,60;" +
    "1997-11-29,44,58;" +
    "1997-11-30,47,54;" +
    "1997-11-30,45,52;" +
    "1997-12-01,37,60;" +
    "1997-12-01,42,64;" +
    "1997-12-02,35,54;" +
    "1997-12-02,38,54;" +
    "1997-12-03,41,53;" +
    "1997-12-03,43,54;" +
    "1997-12-04,46,55;" +
    "1997-12-04,49,59;" +
    "1997-12-05,50,61;" +
    "1997-12-05,51,58;" +
    "1997-12-06,49,55;" +
    "1997-12-06,49,53;" +
    "1997-12-07,46,56;" +
    "1997-12-07,44,56;" +
    "1997-12-08,43,52;" +
    "1997-12-08,39,51;" +
    "1997-12-09,34,55;" +
    "1997-12-09,36,57;" +
    "1997-12-10,37,56;" +
    "1997-12-10,42,58;" +
    "1997-12-11,38,55;" +
    "1997-12-11,40,56;" +
    "1997-12-12,32,46;" +
    "1997-12-12,32,49;" +
    "1997-12-13,35,44;" +
    "1997-12-13,37,47;" +
    "1997-12-14,39,59;" +
    "1997-12-14,45,56;" +
    "1997-12-15,34,56;" +
    "1997-12-15,38,58;" +
    "1997-12-16,38,55;" +
    "1997-12-16,40,56;" +
    "1997-12-17,44,61;" +
    "1997-12-17,46,64;" +
    "1997-12-18,45,59;" +
    "1997-12-18,46,57;" +
    "1997-12-19,44,57;" +
    "1997-12-19,44,57;" +
    "1997-12-20,33,56;" +
    "1997-12-20,40,54;" +
    "1997-12-21,37,55;" +
    "1997-12-21,39,53;" +
    "1997-12-22,41,54;" +
    "1997-12-22,42,53;" +
    "1997-12-23,30,58;" +
    "1997-12-23,36,58;" +
    "1997-12-24,38,56;" +
    "1997-12-24,45,53;" +
    "1997-12-25,42,54;" +
    "1997-12-25,40,54;" +
    "1997-12-26,28,54;" +
    "1997-12-26,34,56;" +
    "1997-12-27,28,54;" +
    "1997-12-27,31,56;" +
    "1997-12-28,36,61;" +
    "1997-12-28,49,62;" +
    "1997-12-29,37,57;" +
    "1997-12-29,40,58;" +
    "1997-12-30,37,59;" +
    "1997-12-30,44,62;" +
    "1997-12-31,42,59;" +
    "1997-12-31,44,57;"