export const data = "1981-01-01,31,46;" +
    "1981-01-02,32,48;" +
    "1981-01-03,44,60;" +
    "1981-01-04,43,63;" +
    "1981-01-05,42,59;" +
    "1981-01-06,34,51;" +
    "1981-01-07,35,48;" +
    "1981-01-08,34,55;" +
    "1981-01-09,36,48;" +
    "1981-01-10,35,45;" +
    "1981-01-11,28,50;" +
    "1981-01-12,39,54;" +
    "1981-01-13,36,66;" +
    "1981-01-14,34,61;" +
    "1981-01-15,37,65;" +
    "1981-01-16,47,65;" +
    "1981-01-17,47,67;" +
    "1981-01-18,43,67;" +
    "1981-01-19,46,66;" +
    "1981-01-20,46,61;" +
    "1981-01-21,50,59;" +
    "1981-01-22,50,65;" +
    "1981-01-23,45,57;" +
    "1981-01-24,42,57;" +
    "1981-01-25,31,58;" +
    "1981-01-26,36,52;" +
    "1981-01-27,45,58;" +
    "1981-01-28,46,56;" +
    "1981-01-29,41,54;" +
    "1981-01-30,31,56;" +
    "1981-01-31,30,58;" +
    "1981-02-01,31,60;" +
    "1981-02-02,37,60;" +
    "1981-02-03,31,64;" +
    "1981-02-04,36,64;" +
    "1981-02-05,36,62;" +
    "1981-02-06,40,60;" +
    "1981-02-07,43,55;" +
    "1981-02-08,45,52;" +
    "1981-02-09,45,62;" +
    "1981-02-10,39,64;" +
    "1981-02-11,51,69;" +
    "1981-02-12,46,65;" +
    "1981-02-13,49,68;" +
    "1981-02-14,53,70;" +
    "1981-02-15,44,71;" +
    "1981-02-16,42,73;" +
    "1981-02-17,55,71;" +
    "1981-02-18,43,73;" +
    "1981-02-19,49,70;" +
    "1981-02-20,38,65;" +
    "1981-02-21,46,67;" +
    "1981-02-22,35,68;" +
    "1981-02-23,37,67;" +
    "1981-02-24,45,57;" +
    "1981-02-25,41,57;" +
    "1981-02-26,36,61;" +
    "1981-02-27,39,63;" +
    "1981-02-28,44,59;" +
    "1981-03-01,46,56;" +
    "1981-03-02,45,60;" +
    "1981-03-03,47,62;" +
    "1981-03-04,43,60;" +
    "1981-03-05,40,64;" +
    "1981-03-06,37,67;" +
    "1981-03-07,39,64;" +
    "1981-03-08,43,68;" +
    "1981-03-09,40,74;" +
    "1981-03-10,40,72;" +
    "1981-03-11,44,73;" +
    "1981-03-12,50,69;" +
    "1981-03-13,44,60;" +
    "1981-03-14,45,61;" +
    "1981-03-15,43,57;" +
    "1981-03-16,38,63;" +
    "1981-03-17,44,64;" +
    "1981-03-18,41,60;" +
    "1981-03-19,49,57;" +
    "1981-03-20,37,55;" +
    "1981-03-21,47,67;" +
    "1981-03-22,46,67;" +
    "1981-03-23,38,67;" +
    "1981-03-24,40,72;" +
    "1981-03-25,52,66;" +
    "1981-03-26,44,62;" +
    "1981-03-27,39,70;" +
    "1981-03-28,40,76;" +
    "1981-03-29,48,68;" +
    "1981-03-30,36,66;" +
    "1981-03-31,36,62;" +
    "1981-04-01,46,63;" +
    "1981-04-02,38,68;" +
    "1981-04-03,40,69;" +
    "1981-04-04,37,75;" +
    "1981-04-05,41,81;" +
    "1981-04-06,42,75;" +
    "1981-04-07,39,73;" +
    "1981-04-08,44,66;" +
    "1981-04-09,41,72;" +
    "1981-04-10,44,64;" +
    "1981-04-11,41,66;" +
    "1981-04-12,35,72;" +
    "1981-04-13,36,80;" +
    "1981-04-14,40,82;" +
    "1981-04-15,41,75;" +
    "1981-04-16,42,80;" +
    "1981-04-17,50,74;" +
    "1981-04-18,49,68;" +
    "1981-04-19,47,63;" +
    "1981-04-20,44,66;" +
    "1981-04-21,43,76;" +
    "1981-04-22,49,84;" +
    "1981-04-23,51,86;" +
    "1981-04-24,48,78;" +
    "1981-04-25,45,64;" +
    "1981-04-26,43,66;" +
    "1981-04-27,39,83;" +
    "1981-04-28,52,93;" +
    "1981-04-29,55,96;" +
    "1981-04-30,57,95;" +
    "1981-05-01,51,84;" +
    "1981-05-02,48,68;" +
    "1981-05-03,44,73;" +
    "1981-05-04,46,68;" +
    "1981-05-05,45,76;" +
    "1981-05-06,39,78;" +
    "1981-05-07,39,80;" +
    "1981-05-08,42,88;" +
    "1981-05-09,50,85;" +
    "1981-05-10,44,85;" +
    "1981-05-11,51,90;" +
    "1981-05-12,50,88;" +
    "1981-05-13,49,78;" +
    "1981-05-14,51,71;" +
    "1981-05-15,50,69;" +
    "1981-05-16,45,77;" +
    "1981-05-17,44,78;" +
    "1981-05-18,56,72;" +
    "1981-05-19,49,64;" +
    "1981-05-20,47,73;" +
    "1981-05-21,52,70;" +
    "1981-05-22,46,84;" +
    "1981-05-23,50,85;" +
    "1981-05-24,55,81;" +
    "1981-05-25,59,78;" +
    "1981-05-26,52,80;" +
    "1981-05-27,52,87;" +
    "1981-05-28,51,88;" +
    "1981-05-29,51,84;" +
    "1981-05-30,55,75;" +
    "1981-05-31,50,91;" +
    "1981-06-01,50,89;" +
    "1981-06-02,54,84;" +
    "1981-06-03,51,92;" +
    "1981-06-04,55,103;" +
    "1981-06-05,61,100;" +
    "1981-06-06,59,92;" +
    "1981-06-07,54,86;" +
    "1981-06-08,60,85;" +
    "1981-06-09,55,81;" +
    "1981-06-10,52,85;" +
    "1981-06-11,52,82;" +
    "1981-06-12,52,78;" +
    "1981-06-13,46,81;" +
    "1981-06-14,47,87;" +
    "1981-06-15,60,100;" +
    "1981-06-16,57,100;" +
    "1981-06-17,52,99;" +
    "1981-06-18,55,104;" +
    "1981-06-19,65,109;" +
    "1981-06-20,64,107;" +
    "1981-06-21,61,108;" +
    "1981-06-22,65,104;" +
    "1981-06-23,57,98;" +
    "1981-06-24,53,92;" +
    "1981-06-25,56,96;" +
    "1981-06-26,57,97;" +
    "1981-06-27,56,101;" +
    "1981-06-28,60,92;" +
    "1981-06-29,55,88;" +
    "1981-06-30,54,87;" +
    "1981-07-01,53,85;" +
    "1981-07-02,55,93;" +
    "1981-07-03,55,96;" +
    "1981-07-04,61,95;" +
    "1981-07-05,69,92;" +
    "1981-07-06,57,85;" +
    "1981-07-07,50,92;" +
    "1981-07-08,55,100;" +
    "1981-07-09,55,89;" +
    "1981-07-10,51,87;" +
    "1981-07-11,51,84;" +
    "1981-07-12,49,83;" +
    "1981-07-13,49,98;" +
    "1981-07-14,59,104;" +
    "1981-07-15,57,96;" +
    "1981-07-16,53,85;" +
    "1981-07-17,53,78;" +
    "1981-07-18,52,92;" +
    "1981-07-19,52,98;" +
    "1981-07-20,55,99;" +
    "1981-07-21,55,100;" +
    "1981-07-22,56,94;" +
    "1981-07-23,53,92;" +
    "1981-07-24,55,93;" +
    "1981-07-25,52,95;" +
    "1981-07-26,54,90;" +
    "1981-07-27,54,88;" +
    "1981-07-28,54,94;" +
    "1981-07-29,53,88;" +
    "1981-07-30,52,84;" +
    "1981-07-31,52,80;" +
    "1981-08-01,53,78;" +
    "1981-08-02,51,79;" +
    "1981-08-03,53,78;" +
    "1981-08-04,52,87;" +
    "1981-08-05,52,99;" +
    "1981-08-06,55,104;" +
    "1981-08-07,61,106;" +
    "1981-08-08,62,103;" +
    "1981-08-09,58,90;" +
    "1981-08-10,57,86;" +
    "1981-08-11,58,83;" +
    "1981-08-12,57,84;" +
    "1981-08-13,56,82;" +
    "1981-08-14,55,85;" +
    "1981-08-15,53,93;" +
    "1981-08-16,55,93;" +
    "1981-08-17,53,83;" +
    "1981-08-18,54,73;" +
    "1981-08-19,56,76;" +
    "1981-08-20,52,82;" +
    "1981-08-21,50,92;" +
    "1981-08-22,51,87;" +
    "1981-08-23,54,80;" +
    "1981-08-24,58,83;" +
    "1981-08-25,50,88;" +
    "1981-08-26,49,96;" +
    "1981-08-27,53,104;" +
    "1981-08-28,58,105;" +
    "1981-08-29,55,95;" +
    "1981-08-30,54,90;" +
    "1981-08-31,54,95;" +
    "1981-09-01,54,94;" +
    "1981-09-02,53,89;" +
    "1981-09-03,51,88;" +
    "1981-09-04,52,90;" +
    "1981-09-05,54,97;" +
    "1981-09-06,55,90;" +
    "1981-09-07,56,90;" +
    "1981-09-08,55,92;" +
    "1981-09-09,54,81;" +
    "1981-09-10,54,92;" +
    "1981-09-11,54,90;" +
    "1981-09-12,56,85;" +
    "1981-09-13,56,80;" +
    "1981-09-14,55,83;" +
    "1981-09-15,54,84;" +
    "1981-09-16,54,82;" +
    "1981-09-17,56,86;" +
    "1981-09-18,55,80;" +
    "1981-09-19,57,90;" +
    "1981-09-20,52,84;" +
    "1981-09-21,48,90;" +
    "1981-09-22,47,88;" +
    "1981-09-23,46,82;" +
    "1981-09-24,48,74;" +
    "1981-09-25,52,76;" +
    "1981-09-26,51,84;" +
    "1981-09-27,50,81;" +
    "1981-09-28,53,77;" +
    "1981-09-29,48,80;" +
    "1981-09-30,45,87;" +
    "1981-10-01,52,90;" +
    "1981-10-02,54,80;" +
    "1981-10-03,52,68;" +
    "1981-10-04,40,73;" +
    "1981-10-05,42,80;" +
    "1981-10-06,45,77;" +
    "1981-10-07,56,68;" +
    "1981-10-08,47,73;" +
    "1981-10-09,44,76;" +
    "1981-10-10,55,67;" +
    "1981-10-11,43,67;" +
    "1981-10-12,39,66;" +
    "1981-10-13,38,68;" +
    "1981-10-14,39,70;" +
    "1981-10-15,38,75;" +
    "1981-10-16,39,77;" +
    "1981-10-17,46,77;" +
    "1981-10-18,43,83;" +
    "1981-10-19,43,82;" +
    "1981-10-20,42,83;" +
    "1981-10-21,44,81;" +
    "1981-10-22,42,82;" +
    "1981-10-23,43,83;" +
    "1981-10-24,43,74;" +
    "1981-10-25,51,72;" +
    "1981-10-26,51,68;" +
    "1981-10-27,51,72;" +
    "1981-10-28,52,67;" +
    "1981-10-29,47,63;" +
    "1981-10-30,37,68;" +
    "1981-10-31,42,67;" +
    "1981-11-01,42,74;" +
    "1981-11-02,41,74;" +
    "1981-11-03,42,74;" +
    "1981-11-04,42,68;" +
    "1981-11-05,50,65;" +
    "1981-11-06,51,69;" +
    "1981-11-07,42,76;" +
    "1981-11-08,42,67;" +
    "1981-11-09,45,72;" +
    "1981-11-10,52,75;" +
    "1981-11-11,47,74;" +
    "1981-11-12,53,65;" +
    "1981-11-13,54,64;" +
    "1981-11-14,54,66;" +
    "1981-11-15,52,71;" +
    "1981-11-16,54,68;" +
    "1981-11-17,49,65;" +
    "1981-11-18,38,60;" +
    "1981-11-19,38,62;" +
    "1981-11-20,46,66;" +
    "1981-11-21,51,67;" +
    "1981-11-22,51,68;" +
    "1981-11-23,48,68;" +
    "1981-11-24,49,65;" +
    "1981-11-25,40,59;" +
    "1981-11-26,45,55;" +
    "1981-11-27,42,53;" +
    "1981-11-28,41,60;" +
    "1981-11-29,32,62;" +
    "1981-11-30,35,58;" +
    "1981-12-01,36,65;" +
    "1981-12-02,37,52;" +
    "1981-12-03,34,60;" +
    "1981-12-04,41,52;" +
    "1981-12-05,40,57;" +
    "1981-12-06,41,65;" +
    "1981-12-07,47,58;" +
    "1981-12-08,43,50;" +
    "1981-12-09,42,65;" +
    "1981-12-10,40,60;" +
    "1981-12-11,35,58;" +
    "1981-12-12,36,60;" +
    "1981-12-13,48,63;" +
    "1981-12-14,49,64;" +
    "1981-12-15,50,65;" +
    "1981-12-16,42,59;" +
    "1981-12-17,36,53;" +
    "1981-12-18,43,55;" +
    "1981-12-19,52,67;" +
    "1981-12-20,50,63;" +
    "1981-12-21,47,63;" +
    "1981-12-22,36,58;" +
    "1981-12-23,37,60;" +
    "1981-12-24,36,58;" +
    "1981-12-25,39,61;" +
    "1981-12-26,45,57;" +
    "1981-12-27,43,58;" +
    "1981-12-28,34,56;" +
    "1981-12-29,47,58;" +
    "1981-12-30,49,59;" +
    "1981-12-31,47,56;"