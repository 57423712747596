export const data = "1999-01-01,33,60;" +
    "1999-01-01,39,60;" +
    "1999-01-01,42,62;" +
    "1999-01-02,30,63;" +
    "1999-01-02,33,64;" +
    "1999-01-02,41,62;" +
    "1999-01-03,35,60;" +
    "1999-01-03,41,61;" +
    "1999-01-03,39,63;" +
    "1999-01-04,31,59;" +
    "1999-01-04,31,59;" +
    "1999-01-04,32,60;" +
    "1999-01-05,29,61;" +
    "1999-01-05,32,60;" +
    "1999-01-05,37,62;" +
    "1999-01-06,29,50;" +
    "1999-01-06,30,54;" +
    "1999-01-06,29,52;" +
    "1999-01-07,31,49;" +
    "1999-01-07,34,51;" +
    "1999-01-07,32,49;" +
    "1999-01-08,29,55;" +
    "1999-01-08,31,55;" +
    "1999-01-08,35,54;" +
    "1999-01-09,29,52;" +
    "1999-01-09,32,50;" +
    "1999-01-09,29,52;" +
    "1999-01-10,27,46;" +
    "1999-01-10,27,44;" +
    "1999-01-10,27,45;" +
    "1999-01-11,27,60;" +
    "1999-01-11,24,62;" +
    "1999-01-11,25,61;" +
    "1999-01-12,37,61;" +
    "1999-01-12,38,64;" +
    "1999-01-12,42,61;" +
    "1999-01-13,36,57;" +
    "1999-01-13,41,59;" +
    "1999-01-13,42,59;" +
    "1999-01-14,36,56;" +
    "1999-01-14,37,57;" +
    "1999-01-14,40,57;" +
    "1999-01-15,45,62;" +
    "1999-01-15,45,62;" +
    "1999-01-15,45,64;" +
    "1999-01-16,50,60;" +
    "1999-01-16,52,60;" +
    "1999-01-16,52,62;" +
    "1999-01-17,50,61;" +
    "1999-01-17,50,63;" +
    "1999-01-17,52,62;" +
    "1999-01-18,54,57;" +
    "1999-01-18,54,58;" +
    "1999-01-18,56,60;" +
    "1999-01-19,53,64;" +
    "1999-01-19,53,66;" +
    "1999-01-19,55,67;" +
    "1999-01-20,50,57;" +
    "1999-01-20,53,57;" +
    "1999-01-20,54,59;" +
    "1999-01-21,45,56;" +
    "1999-01-21,48,58;" +
    "1999-01-21,50,63;" +
    "1999-01-22,46,59;" +
    "1999-01-22,46,63;" +
    "1999-01-22,50,64;" +
    "1999-01-23,37,56;" +
    "1999-01-23,46,56;" +
    "1999-01-23,40,56;" +
    "1999-01-24,28,52;" +
    "1999-01-24,30,52;" +
    "1999-01-24,34,53;" +
    "1999-01-25,38,53;" +
    "1999-01-25,40,55;" +
    "1999-01-25,40,53;" +
    "1999-01-26,33,45;" +
    "1999-01-26,41,50;" +
    "1999-01-26,39,47;" +
    "1999-01-27,30,57;" +
    "1999-01-27,33,58;" +
    "1999-01-27,40,56;" +
    "1999-01-28,29,58;" +
    "1999-01-28,30,61;" +
    "1999-01-28,35,59;" +
    "1999-01-29,29,55;" +
    "1999-01-29,30,55;" +
    "1999-01-29,33,56;" +
    "1999-01-30,31,56;" +
    "1999-01-30,32,57;" +
    "1999-01-30,32,57;" +
    "1999-01-31,36,52;" +
    "1999-01-31,44,53;" +
    "1999-01-31,40,52;" +
    "1999-02-01,32,55;" +
    "1999-02-01,35,56;" +
    "1999-02-01,36,57;" +
    "1999-02-02,29,59;" +
    "1999-02-02,30,61;" +
    "1999-02-02,34,61;" +
    "1999-02-03,34,63;" +
    "1999-02-03,36,65;" +
    "1999-02-03,41,65;" +
    "1999-02-04,36,57;" +
    "1999-02-04,42,59;" +
    "1999-02-04,40,59;" +
    "1999-02-05,31,55;" +
    "1999-02-05,33,58;" +
    "1999-02-05,36,56;" +
    "1999-02-06,42,55;" +
    "1999-02-06,43,54;" +
    "1999-02-06,43,56;" +
    "1999-02-07,48,56;" +
    "1999-02-07,51,58;" +
    "1999-02-07,53,59;" +
    "1999-02-08,47,58;" +
    "1999-02-08,47,58;" +
    "1999-02-08,54,63;" +
    "1999-02-09,36,57;" +
    "1999-02-09,40,58;" +
    "1999-02-09,40,63;" +
    "1999-02-10,30,52;" +
    "1999-02-10,33,53;" +
    "1999-02-10,37,57;" +
    "1999-02-11,30,52;" +
    "1999-02-11,32,53;" +
    "1999-02-11,36,51;" +
    "1999-02-12,33,54;" +
    "1999-02-12,34,56;" +
    "1999-02-12,36,54;" +
    "1999-02-13,33,60;" +
    "1999-02-13,36,63;" +
    "1999-02-13,39,61;" +
    "1999-02-14,36,54;" +
    "1999-02-14,44,55;" +
    "1999-02-14,42,54;" +
    "1999-02-15,34,56;" +
    "1999-02-15,35,58;" +
    "1999-02-15,38,56;" +
    "1999-02-16,45,57;" +
    "1999-02-16,46,57;" +
    "1999-02-16,46,57;" +
    "1999-02-17,47,65;" +
    "1999-02-17,46,66;" +
    "1999-02-17,51,70;" +
    "1999-02-18,44,58;" +
    "1999-02-18,46,60;" +
    "1999-02-18,47,58;" +
    "1999-02-19,36,61;" +
    "1999-02-19,38,62;" +
    "1999-02-19,43,60;" +
    "1999-02-20,37,53;" +
    "1999-02-20,40,54;" +
    "1999-02-20,41,51;" +
    "1999-02-21,42,54;" +
    "1999-02-21,42,57;" +
    "1999-02-21,43,54;" +
    "1999-02-22,41,59;" +
    "1999-02-22,44,61;" +
    "1999-02-22,45,58;" +
    "1999-02-23,36,63;" +
    "1999-02-23,37,65;" +
    "1999-02-23,45,63;" +
    "1999-02-24,39,59;" +
    "1999-02-24,39,62;" +
    "1999-02-24,40,59;" +
    "1999-02-25,41,56;" +
    "1999-02-25,43,58;" +
    "1999-02-25,43,58;" +
    "1999-02-26,34,57;" +
    "1999-02-26,36,60;" +
    "1999-02-26,39,60;" +
    "1999-02-27,38,66;" +
    "1999-02-27,40,69;" +
    "1999-02-27,42,68;" +
    "1999-02-28,46,66;" +
    "1999-02-28,47,68;" +
    "1999-02-28,49,66;" +
    "1999-03-01,41,64;" +
    "1999-03-01,49,67;" +
    "1999-03-01,46,65;" +
    "1999-03-02,37,69;" +
    "1999-03-02,38,72;" +
    "1999-03-02,42,70;" +
    "1999-03-03,43,58;" +
    "1999-03-03,46,61;" +
    "1999-03-03,42,58;" +
    "1999-03-04,36,56;" +
    "1999-03-04,39,59;" +
    "1999-03-04,38,54;" +
    "1999-03-05,32,58;" +
    "1999-03-05,34,61;" +
    "1999-03-05,36,60;" +
    "1999-03-06,35,53;" +
    "1999-03-06,38,56;" +
    "1999-03-06,40,53;" +
    "1999-03-07,31,57;" +
    "1999-03-07,34,60;" +
    "1999-03-07,35,56;" +
    "1999-03-08,44,52;" +
    "1999-03-08,44,52;" +
    "1999-03-08,43,50;" +
    "1999-03-09,35,53;" +
    "1999-03-09,39,56;" +
    "1999-03-09,39,55;" +
    "1999-03-10,32,55;" +
    "1999-03-10,34,57;" +
    "1999-03-10,36,54;" +
    "1999-03-11,37,60;" +
    "1999-03-11,42,63;" +
    "1999-03-11,41,59;" +
    "1999-03-12,34,63;" +
    "1999-03-12,37,67;" +
    "1999-03-12,39,63;" +
    "1999-03-13,34,62;" +
    "1999-03-13,37,65;" +
    "1999-03-13,40,62;" +
    "1999-03-14,43,55;" +
    "1999-03-14,44,56;" +
    "1999-03-14,43,54;" +
    "1999-03-15,43,58;" +
    "1999-03-15,43,60;" +
    "1999-03-15,43,57;" +
    "1999-03-16,47,60;" +
    "1999-03-16,46,63;" +
    "1999-03-16,47,61;" +
    "1999-03-17,45,62;" +
    "1999-03-17,47,64;" +
    "1999-03-17,44,62;" +
    "1999-03-18,41,61;" +
    "1999-03-18,42,64;" +
    "1999-03-18,43,61;" +
    "1999-03-19,46,59;" +
    "1999-03-19,47,61;" +
    "1999-03-19,46,56;" +
    "1999-03-20,41,59;" +
    "1999-03-20,43,60;" +
    "1999-03-20,44,59;" +
    "1999-03-21,40,63;" +
    "1999-03-21,43,67;" +
    "1999-03-21,46,60;" +
    "1999-03-22,38,59;" +
    "1999-03-22,40,61;" +
    "1999-03-22,41,58;" +
    "1999-03-23,46,59;" +
    "1999-03-23,46,62;" +
    "1999-03-23,46,59;" +
    "1999-03-24,47,61;" +
    "1999-03-24,47,62;" +
    "1999-03-24,47,60;" +
    "1999-03-25,50,65;" +
    "1999-03-25,51,66;" +
    "1999-03-25,49,65;" +
    "1999-03-26,43,64;" +
    "1999-03-26,46,66;" +
    "1999-03-26,45,62;" +
    "1999-03-27,34,61;" +
    "1999-03-27,37,63;" +
    "1999-03-27,40,59;" +
    "1999-03-28,33,63;" +
    "1999-03-28,38,66;" +
    "1999-03-28,39,62;" +
    "1999-03-29,37,61;" +
    "1999-03-29,39,64;" +
    "1999-03-29,40,59;" +
    "1999-03-30,35,57;" +
    "1999-03-30,40,58;" +
    "1999-03-30,39,55;" +
    "1999-03-31,39,55;" +
    "1999-03-31,39,56;" +
    "1999-03-31,38,52;" +
    "1999-04-01,33,59;" +
    "1999-04-01,36,62;" +
    "1999-04-01,37,56;" +
    "1999-04-02,35,64;" +
    "1999-04-02,36,67;" +
    "1999-04-02,40,65;" +
    "1999-04-03,44,56;" +
    "1999-04-03,46,57;" +
    "1999-04-03,44,53;" +
    "1999-04-04,35,60;" +
    "1999-04-04,36,61;" +
    "1999-04-04,37,59;" +
    "1999-04-05,41,49;" +
    "1999-04-05,45,58;" +
    "1999-04-05,42,50;" +
    "1999-04-06,41,56;" +
    "1999-04-06,41,58;" +
    "1999-04-06,43,56;" +
    "1999-04-07,41,53;" +
    "1999-04-07,43,56;" +
    "1999-04-07,43,52;" +
    "1999-04-08,38,51;" +
    "1999-04-08,42,56;" +
    "1999-04-08,37,48;" +
    "1999-04-09,31,56;" +
    "1999-04-09,33,59;" +
    "1999-04-09,35,54;" +
    "1999-04-10,35,58;" +
    "1999-04-10,38,60;" +
    "1999-04-10,40,56;" +
    "1999-04-11,46,56;" +
    "1999-04-11,45,56;" +
    "1999-04-11,46,55;" +
    "1999-04-12,40,68;" +
    "1999-04-12,41,70;" +
    "1999-04-12,45,67;" +
    "1999-04-13,44,73;" +
    "1999-04-13,45,75;" +
    "1999-04-13,46,74;" +
    "1999-04-14,47,79;" +
    "1999-04-14,49,81;" +
    "1999-04-14,53,78;" +
    "1999-04-15,47,79;" +
    "1999-04-15,50,81;" +
    "1999-04-15,55,79;" +
    "1999-04-16,51,85;" +
    "1999-04-16,54,86;" +
    "1999-04-16,58,84;" +
    "1999-04-17,52,84;" +
    "1999-04-17,55,88;" +
    "1999-04-17,56,85;" +
    "1999-04-18,45,78;" +
    "1999-04-18,50,82;" +
    "1999-04-18,50,76;" +
    "1999-04-19,48,74;" +
    "1999-04-19,47,75;" +
    "1999-04-19,48,72;" +
    "1999-04-20,48,66;" +
    "1999-04-20,50,70;" +
    "1999-04-20,46,64;" +
    "1999-04-21,46,67;" +
    "1999-04-21,47,70;" +
    "1999-04-21,45,64;" +
    "1999-04-22,47,72;" +
    "1999-04-22,48,74;" +
    "1999-04-22,47,68;" +
    "1999-04-23,50,77;" +
    "1999-04-23,57,79;" +
    "1999-04-23,57,74;" +
    "1999-04-24,44,76;" +
    "1999-04-24,49,79;" +
    "1999-04-24,50,74;" +
    "1999-04-25,49,64;" +
    "1999-04-25,50,67;" +
    "1999-04-25,48,61;" +
    "1999-04-26,45,65;" +
    "1999-04-26,50,68;" +
    "1999-04-26,43,63;" +
    "1999-04-27,43,60;" +
    "1999-04-27,44,62;" +
    "1999-04-27,42,57;" +
    "1999-04-28,41,63;" +
    "1999-04-28,42,65;" +
    "1999-04-28,39,61;" +
    "1999-04-29,44,71;" +
    "1999-04-29,46,73;" +
    "1999-04-29,45,67;" +
    "1999-04-30,44,77;" +
    "1999-04-30,46,80;" +
    "1999-04-30,47,75;" +
    "1999-05-01,47,66;" +
    "1999-05-01,47,69;" +
    "1999-05-01,47,62;" +
    "1999-05-02,48,62;" +
    "1999-05-02,48,63;" +
    "1999-05-02,47,59;" +
    "1999-05-03,47,61;" +
    "1999-05-03,50,62;" +
    "1999-05-03,46,57;" +
    "1999-05-04,44,65;" +
    "1999-05-04,45,67;" +
    "1999-05-04,43,61;" +
    "1999-05-05,40,78;" +
    "1999-05-05,42,80;" +
    "1999-05-05,42,78;" +
    "1999-05-06,46,70;" +
    "1999-05-06,48,77;" +
    "1999-05-06,48,66;" +
    "1999-05-07,47,69;" +
    "1999-05-07,47,73;" +
    "1999-05-07,45,66;" +
    "1999-05-08,38,68;" +
    "1999-05-08,41,70;" +
    "1999-05-08,40,66;" +
    "1999-05-09,38,68;" +
    "1999-05-09,41,70;" +
    "1999-05-09,41,64;" +
    "1999-05-10,42,73;" +
    "1999-05-10,43,75;" +
    "1999-05-10,42,70;" +
    "1999-05-11,50,78;" +
    "1999-05-11,50,82;" +
    "1999-05-11,49,76;" +
    "1999-05-12,46,65;" +
    "1999-05-12,52,78;" +
    "1999-05-12,44,63;" +
    "1999-05-13,41,67;" +
    "1999-05-13,43,70;" +
    "1999-05-13,42,64;" +
    "1999-05-14,43,62;" +
    "1999-05-14,45,63;" +
    "1999-05-14,42,59;" +
    "1999-05-15,39,67;" +
    "1999-05-15,42,70;" +
    "1999-05-15,42,64;" +
    "1999-05-16,40,72;" +
    "1999-05-16,42,75;" +
    "1999-05-16,43,69;" +
    "1999-05-17,42,77;" +
    "1999-05-17,45,79;" +
    "1999-05-17,45,73;" +
    "1999-05-18,50,67;" +
    "1999-05-18,52,69;" +
    "1999-05-18,48,64;" +
    "1999-05-19,50,65;" +
    "1999-05-19,50,68;" +
    "1999-05-19,49,62;" +
    "1999-05-20,47,62;" +
    "1999-05-20,49,63;" +
    "1999-05-20,46,59;" +
    "1999-05-21,43,80;" +
    "1999-05-21,44,82;" +
    "1999-05-21,44,78;" +
    "1999-05-22,50,89;" +
    "1999-05-22,53,91;" +
    "1999-05-22,55,85;" +
    "1999-05-23,50,75;" +
    "1999-05-23,51,76;" +
    "1999-05-23,49,73;" +
    "1999-05-24,51,74;" +
    "1999-05-24,52,76;" +
    "1999-05-24,50,70;" +
    "1999-05-25,51,82;" +
    "1999-05-25,51,84;" +
    "1999-05-25,49,79;" +
    "1999-05-26,51,77;" +
    "1999-05-26,53,82;" +
    "1999-05-26,49,74;" +
    "1999-05-27,50,72;" +
    "1999-05-27,51,75;" +
    "1999-05-27,48,69;" +
    "1999-05-28,49,67;" +
    "1999-05-28,50,69;" +
    "1999-05-28,48,61;" +
    "1999-05-29,49,63;" +
    "1999-05-29,50,64;" +
    "1999-05-29,48,61;" +
    "1999-05-30,46,72;" +
    "1999-05-30,47,73;" +
    "1999-05-30,46,68;" +
    "1999-05-31,50,69;" +
    "1999-05-31,50,71;" +
    "1999-05-31,49,66;" +
    "1999-06-01,51,68;" +
    "1999-06-01,52,70;" +
    "1999-06-01,50,64;" +
    "1999-06-02,49,61;" +
    "1999-06-02,50,63;" +
    "1999-06-02,47,57;" +
    "1999-06-03,48,65;" +
    "1999-06-03,47,66;" +
    "1999-06-03,47,63;" +
    "1999-06-04,48,71;" +
    "1999-06-04,48,74;" +
    "1999-06-04,47,69;" +
    "1999-06-05,51,78;" +
    "1999-06-05,52,80;" +
    "1999-06-05,50,75;" +
    "1999-06-06,47,74;" +
    "1999-06-06,48,76;" +
    "1999-06-06,45,70;" +
    "1999-06-07,45,67;" +
    "1999-06-07,46,67;" +
    "1999-06-07,44,65;" +
    "1999-06-08,45,73;" +
    "1999-06-08,45,74;" +
    "1999-06-08,44,70;" +
    "1999-06-09,44,73;" +
    "1999-06-09,43,75;" +
    "1999-06-09,45,71;" +
    "1999-06-10,48,81;" +
    "1999-06-10,47,82;" +
    "1999-06-10,47,78;" +
    "1999-06-11,50,76;" +
    "1999-06-11,50,78;" +
    "1999-06-11,48,72;" +
    "1999-06-12,50,80;" +
    "1999-06-12,50,81;" +
    "1999-06-12,48,77;" +
    "1999-06-13,51,81;" +
    "1999-06-13,52,83;" +
    "1999-06-13,50,78;" +
    "1999-06-14,1,75;" +
    "1999-06-14,55,77;" +
    "1999-06-14,52,72;" +
    "1999-06-15,53,74;" +
    "1999-06-15,53,75;" +
    "1999-06-15,52,70;" +
    "1999-06-16,53,81;" +
    "1999-06-16,53,83;" +
    "1999-06-16,51,79;" +
    "1999-06-17,52,87;" +
    "1999-06-17,52,88;" +
    "1999-06-17,51,84;" +
    "1999-06-18,52,80;" +
    "1999-06-18,52,82;" +
    "1999-06-18,51,77;" +
    "1999-06-19,51,82;" +
    "1999-06-19,52,83;" +
    "1999-06-19,50,79;" +
    "1999-06-20,52,89;" +
    "1999-06-20,51,89;" +
    "1999-06-20,50,85;" +
    "1999-06-21,55,79;" +
    "1999-06-21,54,82;" +
    "1999-06-21,52,77;" +
    "1999-06-22,59,95;" +
    "1999-06-22,59,96;" +
    "1999-06-22,58,92;" +
    "1999-06-23,57,90;" +
    "1999-06-23,61,91;" +
    "1999-06-23,55,87;" +
    "1999-06-24,55,82;" +
    "1999-06-24,54,84;" +
    "1999-06-24,53,77;" +
    "1999-06-25,53,82;" +
    "1999-06-25,52,83;" +
    "1999-06-25,51,78;" +
    "1999-06-26,50,88;" +
    "1999-06-26,49,89;" +
    "1999-06-26,49,84;" +
    "1999-06-27,50,95;" +
    "1999-06-27,52,96;" +
    "1999-06-27,52,93;" +
    "1999-06-28,58,100;" +
    "1999-06-28,60,100;" +
    "1999-06-28,62,97;" +
    "1999-06-29,60,101;" +
    "1999-06-29,63,102;" +
    "1999-06-29,65,99;" +
    "1999-06-30,65,104;" +
    "1999-06-30,65,104;" +
    "1999-06-30,65,101;" +
    "1999-07-01,57,92;" +
    "1999-07-01,63,98;" +
    "1999-07-01,55,90;" +
    "1999-07-02,54,81;" +
    "1999-07-02,54,83;" +
    "1999-07-02,53,76;" +
    "1999-07-03,52,71;" +
    "1999-07-03,52,75;" +
    "1999-07-03,50,67;" +
    "1999-07-04,49,83;" +
    "1999-07-04,49,84;" +
    "1999-07-04,48,80;" +
    "1999-07-05,51,93;" +
    "1999-07-05,53,94;" +
    "1999-07-05,54,90;" +
    "1999-07-06,52,77;" +
    "1999-07-06,52,87;" +
    "1999-07-06,50,75;" +
    "1999-07-07,50,92;" +
    "1999-07-07,50,93;" +
    "1999-07-07,49,77;" +
    "1999-07-08,54,97;" +
    "1999-07-08,56,97;" +
    "1999-07-08,64,64;" +
    "1999-07-09,54,93;" +
    "1999-07-09,54,94;" +
    "1999-07-10,54,96;" +
    "1999-07-10,55,97;" +
    "1999-07-11,61,105;" +
    "1999-07-11,63,105;" +
    "1999-07-12,76,107;" +
    "1999-07-12,76,106;" +
    "1999-07-13,68,100;" +
    "1999-07-13,75,99;" +
    "1999-07-13,69,96;" +
    "1999-07-14,59,92;" +
    "1999-07-14,64,94;" +
    "1999-07-14,58,89;" +
    "1999-07-15,54,80;" +
    "1999-07-15,57,83;" +
    "1999-07-15,52,78;" +
    "1999-07-16,53,74;" +
    "1999-07-16,54,76;" +
    "1999-07-16,52,71;" +
    "1999-07-17,53,77;" +
    "1999-07-17,54,80;" +
    "1999-07-17,51,75;" +
    "1999-07-18,49,81;" +
    "1999-07-18,49,83;" +
    "1999-07-18,49,79;" +
    "1999-07-19,52,72;" +
    "1999-07-19,53,74;" +
    "1999-07-19,51,68;" +
    "1999-07-20,58,73;" +
    "1999-07-20,56,75;" +
    "1999-07-20,56,71;" +
    "1999-07-21,56,78;" +
    "1999-07-21,58,80;" +
    "1999-07-21,54,76;" +
    "1999-07-22,53,83;" +
    "1999-07-22,54,85;" +
    "1999-07-22,52,82;" +
    "1999-07-23,54,72;" +
    "1999-07-23,54,79;" +
    "1999-07-23,53,69;" +
    "1999-07-24,56,78;" +
    "1999-07-24,56,79;" +
    "1999-07-24,54,74;" +
    "1999-07-25,53,82;" +
    "1999-07-25,54,85;" +
    "1999-07-25,53,80;" +
    "1999-07-26,54,79;" +
    "1999-07-26,54,81;" +
    "1999-07-26,53,76;" +
    "1999-07-27,56,75;" +
    "1999-07-27,56,76;" +
    "1999-07-27,53,71;" +
    "1999-07-28,54,75;" +
    "1999-07-28,56,76;" +
    "1999-07-28,53,73;" +
    "1999-07-29,53,81;" +
    "1999-07-29,55,83;" +
    "1999-07-29,52,79;" +
    "1999-07-30,53,82;" +
    "1999-07-30,54,83;" +
    "1999-07-30,52,79;" +
    "1999-07-31,52,75;" +
    "1999-07-31,53,77;" +
    "1999-07-31,51,73;" +
    "1999-08-01,53,82;" +
    "1999-08-01,53,82;" +
    "1999-08-01,52,78;" +
    "1999-08-02,58,84;" +
    "1999-08-02,58,85;" +
    "1999-08-02,57,83;" +
    "1999-08-03,58,85;" +
    "1999-08-03,57,87;" +
    "1999-08-03,55,83;" +
    "1999-08-04,56,76;" +
    "1999-08-04,56,77;" +
    "1999-08-04,55,72;" +
    "1999-08-05,59,64;" +
    "1999-08-05,58,72;" +
    "1999-08-05,58,61;" +
    "1999-08-06,58,74;" +
    "1999-08-06,58,74;" +
    "1999-08-06,57,72;" +
    "1999-08-07,57,75;" +
    "1999-08-07,58,76;" +
    "1999-08-07,56,73;" +
    "1999-08-08,58,75;" +
    "1999-08-08,57,75;" +
    "1999-08-08,56,72;" +
    "1999-08-09,58,75;" +
    "1999-08-09,58,76;" +
    "1999-08-09,56,74;" +
    "1999-08-10,58,73;" +
    "1999-08-10,58,73;" +
    "1999-08-10,56,71;" +
    "1999-08-11,60,73;" +
    "1999-08-11,60,73;" +
    "1999-08-11,58,72;" +
    "1999-08-12,57,77;" +
    "1999-08-12,58,79;" +
    "1999-08-12,55,75;" +
    "1999-08-13,56,77;" +
    "1999-08-13,56,79;" +
    "1999-08-13,55,75;" +
    "1999-08-14,53,75;" +
    "1999-08-14,53,75;" +
    "1999-08-14,53,73;" +
    "1999-08-15,49,87;" +
    "1999-08-15,49,88;" +
    "1999-08-15,50,86;" +
    "1999-08-16,54,90;" +
    "1999-08-16,55,91;" +
    "1999-08-16,56,89;" +
    "1999-08-17,56,84;" +
    "1999-08-17,56,85;" +
    "1999-08-17,54,82;" +
    "1999-08-18,55,71;" +
    "1999-08-18,55,82;" +
    "1999-08-18,54,69;" +
    "1999-08-19,53,90;" +
    "1999-08-19,52,91;" +
    "1999-08-19,53,88;" +
    "1999-08-20,54,86;" +
    "1999-08-20,55,87;" +
    "1999-08-20,55,83;" +
    "1999-08-21,55,95;" +
    "1999-08-21,54,96;" +
    "1999-08-21,54,94;" +
    "1999-08-22,58,101;" +
    "1999-08-22,59,102;" +
    "1999-08-22,66,100;" +
    "1999-08-23,63,94;" +
    "1999-08-23,64,96;" +
    "1999-08-23,63,92;" +
    "1999-08-24,58,97;" +
    "1999-08-24,58,99;" +
    "1999-08-24,57,95;" +
    "1999-08-25,60,103;" +
    "1999-08-25,60,103;" +
    "1999-08-25,61,103;" +
    "1999-08-26,63,99;" +
    "1999-08-26,66,100;" +
    "1999-08-26,71,98;" +
    "1999-08-27,61,92;" +
    "1999-08-27,73,93;" +
    "1999-08-27,61,91;" +
    "1999-08-28,59,95;" +
    "1999-08-28,59,96;" +
    "1999-08-28,57,93;" +
    "1999-08-29,57,83;" +
    "1999-08-29,57,86;" +
    "1999-08-29,55,80;" +
    "1999-08-30,55,76;" +
    "1999-08-30,58,76;" +
    "1999-08-30,56,74;" +
    "1999-08-31,50,80;" +
    "1999-08-31,51,80;" +
    "1999-08-31,53,77;" +
    "1999-09-01,48,81;" +
    "1999-09-01,48,82;" +
    "1999-09-01,49,79;" +
    "1999-09-02,52,81;" +
    "1999-09-02,51,82;" +
    "1999-09-02,52,80;" +
    "1999-09-03,54,82;" +
    "1999-09-03,53,83;" +
    "1999-09-03,54,79;" +
    "1999-09-04,52,88;" +
    "1999-09-04,52,88;" +
    "1999-09-04,53,87;" +
    "1999-09-05,54,98;" +
    "1999-09-05,54,98;" +
    "1999-09-05,57,97;" +
    "1999-09-06,52,95;" +
    "1999-09-06,56,96;" +
    "1999-09-06,58,93;" +
    "1999-09-07,55,91;" +
    "1999-09-07,55,92;" +
    "1999-09-07,57,90;" +
    "1999-09-08,57,86;" +
    "1999-09-08,56,87;" +
    "1999-09-08,55,85;" +
    "1999-09-09,57,76;" +
    "1999-09-09,59,79;" +
    "1999-09-09,55,74;" +
    "1999-09-10,57,81;" +
    "1999-09-10,56,82;" +
    "1999-09-10,56,79;" +
    "1999-09-11,56,81;" +
    "1999-09-11,55,83;" +
    "1999-09-11,54,79;" +
    "1999-09-12,56,85;" +
    "1999-09-12,56,87;" +
    "1999-09-12,55,84;" +
    "1999-09-13,56,82;" +
    "1999-09-13,56,83;" +
    "1999-09-13,54,79;" +
    "1999-09-14,53,78;" +
    "1999-09-14,54,80;" +
    "1999-09-14,53,76;" +
    "1999-09-15,54,87;" +
    "1999-09-15,55,88;" +
    "1999-09-15,52,86;" +
    "1999-09-16,53,85;" +
    "1999-09-16,53,86;" +
    "1999-09-16,52,84;" +
    "1999-09-17,54,83;" +
    "1999-09-17,54,84;" +
    "1999-09-17,53,82;" +
    "1999-09-18,54,76;" +
    "1999-09-18,54,78;" +
    "1999-09-18,52,75;" +
    "1999-09-19,56,73;" +
    "1999-09-19,56,73;" +
    "1999-09-19,56,71;" +
    "1999-09-20,54,81;" +
    "1999-09-20,54,83;" +
    "1999-09-20,54,80;" +
    "1999-09-21,52,91;" +
    "1999-09-21,53,92;" +
    "1999-09-21,54,91;" +
    "1999-09-22,62,85;" +
    "1999-09-22,62,86;" +
    "1999-09-22,60,84;" +
    "1999-09-23,58,87;" +
    "1999-09-23,58,87;" +
    "1999-09-23,57,86;" +
    "1999-09-24,56,85;" +
    "1999-09-24,56,86;" +
    "1999-09-24,56,83;" +
    "1999-09-25,55,95;" +
    "1999-09-25,56,96;" +
    "1999-09-25,58,94;" +
    "1999-09-26,57,96;" +
    "1999-09-26,61,95;" +
    "1999-09-26,65,94;" +
    "1999-09-27,62,92;" +
    "1999-09-27,69,90;" +
    "1999-09-27,71,90;" +
    "1999-09-28,60,89;" +
    "1999-09-28,64,88;" +
    "1999-09-28,70,88;" +
    "1999-09-29,57,95;" +
    "1999-09-29,58,94;" +
    "1999-09-29,65,96;" +
    "1999-09-30,58,97;" +
    "1999-09-30,58,98;" +
    "1999-09-30,66,96;" +
    "1999-10-01,52,89;" +
    "1999-10-01,56,90;" +
    "1999-10-01,59,88;" +
    "1999-10-02,52,77;" +
    "1999-10-02,53,81;" +
    "1999-10-02,52,76;" +
    "1999-10-03,53,77;" +
    "1999-10-03,54,78;" +
    "1999-10-03,53,76;" +
    "1999-10-04,55,72;" +
    "1999-10-04,54,73;" +
    "1999-10-04,55,70;" +
    "1999-10-05,54,73;" +
    "1999-10-05,54,73;" +
    "1999-10-05,53,71;" +
    "1999-10-06,51,71;" +
    "1999-10-06,59,72;" +
    "1999-10-06,52,69;" +
    "1999-10-07,47,85;" +
    "1999-10-07,48,82;" +
    "1999-10-07,50,83;" +
    "1999-10-08,51,90;" +
    "1999-10-08,52,90;" +
    "1999-10-08,58,90;" +
    "1999-10-09,51,91;" +
    "1999-10-09,53,91;" +
    "1999-10-09,60,92;" +
    "1999-10-10,50,95;" +
    "1999-10-10,54,92;" +
    "1999-10-10,62,95;" +
    "1999-10-11,53,90;" +
    "1999-10-11,54,90;" +
    "1999-10-11,60,88;" +
    "1999-10-12,50,88;" +
    "1999-10-12,50,87;" +
    "1999-10-12,53,88;" +
    "1999-10-13,53,91;" +
    "1999-10-13,54,92;" +
    "1999-10-13,61,91;" +
    "1999-10-14,54,90;" +
    "1999-10-14,56,90;" +
    "1999-10-14,59,88;" +
    "1999-10-15,53,85;" +
    "1999-10-15,56,83;" +
    "1999-10-15,58,83;" +
    "1999-10-16,59,81;" +
    "1999-10-16,61,80;" +
    "1999-10-16,66,81;" +
    "1999-10-17,51,85;" +
    "1999-10-17,58,86;" +
    "1999-10-17,59,86;" +
    "1999-10-18,47,83;" +
    "1999-10-18,47,83;" +
    "1999-10-18,53,83;" +
    "1999-10-19,45,83;" +
    "1999-10-19,46,81;" +
    "1999-10-19,52,82;" +
    "1999-10-20,48,84;" +
    "1999-10-20,47,82;" +
    "1999-10-20,54,85;" +
    "1999-10-21,47,85;" +
    "1999-10-21,48,83;" +
    "1999-10-21,55,85;" +
    "1999-10-22,46,86;" +
    "1999-10-22,48,86;" +
    "1999-10-22,53,87;" +
    "1999-10-23,49,69;" +
    "1999-10-23,50,71;" +
    "1999-10-23,49,68;" +
    "1999-10-24,48,78;" +
    "1999-10-24,49,79;" +
    "1999-10-24,51,78;" +
    "1999-10-25,44,79;" +
    "1999-10-25,46,80;" +
    "1999-10-25,47,79;" +
    "1999-10-26,44,75;" +
    "1999-10-26,46,75;" +
    "1999-10-26,50,73;" +
    "1999-10-27,53,77;" +
    "1999-10-27,54,78;" +
    "1999-10-27,52,75;" +
    "1999-10-28,44,66;" +
    "1999-10-28,54,69;" +
    "1999-10-28,49,66;" +
    "1999-10-29,41,72;" +
    "1999-10-29,43,72;" +
    "1999-10-29,45,72;" +
    "1999-10-30,41,73;" +
    "1999-10-30,43,72;" +
    "1999-10-30,49,73;" +
    "1999-10-31,41,79;" +
    "1999-10-31,44,77;" +
    "1999-10-31,50,79;" +
    "1999-11-01,49,81;" +
    "1999-11-01,54,79;" +
    "1999-11-01,58,86;" +
    "1999-11-02,45,79;" +
    "1999-11-02,46,80;" +
    "1999-11-02,50,78;" +
    "1999-11-03,48,71;" +
    "1999-11-03,51,73;" +
    "1999-11-03,51,72;" +
    "1999-11-04,43,79;" +
    "1999-11-04,44,79;" +
    "1999-11-04,47,81;" +
    "1999-11-05,46,78;" +
    "1999-11-05,48,79;" +
    "1999-11-05,51,78;" +
    "1999-11-06,49,71;" +
    "1999-11-06,50,72;" +
    "1999-11-06,52,71;" +
    "1999-11-07,45,72;" +
    "1999-11-07,47,71;" +
    "1999-11-07,51,70;" +
    "1999-11-08,48,66;" +
    "1999-11-08,53,66;" +
    "1999-11-08,48,65;" +
    "1999-11-09,43,66;" +
    "1999-11-09,43,67;" +
    "1999-11-09,45,66;" +
    "1999-11-10,50,70;" +
    "1999-11-10,49,71;" +
    "1999-11-10,50,69;" +
    "1999-11-11,49,74;" +
    "1999-11-11,49,74;" +
    "1999-11-11,51,75;" +
    "1999-11-12,47,72;" +
    "1999-11-12,50,72;" +
    "1999-11-12,51,72;" +
    "1999-11-13,46,76;" +
    "1999-11-13,46,74;" +
    "1999-11-13,53,76;" +
    "1999-11-14,47,65;" +
    "1999-11-14,48,66;" +
    "1999-11-14,51,63;" +
    "1999-11-15,50,71;" +
    "1999-11-15,53,72;" +
    "1999-11-15,54,72;" +
    "1999-11-16,46,66;" +
    "1999-11-16,47,68;" +
    "1999-11-16,49,65;" +
    "1999-11-17,43,60;" +
    "1999-11-17,46,61;" +
    "1999-11-17,45,59;" +
    "1999-11-18,39,61;" +
    "1999-11-18,40,61;" +
    "1999-11-18,41,62;" +
    "1999-11-19,39,63;" +
    "1999-11-19,42,63;" +
    "1999-11-19,45,59;" +
    "1999-11-20,46,61;" +
    "1999-11-20,45,59;" +
    "1999-11-20,47,58;" +
    "1999-11-21,41,59;" +
    "1999-11-21,45,60;" +
    "1999-11-21,44,57;" +
    "1999-11-22,34,59;" +
    "1999-11-22,36,60;" +
    "1999-11-22,41,60;" +
    "1999-11-23,34,60;" +
    "1999-11-23,35,61;" +
    "1999-11-23,38,61;" +
    "1999-11-24,34,61;" +
    "1999-11-24,36,62;" +
    "1999-11-24,39,63;" +
    "1999-11-25,36,67;" +
    "1999-11-25,38,67;" +
    "1999-11-25,41,67;" +
    "1999-11-26,45,60;" +
    "1999-11-26,44,60;" +
    "1999-11-26,45,58;" +
    "1999-11-27,50,59;" +
    "1999-11-27,47,61;" +
    "1999-11-27,49,56;" +
    "1999-11-28,46,58;" +
    "1999-11-28,44,58;" +
    "1999-11-28,48,57;" +
    "1999-11-29,46,60;" +
    "1999-11-29,47,61;" +
    "1999-11-30,44,61;" +
    "1999-11-30,48,62;" +
    "1999-11-30,46,59;" +
    "1999-12-01,40,59;" +
    "1999-12-01,44,62;" +
    "1999-12-01,43,58;" +
    "1999-12-02,37,59;" +
    "1999-12-02,38,59;" +
    "1999-12-02,40,58;" +
    "1999-12-03,40,60;" +
    "1999-12-03,41,60;" +
    "1999-12-03,42,58;" +
    "1999-12-04,33,56;" +
    "1999-12-04,36,56;" +
    "1999-12-04,41,56;" +
    "1999-12-05,30,61;" +
    "1999-12-05,33,60;" +
    "1999-12-05,38,63;" +
    "1999-12-06,33,57;" +
    "1999-12-06,36,58;" +
    "1999-12-06,37,56;" +
    "1999-12-07,35,58;" +
    "1999-12-07,47,58;" +
    "1999-12-07,40,56;" +
    "1999-12-08,30,55;" +
    "1999-12-08,32,54;" +
    "1999-12-08,36,54;" +
    "1999-12-09,39,53;" +
    "1999-12-09,41,56;" +
    "1999-12-09,42,54;" +
    "1999-12-10,35,56;" +
    "1999-12-10,37,56;" +
    "1999-12-10,38,57;" +
    "1999-12-11,32,60;" +
    "1999-12-11,36,62;" +
    "1999-12-11,39,60;" +
    "1999-12-12,33,60;" +
    "1999-12-12,36,60;" +
    "1999-12-12,40,61;" +
    "1999-12-13,34,56;" +
    "1999-12-13,44,56;" +
    "1999-12-13,40,54;" +
    "1999-12-14,30,58;" +
    "1999-12-14,33,58;" +
    "1999-12-14,37,59;" +
    "1999-12-15,30,59;" +
    "1999-12-15,31,58;" +
    "1999-12-15,35,61;" +
    "1999-12-16,27,61;" +
    "1999-12-16,31,61;" +
    "1999-12-16,34,63;" +
    "1999-12-17,32,66;" +
    "1999-12-17,35,65;" +
    "1999-12-17,40,66;" +
    "1999-12-18,34,60;" +
    "1999-12-18,36,61;" +
    "1999-12-18,43,60;" +
    "1999-12-19,40,65;" +
    "1999-12-19,46,64;" +
    "1999-12-19,49,65;" +
    "1999-12-20,44,65;" +
    "1999-12-20,48,65;" +
    "1999-12-20,52,64;" +
    "1999-12-21,37,70;" +
    "1999-12-21,40,67;" +
    "1999-12-21,47,69;" +
    "1999-12-22,37,70;" +
    "1999-12-22,40,71;" +
    "1999-12-22,47,68;" +
    "1999-12-23,31,68;" +
    "1999-12-23,34,67;" +
    "1999-12-23,44,68;" +
    "1999-12-24,32,64;" +
    "1999-12-24,35,63;" +
    "1999-12-24,40,64;" +
    "1999-12-25,29,64;" +
    "1999-12-25,33,63;" +
    "1999-12-25,41,66;" +
    "1999-12-26,29,62;" +
    "1999-12-26,32,61;" +
    "1999-12-26,41,62;" +
    "1999-12-27,30,64;" +
    "1999-12-27,33,62;" +
    "1999-12-27,42,64;" +
    "1999-12-28,32,69;" +
    "1999-12-28,34,68;" +
    "1999-12-28,41,71;" +
    "1999-12-29,31,64;" +
    "1999-12-29,33,62;" +
    "1999-12-29,41,65;" +
    "1999-12-30,29,61;" +
    "1999-12-30,32,59;" +
    "1999-12-30,40,62;" +
    "1999-12-31,31,52;" +
    "1999-12-31,34,51;" +
    "1999-12-31,39,49;"