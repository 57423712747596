export const data = "1986-01-01,46,56;" +
    "1986-01-02,45,59;" +
    "1986-01-03,49,58;" +
    "1986-01-04,50,65;" +
    "1986-01-05,52,65;" +
    "1986-01-06,45,61;" +
    "1986-01-07,40,58;" +
    "1986-01-08,38,62;" +
    "1986-01-09,35,61;" +
    "1986-01-10,32,61;" +
    "1986-01-11,35,47;" +
    "1986-01-12,38,46;" +
    "1986-01-13,38,60;" +
    "1986-01-14,38,60;" +
    "1986-01-15,41,57;" +
    "1986-01-16,50,67;" +
    "1986-01-17,54,70;" +
    "1986-01-18,50,61;" +
    "1986-01-19,52,65;" +
    "1986-01-20,41,61;" +
    "1986-01-21,40,59;" +
    "1986-01-22,37,63;" +
    "1986-01-23,36,62;" +
    "1986-01-24,39,62;" +
    "1986-01-25,36,59;" +
    "1986-01-26,37,62;" +
    "1986-01-27,38,63;" +
    "1986-01-28,43,57;" +
    "1986-01-29,49,59;" +
    "1986-01-30,49,64;" +
    "1986-01-31,50,60;" +
    "1986-02-01,43,64;" +
    "1986-02-02,51,59;" +
    "1986-02-03,45,56;" +
    "1986-02-04,35,63;" +
    "1986-02-05,37,60;" +
    "1986-02-06,34,56;" +
    "1986-02-07,34,58;" +
    "1986-02-08,29,56;" +
    "1986-02-09,32,59;" +
    "1986-02-10,30,61;" +
    "1986-02-11,36,56;" +
    "1986-02-12,47,60;" +
    "1986-02-13,50,65;" +
    "1986-02-14,54,68;" +
    "1986-02-15,55,62;" +
    "1986-02-16,56,59;" +
    "1986-02-17,56,63;" +
    "1986-02-18,58,68;" +
    "1986-02-19,53,65;" +
    "1986-02-20,46,65;" +
    "1986-02-21,43,67;" +
    "1986-02-22,44,70;" +
    "1986-02-23,48,71;" +
    "1986-02-24,45,70;" +
    "1986-02-25,45,74;" +
    "1986-02-26,47,74;" +
    "1986-02-27,46,76;" +
    "1986-02-28,46,74;" +
    "1986-03-01,49,75;" +
    "1986-03-02,44,72;" +
    "1986-03-03,46,75;" +
    "1986-03-04,47,75;" +
    "1986-03-05,47,80;" +
    "1986-03-06,50,74;" +
    "1986-03-07,57,65;" +
    "1986-03-08,52,63;" +
    "1986-03-09,45,69;" +
    "1986-03-10,49,58;" +
    "1986-03-11,48,61;" +
    "1986-03-12,43,62;" +
    "1986-03-13,42,57;" +
    "1986-03-14,39,60;" +
    "1986-03-15,44,59;" +
    "1986-03-16,44,55;" +
    "1986-03-17,36,65;" +
    "1986-03-18,40,66;" +
    "1986-03-19,39,69;" +
    "1986-03-20,41,76;" +
    "1986-03-21,43,74;" +
    "1986-03-22,44,75;" +
    "1986-03-23,44,75;" +
    "1986-03-24,49,67;" +
    "1986-03-25,41,73;" +
    "1986-03-26,45,74;" +
    "1986-03-27,48,77;" +
    "1986-03-28,50,81;" +
    "1986-03-29,55,74;" +
    "1986-03-30,54,68;" +
    "1986-03-31,47,79;" +
    "1986-04-01,50,63;" +
    "1986-04-02,37,69;" +
    "1986-04-03,38,73;" +
    "1986-04-04,43,68;" +
    "1986-04-05,57,61;" +
    "1986-04-06,50,64;" +
    "1986-04-07,52,60;" +
    "1986-04-08,45,70;" +
    "1986-04-09,45,75;" +
    "1986-04-10,45,80;" +
    "1986-04-11,41,76;" +
    "1986-04-12,47,62;" +
    "1986-04-13,36,67;" +
    "1986-04-14,43,73;" +
    "1986-04-15,48,59;" +
    "1986-04-16,45,55;" +
    "1986-04-17,41,67;" +
    "1986-04-18,38,75;" +
    "1986-04-19,41,82;" +
    "1986-04-20,47,87;" +
    "1986-04-21,56,90;" +
    "1986-04-22,50,79;" +
    "1986-04-23,45,69;" +
    "1986-04-24,41,65;" +
    "1986-04-25,49,64;" +
    "1986-04-26,39,74;" +
    "1986-04-27,42,80;" +
    "1986-04-28,47,73;" +
    "1986-04-29,41,75;" +
    "1986-04-30,37,77;" +
    "1986-05-01,48,65;" +
    "1986-05-02,45,73;" +
    "1986-05-03,52,63;" +
    "1986-05-04,48,67;" +
    "1986-05-05,48,70;" +
    "1986-05-06,38,60;" +
    "1986-05-07,36,72;" +
    "1986-05-08,42,80;" +
    "1986-05-09,45,85;" +
    "1986-05-10,46,72;" +
    "1986-05-11,39,77;" +
    "1986-05-12,41,83;" +
    "1986-05-13,46,86;" +
    "1986-05-14,47,78;" +
    "1986-05-15,49,81;" +
    "1986-05-16,46,90;" +
    "1986-05-17,50,95;" +
    "1986-05-18,51,86;" +
    "1986-05-19,47,78;" +
    "1986-05-20,52,71;" +
    "1986-05-21,44,71;" +
    "1986-05-22,40,80;" +
    "1986-05-23,46,70;" +
    "1986-05-24,45,84;" +
    "1986-05-25,56,88;" +
    "1986-05-26,54,84;" +
    "1986-05-27,52,79;" +
    "1986-05-28,51,85;" +
    "1986-05-29,49,93;" +
    "1986-05-30,52,86;" +
    "1986-05-31,53,75;" +
    "1986-06-01,53,75;" +
    "1986-06-02,54,71;" +
    "1986-06-03,55,75;" +
    "1986-06-04,55,74;" +
    "1986-06-05,54,72;" +
    "1986-06-06,54,70;" +
    "1986-06-07,52,78;" +
    "1986-06-08,49,92;" +
    "1986-06-09,53,98;" +
    "1986-06-10,59,94;" +
    "1986-06-11,54,84;" +
    "1986-06-12,51,83;" +
    "1986-06-13,51,78;" +
    "1986-06-14,55,75;" +
    "1986-06-15,55,75;" +
    "1986-06-16,53,78;" +
    "1986-06-17,54,79;" +
    "1986-06-18,51,80;" +
    "1986-06-19,48,85;" +
    "1986-06-20,48,87;" +
    "1986-06-21,51,96;" +
    "1986-06-22,57,100;" +
    "1986-06-23,59,96;" +
    "1986-06-24,56,92;" +
    "1986-06-25,55,90;" +
    "1986-06-26,54,84;" +
    "1986-06-27,53,80;" +
    "1986-06-28,54,79;" +
    "1986-06-29,51,85;" +
    "1986-06-30,50,93;" +
    "1986-07-01,56,99;" +
    "1986-07-02,59,95;" +
    "1986-07-03,56,87;" +
    "1986-07-04,52,84;" +
    "1986-07-05,52,89;" +
    "1986-07-06,57,80;" +
    "1986-07-07,52,87;" +
    "1986-07-08,51,88;" +
    "1986-07-09,51,85;" +
    "1986-07-10,52,95;" +
    "1986-07-11,55,96;" +
    "1986-07-12,52,91;" +
    "1986-07-13,48,89;" +
    "1986-07-14,54,93;" +
    "1986-07-15,54,84;" +
    "1986-07-16,48,79;" +
    "1986-07-17,49,78;" +
    "1986-07-18,51,80;" +
    "1986-07-19,49,91;" +
    "1986-07-20,52,100;" +
    "1986-07-21,57,97;" +
    "1986-07-22,56,87;" +
    "1986-07-23,57,87;" +
    "1986-07-24,58,83;" +
    "1986-07-25,55,81;" +
    "1986-07-26,53,84;" +
    "1986-07-27,52,80;" +
    "1986-07-28,50,84;" +
    "1986-07-29,50,94;" +
    "1986-07-30,52,100;" +
    "1986-07-31,55,100;" +
    "1986-08-01,55,96;" +
    "1986-08-02,53,97;" +
    "1986-08-03,55,100;" +
    "1986-08-04,58,97;" +
    "1986-08-05,54,89;" +
    "1986-08-06,53,92;" +
    "1986-08-07,52,90;" +
    "1986-08-08,51,88;" +
    "1986-08-09,52,86;" +
    "1986-08-10,53,87;" +
    "1986-08-11,53,93;" +
    "1986-08-12,53,94;" +
    "1986-08-13,53,92;" +
    "1986-08-14,53,88;" +
    "1986-08-15,54,82;" +
    "1986-08-16,55,80;" +
    "1986-08-17,56,84;" +
    "1986-08-18,50,95;" +
    "1986-08-19,52,100;" +
    "1986-08-20,55,95;" +
    "1986-08-21,52,88;" +
    "1986-08-22,50,81;" +
    "1986-08-23,51,86;" +
    "1986-08-24,50,86;" +
    "1986-08-25,52,94;" +
    "1986-08-26,54,92;" +
    "1986-08-27,54,87;" +
    "1986-08-28,53,79;" +
    "1986-08-29,51,81;" +
    "1986-08-30,50,90;" +
    "1986-08-31,51,95;" +
    "1986-09-01,54,97;" +
    "1986-09-02,54,89;" +
    "1986-09-03,54,89;" +
    "1986-09-04,54,91;" +
    "1986-09-05,53,93;" +
    "1986-09-06,52,87;" +
    "1986-09-07,53,82;" +
    "1986-09-08,51,77;" +
    "1986-09-09,45,80;" +
    "1986-09-10,44,90;" +
    "1986-09-11,47,90;" +
    "1986-09-12,49,76;" +
    "1986-09-13,50,74;" +
    "1986-09-14,49,74;" +
    "1986-09-15,45,77;" +
    "1986-09-16,45,75;" +
    "1986-09-17,50,73;" +
    "1986-09-18,45,72;" +
    "1986-09-19,49,71;" +
    "1986-09-20,44,77;" +
    "1986-09-21,43,76;" +
    "1986-09-22,41,80;" +
    "1986-09-23,51,70;" +
    "1986-09-24,53,68;" +
    "1986-09-25,47,69;" +
    "1986-09-26,46,71;" +
    "1986-09-27,49,70;" +
    "1986-09-28,42,78;" +
    "1986-09-29,39,83;" +
    "1986-09-30,49,83;" +
    "1986-10-01,47,79;" +
    "1986-10-02,54,79;" +
    "1986-10-03,50,82;" +
    "1986-10-04,43,85;" +
    "1986-10-05,46,90;" +
    "1986-10-06,49,90;" +
    "1986-10-07,48,92;" +
    "1986-10-08,49,92;" +
    "1986-10-09,47,87;" +
    "1986-10-10,50,74;" +
    "1986-10-11,51,73;" +
    "1986-10-12,47,75;" +
    "1986-10-13,41,79;" +
    "1986-10-14,47,84;" +
    "1986-10-15,53,84;" +
    "1986-10-16,47,70;" +
    "1986-10-17,56,71;" +
    "1986-10-18,46,75;" +
    "1986-10-19,40,78;" +
    "1986-10-20,44,75;" +
    "1986-10-21,47,77;" +
    "1986-10-22,45,80;" +
    "1986-10-23,47,83;" +
    "1986-10-24,54,78;" +
    "1986-10-25,47,80;" +
    "1986-10-26,48,85;" +
    "1986-10-27,52,78;" +
    "1986-10-28,45,71;" +
    "1986-10-29,47,76;" +
    "1986-10-30,48,70;" +
    "1986-10-31,41,76;" +
    "1986-11-01,47,78;" +
    "1986-11-02,49,77;" +
    "1986-11-03,37,83;" +
    "1986-11-04,40,82;" +
    "1986-11-05,39,82;" +
    "1986-11-06,46,73;" +
    "1986-11-07,42,67;" +
    "1986-11-08,36,68;" +
    "1986-11-09,37,73;" +
    "1986-11-10,39,70;" +
    "1986-11-11,37,73;" +
    "1986-11-12,36,73;" +
    "1986-11-13,35,78;" +
    "1986-11-14,45,65;" +
    "1986-11-15,39,69;" +
    "1986-11-16,36,69;" +
    "1986-11-17,40,70;" +
    "1986-11-18,44,74;" +
    "1986-11-19,46,68;" +
    "1986-11-20,38,73;" +
    "1986-11-21,44,68;" +
    "1986-11-22,36,68;" +
    "1986-11-23,35,73;" +
    "1986-11-24,33,66;" +
    "1986-11-25,39,72;" +
    "1986-11-26,32,73;" +
    "1986-11-27,28,72;" +
    "1986-11-28,32,59;" +
    "1986-11-29,35,59;" +
    "1986-11-30,38,60;" +
    "1986-12-01,29,62;" +
    "1986-12-02,29,65;" +
    "1986-12-03,31,64;" +
    "1986-12-04,40,61;" +
    "1986-12-05,37,62;" +
    "1986-12-06,35,63;" +
    "1986-12-07,39,63;" +
    "1986-12-08,30,66;" +
    "1986-12-09,30,61;" +
    "1986-12-10,28,59;" +
    "1986-12-11,29,50;" +
    "1986-12-12,33,45;" +
    "1986-12-13,33,54;" +
    "1986-12-14,38,47;" +
    "1986-12-15,38,47;" +
    "1986-12-16,36,64;" +
    "1986-12-17,31,64;" +
    "1986-12-18,40,57;" +
    "1986-12-19,39,60;" +
    "1986-12-20,33,60;" +
    "1986-12-21,29,54;" +
    "1986-12-22,35,48;" +
    "1986-12-23,38,61;" +
    "1986-12-24,33,60;" +
    "1986-12-25,32,57;" +
    "1986-12-26,33,49;" +
    "1986-12-27,32,48;" +
    "1986-12-28,33,45;" +
    "1986-12-29,32,55;" +
    "1986-12-30,38,47;" +
    "1986-12-31,36,52;"