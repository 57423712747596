export const data = "1992-01-01,35,57;" +
    "1992-01-01,18,57;" +
    "1992-01-02,33,50;" +
    "1992-01-02,38,50;" +
    "1992-01-03,38,62;" +
    "1992-01-03,38,60;" +
    "1992-01-04,42,60;" +
    "1992-01-04,47,58;" +
    "1992-01-05,42,60;" +
    "1992-01-05,45,52;" +
    "1992-01-06,45,50;" +
    "1992-01-06,44,48;" +
    "1992-01-07,43,49;" +
    "1992-01-07,41,48;" +
    "1992-01-08,33,56;" +
    "1992-01-08,37,55;" +
    "1992-01-09,31,49;" +
    "1992-01-09,37,51;" +
    "1992-01-10,35,55;" +
    "1992-01-10,34,52;" +
    "1992-01-11,35,57;" +
    "1992-01-11,37,55;" +
    "1992-01-12,39,54;" +
    "1992-01-12,38,56;" +
    "1992-01-13,30,51;" +
    "1992-01-13,33,52;" +
    "1992-01-14,31,56;" +
    "1992-01-14,35,58;" +
    "1992-01-15,36,51;" +
    "1992-01-15,36,45;" +
    "1992-01-16,35,53;" +
    "1992-01-16,34,51;" +
    "1992-01-17,33,57;" +
    "1992-01-17,34,56;" +
    "1992-01-18,37,59;" +
    "1992-01-18,36,61;" +
    "1992-01-19,31,59;" +
    "1992-01-19,32,46;" +
    "1992-01-20,29,42;" +
    "1992-01-20,29,45;" +
    "1992-01-21,29,53;" +
    "1992-01-21,28,53;" +
    "1992-01-22,28,40;" +
    "1992-01-22,29,41;" +
    "1992-01-23,32,37;" +
    "1992-01-23,30,35;" +
    "1992-01-24,32,38;" +
    "1992-01-24,29,37;" +
    "1992-01-25,35,54;" +
    "1992-01-25,35,60;" +
    "1992-01-26,39,63;" +
    "1992-01-26,39,63;" +
    "1992-01-27,34,52;" +
    "1992-01-27,43,52;" +
    "1992-01-28,43,62;" +
    "1992-01-28,43,63;" +
    "1992-01-29,42,57;" +
    "1992-01-29,42,59;" +
    "1992-01-30,39,51;" +
    "1992-01-30,43,49;" +
    "1992-01-31,39,55;" +
    "1992-01-31,40,56;" +
    "1992-02-01,48,61;" +
    "1992-02-01,42,56;" +
    "1992-02-02,34,63;" +
    "1992-02-02,38,62;" +
    "1992-02-03,39,65;" +
    "1992-02-03,44,66;" +
    "1992-02-04,38,64;" +
    "1992-02-04,40,65;" +
    "1992-02-05,34,58;" +
    "1992-02-05,38,59;" +
    "1992-02-06,45,52;" +
    "1992-02-06,45,52;" +
    "1992-02-07,48,56;" +
    "1992-02-07,48,56;" +
    "1992-02-08,52,60;" +
    "1992-02-08,52,58;" +
    "1992-02-09,50,59;" +
    "1992-02-09,49,58;" +
    "1992-02-10,49,57;" +
    "1992-02-10,47,54;" +
    "1992-02-11,46,66;" +
    "1992-02-11,47,60;" +
    "1992-02-12,49,63;" +
    "1992-02-12,46,57;" +
    "1992-02-13,48,61;" +
    "1992-02-13,46,56;" +
    "1992-02-14,42,58;" +
    "1992-02-14,42,56;" +
    "1992-02-15,40,56;" +
    "1992-02-15,40,51;" +
    "1992-02-16,46,58;" +
    "1992-02-16,43,51;" +
    "1992-02-17,44,58;" +
    "1992-02-17,43,56;" +
    "1992-02-18,40,63;" +
    "1992-02-18,43,57;" +
    "1992-02-19,51,64;" +
    "1992-02-19,52,59;" +
    "1992-02-20,50,65;" +
    "1992-02-20,4,60;" +
    "1992-02-21,51,62;" +
    "1992-02-21,50,60;" +
    "1992-02-22,51,64;" +
    "1992-02-22,50,63;" +
    "1992-02-23,42,67;" +
    "1992-02-23,48,68;" +
    "1992-02-24,41,68;" +
    "1992-02-24,48,66;" +
    "1992-02-25,43,70;" +
    "1992-02-25,50,70;" +
    "1992-02-26,42,70;" +
    "1992-02-26,48,70;" +
    "1992-02-27,42,71;" +
    "1992-02-27,50,72;" +
    "1992-02-28,45,70;" +
    "1992-02-28,50,70;" +
    "1992-02-29,47,67;" +
    "1992-02-29,52,66;" +
    "1992-03-01,47,67;" +
    "1992-03-01,49,64;" +
    "1992-03-02,51,63;" +
    "1992-03-02,49,61;" +
    "1992-03-03,53,62;" +
    "1992-03-03,50,58;" +
    "1992-03-04,43,65;" +
    "1992-03-04,44,61;" +
    "1992-03-05,52,62;" +
    "1992-03-05,47,57;" +
    "1992-03-06,46,62;" +
    "1992-03-06,46,58;" +
    "1992-03-07,44,63;" +
    "1992-03-07,45,59;" +
    "1992-03-08,42,67;" +
    "1992-03-08,45,63;" +
    "1992-03-09,50,67;" +
    "1992-03-09,48,64;" +
    "1992-03-10,46,69;" +
    "1992-03-10,49,68;" +
    "1992-03-11,47,71;" +
    "1992-03-11,47,68;" +
    "1992-03-12,40,69;" +
    "1992-03-12,43,69;" +
    "1992-03-13,46,65;" +
    "1992-03-13,47,60;" +
    "1992-03-14,46,61;" +
    "1992-03-14,47,59;" +
    "1992-03-15,46,62;" +
    "1992-03-15,49,58;" +
    "1992-03-16,45,58;" +
    "1992-03-16,47,55;" +
    "1992-03-17,42,62;" +
    "1992-03-17,44,60;" +
    "1992-03-18,40,62;" +
    "1992-03-18,42,61;" +
    "1992-03-19,44,63;" +
    "1992-03-19,46,63;" +
    "1992-03-20,50,64;" +
    "1992-03-20,50,62;" +
    "1992-03-21,49,67;" +
    "1992-03-21,50,53;" +
    "1992-03-22,51,65;" +
    "1992-03-22,49,61;" +
    "1992-03-23,47,62;" +
    "1992-03-23,48,64;" +
    "1992-03-24,45,64;" +
    "1992-03-24,46,62;" +
    "1992-03-25,50,67;" +
    "1992-03-25,51,65;" +
    "1992-03-26,44,73;" +
    "1992-03-26,47,71;" +
    "1992-03-27,49,74;" +
    "1992-03-27,50,69;" +
    "1992-03-28,52,65;" +
    "1992-03-28,47,60;" +
    "1992-03-29,42,67;" +
    "1992-03-29,45,62;" +
    "1992-03-30,51,58;" +
    "1992-03-30,48,54;" +
    "1992-03-31,47,71;" +
    "1992-03-31,45,74;" +
    "1992-04-01,47,76;" +
    "1992-04-01,49,76;" +
    "1992-04-02,49,76;" +
    "1992-04-02,50,71;" +
    "1992-04-03,50,71;" +
    "1992-04-03,50,65;" +
    "1992-04-04,49,67;" +
    "1992-04-04,46,62;" +
    "1992-04-05,40,68;" +
    "1992-04-05,41,63;" +
    "1992-04-06,39,70;" +
    "1992-04-06,42,69;" +
    "1992-04-07,41,77;" +
    "1992-04-07,45,74;" +
    "1992-04-08,43,75;" +
    "1992-04-08,45,70;" +
    "1992-04-09,44,69;" +
    "1992-04-09,47,64;" +
    "1992-04-10,50,76;" +
    "1992-04-10,51,72;" +
    "1992-04-11,52,68;" +
    "1992-04-11,53,65;" +
    "1992-04-12,52,68;" +
    "1992-04-12,52,60;" +
    "1992-04-13,44,71;" +
    "1992-04-13,45,67;" +
    "1992-04-14,49,73;" +
    "1992-04-14,50,67;" +
    "1992-04-15,53,70;" +
    "1992-04-15,51,66;" +
    "1992-04-16,56,75;" +
    "1992-04-16,56,70;" +
    "1992-04-17,58,73;" +
    "1992-04-17,52,67;" +
    "1992-04-18,43,76;" +
    "1992-04-18,45,74;" +
    "1992-04-19,51,83;" +
    "1992-04-19,58,81;" +
    "1992-04-20,51,81;" +
    "1992-04-20,51,75;" +
    "1992-04-21,50,68;" +
    "1992-04-21,47,64;" +
    "1992-04-22,46,70;" +
    "1992-04-22,44,66;" +
    "1992-04-23,41,74;" +
    "1992-04-23,44,74;" +
    "1992-04-24,46,80;" +
    "1992-04-24,49,79;" +
    "1992-04-25,48,87;" +
    "1992-04-25,52,84;" +
    "1992-04-26,48,82;" +
    "1992-04-26,52,77;" +
    "1992-04-27,48,86;" +
    "1992-04-27,49,82;" +
    "1992-04-28,53,89;" +
    "1992-04-28,54,86;" +
    "1992-04-29,55,83;" +
    "1992-04-29,56,77;" +
    "1992-04-30,56,71;" +
    "1992-04-30,50,66;" +
    "1992-05-01,46,80;" +
    "1992-05-01,46,77;" +
    "1992-05-02,50,87;" +
    "1992-05-02,52,88;" +
    "1992-05-03,53,91;" +
    "1992-05-03,57,87;" +
    "1992-05-04,53,91;" +
    "1992-05-04,52,87;" +
    "1992-05-05,54,86;" +
    "1992-05-05,56,82;" +
    "1992-05-06,54,78;" +
    "1992-05-06,53,72;" +
    "1992-05-07,57,76;" +
    "1992-05-07,54,71;" +
    "1992-05-08,53,84;" +
    "1992-05-08,51,79;" +
    "1992-05-09,58,88;" +
    "1992-05-09,58,85;" +
    "1992-05-10,53,87;" +
    "1992-05-10,59,83;" +
    "1992-05-11,61,82;" +
    "1992-05-11,55,78;" +
    "1992-05-12,52,80;" +
    "1992-05-12,50,77;" +
    "1992-05-13,51,76;" +
    "1992-05-13,50,72;" +
    "1992-05-14,51,74;" +
    "1992-05-14,50,70;" +
    "1992-05-15,50,75;" +
    "1992-05-15,48,71;" +
    "1992-05-16,51,80;" +
    "1992-05-16,50,76;" +
    "1992-05-17,49,81;" +
    "1992-05-17,50,77;" +
    "1992-05-18,54,77;" +
    "1992-05-18,52,73;" +
    "1992-05-19,54,75;" +
    "1992-05-19,53,70;" +
    "1992-05-20,54,78;" +
    "1992-05-20,52,75;" +
    "1992-05-21,50,86;" +
    "1992-05-21,50,83;" +
    "1992-05-22,49,91;" +
    "1992-05-22,53,88;" +
    "1992-05-23,52,90;" +
    "1992-05-23,54,87;" +
    "1992-05-24,55,85;" +
    "1992-05-24,54,79;" +
    "1992-05-25,54,79;" +
    "1992-05-25,54,77;" +
    "1992-05-26,56,84;" +
    "1992-05-26,55,80;" +
    "1992-05-27,55,86;" +
    "1992-05-27,53,82;" +
    "1992-05-28,54,81;" +
    "1992-05-28,53,77;" +
    "1992-05-29,55,85;" +
    "1992-05-29,54,81;" +
    "1992-05-30,55,87;" +
    "1992-05-30,54,83;" +
    "1992-05-31,55,91;" +
    "1992-05-31,54,86;" +
    "1992-06-01,55,91;" +
    "1992-06-01,54,85;" +
    "1992-06-02,56,90;" +
    "1992-06-02,55,85;" +
    "1992-06-03,57,90;" +
    "1992-06-03,56,86;" +
    "1992-06-04,53,94;" +
    "1992-06-04,54,90;" +
    "1992-06-05,55,84;" +
    "1992-06-05,54,78;" +
    "1992-06-06,57,78;" +
    "1992-06-06,53,74;" +
    "1992-06-07,54,80;" +
    "1992-06-07,52,75;" +
    "1992-06-08,51,81;" +
    "1992-06-08,50,77;" +
    "1992-06-09,54,83;" +
    "1992-06-09,51,78;" +
    "1992-06-10,53,74;" +
    "1992-06-10,52,68;" +
    "1992-06-11,53,69;" +
    "1992-06-11,52,67;" +
    "1992-06-12,53,72;" +
    "1992-06-12,51,67;" +
    "1992-06-13,51,73;" +
    "1992-06-13,51,69;" +
    "1992-06-14,52,73;" +
    "1992-06-14,50,69;" +
    "1992-06-15,53,70;" +
    "1992-06-15,52,55;" +
    "1992-06-16,49,82;" +
    "1992-06-17,53,85;" +
    "1992-06-18,53,79;" +
    "1992-06-19,54,91;" +
    "1992-06-19,81,87;" +
    "1992-06-20,56,95;" +
    "1992-06-20,84,91;" +
    "1992-06-21,60,94;" +
    "1992-06-21,68,89;" +
    "1992-06-22,58,91;" +
    "1992-06-22,86,86;" +
    "1992-06-23,57,85;" +
    "1992-06-23,78,82;" +
    "1992-06-24,58,86;" +
    "1992-06-24,63,83;" +
    "1992-06-25,59,82;" +
    "1992-06-26,61,80;" +
    "1992-06-27,59,78;" +
    "1992-06-28,60,77;" +
    "1992-06-28,71,73;" +
    "1992-06-29,61,73;" +
    "1992-06-29,58,60;" +
    "1992-06-30,57,75;" +
    "1992-07-01,56,79;" +
    "1992-07-01,72,75;" +
    "1992-07-02,53,86;" +
    "1992-07-02,75,83;" +
    "1992-07-03,54,87;" +
    "1992-07-04,60,74;" +
    "1992-07-05,57,80;" +
    "1992-07-06,55,80;" +
    "1992-07-07,51,90;" +
    "1992-07-08,58,94;" +
    "1992-07-08,80,89;" +
    "1992-07-09,62,97;" +
    "1992-07-09,70,93;" +
    "1992-07-10,65,91;" +
    "1992-07-10,63,88;" +
    "1992-07-11,63,87;" +
    "1992-07-11,62,81;" +
    "1992-07-12,61,82;" +
    "1992-07-12,63,81;" +
    "1992-07-13,62,89;" +
    "1992-07-13,60,86;" +
    "1992-07-14,61,89;" +
    "1992-07-14,61,86;" +
    "1992-07-15,61,91;" +
    "1992-07-15,60,87;" +
    "1992-07-16,58,93;" +
    "1992-07-16,57,87;" +
    "1992-07-17,59,90;" +
    "1992-07-17,57,81;" +
    "1992-07-18,60,90;" +
    "1992-07-18,59,87;" +
    "1992-07-19,58,85;" +
    "1992-07-19,58,81;" +
    "1992-07-20,60,82;" +
    "1992-07-20,58,76;" +
    "1992-07-21,58,82;" +
    "1992-07-21,57,76;" +
    "1992-07-22,58,77;" +
    "1992-07-22,55,74;" +
    "1992-07-23,54,89;" +
    "1992-07-23,54,85;" +
    "1992-07-24,53,92;" +
    "1992-07-24,54,88;" +
    "1992-07-25,55,86;" +
    "1992-07-25,55,81;" +
    "1992-07-26,58,93;" +
    "1992-07-26,54,90;" +
    "1992-07-27,60,99;" +
    "1992-07-27,60,94;" +
    "1992-07-28,61,98;" +
    "1992-07-28,60,93;" +
    "1992-07-29,55,91;" +
    "1992-07-29,53,85;" +
    "1992-07-30,55,83;" +
    "1992-07-30,52,77;" +
    "1992-07-31,54,89;" +
    "1992-07-31,54,86;" +
    "1992-08-01,55,96;" +
    "1992-08-02,56,97;" +
    "1992-08-03,54,97;" +
    "1992-08-04,55,92;" +
    "1992-08-05,54,81;" +
    "1992-08-06,55,84;" +
    "1992-08-07,57,85;" +
    "1992-08-08,59,83;" +
    "1992-08-09,55,98;" +
    "1992-08-10,61,103;" +
    "1992-08-11,62,98;" +
    "1992-08-12,60,89;" +
    "1992-08-13,60,96;" +
    "1992-08-14,59,94;" +
    "1992-08-15,65,99;" +
    "1992-08-16,65,104;" +
    "1992-08-17,56,98;" +
    "1992-08-18,54,104;" +
    "1992-08-19,60,101;" +
    "1992-08-20,61,94;" +
    "1992-08-21,55,84;" +
    "1992-08-22,53,83;" +
    "1992-08-23,52,92;" +
    "1992-08-24,53,92;" +
    "1992-08-25,55,87;" +
    "1992-08-26,54,97;" +
    "1992-08-27,55,97;" +
    "1992-08-28,56,86;" +
    "1992-08-29,57,76;" +
    "1992-08-30,58,75;" +
    "1992-08-31,57,79;" +
    "1992-09-01,60,81;" +
    "1992-09-01,58,78;" +
    "1992-09-02,56,80;" +
    "1992-09-02,55,76;" +
    "1992-09-03,56,73;" +
    "1992-09-03,55,68;" +
    "1992-09-04,52,85;" +
    "1992-09-04,53,82;" +
    "1992-09-05,53,89;" +
    "1992-09-05,57,85;" +
    "1992-09-06,53,89;" +
    "1992-09-06,54,87;" +
    "1992-09-07,53,92;" +
    "1992-09-07,59,91;" +
    "1992-09-08,52,94;" +
    "1992-09-08,54,91;" +
    "1992-09-09,55,87;" +
    "1992-09-09,56,83;" +
    "1992-09-10,56,77;" +
    "1992-09-10,56,72;" +
    "1992-09-11,56,77;" +
    "1992-09-11,57,74;" +
    "1992-09-12,54,88;" +
    "1992-09-12,54,85;" +
    "1992-09-13,54,92;" +
    "1992-09-13,61,88;" +
    "1992-09-14,53,86;" +
    "1992-09-14,52,71;" +
    "1992-09-15,47,84;" +
    "1992-09-15,49,81;" +
    "1992-09-16,51,84;" +
    "1992-09-16,52,80;" +
    "1992-09-17,56,83;" +
    "1992-09-17,56,80;" +
    "1992-09-18,53,91;" +
    "1992-09-18,52,88;" +
    "1992-09-19,54,91;" +
    "1992-09-19,54,88;" +
    "1992-09-20,53,94;" +
    "1992-09-20,55,90;" +
    "1992-09-21,53,95;" +
    "1992-09-21,55,92;" +
    "1992-09-22,55,83;" +
    "1992-09-22,54,78;" +
    "1992-09-23,57,87;" +
    "1992-09-23,57,84;" +
    "1992-09-24,56,80;" +
    "1992-09-24,57,77;" +
    "1992-09-25,51,86;" +
    "1992-09-25,57,86;" +
    "1992-09-26,61,91;" +
    "1992-09-26,65,91;" +
    "1992-09-27,55,95;" +
    "1992-09-27,63,96;" +
    "1992-09-28,52,99;" +
    "1992-09-28,62,98;" +
    "1992-09-29,58,92;" +
    "1992-09-29,61,83;" +
    "1992-09-30,56,87;" +
    "1992-09-30,58,84;" +
    "1992-10-01,64,75;" +
    "1992-10-01,56,72;" +
    "1992-10-02,56,73;" +
    "1992-10-02,55,69;" +
    "1992-10-03,50,79;" +
    "1992-10-03,51,76;" +
    "1992-10-04,51,85;" +
    "1992-10-04,56,82;" +
    "1992-10-05,51,86;" +
    "1992-10-05,55,86;" +
    "1992-10-06,52,90;" +
    "1992-10-06,61,89;" +
    "1992-10-07,52,93;" +
    "1992-10-07,62,91;" +
    "1992-10-08,54,92;" +
    "1992-10-08,60,88;" +
    "1992-10-09,51,91;" +
    "1992-10-09,59,91;" +
    "1992-10-10,54,94;" +
    "1992-10-10,62,93;" +
    "1992-10-11,54,96;" +
    "1992-10-11,61,93;" +
    "1992-10-12,54,92;" +
    "1992-10-12,57,89;" +
    "1992-10-13,54,81;" +
    "1992-10-13,54,78;" +
    "1992-10-14,54,73;" +
    "1992-10-14,52,68;" +
    "1992-10-15,49,73;" +
    "1992-10-15,50,69;" +
    "1992-10-16,51,79;" +
    "1992-10-16,51,76;" +
    "1992-10-17,48,81;" +
    "1992-10-17,52,80;" +
    "1992-10-18,52,74;" +
    "1992-10-18,53,70;" +
    "1992-10-19,51,78;" +
    "1992-10-19,53,73;" +
    "1992-10-20,54,78;" +
    "1992-10-20,56,76;" +
    "1992-10-21,58,74;" +
    "1992-10-21,53,71;" +
    "1992-10-22,50,77;" +
    "1992-10-22,51,77;" +
    "1992-10-23,46,79;" +
    "1992-10-23,52,80;" +
    "1992-10-24,52,79;" +
    "1992-10-24,59,75;" +
    "1992-10-25,55,74;" +
    "1992-10-25,57,71;" +
    "1992-10-26,54,79;" +
    "1992-10-26,56,79;" +
    "1992-10-27,55,77;" +
    "1992-10-27,55,76;" +
    "1992-10-28,56,61;" +
    "1992-10-28,54,59;" +
    "1992-10-29,56,64;" +
    "1992-10-29,55,61;" +
    "1992-10-30,57,64;" +
    "1992-10-30,55,63;" +
    "1992-10-31,50,70;" +
    "1992-10-31,53,68;" +
    "1992-11-01,56,72;" +
    "1992-11-01,58,69;" +
    "1992-11-02,56,76;" +
    "1992-11-02,57,73;" +
    "1992-11-03,53,75;" +
    "1992-11-03,57,72;" +
    "1992-11-04,50,76;" +
    "1992-11-04,55,73;" +
    "1992-11-05,50,77;" +
    "1992-11-05,53,76;" +
    "1992-11-06,48,77;" +
    "1992-11-06,52,77;" +
    "1992-11-07,51,72;" +
    "1992-11-07,52,70;" +
    "1992-11-08,47,66;" +
    "1992-11-08,48,64;" +
    "1992-11-09,42,65;" +
    "1992-11-09,48,63;" +
    "1992-11-10,41,63;" +
    "1992-11-10,44,62;" +
    "1992-11-11,33,65;" +
    "1992-11-11,40,65;" +
    "1992-11-12,36,64;" +
    "1992-11-12,41,65;" +
    "1992-11-13,38,66;" +
    "1992-11-13,46,65;" +
    "1992-11-14,39,68;" +
    "1992-11-14,48,68;" +
    "1992-11-15,38,71;" +
    "1992-11-15,45,72;" +
    "1992-11-16,44,68;" +
    "1992-11-16,45,65;" +
    "1992-11-17,54,65;" +
    "1992-11-17,47,62;" +
    "1992-11-18,39,69;" +
    "1992-11-18,41,66;" +
    "1992-11-19,43,58;" +
    "1992-11-19,45,57;" +
    "1992-11-20,37,61;" +
    "1992-11-20,42,58;" +
    "1992-11-21,38,64;" +
    "1992-11-21,44,62;" +
    "1992-11-22,46,54;" +
    "1992-11-22,39,52;" +
    "1992-11-23,29,58;" +
    "1992-11-23,37,56;" +
    "1992-11-24,37,53;" +
    "1992-11-24,41,50;" +
    "1992-11-25,44,57;" +
    "1992-11-25,43,57;" +
    "1992-11-26,40,63;" +
    "1992-11-26,45,63;" +
    "1992-11-27,48,67;" +
    "1992-11-27,47,66;" +
    "1992-11-28,36,64;" +
    "1992-11-28,41,61;" +
    "1992-11-29,44,62;" +
    "1992-11-29,45,62;" +
    "1992-11-30,33,62;" +
    "1992-11-30,39,63;" +
    "1992-12-01,35,64;" +
    "1992-12-01,43,63;" +
    "1992-12-02,35,56;" +
    "1992-12-02,40,53;" +
    "1992-12-03,45,55;" +
    "1992-12-03,44,54;" +
    "1992-12-04,39,60;" +
    "1992-12-04,43,59;" +
    "1992-12-05,32,60;" +
    "1992-12-05,39,57;" +
    "1992-12-06,47,57;" +
    "1992-12-06,47,55;" +
    "1992-12-07,43,57;" +
    "1992-12-07,42,51;" +
    "1992-12-08,37,59;" +
    "1992-12-08,42,56;" +
    "1992-12-09,54,63;" +
    "1992-12-09,54,60;" +
    "1992-12-10,50,63;" +
    "1992-12-10,47,59;" +
    "1992-12-11,42,54;" +
    "1992-12-11,42,51;" +
    "1992-12-12,34,54;" +
    "1992-12-12,36,52;" +
    "1992-12-13,44,55;" +
    "1992-12-13,46,54;" +
    "1992-12-14,36,56;" +
    "1992-12-14,39,57;" +
    "1992-12-15,33,57;" +
    "1992-12-15,37,56;" +
    "1992-12-16,41,56;" +
    "1992-12-16,39,56;" +
    "1992-12-17,42,53;" +
    "1992-12-17,36,50;" +
    "1992-12-18,29,53;" +
    "1992-12-18,30,50;" +
    "1992-12-19,29,51;" +
    "1992-12-19,35,50;" +
    "1992-12-20,38,54;" +
    "1992-12-20,39,52;" +
    "1992-12-21,42,55;" +
    "1992-12-21,40,54;" +
    "1992-12-22,35,52;" +
    "1992-12-22,39,53;" +
    "1992-12-23,37,54;" +
    "1992-12-23,32,53;" +
    "1992-12-24,34,43;" +
    "1992-12-24,31,45;" +
    "1992-12-25,31,42;" +
    "1992-12-25,30,43;" +
    "1992-12-26,31,41;" +
    "1992-12-26,28,41;" +
    "1992-12-27,29,51;" +
    "1992-12-27,30,50;" +
    "1992-12-28,40,53;" +
    "1992-12-28,45,51;" +
    "1992-12-29,40,53;" +
    "1992-12-29,38,48;" +
    "1992-12-30,36,54;" +
    "1992-12-30,38,51;" +
    "1992-12-31,39,56;" +
    "1992-12-31,43,54;"