export const data = "1993-01-01,42,55;" +
    "1993-01-01,38,53;" +
    "1993-01-02,31,48;" +
    "1993-01-02,33,45;" +
    "1993-01-03,28,46;" +
    "1993-01-03,32,46;" +
    "1993-01-04,25,50;" +
    "1993-01-04,27,51;" +
    "1993-01-05,29,40;" +
    "1993-01-05,31,39;" +
    "1993-01-06,35,42;" +
    "1993-01-06,38,51;" +
    "1993-01-07,35,53;" +
    "1993-01-07,44,53;" +
    "1993-01-08,42,53;" +
    "1993-01-08,41,50;" +
    "1993-01-09,37,53;" +
    "1993-01-09,38,50;" +
    "1993-01-10,36,50;" +
    "1993-01-10,36,47;" +
    "1993-01-11,29,47;" +
    "1993-01-11,32,46;" +
    "1993-01-12,35,41;" +
    "1993-01-12,34,56;" +
    "1993-01-13,39,60;" +
    "1993-01-13,53,59;" +
    "1993-01-14,45,56;" +
    "1993-01-14,43,54;" +
    "1993-01-15,38,61;" +
    "1993-01-15,43,59;" +
    "1993-01-16,40,55;" +
    "1993-01-16,43,53;" +
    "1993-01-17,43,54;" +
    "1993-01-17,43,51;" +
    "1993-01-18,45,50;" +
    "1993-01-18,45,50;" +
    "1993-01-19,43,54;" +
    "1993-01-19,44,53;" +
    "1993-01-20,48,63;" +
    "1993-01-20,52,58;" +
    "1993-01-21,51,64;" +
    "1993-01-21,50,61;" +
    "1993-01-22,46,59;" +
    "1993-01-22,45,54;" +
    "1993-01-23,40,54;" +
    "1993-01-23,40,56;" +
    "1993-01-24,37,60;" +
    "1993-01-24,39,59;" +
    "1993-01-25,33,55;" +
    "1993-01-25,36,54;" +
    "1993-01-26,33,60;" +
    "1993-01-26,42,59;" +
    "1993-01-27,34,52;" +
    "1993-01-27,34,55;" +
    "1993-01-28,34,57;" +
    "1993-01-28,35,58;" +
    "1993-01-29,39,65;" +
    "1993-01-29,45,61;" +
    "1993-01-30,43,61;" +
    "1993-01-30,45,59;" +
    "1993-01-31,33,64;" +
    "1993-01-31,39,54;" +
    "1993-02-01,34,58;" +
    "1993-02-01,39,58;" +
    "1993-02-02,36,58;" +
    "1993-02-02,40,57;" +
    "1993-02-03,44,55;" +
    "1993-02-03,45,55;" +
    "1993-02-04,43,64;" +
    "1993-02-04,43,66;" +
    "1993-02-05,52,68;" +
    "1993-02-05,52,63;" +
    "1993-02-06,44,61;" +
    "1993-02-06,45,59;" +
    "1993-02-07,44,61;" +
    "1993-02-07,47,60;" +
    "1993-02-08,49,60;" +
    "1993-02-08,49,56;" +
    "1993-02-09,42,56;" +
    "1993-02-09,46,53;" +
    "1993-02-10,39,60;" +
    "1993-02-10,42,57;" +
    "1993-02-11,48,60;" +
    "1993-02-11,43,57;" +
    "1993-02-12,36,59;" +
    "1993-02-12,38,54;" +
    "1993-02-13,34,58;" +
    "1993-02-13,37,57;" +
    "1993-02-14,33,59;" +
    "1993-02-14,39,58;" +
    "1993-02-15,33,59;" +
    "1993-02-15,37,55;" +
    "1993-02-16,44,52;" +
    "1993-02-16,44,50;" +
    "1993-02-17,45,64;" +
    "1993-02-17,45,59;" +
    "1993-02-18,44,64;" +
    "1993-02-18,47,57;" +
    "1993-02-19,48,59;" +
    "1993-02-19,42,56;" +
    "1993-02-20,41,54;" +
    "1993-02-20,39,49;" +
    "1993-02-21,40,56;" +
    "1993-02-21,42,54;" +
    "1993-02-22,40,56;" +
    "1993-02-22,46,54;" +
    "1993-02-23,44,56;" +
    "1993-02-23,45,53;" +
    "1993-02-24,37,55;" +
    "1993-02-24,39,51;" +
    "1993-02-25,37,53;" +
    "1993-02-25,37,50;" +
    "1993-02-26,41,46;" +
    "1993-02-26,40,44;" +
    "1993-02-27,41,58;" +
    "1993-02-27,48,51;" +
    "1993-02-28,34,62;" +
    "1993-03-01,39,61;" +
    "1993-03-01,46,58;" +
    "1993-03-02,40,65;" +
    "1993-03-02,41,64;" +
    "1993-03-03,48,65;" +
    "1993-03-03,47,61;" +
    "1993-03-04,41,69;" +
    "1993-03-04,43,65;" +
    "1993-03-05,42,72;" +
    "1993-03-05,44,70;" +
    "1993-03-06,44,69;" +
    "1993-03-06,50,68;" +
    "1993-03-07,44,74;" +
    "1993-03-07,51,74;" +
    "1993-03-08,51,77;" +
    "1993-03-08,50,75;" +
    "1993-03-09,50,70;" +
    "1993-03-09,50,67;" +
    "1993-03-10,47,65;" +
    "1993-03-10,50,63;" +
    "1993-03-11,47,72;" +
    "1993-03-11,49,70;" +
    "1993-03-12,49,72;" +
    "1993-03-12,50,70;" +
    "1993-03-13,53,76;" +
    "1993-03-13,52,73;" +
    "1993-03-14,53,68;" +
    "1993-03-14,52,64;" +
    "1993-03-15,49,67;" +
    "1993-03-15,50,63;" +
    "1993-03-16,50,68;" +
    "1993-03-16,53,66;" +
    "1993-03-17,50,68;" +
    "1993-03-17,54,63;" +
    "1993-03-18,52,67;" +
    "1993-03-18,50,63;" +
    "1993-03-19,50,67;" +
    "1993-03-19,50,66;" +
    "1993-03-20,46,71;" +
    "1993-03-20,47,67;" +
    "1993-03-21,51,72;" +
    "1993-03-21,48,68;" +
    "1993-03-22,42,74;" +
    "1993-03-22,45,70;" +
    "1993-03-23,53,60;" +
    "1993-03-23,53,58;" +
    "1993-03-24,47,61;" +
    "1993-03-24,47,58;" +
    "1993-03-25,48,57;" +
    "1993-03-25,48,55;" +
    "1993-03-26,49,54;" +
    "1993-03-26,47,52;" +
    "1993-03-27,46,57;" +
    "1993-03-27,46,55;" +
    "1993-03-28,46,61;" +
    "1993-03-28,45,57;" +
    "1993-03-29,46,64;" +
    "1993-03-29,45,63;" +
    "1993-03-30,44,68;" +
    "1993-03-30,45,65;" +
    "1993-03-31,47,68;" +
    "1993-03-31,47,64;" +
    "1993-04-01,44,68;" +
    "1993-04-01,46,58;" +
    "1993-04-02,42,70;" +
    "1993-04-02,43,65;" +
    "1993-04-03,51,67;" +
    "1993-04-03,50,63;" +
    "1993-04-04,53,64;" +
    "1993-04-04,47,59;" +
    "1993-04-05,45,64;" +
    "1993-04-05,44,59;" +
    "1993-04-06,49,67;" +
    "1993-04-06,49,64;" +
    "1993-04-07,44,74;" +
    "1993-04-07,47,71;" +
    "1993-04-08,46,72;" +
    "1993-04-08,47,67;" +
    "1993-04-09,48,68;" +
    "1993-04-09,47,62;" +
    "1993-04-10,50,66;" +
    "1993-04-10,46,61;" +
    "1993-04-11,40,66;" +
    "1993-04-11,41,62;" +
    "1993-04-12,41,71;" +
    "1993-04-12,44,67;" +
    "1993-04-13,40,69;" +
    "1993-04-13,43,49;" +
    "1993-04-14,51,67;" +
    "1993-04-15,52,65;" +
    "1993-04-16,44,67;" +
    "1993-04-17,49,66;" +
    "1993-04-18,46,66;" +
    "1993-04-19,42,72;" +
    "1993-04-20,44,77;" +
    "1993-04-21,49,70;" +
    "1993-04-21,50,65;" +
    "1993-04-22,50,65;" +
    "1993-04-22,47,60;" +
    "1993-04-23,47,63;" +
    "1993-04-23,46,59;" +
    "1993-04-24,49,70;" +
    "1993-04-24,49,66;" +
    "1993-04-25,47,76;" +
    "1993-04-25,48,71;" +
    "1993-04-26,47,76;" +
    "1993-04-26,46,67;" +
    "1993-04-27,43,76;" +
    "1993-04-27,44,71;" +
    "1993-04-28,45,84;" +
    "1993-04-28,47,79;" +
    "1993-04-29,50,82;" +
    "1993-04-29,52,76;" +
    "1993-04-30,49,85;" +
    "1993-04-30,51,81;" +
    "1993-05-01,52,86;" +
    "1993-05-01,57,83;" +
    "1993-05-02,54,80;" +
    "1993-05-02,50,72;" +
    "1993-05-03,53,67;" +
    "1993-05-03,51,61;" +
    "1993-05-04,46,72;" +
    "1993-05-05,44,78;" +
    "1993-05-05,51,74;" +
    "1993-05-06,53,67;" +
    "1993-05-06,48,63;" +
    "1993-05-07,46,71;" +
    "1993-05-07,44,47;" +
    "1993-05-08,44,76;" +
    "1993-05-08,47,72;" +
    "1993-05-09,47,85;" +
    "1993-05-09,50,84;" +
    "1993-05-10,53,88;" +
    "1993-05-10,54,84;" +
    "1993-05-11,53,67;" +
    "1993-05-11,46,57;" +
    "1993-05-12,41,69;" +
    "1993-05-12,41,64;" +
    "1993-05-13,48,73;" +
    "1993-05-13,48,68;" +
    "1993-05-14,46,75;" +
    "1993-05-14,46,71;" +
    "1993-05-15,48,77;" +
    "1993-05-15,48,75;" +
    "1993-05-16,47,84;" +
    "1993-05-16,49,78;" +
    "1993-05-17,51,76;" +
    "1993-05-17,50,69;" +
    "1993-05-18,48,81;" +
    "1993-05-18,49,77;" +
    "1993-05-19,59,82;" +
    "1993-05-19,59,77;" +
    "1993-05-20,60,73;" +
    "1993-05-20,57,70;" +
    "1993-05-21,54,74;" +
    "1993-05-21,53,69;" +
    "1993-05-22,51,83;" +
    "1993-05-22,51,79;" +
    "1993-05-23,52,76;" +
    "1993-05-23,52,70;" +
    "1993-05-24,55,69;" +
    "1993-05-24,52,66;" +
    "1993-05-25,55,75;" +
    "1993-05-25,53,71;" +
    "1993-05-26,56,71;" +
    "1993-05-26,56,68;" +
    "1993-05-27,56,71;" +
    "1993-05-27,53,67;" +
    "1993-05-28,50,74;" +
    "1993-05-28,50,69;" +
    "1993-05-29,50,83;" +
    "1993-05-29,50,79;" +
    "1993-05-30,59,72;" +
    "1993-05-30,58,70;" +
    "1993-05-31,57,74;" +
    "1993-05-31,56,70;" +
    "1993-06-01,55,75;" +
    "1993-06-01,53,70;" +
    "1993-06-02,53,72;" +
    "1993-06-02,51,67;" +
    "1993-06-03,53,72;" +
    "1993-06-03,51,66;" +
    "1993-06-04,55,65;" +
    "1993-06-04,52,64;" +
    "1993-06-05,49,65;" +
    "1993-06-05,48,61;" +
    "1993-06-06,53,65;" +
    "1993-06-06,52,62;" +
    "1993-06-07,56,72;" +
    "1993-06-07,53,68;" +
    "1993-06-08,51,82;" +
    "1993-06-08,51,77;" +
    "1993-06-09,52,89;" +
    "1993-06-09,52,77;" +
    "1993-06-10,58,81;" +
    "1993-06-10,52,68;" +
    "1993-06-11,51,81;" +
    "1993-06-11,49,76;" +
    "1993-06-12,50,85;" +
    "1993-06-12,51,80;" +
    "1993-06-13,51,91;" +
    "1993-06-13,51,86;" +
    "1993-06-14,56,93;" +
    "1993-06-14,58,88;" +
    "1993-06-15,54,83;" +
    "1993-06-15,55,56;" +
    "1993-06-16,54,90;" +
    "1993-06-17,74,95;" +
    "1993-06-17,73,92;" +
    "1993-06-18,62,100;" +
    "1993-06-18,66,95;" +
    "1993-06-19,62,100;" +
    "1993-06-19,63,96;" +
    "1993-06-20,58,92;" +
    "1993-06-20,53,79;" +
    "1993-06-21,54,76;" +
    "1993-06-21,51,70;" +
    "1993-06-22,50,80;" +
    "1993-06-22,48,76;" +
    "1993-06-23,51,92;" +
    "1993-06-23,51,89;" +
    "1993-06-24,64,94;" +
    "1993-06-24,69,94;" +
    "1993-06-25,62,105;" +
    "1993-06-25,69,102;" +
    "1993-06-26,63,101;" +
    "1993-06-26,67,97;" +
    "1993-06-27,60,93;" +
    "1993-06-27,52,82;" +
    "1993-06-28,53,80;" +
    "1993-06-28,50,75;" +
    "1993-06-29,53,92;" +
    "1993-06-29,54,88;" +
    "1993-06-30,57,95;" +
    "1993-06-30,59,92;" +
    "1993-07-01,59,96;" +
    "1993-07-01,59,91;" +
    "1993-07-02,57,88;" +
    "1993-07-02,53,75;" +
    "1993-07-03,52,90;" +
    "1993-07-03,52,85;" +
    "1993-07-04,54,92;" +
    "1993-07-04,54,88;" +
    "1993-07-05,58,92;" +
    "1993-07-05,58,88;" +
    "1993-07-06,57,91;" +
    "1993-07-06,56,87;" +
    "1993-07-07,56,91;" +
    "1993-07-07,55,86;" +
    "1993-07-08,54,88;" +
    "1993-07-08,54,82;" +
    "1993-07-09,55,84;" +
    "1993-07-09,54,78;" +
    "1993-07-10,55,87;" +
    "1993-07-10,55,82;" +
    "1993-07-11,56,89;" +
    "1993-07-11,55,85;" +
    "1993-07-12,56,88;" +
    "1993-07-12,56,83;" +
    "1993-07-13,55,86;" +
    "1993-07-13,54,81;" +
    "1993-07-14,53,84;" +
    "1993-07-14,53,79;" +
    "1993-07-15,52,81;" +
    "1993-07-15,52,77;" +
    "1993-07-16,53,87;" +
    "1993-07-16,52,83;" +
    "1993-07-17,51,91;" +
    "1993-07-17,50,88;" +
    "1993-07-18,55,92;" +
    "1993-07-18,55,90;" +
    "1993-07-19,54,82;" +
    "1993-07-19,53,75;" +
    "1993-07-20,53,82;" +
    "1993-07-20,52,77;" +
    "1993-07-21,53,82;" +
    "1993-07-21,53,78;" +
    "1993-07-22,57,87;" +
    "1993-07-22,56,83;" +
    "1993-07-23,60,89;" +
    "1993-07-23,59,85;" +
    "1993-07-24,62,90;" +
    "1993-07-24,62,87;" +
    "1993-07-25,60,87;" +
    "1993-07-25,59,82;" +
    "1993-07-26,58,88;" +
    "1993-07-26,56,82;" +
    "1993-07-27,57,83;" +
    "1993-07-27,56,79;" +
    "1993-07-28,57,77;" +
    "1993-07-28,56,73;" +
    "1993-07-29,58,77;" +
    "1993-07-29,56,74;" +
    "1993-07-30,51,92;" +
    "1993-07-30,52,93;" +
    "1993-07-31,59,103;" +
    "1993-07-31,63,101;" +
    "1993-08-01,64,107;" +
    "1993-08-01,70,104;" +
    "1993-08-02,67,101;" +
    "1993-08-02,72,99;" +
    "1993-08-03,62,97;" +
    "1993-08-03,59,95;" +
    "1993-08-04,59,91;" +
    "1993-08-04,59,83;" +
    "1993-08-05,60,85;" +
    "1993-08-05,57,81;" +
    "1993-08-06,56,79;" +
    "1993-08-06,55,74;" +
    "1993-08-07,57,77;" +
    "1993-08-07,56,72;" +
    "1993-08-08,53,81;" +
    "1993-08-08,54,78;" +
    "1993-08-09,54,84;" +
    "1993-08-09,54,80;" +
    "1993-08-10,57,74;" +
    "1993-08-10,56,69;" +
    "1993-08-11,59,75;" +
    "1993-08-11,56,72;" +
    "1993-08-12,53,83;" +
    "1993-08-12,54,80;" +
    "1993-08-13,57,78;" +
    "1993-08-13,56,74;" +
    "1993-08-14,57,81;" +
    "1993-08-14,55,76;" +
    "1993-08-15,58,76;" +
    "1993-08-15,55,72;" +
    "1993-08-16,51,85;" +
    "1993-08-16,53,85;" +
    "1993-08-17,56,91;" +
    "1993-08-17,60,87;" +
    "1993-08-18,56,91;" +
    "1993-08-18,56,88;" +
    "1993-08-19,56,81;" +
    "1993-08-19,55,72;" +
    "1993-08-20,59,80;" +
    "1993-08-20,58,77;" +
    "1993-08-21,55,92;" +
    "1993-08-21,56,91;" +
    "1993-08-22,60,99;" +
    "1993-08-22,65,97;" +
    "1993-08-23,59,93;" +
    "1993-08-23,61,91;" +
    "1993-08-24,53,94;" +
    "1993-08-24,54,92;" +
    "1993-08-25,62,90;" +
    "1993-08-25,65,90;" +
    "1993-08-26,56,96;" +
    "1993-08-26,61,95;" +
    "1993-08-27,59,100;" +
    "1993-08-27,66,98;" +
    "1993-08-28,60,97;" +
    "1993-08-28,68,95;" +
    "1993-08-29,56,91;" +
    "1993-08-29,58,87;" +
    "1993-08-30,55,79;" +
    "1993-08-30,55,77;" +
    "1993-08-31,53,86;" +
    "1993-08-31,54,82;" +
    "1993-09-01,58,96;" +
    "1993-09-01,57,93;" +
    "1993-09-02,62,98;" +
    "1993-09-02,65,98;" +
    "1993-09-03,59,92;" +
    "1993-09-03,57,85;" +
    "1993-09-04,57,81;" +
    "1993-09-04,56,77;" +
    "1993-09-05,57,75;" +
    "1993-09-05,56,72;" +
    "1993-09-06,57,78;" +
    "1993-09-06,55,75;" +
    "1993-09-07,52,93;" +
    "1993-09-07,52,93;" +
    "1993-09-08,58,98;" +
    "1993-09-08,64,95;" +
    "1993-09-09,57,95;" +
    "1993-09-09,57,93;" +
    "1993-09-10,56,87;" +
    "1993-09-10,55,84;" +
    "1993-09-11,54,77;" +
    "1993-09-11,53,71;" +
    "1993-09-12,53,87;" +
    "1993-09-12,52,84;" +
    "1993-09-13,51,90;" +
    "1993-09-13,55,87;" +
    "1993-09-14,53,80;" +
    "1993-09-14,53,74;" +
    "1993-09-15,56,74;" +
    "1993-09-15,53,72;" +
    "1993-09-16,51,71;" +
    "1993-09-16,52,69;" +
    "1993-09-17,55,69;" +
    "1993-09-17,52,68;" +
    "1993-09-18,51,79;" +
    "1993-09-18,52,77;" +
    "1993-09-19,48,82;" +
    "1993-09-19,49,83;" +
    "1993-09-20,54,86;" +
    "1993-09-20,57,84;" +
    "1993-09-21,51,80;" +
    "1993-09-21,51,79;" +
    "1993-09-22,48,78;" +
    "1993-09-22,49,76;" +
    "1993-09-23,49,87;" +
    "1993-09-23,49,86;" +
    "1993-09-24,48,90;" +
    "1993-09-24,54,90;" +
    "1993-09-25,49,91;" +
    "1993-09-25,57,91;" +
    "1993-09-26,51,93;" +
    "1993-09-26,61,94;" +
    "1993-09-27,52,94;" +
    "1993-09-27,61,95;" +
    "1993-09-28,55,94;" +
    "1993-09-28,62,95;" +
    "1993-09-29,55,93;" +
    "1993-09-29,62,91;" +
    "1993-09-30,58,91;" +
    "1993-09-30,57,89;" +
    "1993-10-01,56,88;" +
    "1993-10-01,57,86;" +
    "1993-10-02,53,83;" +
    "1993-10-02,52,79;" +
    "1993-10-03,53,73;" +
    "1993-10-03,52,71;" +
    "1993-10-04,55,67;" +
    "1993-10-04,53,63;" +
    "1993-10-05,55,64;" +
    "1993-10-05,54,63;" +
    "1993-10-06,53,73;" +
    "1993-10-06,54,71;" +
    "1993-10-07,54,80;" +
    "1993-10-07,55,79;" +
    "1993-10-08,50,79;" +
    "1993-10-08,53,78;" +
    "1993-10-09,56,76;" +
    "1993-10-09,59,75;" +
    "1993-10-10,58,77;" +
    "1993-10-10,57,76;" +
    "1993-10-11,50,70;" +
    "1993-10-11,56,69;" +
    "1993-10-12,51,78;" +
    "1993-10-12,53,77;" +
    "1993-10-13,56,74;" +
    "1993-10-13,56,72;" +
    "1993-10-14,56,72;" +
    "1993-10-14,57,71;" +
    "1993-10-15,57,67;" +
    "1993-10-15,55,66;" +
    "1993-10-16,53,72;" +
    "1993-10-16,52,69;" +
    "1993-10-17,46,70;" +
    "1993-10-17,49,69;" +
    "1993-10-18,44,74;" +
    "1993-10-18,41,73;" +
    "1993-10-19,45,76;" +
    "1993-10-19,49,79;" +
    "1993-10-20,46,81;" +
    "1993-10-20,52,81;" +
    "1993-10-21,54,75;" +
    "1993-10-21,55,74;" +
    "1993-10-22,46,79;" +
    "1993-10-22,51,80;" +
    "1993-10-23,47,81;" +
    "1993-10-23,55,82;" +
    "1993-10-24,48,82;" +
    "1993-10-24,54,82;" +
    "1993-10-25,49,84;" +
    "1993-10-25,58,84;" +
    "1993-10-26,55,86;" +
    "1993-10-26,65,86;" +
    "1993-10-27,59,85;" +
    "1993-10-27,61,85;" +
    "1993-10-28,48,82;" +
    "1993-10-28,54,81;" +
    "1993-10-29,52,78;" +
    "1993-10-29,61,79;" +
    "1993-10-30,49,83;" +
    "1993-10-30,55,84;" +
    "1993-10-31,54,74;" +
    "1993-10-31,51,72;" +
    "1993-11-01,48,77;" +
    "1993-11-01,51,76;" +
    "1993-11-02,53,78;" +
    "1993-11-02,57,81;" +
    "1993-11-03,42,82;" +
    "1993-11-03,50,81;" +
    "1993-11-04,43,78;" +
    "1993-11-04,50,78;" +
    "1993-11-05,45,77;" +
    "1993-11-05,53,78;" +
    "1993-11-06,45,73;" +
    "1993-11-06,51,73;" +
    "1993-11-07,40,75;" +
    "1993-11-07,48,75;" +
    "1993-11-08,41,75;" +
    "1993-11-08,46,75;" +
    "1993-11-09,42,66;" +
    "1993-11-09,47,65;" +
    "1993-11-10,48,67;" +
    "1993-11-10,49,65;" +
    "1993-11-11,46,61;" +
    "1993-11-11,47,59;" +
    "1993-11-12,41,59;" +
    "1993-11-12,45,60;" +
    "1993-11-13,40,62;" +
    "1993-11-13,46,62;" +
    "1993-11-14,42,62;" +
    "1993-11-14,48,60;" +
    "1993-11-15,40,66;" +
    "1993-11-15,45,66;" +
    "1993-11-16,32,61;" +
    "1993-11-16,39,64;" +
    "1993-11-17,36,64;" +
    "1993-11-17,42,63;" +
    "1993-11-18,44,62;" +
    "1993-11-18,45,62;" +
    "1993-11-19,43,63;" +
    "1993-11-19,44,67;" +
    "1993-11-20,36,64;" +
    "1993-11-20,43,64;" +
    "1993-11-21,39,55;" +
    "1993-11-21,41,54;" +
    "1993-11-22,39,61;" +
    "1993-11-22,42,60;" +
    "1993-11-23,33,55;" +
    "1993-11-23,36,55;" +
    "1993-11-24,33,53;" +
    "1993-11-24,36,53;" +
    "1993-11-25,33,54;" +
    "1993-11-25,38,53;" +
    "1993-11-26,33,57;" +
    "1993-11-26,40,58;" +
    "1993-11-27,37,54;" +
    "1993-11-27,46,55;" +
    "1993-11-28,45,51;" +
    "1993-11-28,45,56;" +
    "1993-11-29,45,62;" +
    "1993-11-29,50,62;" +
    "1993-11-30,44,60;" +
    "1993-11-30,44,59;" +
    "1993-12-01,34,64;" +
    "1993-12-01,40,62;" +
    "1993-12-02,39,67;" +
    "1993-12-02,43,68;" +
    "1993-12-03,45,66;" +
    "1993-12-03,49,67;" +
    "1993-12-04,45,63;" +
    "1993-12-04,46,65;" +
    "1993-12-05,35,62;" +
    "1993-12-05,41,61;" +
    "1993-12-06,38,62;" +
    "1993-12-06,40,59;" +
    "1993-12-07,40,60;" +
    "1993-12-07,43,60;" +
    "1993-12-08,53,61;" +
    "1993-12-08,50,59;" +
    "1993-12-09,51,64;" +
    "1993-12-09,51,62;" +
    "1993-12-10,45,65;" +
    "1993-12-10,50,64;" +
    "1993-12-11,47,58;" +
    "1993-12-11,44,58;" +
    "1993-12-12,37,54;" +
    "1993-12-12,40,53;" +
    "1993-12-13,39,60;" +
    "1993-12-13,40,59;" +
    "1993-12-14,46,55;" +
    "1993-12-14,41,53;" +
    "1993-12-15,35,55;" +
    "1993-12-15,35,55;" +
    "1993-12-16,31,53;" +
    "1993-12-16,34,51;" +
    "1993-12-17,36,44;" +
    "1993-12-17,37,45;" +
    "1993-12-18,37,48;" +
    "1993-12-18,36,48;" +
    "1993-12-19,29,53;" +
    "1993-12-19,34,55;" +
    "1993-12-20,30,44;" +
    "1993-12-20,31,41;" +
    "1993-12-21,30,42;" +
    "1993-12-21,29,43;" +
    "1993-12-22,31,37;" +
    "1993-12-22,29,37;" +
    "1993-12-23,31,50;" +
    "1993-12-23,39,43;" +
    "1993-12-24,27,51;" +
    "1993-12-24,38,51;" +
    "1993-12-25,27,44;" +
    "1993-12-25,28,44;" +
    "1993-12-26,34,42;" +
    "1993-12-26,34,43;" +
    "1993-12-27,37,57;" +
    "1993-12-27,39,57;" +
    "1993-12-28,35,57;" +
    "1993-12-28,41,59;" +
    "1993-12-29,37,56;" +
    "1993-12-29,44,58;" +
    "1993-12-30,38,63;" +
    "1993-12-30,41,62;" +
    "1993-12-31,38,53;" +
    "1993-12-31,41,54;"