export const data = "2008-01-01,33,52;" +
    "2008-01-01,35,50;" +
    "2008-01-02,32,59;" +
    "2008-01-02,31,59;" +
    "2008-01-03,41,57;" +
    "2008-01-03,41,56;" +
    "2008-01-04,48,59;" +
    "2008-01-04,48,59;" +
    "2008-01-05,38,53;" +
    "2008-01-05,40,54;" +
    "2008-01-06,40,52;" +
    "2008-01-06,40,53;" +
    "2008-01-07,39,52;" +
    "2008-01-07,39,54;" +
    "2008-01-08,38,56;" +
    "2008-01-08,37,56;" +
    "2008-01-09,39,50;" +
    "2008-01-09,41,50;" +
    "2008-01-10,45,51;" +
    "2008-01-10,44,50;" +
    "2008-01-11,47,53;" +
    "2008-01-11,47,53;" +
    "2008-01-12,40,57;" +
    "2008-01-12,44,57;" +
    "2008-01-13,38,57;" +
    "2008-01-13,44,56;" +
    "2008-01-14,34,54;" +
    "2008-01-14,38,53;" +
    "2008-01-15,34,58;" +
    "2008-01-15,33,58;" +
    "2008-01-16,42,55;" +
    "2008-01-16,40,54;" +
    "2008-01-17,34,56;" +
    "2008-01-17,41,53;" +
    "2008-01-18,32,56;" +
    "2008-01-18,34,55;" +
    "2008-01-19,30,62;" +
    "2008-01-19,31,64;" +
    "2008-01-20,34,52;" +
    "2008-01-20,35,52;" +
    "2008-01-21,41,50;" +
    "2008-01-21,40,50;" +
    "2008-01-22,39,47;" +
    "2008-01-22,39,47;" +
    "2008-01-23,40,43;" +
    "2008-01-23,38,42;" +
    "2008-01-24,37,48;" +
    "2008-01-24,37,46;" +
    "2008-01-25,45,57;" +
    "2008-01-25,39,53;" +
    "2008-01-26,52,63;" +
    "2008-01-26,53,63;" +
    "2008-01-27,43,58;" +
    "2008-01-27,47,59;" +
    "2008-01-28,36,52;" +
    "2008-01-28,39,53;" +
    "2008-01-29,33,50;" +
    "2008-01-29,32,48;" +
    "2008-01-30,36,54;" +
    "2008-01-30,36,54;" +
    "2008-01-31,40,53;" +
    "2008-01-31,38,52;" +
    "2008-02-01,36,53;" +
    "2008-02-01,35,54;" +
    "2008-02-02,35,51;" +
    "2008-02-02,37,50;" +
    "2008-02-03,41,53;" +
    "2008-02-03,45,52;" +
    "2008-02-04,30,54;" +
    "2008-02-04,32,54;" +
    "2008-02-05,33,57;" +
    "2008-02-05,34,59;" +
    "2008-02-06,38,56;" +
    "2008-02-06,40,56;" +
    "2008-02-07,34,61;" +
    "2008-02-07,35,63;" +
    "2008-02-08,36,63;" +
    "2008-02-08,35,63;" +
    "2008-02-09,36,64;" +
    "2008-02-09,40,64;" +
    "2008-02-10,36,69;" +
    "2008-02-10,38,68;" +
    "2008-02-11,39,69;" +
    "2008-02-11,40,68;" +
    "2008-02-12,39,69;" +
    "2008-02-12,40,68;" +
    "2008-02-13,42,63;" +
    "2008-02-13,44,64;" +
    "2008-02-14,39,59;" +
    "2008-02-14,44,58;" +
    "2008-02-15,33,65;" +
    "2008-02-15,37,66;" +
    "2008-02-16,33,64;" +
    "2008-02-16,35,64;" +
    "2008-02-17,35,63;" +
    "2008-02-17,36,66;" +
    "2008-02-18,44,59;" +
    "2008-02-18,44,61;" +
    "2008-02-19,46,57;" +
    "2008-02-19,45,57;" +
    "2008-02-20,46,57;" +
    "2008-02-20,46,59;" +
    "2008-02-21,42,58;" +
    "2008-02-21,43,57;" +
    "2008-02-22,46,53;" +
    "2008-02-22,43,55;" +
    "2008-02-23,35,53;" +
    "2008-02-23,35,54;" +
    "2008-02-24,47,56;" +
    "2008-02-24,46,56;" +
    "2008-02-25,45,59;" +
    "2008-02-25,44,61;" +
    "2008-02-26,37,63;" +
    "2008-02-26,37,63;" +
    "2008-02-27,42,70;" +
    "2008-02-27,42,70;" +
    "2008-02-28,42,73;" +
    "2008-02-28,42,72;" +
    "2008-02-29,42,69;" +
    "2008-02-29,42,71;" +
    "2008-03-01,45,61;" +
    "2008-03-01,48,62;" +
    "2008-03-02,43,64;" +
    "2008-03-02,43,64;" +
    "2008-03-03,39,68;" +
    "2008-03-03,41,68;" +
    "2008-03-04,37,69;" +
    "2008-03-04,37,70;" +
    "2008-03-05,39,66;" +
    "2008-03-05,41,67;" +
    "2008-03-06,35,63;" +
    "2008-03-06,38,63;" +
    "2008-03-07,36,68;" +
    "2008-03-07,38,69;" +
    "2008-03-08,41,69;" +
    "2008-03-08,42,70;" +
    "2008-03-09,39,71;" +
    "2008-03-09,39,72;" +
    "2008-03-10,42,73;" +
    "2008-03-10,44,75;" +
    "2008-03-11,43,67;" +
    "2008-03-11,44,69;" +
    "2008-03-12,44,64;" +
    "2008-03-12,43,66;" +
    "2008-03-13,48,61;" +
    "2008-03-13,52,64;" +
    "2008-03-14,45,59;" +
    "2008-03-14,45,60;" +
    "2008-03-15,42,57;" +
    "2008-03-15,41,59;" +
    "2008-03-16,40,61;" +
    "2008-03-16,40,61;" +
    "2008-03-17,37,69;" +
    "2008-03-17,39,71;" +
    "2008-03-18,39,71;" +
    "2008-03-18,42,73;" +
    "2008-03-19,47,61;" +
    "2008-03-19,46,66;" +
    "2008-03-20,40,61;" +
    "2008-03-20,42,64;" +
    "2008-03-21,35,67;" +
    "2008-03-21,37,66;" +
    "2008-03-22,36,74;" +
    "2008-03-22,36,75;" +
    "2008-03-23,37,75;" +
    "2008-03-23,39,77;" +
    "2008-03-24,38,73;" +
    "2008-03-24,40,75;" +
    "2008-03-25,47,68;" +
    "2008-03-25,47,70;" +
    "2008-03-26,39,62;" +
    "2008-03-26,42,64;" +
    "2008-03-27,36,62;" +
    "2008-03-27,39,63;" +
    "2008-03-28,40,64;" +
    "2008-03-28,44,65;" +
    "2008-03-29,44,61;" +
    "2008-03-29,50,61;" +
    "2008-03-30,43,59;" +
    "2008-03-30,42,62;" +
    "2008-03-31,33,62;" +
    "2008-03-31,34,63;" +
    "2008-04-01,40,67;" +
    "2008-04-01,42,69;" +
    "2008-04-02,40,66;" +
    "2008-04-02,42,67;" +
    "2008-04-03,42,70;" +
    "2008-04-03,43,72;" +
    "2008-04-04,40,64;" +
    "2008-04-04,42,65;" +
    "2008-04-05,43,61;" +
    "2008-04-05,45,64;" +
    "2008-04-06,44,61;" +
    "2008-04-06,46,62;" +
    "2008-04-07,36,64;" +
    "2008-04-07,37,66;" +
    "2008-04-08,44,58;" +
    "2008-04-08,45,59;" +
    "2008-04-09,43,63;" +
    "2008-04-09,44,64;" +
    "2008-04-10,38,74;" +
    "2008-04-10,40,77;" +
    "2008-04-11,42,79;" +
    "2008-04-11,44,80;" +
    "2008-04-12,45,85;" +
    "2008-04-12,47,85;" +
    "2008-04-13,47,89;" +
    "2008-04-13,50,92;" +
    "2008-04-14,45,62;" +
    "2008-04-14,48,82;" +
    "2008-04-15,39,64;" +
    "2008-04-15,40,65;" +
    "2008-04-16,36,74;" +
    "2008-04-16,38,75;" +
    "2008-04-17,41,81;" +
    "2008-04-17,42,83;" +
    "2008-04-18,44,73;" +
    "2008-04-18,48,79;" +
    "2008-04-19,42,58;" +
    "2008-04-19,43,60;" +
    "2008-04-20,36,58;" +
    "2008-04-20,37,59;" +
    "2008-04-21,38,58;" +
    "2008-04-21,38,60;" +
    "2008-04-22,42,61;" +
    "2008-04-22,41,62;" +
    "2008-04-23,41,63;" +
    "2008-04-23,48,64;" +
    "2008-04-24,34,71;" +
    "2008-04-24,35,72;" +
    "2008-04-25,40,78;" +
    "2008-04-25,42,80;" +
    "2008-04-26,45,86;" +
    "2008-04-26,46,87;" +
    "2008-04-27,49,90;" +
    "2008-04-27,52,92;" +
    "2008-04-28,48,81;" +
    "2008-04-28,50,85;" +
    "2008-04-29,46,65;" +
    "2008-04-29,49,75;" +
    "2008-04-30,42,66;" +
    "2008-04-30,40,68;" +
    "2008-05-01,38,75;" +
    "2008-05-01,40,76;" +
    "2008-05-02,44,70;" +
    "2008-05-02,45,71;" +
    "2008-05-03,43,76;" +
    "2008-05-03,44,76;" +
    "2008-05-04,47,65;" +
    "2008-05-04,47,67;" +
    "2008-05-05,45,75;" +
    "2008-05-05,45,77;" +
    "2008-05-06,49,79;" +
    "2008-05-06,49,80;" +
    "2008-05-07,48,69;" +
    "2008-05-07,48,76;" +
    "2008-05-08,46,75;" +
    "2008-05-08,46,76;" +
    "2008-05-09,45,73;" +
    "2008-05-09,45,75;" +
    "2008-05-10,45,81;" +
    "2008-05-10,44,82;" +
    "2008-05-11,49,71;" +
    "2008-05-11,50,73;" +
    "2008-05-12,46,73;" +
    "2008-05-12,46,74;" +
    "2008-05-13,43,88;" +
    "2008-05-13,45,89;" +
    "2008-05-14,53,91;" +
    "2008-05-14,55,89;" +
    "2008-05-15,63,98;" +
    "2008-05-15,70,98;" +
    "2008-05-16,63,101;" +
    "2008-05-16,64,101;" +
    "2008-05-17,62,97;" +
    "2008-05-17,63,98;" +
    "2008-05-18,58,96;" +
    "2008-05-18,58,97;" +
    "2008-05-19,52,91;" +
    "2008-05-19,52,92;" +
    "2008-05-20,52,76;" +
    "2008-05-20,52,83;" +
    "2008-05-21,50,75;" +
    "2008-05-21,48,76;" +
    "2008-05-22,49,77;" +
    "2008-05-22,48,77;" +
    "2008-05-23,48,71;" +
    "2008-05-23,46,76;" +
    "2008-05-24,53,64;" +
    "2008-05-24,52,65;" +
    "2008-05-25,49,71;" +
    "2008-05-25,51,69;" +
    "2008-05-26,47,65;" +
    "2008-05-26,48,67;" +
    "2008-05-27,51,69;" +
    "2008-05-27,51,69;" +
    "2008-05-28,47,70;" +
    "2008-05-28,46,71;" +
    "2008-05-29,48,69;" +
    "2008-05-29,48,70;" +
    "2008-05-30,52,75;" +
    "2008-05-30,53,77;" +
    "2008-05-31,51,65;" +
    "2008-05-31,51,67;" +
    "2008-06-01,48,69;" +
    "2008-06-01,49,71;" +
    "2008-06-02,47,72;" +
    "2008-06-02,46,74;" +
    "2008-06-03,51,72;" +
    "2008-06-03,52,73;" +
    "2008-06-04,49,73;" +
    "2008-06-04,49,74;" +
    "2008-06-05,49,80;" +
    "2008-06-05,49,82;" +
    "2008-06-06,50,75;" +
    "2008-06-06,50,75;" +
    "2008-06-07,44,85;" +
    "2008-06-07,45,86;" +
    "2008-06-08,50,90;" +
    "2008-06-08,50,91;" +
    "2008-06-09,54,97;" +
    "2008-06-09,53,98;" +
    "2008-06-10,58,89;" +
    "2008-06-10,57,90;" +
    "2008-06-11,61,90;" +
    "2008-06-11,63,89;" +
    "2008-06-12,56,92;" +
    "2008-06-12,57,92;" +
    "2008-06-13,56,95;" +
    "2008-06-13,57,96;" +
    "2008-06-14,54,87;" +
    "2008-06-14,54,89;" +
    "2008-06-15,52,82;" +
    "2008-06-15,52,84;" +
    "2008-06-16,50,78;" +
    "2008-06-16,49,82;" +
    "2008-06-17,49,93;" +
    "2008-06-17,49,94;" +
    "2008-06-18,53,94;" +
    "2008-06-18,55,95;" +
    "2008-06-19,55,99;" +
    "2008-06-19,56,98;" +
    "2008-06-20,62,104;" +
    "2008-06-20,62,105;" +
    "2008-06-21,67,102;" +
    "2008-06-21,67,102;" +
    "2008-06-22,56,87;" +
    "2008-06-22,60,91;" +
    "2008-06-23,52,74;" +
    "2008-06-23,50,84;" +
    "2008-06-24,52,87;" +
    "2008-06-24,52,87;" +
    "2008-06-25,52,76;" +
    "2008-06-25,51,78;" +
    "2008-06-26,52,87;" +
    "2008-06-26,51,88;" +
    "2008-06-27,59,90;" +
    "2008-06-27,60,91;" +
    "2008-06-28,55,81;" +
    "2008-06-28,55,83;" +
    "2008-06-29,54,85;" +
    "2008-06-29,54,87;" +
    "2008-06-30,53,78;" +
    "2008-06-30,53,80;" +
    "2008-07-01,56,81;" +
    "2008-07-01,56,82;" +
    "2008-07-02,53,85;" +
    "2008-07-02,53,86;" +
    "2008-07-03,56,85;" +
    "2008-07-03,57,85;" +
    "2008-07-04,57,76;" +
    "2008-07-04,57,79;" +
    "2008-07-05,55,92;" +
    "2008-07-05,55,92;" +
    "2008-07-06,58,96;" +
    "2008-07-06,57,97;" +
    "2008-07-07,64,104;" +
    "2008-07-07,63,105;" +
    "2008-07-08,68,109;" +
    "2008-07-08,68,110;" +
    "2008-07-09,69,105;" +
    "2008-07-09,68,106;" +
    "2008-07-10,66,101;" +
    "2008-07-10,66,101;" +
    "2008-07-11,61,90;" +
    "2008-07-11,60,91;" +
    "2008-07-12,60,87;" +
    "2008-07-12,60,89;" +
    "2008-07-13,58,90;" +
    "2008-07-13,58,92;" +
    "2008-07-14,59,82;" +
    "2008-07-14,59,85;" +
    "2008-07-15,57,78;" +
    "2008-07-15,58,81;" +
    "2008-07-16,57,86;" +
    "2008-07-16,57,88;" +
    "2008-07-17,55,83;" +
    "2008-07-17,55,85;" +
    "2008-07-18,52,88;" +
    "2008-07-18,52,91;" +
    "2008-07-19,54,86;" +
    "2008-07-19,53,88;" +
    "2008-07-20,53,71;" +
    "2008-07-20,53,77;" +
    "2008-07-21,53,71;" +
    "2008-07-21,53,72;" +
    "2008-07-22,52,92;" +
    "2008-07-22,52,93;" +
    "2008-07-23,60,92;" +
    "2008-07-23,58,93;" +
    "2008-07-24,54,89;" +
    "2008-07-24,53,93;" +
    "2008-07-25,55,92;" +
    "2008-07-25,55,93;" +
    "2008-07-26,57,97;" +
    "2008-07-26,57,97;" +
    "2008-07-27,55,80;" +
    "2008-07-27,55,89;" +
    "2008-07-28,54,78;" +
    "2008-07-28,54,80;" +
    "2008-07-29,55,81;" +
    "2008-07-29,54,82;" +
    "2008-07-30,55,87;" +
    "2008-07-30,54,89;" +
    "2008-07-31,55,86;" +
    "2008-07-31,55,88;" +
    "2008-08-01,55,90;" +
    "2008-08-01,53,91;" +
    "2008-08-02,57,93;" +
    "2008-08-02,53,93;" +
    "2008-08-03,52,81;" +
    "2008-08-03,52,93;" +
    "2008-08-04,53,86;" +
    "2008-08-04,52,89;" +
    "2008-08-05,54,82;" +
    "2008-08-05,53,84;" +
    "2008-08-06,57,86;" +
    "2008-08-06,56,89;" +
    "2008-08-07,55,81;" +
    "2008-08-07,54,84;" +
    "2008-08-08,54,77;" +
    "2008-08-08,55,80;" +
    "2008-08-09,54,81;" +
    "2008-08-09,54,83;" +
    "2008-08-10,51,93;" +
    "2008-08-10,52,94;" +
    "2008-08-11,59,97;" +
    "2008-08-11,58,98;" +
    "2008-08-12,58,96;" +
    "2008-08-12,56,97;" +
    "2008-08-13,62,102;" +
    "2008-08-13,61,104;" +
    "2008-08-14,62,99;" +
    "2008-08-14,62,101;" +
    "2008-08-15,63,98;" +
    "2008-08-15,62,99;" +
    "2008-08-16,58,87;" +
    "2008-08-16,63,94;" +
    "2008-08-17,57,86;" +
    "2008-08-17,57,88;" +
    "2008-08-18,58,75;" +
    "2008-08-18,57,77;" +
    "2008-08-19,58,77;" +
    "2008-08-19,58,78;" +
    "2008-08-20,56,83;" +
    "2008-08-20,56,84;" +
    "2008-08-21,59,80;" +
    "2008-08-21,56,84;" +
    "2008-08-22,57,90;" +
    "2008-08-22,54,91;" +
    "2008-08-23,58,89;" +
    "2008-08-23,57,90;" +
    "2008-08-24,57,94;" +
    "2008-08-24,57,96;" +
    "2008-08-25,58,89;" +
    "2008-08-25,57,90;" +
    "2008-08-26,54,93;" +
    "2008-08-26,53,94;" +
    "2008-08-27,59,102;" +
    "2008-08-27,58,103;" +
    "2008-08-28,65,104;" +
    "2008-08-28,64,105;" +
    "2008-08-29,65,104;" +
    "2008-08-29,65,104;" +
    "2008-08-30,57,90;" +
    "2008-08-30,60,94;" +
    "2008-08-31,53,81;" +
    "2008-08-31,52,82;" +
    "2008-09-01,54,87;" +
    "2008-09-01,54,86;" +
    "2008-09-02,54,95;" +
    "2008-09-02,53,95;" +
    "2008-09-03,57,99;" +
    "2008-09-03,56,100;" +
    "2008-09-04,58,100;" +
    "2008-09-04,59,100;" +
    "2008-09-05,60,103;" +
    "2008-09-05,60,102;" +
    "2008-09-06,60,103;" +
    "2008-09-06,61,103;" +
    "2008-09-07,57,98;" +
    "2008-09-07,60,100;" +
    "2008-09-08,55,87;" +
    "2008-09-08,55,89;" +
    "2008-09-09,58,75;" +
    "2008-09-09,57,80;" +
    "2008-09-10,57,83;" +
    "2008-09-10,57,85;" +
    "2008-09-11,55,89;" +
    "2008-09-11,54,90;" +
    "2008-09-12,54,81;" +
    "2008-09-12,54,83;" +
    "2008-09-13,55,82;" +
    "2008-09-13,53,85;" +
    "2008-09-14,54,87;" +
    "2008-09-14,53,89;" +
    "2008-09-15,54,87;" +
    "2008-09-15,53,88;" +
    "2008-09-16,54,81;" +
    "2008-09-16,53,83;" +
    "2008-09-17,51,71;" +
    "2008-09-17,54,72;" +
    "2008-09-18,50,74;" +
    "2008-09-18,51,75;" +
    "2008-09-19,49,76;" +
    "2008-09-19,48,77;" +
    "2008-09-20,58,72;" +
    "2008-09-20,60,74;" +
    "2008-09-21,54,76;" +
    "2008-09-21,57,78;" +
    "2008-09-22,49,86;" +
    "2008-09-22,48,86;" +
    "2008-09-23,51,90;" +
    "2008-09-23,51,89;" +
    "2008-09-24,52,94;" +
    "2008-09-24,52,94;" +
    "2008-09-25,57,92;" +
    "2008-09-25,55,93;" +
    "2008-09-26,58,93;" +
    "2008-09-26,57,94;" +
    "2008-09-27,57,95;" +
    "2008-09-27,57,94;" +
    "2008-09-28,57,95;" +
    "2008-09-28,58,95;" +
    "2008-09-29,56,81;" +
    "2008-09-29,56,83;" +
    "2008-09-30,55,89;" +
    "2008-09-30,54,89;" +
    "2008-10-01,56,95;" +
    "2008-10-01,55,94;" +
    "2008-10-02,58,77;" +
    "2008-10-02,60,77;" +
    "2008-10-03,56,74;" +
    "2008-10-03,55,75;" +
    "2008-10-04,55,70;" +
    "2008-10-04,59,70;" +
    "2008-10-05,54,74;" +
    "2008-10-05,54,74;" +
    "2008-10-06,50,81;" +
    "2008-10-06,50,82;" +
    "2008-10-07,51,90;" +
    "2008-10-07,50,89;" +
    "2008-10-08,53,87;" +
    "2008-10-08,52,88;" +
    "2008-10-09,48,76;" +
    "2008-10-09,48,74;" +
    "2008-10-10,46,65;" +
    "2008-10-10,46,69;" +
    "2008-10-11,52,66;" +
    "2008-10-11,48,67;" +
    "2008-10-12,44,69;" +
    "2008-10-12,42,68;" +
    "2008-10-13,41,77;" +
    "2008-10-13,43,75;" +
    "2008-10-14,44,82;" +
    "2008-10-14,44,83;" +
    "2008-10-15,46,84;" +
    "2008-10-15,47,84;" +
    "2008-10-16,49,87;" +
    "2008-10-16,49,85;" +
    "2008-10-17,48,87;" +
    "2008-10-17,48,84;" +
    "2008-10-18,51,81;" +
    "2008-10-18,55,81;" +
    "2008-10-19,48,73;" +
    "2008-10-19,47,74;" +
    "2008-10-20,47,70;" +
    "2008-10-20,48,71;" +
    "2008-10-21,45,82;" +
    "2008-10-21,44,80;" +
    "2008-10-22,54,81;" +
    "2008-10-22,56,79;" +
    "2008-10-23,47,89;" +
    "2008-10-23,47,87;" +
    "2008-10-24,48,86;" +
    "2008-10-24,47,83;" +
    "2008-10-25,46,85;" +
    "2008-10-25,47,82;" +
    "2008-10-26,46,85;" +
    "2008-10-26,46,84;" +
    "2008-10-27,48,79;" +
    "2008-10-27,47,78;" +
    "2008-10-28,46,84;" +
    "2008-10-28,46,85;" +
    "2008-10-29,47,84;" +
    "2008-10-29,46,85;" +
    "2008-10-30,52,66;" +
    "2008-10-30,52,74;" +
    "2008-10-31,58,75;" +
    "2008-10-31,58,76;" +
    "2008-11-01,58,69;" +
    "2008-11-01,56,68;" +
    "2008-11-02,52,66;" +
    "2008-11-02,55,67;" +
    "2008-11-03,49,66;" +
    "2008-11-03,48,65;" +
    "2008-11-04,45,62;" +
    "2008-11-04,47,61;" +
    "2008-11-05,38,65;" +
    "2008-11-05,38,64;" +
    "2008-11-06,46,66;" +
    "2008-11-06,46,66;" +
    "2008-11-07,45,72;" +
    "2008-11-07,45,72;" +
    "2008-11-08,45,63;" +
    "2008-11-08,45,63;" +
    "2008-11-09,48,64;" +
    "2008-11-09,48,64;" +
    "2008-11-10,49,63;" +
    "2008-11-10,49,63;" +
    "2008-11-11,50,65;" +
    "2008-11-11,47,64;" +
    "2008-11-12,51,71;" +
    "2008-11-12,50,72;" +
    "2008-11-13,49,76;" +
    "2008-11-13,49,77;" +
    "2008-11-14,53,74;" +
    "2008-11-14,53,73;" +
    "2008-11-15,49,77;" +
    "2008-11-15,52,76;" +
    "2008-11-16,45,75;" +
    "2008-11-16,46,74;" +
    "2008-11-17,46,75;" +
    "2008-11-17,46,74;" +
    "2008-11-18,44,77;" +
    "2008-11-18,44,78;" +
    "2008-11-19,48,63;" +
    "2008-11-19,48,64;" +
    "2008-11-20,47,65;" +
    "2008-11-20,47,65;" +
    "2008-11-21,41,68;" +
    "2008-11-21,46,66;" +
    "2008-11-22,37,69;" +
    "2008-11-22,38,71;" +
    "2008-11-23,41,64;" +
    "2008-11-23,45,62;" +
    "2008-11-24,40,63;" +
    "2008-11-24,41,64;" +
    "2008-11-25,43,60;" +
    "2008-11-25,44,60;" +
    "2008-11-26,50,54;" +
    "2008-11-26,50,55;" +
    "2008-11-27,52,62;" +
    "2008-11-27,51,64;" +
    "2008-11-28,43,58;" +
    "2008-11-28,50,59;" +
    "2008-11-29,41,66;" +
    "2008-11-29,42,65;" +
    "2008-11-30,41,65;" +
    "2008-11-30,40,64;" +
    "2008-12-01,40,65;" +
    "2008-12-01,40,65;" +
    "2008-12-02,40,62;" +
    "2008-12-02,48,62;" +
    "2008-12-03,36,55;" +
    "2008-12-03,36,54;" +
    "2008-12-04,41,57;" +
    "2008-12-04,42,56;" +
    "2008-12-05,34,62;" +
    "2008-12-05,35,60;" +
    "2008-12-06,34,62;" +
    "2008-12-06,33,61;" +
    "2008-12-07,33,56;" +
    "2008-12-07,34,59;" +
    "2008-12-08,35,50;" +
    "2008-12-08,43,49;" +
    "2008-12-09,34,52;" +
    "2008-12-09,35,50;" +
    "2008-12-10,32,63;" +
    "2008-12-10,33,61;" +
    "2008-12-11,34,65;" +
    "2008-12-11,34,64;" +
    "2008-12-12,35,63;" +
    "2008-12-12,35,63;" +
    "2008-12-13,36,54;" +
    "2008-12-13,43,52;" +
    "2008-12-14,30,51;" +
    "2008-12-14,34,49;" +
    "2008-12-15,35,50;" +
    "2008-12-15,38,49;" +
    "2008-12-16,33,44;" +
    "2008-12-16,34,43;" +
    "2008-12-17,29,51;" +
    "2008-12-17,35,52;" +
    "2008-12-18,27,52;" +
    "2008-12-18,28,53;" +
    "2008-12-19,35,54;" +
    "2008-12-19,45,54;" +
    "2008-12-20,28,50;" +
    "2008-12-20,29,50;" +
    "2008-12-21,38,52;" +
    "2008-12-21,38,48;" +
    "2008-12-22,39,53;" +
    "2008-12-22,39,52;" +
    "2008-12-23,38,52;" +
    "2008-12-23,39,52;" +
    "2008-12-24,45,54;" +
    "2008-12-24,44,54;" +
    "2008-12-25,39,53;" +
    "2008-12-25,41,53;" +
    "2008-12-26,29,52;" +
    "2008-12-26,30,52;" +
    "2008-12-27,32,55;" +
    "2008-12-27,32,56;" +
    "2008-12-28,38,58;" +
    "2008-12-28,37,58;" +
    "2008-12-29,38,63;" +
    "2008-12-29,38,64;" +
    "2008-12-30,36,59;" +
    "2008-12-30,40,57;" +
    "2008-12-31,33,60;" +
    "2008-12-31,33,62;"