export const data = "2009-01-01,37,49;" +
    "2009-01-01,39,49;" +
    "2009-01-02,39,54;" +
    "2009-01-02,38,54;" +
    "2009-01-03,29,52;" +
    "2009-01-03,33,52;" +
    "2009-01-04,26,52;" +
    "2009-01-04,27,52;" +
    "2009-01-05,38,45;" +
    "2009-01-05,37,43;" +
    "2009-01-06,39,57;" +
    "2009-01-06,40,58;" +
    "2009-01-07,37,49;" +
    "2009-01-07,37,48;" +
    "2009-01-08,35,56;" +
    "2009-01-08,40,56;" +
    "2009-01-09,31,56;" +
    "2009-01-09,31,55;" +
    "2009-01-10,34,61;" +
    "2009-01-10,38,61;" +
    "2009-01-11,32,70;" +
    "2009-01-11,39,71;" +
    "2009-01-12,44,70;" +
    "2009-01-12,50,69;" +
    "2009-01-13,40,74;" +
    "2009-01-13,42,73;" +
    "2009-01-14,36,69;" +
    "2009-01-14,37,68;" +
    "2009-01-15,37,71;" +
    "2009-01-15,41,70;" +
    "2009-01-16,34,70;" +
    "2009-01-16,36,69;" +
    "2009-01-17,34,69;" +
    "2009-01-17,35,68;" +
    "2009-01-18,35,65;" +
    "2009-01-18,36,64;" +
    "2009-01-19,34,67;" +
    "2009-01-19,36,66;" +
    "2009-01-20,35,69;" +
    "2009-01-20,36,67;" +
    "2009-01-21,41,56;" +
    "2009-01-21,43,57;" +
    "2009-01-22,49,54;" +
    "2009-01-22,47,53;" +
    "2009-01-23,50,57;" +
    "2009-01-23,49,56;" +
    "2009-01-24,46,55;" +
    "2009-01-24,50,57;" +
    "2009-01-25,39,56;" +
    "2009-01-25,44,55;" +
    "2009-01-26,34,55;" +
    "2009-01-26,34,55;" +
    "2009-01-27,31,55;" +
    "2009-01-27,34,56;" +
    "2009-01-28,30,63;" +
    "2009-01-28,31,64;" +
    "2009-01-29,37,64;" +
    "2009-01-29,41,64;" +
    "2009-01-30,35,62;" +
    "2009-01-30,35,62;" +
    "2009-01-31,33,69;" +
    "2009-01-31,34,71;" +
    "2009-02-01,37,65;" +
    "2009-02-01,39,64;" +
    "2009-02-02,34,62;" +
    "2009-02-02,35,62;" +
    "2009-02-03,36,63;" +
    "2009-02-03,37,63;" +
    "2009-02-04,36,65;" +
    "2009-02-04,37,65;" +
    "2009-02-05,48,60;" +
    "2009-02-05,48,61;" +
    "2009-02-06,48,54;" +
    "2009-02-06,47,54;" +
    "2009-02-07,41,63;" +
    "2009-02-07,45,64;" +
    "2009-02-08,37,55;" +
    "2009-02-08,38,57;" +
    "2009-02-09,37,53;" +
    "2009-02-09,38,53;" +
    "2009-02-10,31,52;" +
    "2009-02-10,31,54;" +
    "2009-02-11,39,53;" +
    "2009-02-11,40,52;" +
    "2009-02-12,40,53;" +
    "2009-02-12,40,54;" +
    "2009-02-13,40,52;" +
    "2009-02-13,39,53;" +
    "2009-02-14,38,54;" +
    "2009-02-14,38,55;" +
    "2009-02-15,46,54;" +
    "2009-02-15,46,53;" +
    "2009-02-16,45,53;" +
    "2009-02-16,45,52;" +
    "2009-02-17,43,53;" +
    "2009-02-17,43,52;" +
    "2009-02-18,37,57;" +
    "2009-02-18,37,56;" +
    "2009-02-19,37,61;" +
    "2009-02-19,36,61;" +
    "2009-02-20,38,63;" +
    "2009-02-20,39,64;" +
    "2009-02-21,38,63;" +
    "2009-02-21,40,63;" +
    "2009-02-22,54,61;" +
    "2009-02-22,53,63;" +
    "2009-02-23,54,60;" +
    "2009-02-23,53,60;" +
    "2009-02-24,48,63;" +
    "2009-02-24,48,64;" +
    "2009-02-25,48,62;" +
    "2009-02-25,46,64;" +
    "2009-02-26,43,62;" +
    "2009-02-26,49,64;" +
    "2009-02-27,38,61;" +
    "2009-02-27,39,62;" +
    "2009-02-28,44,61;" +
    "2009-02-28,45,62;" +
    "2009-03-01,50,64;" +
    "2009-03-01,51,59;" +
    "2009-03-02,51,60;" +
    "2009-03-02,53,63;" +
    "2009-03-03,46,56;" +
    "2009-03-03,46,56;" +
    "2009-03-04,39,54;" +
    "2009-03-04,39,55;" +
    "2009-03-05,42,55;" +
    "2009-03-05,41,55;" +
    "2009-03-06,39,57;" +
    "2009-03-06,42,58;" +
    "2009-03-07,35,60;" +
    "2009-03-07,35,62;" +
    "2009-03-08,36,60;" +
    "2009-03-08,38,62;" +
    "2009-03-09,36,55;" +
    "2009-03-09,36,56;" +
    "2009-03-10,31,57;" +
    "2009-03-10,31,58;" +
    "2009-03-11,33,62;" +
    "2009-03-11,33,63;" +
    "2009-03-12,35,66;" +
    "2009-03-12,35,68;" +
    "2009-03-13,42,67;" +
    "2009-03-13,41,70;" +
    "2009-03-14,46,53;" +
    "2009-03-14,45,61;" +
    "2009-03-15,47,62;" +
    "2009-03-15,46,65;" +
    "2009-03-16,52,65;" +
    "2009-03-16,52,66;" +
    "2009-03-17,46,69;" +
    "2009-03-17,50,71;" +
    "2009-03-18,44,72;" +
    "2009-03-18,45,73;" +
    "2009-03-19,49,75;" +
    "2009-03-19,49,77;" +
    "2009-03-20,46,69;" +
    "2009-03-20,46,72;" +
    "2009-03-21,47,61;" +
    "2009-03-21,47,62;" +
    "2009-03-22,41,56;" +
    "2009-03-22,41,57;" +
    "2009-03-23,34,60;" +
    "2009-03-23,34,62;" +
    "2009-03-24,35,69;" +
    "2009-03-24,36,70;" +
    "2009-03-25,39,70;" +
    "2009-03-25,40,72;" +
    "2009-03-26,44,74;" +
    "2009-03-26,45,76;" +
    "2009-03-27,45,76;" +
    "2009-03-27,47,76;" +
    "2009-03-28,44,78;" +
    "2009-03-28,46,81;" +
    "2009-03-29,45,67;" +
    "2009-03-29,47,72;" +
    "2009-03-30,42,67;" +
    "2009-03-30,44,67;" +
    "2009-03-31,38,74;" +
    "2009-03-31,40,75;" +
    "2009-04-01,42,71;" +
    "2009-04-01,44,74;" +
    "2009-04-02,44,65;" +
    "2009-04-02,45,66;" +
    "2009-04-03,38,63;" +
    "2009-04-03,40,65;" +
    "2009-04-04,36,66;" +
    "2009-04-04,36,67;" +
    "2009-04-05,35,75;" +
    "2009-04-05,38,75;" +
    "2009-04-06,39,78;" +
    "2009-04-06,41,79;" +
    "2009-04-07,46,58;" +
    "2009-04-07,48,74;" +
    "2009-04-08,49,61;" +
    "2009-04-08,48,61;" +
    "2009-04-09,49,55;" +
    "2009-04-09,50,56;" +
    "2009-04-10,46,61;" +
    "2009-04-10,47,61;" +
    "2009-04-11,41,63;" +
    "2009-04-11,44,65;" +
    "2009-04-12,50,70;" +
    "2009-04-12,49,72;" +
    "2009-04-13,46,67;" +
    "2009-04-13,46,68;" +
    "2009-04-14,39,59;" +
    "2009-04-14,39,60;" +
    "2009-04-15,38,61;" +
    "2009-04-15,38,63;" +
    "2009-04-16,39,66;" +
    "2009-04-16,41,67;" +
    "2009-04-17,39,75;" +
    "2009-04-17,39,78;" +
    "2009-04-18,44,82;" +
    "2009-04-18,45,84;" +
    "2009-04-19,49,88;" +
    "2009-04-19,50,88;" +
    "2009-04-20,52,93;" +
    "2009-04-20,54,94;" +
    "2009-04-21,54,94;" +
    "2009-04-21,56,94;" +
    "2009-04-22,52,88;" +
    "2009-04-22,55,90;" +
    "2009-04-23,47,69;" +
    "2009-04-23,52,81;" +
    "2009-04-24,47,60;" +
    "2009-04-24,47,62;" +
    "2009-04-25,42,64;" +
    "2009-04-25,40,66;" +
    "2009-04-26,44,68;" +
    "2009-04-26,45,71;" +
    "2009-04-27,44,59;" +
    "2009-04-27,44,63;" +
    "2009-04-28,45,60;" +
    "2009-04-28,44,61;" +
    "2009-04-29,43,63;" +
    "2009-04-29,43,64;" +
    "2009-04-30,41,71;" +
    "2009-04-30,41,73;" +
    "2009-05-01,47,65;" +
    "2009-05-01,48,70;" +
    "2009-05-02,53,66;" +
    "2009-05-02,52,66;" +
    "2009-05-03,52,64;" +
    "2009-05-03,55,66;" +
    "2009-05-04,52,69;" +
    "2009-05-04,52,74;" +
    "2009-05-05,58,75;" +
    "2009-05-05,59,77;" +
    "2009-05-06,53,77;" +
    "2009-05-06,54,77;" +
    "2009-05-07,54,79;" +
    "2009-05-07,54,80;" +
    "2009-05-08,48,82;" +
    "2009-05-08,48,83;" +
    "2009-05-09,49,83;" +
    "2009-05-09,50,84;" +
    "2009-05-10,48,84;" +
    "2009-05-10,49,85;" +
    "2009-05-11,50,79;" +
    "2009-05-11,57,82;" +
    "2009-05-12,45,77;" +
    "2009-05-12,46,78;" +
    "2009-05-13,46,80;" +
    "2009-05-13,48,80;" +
    "2009-05-14,49,76;" +
    "2009-05-14,50,77;" +
    "2009-05-15,54,86;" +
    "2009-05-15,54,87;" +
    "2009-05-16,54,99;" +
    "2009-05-16,55,99;" +
    "2009-05-17,61,104;" +
    "2009-05-17,61,103;" +
    "2009-05-18,53,86;" +
    "2009-05-18,61,95;" +
    "2009-05-19,51,79;" +
    "2009-05-19,53,80;" +
    "2009-05-20,49,78;" +
    "2009-05-20,47,79;" +
    "2009-05-21,47,84;" +
    "2009-05-21,48,85;" +
    "2009-05-22,50,80;" +
    "2009-05-22,49,82;" +
    "2009-05-23,49,69;" +
    "2009-05-23,50,71;" +
    "2009-05-24,49,62;" +
    "2009-05-24,50,64;" +
    "2009-05-25,51,81;" +
    "2009-05-25,49,82;" +
    "2009-05-26,50,90;" +
    "2009-05-26,50,90;" +
    "2009-05-27,58,91;" +
    "2009-05-27,57,92;" +
    "2009-05-28,55,87;" +
    "2009-05-28,55,88;" +
    "2009-05-29,53,80;" +
    "2009-05-29,53,82;" +
    "2009-05-30,53,73;" +
    "2009-05-30,54,75;" +
    "2009-05-31,51,71;" +
    "2009-05-31,52,74;" +
    "2009-06-01,51,73;" +
    "2009-06-01,52,74;" +
    "2009-06-02,53,76;" +
    "2009-06-02,53,76;" +
    "2009-06-03,54,78;" +
    "2009-06-03,54,79;" +
    "2009-06-04,54,72;" +
    "2009-06-04,53,72;" +
    "2009-06-05,54,73;" +
    "2009-06-05,54,73;" +
    "2009-06-06,52,74;" +
    "2009-06-06,51,74;" +
    "2009-06-07,54,73;" +
    "2009-06-07,53,75;" +
    "2009-06-08,54,71;" +
    "2009-06-08,54,72;" +
    "2009-06-09,57,70;" +
    "2009-06-09,54,71;" +
    "2009-06-10,57,71;" +
    "2009-06-10,57,71;" +
    "2009-06-11,57,71;" +
    "2009-06-11,56,72;" +
    "2009-06-12,57,70;" +
    "2009-06-12,55,71;" +
    "2009-06-13,54,73;" +
    "2009-06-13,57,74;" +
    "2009-06-14,55,74;" +
    "2009-06-14,55,75;" +
    "2009-06-15,53,69;" +
    "2009-06-15,54,70;" +
    "2009-06-16,56,79;" +
    "2009-06-16,56,80;" +
    "2009-06-17,55,72;" +
    "2009-06-17,55,74;" +
    "2009-06-18,55,93;" +
    "2009-06-18,52,95;" +
    "2009-06-19,55,87;" +
    "2009-06-19,58,87;" +
    "2009-06-20,52,75;" +
    "2009-06-20,52,75;" +
    "2009-06-21,52,78;" +
    "2009-06-21,51,79;" +
    "2009-06-22,51,87;" +
    "2009-06-22,51,87;" +
    "2009-06-23,54,94;" +
    "2009-06-23,55,95;" +
    "2009-06-24,56,91;" +
    "2009-06-24,58,92;" +
    "2009-06-25,55,85;" +
    "2009-06-25,54,86;" +
    "2009-06-26,53,90;" +
    "2009-06-26,52,91;" +
    "2009-06-27,56,104;" +
    "2009-06-27,57,104;" +
    "2009-06-28,64,109;" +
    "2009-06-28,65,109;" +
    "2009-06-29,62,99;" +
    "2009-06-29,64,99;" +
    "2009-06-30,57,94;" +
    "2009-06-30,57,95;" +
    "2009-07-01,57,87;" +
    "2009-07-01,56,89;" +
    "2009-07-02,56,88;" +
    "2009-07-02,55,91;" +
    "2009-07-03,54,91;" +
    "2009-07-03,54,92;" +
    "2009-07-04,55,80;" +
    "2009-07-04,54,85;" +
    "2009-07-05,53,73;" +
    "2009-07-05,55,76;" +
    "2009-07-06,52,79;" +
    "2009-07-06,53,81;" +
    "2009-07-07,52,78;" +
    "2009-07-07,51,80;" +
    "2009-07-08,52,85;" +
    "2009-07-08,52,85;" +
    "2009-07-09,53,79;" +
    "2009-07-09,52,82;" +
    "2009-07-10,51,84;" +
    "2009-07-10,48,85;" +
    "2009-07-11,54,85;" +
    "2009-07-11,54,83;" +
    "2009-07-12,57,86;" +
    "2009-07-12,60,86;" +
    "2009-07-13,51,97;" +
    "2009-07-13,52,94;" +
    "2009-07-14,60,101;" +
    "2009-07-14,60,101;" +
    "2009-07-15,62,99;" +
    "2009-07-15,62,99;" +
    "2009-07-16,58,100;" +
    "2009-07-16,58,101;" +
    "2009-07-17,61,101;" +
    "2009-07-17,62,101;" +
    "2009-07-18,64,102;" +
    "2009-07-18,63,102;" +
    "2009-07-19,61,104;" +
    "2009-07-19,63,104;" +
    "2009-07-20,56,88;" +
    "2009-07-20,56,96;" +
    "2009-07-21,54,80;" +
    "2009-07-21,54,82;" +
    "2009-07-22,53,82;" +
    "2009-07-22,53,84;" +
    "2009-07-23,54,82;" +
    "2009-07-23,54,84;" +
    "2009-07-24,55,77;" +
    "2009-07-24,54,79;" +
    "2009-07-25,55,89;" +
    "2009-07-25,54,90;" +
    "2009-07-26,54,98;" +
    "2009-07-26,54,99;" +
    "2009-07-27,57,97;" +
    "2009-07-27,57,98;" +
    "2009-07-28,56,82;" +
    "2009-07-28,56,91;" +
    "2009-07-29,58,79;" +
    "2009-07-29,57,81;" +
    "2009-07-30,57,79;" +
    "2009-07-30,57,82;" +
    "2009-07-31,56,84;" +
    "2009-07-31,55,85;" +
    "2009-08-01,54,83;" +
    "2009-08-01,55,85;" +
    "2009-08-02,55,78;" +
    "2009-08-02,55,81;" +
    "2009-08-03,56,77;" +
    "2009-08-03,56,79;" +
    "2009-08-04,58,84;" +
    "2009-08-04,58,85;" +
    "2009-08-05,55,77;" +
    "2009-08-05,55,79;" +
    "2009-08-06,57,74;" +
    "2009-08-06,60,74;" +
    "2009-08-07,58,77;" +
    "2009-08-07,58,78;" +
    "2009-08-08,54,90;" +
    "2009-08-08,54,91;" +
    "2009-08-09,57,97;" +
    "2009-08-09,56,97;" +
    "2009-08-10,61,101;" +
    "2009-08-10,60,100;" +
    "2009-08-11,59,86;" +
    "2009-08-11,59,93;" +
    "2009-08-12,57,98;" +
    "2009-08-12,56,97;" +
    "2009-08-13,60,89;" +
    "2009-08-13,61,93;" +
    "2009-08-14,56,78;" +
    "2009-08-14,56,80;" +
    "2009-08-15,53,90;" +
    "2009-08-15,51,91;" +
    "2009-08-16,54,95;" +
    "2009-08-16,55,95;" +
    "2009-08-17,56,88;" +
    "2009-08-17,56,90;" +
    "2009-08-18,55,91;" +
    "2009-08-18,55,93;" +
    "2009-08-19,57,84;" +
    "2009-08-19,56,86;" +
    "2009-08-20,56,82;" +
    "2009-08-20,57,84;" +
    "2009-08-21,56,101;" +
    "2009-08-21,55,101;" +
    "2009-08-22,60,90;" +
    "2009-08-22,62,96;" +
    "2009-08-23,54,83;" +
    "2009-08-23,55,84;" +
    "2009-08-24,50,82;" +
    "2009-08-24,49,83;" +
    "2009-08-25,53,90;" +
    "2009-08-25,52,90;" +
    "2009-08-26,53,90;" +
    "2009-08-26,53,91;" +
    "2009-08-27,55,98;" +
    "2009-08-27,54,97;" +
    "2009-08-28,62,103;" +
    "2009-08-28,62,101;" +
    "2009-08-29,66,104;" +
    "2009-08-29,65,103;" +
    "2009-08-30,58,85;" +
    "2009-08-30,61,94;" +
    "2009-08-31,56,81;" +
    "2009-08-31,56,82;" +
    "2009-09-01,58,93;" +
    "2009-09-01,57,94;" +
    "2009-09-02,63,99;" +
    "2009-09-02,62,100;" +
    "2009-09-03,62,99;" +
    "2009-09-03,62,98;" +
    "2009-09-04,56,84;" +
    "2009-09-04,56,87;" +
    "2009-09-05,54,79;" +
    "2009-09-05,55,80;" +
    "2009-09-06,54,78;" +
    "2009-09-06,55,79;" +
    "2009-09-07,54,84;" +
    "2009-09-07,54,84;" +
    "2009-09-08,50,89;" +
    "2009-09-08,50,90;" +
    "2009-09-09,55,91;" +
    "2009-09-09,54,91;" +
    "2009-09-10,58,98;" +
    "2009-09-10,57,98;" +
    "2009-09-11,61,101;" +
    "2009-09-11,60,100;" +
    "2009-09-12,58,75;" +
    "2009-09-12,60,89;" +
    "2009-09-13,58,77;" +
    "2009-09-13,58,78;" +
    "2009-09-14,58,75;" +
    "2009-09-14,57,76;" +
    "2009-09-15,55,85;" +
    "2009-09-15,54,86;" +
    "2009-09-16,56,85;" +
    "2009-09-16,55,86;" +
    "2009-09-17,59,93;" +
    "2009-09-17,58,91;" +
    "2009-09-18,59,99;" +
    "2009-09-18,58,99;" +
    "2009-09-19,59,89;" +
    "2009-09-19,60,89;" +
    "2009-09-20,56,93;" +
    "2009-09-20,56,94;" +
    "2009-09-21,60,97;" +
    "2009-09-21,59,98;" +
    "2009-09-22,59,102;" +
    "2009-09-22,59,102;" +
    "2009-09-23,61,96;" +
    "2009-09-23,59,98;" +
    "2009-09-24,59,93;" +
    "2009-09-24,56,93;" +
    "2009-09-25,53,99;" +
    "2009-09-25,53,99;" +
    "2009-09-26,58,103;" +
    "2009-09-26,58,102;" +
    "2009-09-27,61,102;" +
    "2009-09-27,61,101;" +
    "2009-09-28,57,75;" +
    "2009-09-28,55,89;" +
    "2009-09-29,53,69;" +
    "2009-09-29,54,69;" +
    "2009-09-30,46,75;" +
    "2009-09-30,44,75;" +
    "2009-10-01,50,81;" +
    "2009-10-01,52,79;" +
    "2009-10-02,49,85;" +
    "2009-10-02,48,85;" +
    "2009-10-03,51,70;" +
    "2009-10-03,51,76;" +
    "2009-10-04,45,66;" +
    "2009-10-04,46,66;" +
    "2009-10-05,42,71;" +
    "2009-10-05,41,71;" +
    "2009-10-06,44,74;" +
    "2009-10-06,43,73;" +
    "2009-10-07,43,79;" +
    "2009-10-07,44,80;" +
    "2009-10-08,49,71;" +
    "2009-10-08,47,73;" +
    "2009-10-09,49,76;" +
    "2009-10-09,51,77;" +
    "2009-10-10,49,75;" +
    "2009-10-10,47,75;" +
    "2009-10-11,50,63;" +
    "2009-10-11,49,66;" +
    "2009-10-12,53,66;" +
    "2009-10-12,50,66;" +
    "2009-10-13,58,64;" +
    "2009-10-13,56,62;" +
    "2009-10-14,59,74;" +
    "2009-10-14,58,74;" +
    "2009-10-15,63,81;" +
    "2009-10-15,62,82;" +
    "2009-10-16,60,82;" +
    "2009-10-16,58,80;" +
    "2009-10-17,56,83;" +
    "2009-10-17,54,84;" +
    "2009-10-18,56,69;" +
    "2009-10-18,57,72;" +
    "2009-10-19,53,68;" +
    "2009-10-19,53,68;" +
    "2009-10-20,50,71;" +
    "2009-10-20,49,71;" +
    "2009-10-21,54,73;" +
    "2009-10-21,53,74;" +
    "2009-10-22,55,74;" +
    "2009-10-22,54,76;" +
    "2009-10-23,51,82;" +
    "2009-10-23,51,83;" +
    "2009-10-24,52,78;" +
    "2009-10-24,52,79;" +
    "2009-10-25,50,82;" +
    "2009-10-25,51,81;" +
    "2009-10-26,52,86;" +
    "2009-10-26,53,81;" +
    "2009-10-27,49,64;" +
    "2009-10-27,48,65;" +
    "2009-10-28,44,62;" +
    "2009-10-28,49,62;" +
    "2009-10-29,38,70;" +
    "2009-10-29,38,71;" +
    "2009-10-30,45,74;" +
    "2009-10-30,44,73;" +
    "2009-10-31,46,75;" +
    "2009-10-31,45,76;" +
    "2009-11-01,47,74;" +
    "2009-11-01,47,74;" +
    "2009-11-02,46,80;" +
    "2009-11-02,46,79;" +
    "2009-11-03,46,75;" +
    "2009-11-03,46,74;" +
    "2009-11-04,46,68;" +
    "2009-11-04,46,69;" +
    "2009-11-05,47,69;" +
    "2009-11-05,47,70;" +
    "2009-11-06,46,68;" +
    "2009-11-06,47,70;" +
    "2009-11-07,41,66;" +
    "2009-11-07,43,70;" +
    "2009-11-08,38,66;" +
    "2009-11-08,41,70;" +
    "2009-11-09,34,65;" +
    "2009-11-09,35,65;" +
    "2009-11-10,43,64;" +
    "2009-11-10,44,63;" +
    "2009-11-11,48,65;" +
    "2009-11-11,48,66;" +
    "2009-11-12,40,62;" +
    "2009-11-12,48,61;" +
    "2009-11-13,36,59;" +
    "2009-11-13,36,60;" +
    "2009-11-14,37,61;" +
    "2009-11-14,41,60;" +
    "2009-11-15,31,65;" +
    "2009-11-15,33,64;" +
    "2009-11-16,34,65;" +
    "2009-11-16,34,65;" +
    "2009-11-17,34,64;" +
    "2009-11-17,34,64;" +
    "2009-11-18,37,61;" +
    "2009-11-18,41,61;" +
    "2009-11-19,32,62;" +
    "2009-11-19,33,62;" +
    "2009-11-20,34,61;" +
    "2009-11-20,34,60;" +
    "2009-11-21,35,57;" +
    "2009-11-21,37,56;" +
    "2009-11-22,37,58;" +
    "2009-11-22,36,60;" +
    "2009-11-23,39,63;" +
    "2009-11-23,39,62;" +
    "2009-11-24,37,66;" +
    "2009-11-24,39,65;" +
    "2009-11-25,37,66;" +
    "2009-11-25,37,65;" +
    "2009-11-26,34,68;" +
    "2009-11-26,36,68;" +
    "2009-11-27,45,58;" +
    "2009-11-27,44,59;" +
    "2009-11-28,36,65;" +
    "2009-11-28,38,64;" +
    "2009-11-29,39,68;" +
    "2009-11-29,46,67;" +
    "2009-11-30,34,61;" +
    "2009-11-30,35,60;" +
    "2009-12-01,32,65;" +
    "2009-12-01,34,64;" +
    "2009-12-02,33,58;" +
    "2009-12-02,32,58;" +
    "2009-12-03,35,62;" +
    "2009-12-03,36,61;" +
    "2009-12-04,29,62;" +
    "2009-12-04,31,63;" +
    "2009-12-05,31,53;" +
    "2009-12-05,40,52;" +
    "2009-12-06,24,51;" +
    "2009-12-06,26,53;" +
    "2009-12-07,29,45;" +
    "2009-12-07,33,45;" +
    "2009-12-08,24,46;" +
    "2009-12-08,26,48;" +
    "2009-12-09,26,44;" +
    "2009-12-09,27,43;" +
    "2009-12-10,38,44;" +
    "2009-12-10,36,43;" +
    "2009-12-11,40,47;" +
    "2009-12-11,38,44;" +
    "2009-12-12,44,60;" +
    "2009-12-12,43,59;" +
    "2009-12-13,49,57;" +
    "2009-12-13,48,55;" +
    "2009-12-14,41,57;" +
    "2009-12-14,41,58;" +
    "2009-12-15,42,59;" +
    "2009-12-15,41,58;" +
    "2009-12-16,47,59;" +
    "2009-12-16,46,58;" +
    "2009-12-17,42,60;" +
    "2009-12-17,46,60;" +
    "2009-12-18,39,60;" +
    "2009-12-18,40,59;" +
    "2009-12-19,40,51;" +
    "2009-12-19,42,52;" +
    "2009-12-20,43,64;" +
    "2009-12-20,43,66;" +
    "2009-12-21,44,58;" +
    "2009-12-21,45,59;" +
    "2009-12-22,35,53;" +
    "2009-12-22,34,53;" +
    "2009-12-23,33,57;" +
    "2009-12-23,36,57;" +
    "2009-12-24,32,57;" +
    "2009-12-24,32,57;" +
    "2009-12-25,30,57;" +
    "2009-12-25,31,57;" +
    "2009-12-26,33,54;" +
    "2009-12-26,35,54;" +
    "2009-12-27,43,53;" +
    "2009-12-27,41,55;" +
    "2009-12-28,37,51;" +
    "2009-12-28,42,49;" +
    "2009-12-29,33,53;" +
    "2009-12-29,31,54;" +
    "2009-12-30,45,59;" +
    "2009-12-30,47,59;" +
    "2009-12-31,45,59;" +
    "2009-12-31,44,60;"